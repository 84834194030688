<div class="full-page-background"></div>
<div class="wrapper">
  <div class="sign-up-wrapper">

    <nav class="navbar navbar-transparent">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navigation-example-2"
          (click)="sidebarToggle()">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>

      <div class="collapse navbar-collapse">
        <ul class="nav navbar-nav navbar-right margin-right-60">
          <li>
            <a class="nav-colour" (click)="logout()">
              <i class="fal fa-sign-out"></i> Log out
            </a>
          </li>
        </ul>
      </div>
    </nav>

    <div class="main-content">
      <div class="container">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>