import { Component, OnDestroy, OnInit } from '@angular/core';
import { Goal, GoalStatus, GoalType } from 'app/models/goals/goal.model';
import { GoalsAPIService } from 'app/shared/api/goals.api.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { UserAPIService } from 'app/shared/api/user.api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { User } from 'app/models/user/user.model';
import { GoalTag } from 'app/models/goals/goal-tag.model';
import { Breadcrumb } from 'app/models/breadcrumb.model';
import { BreadcrumbService } from 'app/shared/breadcrumbs/breadcrumbs.service';
import { GoalCreateService } from '../goals-create/goals-create.service';

interface State { loading: boolean, error: boolean }

@Component({
  selector: 'app-goals-all',
  templateUrl: './goals-all.component.html',
  styleUrls: ['./goals-all.component.css']
})
export class GoalsAllComponent implements OnInit, OnDestroy {
  state: State;
  userId: number;

  goals: Array<Goal>;
  eGoalStatus = GoalStatus;
  eGoalType = GoalType;

  departmentName = '';


  user: User | null;
  tag: GoalTag | null;

  source: null | string;

  breadcrumb: Breadcrumb;
  subscriptions: Array<Subscription>;

  filter: string;

  constructor(
    public route: ActivatedRoute,
    private goalsAPIService: GoalsAPIService,
    private userAPIService: UserAPIService,
    private breadcrumbService: BreadcrumbService,
    private goalCreateService: GoalCreateService
  ) {
    this.state = { loading: true, error: false }
    this.user = null;
    this.tag = null;

    this.breadcrumb = this.breadcrumbService.init(this.route);

    // set
    const userId = this.route.snapshot.paramMap.get('userId');
    if (userId === null || isNaN(+userId) === true) {
      this.state.error = true;
      this.state.loading = false;
    } else {
      this.userId = +userId;
    }

    this.filter = 'all';

    this.subscriptions = new Array<Subscription>();
    this.subscriptions.push(
      this.goalCreateService.getGoalCreated().subscribe(goal => {
        this.ngOnInit();
      }),
    );
  }

  ngOnInit() {
    this.state.loading = true;
    this.state.error = false;

    this.initUser(this.userId, this.filter)
  }

  ngOnDestroy() {
    this.breadcrumbService.remove(this.breadcrumb);
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }


  changeFilter(filter: string) {
    this.state.loading = true;
    this.state.error = false;

    this.filter = filter;
    this.initUser(this.userId, this.filter);
  }

  initUser(userId: number, filter: string) {
    this.userAPIService.getById(userId).subscribe(user => {
      this.breadcrumb = this.breadcrumbService.updateCurrentBreadcrumb(`${user.firstName} ${user.lastName}'s Goals`, null);
      this.user = user;
      if (filter === 'active') {
        this.handleResponse(this.goalsAPIService.getIndividualActiveGoalsByUserId(userId));
      } else if (filter === 'complete') {
        this.handleResponse(this.goalsAPIService.getIndividualCompleteGoalsByUserId(userId));
      } else if (filter === 'archived') {
        this.handleResponse(this.goalsAPIService.getIndividualArchivedGoalsByUserId(userId));
      } else if (filter === 'all') {
        this.handleResponse(this.goalsAPIService.getIndividualGoalsByUserId(userId));
      }
    }, (failure: HttpErrorResponse) => {
      this.state.error = true;
      this.state.loading = false;
    });
  }

  handleResponse(observable: Observable<Array<Goal>>) {
    observable.subscribe(goals => {
      this.goals = Goal.getGoalArrayCompletionPercentage(goals);
      this.state.loading = false;
    }, error => {
      this.state.error = true;
      this.state.loading = false;
    });
  }

}
