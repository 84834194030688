import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/shared/globals/globals';
import { NotificationService } from '@app/shared/globals/notification.service';
import { CompanyAPIService } from '@app/shared/api/company.api.service';
import { FrankliNotificationType } from '@app/models/notifications/frankli-event-type.enum';

interface Progress { left: string; right: string }

@Component({
  selector: 'app-sidebar-setup',
  templateUrl: './sidebar-setup.component.html',
  styleUrls: ['./sidebar-setup.component.css']
})
export class SidebarSetupComponent implements OnInit {
  progress!: Progress;
  complete!: number;
  total!: number;

  constructor(
    public router: Router,
    public globals: Globals,
    private notificationService: NotificationService,
    private companyAPIService: CompanyAPIService
  ) {
    this.progress = {
      left: `rotate(${0}deg)`,
      right: `rotate(${0}deg)`
    }
    this.complete = 0;
    this.total = 0;
    this.updateProgress();
  }

  ngOnInit() {
    this.notificationService.getNotifications$().subscribe(notification => {
      // Refresh sidebar numbers on step completion
      if (notification.eventType === FrankliNotificationType.COMPANY_SET_UP_STEP_COMPLETE) {
        this.companyAPIService.getSetUpSteps().subscribe(groups => {
          this.globals.company.setUpGroups = groups;
          this.updateProgress();
        })
      }
    });
  }

  openSetupGuide() {
    this.router.navigate(['/company-setup/steps']);
  }

  private getProgress(total: number, complete: number): Progress {
    const percentage = Number(((complete / total)).toFixed(2));
    const degrees = 360 * percentage;

    let left = 0;
    let right = 0;
    if (degrees > 180) {
      left = degrees - 180;
      right = 180;
    } else {
      right = degrees;
    }

    return {
      left: `rotate(${left}deg)`,
      right: `rotate(${right}deg)`
    }
  }

  private updateProgress(): void {
    let total = 0;
    let complete = 0;

    this.globals.company.setUpGroups.forEach(group => {
      group.steps.forEach(step => {
        total++;
        if (step.complete === true || step.skipped === true) {
          complete++;
        }
      });
    });

    this.total = total;
    this.complete = complete;
    this.progress = this.getProgress(this.total, this.complete);
  }
}
