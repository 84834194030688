<div id="container" class="col-md-12">
  <div *ngIf="praiseList.length > 0">
    <div class="header">
      <h4 *ngIf="user.id === userProfile.id" class="title text-left">You've been praised!
        <span class="fal fa-edit frankli-edit expand-on-hover" [title]="'Edit Praise List'"
          [routerLink]="'/feedback/history/praise/profile/' + userProfile?.id"></span>
      </h4>
      <h4 *ngIf="user.id !== userProfile.id" class="title text-left">Praise for {{userProfile?.firstName}}</h4>
    </div>
    <hr class="less-padding" />
    <div class="content p-0 scroll-area">
      <div>
        <div class="col-12" *ngFor="let praise of praiseList; let i = index">
          <div class="praise-content m-bottom-10" *ngIf="praise.id === editingId">
            <div class="row">
              <div class="col-xs-11">
                <div class="row no-gutters">
                  <div class="col-xs-1 p-right-0 text-right z-10">
                    <span class="praise-user">
                      <app-profile-photo [src]="praise.sourceUserImageUrl"></app-profile-photo>
                    </span>
                  </div>
                  <div class="col-xs-11 praise-card">
                    <div class="row no-gutters">
                      <div class="col-xs-9">
                        <div class="frankli-form-group frankli-form-group-filled" [class.frankli-form-group-invalid]="submittedPraise && editForm.controls.editMessage.invalid">
                          <label>{{praise.sourceUserFirstName + ' ' + praise.sourceUserLastName}} wrote:</label>
                          <app-editor-response [formControl]="editForm.controls.editMessage" [toolbar]="editorToolbar" [enforceCharacterLimit]="false" [columnIndent]="false" [height]="150" [maxCharacters]="largeEditorMaxLengthSoft" [minlength]="largeEditorMinLength" [placeholder]="'Please write here (Max ' + largeEditorMaxLengthSoft + ' characters)'"></app-editor-response>
                        </div>
                        <div class="frankli-form-group-alert">
                          <ng-container *ngIf="submittedPraise">
                            <div *ngIf="editForm.controls.editMessage.hasError('required')">Please provide a little detail here</div>
                            <div *ngIf="editForm.controls.editMessage.hasError('softmin')">Your submission must be at least {{largeEditorMinLength}} characters</div>
                          </ng-container>
                          <div *ngIf="editForm.controls.editMessage.hasError('softmax')">Your submission must be {{largeEditorMaxLengthSoft}} characters or fewer</div>
                          <div *ngIf="editForm.controls.editMessage.hasError('maxlength')">Too much formatting</div>
                        </div>
                        <div class="row">
                          <div class="col-md-10 col-md-offset-1">
                            <p class="text-center">Which Company values did they demonstrate?</p>
                            <div>
                              <div class="sm-scroll-area">
                                  <div id="noValues" *ngIf="availableValues.length === 0">
                                      <p>No values found</p>
                                  </div>
                                  <span class="interest" [class.interest-selected]="value.selected" *ngFor="let value of availableValues" (click)="toggleValue(value)">{{value.text}}</span>
                              </div>
                              </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xs-2 text-right text-nowrap">
                        <span class="action-icons">
                          <span class="fal fa-check clickable expand-on-hover frankli-create" (click)="savePraise(praise)" [title]="'Save Praise'"></span>
                          <span class="fal fa-times clickable expand-on-hover frankli-close" (click)="cancelEditing()" [title]="'Cancel Editing'"></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="praise.id !== editingId" class="praise-content m-bottom-10">
            <div class="row">
              <div class="col-xs-11">
                <div class="row no-gutters">
                  <div class="col-xs-1 p-right-0 text-right z-10">
                    <span class="praise-user">
                      <app-profile-photo [src]="praise.sourceUserImageUrl"></app-profile-photo>
                    </span>
                  </div>
                  <div class="col-xs-11 p-left-0 praise-card">
                    <div class="row no-gutters">
                      <div class="m-left-20 col-xs-9">
                        <label>{{praise.sourceUserFirstName + ' ' + praise.sourceUserLastName}} wrote:</label>
                        <div class="wrap-text praise-message-text">
                          <app-rich-display [text]="praise.message"></app-rich-display>
                        </div>
                      </div>
                      <div class="col-xs-2 text-right text-nowrap actions-col">
                        <!-- Conditionally show the date span based on who owns the praise to prevent a visual glitch. May be cleaner way to do this. -->
                        <span *ngIf="praise.sourceUserId === globals.user.id || user.id === userProfile.id" class="smaller m-top-5 m-right-5 pull-right praise-ts">{{praise.timestamp | dateAgo}}</span>
                        <span *ngIf="praise.sourceUserId !== globals.user.id && user.id !== userProfile.id" class="smaller m-top-5 m-right-5 pull-right">{{praise.timestamp | dateAgo}}</span>
                        <span class="action-icons pull-right">
                          <span *ngIf="praise.sourceUserId === globals.user.id" class="fal fa-edit clickable expand-on-hover frankli-edit" (click)="editPraise(praise)" [title]="'Edit Praise'"></span>
                          <span *ngIf="user.id === userProfile.id" class="fal fa-eye-slash frankli-view expand-on-hover" (click)="hidePraise(praise)" [title]="'Hide Praise'"></span>
                          <span *ngIf="praise.sourceUserId === globals.user.id" class="fal fa-times clickable expand-on-hover frankli-close" (click)="removePraise(praise)" [title]="'Remove Praise'"></span>
                        </span>
                      </div>
                    </div>
                    <div class="border-top"></div>
                    <div class="row no-gutters m-top-10">
                      <div class="reaction-group m-left-20 col-xs-5">
                      <span id="reactionStart" class="clickable" (mouseenter)="mouseInPop = true" (mouseleave)="mouseInPop = false" [popover]="reactionTemplate" [delay]="300" [popoverContext]="{praise:praise}" #pop="bs-popover" placement="top" outsideClick="true" triggers="mouseenter" (onShown)="hideOtherPopovers($event, praise)">
                        <span *ngIf="!userHasReacted(praise)" (click)="toggleReaction(praise, eReactionType.LIKE)"><span class="fal fa-thumbs-up"></span> Like</span>
                        <span *ngIf="userHasReacted(praise, eReactionType.LIKE)" (click)="toggleReaction(praise, eReactionType.LIKE)"><span class="fas fa-thumbs-up"></span> Liked</span>
                        <span *ngIf="userHasReacted(praise, eReactionType.CELEBRATE)" (click)="toggleReaction(praise, eReactionType.CELEBRATE)"><span class="fas fa-hands-wash"></span> Celebrated</span>
                        <span *ngIf="userHasReacted(praise, eReactionType.LOVE)" (click)="toggleReaction(praise, eReactionType.LOVE)"><span class="fas fa-heart"></span> Loved</span>
                      </span>
                        <ng-container *ngIf="praise.reactions?.length > 0">
                          <span><b> | </b></span>
                          <span class="clickable reaction-group-reactions" (click)="loadReactionUserModal(praise.reactions)" [title]="'This praise has ' + praise.reactions?.length + ' reactions'">
                        <span *ngIf="getUniqueReactions(praise.reactions).has(eReactionType.LIKE)" class="fas fa-thumbs-up like-icon"></span>
                        <span *ngIf="getUniqueReactions(praise.reactions).has(eReactionType.CELEBRATE)" class="fas fa-hands-wash celebrate-icon"></span>
                        <span *ngIf="getUniqueReactions(praise.reactions).has(eReactionType.LOVE)" class="fas fa-heart love-icon"></span>
                        <span id="reactionLink">{{ praise.reactions?.length }}</span>
                      </span>
                        </ng-container>
                      </div>
                      <div class="col-xs-6 text-right" *ngIf="praise.values?.length > 0">
                        <div>
                          <span class="praise-values p-left-10" *ngFor="let v of praise?.values">{{v.text | titlecase}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="praiseList.length > 4" class="text-center p-bottom-10">
          <a class="text-center praise-link" (click)="viewAllPraise()">View All Praise</a>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="praiseList.length === 0 && userProfile !== undefined && user.id !== userProfile.id && !loading">
    <div class="header">
      <h4 class="title text-left">Praise for {{userProfile.firstName}}</h4>
    </div>
    <hr class="less-padding" />
    <div class="content text-center">
      <p>
        <img class="icon-padding new-icon-height" src="/assets/img/NoPraise.svg" />
        <br> No praise left yet for {{userProfile.firstName}}. Be the first and start a movement!
      </p>
      <!-- <br/> -->
      <button class="btn-frankli-round btn-frankli-green-inverted" (click)="givePraise()">Praise
        {{userProfile.firstName}}</button>
    </div>
  </div>
  <div *ngIf="praiseList.length === 0 && user.id === userProfile.id && !loading">
    <div class="header">
      <h4 class="title">Your praise
        <span [routerLink]="'/feedback/history/praise/profile/' + userProfile.id" [title]="'Edit Praise List'"
          class="fal fa-edit frankli-edit expand-on-hover"></span>
      </h4>
    </div>
    <hr class="less-padding" />
    <div class="content text-center">
      <p>
        <img class="icon-padding new-icon-height" src="/assets/img/WaitingPraise.svg" />
        <br> All is quiet here at the minute
      </p>
    </div>
  </div>
  <div *ngIf="!loading && user.id === userProfile.id && userProfile !== undefined ">
    <hr class="less-padding" />
    <div class="content">
      <div class="row">
        <div class="col-sm-9 col-md-8 col-lg-9 p-top-align">
          <span *ngIf="user.id === userProfile.id">Do you know someone who deserves some recognition for their hard
            work, support, mentoring or just a good person?</span>
        </div>
        <div class="col-sm-3 col-md-4 col-lg-3">
          <button *ngIf="user.id === userProfile.id" class="btn-frankli-round btn-frankli-green-inverted pull-right"
            [routerLink]="'feedback/give/praise'" [queryParams]="{source: 'profile'}">Praise someone</button>
          <button *ngIf="user.id !== userProfile.id" class="btn-frankli-round btn-frankli-green-inverted pull-right"
            (click)="givePraise()"> Praise {{userProfile.firstName}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- On error -->
<div class="row" *ngIf="error === true">
  <div class="col-md-12">
    <div class="header">
      <h4 class="title text-center">Error</h4>
    </div>
    <div class="content">
      <div class="row">
        <div class="col-12 text-center">
          <img src="/assets/img/Error.svg" />
          <br> Error fetching praise
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Reaction Template -->
<ng-template #reactionTemplate let-praise='praise'>
  <div (mouseenter)="mouseInPop = true" (mouseleave)="mouseInPop = false">
    <span class="clickable expand-on-hover fa-thumbs-up like-icon" title="Like" [ngClass]="userHasReacted(praise, eReactionType.LIKE) ? 'fas' : 'fal'" (click)="toggleReaction(praise, eReactionType.LIKE)"></span>
    <span class="clickable expand-on-hover fa-hands-wash celebrate-icon" title="Celebrate" [ngClass]="userHasReacted(praise, eReactionType.CELEBRATE) ? 'fas' : 'fal'" (click)="toggleReaction(praise, eReactionType.CELEBRATE)"></span>
    <span class="clickable expand-on-hover fa-heart love-icon" title="Love" [ngClass]="userHasReacted(praise, eReactionType.LOVE) ? 'fas' : 'fal'" (click)="toggleReaction(praise, eReactionType.LOVE)"></span>
  </div>
</ng-template>
<!-- Reaction user modal -->
<app-modal #reactionUserModal [background]="'light'" [width]="'20%'">
  <div class="modal-body-content">
    <h5 class="text-center">Reactions</h5>
    <!-- Tabs -->
    <ul class="nav nav-justified text-center">
      <li title="All" class="nav-link clickable tab-option" [class.tab-selected]="modalTab === eModalTab.ALL" (click)="changeTab(eModalTab.ALL)">
        <span>All {{ reactionUsers.length }}</span>
      </li>
      <li title="Like" class="nav-link clickable tab-option like-icon" [class.tab-selected]="modalTab === eModalTab.LIKE" (click)="changeTab(eModalTab.LIKE)">
        <span class="fas fa-thumbs-up"></span>
        <span> {{ getReactionUsersForType(eReactionType.LIKE).length }}</span>
      </li>
      <li title="Celebrate" class="nav-link clickable tab-option celebrate-icon" [class.tab-selected]="modalTab === eModalTab.CELEBRATE" (click)="changeTab(eModalTab.CELEBRATE)">
        <span class="fas fa-hands-wash"></span>
        <span> {{ getReactionUsersForType(eReactionType.CELEBRATE).length}}</span>
      </li>
      <li title="Love" class="nav-link clickable tab-option love-icon" [class.tab-selected]="modalTab === eModalTab.LOVE" (click)="changeTab(eModalTab.LOVE)">
        <span class="fas fa-heart"></span>
        <span> {{ getReactionUsersForType(eReactionType.LOVE).length }}</span>
      </li>
    </ul>
    <div class="user-reaction-list">
      <div class="m-top-10" *ngFor="let reactionUser of reactionUsersDisplay">
        <span class="owner-img">
          <app-profile-photo class="profile-picture" [src]="reactionUser.user.imageUrl"></app-profile-photo>
        </span>
        <span class="lh-40 m-left-5">{{reactionUser.user.firstName}} {{reactionUser.user.lastName}}</span>
      </div>
      <div *ngIf="reactionUsersDisplay.length === 0">
        <div class="p-top-20 p-bottom-20 text-center">
          <span>No reactions</span>
        </div>
      </div>
    </div>
  </div>
</app-modal>