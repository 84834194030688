<div>
  <ul class="nav nav-justified noselect">
    <li>
      <a class="nav-link clickable" [class.tab-selected]="tabOpen==ePageTabs.CONTACT"
         (click)="switchTab(ePageTabs.CONTACT)">
        Contact Details
      </a>
    </li>
    <li>
      <a class="nav-link clickable" [class.tab-selected]="tabOpen==ePageTabs.PERSONAL"
         (click)="switchTab(ePageTabs.PERSONAL)">
        Personal Interests
      </a>
    </li>
    <li>
      <a class="nav-link clickable" [class.tab-selected]="tabOpen==ePageTabs.PROFESSIONAL"
         (click)="switchTab(ePageTabs.PROFESSIONAL)">
        Professional Interests
      </a>
    </li>
    <li>
      <a class="nav-link">
        <div class="text-right">
          <span class="fal fa-2x fa-chevron-right clickable expand-rotate rotate-icon"
                [class.rotate-90]="tabOpen!=ePageTabs.NONE" (click)="toggleExpand()"></span>
        </div>
      </a>
    </li>
  </ul>

  <div *ngIf="tabOpen!=ePageTabs.NONE">
    <div class="row info-box" *ngIf="tabOpen==ePageTabs.CONTACT">
      <div class="content">
        <div class="row">
          <div class="col-md-3 text-left user-info">
            <label>Role</label>
          </div>
          <div class="col-md-9 text-left user-info" [class.competency-popup]="globals.hasFeature(eFeature.COMPETENCIES)">
            <ng-container *ngIf="userProfile.position; else noPosition">
              <span #positionText>
                {{userProfile.position.name}}
                {{(userProfile.position.grade.trim().length > 0 && globals.hasFeature(eFeature.GRADES)) ? '(' + userProfile.position.grade + ')' : ''}}
              </span>
              <ng-container *ngIf="globals.hasFeature(eFeature.COMPETENCIES)">
                <div class="competency-popup-pointer" [style.left]="((positionText.offsetWidth/2)+5)+'px'">
                </div>
                <div class="competency-popup-window">
                  <ng-container *ngIf="userProfile.position.competencies.length > 0">
                    <h3 class="m-top-0">
                      Competencies for {{userProfile.position.name}}
                      {{(userProfile.position.grade.trim().length > 0 && globals.hasFeature(eFeature.GRADES)) ? '(' + userProfile.position.grade + ')' : ''}}
                    </h3>
                  </ng-container>
                  <ng-container *ngFor="let competency of userProfile.position.competencies; let i = index">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="popup-competency-name">{{competency.name}}</div>
                        <div class="popup-competency-description">
                          <app-rich-display [text]="competency.description"></app-rich-display>
                        </div>
                        <hr *ngIf="i !== (userProfile.position.competencies.length - 1)"/>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="userProfile.position.competencies.length === 0">
                    <div class="popup-competency-empty text-center">This position has no competencies
                      assigned
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
            <ng-template #noPosition>
              Position Pending 
            </ng-template>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 text-left user-info">
            <label>Manager</label>
          </div>
          <div class="col-md-9 text-left user-info">
            <span *ngIf="userProfile.managerId !== userProfile.id; else noManager" [routerLink]="'/profile/' + userProfile.managerId" class="text-capitalize clickable frankli-view" [title]="userProfile.managerName | titlecase">{{userProfile.managerName}}</span>
            <ng-template #noManager>
              <span class="no-manager noselect">{{userProfile.managerName}}</span>
            </ng-template>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 text-left user-info">
            <label>Email</label>
          </div>
          <div class="col-md-9 text-left user-info">
            <a href="mailto:{{userProfile.email}}">{{userProfile.email}}</a>
          </div>
        </div>
        <div class="row" *ngIf="userProfile.twitterHandle">
          <div class="col-md-3 text-left user-info">
            <label>Twitter</label>
          </div>
          <div class="col-md-9 text-left user-info">
            <a href="https://twitter.com/{{userProfile.twitterHandle}}">@{{userProfile.twitterHandle}}</a>
          </div>
        </div>
        <div class="row" *ngIf="userProfile.birthdayReminder && userProfile.dateOfBirth">
          <div class="col-md-3 text-left user-info">
            <label>Birthday</label>
          </div>
          <div class="col-md-9 text-left user-info">
            {{userProfile.dateOfBirth | date:"dd MMM"}}
          </div>
        </div>
        <div class="row" *ngIf="userProfile.phoneNumber">
          <div class="col-md-3 text-left user-info">
            <label>Phone</label>
          </div>
          <div class="col-md-9 text-left user-info">
            {{userProfile.phoneNumber}}
          </div>
        </div>
        <div class="row" *ngIf="userProfile.preferredContactMethod">
          <div class="col-md-3 text-left user-info">
            <label>Contact Me</label>
          </div>
          <div class="col-md-9 text-left user-info" [ngSwitch]="userProfile.preferredContactMethod">
            <span *ngSwitchCase="ePreferredContactMethod.NONE">No preference</span>
            <span *ngSwitchCase="ePreferredContactMethod.IN_PERSON">In Person</span>
            <span *ngSwitchCase="ePreferredContactMethod.EMAIL">By Email</span>
            <span *ngSwitchCase="ePreferredContactMethod.PHONE">By Phone</span>
            <span *ngSwitchCase="ePreferredContactMethod.TEXT">By Text</span>
            <span *ngSwitchCase="ePreferredContactMethod.LINKEDIN">On LinkedIn</span>
            <span *ngSwitchCase="ePreferredContactMethod.MICROSOFT_TEAMS">On Microsoft Teams</span>
            <span *ngSwitchCase="ePreferredContactMethod.SLACK">On Slack</span>
            <span *ngSwitchCase="ePreferredContactMethod.TWITTER">On Twitter</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="tabOpen==ePageTabs.PERSONAL">
      <!-- Personal Interests -->
      <div class="col-md-12" *ngIf="(personalInterests.length > 0) && !loading">
        <div class="row frankli-table frankli-table-header frankli-table-header-large noselect  ">
          <div class="col-sm-3 p-left-10">Interest Name</div>
          <div class="col-sm-9">People who share this interest</div>
        </div>
        <ng-container *ngFor="let pi of personalInterests; index as i">
          <div class="row clickable interest-height" *ngIf="i < displayCount">
            <div class="col-sm-3">
              <a class="interest-link" routerLink="connect/socialise/interest/{{pi.interest.id}}" [queryParams]="{source: 'profile'}">{{pi.interest.name}}</a>
            </div>
            <div class="col-sm-9 text-nowrap ellipsis-text similar-overflow">
              <ng-container *ngFor="let u of pi.users; index as i">
                <span class="img-similar safari-transform-fix" (click)="navigateToUser(u.id)">
                  <app-profile-photo
                  *ngIf="i < 11"
                  [src]="u.imageUrl"
                  [title]="(u.firstName + ' ' + u.lastName) | titlecase" 
                  [routerLink]="'/profile/'+u.id"
                  ></app-profile-photo>
                </span>
              </ng-container>
              <span *ngIf="pi.users.length > 10" class="img-similar safari-transform-fix similar-overflow-icon">
                +{{pi.users.length - 10}}
              </span>
            </div>
          </div>
        </ng-container>
        <div class="row text-center" *ngIf="personalInterests.length > displayCount">
          <button class="btn-frankli-round btn-frankli-green-inverted btn-frankli-small btn-load-more" (click)="loadMore()">Load more</button>
        </div>
      </div>
      <div class="content text-center" *ngIf="(personalInterests.length == 0) && !loading">
        <p>
          <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg"/>
          <br> No personal interests selected <br>
          <button *ngIf="user.id == userProfile.id" class="btn-frankli-round btn-frankli-green btn-frankli-small m-top-10"
                  [routerLink]="['/profile/edit/personal-interests']">Select interests</button>
        </p>
      </div>
      <div class="col-md-12 text-center" *ngIf="loading">
        <div class="row">
          <div class="col-xs-12 text-center">
            <span class="fal fa-3x fa-spin fa-spinner fa-fw"></span>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="tabOpen==ePageTabs.PROFESSIONAL">
      <!-- Professional Interests -->
      <div class="col-md-12" *ngIf="(professionalInterests.length > 0) && !loading">
        <div class="row frankli-table frankli-table-header frankli-table-header-large noselect">
          <div class="col-sm-3 p-left-10">Interest Name</div>
          <div class="col-sm-9">People who share this interest</div>
        </div>
        <ng-container *ngFor="let pi of professionalInterests; index as i">
          <div class="row clickable interest-height" *ngIf="i < displayCount">
            <div class="col-sm-3">
              <a  class="interest-link" routerLink="connect/socialise/interest/{{pi.interest.id}}" [queryParams]="{source: 'profile'}">{{pi.interest.name}}</a>
            </div>
            <div class="col-sm-9 text-nowrap ellipsis-text similar-overflow">
              <ng-container *ngFor="let u of pi.users; index as i">
                <span class="img-similar safari-transform-fix" (click)="navigateToUser(u.id)">
                  <app-profile-photo
                  *ngIf="i < 11"
                  [src]="u.imageUrl"
                  [title]="(u.firstName + ' ' + u.lastName) | titlecase" 
                  [routerLink]="'/profile/'+u.id"
                  ></app-profile-photo>
                </span>
              </ng-container>
              <span *ngIf="pi.users.length > 10" class="img-similar safari-transform-fix similar-overflow-icon">
                +{{pi.users.length - 10}}
              </span>
            </div>
          </div>
        </ng-container>
        <div class="row text-center" *ngIf="professionalInterests.length > displayCount">
          <button class="btn-frankli-round btn-frankli-green-inverted btn-frankli-small btn-load-more" (click)="loadMore()">Load more</button>
        </div>
      </div>
      <div class="content text-center" *ngIf="(professionalInterests.length == 0) && !loading">
        <p>
          <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg"/>
          <br> No professional interests selected <br>
          <button *ngIf="user.id == userProfile.id" class="btn-frankli-round btn-frankli-green btn-frankli-small m-top-10"
                  [routerLink]="['/profile/edit/professional-interests']">Select interests</button>
        </p>
      </div>
      <div class="col-md-12 text-center" *ngIf="loading">
        <div class="row">
          <div class="col-xs-12 text-center">
            <span class="fal fa-3x fa-spin fa-spinner fa-fw"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>