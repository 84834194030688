import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { DynamicFormQuestionComponent } from '@app/shared/survey-components/dynamic-form/dynamic-form-question/dynamic-form-question.component';
import { DynamicFormComponent } from '@app/shared/survey-components/dynamic-form/dynamic-form.component';
import { Angulartics2Module } from 'angulartics2';
import { SharedModule } from 'app/shared/shared.module';
import { ExpectationsScaleEditComponent } from 'app/shared/survey-components/expectations-scale-edit/expectations-scale-edit.component';
import { FivePointScaleEditComponent } from 'app/shared/survey-components/five-point-scale-edit/five-point-scale-edit.component';
import { PerformerScaleEditComponent } from 'app/shared/survey-components/performer-scale-edit/performer-scale-edit.component';
import { PerformerScaleComponent } from 'app/shared/survey-components/performer-scale/performer-scale.component';
import { EmotionScaleComponent } from './emotion-scale/emotion-scale.component';
import { EnpsScaleComponent } from './enps-scale/enps-scale.component';
import { ExpectationsScaleComponent } from './expectations-scale/expectations-scale.component';
import { FivePointScaleComponent } from './five-point-scale/five-point-scale.component';
import { LikertScaleComponent } from './likert-scale/likert-scale.component';
import { MultiChoiceComponent } from './multi-choice/multi-choice.component';
import { SingleChoiceComponent } from './single-choice/single-choice.component';
import { TenScaleComponent } from './ten-scale/ten-scale.component';

// NB: Update this when you add a new component
const components = [
    EmotionScaleComponent,
    LikertScaleComponent,
    TenScaleComponent,
    MultiChoiceComponent,
    SingleChoiceComponent,
    PerformerScaleComponent,
    PerformerScaleEditComponent,
    ExpectationsScaleComponent,
    ExpectationsScaleEditComponent,
    FivePointScaleComponent,
    FivePointScaleEditComponent,
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    EnpsScaleComponent

];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        Angulartics2Module,
        SharedModule,
        ReactiveFormsModule,
        PipesModule
    ],
    declarations: components,
    exports: components
})
export class SurveyFormComponentsModule { }
