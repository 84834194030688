import { OneToOneRecommendedTalkingPoint } from '@app/models/one-to-one/one-to-one-recommended-talking-point.model';

export const mockMentoringTemplateTalkingPoints: OneToOneRecommendedTalkingPoint[] = [
    {
        id: 1,
        companyId: 1,
        text: 'What are your short, medium & long-term career goals?',
        orderIndex: 1
    },
    {
        id: 2,
        companyId: 1,
        text: 'What’s working in your career & what areas are you looking to improve?',
        orderIndex: 2
    },
    {
        id: 3,
        companyId: 1,
        text: 'What ideas have you developed to help you overcome challenges and meet your goals?',
        orderIndex: 3
    },
    {
        id: 4,
        companyId: 1,
        text: 'What areas do you feel comfortable addressing on your own and what areas require more support?',
        orderIndex: 4
    },
    {
        id: 5,
        companyId: 1,
        text: 'How can I help you achieve your goals?',
        orderIndex: 5
    },
];

export const mockCoachingTemplateTalkingPoints: OneToOneRecommendedTalkingPoint[] = [
    {
        id: 6,
        companyId: 1,
        text: 'Why is this an area of interest to you?',
        orderIndex: 1
    },
    {
        id: 7,
        companyId: 1,
        text: 'What have you done so far to increase your knowledge/awareness of this area?',
        orderIndex: 2
    },
    {
        id: 8,
        companyId: 1,
        text: 'What areas would you like to focus on?',
        orderIndex: 3
    },
    {
        id: 9,
        companyId: 1,
        text: 'What would you like to have achieved by the end of these sessions?',
        orderIndex: 4
    },
    {
        id: 10,
        companyId: 1,
        text: 'What additional support do you need from me?',
        orderIndex: 5
    },
];

export const mockEmployeeLedTemplateTalkingPoints: OneToOneRecommendedTalkingPoint[] = [
    {
        id: 11,
        companyId: 1,
        text: 'What\'s the most important thing we need to discuss today?',
        orderIndex: 1
    },
    {
        id: 12,
        companyId: 1,
        text: 'What obstacles are you encountering right now?',
        orderIndex: 2
    },
    {
        id: 13,
        companyId: 1,
        text: 'What are the most important things you will focus on before we meet next?',
        orderIndex: 3
    }
];

export const mockPerformanceReviewTemplateTalkingPoints: OneToOneRecommendedTalkingPoint[] = [
    {
        id: 14,
        companyId: 1,
        text: 'From your perspective, how do you feel you have progressed during this review period?',
        orderIndex: 1
    },
    {
        id: 15,
        companyId: 1,
        text: 'Looking forward, what are some priorities we are going to work on?',
        orderIndex: 2
    },
    {
        id: 16,
        companyId: 1,
        text: 'What\'s the most important thing I can  do differently for you as your manager?',
        orderIndex: 3
    }
];

export const mockManagerLedTemplateTalkingPoints: OneToOneRecommendedTalkingPoint[] = [
    {
        id: 17,
        companyId: 1,
        text: 'Do you feel you\'re getting enough feedback? Why/why not?',
        orderIndex: 1
    },
    {
        id: 18,
        companyId: 1,
        text: 'How could I do a better job communicating with you?',
        orderIndex: 2
    },
    {
        id: 19,
        companyId: 1,
        text: 'Which areas would you like more or less direction from me on your work?',
        orderIndex: 3
    },
    {
        id: 20,
        companyId: 1,
        text: 'What would you like to see change about these discussions? How could we make them more useful for you?',
        orderIndex: 4
    },
];

export const mockCareerFocusedTemplateTalkingPoints: OneToOneRecommendedTalkingPoint[] = [
    {
        id: 21,
        companyId: 1,
        text: 'What are the new things you learned at work lately? What are the areas you want to learn about?',
        orderIndex: 1
    },
    {
        id: 22,
        companyId: 1,
        text: 'Do you feel like we\'re helping you advance your career? What can I be doing to help grow your career?',
        orderIndex: 2
    },
    {
        id: 23,
        companyId: 1,
        text: 'What is one aspect of your job you would like more help or coaching with?',
        orderIndex: 3
    },
    {
        id: 24,
        companyId: 1,
        text: 'A year from now, what do you want to have accomplished?',
        orderIndex: 4
    },
];

export const mockJobSatisfactionTemplateTalkingPoints: OneToOneRecommendedTalkingPoint[] = [
    {
        id: 25,
        companyId: 1,
        text: 'Do you feel like you\'re growing in your role? What makes you say that?',
        orderIndex: 1
    },
    {
        id: 26,
        companyId: 1,
        text: 'What feedback/praises have you been getting about your current priorities?',
        orderIndex: 2
    },
    {
        id: 27,
        companyId: 1,
        text: 'What\'s a recent situation you wish you handled differently? What would you change?',
        orderIndex: 3
    },
    {
        id: 28,
        companyId: 1,
        text: 'What\'s an area of your work you want to improve? How can we work together on that?',
        orderIndex: 4
    },
    {
        id: 29,
        companyId: 1,
        text: 'Can you give some specific examples of when you enjoyed working here the most?',
        orderIndex: 5
    }
];

export const mockTeamAndCompanyTemplateTalkingPoints: OneToOneRecommendedTalkingPoint[] = [
    {
        id: 30,
        companyId: 1,
        text: 'How would you describe how work is shared among team members?',
        orderIndex: 1
    },
    {
        id: 31,
        companyId: 1,
        text: 'How would you rate our communication as a team?',
        orderIndex: 2
    },
    {
        id: 32,
        companyId: 1,
        text: 'How would you say we\'re doing at working together as a team? What makes you say that?',
        orderIndex: 3
    },
    {
        id: 33,
        companyId: 1,
        text: 'Who inspires you in the team? Whose opinions do you respect? What have they done?',
        orderIndex: 4
    },
    {
        id: 34,
        companyId: 1,
        text: 'What\'s the biggest opportunity you see that we aren\'t thinking about?',
        orderIndex: 5
    }
];

export const mockWorkLifeBalanceTemplateTalkingPoints: OneToOneRecommendedTalkingPoint[] = [
    {
        id: 35,
        companyId: 1,
        text: 'What is an ideal, productive day at work for you? Walk me through the day…',
        orderIndex: 1
    },
    {
        id: 36,
        companyId: 1,
        text: 'What makes you excited and motivated to work on a particular project?',
        orderIndex: 2
    },
    {
        id: 37,
        companyId: 1,
        text: 'What part of your work routine do you find is working best? What area do you want to improve?',
        orderIndex: 3
    },
    {
        id: 38,
        companyId: 1,
        text: 'What\'s one thing we could change about work for you that would improve your personal life?',
        orderIndex: 4
    },
];

export const mockGoalOwnersTemplateTalkingPoints: OneToOneRecommendedTalkingPoint[] = [
    {
        id: 39,
        companyId: 1,
        text: 'What progress have we made since the last meeting?',
        orderIndex: 1
    },
    {
        id: 40,
        companyId: 1,
        text: 'In terms of the Objective and the key results, are they still relevant?',
        orderIndex: 2
    },
    {
        id: 41,
        companyId: 1,
        text: 'How likely are we achieve this goal?',
        orderIndex: 3
    },
];

export const mockOneToOneRecommendedTalkingPoints: OneToOneRecommendedTalkingPoint[] = [
    ...mockMentoringTemplateTalkingPoints,
    ...mockCoachingTemplateTalkingPoints,
    ...mockEmployeeLedTemplateTalkingPoints,
    ...mockPerformanceReviewTemplateTalkingPoints,
    ...mockManagerLedTemplateTalkingPoints,
    ...mockCareerFocusedTemplateTalkingPoints,
    ...mockJobSatisfactionTemplateTalkingPoints,
    ...mockTeamAndCompanyTemplateTalkingPoints,
    ...mockWorkLifeBalanceTemplateTalkingPoints,
    ...mockGoalOwnersTemplateTalkingPoints
];