import { Component, OnInit } from '@angular/core';
import { AuthAPIService } from '../auth/auth.api.service';

@Component({
  selector: 'app-logout-component',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css'],
})
export class LogoutComponent implements OnInit {

  constructor(
    private authService: AuthAPIService,
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.authService.logout();
    }, 1000);
  }
}
