import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Globals} from "@app/shared/globals/globals";

@Injectable()
export class FeatureLockedGuard implements CanActivate {

  constructor(
    private globals: Globals,
    private router: Router,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.globals.company.featureLocked) {
      this.router.navigate(['/feature-locked']).then();
      return false
    }
    return true;
  }
}