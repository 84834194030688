import { Component, OnInit } from '@angular/core';
import { StatusAPIService } from 'app/shared/api/status.api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { WebsocketService } from 'app/shared/api/websocket.service';

@Component({
  selector: 'app-overlay-no-internet-connection-component',
  templateUrl: './overlay-no-internet-connection.component.html',
  styleUrls: ['./overlay-no-internet-connection.component.css']
})
export class OverlayNoInternetConnectionComponent implements OnInit {

  constructor(
    private statusAPIService: StatusAPIService,
    private WebsocketService: WebsocketService
  ) { }

  ngOnInit() {
    this.WebsocketService.deactivate();
    this.checkStatus();
  }

  private checkStatus() {
    setTimeout(() => {
      this.statusAPIService.getStatus().subscribe(() => {
        // server back online
        location.reload();
      }, (failure: HttpErrorResponse) => {
        this.checkStatus();
      })
    }, 5000);
  }
}
