import { User } from 'app/models/user/user.model';
import { PreferredContactMethod } from './preferred-contact-method.model';

export class UserPersonalDetails {
  dateOfBirth: Date | null;
  birthdayReminder: boolean;
  preferredContactMethod: PreferredContactMethod;
  linkedIn: string;
  phoneNumber: string;
  teamsEmail: string;
  twitterHandle: string;

  constructor(user: User) {
    this.dateOfBirth = user.dateOfBirth;
    this.birthdayReminder = user.birthdayReminder;
    this.preferredContactMethod = user.preferredContactMethod;
    this.linkedIn = user.linkedIn;
    this.phoneNumber = user.phoneNumber;
    this.teamsEmail = user.teamsEmail;
    this.twitterHandle = user.twitterHandle;
  }
}
