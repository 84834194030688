import { CompetencyCategory } from '@app/models/competencies/competency-category.model';

export const mockCompetencyCategories: CompetencyCategory[] = [
    {
        id: 1,
        name: "Company Values"
    },
    {
        id: 2,
        name: "Leadership"
    }
];