import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import {Inject, Injectable} from '@angular/core';
import { Globals } from '../globals/globals';
import { CompanyFeatures } from 'app/models/company.model';
import {DOCUMENT} from '@angular/common';

@Injectable()
export class FeatureGuard implements CanActivate {

  constructor(
    private globals: Globals,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const feature = <CompanyFeatures>route.data['feature'];
    if (this.globals.hasFeature(feature)) {
      return true;
    } else {
      if (this.globals.company.featureLocked) {
        switch (route.data.feature) {
          case CompanyFeatures.ORG_CHART:
          case CompanyFeatures.PEOPLE_DIRECTORY:
            this.document.location.href = 'https://frankli.io/people-directory/';
            break;
          case CompanyFeatures.FEEDBACK:
            this.document.location.href = 'https://frankli.io/feedback/';
            break;
          case CompanyFeatures.GOALS:
            this.document.location.href = 'https://frankli.io/okr-goals/';
            break;
          case CompanyFeatures.ONE_TO_ONE:
            this.document.location.href = 'https://frankli.io/one-to-ones/';
            break;
          case CompanyFeatures.SURVEYS:
            this.document.location.href = 'https://frankli.io/people-engagement-surveys/';
            break;
          case CompanyFeatures.CONNECT:
            this.document.location.href = 'https://frankli.io/connect/';
            break;
          case CompanyFeatures.EVALUATION_CYCLES:
            this.document.location.href = 'https://frankli.io/reviews/';
            break;
          default:
            this.router.navigate(['/feature-locked']).then();
        }
        return false;
      }
      this.router.navigate(['/not-enabled']).then();
      return false;
    }
  }
}
