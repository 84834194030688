import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserAPIService } from '../api/user.api.service';
import { Globals } from '../globals/globals';
import { environment } from 'environments/environment';

interface State {
  loading: boolean,
  error: boolean,
  errorMessage: string,
  submitted: boolean,
  complete: boolean
}

@Component({
  selector: 'app-reset-password-new',
  templateUrl: './reset-password-new.component.html',
  styleUrls: ['./reset-password-new.component.css']
})
export class ResetPasswordNewComponent implements OnInit {
  state: State;

  form: FormGroup;
  password: AbstractControl;
  confirmPassword: AbstractControl;
  minimumPasswordLength = environment.password.minimumLength;
  maximumPasswordLength = environment.password.maximumLength;

  constructor(
    private formBuilder: FormBuilder,
    private userAPIService: UserAPIService,
    private globals: Globals,
    private router: Router
  ) {
    this.state = {
      loading: false,
      error: false,
      errorMessage: '',
      submitted: false,
      complete: false
    };

    this.form = this.setupForm();
    this.password = this.form.controls.password;
    this.confirmPassword = this.form.controls.confirmPassword;
  }

  ngOnInit() {
    if (this.globals.user.passwordReset === false) {
      this.router.navigateByUrl('/dashboard');
    }
  }

  setupForm(): FormGroup {
    return this.formBuilder.group({
      password: ['', [
        Validators.required,
        Validators.minLength(environment.password.minimumLength),
        Validators.maxLength(environment.password.maximumLength)
      ]],
      confirmPassword: ['', [
        Validators.required,
        Validators.minLength(environment.password.minimumLength),
        Validators.maxLength(environment.password.maximumLength)
      ]],
    });
  }

  resetForm(): void {
    this.state = {
      loading: false,
      error: false,
      errorMessage: '',
      submitted: false,
      complete: false
    };

    this.form = this.setupForm();
    this.password = this.form.controls.password;
    this.confirmPassword = this.form.controls.confirmPassword;
  }

  formSubmit() {
    this.state.submitted = true;
    this.state.errorMessage = '';
    this.state.error = false;
    if (this.form.valid === true) {
      if (this.password.value !== this.confirmPassword.value) {
        this.state.error = true;
        this.state.errorMessage = `Passwords don't match.`;
      } else {
        this.state.loading = true;
        this.state.error = false;
        this.state.errorMessage = '';
        this.userAPIService.updatePasswordOnReset(this.password.value).subscribe(response => {
          this.userAPIService.getUserInit().subscribe(ui => {
          }, (failure: HttpErrorResponse) => {
            this.state.error = true;
            this.state.loading = false;
          });
          this.state.complete = true;
          this.state.loading = false;
        }, (failure: HttpErrorResponse) => {
          this.state.error = true;
          this.state.loading = false;
        });
      }
    }
  }

}
