import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateJoined'
})
export class DateJoinedPipe extends DatePipe implements PipeTransform {
  previousValue!: Date;
  resultCached!: string;

  transform(value: Date, args?: any): any {
    // Skip calculation if value hasn't changed
    if ((value === this.previousValue) && this.resultCached) {
      return this.resultCached;
    }
    this.previousValue = value;

    // TODO: Maybe use moment: could be limiting
    const year = (1000 * 60 * 60 * 24 * 365);
    const month = (1000 * 60 * 60 * 24 * 30);
    const week = (1000 * 60 * 60 * 24 * 7);
    const day = (1000 * 60 * 60 * 24);

    const today = new Date();
    const startDate = new Date(value);
    const difference = today.getTime() - startDate.getTime();
    const diffDays = (difference);
    let result = 'ERROR';

    // future
    if (difference < 0) {
      result = 'Starting soon'
    }
    // years
    if (difference > year) {
      result = this.shanesSuperCoolDateAlgorithm(difference, year, 'year');
    }
    // months
    if (difference > month) {
      result = this.shanesSuperCoolDateAlgorithm(difference, month, 'month');
    }
    // weeks
    if (difference > week) {
      result = this.shanesSuperCoolDateAlgorithm(difference, week, 'week');
    }
    // days
    if (difference > day) {
      result = this.shanesSuperCoolDateAlgorithm(difference, day, 'day');
    }
    // today
    if (difference < day) {
      result = 'Today';
    }

    this.resultCached = result;
    return result;
  }

  shanesSuperCoolDateAlgorithm(timespan: number, timeunit: number, unitname: string) {
    let spans;
    spans = Math.floor((timespan / timeunit));
    if (spans === 1) {
      return `1 ${unitname} ago`;
    } else {
      return `${spans} ${unitname}s ago`;
    }
  }
}
