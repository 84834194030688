<router-outlet></router-outlet>
<ng-container *ngIf="route.children.length === 0">
  <div class="row btn-create-position">
    <div class="col-md-12 text-right">
      <button class="btn-frankli-round btn-frankli-green" (click)="modalCreate.show()" angulartics2On="click"
        angularticsAction="ShowGoalsCreateModal" angularticsCategory="Goals" [attr.data-intercom-target]="'Create A Goal'">Create a Goal</button>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="row">
          <div class="col-xs-12">
            <span class="menu-icon fa-2x fal fa-bars text-center hidden-sm hidden-md hidden-lg clickable"
              data-toggle="collapse" data-target="#nav-tabs"
              style="box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1)"></span>
            <ul id="nav-tabs" class="nav nav-justified collapse open-on-md">
              <li>
                <a [ngClass]="{'text-info': (filter === 'all') }" (click)="changeFilter('all')" class="nav-link">
                  All
                </a>
              </li>
              <li>
                <a [ngClass]="{'text-info': (filter === 'active') }" (click)="changeFilter('active')" class="nav-link">
                  Active
                </a>
              </li>
              <li>
                <a [ngClass]="{'text-info': (filter === 'complete') }" (click)="changeFilter('complete')"
                  class="nav-link">
                  Complete
                </a>
              </li>
              <li>
                <a [ngClass]="{'text-info': (filter === 'archived') }" (click)="changeFilter('archived')"
                  class="nav-link">
                  Archived
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="p-bottom-0 p-top-coltoggle" *ngIf="state.loading === false && state.error === false">
          <app-goals-all-table-component [goals]="goals"></app-goals-all-table-component>
        </div>

        <!-- LOADING -->
        <div class="content" *ngIf="state.loading === true && state.error === false">
          <div class="row m-top-15 m-bottom-15">
            <div class="col-md-12">
              <div class=" text-center">
                <span class="fal fa-spin fa-spinner fa-3x fa-fw"></span>
              </div>
            </div>
          </div>
        </div>

        <!-- ERROR -->
        <div class="content" *ngIf="state.loading === false && state.error === true">
          <div class="row m-top-15 m-bottom-15">
            <div class="col-md-12">
              <div class="text-center" *ngIf="state.loading === false && state.error === true">
                <br>
                <img class="icon-padding new-icon-height" src="/assets/img/Error.svg" />
                <p>
                  <br>An error occurred while getting your goals</p>
                <span class="btn-frankli-round btn-frankli-green" [routerLink]="'/dashboard'">
                  Back to your dashboard?
                </span>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>

  <app-goal-modal #modalCreate>
    <div class="app-goal-modal-header">
      <app-goals-modal-header [modal]="modalCreate" [headerText]="'Create a new goal'"></app-goals-modal-header>
    </div>
    <div class="app-goal-modal-body">
      <app-goals-create [modal]="modalCreate" [mode]="'CREATE'" [userId]="userId"></app-goals-create>
    </div>
    <div class="app-goal-modal-sidebar">
      <app-goals-modal-sidebar [modal]="modalCreate"></app-goals-modal-sidebar>
    </div>
  </app-goal-modal>
</ng-container>
