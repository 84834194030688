<div class="card outer-card">
  <ng-container *ngIf="user; else noUser;">
    <div class="card-header">
      <!-- Background colour -->
      <div class="background-colour" [style.background]="background"></div>
      <!-- Location -->
      <div class="location-display">
        <span class="fal fa-fw fa-map-marker-alt"></span>
        <span>
          <ng-container *ngIf="user.officeLocation; let location; else noLocation">
            <ng-container *ngIf="location !== nameWorkingRemotely; else noLocation">
              <span [title]="location.name">{{location.name}}</span>
            </ng-container>
          </ng-container>
          <ng-template #noLocation>
            No office location
          </ng-template>
        </span>
      </div>

      <div class="img-container" [style.border-color]="background" [title]="imgTitle">
        <app-profile-photo [src]="user.imageUrl" [alt]="user.firstName + ' ' + user.lastName"></app-profile-photo>
      </div>
    </div>
    <div class="card-body">
      <!-- Social links -->
      <div class="position-social-links">
        <!-- Preferred contact method -->
        <!-- <div>
          <span *ngIf="user.preferredContactMethod">Preferred: {{user.preferredContactMethod | underscoreToSpace | titlecase}}</span>
          <span>&nbsp;</span>
        </div> -->

        <!-- MS Teams -->
        <!--  && ((user.preferredContactMethod === ePreferredContactMethod.MICROSOFT_TEAMS) || (user.preferredContactMethod === ePreferredContactMethod.NONE)) -->
        <div class="d-inline-block contact-icon" *ngIf="user.teamsEmail" [tooltip]="teamsTooltip" (click)="openTeams($event, user.teamsEmail)"
          [class.preferred-contact]="user.preferredContactMethod === ePreferredContactMethod.MICROSOFT_TEAMS">
          <span class="expand-on-hover frankli-view fab fa-fw fa-microsoft"></span>
          <ng-template #teamsTooltip>
            <div class="text-nowrap">
              <div>
                <ng-container [ngTemplateOutlet]="requiredLabel" *ngIf="user.preferredContactMethod === ePreferredContactMethod.MICROSOFT_TEAMS"></ng-container>
              </div>
              <div>{{user.teamsEmail}}</div>
            </div>
          </ng-template>
        </div>

        <!-- Email -->
        <!--  && ((user.preferredContactMethod === ePreferredContactMethod.EMAIL) || (user.preferredContactMethod === ePreferredContactMethod.NONE)) -->
        <div class="d-inline-block contact-icon" *ngIf="user.email" [tooltip]="emailTooltip" (click)="openEmail($event, user.email)"
          [class.preferred-contact]="user.preferredContactMethod === ePreferredContactMethod.EMAIL">
          <span class="expand-on-hover frankli-view fas fa-fw fa-envelope"></span>
          <ng-template #emailTooltip>
            <div class="text-nowrap">
              <div>
                <ng-container [ngTemplateOutlet]="requiredLabel" *ngIf="user.preferredContactMethod === ePreferredContactMethod.EMAIL"></ng-container>
              </div>
              <div>{{user.email}}</div>
            </div>
          </ng-template>
        </div>

        <!-- Phone -->
        <!--  && ((user.preferredContactMethod === ePreferredContactMethod.PHONE) || (user.preferredContactMethod === ePreferredContactMethod.NONE) || (user.preferredContactMethod === ePreferredContactMethod.TEXT)) -->
        <div class="d-inline-block contact-icon" *ngIf="user.phoneNumber" [tooltip]="phoneTooltip" (click)="openPhone($event, user.phoneNumber)"
          [class.preferred-contact]="((user.preferredContactMethod === ePreferredContactMethod.PHONE) || (user.preferredContactMethod === ePreferredContactMethod.TEXT))">
          <span class="expand-on-hover frankli-view fas fa-fw fa-phone"></span>
          <ng-template #phoneTooltip>
            <div class="text-nowrap">
              <div>
                <ng-container [ngTemplateOutlet]="requiredLabel" *ngIf="((user.preferredContactMethod === ePreferredContactMethod.PHONE) || (user.preferredContactMethod === ePreferredContactMethod.TEXT))"></ng-container>
              </div>
              <div>{{user.phoneNumber}}</div>
            </div>
          </ng-template>
        </div>

        <span *ngIf="user.linkedIn || user.twitterHandle">|</span>

        <!-- LinkedIn -->
        <div class="d-inline-block contact-icon" *ngIf="user.linkedIn" [tooltip]="linkedInTooltip" (click)="openLinkedIn($event, user.linkedIn)"
          [class.preferred-contact]="user.preferredContactMethod === ePreferredContactMethod.LINKEDIN">
          <span class="expand-on-hover frankli-view fab fa-fw fa-linkedin"></span>
          <ng-template #linkedInTooltip>
            <div class="text-nowrap">
              <div>
                <ng-container [ngTemplateOutlet]="requiredLabel" *ngIf="user.preferredContactMethod === ePreferredContactMethod.LINKEDIN"></ng-container>
              </div>
              <div>{{user.linkedIn}}</div>
            </div>
          </ng-template>
        </div>

        <!-- Twitter -->
        <div class="d-inline-block contact-icon" *ngIf="user.twitterHandle" [tooltip]="twitterTooltip" (click)="openTwitter($event, user.twitterHandle)"
          [class.preferred-contact]="user.preferredContactMethod === ePreferredContactMethod.TWITTER">
          <span class="expand-on-hover frankli-view fab fa-fw fa-twitter"></span>
          <ng-template #twitterTooltip>
            <div class="text-nowrap">
              <div>
                <ng-container [ngTemplateOutlet]="requiredLabel"*ngIf="user.preferredContactMethod === ePreferredContactMethod.TWITTER"></ng-container>
              </div>
              <div>@{{user.twitterHandle}}</div>
            </div>
          </ng-template>
        </div>

        <ng-template #requiredLabel>
          <!-- <div class="preferred-label">(PREFERED)</div> -->
        </ng-template>

        <!-- TODO: Slack -->
      </div>

      <!-- Position and dept -->
      <div class="position-department-display">
        <!-- Position -->
        <span class="user-position">
          <ng-container *ngIf="user.position; let position; else noPosition">
            <span [title]="position.name">{{position.name}}</span>
            <span *ngIf="globals.hasFeature(eCompanyFeatures.GRADES) && position.grade">({{position.grade}})</span>
          </ng-container>
          <ng-template #noPosition>
            <span>No position</span>
          </ng-template>
        </span>

        <!-- Department -->
        <span class="user-department">
          <ng-container *ngIf="user.organisationalUnit; let department; else noDepartment">
            <span>, </span>
            <span [title]="department.name">{{department.name}}</span>
          </ng-container>
          <ng-template #noDepartment>
            <span>No {{companyWording.department | titlecase}}</span>
          </ng-template>
        </span>
      </div>

      <!-- Name -->
      <div class="name-display" [title]="user.firstName + ' ' + user.lastName">{{user.firstName}} {{user.lastName}}
      </div>
    </div>
  </ng-container>

  <ng-template #noUser>
    <div class="empty-state">
      Invalid user
    </div>
  </ng-template>
</div>