<div class="content">
  <ng-container *ngIf="innerCategories.length > 0; else noCategories">
    <div class="row">
      <!-- Categories -->
      <div class="col-md-3 noselect">
        <div class="interest-header">
          Categories
        </div>
        <div class="interest-category" *ngFor="let category of innerCategories" (click)="selectCategory(category)"
          [class.text-highltr]="selectedCategory && selectedCategory.name === category.name">
          {{category.name}}
          <!--<span *ngIf="categoryAmount(category) > 0" class="icon-number">{{categoryAmount(category)}}</span>-->
        </div>
      </div>

      <!-- Choices -->
      <div class="col-md-6 noselect">
        <div class="interest-header">
          Choices
        </div>
        <div class="scroll-area" *ngIf="selectedCategory">
          <div *ngFor="let interest of selectedCategory.interests; let i = index" [class.m-r]="i % 2 === 0"
            [class.m-l]="i % 2 === 1" class="interest-body interest-choice" (click)="addInterest(interest)">
            {{interest.name}}
          </div>

          <div *ngIf="selectedCategory.interests.length === 0" class="text-center m-top-25">
            <p>
              <img class="icon-padding new-icon-height" src="/assets/img/Empty.svg" />
              <br>
              No options left for this category
            </p>
          </div>
        </div>
      </div>

      <!-- Selected interests -->
      <div class="col-md-3 noselect">
        <div class="interest-header">
          Selected
        </div>
        <div class="scroll-area">
          <div *ngFor="let interest of innerInterests" class="interest-selected interest-body "
            (click)="removeInterest(interest)">
            {{interest.name}}
            <span class="fal fa-times interest-icon"></span>
          </div>
          <div class="text-center" *ngIf="innerInterests.length === 0">No Interests Selected
          </div>
        </div>
      </div>
    </div>

    <div class="text-center">
      <button class="btn-frankli-round btn-frankli-green" (click)="modal.show()">Submit new interest</button>
    </div>
  </ng-container>
  <ng-template #noCategories>
    <div class="p-top-15 p-bottom-15 text-center">
      <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
      <br />
      <span>Looks like no interests exist yet. Why not create one?</span>
      <br />
      <button class="btn-frankli-round btn-frankli-green m-top-10" (click)="modal.show()">Create a new interest</button>
    </div>
  </ng-template>

  <div class="row">
    <div class="col-xs-12">
      <hr class="hr-divider" />
    </div>
  </div>

  <div class="row m-top-10">
    <!-- Edit -->
    <div class="col-md-12" *ngIf="formType === 'EDIT'">
      <button *ngIf="innerInterests.length >= minimumInterests && loading === false" type="submit"
        class="btn-frankli-round btn-frankli-green pull-right" (click)="save()">Save
      </button>
      <button *ngIf="innerInterests.length >= minimumInterests && loading === true"
        class="btn-frankli-round btn-frankli-green pull-right">
        <span class="fal fa-spin fa-spinner"></span>
      </button>
      <button *ngIf="innerInterests.length < minimumInterests" type="button"
        class="btn-frankli-round btn-frankli-green pull-right">Select {{minimumInterests -
        innerInterests.length}} more interests to continue
      </button>
    </div>

    <!--  Sign up -->
    <div class="col-md-12" *ngIf="formType === 'SIGN_UP'">
      <button class="btn-frankli-round btn-frankli-gray pull-left" (click)="previous()">
        <i class="fal fa-arrow-circle-left icon-align"></i> Previous</button>

      <button *ngIf="step.current === 'INTERESTS_PERSONAL' && innerInterests.length >= minimumInterests" type="button"
        class="btn-frankli-round btn-frankli-green pull-right" (click)="next()">Next
        <i class="fal fa-arrow-circle-right icon-align"></i>
      </button>
      <button *ngIf="step.current === 'INTERESTS_PROFESSIONAL' && innerInterests.length >= minimumInterests"
        type="button" class="btn-frankli-round btn-frankli-green pull-right" (click)="complete()">
        Complete
        <i class="fal fa-check-circle icon-align frankli-view expand-on-hover"></i>
      </button>
      <button *ngIf="innerInterests.length < minimumInterests" type="button"
        class="btn-frankli-round btn-frankli-green pull-right">Select {{minimumInterests -
        innerInterests.length}} more interests to continue
      </button>
    </div>
  </div>
  <app-create-interest-modal #modal [categories]="innerCategories" [step]="type"></app-create-interest-modal>