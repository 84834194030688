import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Globals } from '../globals/globals';

@Injectable()
export class UnauthenticatedGuard implements CanActivate {

  constructor(
    private globals: Globals,
    private router: Router
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.globals.user === undefined) {
      return true;
    }

    this.router.navigateByUrl('');
    return false;
  }
}
