import {User} from 'app/models/user/user.model';
import {Address} from 'app/models/address.model';
import {OfficeLocation} from '../company.model';

export class UserLocationDetails {
  homeAddress: Address;
  officeLocation: OfficeLocation | null;

  constructor(user: User) {
    this.homeAddress = user.homeAddress;
    this.officeLocation = user.officeLocation;
  }
}
