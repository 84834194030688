import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GoalsAPIService } from 'app/shared/api/goals.api.service';
import { Goal, GoalType, GoalVisibility } from 'app/models/goals/goal.model';
import { Globals } from 'app/shared/globals/globals';
import { forkJoin, Observable, Subject } from 'rxjs';
import { GoalCreateService } from '../../goals-create/goals-create.service';
import { GoalsModalComponent } from '../goals-modal.component';
import { CompanyFeatures, OfficeLocation } from 'app/models/company.model';
import { FormControl } from '@angular/forms';
import { GoalTemplate } from '@app/models/goals/goal-template.model';
import { takeUntil } from 'rxjs/operators';
import { OrganisationalUnit } from '@app/models/organisational-unit.model';
import { CompanyAPIService } from '@app/shared/api/company.api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CompanyWording } from '@app/models/company/company-wording/company-wording.model';

interface GoalTypeDropdown {
  text: string,
  type: GoalType
}

interface PageGoal extends Goal {
  keyResultsShown: boolean;
}

interface PageGoalTemplate extends GoalTemplate {
  resultsShown: boolean;
}

interface DropdownState {
  loading: boolean;
  error: boolean;
}

@Component({
  selector: 'app-goals-modal-sidebar',
  templateUrl: './goals-modal-sidebar.component.html',
  styleUrls: ['./goals-modal-sidebar.component.css']
})
export class GoalsModalSidebarComponent implements OnInit, OnDestroy {
  private readonly ngUnsubscribe$: Subject<void> = new Subject<void>();
  @Input() modal !: GoalsModalComponent;

  eGoalType = GoalType;
  eFeature = CompanyFeatures;

  goalTypes: GoalTypeDropdown[];

  goals: PageGoal[];
  goalsDisplayed: PageGoal[];

  templates: PageGoalTemplate[];
  templatesDisplayed: PageGoalTemplate[];

  state: {
    goals: DropdownState
    templates: DropdownState
  }

  tipsList: Array<string> =  [
   
  ];

  eGoalVisibility = GoalVisibility;

  public menuToggles = {
    competencies: false,
    tips: false,
    goals: false,
    templates: false
  }

  goalSearch: FormControl;
  goalType: FormControl;
  goalDepartmentId: FormControl;
  goalSiteId: FormControl;

  templateSearch: FormControl;

  departments: Array<OrganisationalUnit> = new Array<OrganisationalUnit>();
  sites: Array<OfficeLocation> = new Array<OfficeLocation>();
  companyWording: CompanyWording;

  constructor(
    private goalsAPIService: GoalsAPIService,
    public globals: Globals,
    private goalCreateService: GoalCreateService,
    private companyAPIService: CompanyAPIService
  ) {
    this.companyWording = this.globals.company.companyWording;
    
    this.tipsList.push('When just starting out, having everyone own just one single objective has proven very successful.');
    this.tipsList.push(`A person or a ${this.companyWording.team.toLowerCase()} should have up to 3 objectives per planning period (for example, quarter).`);
    this.tipsList.push(`Objectives are always qualitative and aspirational. They are something that you, your ${this.companyWording.team.toLowerCase()}, or your organization aim to achieve.`);
    this.tipsList.push('Key Results are quantitative. They tell you if you have achieved your objective, so they should be measurable to avoid any doubt.');
    this.tipsList.push('We would recommend well defined goals have 3 key results.');
    this.goals = [];
    this.goalsDisplayed = [];

    this.templates = [];
    this.templatesDisplayed = [];

    this.goalTypes = [];

    this.goalSearch = new FormControl('', []);
    this.goalSearch.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => this.searchGoals());

    this.goalType = new FormControl(null, []);
    this.goalType.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => this.updateGoals());

    // Default Department Id
    const departmentId = (this.globals.user.organisationalUnit ? this.globals.user.organisationalUnit.id : null);
    this.goalDepartmentId = new FormControl(departmentId, []);
    this.goalDepartmentId.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => this.updateGoals());

    // Default Site Id
    const siteId = (this.globals.user.officeLocation ? this.globals.user.officeLocation.id : null);
    this.goalSiteId = new FormControl(siteId, []);
    this.goalSiteId.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => this.updateGoals());

    this.templateSearch = new FormControl('', []);
    this.templateSearch.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => this.searchTemplates())

    const defaultState = {
      loading: false,
      error: false
    }
    this.state = {
      goals: defaultState,
      templates: defaultState
    }
  }

  // #region - LIFECYCLE HOOKS
  ngOnInit() {
    // Default top dropdown to be open
    if (this.globals.hasFeature(CompanyFeatures.COMPETENCIES)) {
      this.menuToggles.competencies = true;
    } else {
      this.menuToggles.tips = true;
    }

    // Add options to goal types dropdown based on features enabled
    this.goalTypes.push({
      type: GoalType.PERSONAL_OPERATIONAL,
      text: 'Personal Developmental / Operational'
    });
    if (this.globals.user.organisationalUnit !== null) {
      this.goalTypes.push({
        type: GoalType.DEPARTMENT,
        text: this.companyWording.department
      });
    }
    this.goalTypes.push({
      type: GoalType.COMPANY,
      text: 'Company'
    });
    this.goalTypes.push({
      type: GoalType.OFFICE_LOCATION,
      text: 'Site'
    })
    this.goalTypes.push({
      type: GoalType.TEAM, 
      text: this.companyWording.team
    })

    this.updateGoals();
    this.getTemplates();

    this.goalCreateService.getInit()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        this.updateGoals();
      });

    this.getDepartments();
    this.getSites();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }

  // #endregion

  toggleSidebar() {
    this.modal.toggleSidebar();
  }

  // #region - GOAL TIPS
  tIncrease() {
    this.tipsList.push(this.tipsList.splice(0, 1)[0]);
  }
  tDecrease() {
    this.tipsList.unshift(this.tipsList.splice(this.tipsList.length - 1, 1)[0]);
  }
  // #endregion

  // #region - GOALS
  parseToPageGoals(goals: Goal[]) {
    return (goals as PageGoal[])
      .map(g => {
        g.keyResultsShown = false;
        return g;
      });
  }

  updateGoals() {
    this.state.goals.loading = true;
    let subscription = null;
    this.goalType.value
    switch (this.goalType.value) {
      case GoalType.PERSONAL_OPERATIONAL:
      case GoalType.PERSONAL_DEVELOPMENTAL:
        subscription = this.goalsAPIService.getPersonalGoals();
        break;
      case GoalType.DEPARTMENT:
        if (this.goalDepartmentId.value === null) {
          subscription = this.goalsAPIService.getDepartmentGoals();
        } else {
          subscription = this.goalsAPIService.getDepartmentGoalsByDepartmentId(this.goalDepartmentId.value);
        }
        break;
      case GoalType.OFFICE_LOCATION:
        if (this.goalSiteId.value === null) {
          subscription = this.goalsAPIService.getOfficeLocationGoals();
        } else {
          subscription = this.goalsAPIService.getOfficeLocationGoalsByOfficeLocationId(this.goalSiteId.value);
        }
        break;
      case GoalType.COMPANY:
        subscription = this.goalsAPIService.getCompanyGoals();
        break;
      case GoalType.TEAM:
        subscription = this.goalsAPIService.getTeamGoals();
        break;
      case null:
        forkJoin([
          this.goalsAPIService.getPersonalGoals(),
          this.goalsAPIService.getCompanyGoals(),
          this.goalsAPIService.getDepartmentGoals(),
          this.goalsAPIService.getOfficeLocationGoals(),
        ]).subscribe(([personalGoals, CompanyGoals, departmentGoals, siteGoals]) => {
          this.goals = this.parseToPageGoals([...personalGoals, ...CompanyGoals, ...departmentGoals, ...siteGoals])
          this.searchGoals();
        }, (error: any) => {
          this.state.goals.error = true;
        }, () => {
          this.state.goals.loading = false;
        })
        return;
    }

    if (subscription !== null) {
      (subscription as Observable<any>).subscribe(
        (goals: Goal[]) => {
          this.goals = this.parseToPageGoals(goals);
          this.searchGoals();
        },
        (error: any) => {
          this.state.goals.error = true;
        },
        () => {
          this.state.goals.loading = false;
        });
    }
  }

  searchGoals() {
    const sarg = this.goalSearch.value;

    if (sarg) {
      this.goalsDisplayed = this.goals.filter(g => g.title.toLocaleLowerCase().includes(sarg.toLocaleLowerCase()))
    } else {
      this.goalsDisplayed = this.goals;
    }
  }

  copyGoal(goal: Goal) {
    this.goalCreateService.sendGoalCopied(goal);
  }
  // #endregion

  // #region - TEMPLATES
  parseToPageGoalTemplates(templates: GoalTemplate[]) {
    return (templates as PageGoalTemplate[])
      .map(t => {
        t.resultsShown = false;
        return t;
      });
  }

  getTemplates() {
    this.state.templates.loading = true;
    this.goalsAPIService.getGoalTemplates().subscribe(templates => {
      this.templates = this.parseToPageGoalTemplates(templates);
      this.templatesDisplayed = this.templates;
    }, (err: any) => {
      this.state.templates.error = true;
    }, () => {
      this.state.templates.loading = false;
    })
  }

  getDepartments(): void {
    this.companyAPIService.getOrgByType('Department').subscribe(departments => {
      this.departments = departments;
    }, (failure: HttpErrorResponse) => {
      // TODO:
    });
  }

  getSites(): void {
    this.companyAPIService.getAllOfficeLocations().subscribe(sites => {
      this.sites = sites;
    }, (failure: HttpErrorResponse) => {
      // TODO:
    });
  }

  searchTemplates() {
    const sarg = this.templateSearch.value;

    if (sarg) {
      this.templatesDisplayed = this.templates.filter(t => t.title.toLocaleLowerCase().includes(sarg.toLocaleLowerCase()))
    } else {
      this.templatesDisplayed = this.templates;
    }
  }

  copyTemplate(template: GoalTemplate) {
    this.goalCreateService.sendGoalTemplateCopied(template);
  }
  // #endregion
}
