import { ConnectSkill } from '@app/models/connect/skill/connect-skill.model';
import { mockInterests } from '@app/mock/api/data/mockInterests';

export const mockConnectSkills: ConnectSkill[] = [
  {
    id: 1,
    interest: mockInterests[30],
    description: ''
  },
  {
    id: 2,
    interest: mockInterests[31],
    description: ''
  },
  {
    id: 3,
    interest: mockInterests[32],
    description: ''
  },
  {
    id: 4,
    interest: mockInterests[33],
    description: ''
  },
  {
    id: 5,
    interest: mockInterests[34],
    description: ''
  },
  {
    id: 6,
    interest: mockInterests[35],
    description: ''
  },
  {
    id: 7,
    interest: mockInterests[36],
    description: ''
  },
  {
    id: 8,
    interest: mockInterests[37],
    description: ''
  },
  {
    id: 9,
    interest: mockInterests[38],
    description: ''
  },
  {
    id: 10,
    interest: mockInterests[39],
    description: ''
  },
  {
    id: 11,
    interest: mockInterests[40],
    description: ''
  }
];