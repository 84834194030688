import { Component, Input, OnInit } from '@angular/core';
import { FeedbackUnprompted } from '@app/models/feedback/feedback.model';

@Component({
  selector: 'app-feedback-unprompted-display',
  templateUrl: './feedback-unprompted-display.component.html',
  styleUrls: ['./feedback-unprompted-display.component.css']
})
export class FeedbackUnpromptedDisplayComponent implements OnInit {
  @Input() feedback: FeedbackUnprompted;

  @Input() showSourceUser: boolean;
  @Input() showDestinationUser: boolean;

  constructor() {
    this.feedback = undefined!;

    this.showSourceUser = true;
    this.showDestinationUser = true;
  }

  ngOnInit() {
  }

}
