<div class="password-reset-container">

  <!-- Form -->
  <ng-container *ngIf="state.complete === false">

    <!-- Submit -->
    <ng-container *ngIf="state.error === false">
      <div class="card">
        <form [formGroup]="form" (ngSubmit)="formSubmit()">

          <div class="header text-center password-reset-header">
            <h4 class="text-uppercase">Reset your password</h4>
          </div>

          <div class="content">

            <!-- password -->
            <div class="row">
              <div class="col-md-12">
                <div class="frankli-form-group text-left"
                  [class.frankli-form-group-invalid]='password.invalid && state.submitted'
                  [class.frankli-form-group-filled]='password.value.length > 0'>
                  <label for="Password">New Password</label>
                  <input type="password" id="Password" name="password" class="form-control"
                    placeholder="Set new password" autocomplete="password" [formControl]="password">

                  <ng-container *ngIf="password.invalid && state.submitted === true">
                    <div class="frankli-form-group-alert">
                      <div *ngIf="password.hasError('required')">
                        New password is required.
                      </div>
                      <div *ngIf="password.hasError('minlength')">
                        Password must be at least {{minimumPasswordLength}} characters long.
                      </div>
                      <div *ngIf="password.hasError('maxlength')">
                        Password cannot be more than {{maximumPasswordLength}} characters long.
                      </div>
                    </div>
                  </ng-container>

                </div>
              </div>
            </div>

            <!-- new password -->
            <div class="row m-top-20">
              <div class="col-md-12">
                <div class="frankli-form-group text-left"
                  [class.frankli-form-group-invalid]='confirmPassword.invalid && state.submitted'
                  [class.frankli-form-group-filled]='confirmPassword.value.length > 0'>
                  <label for="confirmPassword">Confirm Password</label>
                  <input type="password" id="confirmPassword" name="confirmPassword" class="form-control"
                    placeholder="Confirm password" [formControl]="confirmPassword">

                  <ng-container *ngIf="confirmPassword.invalid && state.submitted === true">
                    <div class="frankli-form-group-alert">
                      <div *ngIf="confirmPassword.hasError('required')">
                        Confirm new password.
                      </div>
                      <div *ngIf="confirmPassword.hasError('minlength')">
                        Password must be at least {{minimumPasswordLength}} characters long.
                      </div>
                      <div *ngIf="confirmPassword.hasError('maxlength')">
                        Password cannot be more than {{maximumPasswordLength}} characters long.
                      </div>
                    </div>
                  </ng-container>

                </div>
              </div>
            </div>

            <!-- submit -->
            <div class="row m-top-20">
              <div class="col-md-12 text-center">
                <button *ngIf="state.loading === false" class="btn-frankli-round btn-frankli-green" type="submit">
                  Change Password
                </button>
                <button *ngIf="state.loading === true" class="btn-frankli-round btn-frankli-green">
                  <span class="fal fa-spin fa-spinner"></span>
                </button>
              </div>
            </div>

          </div>
        </form>
      </div>

    </ng-container>

    <!-- Error -->
    <ng-container *ngIf="state.error === true">
      <div class="card">
        <div class="header text-center">
          <p>
            <img class="icon-padding new-icon-height" src="/assets/img/Failure.svg" />
            <br />
            {{state.errorMessage}}
          </p>
        </div>
        <div class="content m-top-10">
          <div class="row text-center">
            <div class="col-md-12">
              <button (click)="resetForm()" class="btn-frankli-round btn-frankli-green">Try again?</button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

  </ng-container>

  <!-- Complete -->
  <ng-container *ngIf="state.complete === true">
    <div class="card">
      <div class="header text-center">
        <p>
          <img class="icon-padding new-icon-height" src="/assets/img/Success.svg" />
          <br />
          Password successfully updated
        </p>
      </div>
      <div class="content m-top-10">
        <div class="row text-center">
          <div class="col-md-12">
            <button [routerLink]="'/dashboard'" class="btn-frankli-round btn-frankli-green">Go to dashboard</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

</div>