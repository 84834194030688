import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserState } from 'app/models/user/user.model';
import { Globals } from '../globals/globals';

@Injectable()
export class NewUserGuard implements CanActivate {
  constructor(
    private globals: Globals,
    private router: Router
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.globals.user.userState === UserState.INVITED || this.globals.user.userState === UserState.PENDING) {
      this.router.navigate(['/sign-up']);
      return false;
    }
    return true;
  }

}
