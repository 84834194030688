import { OneToOneMeetingLength } from '@app/models/one-to-one/one-to-one-meeting-length.model';
import { Todo } from '@app/models/todos/todos.model';
import { OneToOneActivity } from 'app/models/one-to-one/one-to-one-activity.model';
import { OneToOneMeeting } from 'app/models/one-to-one/one-to-one-meeting.model';
import { OneToOneStatus } from 'app/models/one-to-one/one-to-one-status.model';
import { TalkingPoint } from 'app/models/one-to-one/talking-point.model';
import { User } from 'app/models/user/user.model';
import { OneToOneMeetingStatus } from 'app/models/one-to-one/one-to-one-meeting-status.model';

export class OneToOneSchedule {
  id?: number;
  version?: number;
  enabled: Boolean;
  purposeTitle: string;
  startDateTime?: string;
  locationType: ScheduleLocationType;
  location: string;
  timezone: string;
  frequency: string;
  talkingPointsRecurring: Array<TalkingPoint>;
  actionPoints: Array<Todo>;
  user: User;
  manager: User;
  status: OneToOneStatus | string;
  activity?: Array<OneToOneActivity>;
  meetingList?: Array<OneToOneMeeting>;
  meetingLength: OneToOneMeetingLength;
  evaluationCycleId?: number;
  seriesStartDateTime?: string;

  constructor(enabled: boolean,
              purpose: string,
              timezone: string,
              frequency: string,
              talkingPointsRecurring: Array<TalkingPoint>,
              user: User,
              manager: User,
              locationType: ScheduleLocationType,
              location: string,
              meetingLength: OneToOneMeetingLength) {
    this.enabled = enabled;
    this.purposeTitle = purpose;
    this.timezone = timezone;
    this.frequency = frequency;
    this.talkingPointsRecurring = talkingPointsRecurring;
    this.user = user;
    this.manager = manager;
    this.locationType = locationType;
    this.location = location;
    this.status = OneToOneStatus.ACTIVE;
    this.meetingLength = meetingLength;
  }
}

export enum ScheduleLocationType {
  IN_PERSON = 'IN_PERSON',
  ZOOM = 'ZOOM',
  SLACK = 'SLACK',
  MICROSOFT_TEAMS = 'MICROSOFT_TEAMS',
  SKYPE = 'SKYPE',
  OTHER = 'OTHER'
}

export class PostReviewCycleOneToOneView {
  id: number;
  evaluationCycleId: number;
  managerId: number;
  userId: number;
  meetingStatus: OneToOneMeetingStatus;
}