import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DateJoinedPipe } from 'app/shared/pipes/date-joined.pipe';
import { AgePipe } from 'app/shared/pipes/age.pipe';
import { EnumToArrayPipe } from 'app/shared/pipes/enum-to-array.pipe';
import { DateAgoPipe } from './date-ago.pipe';
import { UnderscoreToSpacePipe } from 'app/shared/pipes/underscore_to_space.pipe';
import { HtmlPipe } from 'app/shared/pipes/html.pipe';
import { KeyValueAlphabeticalPipe } from './alphabetical-key-value.pipe';
import { SecurePipe } from './secure.pipe';
import { MomentPipe } from 'app/shared/pipes/moment.pipe';
import { EllipsisPipe } from './ellipsis.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { LikertCommentPipe } from './likert-comment.pipe';
import { InitialsPipe } from '@app/shared/pipes/initials.pipe';
import { NumberToWordPipe } from '@app/shared/pipes/number-to-word.pipe';

const pipes = [
  DateJoinedPipe,
  AgePipe,
  EnumToArrayPipe,
  DateAgoPipe,
  UnderscoreToSpacePipe,
  HtmlPipe,
  KeyValueAlphabeticalPipe,
  SecurePipe,
  MomentPipe,
  EllipsisPipe,
  DateFormatPipe,
  LikertCommentPipe,
  InitialsPipe,
  NumberToWordPipe
]

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: pipes,
  exports: pipes,
  providers: [
    DatePipe,
    DateAgoPipe,
  ]
})
export class PipesModule { }

