import { User, UserGender } from 'app/models/user/user.model';
import { Address } from 'app/models/address.model';
import { OfficeLocation } from '../company.model';

export class UserCompleteSignUp {
  homeAddress: Address;
  description: string;
  gender: UserGender;
  preferredContactMethod: string;
  birthdayReminder: boolean;
  dateOfBirth: Date;
  phoneNumber: string;
  twitterHandle: string;
  imageUrl: string;
  officeLocation: OfficeLocation | null;

  constructor(user: User) {
    this.homeAddress = user.homeAddress;
    this.description = user.description;
    this.gender = user.gender;
    this.preferredContactMethod = user.preferredContactMethod;
    this.birthdayReminder = user.birthdayReminder;
    this.dateOfBirth = user.dateOfBirth!;
    this.officeLocation = user.officeLocation;
    this.phoneNumber = user.phoneNumber;
    this.twitterHandle = user.twitterHandle;
    this.imageUrl = user.imageUrl;
  }
}
