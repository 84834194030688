import { OneToOneSchedule, ScheduleLocationType } from '@app/models/one-to-one/one-to-one-schedule.model';
import { mockTodos } from '@app/mock/api/data/mockTodos';
import {
    mockCoachingTemplateTalkingPoints,
    mockEmployeeLedTemplateTalkingPoints, mockMentoringTemplateTalkingPoints, mockPerformanceReviewTemplateTalkingPoints
} from '@app/mock/api/data/mockOneToOneRecommendedTalkingPoints';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { OneToOneStatus } from '@app/models/one-to-one/one-to-one-status.model';
import { OneToOneMeetingLength } from '@app/models/one-to-one/one-to-one-meeting-length.model';
import { mockOneToOneMeetings } from '@app/mock/api/data/mockOneToOneMeetings';
import { mockEvaluationCycles } from '@app/mock/api/data/mockEvaluationCycles';
import { deepClone } from '@app/shared/utils/helpers';

export const mockOneToOneSchedules: OneToOneSchedule[] = [
    {
        id: 1,
        enabled: true,
        purposeTitle: "Regular 1:1",
        locationType: ScheduleLocationType.SLACK,
        location: 'I will call you on Slack at this time',
        timezone: 'Europe/Dublin',
        frequency: 'Weekly',
        talkingPointsRecurring: mockEmployeeLedTemplateTalkingPoints.map(tp => ({
            id: tp.id,
            actioned: true,
            recurring: true,
            title: tp.text,
            hasRelatedGoals: false,
            fromPrevious: false,
            sourceUserId: mockUsers[0].id,
            comment: ''
        })),
        actionPoints: [
            mockTodos[3],
            mockTodos[4]
        ],
        user: mockUsers[1],
        manager: mockUsers[0],
        status: OneToOneStatus.ACTIVE,
        activity: [],
        meetingList: [
            mockOneToOneMeetings[0],
            mockOneToOneMeetings[1]
        ],
        meetingLength: OneToOneMeetingLength.THIRTY_MINUTES,
        seriesStartDateTime: mockOneToOneMeetings[1].meetingTimestamp,
        startDateTime: mockOneToOneMeetings[1].meetingTimestamp
    },
    {
        id: 2,
        enabled: true,
        purposeTitle: "Coaching Session",
        locationType: ScheduleLocationType.SLACK,
        location: 'I will call you on Slack at this time',
        timezone: 'Europe/Dublin',
        frequency: 'Individual',
        talkingPointsRecurring: mockCoachingTemplateTalkingPoints.map(tp => ({
            id: tp.id,
            actioned: true,
            recurring: true,
            title: tp.text,
            hasRelatedGoals: false,
            fromPrevious: false,
            sourceUserId: mockUsers[0].id,
            comment: ''
        })),
        actionPoints: [
            mockTodos[3],
            mockTodos[4]
        ],
        user: mockUsers[1],
        manager: mockUsers[0],
        status: OneToOneStatus.ACTIVE,
        activity: [],
        meetingList: [
            mockOneToOneMeetings[2]
        ],
        meetingLength: OneToOneMeetingLength.SIXTY_MINUTES,
        seriesStartDateTime: mockOneToOneMeetings[2].meetingTimestamp,
        startDateTime: mockOneToOneMeetings[2].meetingTimestamp
    },
    {
        id: 3,
        enabled: true,
        purposeTitle: "Mentoring Session",
        locationType: ScheduleLocationType.SLACK,
        location: 'I will call you on Slack at this time',
        timezone: 'Europe/Dublin',
        frequency: 'Individual',
        talkingPointsRecurring: mockMentoringTemplateTalkingPoints.map(tp => ({
            id: tp.id,
            actioned: true,
            recurring: true,
            title: tp.text,
            hasRelatedGoals: false,
            fromPrevious: false,
            sourceUserId: mockUsers[0].id,
            comment: ''
        })),
        actionPoints: [
            mockTodos[3],
            mockTodos[4]
        ],
        user: mockUsers[1],
        manager: mockUsers[0],
        status: OneToOneStatus.ACTIVE,
        activity: [],
        meetingList: [
            mockOneToOneMeetings[3]
        ],
        meetingLength: OneToOneMeetingLength.SIXTY_MINUTES,
        seriesStartDateTime: mockOneToOneMeetings[3].meetingTimestamp,
        startDateTime: mockOneToOneMeetings[3].meetingTimestamp
    },
    {
        id: 4,
        enabled: true,
        purposeTitle: "Discuss Outcome of Mid Year Review 2021",
        locationType: ScheduleLocationType.SLACK,
        location: 'I will call you on Slack at this time',
        timezone: 'Europe/Dublin',
        frequency: 'Individual',
        talkingPointsRecurring: mockPerformanceReviewTemplateTalkingPoints.map(tp => ({
            id: tp.id,
            actioned: true,
            recurring: true,
            title: tp.text,
            hasRelatedGoals: false,
            fromPrevious: false,
            sourceUserId: mockUsers[0].id,
            comment: ''
        })),
        actionPoints: [
            mockTodos[3],
            mockTodos[4]
        ],
        user: mockUsers[2],
        manager: mockUsers[0],
        status: OneToOneStatus.ACTIVE,
        activity: [],
        meetingList: [
            mockOneToOneMeetings[4]
        ],
        meetingLength: OneToOneMeetingLength.THIRTY_MINUTES,
        seriesStartDateTime: mockOneToOneMeetings[4].meetingTimestamp,
        startDateTime: mockOneToOneMeetings[4].meetingTimestamp,
        evaluationCycleId: mockEvaluationCycles[0].id
    }
];