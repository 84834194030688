import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GoalPriority, GoalType, GoalVisibility } from 'app/models/goals/goal.model';
import { GoalsAPIService } from 'app/shared/api/goals.api.service';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import { GoalCreateService } from '../../goals-create/goals-create.service';
import { CompanyFeatures } from 'app/models/company.model';
import { Globals } from 'app/shared/globals/globals';
import { PaginationComponent } from 'app/shared/pagination/pagination.component';
import { GoalsModalComponent } from '../../goals-modal/goals-modal.component';
import { CompanyWording } from '@app/models/company/company-wording/company-wording.model';
import { GoalDraft } from '@app/models/goals/goal-draft.model';

declare var $: any;

interface State {
  deleting: boolean,
  id: number | null
}

@Component({
  selector: 'app-goals-overview-drafts-component',
  templateUrl: './goals-overview-drafts.component.html',
  styleUrls: ['./goals-overview-drafts.component.css'],
})
export class GoalsOverviewDraftsComponent implements OnInit {
  @Input() page: number;
  @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();

  @Input() goals !: Array<GoalDraft>;
  @ViewChild('modalEditDraft') modalEditDraft !: GoalsModalComponent;

  goalsDisplayed: Array<GoalDraft> = new Array<GoalDraft>();
  @ViewChild('pagination') pagination!: PaginationComponent;

  eFeature = CompanyFeatures;

  state: State;

  eGoalType = GoalType;
  eGoalVisibility = GoalVisibility;
  eGoalPriority = GoalPriority;

  selectedGoal: GoalDraft | null;

  columnTitles: string[];
  companyWording: CompanyWording;

  constructor(
    private goalsAPIService: GoalsAPIService,
    private goalCreateService: GoalCreateService,
    private globals: Globals
  ) {
    this.companyWording = this.globals.company.companyWording;
    this.state = {deleting: false, id: null};
    this.selectedGoal = null;
    this.columnTitles = ['title', 'priority', 'type', 'visibility', 'key results', 'owners', 'tags', 'end date', 'actions'];
  }

  ngOnInit() {
    this.state.deleting = false;

    this.goals = this.goals.sort((goalA, goalB) => {
      const a = (goalA.endDate ? new Date(goalA.endDate).valueOf() : new Date());
      const b = (goalB.endDate ? new Date(goalB.endDate).valueOf() : new Date());
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });
    if (this.pagination) {
      this.pagination.update();
    }
  }

  paginationChanged(e: any) {
    this.pageChanged.emit(e);
  }

  editDraftGoal(goal: GoalDraft) {
    this.selectedGoal = goal;
    this.goalCreateService.sendReset();
    this.modalEditDraft.show();
  }

  deleteDraftGoal(goal: GoalDraft) {
    this.state.deleting = true;
    this.state.id = goal.id;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Your draft goal will be deleted',
      imageUrl: 'assets/img/swal-icons/Frankli_sure_icon-46.svg',
      imageWidth: 140,
      imageHeight: 140,
      showCancelButton: true,
      confirmButtonColor: '#54c6bb',
      confirmButtonText: 'Delete Draft',
    }).then((result) => {
      if (result.value) {
        this.goalsAPIService.deleteDraftGoalById(goal.id).subscribe((response: GoalDraft) => {
          $.notify('Draft successfully deleted');
          this.state.deleting = false;
          this.state.id = null;
          this.goalCreateService.sendGoalSaved(response);
        }, (_failure: HttpErrorResponse) => {
          this.state.deleting = false;
          this.state.id = null;
        });
      } else {
        this.state.deleting = false;
        this.state.id = null;
      }
    });
  }

}