import { CreateGoalKeyResultDto } from "./create-goal-key-result.dto";
import { GoalPriority, GoalStatus, GoalType, GoalVisibility } from "./goal.model";

export class CreateGoalDto {

  constructor(
    public title: string,
    public type: GoalType,
    public departmentId: number | null,
    public officeLocationId: number | null,
    public endDate: Date,
    public visibility: GoalVisibility,
    public priority: GoalPriority,
    public tagIds: Array<number>,
    public ownerIds: Array<number>,
    public keyResults: Array<CreateGoalKeyResultDto>,
    public alignmentId: number | null,
    public developmentNeeds: string | null
  ) { }

}