import Swal, { SweetAlertResult } from 'sweetalert2';
import { SwalLargeFont } from '@app/shared/utils/swal-custom-classes';

export class SwalUtils {
    
    static displayErrorSwal = (message?: string, title?: string): Promise<SweetAlertResult> => Swal.fire({
        title: title || 'Sorry, We Encountered an Error',
        text: message || 'Please try again later.',
        imageUrl: 'assets/img/swal-icons/Frankli_cancel_icon-45.svg',
        imageWidth: 140,
        imageHeight: 140,
        customClass: SwalLargeFont,
        confirmButtonColor: '#54c6bb',
    });

    static displayWarning = (message?: string, title?: string): Promise<SweetAlertResult> => Swal.fire({
        title: title || 'Sorry, We Encountered an Error',
        text: message || 'Please try again later.',
        imageUrl: 'assets/img/swal-icons/frankli-warning-icon.svg',
        imageWidth: 140,
        imageHeight: 140,
        confirmButtonColor: '#54c6bb',
    });

    static displayMockNoOpSwal = (): Promise<SweetAlertResult> => Swal.fire({
        title: 'Hey there!',
        text: 'You\'re interacting with a read-only version of Frankli',
        imageUrl: 'assets/img/swal-icons/Frankli_sure_icon-46.svg',
        imageWidth: 140,
        imageHeight: 140,
        customClass: SwalLargeFont,
        confirmButtonColor: '#54c6bb',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: 'Request a Product Tour',
        cancelButtonText: 'Continue Browsing'
    });

    static displayWarningConfirmationSwal = (message: string): Promise<SweetAlertResult> => Swal.fire({
        title: 'Are you sure?',
        text: message,
        imageUrl: 'assets/img/swal-icons/frankli-warning-icon.svg',
        imageWidth: 140,
        imageHeight: 140,
        confirmButtonColor: '#54c6bb',
        confirmButtonText: 'Confirm',
        customClass: SwalLargeFont,
        showCancelButton: true,
        reverseButtons: true
    });

    static displayConfirmationSwal = (message: string): Promise<SweetAlertResult> => Swal.fire({
        title: 'Are you sure?',
        text: message,
        imageUrl: 'assets/img/swal-icons/Frankli_good_job_icon-44.svg',
        imageWidth: 140,
        imageHeight: 140,
        confirmButtonColor: '#54c6bb',
        confirmButtonText: 'Confirm',
        customClass: SwalLargeFont,
        showCancelButton: true,
        reverseButtons: true
    });
}