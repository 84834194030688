<!-- TODO: -->
<!-- - Reset icon -->
<!-- - Style with our styles -->
<div class="picker-container" [class.picker-inline]="inline">
  <input class="form-control picker-input" [placeholder]="placeholder" [name]="name" [formControl]="innerFormControl" [options]="config" [disabled]="disabled" #datePicker="angular-mydatepicker" [class.hidden]="inline" angular-mydatepicker>
  <ng-container *ngIf="!inline">
    <div class="picker-open-area" (click)="openPicker()"></div>
    <div class="picker-undo" *ngIf="showResetButton === true">
      <span class="fa fa-undo" (click)="resetDate()"></span>
    </div>
  </ng-container>
</div>
