import { OneToOneMeeting } from '@app/models/one-to-one/one-to-one-meeting.model';
import { OneToOneMeetingStatus } from '@app/models/one-to-one/one-to-one-meeting-status.model';
import * as moment from 'moment';
import {
    mockCoachingTemplateTalkingPoints,
    mockEmployeeLedTemplateTalkingPoints, mockMentoringTemplateTalkingPoints, mockPerformanceReviewTemplateTalkingPoints
} from '@app/mock/api/data/mockOneToOneRecommendedTalkingPoints';
import { mockUsers } from '@app/mock/api/data/mockUsers';

export const mockOneToOneMeetings: OneToOneMeeting[] = [
    {
        id: 1,
        autoCompleted: false,
        talkingPoints: mockEmployeeLedTemplateTalkingPoints.map(tp => ({
            id: tp.id,
            actioned: true,
            recurring: true,
            title: tp.text,
            hasRelatedGoals: false,
            fromPrevious: false,
            sourceUserId: mockUsers[0].id,
            comment: ''
        })),
        sharedUserNotes: 'These are notes shared by the 1:1 participant that are visible to both the 1:1 Creator and Participant.',
        sharedManagerNotes: 'These are notes shared by the 1:1 creator that are visible to both the 1:1 Creator and Participant.',
        userNotes: 'These are notes created by the 1:1 participant that are visible only to the Participant.',
        managerNotes: 'These are notes created by the 1:1 creator that are visible only to the Creator.',
        completedTimestamp: moment().subtract(6, 'days').toDate().toString(),
        meetingTimestamp: moment().subtract(1, 'week').toDate().toString(),
        status: OneToOneMeetingStatus.COMPLETED
    },
    {
        id: 2,
        autoCompleted: false,
        talkingPoints: mockEmployeeLedTemplateTalkingPoints.map(tp => ({
            id: tp.id,
            actioned: false,
            recurring: true,
            title: tp.text,
            hasRelatedGoals: false,
            fromPrevious: false,
            sourceUserId: mockUsers[0].id,
            comment: ''
        })),
        sharedUserNotes: 'These are notes shared by the 1:1 participant that are visible to both the 1:1 Creator and Participant.',
        sharedManagerNotes: 'These are notes shared by the 1:1 creator that are visible to both the 1:1 Creator and Participant.',
        userNotes: 'These are notes created by the 1:1 participant that are visible only to the Participant.',
        managerNotes: 'These are notes created by the 1:1 creator that are visible only to the Creator.',
        meetingTimestamp: moment().add(10, 'minutes').toDate().toString(),
        status: OneToOneMeetingStatus.IN_PROGRESS
    },
    {
        id: 3,
        autoCompleted: false,
        talkingPoints: mockCoachingTemplateTalkingPoints.map(tp => ({
            id: tp.id,
            actioned: false,
            recurring: true,
            title: tp.text,
            hasRelatedGoals: false,
            fromPrevious: false,
            sourceUserId: mockUsers[0].id,
            comment: ''
        })),
        sharedUserNotes: 'These are notes shared by the 1:1 participant that are visible to both the 1:1 Creator and Participant.',
        sharedManagerNotes: 'These are notes shared by the 1:1 creator that are visible to both the 1:1 Creator and Participant.',
        userNotes: 'These are notes created by the 1:1 participant that are visible only to the Participant.',
        managerNotes: 'These are notes created by the 1:1 creator that are visible only to the Creator.',
        meetingTimestamp: moment().add(1, 'week').toDate().toString(),
        status: OneToOneMeetingStatus.SCHEDULED
    },
    {
        id: 4,
        autoCompleted: false,
        talkingPoints: mockMentoringTemplateTalkingPoints.map(tp => ({
            id: tp.id,
            actioned: false,
            recurring: true,
            title: tp.text,
            hasRelatedGoals: false,
            fromPrevious: false,
            sourceUserId: mockUsers[0].id,
            comment: ''
        })),
        sharedUserNotes: 'These are notes shared by the 1:1 participant that are visible to both the 1:1 Creator and Participant.',
        sharedManagerNotes: 'These are notes shared by the 1:1 creator that are visible to both the 1:1 Creator and Participant.',
        userNotes: 'These are notes created by the 1:1 participant that are visible only to the Participant.',
        managerNotes: 'These are notes created by the 1:1 creator that are visible only to the Creator.',
        meetingTimestamp: moment().add(2, 'weeks').toDate().toString(),
        status: OneToOneMeetingStatus.SCHEDULED
    },
    {
        id: 5,
        autoCompleted: false,
        talkingPoints: mockPerformanceReviewTemplateTalkingPoints.map(tp => ({
            id: tp.id,
            actioned: false,
            recurring: true,
            title: tp.text,
            hasRelatedGoals: false,
            fromPrevious: false,
            sourceUserId: mockUsers[0].id,
            comment: ''
        })),
        sharedUserNotes: 'These are notes shared by the 1:1 participant that are visible to both the 1:1 Creator and Participant.',
        sharedManagerNotes: 'These are notes shared by the 1:1 creator that are visible to both the 1:1 Creator and Participant.',
        userNotes: 'These are notes created by the 1:1 participant that are visible only to the Participant.',
        managerNotes: 'These are notes created by the 1:1 creator that are visible only to the Creator.',
        meetingTimestamp: moment().add(1, 'week').toDate().toString(),
        status: OneToOneMeetingStatus.SCHEDULED
    }
];