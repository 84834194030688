import { Survey } from '@app/models/survey/survey.model';
import { EnpsAdditionalQuestions } from '@app/models/survey/enps-additional-questions.model';
import {
  mockCovidResponseSurveyQuestions,
  mockEmployeeSatisfactionSurveyQuestions, mockEnpsSurveyQuestions,
  mockExitInterviewSurveyQuestions, mockJobSatisfactionSurveyQuestions,
  mockOnboardingSurveyQuestions, mockTrainingEvaluationSurveyQuestions,
  mockWellbeingSurveyQuestions
} from '@app/mock/api/data/mockSurveyQuestions';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import * as moment from 'moment';

export const mockSurveys: Survey[] = [
  {
    id: 1,
    name: 'Wellbeing Survey',
    templateId: null,
    surveyQuestions: mockWellbeingSurveyQuestions,
    recipients: [],
    message: null,
    timezone: null,
    frequency: 'Individual',
    expiryAfter: 1,
    expiryType: 'Month',
    unauthenticated: false,
    userIds: [],
    enps: false,
    enpsAdditionalQuestions: EnpsAdditionalQuestions.NONE,
    enpsAdditionalQuestionsResult: 0,
    continuous: false,
    imageURL: '../../assets/img/surveys/Frankli_Wellbeing_Survey.svg',
    externalUsers: [],
    creatorId: null
  },
  {
    id: 2,
    name: 'Onboarding Survey',
    templateId: null,
    surveyQuestions: mockOnboardingSurveyQuestions,
    recipients: [],
    message: null,
    timezone: null,
    frequency: 'Individual',
    expiryAfter: 1,
    expiryType: 'Month',
    unauthenticated: false,
    userIds: [],
    enps: false,
    enpsAdditionalQuestions: EnpsAdditionalQuestions.NONE,
    enpsAdditionalQuestionsResult: 0,
    continuous: false,
    imageURL: '../../assets/img/surveys/Frankli_Onboarding_Survey.svg',
    externalUsers: [],
    creatorId: null
  },
  {
    id: 3,
    name: 'Exit Interview Survey',
    templateId: null,
    surveyQuestions: mockExitInterviewSurveyQuestions,
    recipients: [],
    message: null,
    timezone: null,
    frequency: 'Individual',
    expiryAfter: 1,
    expiryType: 'Month',
    unauthenticated: false,
    userIds: [],
    enps: false,
    enpsAdditionalQuestions: EnpsAdditionalQuestions.NONE,
    enpsAdditionalQuestionsResult: 0,
    continuous: false,
    imageURL: '../../assets/img/surveys/Frankli_Exit_Interview_Survey.svg',
    externalUsers: [],
    creatorId: null
  },
  {
    id: 4,
    name: 'Employee Satisfaction Survey',
    templateId: null,
    surveyQuestions: mockEmployeeSatisfactionSurveyQuestions,
    recipients: [],
    message: null,
    timezone: null,
    frequency: 'Individual',
    expiryAfter: 1,
    expiryType: 'Month',
    unauthenticated: false,
    userIds: [],
    enps: false,
    enpsAdditionalQuestions: EnpsAdditionalQuestions.NONE,
    enpsAdditionalQuestionsResult: 0,
    continuous: false,
    imageURL: '../../assets/img/surveys/Frankli_Employee_Satisfaction_Survey.svg',
    externalUsers: [],
    creatorId: null
  },
  {
    id: 5,
    name: 'Job Satisfaction Survey',
    templateId: null,
    surveyQuestions: mockJobSatisfactionSurveyQuestions,
    recipients: [],
    message: null,
    timezone: null,
    frequency: 'Individual',
    expiryAfter: 1,
    expiryType: 'Month',
    unauthenticated: false,
    userIds: [],
    enps: false,
    enpsAdditionalQuestions: EnpsAdditionalQuestions.NONE,
    enpsAdditionalQuestionsResult: 0,
    continuous: false,
    imageURL: '../../assets/img/surveys/Frankli_Job_Satisfaction_Survey.svg',
    externalUsers: [],
    creatorId: null
  },
  {
    id: 6,
    name: 'Training Evaluation Survey',
    templateId: null,
    surveyQuestions: mockTrainingEvaluationSurveyQuestions,
    recipients: [],
    message: null,
    timezone: null,
    frequency: 'Individual',
    expiryAfter: 1,
    expiryType: 'Month',
    unauthenticated: false,
    userIds: [],
    enps: false,
    enpsAdditionalQuestions: EnpsAdditionalQuestions.NONE,
    enpsAdditionalQuestionsResult: 0,
    continuous: false,
    imageURL: '../../assets/img/surveys/Frankli_Training_Evaluation_Survey.svg',
    externalUsers: [],
    creatorId: null
  },
  {
    id: 7,
    name: 'Covid Response Survey',
    templateId: null,
    surveyQuestions: mockCovidResponseSurveyQuestions,
    recipients: [],
    message: null,
    timezone: null,
    frequency: 'Individual',
    expiryAfter: 1,
    expiryType: 'Month',
    unauthenticated: false,
    userIds: [],
    enps: false,
    enpsAdditionalQuestions: EnpsAdditionalQuestions.NONE,
    enpsAdditionalQuestionsResult: 0,
    continuous: false,
    imageURL: '../../assets/img/surveys/Frankli_Covid_Response_Survey.svg',
    externalUsers: [],
    creatorId: null
  },
  {
    id: 8,
    name: 'eNPS Survey',
    templateId: null,
    surveyQuestions: mockEnpsSurveyQuestions,
    recipients: [],
    message: null,
    timezone: null,
    frequency: 'Individual',
    expiryAfter: 1,
    expiryType: 'Month',
    unauthenticated: false,
    userIds: [],
    enps: false,
    enpsAdditionalQuestions: EnpsAdditionalQuestions.NONE,
    enpsAdditionalQuestionsResult: 0,
    continuous: false,
    imageURL: '../../assets/img/surveys/Frankli_eNPS_Survey.svg',
    externalUsers: [],
    creatorId: null
  },
  {
    id: 9,
    name: 'Company-Wide Wellbeing Survey',
    templateId: 1,
    surveyQuestions: mockWellbeingSurveyQuestions,
    recipients: mockUsers.filter(u => u.id !== mockUsers[0].id),
    message: 'A new survey \'Wellbeing Survey\' has been published, and you\'re one of the recipients!',
    timezone: 'Europe/Dublin',
    frequency: 'Individual',
    expiryAfter: 1,
    expiryType: 'Month',
    unauthenticated: false,
    userIds: mockUsers.filter(u => u.id !== mockUsers[0].id).map(u => u.id),
    enps: false,
    enpsAdditionalQuestions: EnpsAdditionalQuestions.NONE,
    enpsAdditionalQuestionsResult: 0,
    continuous: false,
    imageURL: '../../assets/img/surveys/Frankli_Wellbeing_Survey.svg',
    externalUsers: [],
    creatorId: 1,
    startDateTime: moment().subtract(1, 'week').toISOString()
  },
  {
    id: 10,
    name: 'Employee Satisfaction Survey',
    templateId: 4,
    surveyQuestions: mockEmployeeSatisfactionSurveyQuestions,
    recipients: mockUsers,
    message: 'A new survey \'Employee Satisfaction Survey\' has been published, and you\'re one of the recipients!',
    timezone: 'Europe/Dublin',
    frequency: 'Individual',
    expiryAfter: 1,
    expiryType: 'Month',
    unauthenticated: false,
    userIds: mockUsers.map(u => u.id),
    enps: false,
    enpsAdditionalQuestions: EnpsAdditionalQuestions.NONE,
    enpsAdditionalQuestionsResult: 0,
    continuous: false,
    imageURL: '../../assets/img/surveys/Frankli_Employee_Satisfaction_Survey.svg',
    externalUsers: [],
    creatorId: 2,
    startDateTime: moment().subtract(1, 'week').toISOString()
  },
  {
    id: 11,
    name: 'eNPS Survey',
    templateId: null,
    surveyQuestions: mockEnpsSurveyQuestions,
    recipients: mockUsers.filter(u => u.id !== mockUsers[0].id),
    message: 'A new survey \'eNPS Survey\' has been published, and you\'re one of the recipients!',
    timezone: 'Europe/Dublin',
    frequency: 'Individual',
    expiryAfter: 1,
    expiryType: 'Month',
    unauthenticated: false,
    userIds: mockUsers.filter(u => u.id !== mockUsers[0].id).map(u => u.id),
    enps: true,
    enpsAdditionalQuestions: EnpsAdditionalQuestions.NONE,
    enpsAdditionalQuestionsResult: 0,
    continuous: false,
    imageURL: '../../assets/img/surveys/Frankli_eNPS_Survey.svg',
    externalUsers: [],
    creatorId: 1
  },
];