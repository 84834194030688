import { Component, Input, OnInit } from '@angular/core';
import { FileUploadValue } from '@app/models/file-upload-value.model';

@Component({
  selector: 'app-uploaded-file-display',
  templateUrl: './uploaded-file-display.component.html',
  styleUrls: ['./uploaded-file-display.component.css']
})
export class UploadedFileDisplayComponent implements OnInit {
  @Input() file!: FileUploadValue;

  constructor() { }

  ngOnInit() {
  }

}
