import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb } from '@app/models/breadcrumb.model';
import { FeedbackRequest, FeedbackRequestQuestion, FeedbackRequestResponse, FeedbackRequestResponseAnswer, FeedbackRequestResponseStatus } from '@app/models/feedback/feedback-request.model';
import { FeedbackAPIService } from '@app/shared/api/feedback.api.service';
import { BreadcrumbService } from '@app/shared/breadcrumbs/breadcrumbs.service';
import { Globals } from '@app/shared/globals/globals';

interface PageState {
  loading: boolean;
  error: boolean;
  errorMessage: string;
}

interface PageQuestionData {
  question: FeedbackRequestQuestion;
  answer: FeedbackRequestResponseAnswer;
}

@Component({
  selector: 'app-feedback-request-response-single',
  templateUrl: './feedback-request-response-single.component.html',
  styleUrls: ['./feedback-request-response-single.component.css']
})
export class FeedbackRequestResponseSingleComponent implements OnInit, OnDestroy {
  eFeedbackRequestResponseStatus = FeedbackRequestResponseStatus;

  state: PageState;
  breadcrumb: Breadcrumb;

  requestId: number;
  responseId: number;

  request: FeedbackRequest;
  response: FeedbackRequestResponse;

  questionListMapped: PageQuestionData[];

  constructor(
    public globals: Globals,
    private feedbackAPIService: FeedbackAPIService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService
  ) {
    this.requestId = undefined!;
    this.responseId = undefined!;
    this.request = undefined!;
    this.response = undefined!;

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    }

    this.questionListMapped = [];

    this.breadcrumb = this.breadcrumbService.init(this.route);
  }

  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {
    this.breadcrumbService.remove(this.breadcrumb);
  }

  getData() {
    if (!this.route.parent) {
      return this.doError('No parent route found');
    }

    this.route.parent.paramMap.subscribe(
      (parentParams) => {
        const requestId = parentParams.get('id');
        if (!requestId) {
          return this.doError('Invalid request ID');
        }

        this.requestId = +requestId;

        this.route.paramMap.subscribe(
          (params) => {
            const responseId = params.get('id');
            if (!responseId) {
              return this.doError('Invalid response ID');
            }

            this.responseId = +responseId;

            this.feedbackAPIService.getFeedbackRequestSentById(this.requestId).subscribe(
              (res) => {
                const request = res;
                if (!request) {
                  return this.doError('Request not found');
                }

                const response = request.responses.find(r => r.id === this.responseId);
                if (!response) {
                  return this.doError('Response not found');
                }

                this.request = this.parseRequest(request);
                this.response = response;

                this.questionListMapped = this.getQuestionsMapped(this.request, this.response);

                this.state.loading = false;
              },
              (err: HttpErrorResponse) => this.doError(err.message)
            );
          }
        );
      }
    )
  }


  parseRequest(request: FeedbackRequest): FeedbackRequest {
    const output = request as FeedbackRequest;

    if (output) {
      output.questions = this.sortQuestions(output.questions);
    }

    return output;
  }

  sortQuestions(questions: FeedbackRequestQuestion[]): FeedbackRequestQuestion[] {
    return questions.sort((a, b) => a.orderIndex - b.orderIndex);
  }

  getQuestionsMapped(request: FeedbackRequest, response: FeedbackRequestResponse): PageQuestionData[] {
    const questions: PageQuestionData[] = [];

    request.questions.forEach(question => {
      const answer = response.answers.find(a => a.questionId === question.id);

      questions.push({
        question: question,
        answer: answer!
      });
    });

    return questions;
  }

  doError(message: string) {
    this.state.loading = false;
    this.state.error = true;
    this.state.errorMessage = message;
  }
}
