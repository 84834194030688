<div class="frankli-sidebar" [class.sidebar-collapsed]="globals.sidebarCollapsed">
  <div class="frankli-sidebar-inner">
    <div class="frankli-sidebar-header frankli-sidebar-desktop-only">
      <div class="img-container" [ngSwitch]="globals.sidebarCollapsed">
        <img src="/assets/img/Frankli_logo_primary_white.png" alt="Frankli" *ngSwitchCase="false"/>
        <img src="/assets/img/logo.png" alt="Frankli" *ngSwitchCase="true"/>
      </div>
    </div>
  
    <!-- Mobile menu -->
    <div class="frankli-sidebar-mobile-only">
      <div class="sidebar-notification-area text-center">
  
        <!-- General notifications -->
        <div class="sidebar-notification-icon dropdown text-right">
          <div href="#" class="dropdown-toggle pull-left" data-toggle="dropdown"
            [attr.data-intercom-target]="'General Notifications'">
            <span class="fal fa-fw fa-bell"></span>
            <span class="notification"
              *ngIf="notifications.length > 0 && notifications.length < 6">{{notifications.length}}</span>
            <span class="notification" *ngIf="notifications.length >= 6">5+</span>
          </div>
          <ul class="dropdown-menu general">
            <li *ngIf="notifications.length === 0">
              <a>You currently have no notifications.</a>
            </li>
            <li *ngFor="let notification of notifications; index as i" [class.hidden]="i > 3">
              <a *ngIf="notification.eventType !== 'PRAISE_RECEIVED' && notification.eventType !== 'FEEDBACK_RECEIVED' && notification.eventType !== 'IDEA_RECEIVED' && notification.eventType !== 'USER_REFRESH' && notification.eventType !== 'REFRESH'"
                (click)="navigateToRoute(notification.url)" angulartics2On="click"
                angularticsAction="NavNotificationNavbarFeedbackNA"
                angularticsCategory="Feedback">{{notification.text}}</a>
              <a *ngIf="notification.eventType === 'PRAISE_RECEIVED'" [routerLink]="[notification.url]"
                [queryParams]="{type: 'praise'}" angulartics2On="click" angularticsAction="NavNotificationNavbarPraise"
                angularticsCategory="Feedback">{{notification.text}}</a>
              <a *ngIf="notification.eventType === 'FEEDBACK_RECEIVED'" [routerLink]="[notification.url]"
                [queryParams]="{type: 'feedback'}" angulartics2On="click"
                angularticsAction="NavNotificationNavbarFeedback" angularticsCategory="Feedback">{{notification.text}}</a>
              <a *ngIf="notification.eventType === 'IDEA_RECEIVED'" [routerLink]="[notification.url]"
                [queryParams]="{type: 'ideas'}" angulartics2On="click" angularticsAction="NavNotificationNavbarIdeas"
                angularticsCategory="Feedback">{{notification.text}}</a>
              <a *ngIf="notification.eventType === 'ANNIVERSARY'" (click)="navigateToRoute(notification.url)">
                {{notification.text}}
              </a>
              <a *ngIf="notification.eventType === 'COMPLETED_SURVEY'" [routerLink]="notification.url">
                {{notification.text}}
              </a>
            </li>
            <li *ngIf="notifications.length > 4">
              <div class="row text-center m-bottom-10">
                <a [routerLink]="['/notifications']" class="see-all-link">See all</a>
              </div>
            </li>
          </ul>
        </div>
  
        <div class="sidebar-notification-icon sidebar-user-icon dropdown">
          <div class="clickable dropdown-toggle" data-toggle="dropdown">
            <span class="owner-img pull-left" *ngIf="globals.user">
              <app-profile-photo [src]="globals.user.imageUrl"></app-profile-photo>
            </span>
          </div>
          <ul class="dropdown-menu user-info dropdown-with-icons">
            <li>
              <a [routerLink]="['/profile']" [attr.data-intercom-target]="'View Profile'">
                <i class="fal fa-user"></i>View Profile
              </a>
            </li>
            <li class="divider"></li>
            <li>
              <a [routerLink]="['/profile/edit']" [attr.data-intercom-target]="'Edit Profile'">
                <i class="fal fa-edit"></i>Edit Profile
              </a>
            </li>
            <li class="divider"></li>
            <li>
              <a routerLink="/settings/password" angulartics2On="click" angularticsAction="ClickChangePasswordNavbar"
                angularticsCategory="Support" [attr.data-intercom-target]="'Change Password'">
                <i class="fal fa-lock-alt"></i> Change Password
              </a>
            </li>
            <li class="divider"></li>
            <li>
              <a target="_blank" href="http://help.frankli.io" [attr.data-intercom-target]="'Help Center'">
                <i class="fal fa-question-circle"></i> Help Center
              </a>
            </li>
            <li class="divider"></li>
            <li (click)="logout()">
              <a angulartics2On="click" angularticsAction="LogoutNavbar" [attr.data-intercom-target]="'Logout Navbar'"
                angularticsCategory="Support">
                <i class="fal fa-times-circle"></i> Log out
              </a>
            </li>
          </ul>
        </div>
      </div>
  
      <div class="input-group search-container">
        <span class="input-group-addon search-icon">
          <i class="fal fa-search"></i>
        </span>
        <input type="search" class="form-control nav-search-bg" placeholder="Search People or Interests"
          [(ngModel)]="searchValue" maxlength="100" angulartics2On="click" angularticsAction="ClickSearchNavbar"
          angularticsCategory="Support" [attr.data-intercom-target]="'Search Bar'" (keyup)="checkEnter($event)" />
      </div>
    </div>
  
    <div class="frankli-sidebar-wrapper" [class.setup-nudge-wrapper]="true">
      <!-- TODO: Can probably merge all of these into the same template -->
      <!-- Employee -->
      <ul class="frankli-sidebar-group">
        <li routerLinkActive="active" *ngFor="let menuItem of employeeMenuItems" [title]="globals.sidebarCollapsed ? menuItem.title : ''">
  
          <!--If is a single link-->
          <a class="text-nowrap menu-item" [attr.data-intercom-target]="menuItem.title" [routerLink]="[menuItem.path]" *ngIf="!menuItem.children">
            <i class="{{menuItem.iconType}}"></i>
            <span class="menu-title">{{menuItem.title}}</span>
            <span class="locked fal fa-lock" *ngIf="menuItem.locked"></span>
          </a>
  
          <!--If it has a submenu-->
          <ng-container *ngIf="menuItem.children">
            <a class="text-nowrap menu-item" [attr.data-intercom-target]="menuItem.title" data-toggle="collapse" href="#{{menuItem.title}}-employee">
              <i class="{{menuItem.iconType}}"></i>
              <span class="menu-title">{{menuItem.title}}</span>
              <b class="caret"></b>
            </a>

            <div id="{{menuItem.title}}-employee" class="collapse">
              <ul class="frankli-sidebar-submenu">
                <li routerLinkActive="active" *ngFor="let childItem of menuItem.children" [title]="globals.sidebarCollapsed ? childItem.title : ''">
                  <a class="text-nowrap menu-item" [routerLink]="[menuItem.path, childItem.path]" [attr.data-intercom-target]="childItem.title">
                    <i class="{{childItem.iconType}}"></i>
                    <span class="menu-title">{{childItem.title}}</span>
                    <span class="locked fal fa-lock" *ngIf="childItem.locked"></span>
                  </a>
                </li>
              </ul>
            </div>
          </ng-container>
  
        </li>
      </ul>
  
      <!-- Manager -->
      <ng-container *ngIf="checkIfManager(); let isManager">
        <hr class="line"/>
        <ul class="frankli-sidebar-group">
          <li routerLinkActive="active" *ngFor="let menuItem of managerMenuItems" [title]="globals.sidebarCollapsed ? menuItem.title : ''">
            <!--If is a single link-->
            <a class="text-nowrap menu-item" [attr.data-intercom-target]="menuItem.title" [routerLink]="[menuItem.path]"
              *ngIf="!menuItem.children">
              <i class="{{menuItem.iconType}}"></i>
              <span class="menu-title">{{menuItem.title}}</span>
              <span class="locked fal fa-lock" *ngIf="menuItem.locked"></span>
            </a>
    
            <!--If it have a submenu-->
            <a class="text-nowrap menu-item" [attr.data-intercom-target]="menuItem.title" data-toggle="collapse"
              href="#{{menuItem.title}}-manager" *ngIf="menuItem.children">
              <i class="{{menuItem.iconType}}"></i>
              <span class="menu-title">{{menuItem.title}}</span>
              <b class="caret"></b>
            </a>
    
            <!--Display the submenu items-->
            <div id="{{menuItem.title}}-manager" class="collapse" *ngIf="menuItem.children">
              <ul class="frankli-sidebar-submenu">
                <li routerLinkActive="active" *ngFor="let childItem of menuItem.children" [title]="globals.sidebarCollapsed ? childItem.title : ''">
                  <a class="text-nowrap menu-item" [attr.data-intercom-target]="childItem.title" [routerLink]="[childItem.path]">
                    <i class="{{childItem.iconType}}"></i>
                    <span class="menu-title">{{childItem.title}}</span>
                    <span class="locked fal fa-lock" *ngIf="childItem.locked"></span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </ng-container>
  
      <!-- Admin -->
      <ng-container *ngIf="checkIfAdmin(); let isAdmin">
        <hr class="line"/>
        <ul class="frankli-sidebar-group">
          <li routerLinkActive="active" *ngFor="let menuItem of adminMenuItems" [title]="globals.sidebarCollapsed ? menuItem.title : ''">
            <!--If is a single link-->
            <a class="text-nowrap menu-item" [attr.data-intercom-target]="menuItem.title" [routerLink]="[menuItem.path]"
              *ngIf="!menuItem.children">
              <i class="{{menuItem.iconType}}"></i>
              <span class="menu-title">{{menuItem.title}}</span>
              <span class="locked fal fa-lock" *ngIf="menuItem.locked"></span>
            </a>
            <!--If it have a submenu-->

            <ng-container *ngIf="menuItem.children">
              <a class="text-nowrap menu-item" [attr.data-intercom-target]="menuItem.title" data-toggle="collapse"
                href="#{{menuItem.title}}-admin">
                <i class="{{menuItem.iconType}}"></i>
                <span class="menu-title">{{menuItem.title}}</span>
                <b class="caret"></b>
              </a>
      
              <!--Display the submenu items-->
              <div id="{{menuItem.title}}-admin" class="collapse">
                <ul class="frankli-sidebar-submenu">
                  <li routerLinkActive="active" *ngFor="let childItem of menuItem.children"[title]="globals.sidebarCollapsed ? childItem.title : ''">
                    <a class="text-nowrap menu-item" [attr.data-intercom-target]="childItem.title"
                      [routerLink]="[menuItem.path, childItem.path]">
                      <i class="{{childItem.iconType}}"></i>
                      <span class="menu-title">{{childItem.title}}</span>
                      <span class="locked fal fa-lock" *ngIf="childItem.locked"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </ng-container>
          </li>
        </ul>
      </ng-container>
  
      <!-- Frankli admin-->
      <ng-container *ngIf="checkIfFrankliAdmin(); let isFrankliAdmin">
        <hr class="line" *ngIf="checkIfFrankliAdmin()" />
        <ul class="frankli-sidebar-group" *ngIf="checkIfFrankliAdmin()">
          <li routerLinkActive="active" *ngFor="let menuItem of frankliAdminMenuItems" [title]="globals.sidebarCollapsed ? menuItem.title : ''">
            <!--If is a single link-->
            <a class="text-nowrap menu-item" [routerLink]="[menuItem.path]" *ngIf="!menuItem.children">
              <i class="{{menuItem.iconType}}"></i>
              <span class="menu-title">{{menuItem.title}}</span>
            </a>
          </li>
        </ul>
      </ng-container>
  
      <!-- General -->
      <ng-container *ngIf="generalMenuItems?.length > 0">
        <hr class="line"/>
        <ul class="frankli-sidebar-group">
          <li routerLinkActive="active" *ngFor="let menuItem of generalMenuItems" [title]="globals.sidebarCollapsed ? menuItem.title : ''">
            <!--If is a single link-->
            <a class="text-nowrap menu-item" [attr.data-intercom-target]="menuItem.title" [href]="[menuItem.path]" target="_blank"
              *ngIf="!menuItem.children">
              <i class="{{menuItem.iconType}}"></i>
              <span class="menu-title">{{menuItem.title}}</span>
              <span class="locked fal fa-lock" *ngIf="menuItem.locked"></span>
            </a>
    
            <!--If it have a submenu-->
            <ng-container *ngIf="menuItem.children">
              <a class="text-nowrap menu-item" [attr.data-intercom-target]="menuItem.title" data-toggle="collapse"
                href="#{{menuItem.title}}-general">
                <i class="{{menuItem.iconType}}"></i>
                <span class="menu-title">{{menuItem.title}}</span>
                <b class="caret"></b>
              </a>
      
              <!--Display the submenu items-->
              <div id="{{menuItem.title}}-general" class="collapse">
                <ul class="frankli-sidebar-submenu">
                  <li routerLinkActive="active" *ngFor="let childItem of menuItem.children" [title]="childItem.title">
                    <a class="text-nowrap menu-item" [attr.data-intercom-target]="childItem.title"
                      [routerLink]="[menuItem.path, childItem.path]">
                      <i class="{{childItem.iconType}}"></i>
                      <span class="menu-title">{{childItem.title}}</span>
                      <span class="locked fal fa-lock" *ngIf="childItem.locked"></span>
                    </a>
                  </li>
                  <li class="divider"></li>
                  <li>
                    <a target="_blank" href="http://help.frankli.io" [attr.data-intercom-target]="'Help Center'">
                      <i class="fal fa-question-circle"></i> Help Center
                    </a>
                  </li>
                  <li class="divider"></li>
                  <!-- <li (click)="logout()">
                  <a angulartics2On="click" angularticsAction="LogoutNavbar" [attr.data-intercom-target]="'Logout Navbar'" angularticsCategory="Support">
                    <i class="fal fa-times-circle"></i> Log out
                  </a>
                </li> -->
                </ul>
              </div>
            </ng-container>
    
          </li>
        </ul>
      </ng-container>
    </div>
  
    <app-sidebar-setup *ngIf="globals.company && !globals.company.setUpComplete && (globals.hasRole(eRoleName.ADMIN) || globals.hasRole(eRoleName.FRANKLI_ADMIN))"></app-sidebar-setup>
  
    <div class="sidebar-collapse-toggle" (click)="globals.sidebarCollapsed = !globals.sidebarCollapsed" [title]="globals.sidebarCollapsed ? 'Expand sidebar' : 'Collapse sidebar'">
      <span class="fal frankli-view expand-on-hover" [ngClass]="(globals.sidebarCollapsed ? 'fa-fw fa-chevron-right' : 'fa-chevron-left')"></span>
    </div>
  </div>
</div>