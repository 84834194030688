<!-- Universal filter and export -->
<div class="text-center p-left-15 p-right-15 p-top-15 p-bottom-10" [ngClass]="showUniversalFilter ? 'position-filters' : 'p-relative overlay-top'">
  <span class="center-container">
    <!-- (Search / Filter) -->
    <span class="uni-filter-container" *ngIf="showUniversalFilter && filterReady">
      <app-universal-filter [filterOptions]="filterOptions" [searchProps]="searchProps" (resultEmit)="universalFilterEmit($event)" #universalFilter></app-universal-filter>
    </span>

    <!-- Column Toggle -->
    <span class="col-toggle-container">
      <app-column-toggle [titles]="columnTitles" #columnToggle></app-column-toggle>
    </span>
  </span>
</div>

<div [class.container-sticky]="stickyPagination" *ngIf="columnToggle">
  <div class="scroll-x" *ngIf="goals.length > 0">
    <table width="100%">

      <!-- Table headings -->
      <tr class="frankli-table frankli-table-header frankli-table-header-large noselect">
        <!-- State ribbon -->
        <th *ngIf="columnToggle.getColumnEnabled(ePageColumns.STATE)"></th>

        <!-- Dropdown -->
        <th *ngIf="!columnToggle.checkAllDisabled()"></th>

        <!-- Priority -->
        <th title="Priority" class="text-center" (click)="doSort(ePageColumns.PRIORITY)"
          [class.sorting]="sort.type !== ePageColumns.PRIORITY"
          *ngIf="columnToggle.getColumnEnabled(ePageColumns.PRIORITY) && globals.hasFeature(eFeature.GOAL_PRIORITY)"
          [class.sorting-ascending]="sort.type === ePageColumns.PRIORITY && sort.direction === eSortDirection.ASCENDING"
          [class.sorting-descending]="sort.type === ePageColumns.PRIORITY && sort.direction === eSortDirection.DESCENDING">Priority</th>

        <!-- Type -->
        <th title="Type" class="text-center" (click)="doSort(ePageColumns.TYPE)" [class.sorting]="sort.type !== ePageColumns.TYPE"
          *ngIf="columnToggle.getColumnEnabled(ePageColumns.TYPE)"
          [class.sorting-ascending]="sort.type === ePageColumns.TYPE && sort.direction === eSortDirection.ASCENDING"
          [class.sorting-descending]="sort.type === ePageColumns.TYPE && sort.direction === eSortDirection.DESCENDING">Type</th>

        <!-- Key Results -->
        <th title="Key Results" class="text-center" (click)="doSort(ePageColumns.KEY_RESULTS)"
          *ngIf="columnToggle.getColumnEnabled(ePageColumns.KEY_RESULTS)" [class.sorting]="sort.type !== ePageColumns.KEY_RESULTS"
          [class.sorting-ascending]="sort.type === ePageColumns.KEY_RESULTS && sort.direction === eSortDirection.ASCENDING"
          [class.sorting-descending]="sort.type === ePageColumns.KEY_RESULTS && sort.direction === eSortDirection.DESCENDING">Key
          Results</th>

        <!-- Goal Title -->
        <th title="Title" class="text-left" (click)="doSort(ePageColumns.TITLE)" [class.sorting]="sort.type !== ePageColumns.TITLE"
          *ngIf="columnToggle.getColumnEnabled(ePageColumns.TITLE)"
          [class.sorting-ascending]="sort.type === ePageColumns.TITLE && sort.direction === eSortDirection.ASCENDING"
          [class.sorting-descending]="sort.type === ePageColumns.TITLE && sort.direction === eSortDirection.DESCENDING">Title</th>

        <!-- Department -->
        <th title="{{companyWording.department | titlecase}}" class="text-left" (click)="doSort(ePageColumns.DEPARTMENT)"
          *ngIf="columnToggle.getColumnEnabled(ePageColumns.DEPARTMENT)" [class.sorting]="sort.type !== ePageColumns.DEPARTMENT"
          [class.sorting-ascending]="sort.type === ePageColumns.DEPARTMENT && sort.direction === eSortDirection.ASCENDING"
          [class.sorting-descending]="sort.type === ePageColumns.DEPARTMENT && sort.direction === eSortDirection.DESCENDING">{{companyWording.department | titlecase}}</th>

        <!-- Progress bar -->
        <th title="Progress" class="text-left" (click)="doSort(ePageColumns.PROGRESS)"
          *ngIf="columnToggle.getColumnEnabled(ePageColumns.PROGRESS)" [class.sorting]="sort.type !== ePageColumns.PROGRESS"
          [class.sorting-ascending]="sort.type === ePageColumns.PROGRESS && sort.direction === eSortDirection.ASCENDING"
          [class.sorting-descending]="sort.type === ePageColumns.PROGRESS && sort.direction === eSortDirection.DESCENDING">Progress</th>

        <!-- End Date -->
        <th title="End Date" class="text-center" (click)="doSort(ePageColumns.END_DATE)"
          *ngIf="columnToggle.getColumnEnabled(ePageColumns.END_DATE)" [class.sorting]="sort.type !== ePageColumns.END_DATE"
          [class.sorting-ascending]="sort.type === ePageColumns.END_DATE && sort.direction === eSortDirection.ASCENDING"
          [class.sorting-descending]="sort.type === ePageColumns.END_DATE && sort.direction === eSortDirection.DESCENDING">End Date</th>
      </tr>

      <!-- Table contents -->
      <ng-container *ngFor="let goal of goalsDisplayed; index as i; trackBy: trackGoalsDisplayed">
        <!-- Primary goal info -->
        <tr class="frankli-table frankli-table-hover frankli-table-single-line">

          <!-- Status highlight -->
          <td class="chrome-position-fix" *ngIf="columnToggle.getColumnEnabled(ePageColumns.STATE)">
            <div class="goal-status-highlight" [title]="eGoalHelperFunctions.getStatusTitle(goal)" [ngClass]="eGoalHelperFunctions.getStatusHighlight(goal)"></div>
          </td>

          <!-- Dropdown -->
          <td *ngIf="!columnToggle.checkAllDisabled()" (click)="toggleDropdown(i)">
            <span class="fal fa-chevron-right dropdown-toggle" [class.dropdown-opened]="opened[i]"></span>
          </td>

          <!-- Priority -->
          <td *ngIf="columnToggle.getColumnEnabled(ePageColumns.PRIORITY) && globals.hasFeature(eFeature.GOAL_PRIORITY)" (click)="navigateToGoal(goal.id)">
            <div class="text-center">
              <span [class]="'icon-priority no-margin priority-' + goal.priority">{{goal.priority}}</span>
            </div>
          </td>

          <!-- Type -->
          <td class="text-center" *ngIf="columnToggle.getColumnEnabled(ePageColumns.TYPE)" (click)="navigateToGoal(goal.id)" [ngSwitch]="goal.type">
            <span title="Personal Operational Goal" class="fal fa-user goal-type-icon" *ngSwitchCase="eGoalType.PERSONAL_OPERATIONAL"></span>
            <span title="Personal Developmental Goal" class="fal fa-graduation-cap goal-type-icon" *ngSwitchCase="eGoalType.PERSONAL_DEVELOPMENTAL"></span>
            <span title="{{companyWording.team | titlecase}} Goal" class="fal fa-user-friends goal-type-icon" *ngSwitchCase="eGoalType.TEAM"></span>
            <span title="{{companyWording.department | titlecase}} Goal" class="fal fa-users goal-type-icon" *ngSwitchCase="eGoalType.DEPARTMENT"></span>
            <span title="Company Goal" class="fal fa-building goal-type-icon" *ngSwitchCase="eGoalType.COMPANY"></span>
            <span title="Site Goal" class="fal fa-business-time goal-type-icon" *ngSwitchCase="eGoalType.OFFICE_LOCATION"></span>
          </td>

          <!-- Key result count -->
          <td class="key-results-align text-nowrap text-center" *ngIf="columnToggle.getColumnEnabled(ePageColumns.KEY_RESULTS)" (click)="navigateToGoal(goal.id)">
            <span>{{goal.keyResults.length}} key results</span>
          </td>

          <!-- Title -->
          <td *ngIf="columnToggle.getColumnEnabled(ePageColumns.TITLE)" (click)="navigateToGoal(goal.id)">
            <span class="wrap-title">{{goal.title}}</span>
          </td>

          <!-- Department -->
          <td *ngIf="columnToggle.getColumnEnabled(ePageColumns.DEPARTMENT)" (click)="navigateToGoal(goal.id)">{{goal.department ? goal.department.name : ''}}</td>

          <!-- Progress bar -->
          <td *ngIf="columnToggle.getColumnEnabled(ePageColumns.PROGRESS)" (click)="navigateToGoal(goal.id)">
            <tr>
              <td>
                <div class="min-width-progress">
                  <span>
                    <div class="progress m-bottom-0">
                      <div class="progress-bar progress-bar-info" [style.width]="goal.completionPercentage+'%'">
                        <span class="sr-only"></span>
                      </div>
                    </div>
                  </span>
                </div>
              </td>
              <td>
                <div>
                  <div class="m-left-10 m-right-5">{{goal.completionPercentage | number: '1.0-2'}}%</div>
                </div>
              </td>
            </tr>
          </td>

          <!-- Due Date -->
          <td class="text-center date-line-height" (click)="navigateToGoal(goal.id)" [class.text-danger]="getDaysRemainingTotal(goal.endDate) < 0 && goal.complete === false && goal.archived === false" *ngIf="columnToggle.getColumnEnabled(ePageColumns.END_DATE)">
            <span>{{goal.endDate | date:'d MMMM y'}}
              <span *ngIf="goal.complete === false && goal.archived === false">
                <br />
                ({{getDaysRemaining(goal.endDate)}})
              </span>
            </span>
          </td>
        </tr>

        <!-- Dropdown contents -->
        <tr *ngIf="opened[i] && !columnToggle.checkAllDisabled()">
          <td colspan="50">
            <app-goal-dropdown [goal]="goal"></app-goal-dropdown>
          </td>
        </tr>
      </ng-container>

      <tr *ngIf="columnToggle.checkAllDisabled()">
        <td class="text-center">
          <div class="m-top-20 m-bottom-20">No columns selected to display, you can choose which columns to show by
            clicking the <span class="fal fa-columns text-info"></span> icon above</div>
        </td>
      </tr>
    </table>
  </div>

  <div [class.position-pagination]="stickyPagination">
    <app-pagination-new-component *ngIf="!columnToggle.checkAllDisabled()" class="m-top-10" [arrayData]="goalsFiltered" [arrayDisplay]="goalsDisplayed" [currentPage]="page" (pageChanged)="paginationChanged($event)" #pagination></app-pagination-new-component>
  </div>
</div>

<div class="text-center p-bottom-15" *ngIf="goals.length === 0">
  <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
  <br>
  <p> There are no goals available for this search</p>
  <button *ngIf="(type === eGoalOverviewPages.COMPANY && globals.hasRole(eRoleName.GOAL_COMPANY)) || (type !== eGoalOverviewPages.COMPANY)"
    class="btn-frankli-round btn-frankli-green" (click)="showGoalCreate()"
    angulartics2On="click" angularticsAction="ShowGoalsCreateModal" angularticsCategory="Goals">
    Create a Goal
  </button>
</div>