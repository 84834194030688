import { CoachingRequest } from '@app/models/connect/coaching-request/coaching-request.model';
import { CoachingRequestStatus } from '@app/models/connect/type/coaching-request-status.enum';
import { mockCoachingRequestAnswers } from '@app/mock/api/data/mockConnectQuestionsAnswered';
import * as moment from 'moment';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { mockConnectSkills } from '@app/mock/api/data/mockConnectSkills';

export const mockCoachingRequests: CoachingRequest[] = [
  {
    id: 1,
    createdTimestamp: moment().subtract(1, 'weeks').toDate(),
    lastEditTimestamp: moment().subtract(1, 'weeks').toDate(),
    companyId: 1,
    coacheeManagerId: mockUsers[1].managerId,
    allottedHours: 1,
    status: CoachingRequestStatus.COACH_APPROVED,
    coachId: mockUsers[0].id,
    coach: mockUsers[0],
    coacheeId: mockUsers[1].id,
    coachee: mockUsers[1],
    skills: [
      mockConnectSkills[0],
      mockConnectSkills[1]
    ],
    answers: mockCoachingRequestAnswers,
    managerComment: '',
    coachComment: ''
  }
];