import { Component, Input, OnInit } from '@angular/core';
import { PeerFeedback, PeerFeedbackType } from '@app/models/feedback/peer-feedback.model';
import { UserAPIService } from '@app/shared/api/user.api.service';

@Component({
  selector: 'app-feedback-peer-display',
  templateUrl: './feedback-peer-display.component.html',
  styleUrls: ['./feedback-peer-display.component.css']
})
export class FeedbackPeerDisplayComponent implements OnInit {
  @Input() feedback: PeerFeedback;

  @Input() showSourceUser: boolean;
  @Input() showDestinationUser: boolean;

  ePeerFeedbackType = PeerFeedbackType;

  constructor(
    private userAPIService: UserAPIService
  ) {
    this.feedback = undefined!;

    this.showSourceUser = true;
    this.showDestinationUser = true;
  }

  ngOnInit() {
  }
}
