import { Location } from '@angular/common';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NewFeature } from '@app/models/new-feature/new-feature.model';
import { FrankliNotificationType } from '@app/models/notifications/frankli-event-type.enum';
import { FrankliNotification } from '@app/models/notifications/frankli-notification.model';
import { CompanyFeatures, CompanyState } from 'app/models/company.model';
import { RoleName } from 'app/models/role.model';
import { User } from 'app/models/user/user.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { NewFeatureAPIService } from '../api/new-feature.api.service';
import { UserAPIService } from '../api/user.api.service';
import { AuthAPIService } from '../auth/auth.api.service';
import { Globals } from '../globals/globals';
import { NotificationService } from '../globals/notification.service';
import { ModalComponent } from '../modal/modal.component';
import { environment } from '../../../environments/environment';

const misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};
declare var $: any;

export enum SidebarTabs {
  NOTIFICATIONS = 'NOTIFICATIONS',
  TASKS = 'TASKS'
}

interface NewFeatureSub {
  unreadCount: number;
  all: NewFeature[];
}

@Component({
  selector: 'app-navbar-cmp',
  templateUrl: 'navbar.component.html',
  styleUrls: ['navbar.component.css'],
})

export class NavbarComponent implements OnInit, OnDestroy {
  @ViewChild('newFeaturesModal') newFeaturesModal!: ModalComponent;

  connected: boolean;

  sidebarTab: SidebarTabs;
  notificationsShown: boolean;
  listTitles: any[];
  location: Location;
  nativeElement: Node;
  toggleButton: any;
  sidebarVisible: boolean;
  sidebarIcon = 'left';
  searchValue: string;

  users: Array<User>;
  notifications: Array<FrankliNotification>;
  tasks: Array<FrankliNotification>;

  destroy$: Subject<boolean> = new Subject<boolean>();

  eCompanyState = CompanyState;
  eCompanyFeatures = CompanyFeatures;
  eRoleName = RoleName;
  eFrankliNotificationType = FrankliNotificationType;

  @ViewChild('app-navbar-cmp') button!: ElementRef;

  // marking as read
  markingAsRead: Array<number>;
  // tasks that have been clicked on but not actioned
  viewed: Array<number>;

  newFeaturesUnreadCount = 0;
  newFeatures: NewFeatureSub = {
    unreadCount: 0,
    all: []
  };

  // If click event fires inside the component, set to true
  // If click event fires outside, hide notifications then reset variable
  private inside = false;
  isMock = environment.mock.enabled;
  @HostListener('click')
  clickInside() {
    this.inside = true;
  }

  @HostListener('document:click')
  clickOutside() {
    if (this.inside === false) {
      this.notificationsShown = false;
    }
    this.inside = false;
  }

  constructor(
    location: Location,
    private element: ElementRef,
    private authService: AuthAPIService,
    private router: Router,
    private title: Title,
    public globals: Globals,
    private notificationService: NotificationService,
    private userAPIService: UserAPIService,
    private newFeatureService: NewFeatureAPIService
  ) {
    this.connected = false;
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
    this.notificationsShown = false;
    this.sidebarTab = SidebarTabs.TASKS;

    this.users = new Array<User>();
    this.notifications = new Array<FrankliNotification>();
    this.tasks = new Array<FrankliNotification>();

    this.markingAsRead = new Array<number>();
    this.viewed = new Array<number>();
    this.listTitles = [];
    this.searchValue = '';

    /**
     * This subscribes the navbar component to the receive live notifications via the notification service
     */

    this.notificationService.getNotifications$().pipe(takeUntil(this.destroy$)).subscribe((notification) => {
      this.handleNotification(notification);
    });

    /**
     * If websocket connection closes, handle that here
     */
    this.connected = this.notificationService.isConnected();
    if (this.connected === true) {
      this.init();
    }
    this.notificationService.isConnected$().pipe(takeUntil(this.destroy$)).subscribe((connected: boolean) => {
      this.connected = connected;
      if (this.connected === true) {
        this.init();
      }
    });
  }

  ngOnInit() {
    this.newFeatureService.getAllNewFeatures().subscribe((features) => this.parseNewFeatures(features));

    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    if ($('body').hasClass('sidebar-mini')) {
      misc.sidebar_mini_active = true;
    }
    $('#minimizeSidebar').click(function () {
      const $btn = $(this);

      if (misc.sidebar_mini_active === true) {
        $('body').removeClass('sidebar-mini');
        misc.sidebar_mini_active = false;

      } else {
        setTimeout(function () {
          $('body').addClass('sidebar-mini');

          misc.sidebar_mini_active = true;
        }, 300);
      }

      // we simulate the window Resize so the charts will get updated in realtime.
      const simulateWindowResize = setInterval(function () {
        window.dispatchEvent(new Event('resize'));
      }, 180);

      // we stop the simulation of Window Resize after the animations are completed
      setTimeout(function () {
        clearInterval(simulateWindowResize);
      }, 1000);
    });

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private init() {
    // clear notifications that might already exist
    this.notifications.length = 0;
    this.tasks.length = 0;

    // Handle
    const getN = this.notificationService.getNotifications();
    for (let i = 0; i < getN.length; i++) {
      const notification = getN[i];
      this.handleNotification(notification);
    }
    this.notifications.sort((a, b) => {
      return b.id - a.id;
    });
  }

  private handleNotification(notification: FrankliNotification): void {
    if (notification.eventStatus === 'READ') {
      this.removeNotification(notification);
    } else {
      this.addNotification(notification);
    }
    this.updateTitle();
    this.notifications.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
    this.tasks.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
  }

  private removeNotification(notification: FrankliNotification): void {
    if (notification.task === true) {
      this.tasks = this.tasks.filter(n => n.id !== notification.id);
    } else {
      this.notifications = this.notifications.filter(n => n.id !== notification.id);
    }

    // remove from marking as read and viewed
    this.markingAsRead = this.markingAsRead.filter(m => m !== notification.id);
    this.viewed = this.viewed.filter(m => m !== notification.id);
  }

  private addNotification(notification: FrankliNotification): void {
    if (notification.task) {
      this.tasks.unshift(notification);
    } else {
      this.notifications.unshift(notification);
    }
  }

  isMobileMenu() {
    return $(window).width() <= 991;
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName('body')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);
    body.classList.add('nav-open');
    this.sidebarVisible = true;
  }

  sidebarClose() {
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    body.classList.remove('nav-open');
  }

  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  getTitle() {
    let titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(2);
    }
    for (let item = 0; item < this.listTitles.length; item++) {
      const parent = this.listTitles[item];
      if (parent.path === titlee) {
        return parent.title;
      } else if (parent.children) {
        const children_from_url = titlee.split('/')[2];
        for (let current = 0; current < parent.children.length; current++) {
          if (parent.children[current].path === children_from_url) {
            return parent.children[current].title;
          }
        }
      }
    }
    return 'Dashboard';
  }

  getPath() {
    return this.location.prepareExternalUrl(this.location.path());
  }

  sidebarToggleIcon() {
    if (this.sidebarIcon === 'left') {
      this.sidebarIcon = 'right';
    } else {
      this.sidebarIcon = 'left';
    }
  }

  checkEnter(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.searchValue !== '' && this.searchValue) {
      const sarg = this.searchValue;
      this.searchValue = '';
      this.router.navigate(['/search-results'], { queryParams: { q: sarg } });
    }
  }

  logout() {
    if (this.globals.hasUnsavedChanges) {
      Swal.fire({
        title: 'Unsaved Changes',
        text: 'Your changes will not be saved if you leave!',
        imageUrl: 'assets/img/swal-icons/Frankli_sure_icon-46.svg',
        imageWidth: 140,
        imageHeight: 140,
        confirmButtonText: 'Stay on page',
        confirmButtonColor: '#54C6BB',
        cancelButtonText: 'Leave without saving',
        showCancelButton: true,
        width: 390,
        reverseButtons: true
      }).then(val => {
        if (val.dismiss) {
          if (val.dismiss.toString() === 'cancel') {
            this.authService.logout();
          }
        }
      });
    } else {
      this.authService.logout();
    }
  }

  updateTitle() {
    const count = this.notifications.length + this.tasks.length;
    if (count > 0) {
      this.title.setTitle('(' + count + ') Frankli');
    } else {
      this.title.setTitle('Frankli');
    }
  }

  public markAsRead(notification: FrankliNotification): void {
    this.markingAsRead.push(notification.id);
    this.notificationService.markAsRead(notification.id);
  }

  public markAllAsRead() {
    this.notifications.forEach(n => this.markingAsRead.push(n.id));
    this.notificationService.markAllAsRead();
  }

  /**
   * Marks a task as viewed (for frontend use only)
   * @param notification
   */
  public markAsViewed(notification: FrankliNotification): void {
    notification.viewed = true;
  }

  openNotificationsPanel() {
    if (!this.notificationsShown) {
      if ((this.tasks.length > 0) && (this.notifications.length === 0)) {
        this.sidebarTab = SidebarTabs.TASKS;
      } else if ((this.notifications.length > 0) && (this.tasks.length === 0)) {
        this.sidebarTab = SidebarTabs.NOTIFICATIONS;
      }
    }
    this.notificationsShown = !this.notificationsShown
  }

  public onMarkAllAsRead($event: void): void {
    this.markAllAsRead();
  }
  public onMarkAsRead($event: FrankliNotification): void {
    this.markAsRead($event);
  }
  public onMarkAsViewed($event: FrankliNotification): void {
    this.markAsViewed($event);
  }

  parseNewFeatures(features: NewFeature[]) {
    const noArchived = features
      .filter(f => !f.archived)
      .sort((a, b) => {
        // Sort null dates to start
        if (!(a && a.publishDate)) { return -1; }
        if (!(b && b.publishDate)) { return 1; }

        // If both are dates, compare
        const aDate = new Date(a.publishDate).getTime();
        const bDate = new Date(b.publishDate).getTime();

        return bDate - aDate;
      });

    this.newFeaturesUnreadCount = noArchived.filter(f => !f.viewed).length;
    this.newFeatures = {
      unreadCount: this.newFeaturesUnreadCount,
      all: noArchived
    };
  }

  openNewFeatures() {
    if (this.newFeaturesModal) {
      this.newFeaturesModal.show();
      this.newFeatureService.markAllAsRead().toPromise()
        .then((features) => this.parseNewFeatures(features))
    }
  }

}
