import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { UserStickiness } from '@app/models/session-log/user-stickiness.model';

@Component({
  selector: 'app-session-log-user-stickiness-overview-weekly-component',
  templateUrl: './session-log-user-stickiness-overview-weekly.component.html',
  styleUrls: ['./session-log-user-stickiness-overview-weekly.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush // For performance reasons
})
export class SessionLogUserStickinessOverviewWeeklyComponent implements OnChanges, OnInit {
  @Input() showHeader: boolean = true;
  @Input() data: Array<UserStickiness> = new Array<UserStickiness>();
  public dataDisplay: Array<UserStickiness> = new Array<UserStickiness>();

  constructor() { }

  ngOnChanges(): void {
    this.dataDisplay = this.data.filter(data => data.date.getDay() === 0);
  }

  ngOnInit(): void { }

  public calculateWeeklyActiveVsTotal(userStickiness: UserStickiness): string {
    if (userStickiness.totalUsers === 0) {
      return 'N/A';
    }
    return ((userStickiness.weeklyActiveUsers / userStickiness.totalUsers) * 100).toFixed(2);
  }

}
