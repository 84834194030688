import { Todo } from '@app/models/todos/todos.model';
import * as moment from 'moment';

export const mockTodos: Todo[] = [
    {
        id: 1,
        title: 'Onboard team onto Frankli',
        dueDate: moment().add('5', 'days').toDate(),
        userId: 1,
        creationDate: new Date(),
        completionDate: null
    },
    {
        id: 2,
        title: 'Set-up virtual remote event for entire team',
        dueDate: moment().add('7', 'days').toDate(),
        userId: 1,
        creationDate: new Date(),
        completionDate: null
    },
    {
        id: 3,
        title: 'Review and agree all company OKRs before setting live',
        dueDate: moment().add('10', 'days').toDate(),
        userId: 1,
        creationDate: new Date(),
        completionDate: null
    },
    {
        id: 4,
        title: 'Speak to your team about updating their goals weekly',
        dueDate: moment().add('1', 'week').toDate(),
        userId: 1,
        creationDate: new Date(),
        completionDate: null
    },
    {
        id: 5,
        title: 'Follow up on training budget',
        dueDate: moment().add('1', 'week').toDate(),
        userId: 1,
        creationDate: new Date(),
        completionDate: null
    },
];