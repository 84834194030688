import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'age'
})
export class AgePipe implements PipeTransform {
  previousValue!: Date;
  resultCached!: string;

  transform(value: Date): string {
    // Skip calculation if value hasn't changed
    if ((value === this.previousValue) && this.resultCached) {
      return this.resultCached;
    }
    this.previousValue = value;

    const result = this.dateDiffInYears(new Date(value), new Date()).toString();
    this.resultCached = result;
    return result;
  }


  // a and b are javascript Date objects
  private dateDiffInYears(a: Date, b: Date) {
    const MS_PER_YEAR = 1000 * 60 * 60 * 24 * 365;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / MS_PER_YEAR);
  }
}

