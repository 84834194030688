<!-- Demo Banner -->
<ng-container *ngIf="displayBanner === true && company.state === eCompanyState.DEMO && company.configuration.showSandboxBanner">
  <div class="demo-ribbon demo-ribbon-warning text-center">
    <span>
      <i class="fal fa-info-circle"></i>
      {{ user.firstName }}, you're still on the demo version of frankli.
      <a style="text-decoration: underline; color: #333; cursor: pointer;" href="mailto:hello@frankli.io"
        target="_blank">Upgrade Now</a>
    </span>
    <div class="demo-ribbon-close">
      <span class="fal fa-times" (click)="hideBanner()"></span>
    </div>
  </div>
</ng-container>

<!-- Public Demo Banner -->
<ng-container *ngIf="displayBanner && isMock">
  <div class="public-demo-ribbon demo-ribbon-warning text-center">
    <span>
      <span class="fal fa-info-circle m-right-15"></span>
      Hey there! You're interacting with a read-only version of Frankli -
      <a style="text-decoration: underline; color: #333; cursor: pointer;" href="https://frankli.io/demo-request/" target="_blank">
        Request a Product Tour with a member of the Team today
      </a>
      or <a style="text-decoration: underline; color: #333; cursor: pointer;" href="https://www.frankli.io/get-started">start your free trial here.</a>
    </span>
  </div>
</ng-container>

<app-modal #modalRequestSandbox [background]="'light'" [width]="'20%'" [minWidth]="'400px'">
  <div class="modal-body-content">
    <app-request-sandbox-component></app-request-sandbox-component>
  </div>
</app-modal>

<!-- App Update Banner -->
<ng-container *ngIf="updateStatus$ | async">
  <div class="demo-ribbon demo-ribbon-info text-center">
    <a class="nolink" (click)="reloadApp()"><span><strong>Update Available.</strong> Click here to update Frankli.
      </span>
      <span class="fal fa-redo"></span></a>
    <div class="demo-ribbon-close"></div>
  </div>
</ng-container>

<!-- Trial Banner -->
<ng-container *ngIf="
    displayBanner === true &&
    company?.state === eCompanyState.FULL &&
    company?.trial !== null
  ">
  <div class="demo-ribbon demo-ribbon-warning text-center">
    <ng-container *ngIf="getDayDifference() > 1">
      <span>Your trial ends in {{ getDayDifference() }} days.</span>
    </ng-container>
    <ng-container *ngIf="getDayDifference() === 1">
      <span>Your trial ends in 1 day.</span>
    </ng-container>
    <ng-container *ngIf="getDayDifference() === 0">
      <span>Your trial ends today</span>
    </ng-container>
    <ng-container *ngIf="getDayDifference() < 0">
      <span>Your trial has expired</span>
    </ng-container>
    <div class="demo-ribbon-close">
      <span class="fal fa-times" (click)="hideBanner()"></span>
    </div>
  </div>
</ng-container>

<!-- Site Wrapper -->
<div class="wrapper">
  <app-sidebar-cmp></app-sidebar-cmp>
  <div class="main-panel" [class.sidebar-collapsed]="globals.sidebarCollapsed">
    <app-navbar-cmp></app-navbar-cmp>

    <!-- content - lazy loading spinner moved to app.component.ts -->
    <div class="main-content" [class.pattern-background]="routeHasPatternBackground()">
      <div [class.hidden]="lazyLoading">
        <app-breadcrumbs></app-breadcrumbs>
        <router-outlet></router-outlet>
      </div>
    </div>
    <app-footer-cmp></app-footer-cmp>
  </div>
</div>

<!-- Error Overlays -->
<ng-container *ngIf="displayOverlay === true">
  <div class="overlay-wrapper">
    <!-- No Internet Connection -->
    <ng-container *ngIf="status === 0">
      <app-overlay-no-internet-connection-component></app-overlay-no-internet-connection-component>
    </ng-container>
    <!-- Logged Out -->
    <ng-container *ngIf="status === 200">
      <app-overlay-login-component></app-overlay-login-component>
    </ng-container>
    <!-- Server Unavailable -->
    <ng-container *ngIf="status === 502 || status === 503 || status === 504">
      <app-overlay-server-unreachable-component></app-overlay-server-unreachable-component>
    </ng-container>
  </div>
</ng-container>