import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CreateInterestDto, Interest, InterestCategory, UpdateInterestDto } from 'app/models/interest.model';
import { Observable } from 'rxjs';
import { InterestService } from '@app/shared/api/interfaces/interest.service';

@Injectable()
export class InterestAPIService implements InterestService {
  http: HttpClient;
  constructor(private _http: HttpClient) {
    this.http = _http;
  }

  getAllPersonal() {
    const url = '/api/interests/personal';
    return this.http.get<Array<Interest>>(url);
  }

  getAllProfessional() {
    const url = '/api/interests/professional';
    return this.http.get<Array<Interest>>(url);
  }

  getPersonalCategories() {
    const url = 'api/interests/personal/categories';
    return this.http.get<Array<InterestCategory>>(url);
  }

  getProfessionalCategories() {
    const url = 'api/interests/professional/categories';
    return this.http.get<Array<InterestCategory>>(url);
  }

  searchInterests(sarg: string) {
    const url = 'api/interests/search';
    return this.http.post<Array<Interest>>(url, sarg);
  }

  getAllInterests(): Observable<Array<Interest>> {
    const url = 'api/interests';
    return this.http.get<Array<Interest>>(url);
  }

  createInterest(createInterestDto: CreateInterestDto): Observable<Interest> {
    const url = 'api/interests';
    return this.http.put<Interest>(url, createInterestDto);
  }

  getPendingInterests(): Observable<Array<Interest>> {
    const url = 'api/interests/pending';
    return this.http.get<Array<Interest>>(url);
  }

  approvePendingInterest(id: number): Observable<Interest> {
    const url = 'api/interests/pending/approve/' + id;
    return this.http.post<Interest>(url, null);
  }

  importInterests(file: File): Observable<Array<Interest>> {
    const url = 'api/interests/file';
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<Array<Interest>>(url, formData);
  }

  updateInterest(id: number, updateInterestDto: UpdateInterestDto): Observable<Interest> {
    const url = 'api/interests/' + id;
    return this.http.patch<Interest>(url, updateInterestDto);
  }

  deleteInterest(id: number): Observable<Interest> {
    const url = 'api/interests/' + id;
    return this.http.delete<Interest>(url);
  }

}
