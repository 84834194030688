import {Component, OnInit} from '@angular/core';
import {AuthAPIService} from '../auth/auth.api.service';
import {Globals} from '../globals/globals';
import {RoleName} from 'app/models/role.model';

@Component({
  selector: 'app-feature-not-enabled-component',
  templateUrl: './feature-not-enabled.component.html',
  styleUrls: ['./feature-not-enabled.component.css']
})
export class FeatureNotEnabledComponent implements OnInit {
  globals: Globals;
  eRole = RoleName;

  constructor(private authService: AuthAPIService, globals: Globals) {
    this.globals = globals;
  }

  ngOnInit() {

  }
}
