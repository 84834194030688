export enum GoalOverviewPages {
  MY_GOALS = 'My Goals',
  PERSONAL = 'Personal Goals',
  TEAM = 'Team Goals',
  DEPARTMENT = 'Department Goals',
  OFFICE_LOCATION = 'Site Goals',
  COMPANY = 'Company Goals',
  ADMIN_GOAL = 'Goal Admin',
  DRAFTS = 'Draft Goals',
  TAGS = 'Goal Tags',
}