import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CallbackComponent } from '@app/callback/callback.component';
import { CompanyFeatures } from '@app/models/company.model';
import { FeatureGuard } from '@app/shared/auth/feature.guard';
import { FeatureLockedComponent } from '@app/shared/feature-locked/feature-locked.component';
import { Angulartics2Module } from 'angulartics2';
import { OrgChartComponent } from 'app/employee/employee-components/org-chart/org-chart.component';
import { EmployeeLayoutComponent } from 'app/shared/layouts/employee/employee-layout.component';
import { PeopleDirectoryComponent } from './employee/employee-components/people-directory/people-directory.component';
import { ArchivedComponent } from './shared/archived/archived.component';
import { AuthenticatedGuard } from './shared/auth/authenticated.guard';
import { NewUserGuard } from './shared/auth/newuser.guard';
import { PasswordResetAllowGuard } from './shared/auth/password-reset-allow.guard';
import { PasswordResetGuard } from './shared/auth/password-reset.guard';
import { SignUpGuard } from './shared/auth/sign-up.guard';
import { UnauthenticatedGuard } from './shared/auth/unauthenticated.guard';
import { UserAccessRevokedGuard } from './shared/auth/user-access-revoked.guard';
import { UserArchivedGuard } from './shared/auth/user-archived.guard';
import { FeatureNotEnabledComponent } from './shared/feature-not-enabled/feature-not-enabled.component';
import { LoginLayoutComponent } from './shared/layouts/login/login-layout.component';
import { LoginComponent } from './shared/login/login.component';
import { LogoutComponent } from './shared/logout/logout.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { ResetPasswordEmailComponent } from './shared/reset-password-email/reset-password-email.component';
import { ResetPasswordNewComponent } from './shared/reset-password-new/reset-password-new.component';
import { RevokedComponent } from './shared/revoked/revoked.component';
import { SignUpComponent } from './shared/sign-up/sign-up.component';


const appRoutes = [
  {
    path: 'login',
    canActivate: [UnauthenticatedGuard],
    component: LoginLayoutComponent, children: [
      { path: '', component: LoginComponent },
    ],
  },
  {
    path: 'callback',
    component: CallbackComponent,
  },
  {
    path: 'sign-up',
    canActivate: [AuthenticatedGuard, SignUpGuard],
    component: LoginLayoutComponent, children: [
      { path: '', component: SignUpComponent },
    ],
  },
  {
    path: 'reset',
    component: LoginLayoutComponent, children: [
      { path: '', component: ResetPasswordEmailComponent, canActivate: [UnauthenticatedGuard] },
      {
        path: 'password',
        component: ResetPasswordNewComponent,
        canActivate: [AuthenticatedGuard, PasswordResetAllowGuard],
      },
    ],
  },
  {
    path: 'revoked',
    canActivate: [AuthenticatedGuard],
    component: RevokedComponent,
  },
  {
    path: 'archived',
    canActivate: [AuthenticatedGuard],
    component: ArchivedComponent,
  },
  {
    path: 'logout',
    canActivate: [AuthenticatedGuard],
    component: LogoutComponent,
  },
  {
    path: 'external-feedback',
    canActivate: [UnauthenticatedGuard],
    loadChildren: () => import('./external-feedback/external-feedback.module').then(m => m.ExternalFeedbackModule)
  },
  {
    path: 'external-survey',
    canActivate: [UnauthenticatedGuard],
    loadChildren: () => import('./external-survey/external-survey.module').then(m => m.ExternalSurveyModule),
  },
  {
    path: '',
    component: EmployeeLayoutComponent,
    canActivate: [AuthenticatedGuard, UserArchivedGuard, UserAccessRevokedGuard, PasswordResetGuard, NewUserGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
      {
        path: 'people-directory', canActivate: [FeatureGuard], component: PeopleDirectoryComponent,
        data: { label: 'People Directory', feature: CompanyFeatures.PEOPLE_DIRECTORY },
        children: [
          {
            path: 'profile',
            loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
          },
        ],
      },
      {
        path: 'org-chart',
        data: { feature: CompanyFeatures.PEOPLE_DIRECTORY },
        canActivate: [FeatureGuard],
        component: OrgChartComponent,
      },
      {
        path: 'frankli-admin',
        loadChildren: () => import('./frankli-admin/frankli-admin.module').then(m => m.FrankliAdminModule),
      },
      {
        path: 'search-results',
        loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
      }, {
        path: 'manage',
        loadChildren: () => import('./manager/manager.module').then(m => m.ManagerModule),
      }, {
        path: 'connect',
        loadChildren: () => import('./connect/connect.module').then(m => m.ConnectModule),
      }, {
        path: 'feedback',
        loadChildren: () => import('app/feedback/feedback.module').then(m => m.FeedbackModule),
      }, {
        path: 'goals',
        loadChildren: () => import('./goals/goals.module').then(m => m.GoalsModule),
      }, {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
      }, {
        path: 'surveys',
        loadChildren: () => import('./surveys/surveys.module').then(m => m.SurveysModule),
      }, {
        path: 'polls',
        loadChildren: () => import('./surveys/surveys.module').then(m => m.SurveysModule),
      }, {
        path: 'one-to-one',
        loadChildren: () => import('./one-to-one/one-to-one.module').then(m => m.OneToOneModule),
      }, {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'notifications',
        loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
      }, {
        path: 'evaluations',
        loadChildren: () => import('./evaluations/evaluations.module').then(m => m.EvaluationsModule),
      },
      {
        path: 'company-setup',
        loadChildren: () => import('./company-setup/company-setup.module').then(m => m.CompanySetupModule),
      },
      {
        path: 'integration', loadChildren: () => import('./integration/integration.module').then(m => m.IntegrationModule)
      },
      { path: 'not-enabled', component: FeatureNotEnabledComponent },
      { path: 'feature-locked', component: FeatureLockedComponent },

    ],
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      // { enableTracing: true } // <-- TODO: debugging purposes only
    ),
    Angulartics2Module.forRoot(),
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule {
}

