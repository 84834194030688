import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SwalUtils } from '@app/shared/utils/swal.utils';
import { MockApiService } from './api/mock-api.service';

@Injectable()
export class HttpMockErrorInterceptor implements HttpInterceptor {
  static modalOpen: boolean;

  constructor(private readonly mockApi: MockApiService) {
    HttpMockErrorInterceptor.modalOpen = false;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if route is allowed to contact server
    if (this.mockApi.allowedRoutes.includes(req.url)) {
      return next.handle(req).pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(error);

          if(!this.mockApi.excludeErrorHandler.includes(req.url)){
            SwalUtils.displayErrorSwal(error.error).then();
          }

          return throwError(error);
        })
      );
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(error);

        if (!HttpMockErrorInterceptor.modalOpen) {
          HttpMockErrorInterceptor.modalOpen = true;
          SwalUtils.displayMockNoOpSwal().then(result => {
            if (result.dismiss) {
              HttpMockErrorInterceptor.modalOpen = false;
            }

            if (result.value) {
              HttpMockErrorInterceptor.modalOpen = false;
              window.open('https://frankli.io/demo-request/', '_blank');
            }
          });
        }

        return throwError(error);
      })
    );
  }
}