import { IdeaItem } from '@app/models/feedback/idea.model';
import { IdeaEffect } from '@app/models/feedback/idea-effect.model';
import * as moment from 'moment';
import { mockGoalTags } from '@app/mock/api/data/mockGoalTags';

export const mockIdeas: IdeaItem[] = [
  {
    id: 1,
    message: 'Is it possible to give our fire wardens high vis jackets?',
    sourceUserId: 2,
    sourceUserFirstName: "Maxine",
    sourceUserLastName: "Stewart",
    destinationUserId: 1,
    timestamp: moment().subtract(1, 'week').toDate(),
    ideaEffect: IdeaEffect.HEALTH_AND_SAFETY,
    values: [mockGoalTags[0]]
  },
  {
    id: 2,
    message: 'Stand up desks for all employees.',
    sourceUserId: 3,
    sourceUserFirstName: "Tina",
    sourceUserLastName: "Moore",
    destinationUserId: 1,
    timestamp: moment().subtract(1, 'week').toDate(),
    ideaEffect: IdeaEffect.HEALTH_AND_SAFETY,
    values: [mockGoalTags[0]]
  },
  {
    id: 3,
    message: 'Floors are slippy in reception. Watch out.',
    sourceUserId: 3,
    sourceUserFirstName: "Tina",
    sourceUserLastName: "Moore",
    destinationUserId: 1,
    timestamp: moment().subtract(1, 'week').toDate(),
    ideaEffect: IdeaEffect.HEALTH_AND_SAFETY,
    values: [mockGoalTags[0]]
  },
  {
    id: 4,
    message: 'It would be great to define a small budget for team level reward & recognition vouchers that could easily be approved.',
    sourceUserId: 2,
    sourceUserFirstName: "Maxine",
    sourceUserLastName: "Stewart",
    destinationUserId: 1,
    timestamp: moment().subtract(1, 'week').toDate(),
    ideaEffect: IdeaEffect.MORALE,
    values: [mockGoalTags[2]]
  },
  {
    id: 5,
    message: 'Develop a new quality check sheet that incorporates all supplier info.',
    sourceUserId: 4,
    sourceUserFirstName: "Norma",
    sourceUserLastName: "White",
    destinationUserId: 1,
    timestamp: moment().subtract(1, 'week').toDate(),
    ideaEffect: IdeaEffect.QUALITY,
    values: [mockGoalTags[1]]
  },
  {
    id: 6,
    message: 'Cut down on costs by reducing spend on coffee.',
    sourceUserId: 5,
    sourceUserFirstName: "Louisa",
    sourceUserLastName: "Johnson",
    destinationUserId: 1,
    timestamp: moment().subtract(1, 'week').toDate(),
    ideaEffect: IdeaEffect.REVENUE,
    values: [mockGoalTags[2]]
  },
  {
    id: 7,
    message: 'I believe we can create a dedicate support services online via intercom and bill our customers for out of hours support.',
    sourceUserId: 7,
    sourceUserFirstName: "Noah",
    sourceUserLastName: "Ryan",
    destinationUserId: 1,
    timestamp: moment().subtract(1, 'week').toDate(),
    ideaEffect: IdeaEffect.REVENUE,
    values: [mockGoalTags[1]]
  },
  {
    id: 8,
    message: 'I think we should hold regular show and tells across our team to showcase the great work happening.',
    sourceUserId: 6,
    sourceUserFirstName: "Davis",
    sourceUserLastName: "Thornton",
    destinationUserId: 1,
    timestamp: moment().subtract(1, 'week').toDate(),
    ideaEffect: IdeaEffect.PRODUCTIVITY,
    values: [mockGoalTags[0]]
  }
];