<div>
  <div class="content m-top-10 m-bottom-10" *ngIf="tags.length > 0">
    <div class="row m-bottom-10">
  
      <div class="col-md-5">
        <div class="frankli-form-group frankli-form-group-filled frankli-form-group-placeholder-shown">
          <input id="search" type="search" class="form-control round-search" placeholder="Search by tag name"
            [(ngModel)]="searchValue" (ngModelChange)="search()">
          <i class="fal fa-search form-control-feedback"></i>
        </div>
      </div>
  
      <div class="col-md-1 col-md-offset-6">
        <button class="btn btn-sm btn-frankli-purple-o b-radius-20 pull-right m-top-5" (click)="reset()">Clear</button>
      </div>
  
    </div>
  
    <div class="row frankli-table frankli-table-single-line frankli-table-solid-colour-gray p-none"
      *ngFor="let tag of tagsDisplay; index as i;">
      <div class="col-md-12">
        <div class="row no-gutters clickable" (click)="toggleSubMenu(i, tag.tag.id)">
          <div class="col-md-3">
            <span class="fal fa-fw fa-chevron-right rotate-dropdown" [class.rotated]="tag.submenuOpened"></span>
            <span class="m-left-20">{{tag.tag.text}}</span>
          </div>
          <div class="col-md-9">
            <div class="goal-count-bar">
              <div class="bar-line" [style.width]="((tag.tagGoals.length/maxGoalAmount)*100)+'%'">
                <span>{{tag.tagGoals.length}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf='tag.submenuOpened && tag.tagGoals as t; else loading'>
          <div class="col-md-12" *ngIf='t?.length > 0; else noGoals'>
            <div class="row frankli-table frankli-table-hover frankli-table-single-line frankli-table-solid-color-white"
              (click)="navigateToGoal(goal)" *ngFor='let goal of t'>
              <div class="goal-status-highlight" [title]="eGoalHelperFunctions.getStatusTitle(goal)" [ngClass]="eGoalHelperFunctions.getStatusHighlight(goal)">
              </div>
  
              <div class="col-md-1 col-sm-1 col-xs-4 text-center"
                [class.hidden]="globals.hasFeature(eFeature.GOAL_PRIORITY) === false">
                <span [class]="'icon-priority priority-' + goal.priority">{{goal.priority}}</span>
              </div>
  
              <div class="col-md-1 col-sm-1 col-xs-4 text-center">
                <span title="Personal Operational Goal" class="fal fa-user goal-type-icon"
                  *ngIf="goal.type === eGoalType.PERSONAL_OPERATIONAL"></span>
                <span title="Personal Developmental Goal" class="fal fa-graduation-cap goal-type-icon"
                  *ngIf="goal.type === eGoalType.PERSONAL_DEVELOPMENTAL"></span>
                <span title="{{companyWording.department | titlecase}} Goal" class="fal fa-users goal-type-icon"
                  *ngIf="goal.type === eGoalType.DEPARTMENT"></span>
                <span title="Company Goal" class="fal fa-building goal-type-icon"
                  *ngIf="goal.type === eGoalType.COMPANY"></span>
              </div>
  
              <div class="col-md-2 col-sm-2 col-xs-4 key-results-align text-nowrap">
                <span>{{goal.keyResults.length}} key results</span>
              </div>
  
              <div class="col-md-3 col-sm-8 col-xs-12">
                <span title='{{goal.title}}'>
                  {{goal.title}}
                </span>
              </div>
  
              <div class="col-md-3 col-sm-8 col-xs-8">
                <div class="row">
                  <div class="col-md-9 col-sm-9 col-xs-9">
                    <div class="progress">
                      <div class="progress-bar progress-bar-info" [style.width]="goal.completionPercentage+'%'">
                        <span class="sr-only"></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-3 col-xs-3 text-nowrap">
                    {{goal.completionPercentage}}%
                  </div>
                </div>
              </div>
  
              <div class="col-md-2 col-sm-4 col-xs-4 text-center date-line-height"
                [class.text-danger]="getDaysRemainingTotal(goal.endDate) < 0 && goal.complete === false && goal.archived === false">
                <span>{{goal.endDate | date:'d MMMM y'}}
                  <span *ngIf="goal.complete === false && goal.archived === false">
                    <br />
                    ({{getDaysRemaining(goal.endDate)}})
                  </span>
                </span>
              </div>
  
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-pagination-new-component class="m-top-10" [arrayData]="tagsFiltered" [arrayDisplay]="tagsDisplay" [currentPage]="page" (pageChanged)="paginationChanged($event)"></app-pagination-new-component>
  
  <div class="row"
    *ngIf="state.loading === false && state.error === false && tags.length > 0 && tagsDisplay.length === 0">
    <span class="col-md-12 text-center m-bottom-15">
      <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
      <br>
      <p> There are no tags available for this search</p>
    </span>
  </div>
  
  <!-- NO USERS -->
  <div class="content text-center" *ngIf="state.loading === false && state.error === false && tags.length === 0">
    <p>
      <img class="icon-padding new-icon-height" src="/assets/img/NoGoals.svg" />
      <br>
      Your company has not created any tags yet</p>
    <span class="btn-frankli-round btn-frankli-green" [routerLink]="'/dashboard'">
      Back to your dashboard?
    </span>
  </div>
  
  <!-- LOADING -->
  <div class="content text-center" *ngIf="state.loading === true && state.error === false">
    <span class="fal fa-spin fa-spinner fa-3x fa-fw"></span>
  </div>
  
  <!-- ERROR -->
  <div class="content text-center" *ngIf="state.loading === false && state.error === true">
    <p>
      <img class="icon-padding new-icon-height" src="/assets/img/Error.svg" />
      <br>
      An error occurred while getting the people you manage</p>
    <span class="btn-frankli-round btn-frankli-green" [routerLink]="'/dashboard'">
      Back to your dashboard?
    </span>
  </div>
  
  <ng-template #loading>
    <div class="content text-center" *ngIf="state.loading === true && state.error === false">
      <span class="fal fa-spin fa-spinner fa-3x fa-fw"></span>
    </div>
  </ng-template>
  <ng-template #noGoals>
    <div class="content text-center">
      <span>
        <h4 class="m-top-0">No Goals</h4>
      </span>
    </div>
  </ng-template>
</div>