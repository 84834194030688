import { ConnectActivity } from '@app/models/connect/connect-activity/connect-activity.model';
import { ConnectActivityCategory } from '@app/models/connect/type/connect-activity-category.enum';
import { ConnectActivityType } from '@app/models/connect/type/connect-activity-type.enum';
import * as moment from 'moment';

export const mockConnectActivity: ConnectActivity[] = [
  {
    id: 1,
    userId: 1,
    category: ConnectActivityCategory.COACHING,
    type: ConnectActivityType.COACHING_SKILL_SEARCH,
    extraInfo: 'Java',
    timestamp: moment().subtract(1, 'day').toDate()
  },
  {
    id: 2,
    userId: 1,
    category: ConnectActivityCategory.COACHING,
    type: ConnectActivityType.COACHING_SKILL_SEARCH,
    extraInfo: 'Business Management',
    timestamp: moment().subtract(1, 'day').toDate()
  },
  {
    id: 3,
    userId: 1,
    category: ConnectActivityCategory.COACHING,
    type: ConnectActivityType.COACHING_SKILL_SEARCH,
    extraInfo: 'UX',
    timestamp: moment().subtract(1, 'day').toDate()
  },
  {
    id: 4,
    userId: 1,
    category: ConnectActivityCategory.MENTORING,
    type: ConnectActivityType.MENTORING_SKILL_SEARCH,
    extraInfo: 'Java',
    timestamp: moment().subtract(1, 'day').toDate()
  },
  {
    id: 5,
    userId: 1,
    category: ConnectActivityCategory.MENTORING,
    type: ConnectActivityType.MENTORING_SKILL_SEARCH,
    extraInfo: 'Business Management',
    timestamp: moment().subtract(1, 'day').toDate()
  },
  {
    id: 6,
    userId: 1,
    category: ConnectActivityCategory.MENTORING,
    type: ConnectActivityType.MENTORING_SKILL_SEARCH,
    extraInfo: 'UX',
    timestamp: moment().subtract(1, 'day').toDate()
  }
];