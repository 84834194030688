import { PublishedEnpsSummary } from '@app/models/survey/published-enps-summary.model';

export const mockPublishedEnpsSummary: PublishedEnpsSummary = {
  hasScore: false,
  hasPreviousScore: false,
  score: {
      score: 0,
      promotersCount: 0,
      passivesCount: 0,
      detractorsCount: 0,
      promotersPercentage: 0,
      passivesPercentage: 0,
      detractorsPercentage: 0,
      total: 0
  },
  previousScore: {
      score: 0,
      promotersCount: 0,
      passivesCount: 0,
      detractorsCount: 0,
      promotersPercentage: 0,
      passivesPercentage: 0,
      detractorsPercentage: 0,
      total: 0
  }
}
