<div class="modal-sidebar modal-sidebar-divider" [class.modal-sidebar-body-collapsed]="modal.sidebarCollapsed === true">
  <div class="modal-sidebar-padding text-right">
    <span tooltip="Collapse sidebar" placement="right" container="body" class="fal fa-angle-left fa-2x button-sidebar-collapser" (click)="modal.toggleSidebar()"></span>
  </div>

  <hr class="modal-sidebar-line-divider m-top-0" />

  <div class="modal-sidebar-overflow">
    <!-- Competencies -->
    <div class="menu-item" *ngIf="globals.hasFeature(eFeature.COMPETENCIES)">
      <div class="p-top-15 p-bottom-15 p-left-15 p-right-15 clickable"
        (click)="menuToggles.competencies = !menuToggles.competencies">
        <span class="modal-sidebar-header-text">Your Competencies</span>
        <span class="fal fa-chevron-right rotate-0" [class.rotate-90]="menuToggles.competencies === true"></span>
      </div>
  
      <hr class="modal-sidebar-line-divider" />
  
      <ng-container *ngIf="menuToggles.competencies === true && globals.user.position">
        <div class="m-top-15 p-left-15 p-right-15">
          <ng-container *ngFor="let competency of globals.user.position.competencies">
            <div class="sidebar-competency-name">{{competency.name}}</div>
            <div class="sidebar-competency-description" [innerHTML]="competency.description"></div>
          </ng-container>
          <div *ngIf="globals.user.position.competencies.length === 0" class="sidebar-competency-name">There are no
            competencies associated with your position</div>
        </div>
  
        <hr class="modal-sidebar-line-divider" />
      </ng-container>
    </div>
  
    <!-- Goal Tips -->
    <div class="menu-item">
      <div class="p-top-15 p-bottom-15 p-left-15 p-right-15 clickable" (click)="menuToggles.tips = !menuToggles.tips">
        <span class="modal-sidebar-header-text">Goal Tips</span>
        <span class="fal fa-chevron-right rotate-0" [class.rotate-90]="menuToggles.tips === true"></span>
      </div>
  
      <hr class="modal-sidebar-line-divider" />
  
      <ng-container *ngIf="menuToggles.tips === true">
        <!-- Goal Tips Body -->
        <div class="goal-tips">
          <span class="goal-tip-text">{{tipsList[0]}}</span>
  
          <span class="noselect" [title]="'Next Tip'" (click)="tDecrease()"
            class="fal fa-angle-left fa-2x big-input-icon clickable goal-tips-icon goal-tips-icon-left"></span>
          <span class="noselect" [title]="'Next Tip'" (click)="tIncrease()"
            class="fal fa-angle-right fa-2x big-input-icon clickable goal-tips-icon goal-tips-icon-right"></span>
        </div>
  
        <hr class="modal-sidebar-line-divider" />
      </ng-container>
    </div>
  
    <!-- Goal Templates -->
    <div class="menu-item" *ngIf="globals.hasFeature(eFeature.GOAL_TEMPLATE)">
      <div class="p-top-15 p-bottom-15 p-left-15 p-right-15 clickable"
        (click)="menuToggles.templates = !menuToggles.templates">
        <span class="modal-sidebar-header-text">Goal Templates</span>
        <span class="fal fa-chevron-right rotate-0" [class.rotate-90]="menuToggles.templates === true"></span>
      </div>
      <hr class="modal-sidebar-line-divider" />
      <ng-container *ngIf="menuToggles.templates === true">
  
        <!-- TODO: This text -->
        <div class="p-left-15 p-right-15 p-top-10">
          <span>Here are some templates your admin has created. These are basic outlines of what a goal should be.</span>
        </div>
  
        <ng-container *ngIf="!state.templates.loading; else stateLoading">
          <div *ngIf="!state.templates.error; else templatesError">
            <ng-container *ngIf="templates.length > 0; else noTemplatesAny">
              <div class="p-left-15 p-right-15 m-top-5 m-bottom-15 frankli-form-group" [class.frankli-form-group-filled]="templateSearch.value">
                <label for="templateSearch">Search</label>
                <input id="templateSearch" class="form-control" [formControl]="templateSearch" />
              </div>
              
              <hr class="modal-sidebar-line-goal-divider" />
  
              <ng-container *ngIf="templatesDisplayed.length > 0; else noTemplates">
                <div *ngFor="let template of templatesDisplayed; let i = index">
                  <div class="p-left-15 p-right-15 p-top-10 p-bottom-10 goal-overview p-relative">
                    <div class="font-bold">{{template.title}}</div>
  
                    <div>
                      <span class="fal fa-fw expand-on-hover kr-expand-icon"
                        [ngClass]="(template.resultsShown ? 'fa-minus-circle' : 'fa-plus-circle')"
                        [title]="(template.resultsShown ? 'Hide all' : 'Show all')"
                        (click)="template.resultsShown = !template.resultsShown"></span>
                      <div class="key-result-list">
                        <div *ngIf="!template.resultsShown; else showAllTemplateResults">{{template.results.length}} key result{{(template.results.length !== 1) ? 's' : ''}}</div>
                        <ng-template #showAllTemplateResults>
                          <div *ngFor="let r of template.results">{{r.title}}</div>
                        </ng-template>
                      </div>
                    </div>
                    <span class="icon-copy-goal fal fa-fw fa-copy frankli-edit expand-on-hover" title="Copy template"
                      (click)="copyTemplate(template)"></span>
                  </div>
                  <hr class="modal-sidebar-line-goal-divider" />
                </div>
              </ng-container>
  
              <ng-template #noTemplates>
                <div class="text-center p-bottom-15">
                  <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
                  <div>No goal templates found for search</div>
                </div>
              </ng-template>
            </ng-container>
            <ng-template #noTemplatesAny>
              <div class="text-center">
                <img class="icon-padding new-icon-height" src="/assets/img/NoGoals.svg" />
                <div class="display-1 m-bottom-30">No goal templates available</div>
                <hr class="modal-sidebar-line-goal-divider" />
              </div>
            </ng-template>
          </div>
  
          <ng-template #templatesError>
            <div class="text-center">
              <hr class="modal-sidebar-line-goal-divider" />
              <br>
              <img class="icon-padding new-icon-height" src="/assets/img/Error.svg" />
              <br>
              <h4 class="display-1 m-bottom-30">Error loading goal templates</h4>
              <hr class="modal-sidebar-line-goal-divider" />
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
    </div>
  
    <!-- Other Goals -->
    <div class="menu-item">
      <div class="p-top-15 p-bottom-15 p-left-15 p-right-15 clickable" (click)="menuToggles.goals = !menuToggles.goals">
        <span class="modal-sidebar-header-text">Other Goals</span>
        <span class="fal fa-chevron-right rotate-0" [class.rotate-90]="menuToggles.goals === true"></span>
      </div>
      <hr class="modal-sidebar-line-divider" />
      <ng-container *ngIf="menuToggles.goals === true">
  
        <div class="p-left-15 p-right-15 p-top-10">
          <span>Looking at goals from around the company can be helpful while setting your own. You can also copy them if you choose.</span>
        </div>
  
        <!-- Searches and Filters -->
        <div class="p-left-15 p-right-15">
          <div class="m-top-5 frankli-form-group" [class.frankli-form-group-filled]="goalSearch.value">
            <label for="goalSearch">Search</label>
            <input id="goalSearch" class="form-control" [formControl]="goalSearch" />
          </div>
  
          <div class="m-top-10 m-bottom-15 frankli-form-group frankli-form-group-filled">
            <label for="goalType">Type</label>
            <select id="goalType" [formControl]="goalType" class="form-control">
              <option [ngValue]="null">All</option>
              <option [ngValue]="type.type" *ngFor="let type of goalTypes">{{type.text}}</option>
            </select>
          </div>
  
          <div *ngIf="goalType.value === eGoalType.DEPARTMENT" class="m-top-10 m-bottom-15 frankli-form-group frankli-form-group-filled">
            <label for="goalDepartmentId">{{companyWording.department | titlecase}}</label>
            <select id="goalDepartmentId" [formControl]="goalDepartmentId" class="form-control">
              <option [ngValue]="null">All</option>
              <option [ngValue]="department.id" *ngFor="let department of departments">{{department.name}}</option>
            </select>
          </div>
  
          <div *ngIf="goalType.value === eGoalType.OFFICE_LOCATION" class="m-top-10 m-bottom-15 frankli-form-group frankli-form-group-filled">
            <label for="goalSiteId">Site</label>
            <select id="goalSiteId" [formControl]="goalSiteId" class="form-control">
              <option [ngValue]="null">All</option>
              <option [ngValue]="site.id" *ngFor="let site of sites">{{site.name}}</option>
            </select>
          </div>
  
  
        </div>
  
        <ng-container *ngIf="!state.goals.loading; else stateLoading">
          <div *ngIf="!state.goals.error; else goalsError">
            <ng-container *ngIf="goals.length > 0; else noGoalsAny">
              <hr class="modal-sidebar-line-goal-divider" />
              <ng-container *ngIf="goalsDisplayed.length > 0; else noGoals">
                <div *ngFor="let goal of goalsDisplayed; let i = index">
                  <div class="p-left-15 p-right-15 p-top-10 p-bottom-10 goal-overview p-relative">
                    <div class="font-bold">{{goal.title}}</div>
                    <div>
                      <span class="font-bold">Type:&nbsp;</span>
                      <span *ngIf="goal.type !== eGoalType.OFFICE_LOCATION && goal.type !== eGoalType.TEAM && goal.type !== eGoalType.DEPARTMENT">{{goal.type | titlecase | underscoreToSpace}}</span>
                      <span *ngIf="goal.type === eGoalType.OFFICE_LOCATION">Site</span>
                      <span *ngIf="goal.type === eGoalType.DEPARTMENT">{{companyWording.department | titlecase}}</span>
                      <span *ngIf="goal.type === eGoalType.TEAM">{{companyWording.team | titlecase }} </span>
                      
                    </div>
                    <div class="m-top-5 m-bottom-5">
                      <span [class]="'priority-icon priority-' + goal.priority" [title]="goal.priority">{{goal.priority}}</span>
                      <span class="visibility-icon" [ngSwitch]="goal.visibility">
                        <span class="fal fa-eye" title="Public" *ngSwitchCase="eGoalVisibility.PUBLIC"></span>
                        <span class="fal fa-eye-slash" title="Private" *ngSwitchCase="eGoalVisibility.PRIVATE"></span>
                      </span>
                    </div>
  
                    <div>
                      <span class="fal fa-fw expand-on-hover kr-expand-icon"
                        [ngClass]="(goal.keyResultsShown ? 'fa-minus-circle' : 'fa-plus-circle')"
                        [title]="(goal.keyResultsShown ? 'Hide all' : 'Show all')"
                        (click)="goal.keyResultsShown = !goal.keyResultsShown"></span>
                      <div class="key-result-list">
                        <div *ngIf="!goal.keyResultsShown; else showAllResults">{{goal.keyResults.length}} key result{{(goal.keyResults.length > 1) ? 's' : ''}}
                        </div>
                        <ng-template #showAllResults>
                          <div *ngFor="let kr of goal.keyResults">{{kr.result}}</div>
                        </ng-template>
                      </div>
                    </div>
  
                    <!-- <div><span>Due {{goal.endDate | date:'d MMMM y'}}</span></div> -->
  
                    <span class="icon-copy-goal fal fa-fw fa-copy frankli-edit expand-on-hover" title="Copy goal" (click)="copyGoal(goal)"></span>
                  </div>
                  <hr class="modal-sidebar-line-goal-divider" />
                </div>
              </ng-container>
  
              <ng-template #noGoals>
                <div class="text-center p-bottom-15" *ngIf="goals.length > 0">
                  <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
                  <div>No goals found for search</div>
                </div>
              </ng-template>
            </ng-container>
  
            <ng-template #noGoalsAny>
              <div class="text-center">
                <img class="icon-padding new-icon-height" src="/assets/img/NoGoals.svg" />
                <div class="display-1 m-bottom-30">No goals available</div>
                <hr class="modal-sidebar-line-goal-divider" />
              </div>
            </ng-template>
          </div>
  
          <ng-template #goalsError>
            <div class="text-center">
              <hr class="modal-sidebar-line-goal-divider" />
              <br>
              <img class="icon-padding new-icon-height" src="/assets/img/Error.svg" />
              <br>
              <h4 class="display-1 m-bottom-30">Error loading goals</h4>
              <hr class="modal-sidebar-line-goal-divider" />
            </div>
          </ng-template>
        </ng-container>
  
      </ng-container>
    </div>
  </div>
</div>

<ng-template #stateLoading>
  <div class="text-center">
    <hr class="modal-sidebar-line-goal-divider" />
    <span class="fal fa-spin fa-spinner fa-2x fa-fw"></span>
    <hr class="modal-sidebar-line-goal-divider" />
  </div>
</ng-template>