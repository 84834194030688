import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { User } from "@app/models/user/user.model";
import { Observable } from "rxjs";
import { PermissionService } from '@app/shared/api/interfaces/permission.service';

@Injectable()
export class PermissionAPIService implements PermissionService {
  baseURL = 'api/user/permission';
  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(private http: HttpClient) { }

  updatePermission(key: string, users: Array<User>): Observable<Array<User>> {
    const url = `${this.baseURL}/${key}`;
    return this.http.post<Array<User>>(url, users, {headers: this.headers});
  }

  getUsersWithPermission(key: string): Observable<Array<User>> {
    const url = `${this.baseURL}/${key}`;
    return this.http.get<Array<User>>(url, {headers: this.headers});
  }
}