import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Goal } from 'app/models/goals/goal.model';
import { GoalTemplate } from '@app/models/goals/goal-template.model';
import { GoalDraft } from '@app/models/goals/goal-draft.model';

@Injectable()
export class GoalCreateService {
  private subjectCreated = new Subject<string>();
  private subjectReset = new Subject<string>();

  private subjectGoalCreated = new Subject<Goal>();
  private subjectGoalUpdated = new Subject<Goal>();
  private subjectGoalSaved = new Subject<GoalDraft>();
  private subjectGoalCopied = new Subject<Goal>();
  private subjectGoalTemplateCopied = new Subject<GoalTemplate>();

  constructor() {
  }

  // Init
  getInit(): Observable<string> {
    return this.subjectCreated.asObservable();
  }

  sendInit(): void {
    this.subjectCreated.next('INIT');
  }

  // Reset
  getReset(): Observable<string> {
    return this.subjectReset.asObservable();
  }

  sendReset(): void {
    this.subjectReset.next('RESET');
  }

  // Created
  getGoalCreated(): Observable<Goal> {
    return this.subjectGoalCreated.asObservable();
  }

  sendGoalCreated(goal: Goal): void {
    this.subjectGoalCreated.next(goal);
  }

  // Updated
  getGoalUpdated(): Observable<Goal> {
    return this.subjectGoalUpdated.asObservable();
  }

  sendGoalUpdated(goal: Goal): void {
    this.subjectGoalUpdated.next(goal);
  }

  // Saved
  getGoalSaved(): Observable<GoalDraft> {
    return this.subjectGoalSaved.asObservable();
  }

  sendGoalSaved(goalDraft: GoalDraft): void {
    this.subjectGoalSaved.next(goalDraft);
  }

  // Copied - Goal
  getGoalCopied() {
    return this.subjectGoalCopied.asObservable();
  }

  sendGoalCopied(goalCopied: Goal) {
    this.subjectGoalCopied.next(goalCopied);
  }

  // Copied - Template
  getGoalTemplateCopied() {
    return this.subjectGoalTemplateCopied.asObservable();
  }

  sendGoalTemplateCopied(templateCopied: GoalTemplate) {
    this.subjectGoalTemplateCopied.next(templateCopied);
  }
}
