<hr class="m-bottom-0" />
<div class="key-result" [formGroup]="keyResultForm">
  <span tooltip="Remove key result" placement="top"
    class="fal fa-times icon-close btn-remove frankli-close expand-on-hover" (click)="cancel()"></span>

  <div class="row m-top-20">
    <div class="col-md-8">


      <div class="frankli-form-group" [class.frankli-form-group-filled]="keyResultForm.controls.result.value"
        [class.frankli-form-group-invalid]="keyResultForm.controls.result.invalid && submitted">
        <label class="modal-label" [for]="'key-result-name-'+resultIndex"><i class="fal fa-chart-bar"></i> Key
          Result</label>
        <app-form-input-dropdown-component [placeholder]="'Key Result'" [control]="keyResultForm.controls.result"
          [values]="templateValues" [idInput]="'key-result-name-'+resultIndex"
          [enabled]="globals.hasFeature(eFeature.GOAL_TEMPLATE)"></app-form-input-dropdown-component>
        <div class="frankli-form-group-alert">
          <ng-container *ngIf="submitted && keyResultForm.controls.result.invalid">
            <div *ngIf="keyResultForm.controls.result.hasError('required')">Required</div>
            <div *ngIf="keyResultForm.controls.result.hasError('maxlength')">Must be less than 255 characters</div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="frankli-form-group frankli-form-group-filled btn-remove-wrap"
        [class.frankli-form-group-invalid]="keyResultForm.controls.endDate.invalid && submitted">
        <label class="modal-label" [for]='"datetimepicker-"+resultIndex'><i class="fal fa-clock"></i> Result End
          Date</label>
        <app-date-picker-old-component [formControl]="keyResultForm.controls.endDate"
          [invalid]="keyResultForm.controls.endDate.invalid" [index]="resultIndex" [emptyText]="'No date'"
          [verticalPosition]="'bottom'" [minDate]="minDate"></app-date-picker-old-component>
        <div class="frankli-form-group-alert">
          <ng-container *ngIf="submitted && keyResultForm.controls.endDate.invalid">
            <div *ngIf="keyResultForm.controls.endDate.hasError('required')">Required</div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <!-- Measure type -->
  <div class="row m-bottom-20">
    <div class="col-xs-12">
      <div class="frankli-form-group frankli-form-group-filled"
        [class.frankli-form-group-invalid]='keyResultForm.controls.type.invalid && submitted'>
        <label class="modal-label" [for]='"key-result-type-"+resultIndex'><i class="fal fa-cog"></i> Measure
          Type</label>
        <br />
        <div class="btn-group m-top-5">
          <button (click)="keyResultForm.controls.type.setValue(eGoalKeyResultType.BINARY)" type="button" class="btn"
            [class.btn-frankli-green-inverted]="keyResultForm.controls.type.value === eGoalKeyResultType.BINARY">Complete /
            Incomplete</button>
          <button (click)="keyResultForm.controls.type.setValue(eGoalKeyResultType.NUMERIC)" type="button" class="btn"
            [class.btn-frankli-green-inverted]="keyResultForm.controls.type.value === eGoalKeyResultType.NUMERIC">Numeric</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Measure type numeric -->
  <div class="row">
    <div class="col-xs-12" *ngIf="keyResultForm.controls.type.value === eGoalKeyResultType.NUMERIC">
      <div class="row">
        <div class="col-xs-12 col-md-6">
          <div class="frankli-form-group"
            [class.frankli-form-group-invalid]="keyResultForm.controls.measureStartValue.invalid && submitted"
            [class.frankli-form-group-filled]='(keyResultForm.controls.measureStartValue.value != null) && (keyResultForm.controls.measureStartValue != undefined)'>
            <label class="modal-label" *ngIf="mode === 'CREATE' || mode === 'EDIT_DRAFT'">Start Value</label>
            <label class="modal-label" *ngIf="mode === 'EDIT'">Current Value</label>
            <input class="form-control" [formControl]="keyResultForm.controls.measureStartValue"
              placeholder="Current Value" />
            <div class="frankli-form-group-alert">
              <ng-container *ngIf="submitted && keyResultForm.controls.measureStartValue.invalid">
                <div *ngIf="keyResultForm.controls.measureStartValue.hasError('required')">Required</div>
                <div *ngIf="keyResultForm.controls.measureStartValue.hasError('pattern')">Invalid value</div>
              </ng-container>
              <ng-container *ngIf="keyResultForm.hasError('goalSetToEqual')">
                <div>Start Value can't be equal to Goal Value.</div>
              </ng-container>

            </div>
          </div>
        </div>
        <div class="col-xs-12 col-md-6">
          <div class="frankli-form-group"
            [class.frankli-form-group-invalid]="keyResultForm.controls.measureGoalValue.invalid && submitted"
            [class.frankli-form-group-filled]="keyResultForm.controls.measureGoalValue.value">
            <label class="modal-label">Goal Value</label>
            <input class="form-control" [disabled]="keyResultForm.controls.type.value === eGoalKeyResultType.BINARY"
              [formControl]="keyResultForm.controls.measureGoalValue" placeholder="Goal Value" />
            <div class="frankli-form-group-alert">
              <ng-container *ngIf="submitted && keyResultForm.controls.measureGoalValue.invalid">
                <div *ngIf="keyResultForm.controls.measureGoalValue.hasError('required')">Required</div>
                <div *ngIf="keyResultForm.controls.measureGoalValue.hasError('pattern')">Invalid value</div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="col-xs-6">
          <div class="frankli-form-group frankli-form-group-filled frankli-form-group-placeholder-shown"
            [class.frankli-form-group-invalid]="keyResultForm.controls.measureUnit.invalid && submitted">
            <label class="modal-label">Unit (optional)</label>
            <input class="form-control" [formControl]="keyResultForm.controls.measureUnit" placeholder="e.g. %, $, £, Sales" />
            <div class="frankli-form-group-alert">
              <ng-container *ngIf="submitted && keyResultForm.controls.measureUnit.invalid">
                <div *ngIf="keyResultForm.controls.measureUnit.hasError('required')">Required</div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="col-xs-6" *ngIf="keyResultForm.controls.measureUnit.value.trimStart()">
          <label class="modal-label">Placement of Unit</label>
          <br/>
          <div class="btn-group m-top-5">
            <button
              (click)="keyResultForm.controls.measureUnitPlacement.setValue(eGoalKeyResultMeasureUnitPlacement.BEFORE)"
              type="button" class="btn"
              [class.btn-frankli-green-inverted]="keyResultForm.controls.measureUnitPlacement.value === eGoalKeyResultMeasureUnitPlacement.BEFORE">Before</button>
            <button
              (click)="keyResultForm.controls.measureUnitPlacement.setValue(eGoalKeyResultMeasureUnitPlacement.AFTER)"
              type="button" class="btn"
              [class.btn-frankli-green-inverted]="keyResultForm.controls.measureUnitPlacement.value === eGoalKeyResultMeasureUnitPlacement.AFTER">After</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>