import { Component, OnInit } from '@angular/core';
import { AuthAPIService } from '../auth/auth.api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-revoked',
  template: '',
})
export class RevokedComponent implements OnInit {

  constructor(private authService: AuthAPIService) { }

  ngOnInit() {
    Swal.fire({
      title: 'Access Revoked',
      text: 'Contact your manager to find out more',
      imageUrl: 'assets/img/swal-icons/Frankli_sure_icon-46.svg',
      imageWidth: 140,
      imageHeight: 140,
      confirmButtonColor: '#54c6bb'
    }).then(() => {
      this.authService.logout();
    });
  }

}
