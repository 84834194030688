import {
    Goal,
    GoalPriority,
    GoalStatus,
    GoalType,
    GoalVisibility
} from '@app/models/goals/goal.model';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { mockGoalKeyResults } from '@app/mock/api/data/mockGoalKeyResults';
import { mockOrganisationalUnits } from '@app/mock/api/data/mockOrganisationalUnits';
import * as moment from 'moment';
import { mockOfficeLocations } from '@app/mock/api/data/mockOfficeLocations';
import { mockGoalTags } from '@app/mock/api/data/mockGoalTags';
import { GoalActivitiy, GoalActivityType } from '@app/models/goals/goal-activity.model';

const createActivity: GoalActivitiy = {
    id: 1,
    user: mockUsers[0],
    date: moment().subtract(1, 'week').toDate(),
    message: 'Goal Created',
    type: GoalActivityType.CREATE,
    progressUpdate: null,
    editUpdate: null
}

export const mockGoals: Goal[] = [
    {
        id: 1,
        title: 'Review and improve our data security procedures',
        type: GoalType.DEPARTMENT,
        completionPercentage: 50,
        department: mockOrganisationalUnits[1],
        officeLocation: null,
        creationDate: moment().subtract(1, 'week').toDate(),
        endDate: moment().add(1, 'week').toDate(),
        completionDate: null,
        visibility: GoalVisibility.PUBLIC,
        priority: GoalPriority.P1,
        status: GoalStatus.ON_TRACK,
        tags: [mockGoalTags[1]],
        keyResults: [
            mockGoalKeyResults[0],
            mockGoalKeyResults[1]
        ],
        owners: [mockUsers[0], ...mockUsers.filter(u => u.organisationalUnit.name === 'Development')],
        complete: false,
        archived: false,
        activity: [createActivity],
        alignment: null,
        developmentNeeds: null
    },
    {
        id: 2,
        title: 'Improve our testing procedures',
        type: GoalType.DEPARTMENT,
        completionPercentage: 50,
        department: mockOrganisationalUnits[1],
        officeLocation: null,
        creationDate: moment().subtract(1, 'week').toDate(),
        endDate: moment().add(2, 'months').toDate(),
        completionDate: moment().subtract(1, 'day').toDate(),
        visibility: GoalVisibility.PUBLIC,
        priority: GoalPriority.P3,
        status: GoalStatus.ON_TRACK,
        tags: [],
        keyResults: [
            mockGoalKeyResults[2],
            mockGoalKeyResults[3]
        ],
        owners: [mockUsers[0], ...mockUsers.filter(u => u.organisationalUnit.name === 'Development')],
        complete: true,
        archived: false,
        activity: [createActivity],
        alignment: null,
        developmentNeeds: null
    },
    {
        id: 3,
        title: 'Implement new 360-degree product planning process',
        type: GoalType.DEPARTMENT,
        completionPercentage: 50,
        department: mockOrganisationalUnits[0],
        officeLocation: null,
        creationDate: moment().subtract(1, 'week').toDate(),
        endDate: moment().add(2, 'months').toDate(),
        completionDate: null,
        visibility: GoalVisibility.PUBLIC,
        priority: GoalPriority.P1,
        status: GoalStatus.ON_TRACK,
        tags: [mockGoalTags[2]],
        keyResults: [
            mockGoalKeyResults[4],
            mockGoalKeyResults[5],
            mockGoalKeyResults[6],
            mockGoalKeyResults[7]
        ],
        owners: [...mockUsers.filter(u => u.organisationalUnit.name === 'Management')],
        complete: false,
        archived: false,
        activity: [createActivity],
        alignment: null,
        developmentNeeds: null
    },
    {
        id: 4,
        title: 'Improve annual budgeting and business planning',
        type: GoalType.DEPARTMENT,
        completionPercentage: 50,
        department: mockOrganisationalUnits[2],
        officeLocation: null,
        creationDate: moment().subtract(1, 'week').toDate(),
        endDate: moment().add(2, 'months').toDate(),
        completionDate: null,
        visibility: GoalVisibility.PUBLIC,
        priority: GoalPriority.P2,
        status: GoalStatus.PROGRESSING,
        tags: [],
        keyResults: [
            mockGoalKeyResults[8],
            mockGoalKeyResults[9],
            mockGoalKeyResults[10]
        ],
        owners: [mockUsers[0], ...mockUsers.filter(u => u.organisationalUnit.name === 'Finance')],
        complete: false,
        archived: false,
        activity: [createActivity],
        alignment: null,
        developmentNeeds: null
    },
    {
        id: 5,
        title: 'Finish raising new capital for growth needs',
        type: GoalType.DEPARTMENT,
        completionPercentage: 50,
        department: mockOrganisationalUnits[2],
        officeLocation: null,
        creationDate: moment().subtract(1, 'week').toDate(),
        endDate: moment().add(2, 'months').toDate(),
        completionDate: moment().subtract(1, 'day').toDate(),
        visibility: GoalVisibility.PUBLIC,
        priority: GoalPriority.P1,
        status: GoalStatus.PROGRESSING,
        tags: [],
        keyResults: [
            mockGoalKeyResults[11],
            mockGoalKeyResults[12]
        ],
        owners: [mockUsers[0], ...mockUsers.filter(u => u.organisationalUnit.name === 'Finance')],
        complete: true,
        archived: false,
        activity: [createActivity],
        alignment: null,
        developmentNeeds: null
    },
    {
        id: 6,
        title: 'Define and promote company culture and values',
        type: GoalType.DEPARTMENT,
        completionPercentage: 50,
        department: mockOrganisationalUnits[3],
        officeLocation: null,
        creationDate: moment().subtract(1, 'week').toDate(),
        endDate: moment().add(1, 'month').toDate(),
        completionDate: null,
        visibility: GoalVisibility.PUBLIC,
        priority: GoalPriority.P4,
        status: GoalStatus.ON_TRACK,
        tags: mockGoalTags,
        keyResults: [
            mockGoalKeyResults[13],
            mockGoalKeyResults[14],
            mockGoalKeyResults[15]
        ],
        owners: [mockUsers[0], ...mockUsers.filter(u => u.organisationalUnit.name === 'People & Culture')],
        complete: false,
        archived: false,
        activity: [createActivity],
        alignment: null,
        developmentNeeds: null
    },
    {
        id: 7,
        title: 'Design and launch internal Employee Academy',
        type: GoalType.DEPARTMENT,
        completionPercentage: 50,
        department: mockOrganisationalUnits[3],
        officeLocation: null,
        creationDate: moment().subtract(1, 'week').toDate(),
        endDate: moment().add(2, 'months').toDate(),
        completionDate: null,
        visibility: GoalVisibility.PUBLIC,
        priority: GoalPriority.P2,
        status: GoalStatus.OFF_TRACK,
        tags: [mockGoalTags[2]],
        keyResults: [
            mockGoalKeyResults[16],
            mockGoalKeyResults[17],
            mockGoalKeyResults[18]
        ],
        owners: [mockUsers[0], ...mockUsers.filter(u => u.organisationalUnit.name === 'People & Culture')],
        complete: false,
        archived: false,
        activity: [createActivity],
        alignment: null,
        developmentNeeds: null
    },
    {
        id: 8,
        title: 'Achieve record revenues while increasing profitability',
        type: GoalType.DEPARTMENT,
        completionPercentage: 50,
        department: mockOrganisationalUnits[4],
        officeLocation: null,
        creationDate: moment().subtract(1, 'week').toDate(),
        endDate: moment().add(2, 'months').toDate(),
        completionDate: null,
        visibility: GoalVisibility.PUBLIC,
        priority: GoalPriority.P2,
        status: GoalStatus.PROGRESSING,
        tags: [],
        keyResults: [
            mockGoalKeyResults[19],
            mockGoalKeyResults[20],
            mockGoalKeyResults[21]
        ],
        owners: [mockUsers[0], ...mockUsers.filter(u => u.organisationalUnit.name === 'Business Development')],
        complete: false,
        archived: false,
        activity: [createActivity],
        alignment: null,
        developmentNeeds: null
    },
    {
        id: 9,
        title: 'Increase the quality of our sales approach',
        type: GoalType.DEPARTMENT,
        completionPercentage: 50,
        department: mockOrganisationalUnits[4],
        officeLocation: null,
        creationDate: moment().subtract(1, 'week').toDate(),
        endDate: moment().add(2, 'months').toDate(),
        completionDate: null,
        visibility: GoalVisibility.PUBLIC,
        priority: GoalPriority.P3,
        status: GoalStatus.OFF_TRACK,
        tags: [mockGoalTags[0]],
        keyResults: [
            mockGoalKeyResults[22],
            mockGoalKeyResults[23],
            mockGoalKeyResults[24]
        ],
        owners: [mockUsers[0], ...mockUsers.filter(u => u.organisationalUnit.name === 'Business Development')],
        complete: false,
        archived: false,
        activity: [createActivity],
        alignment: null,
        developmentNeeds: null
    },
    {
        id: 10,
        title: 'Simplify & clarify our product, messaging, presentation of things we do',
        type: GoalType.DEPARTMENT,
        completionPercentage: 50,
        department: mockOrganisationalUnits[5],
        officeLocation: null,
        creationDate: moment().subtract(1, 'week').toDate(),
        endDate: moment().add(2, 'months').toDate(),
        completionDate: null,
        visibility: GoalVisibility.PUBLIC,
        priority: GoalPriority.P2,
        status: GoalStatus.ON_TRACK,
        tags: [mockGoalTags[2]],
        keyResults: [
            mockGoalKeyResults[25],
            mockGoalKeyResults[26],
            mockGoalKeyResults[27]
        ],
        owners: [mockUsers[0], ...mockUsers.filter(u => u.organisationalUnit.name === 'Marketing')],
        complete: false,
        archived: false,
        activity: [createActivity],
        alignment: null,
        developmentNeeds: null
    },
    {
        id: 11,
        title: 'Understand our customers and analyze their behavior',
        type: GoalType.DEPARTMENT,
        completionPercentage: 50,
        department: mockOrganisationalUnits[5],
        officeLocation: null,
        creationDate: moment().subtract(1, 'week').toDate(),
        endDate: moment().add(2, 'months').toDate(),
        completionDate: null,
        visibility: GoalVisibility.PUBLIC,
        priority: GoalPriority.P1,
        status: GoalStatus.PROGRESSING,
        tags: [mockGoalTags[0]],
        keyResults: [
            mockGoalKeyResults[28],
            mockGoalKeyResults[29],
            mockGoalKeyResults[30]
        ],
        owners: [mockUsers[0], ...mockUsers.filter(u => u.organisationalUnit.name === 'Marketing')],
        complete: false,
        archived: false,
        activity: [createActivity],
        alignment: null,
        developmentNeeds: null
    },
    {
        id: 12,
        title: 'Improve our Office Energy Consumption Costs by 25%',
        type: GoalType.OFFICE_LOCATION,
        completionPercentage: 50,
        department: null,
        officeLocation: mockOfficeLocations[0],
        creationDate: moment().subtract(1, 'week').toDate(),
        endDate: moment().add(2, 'months').toDate(),
        completionDate: null,
        visibility: GoalVisibility.PUBLIC,
        priority: GoalPriority.P3,
        status: GoalStatus.PROGRESSING,
        tags: [mockGoalTags[1]],
        keyResults: [
            mockGoalKeyResults[31],
            mockGoalKeyResults[32]
        ],
        owners: [...mockUsers.filter(u => u.officeLocation.id === mockOfficeLocations[0].id)],
        complete: false,
        archived: false,
        activity: [createActivity],
        alignment: null,
        developmentNeeds: null
    },
    {
        id: 13,
        title: 'Improve internal employee engagement',
        type: GoalType.OFFICE_LOCATION,
        completionPercentage: 50,
        department: null,
        officeLocation: mockOfficeLocations[1],
        creationDate: moment().subtract(1, 'week').toDate(),
        endDate: moment().add(2, 'months').toDate(),
        completionDate: null,
        visibility: GoalVisibility.PUBLIC,
        priority: GoalPriority.P2,
        status: GoalStatus.ON_TRACK,
        tags: [mockGoalTags[1]],
        keyResults: [
            mockGoalKeyResults[33],
            mockGoalKeyResults[34]
        ],
        owners: [mockUsers[0], ...mockUsers.filter(u => u.officeLocation.id === mockOfficeLocations[1].id)],
        complete: false,
        archived: false,
        activity: [createActivity],
        alignment: null,
        developmentNeeds: null
    },
    {
        id: 14,
        title: 'Be excellent - improve to be the best in whatever we do',
        type: GoalType.OFFICE_LOCATION,
        completionPercentage: 50,
        department: null,
        officeLocation: mockOfficeLocations[2],
        creationDate: moment().subtract(1, 'week').toDate(),
        endDate: moment().add(2, 'months').toDate(),
        completionDate: null,
        visibility: GoalVisibility.PUBLIC,
        priority: GoalPriority.P1,
        status: GoalStatus.ON_TRACK,
        tags: mockGoalTags,
        keyResults: [
            mockGoalKeyResults[35],
            mockGoalKeyResults[36]
        ],
        owners: [mockUsers[0], ...mockUsers.filter(u => u.officeLocation.id === mockOfficeLocations[2].id)],
        complete: false,
        archived: false,
        activity: [createActivity],
        alignment: null,
        developmentNeeds: null
    },
    {
        id: 15,
        title: 'Increase Monthly Recurring Revenue by €50K',
        type: GoalType.COMPANY,
        completionPercentage: 50,
        department: null,
        officeLocation: null,
        creationDate: moment().subtract(1, 'week').toDate(),
        endDate: moment().add(2, 'months').toDate(),
        completionDate: null,
        visibility: GoalVisibility.PUBLIC,
        priority: GoalPriority.P1,
        status: GoalStatus.ON_TRACK,
        tags: mockGoalTags,
        keyResults: [
            mockGoalKeyResults[37]
        ],
        owners: [mockUsers[0]],
        complete: false,
        archived: false,
        activity: [createActivity],
        alignment: null,
        developmentNeeds: null
    },
    {
        id: 16,
        title: 'Launch into the Australian Market by Q1 2021',
        type: GoalType.COMPANY,
        completionPercentage: 50,
        department: null,
        officeLocation: null,
        creationDate: moment().subtract(1, 'week').toDate(),
        endDate: moment().add(2, 'months').toDate(),
        completionDate: null,
        visibility: GoalVisibility.PUBLIC,
        priority: GoalPriority.P2,
        status: GoalStatus.ON_TRACK,
        tags: [mockGoalTags[0]],
        keyResults: [
            mockGoalKeyResults[38]
        ],
        owners: [mockUsers[0]],
        complete: false,
        archived: false,
        activity: [createActivity],
        alignment: null,
        developmentNeeds: null
    },
    {
        id: 17,
        title: 'Increase Customer Satisfaction rate to 90%',
        type: GoalType.COMPANY,
        completionPercentage: 50,
        department: null,
        officeLocation: null,
        creationDate: moment().subtract(1, 'week').toDate(),
        endDate: moment().add(2, 'months').toDate(),
        completionDate: null,
        visibility: GoalVisibility.PUBLIC,
        priority: GoalPriority.P2,
        status: GoalStatus.ON_TRACK,
        tags: [mockGoalTags[0]],
        keyResults: [
            mockGoalKeyResults[39],
            mockGoalKeyResults[40],
            mockGoalKeyResults[41],
            mockGoalKeyResults[42],
            mockGoalKeyResults[43],
            mockGoalKeyResults[44]
        ],
        owners: [mockUsers[0]],
        complete: false,
        archived: false,
        activity: [createActivity],
        alignment: null,
        developmentNeeds: null
    },
    {
        id: 18,
        title: 'Sales revenue in excess of €750k',
        type: GoalType.TEAM,
        completionPercentage: 50,
        department: null,
        officeLocation: null,
        creationDate: moment().subtract(1, 'week').toDate(),
        endDate: moment().add(2, 'months').toDate(),
        completionDate: null,
        visibility: GoalVisibility.PUBLIC,
        priority: GoalPriority.P3,
        status: GoalStatus.ON_TRACK,
        tags: [mockGoalTags[0]],
        keyResults: [
            mockGoalKeyResults[45],
            mockGoalKeyResults[46],
            mockGoalKeyResults[47]
        ],
        owners: [mockUsers[0]],
        complete: false,
        archived: false,
        activity: [createActivity],
        alignment: null,
        developmentNeeds: null
    },
    {
        id: 19,
        title: 'Boost our number of team face to face 1:1s',
        type: GoalType.TEAM,
        completionPercentage: 50,
        department: null,
        officeLocation: null,
        creationDate: moment().subtract(1, 'week').toDate(),
        endDate: moment().add(2, 'months').toDate(),
        completionDate: null,
        visibility: GoalVisibility.PUBLIC,
        priority: GoalPriority.P2,
        status: GoalStatus.ON_TRACK,
        tags: [mockGoalTags[0]],
        keyResults: [
            mockGoalKeyResults[48],
            mockGoalKeyResults[49]
        ],
        owners: [mockUsers[0]],
        complete: false,
        archived: false,
        activity: [createActivity],
        alignment: null,
        developmentNeeds: null
    },
    {
        id: 20,
        title: 'Exceed Customer Service Targets for 2021',
        type: GoalType.PERSONAL_OPERATIONAL,
        completionPercentage: 50,
        department: null,
        officeLocation: null,
        creationDate: moment().subtract(1, 'week').toDate(),
        endDate: moment().add(2, 'months').toDate(),
        completionDate: null,
        visibility: GoalVisibility.PUBLIC,
        priority: GoalPriority.P2,
        status: GoalStatus.PROGRESSING,
        tags: [mockGoalTags[0]],
        keyResults: [
            mockGoalKeyResults[50],
            mockGoalKeyResults[51]
        ],
        owners: [mockUsers[0]],
        complete: false,
        archived: false,
        activity: [createActivity],
        alignment: null,
        developmentNeeds: null
    },
    {
        id: 21,
        title: 'Meet production standards in 2021',
        type: GoalType.PERSONAL_DEVELOPMENTAL,
        completionPercentage: 50,
        department: null,
        officeLocation: null,
        creationDate: moment().subtract(2, 'month').toDate(),
        endDate: moment().add(1, 'month').toDate(),
        completionDate: null,
        visibility: GoalVisibility.PUBLIC,
        priority: GoalPriority.P2,
        status: GoalStatus.PROGRESSING,
        tags: [mockGoalTags[0]],
        keyResults: [
            mockGoalKeyResults[52]
        ],
        owners: [mockUsers[0]],
        complete: false,
        archived: false,
        activity: [createActivity],
        alignment: null,
        developmentNeeds: null
    },
    {
        id: 22,
        title: 'Complete IMI Leadership Course by end of year',
        type: GoalType.PERSONAL_DEVELOPMENTAL,
        completionPercentage: 50,
        department: null,
        officeLocation: null,
        creationDate: moment().subtract(1, 'month').toDate(),
        endDate: moment().add(1, 'month').toDate(),
        completionDate: null,
        visibility: GoalVisibility.PUBLIC,
        priority: GoalPriority.P2,
        status: GoalStatus.OFF_TRACK,
        tags: [mockGoalTags[0]],
        keyResults: [
            mockGoalKeyResults[53]
        ],
        owners: [mockUsers[0]],
        complete: false,
        archived: false,
        activity: [createActivity],
        alignment: null,
        developmentNeeds: null
    },
];