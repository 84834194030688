<div class="row">

  <!-- Loading -->
  <div class="col-xs-12 m-top-25 m-bottom-25 text-center" *ngIf="loading === true">
    <span class="fal fa-spinner fa-pulse fa-fw fa-5x"></span>
  </div>

  <!-- ERROR -->
  <div class="col-xs-12 m-top-25 m-bottom-25 text-center" *ngIf="loading === false && error === true">
    <p>An error occurred while completing sign up!</p>
    <span class="btn-frankli-round btn-frankli-green" (click)="update()">
      Try Again?
    </span>
  </div>

</div>