import { OneToOneAnalytics } from '@app/models/analytics/one-to-one-analytics.model';
import { mockUsers } from '@app/mock/api/data/mockUsers';

export const mockOneToOneAnalytics: OneToOneAnalytics = {
  "all": {
    "name": "All",
    "entityId": 13,
    "schedules": 14,
    "scheduled": 172,
    "completed": 4,
    "entityUsers": 35,
    "usersInOneToOnes": 15,
    "oneToOnesWithManager": 4,
    "totalHoursScheduled": 89.75,
    "totalHoursCompleted": 3,
    "talkingPointsCreated": 660,
    "talkingPointsActioned": 19,
    "talkingPointsUnActioned": 641,
    "actionPointsCreated": 46,
    "actionPointsActioned": 28,
    "actionPointsUnActioned": 18,
    "usersWithoutSchedules": mockUsers.slice(5, 10),
    "lengths": {
    "FIFTEEN_MINUTES": 1,
      "THIRTY_MINUTES": 9,
      "FORTY_FIVE_MINUTES": 3,
      "SIXTY_MINUTES": 1
    },
    "frequencies": {
      "Daily": 0,
        "Weekly": 4,
        "Fortnightly": 3,
        "Monthly": 7,
        "Quarterly": 0,
        "Annually": 0,
        "Individual": 0
    },
    "monthlyMeetings": {
      "1:2021": {
        "scheduled": 0,
          "completed": 1
      },
      "11:2021": {
        "scheduled": 29,
          "completed": 0
      },
      "7:2021": {
        "scheduled": 22,
          "completed": 0
      },
      "9:2021": {
        "scheduled": 29,
          "completed": 0
      },
      "4:2021": {
        "scheduled": 0,
          "completed": 0
      },
      "8:2021": {
        "scheduled": 27,
          "completed": 0
      },
      "10:2021": {
        "scheduled": 29,
          "completed": 0
      },
      "12:2021": {
        "scheduled": 29,
          "completed": 0
      },
      "3:2021": {
        "scheduled": 0,
          "completed": 1
      },
      "5:2021": {
        "scheduled": 0,
          "completed": 1
      },
      "2:2021": {
        "scheduled": 0,
          "completed": 1
      },
      "6:2021": {
        "scheduled": 7,
          "completed": 0
      }
    }
  },
  "sites": [
  {
    "name": "HQ",
    "entityId": 88,
    "schedules": 1,
    "scheduled": 26,
    "completed": 0,
    "entityUsers": 5,
    "usersInOneToOnes": 2,
    "oneToOnesWithManager": 0,
    "totalHoursScheduled": 13,
    "totalHoursCompleted": 0,
    "talkingPointsCreated": 120,
    "talkingPointsActioned": 0,
    "talkingPointsUnActioned": 120,
    "actionPointsCreated": 0,
    "actionPointsActioned": 0,
    "actionPointsUnActioned": 0,
    "usersWithoutSchedules": mockUsers.slice(5, 10),
    "lengths": {
      "FIFTEEN_MINUTES": 0,
      "THIRTY_MINUTES": 1,
      "FORTY_FIVE_MINUTES": 0,
      "SIXTY_MINUTES": 0
    },
    "frequencies": {
      "Daily": 0,
      "Weekly": 1,
      "Fortnightly": 0,
      "Monthly": 0,
      "Quarterly": 0,
      "Annually": 0,
      "Individual": 0
    },
    "monthlyMeetings": {
      "1:2021": {
        "scheduled": 0,
        "completed": 0
      },
      "11:2021": {
        "scheduled": 4,
        "completed": 0
      },
      "7:2021": {
        "scheduled": 4,
        "completed": 0
      },
      "9:2021": {
        "scheduled": 4,
        "completed": 0
      },
      "4:2021": {
        "scheduled": 0,
        "completed": 0
      },
      "8:2021": {
        "scheduled": 4,
        "completed": 0
      },
      "10:2021": {
        "scheduled": 4,
        "completed": 0
      },
      "12:2021": {
        "scheduled": 4,
        "completed": 0
      },
      "3:2021": {
        "scheduled": 0,
        "completed": 0
      },
      "5:2021": {
        "scheduled": 0,
        "completed": 0
      },
      "2:2021": {
        "scheduled": 0,
        "completed": 0
      },
      "6:2021": {
        "scheduled": 2,
        "completed": 0
      }
    }
  },
  {
    "name": "London Office",
    "entityId": 93,
    "schedules": 1,
    "scheduled": 6,
    "completed": 0,
    "entityUsers": 8,
    "usersInOneToOnes": 2,
    "oneToOnesWithManager": 0,
    "totalHoursScheduled": 3,
    "totalHoursCompleted": 0,
    "talkingPointsCreated": 28,
    "talkingPointsActioned": 0,
    "talkingPointsUnActioned": 28,
    "actionPointsCreated": 0,
    "actionPointsActioned": 0,
    "actionPointsUnActioned": 0,
    "usersWithoutSchedules": mockUsers.slice(5, 10),
    "lengths": {
      "FIFTEEN_MINUTES": 0,
      "THIRTY_MINUTES": 1,
      "FORTY_FIVE_MINUTES": 0,
      "SIXTY_MINUTES": 0
    },
    "frequencies": {
      "Daily": 0,
      "Weekly": 0,
      "Fortnightly": 0,
      "Monthly": 1,
      "Quarterly": 0,
      "Annually": 0,
      "Individual": 0
    },
    "monthlyMeetings": {
      "1:2021": {
        "scheduled": 0,
        "completed": 0
      },
      "11:2021": {
        "scheduled": 1,
        "completed": 0
      },
      "7:2021": {
        "scheduled": 1,
        "completed": 0
      },
      "9:2021": {
        "scheduled": 1,
        "completed": 0
      },
      "4:2021": {
        "scheduled": 0,
        "completed": 0
      },
      "8:2021": {
        "scheduled": 1,
        "completed": 0
      },
      "10:2021": {
        "scheduled": 1,
        "completed": 0
      },
      "12:2021": {
        "scheduled": 1,
        "completed": 0
      },
      "3:2021": {
        "scheduled": 0,
        "completed": 0
      },
      "5:2021": {
        "scheduled": 0,
        "completed": 0
      },
      "2:2021": {
        "scheduled": 0,
        "completed": 0
      },
      "6:2021": {
        "scheduled": 0,
        "completed": 0
      }
    }
  },
  {
    "name": "Auckland Office ",
    "entityId": 395,
    "schedules": 12,
    "scheduled": 140,
    "completed": 4,
    "entityUsers": 4,
    "usersInOneToOnes": 14,
    "oneToOnesWithManager": 4,
    "totalHoursScheduled": 73.75,
    "totalHoursCompleted": 3,
    "talkingPointsCreated": 512,
    "talkingPointsActioned": 19,
    "talkingPointsUnActioned": 493,
    "actionPointsCreated": 46,
    "actionPointsActioned": 28,
    "actionPointsUnActioned": 18,
    "usersWithoutSchedules": mockUsers.slice(5, 10),
    "lengths": {
      "FIFTEEN_MINUTES": 1,
      "THIRTY_MINUTES": 7,
      "FORTY_FIVE_MINUTES": 3,
      "SIXTY_MINUTES": 1
    },
    "frequencies": {
      "Daily": 0,
      "Weekly": 3,
      "Fortnightly": 3,
      "Monthly": 6,
      "Quarterly": 0,
      "Annually": 0,
      "Individual": 0
    },
    "monthlyMeetings": {
      "1:2021": {
        "scheduled": 0,
        "completed": 1
      },
      "11:2021": {
        "scheduled": 24,
        "completed": 0
      },
      "7:2021": {
        "scheduled": 17,
        "completed": 0
      },
      "9:2021": {
        "scheduled": 24,
        "completed": 0
      },
      "4:2021": {
        "scheduled": 0,
        "completed": 0
      },
      "8:2021": {
        "scheduled": 22,
        "completed": 0
      },
      "10:2021": {
        "scheduled": 24,
        "completed": 0
      },
      "12:2021": {
        "scheduled": 24,
        "completed": 0
      },
      "3:2021": {
        "scheduled": 0,
        "completed": 1
      },
      "5:2021": {
        "scheduled": 0,
        "completed": 1
      },
      "2:2021": {
        "scheduled": 0,
        "completed": 1
      },
      "6:2021": {
        "scheduled": 5,
        "completed": 0
      }
    }
  }
  ],
  "departments": [
  {
    "name": "Learning & Development",
    "entityId": 351,
    "schedules": 1,
    "scheduled": 6,
    "completed": 0,
    "entityUsers": 3,
    "usersInOneToOnes": 2,
    "oneToOnesWithManager": 0,
    "totalHoursScheduled": 3,
    "totalHoursCompleted": 0,
    "talkingPointsCreated": 28,
    "talkingPointsActioned": 0,
    "talkingPointsUnActioned": 28,
    "actionPointsCreated": 0,
    "actionPointsActioned": 0,
    "actionPointsUnActioned": 0,
    "usersWithoutSchedules": mockUsers.slice(5, 10),
    "lengths": {
      "FIFTEEN_MINUTES": 0,
      "THIRTY_MINUTES": 1,
      "FORTY_FIVE_MINUTES": 0,
      "SIXTY_MINUTES": 0
    },
    "frequencies": {
      "Daily": 0,
      "Weekly": 0,
      "Fortnightly": 0,
      "Monthly": 1,
      "Quarterly": 0,
      "Annually": 0,
      "Individual": 0
    },
    "monthlyMeetings": {
      "1:2021": {
        "scheduled": 0,
        "completed": 0
      },
      "11:2021": {
        "scheduled": 1,
        "completed": 0
      },
      "7:2021": {
        "scheduled": 1,
        "completed": 0
      },
      "9:2021": {
        "scheduled": 1,
        "completed": 0
      },
      "4:2021": {
        "scheduled": 0,
        "completed": 0
      },
      "8:2021": {
        "scheduled": 1,
        "completed": 0
      },
      "10:2021": {
        "scheduled": 1,
        "completed": 0
      },
      "12:2021": {
        "scheduled": 1,
        "completed": 0
      },
      "3:2021": {
        "scheduled": 0,
        "completed": 0
      },
      "5:2021": {
        "scheduled": 0,
        "completed": 0
      },
      "2:2021": {
        "scheduled": 0,
        "completed": 0
      },
      "6:2021": {
        "scheduled": 0,
        "completed": 0
      }
    }
  },
  {
    "name": "Leadership",
    "entityId": 90,
    "schedules": 12,
    "scheduled": 140,
    "completed": 4,
    "entityUsers": 6,
    "usersInOneToOnes": 14,
    "oneToOnesWithManager": 4,
    "totalHoursScheduled": 73.75,
    "totalHoursCompleted": 3,
    "talkingPointsCreated": 512,
    "talkingPointsActioned": 19,
    "talkingPointsUnActioned": 493,
    "actionPointsCreated": 46,
    "actionPointsActioned": 28,
    "actionPointsUnActioned": 18,
    "usersWithoutSchedules": mockUsers.slice(5, 10),
    "lengths": {
      "FIFTEEN_MINUTES": 1,
      "THIRTY_MINUTES": 7,
      "FORTY_FIVE_MINUTES": 3,
      "SIXTY_MINUTES": 1
    },
    "frequencies": {
      "Daily": 0,
      "Weekly": 3,
      "Fortnightly": 3,
      "Monthly": 6,
      "Quarterly": 0,
      "Annually": 0,
      "Individual": 0
    },
    "monthlyMeetings": {
      "1:2021": {
        "scheduled": 0,
        "completed": 1
      },
      "11:2021": {
        "scheduled": 24,
        "completed": 0
      },
      "7:2021": {
        "scheduled": 17,
        "completed": 0
      },
      "9:2021": {
        "scheduled": 24,
        "completed": 0
      },
      "4:2021": {
        "scheduled": 0,
        "completed": 0
      },
      "8:2021": {
        "scheduled": 22,
        "completed": 0
      },
      "10:2021": {
        "scheduled": 24,
        "completed": 0
      },
      "12:2021": {
        "scheduled": 24,
        "completed": 0
      },
      "3:2021": {
        "scheduled": 0,
        "completed": 1
      },
      "5:2021": {
        "scheduled": 0,
        "completed": 1
      },
      "2:2021": {
        "scheduled": 0,
        "completed": 1
      },
      "6:2021": {
        "scheduled": 5,
        "completed": 0
      }
    }
  },
  {
    "name": "People & Culture",
    "entityId": 92,
    "schedules": 1,
    "scheduled": 26,
    "completed": 0,
    "entityUsers": 4,
    "usersInOneToOnes": 2,
    "oneToOnesWithManager": 0,
    "totalHoursScheduled": 13,
    "totalHoursCompleted": 0,
    "talkingPointsCreated": 120,
    "talkingPointsActioned": 0,
    "talkingPointsUnActioned": 120,
    "actionPointsCreated": 0,
    "actionPointsActioned": 0,
    "actionPointsUnActioned": 0,
    "usersWithoutSchedules": mockUsers.slice(5, 10),
    "lengths": {
      "FIFTEEN_MINUTES": 0,
      "THIRTY_MINUTES": 1,
      "FORTY_FIVE_MINUTES": 0,
      "SIXTY_MINUTES": 0
    },
    "frequencies": {
      "Daily": 0,
      "Weekly": 1,
      "Fortnightly": 0,
      "Monthly": 0,
      "Quarterly": 0,
      "Annually": 0,
      "Individual": 0
    },
    "monthlyMeetings": {
      "1:2021": {
        "scheduled": 0,
        "completed": 0
      },
      "11:2021": {
        "scheduled": 4,
        "completed": 0
      },
      "7:2021": {
        "scheduled": 4,
        "completed": 0
      },
      "9:2021": {
        "scheduled": 4,
        "completed": 0
      },
      "4:2021": {
        "scheduled": 0,
        "completed": 0
      },
      "8:2021": {
        "scheduled": 4,
        "completed": 0
      },
      "10:2021": {
        "scheduled": 4,
        "completed": 0
      },
      "12:2021": {
        "scheduled": 4,
        "completed": 0
      },
      "3:2021": {
        "scheduled": 0,
        "completed": 0
      },
      "5:2021": {
        "scheduled": 0,
        "completed": 0
      },
      "2:2021": {
        "scheduled": 0,
        "completed": 0
      },
      "6:2021": {
        "scheduled": 2,
        "completed": 0
      }
    }
  }
  ]
}