export class CreateSandboxDto {
  companyName: string;
  userFirstName: string;
  userLastName: string;
  userEmail: string;

  constructor(
      companyName: string,
      userFirstName: string,
      userLastName: string,
      userEmail: string
  ) {
      this.companyName = companyName;
      this.userFirstName = userFirstName;
      this.userLastName = userLastName;
      this.userEmail = userEmail;
  }
}
