import { User } from 'app/models/user/user.model';

export class DropdownOption {
  name: string;
  value: string | null;

  /**
   * Converts array of users into array of department dropdownoptions
   * @param users
   */
  static getDepartmentOptionsFromUsers(users: Array<User>, noDeptLabel: string): Array<DropdownOption> {
    const departmentOptions = new Array<DropdownOption>();
    let hasNoDepartment = false;

    for (let i = 0; i < users.length; i++) {
      const user = users[i];

      // departments
      if (user.organisationalUnit === null) {
        hasNoDepartment = true;
      } else {
        if (!departmentOptions.some(department => department.value === user.organisationalUnit.name)) {
          const name = user.organisationalUnit.name;
          const item = <DropdownOption>{ name: name, value: name };
          departmentOptions.push(item);
        }
      }
    }

    if (hasNoDepartment === true) {
      const item = <DropdownOption>{ name: noDeptLabel, value: null };
      departmentOptions.push(item);
    }

    return departmentOptions;
  }

  /**
   * Converts array of users into array of office location dropdownoptions
   * @param users
   */
  static getOfficeLocationsOptionsFromUsers(users: Array<User>): Array<DropdownOption> {
    const officeLocationOptions = new Array<DropdownOption>();
    let hasNoOfficeLocation = false;

    for (let i = 0; i < users.length; i++) {
      const user = users[i];

      // office locations
      if (user.officeLocation === null) {
        hasNoOfficeLocation = true;
      } else {
        if (!officeLocationOptions.some(officeLocation => officeLocation.value === user.officeLocation.name)) {
          const name = user.officeLocation.name;
          const item = <DropdownOption>{ name: name, value: name };
          officeLocationOptions.push(item);
        }
      }
    }

    if (hasNoOfficeLocation === true) {
      const item = <DropdownOption>{ name: 'No Office Location', value: null };
      officeLocationOptions.push(item);
    }

    return officeLocationOptions;
  }

  constructor() {
    this.name = '';
    this.value = null;
  }
}
