<nav class="navbar navbar-default navbar-absolute">
  <div class="container-fluid">
    <!-- Brand and toggle get grouped for better mobile display -->
    <div class="navbar-header">
      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
        data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <!-- <a class="navbar-brand">Frankli</a> -->
    </div>

    <!-- Collect the nav links, forms, and other content for toggling -->
    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
      <ul class="nav navbar-nav navbar-right">

        <ng-container *ngIf="showNavbar === true">
          <li>
            <a class="nav-colour" [routerLink]="'/login'">
              <i class="fal fa-sign-in" aria-hidden="true"></i> Log in
            </a>
          </li>
          <li>
            <a class="nav-colour" [routerLink]="'/reset'">
              <i class="fal fa-unlock" aria-hidden="true"></i> Forgot Password
            </a>
          </li>
          <li>
            <a class="nav-colour" href="https://www.frankli.io" target="_blank">
              <i class="fal fa-info-circle" aria-hidden="true"></i> About
            </a>
          </li>
        </ng-container>

        <ng-container *ngIf="showNavbarSignUp === true">
          <li class="clickable" (click)="logout()">
            <a class="nav-colour">
              <i class="fal fa-sign-out"></i> Log out
            </a>
          </li>
        </ng-container>

      </ul>
    </div>
  </div>
</nav>

<div class="login-container">
  <router-outlet></router-outlet>
</div>