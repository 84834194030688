import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GoalKeyResultMeasureUnitPlacement } from '@app/models/goals/goal-key-result-measure-unit-placement.model';
import { GoalKeyResultType } from '@app/models/goals/goal-key-result-type.model';
import { HtmlPipe } from '@app/shared/pipes/html.pipe';
import { GoalKeyResult } from 'app/models/goals/goal-key-result.model';
import { GoalProgressDto } from 'app/models/goals/goal.dto';
import { Goal, GoalStatus } from 'app/models/goals/goal.model';
import { GoalsAPIService } from 'app/shared/api/goals.api.service';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  'selector': 'app-goals-individual-update-component',
  'templateUrl': './goals-individual-update.component.html',
  'styleUrls': ['./goals-individual-update.component.css']
})
export class GoalsIndividualUpdateComponent implements OnInit, OnChanges {
  readonly eGoalKeyResultType = GoalKeyResultType;
  readonly eGoalKeyResultMeasureUnitPlacement = GoalKeyResultMeasureUnitPlacement;
  readonly editorMaxLengthSoft = 800;
  readonly editorMaxLengthHard = 1000;
  readonly editorToolbar = 'undo redo | formatselect | bold italic underline strikethrough | help';

  @Input() goal !: Goal;
  @Input() showControls !: boolean;
  @Input() editing !: any;
  @Output() refresh: EventEmitter<boolean> = new EventEmitter();
  @Output() completeEmit: EventEmitter<boolean> = new EventEmitter();

  eGoalStatus = GoalStatus;

  // Update Form //
  formUpdate!: FormGroup;

  progressUpdate!: FormControl;
  status!: FormControl;
  keyResults!: FormArray;

  submitted = false;
  updateLoading = false;

  constructor(
    private formBuilder: FormBuilder,
    private goalsAPIService: GoalsAPIService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.createFormUpdate();
  }

  ngOnChanges() {
    this.createFormUpdate();
  }

  private createFormUpdate(): void {
    this.submitted = false;
    const keyResult = this.goal.keyResults[0];

    this.formUpdate = this.formBuilder.group({
      progressUpdate: ['', [this.softMaxValidation(this.editorMaxLengthSoft), Validators.maxLength(this.editorMaxLengthHard)]],
      status: [this.goal.status, [Validators.required]],
      keyResults: this.formBuilder.array([])
    });

    this.progressUpdate = this.formUpdate.controls.progressUpdate as FormControl;
    this.status = this.formUpdate.controls.status as FormControl;
    this.keyResults = this.formUpdate.controls.keyResults as FormArray;

    this.progressUpdate.valueChanges.subscribe(res => {
      this.cdRef.detectChanges();
    });

    this.goal.keyResults.forEach(result => {
      this.updateAddKeyResult(this.keyResults, result);
    });
  }

  initKeyResult(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(null, []),
      result: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      type: new FormControl(null, [Validators.required]),
      measureCurrentValue: new FormControl(0, [Validators.required, Validators.pattern(/^(\d{1,22})(.\d{1,2})?$/)]),
      measureStartValue: new FormControl(0, [Validators.required, Validators.pattern(/^(\d{1,22})(.\d{1,2})?$/)]),
      measureGoalValue: new FormControl(1, [Validators.required, Validators.pattern(/^(\d{1,22})(.\d{1,2})?$/)]),
      endDate: new FormControl(null, []),
      reversed: new FormControl(false, []),
      measureUnit: new FormControl(null, []),
      measureUnitPlacement: new FormControl(null, [])
    });
  }

  updateAddKeyResult(formArray: FormArray, result: GoalKeyResult) {
    const formControl = this.initKeyResult();

    formControl.controls.id.setValue(result.id);
    formControl.controls.result.setValue(result.result);
    formControl.controls.type.setValue(result.type);
    formControl.controls.measureCurrentValue.setValue(result.measureCurrentValue);
    formControl.controls.measureStartValue.setValue(result.measureStartValue);
    formControl.controls.measureGoalValue.setValue(result.measureGoalValue);
    formControl.controls.endDate.setValue(result.endDate);
    formControl.controls.reversed.setValue(result.reversed);
    formControl.controls.measureUnit.setValue(result.measureUnit);
    formControl.controls.measureUnitPlacement.setValue(result.measureUnitPlacement);

    // TODO: move whole form types to enums
    // TODO: remove code duplication
    formControl.controls.type.valueChanges.subscribe(type => {

      let current = 0;
      let target = 0;

      switch (type) {
        case GoalKeyResultType.BINARY:
          current = 0;
          target = 1;
          break;
        case GoalKeyResultType.NUMERIC:
          current = 0;
          target = 100;
          break;
        default:
          break;
      }
      formControl.controls.measureCurrentValue.setValue(current);
      formControl.controls.measureGoalValue.setValue(target);
    });

    formArray.push(formControl);
  }

  validateResultValues(): boolean {
    const formArray = this.formUpdate.get('keyResults') as FormArray;
    formArray.controls.forEach((control) => {
      const formGroup = control as FormGroup;
      if (isNaN(formGroup.controls.measureCurrentValue.value)) { return false }
      if (isNaN(formGroup.controls.measureStartValue.value)) { return false }
      if (isNaN(formGroup.controls.measureGoalValue.value)) { return false }
    });

    return true;
  }

  updateGoal() {
    if (!this.updateLoading) {
      this.submitted = true;
      this.updateLoading = true;
      if (this.formUpdate.valid === true && this.validateResultValues()) {

        const keyResults = new Array<GoalKeyResult>();
        const formArray = this.formUpdate.get('keyResults') as FormArray;
        formArray.controls.forEach((control) => {
          const formGroup = control as FormGroup;
          const id = formGroup.controls.id.value;
          const current = Number(formGroup.controls.measureCurrentValue.value);
          const start = Number(formGroup.controls.measureStartValue.value);
          const goal = Number(formGroup.controls.measureGoalValue.value);
          const result = {
            id: id,
            measureCurrentValue: current,
            measureStartValue: start,
            measureGoalValue: goal,
            reversed: (goal < start)
          } as GoalKeyResult;
          keyResults.push(result);
        });
        this.progressUpdate.setValue(this.progressUpdate.value.trim());

        const goalProgressDto = new GoalProgressDto(
          this.status.value,
          keyResults,
          this.progressUpdate.value
        );

        this.goalsAPIService.updateProgress(this.goal.id, goalProgressDto).subscribe((response: Goal) => {
          $.notify('This goal has been successfully updated');
          this.updateLoading = false;
          this.refresh.emit(true);
          this.createFormUpdate();
        }, (_failure: HttpErrorResponse) => {
          this.submitted = false;
          this.updateLoading = false;
        })

      } else {
        this.updateLoading = false;
      }
    }
  }

  setGoalStatus(status: GoalStatus) {
    this.status.setValue(status);
  }

  unsavedChanges() {
    if (this.goal.status !== this.status.value) {
      // return true;
    }
    if (this.progressUpdate.value && this.progressUpdate.value.trim().length !== 0) {
      return true;
    }

    return false;
  }

  completeGoal() {
    if (!this.goal.complete) {
      this.completeEmit.emit(true);
    }
  }

  getProgress(reversed: boolean, goalValue: string, currentValue: string, startValue: string): string {
    const goal = Number(goalValue);
    const current = Number(currentValue);
    const start = Number(startValue);
    if(isNaN(goal) || isNaN(current) || isNaN(start)){
      return '0';
    }

    let progress = ((start - current) / (start - goal)) * 100;

    if (progress > 100) {
      progress = 100;
    }

    if (progress < 0) {
      progress = 0;
    }

    return progress.toFixed(2);
  }

  softMaxValidation(max: number) {
    return (control: AbstractControl) => {
      const value = control.value;
      if (value) {
        const htmlPipe = new HtmlPipe();
        const valueNoTags = htmlPipe.transform(value);
        if (valueNoTags.length > max) {
          return {
            softmax: true,
          };
        }
      }

      return null!;
    }
  }
}
