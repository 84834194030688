import * as moment from 'moment';
import { User } from '../user/user.model';
import { FrankliEventStatus } from './frankli-event-status.enum';
import { FrankliNotificationType } from './frankli-event-type.enum';
import { FrankliNotificationServerside } from './frankli-notification-serverside.model';

export class FrankliNotification {
  public id: number;
  public userId: number;
  public eventType: FrankliNotificationType;
  public eventForeignId: number;
  public timestamp: Date;
  public url: string;
  public imageUrl: string;
  public text: string;
  public eventStatus: FrankliEventStatus;
  public task: boolean;
  public action: boolean;
  public user?: User;
  public viewed?: boolean;

  constructor(frankliNotificationServerside: FrankliNotificationServerside) {
    this.id = frankliNotificationServerside.id;
    this.userId = frankliNotificationServerside.userId;
    this.eventType = frankliNotificationServerside.eventType;
    this.eventForeignId = frankliNotificationServerside.eventForeignId;
    this.timestamp = moment.utc(frankliNotificationServerside.timestamp).toDate();
    this.url = frankliNotificationServerside.url;
    this.imageUrl = frankliNotificationServerside.imageUrl;
    this.text = frankliNotificationServerside.text;
    this.eventStatus = frankliNotificationServerside.eventStatus;
    this.task = frankliNotificationServerside.task;
    this.action = frankliNotificationServerside.action;
  }

}
