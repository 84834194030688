<ng-container *ngIf="goal">
  <!-- Key Results -->
  <div class="kr-container" *ngIf="goal.keyResults">
    <label><i class="fal fa-chart-bar m-right-5"></i> Key Results</label>

    <!-- Header -->
    <div class="row m-top-15 kr-headings">
      <div class="col-md-4 col-md-offset-1">
        Title
      </div>
      <div class="col-md-1">
        Due Date
      </div>
      <div class="col-md-2 text-center">
        Current Value / Goal Value
      </div>
      <div class="col-md-4">
        Progress
      </div>
    </div>

    <!-- Table -->
    <div class="row m-top-15" *ngFor="let result of goal.keyResults; let ix = index;">
      <div class="col-md-1 col-md-offset-0 col-sm-1 col-xs-1 text-right text-nowrap">{{ix + 1}}.</div>
      <div class="col-md-4 col-md-offset-0 col-sm-11 col-xs-11">
        <strong>{{result.result}}</strong>
      </div>
      <div class="col-md-1 col-md-offset-0 col-sm-1 col-xs-12 col-xs-offset-1">
        <span>{{(result.endDate !== null)?(result.endDate | date: 'dd/MM/yyyy'):('')}}</span>
      </div>
      <div class="text-center col-md-2">
        <span *ngIf="result.type === eGoalKeyResultType.BINARY">
          <span>{{(result.measureCurrentValue === 0)?('Incomplete'):('Complete')}}</span>
        </span>
        <span *ngIf="result.type === eGoalKeyResultType.NUMERIC" class="m-left-0 m-right-0">
          <span>
            {{result.measureUnitPlacement === eGoalKeyResultMeasureUnitPlacement.BEFORE ? result.measureUnit : ''}}
            {{result.measureCurrentValue | number: '1.0-2'}}
            {{result.measureUnitPlacement === eGoalKeyResultMeasureUnitPlacement.AFTER ? result.measureUnit : ''}}
          </span>
          <span> / </span>
          <span>
            {{result.measureUnitPlacement === eGoalKeyResultMeasureUnitPlacement.BEFORE ? result.measureUnit : ''}}
            {{result.measureGoalValue | number: '1.0-2'}}
            {{result.measureUnitPlacement === eGoalKeyResultMeasureUnitPlacement.AFTER ? result.measureUnit : ''}}
          </span>
        </span>
      </div>
      <div class="col-md-4 col-md-offset-0 col-sm-12 col-xs-11 col-xs-offset-1">
        <div class="row m-left-0">
          <div class="col-md-9 col-sm-9 col-xs-9">
            <div class="progress progress-bar-margin">
              <div class="progress-bar progress-bar-info" [style.width]="getPercentage(result)+'%'">
                <span class="sr-only"></span>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-3 col-xs-3 text-nowrap">{{getPercentage(result)}} %</div>
        </div>
      </div>
    </div>
  </div>

  <!-- Aligned goal -->
  <div class="aligned-goal-container" *ngIf="alignedGoal; let g">
    <label><i class="fal fa-handshake m-right-5"></i> Goal Aligned With</label>
    <ng-container [ngTemplateOutlet]="goalRow" [ngTemplateOutletContext]="{goal: g}"></ng-container>
  </div>

  <!-- Aligned to this -->
  <div class="aligned-goal-container" *ngIf="alignedGoals.length > 0">
    <label><i class="fal fa-handshake m-right-5"></i> Aligned With This</label>
    <ng-container *ngFor="let g of alignedGoals" [ngTemplateOutlet]="goalRow" [ngTemplateOutletContext]="{goal: g}">
    </ng-container>
  </div>
</ng-container>

<ng-template #goalRow let-goal='goal'>
  <div class="row clickable" (click)="navigateToGoal(goal.id)">
    <div class="col-xs-8">
      <span class="m-left-30">{{goal.title}}</span>
    </div>
    <div class="col-xs-4">
      <div class="row m-left-0">
        <div class="col-md-9 col-sm-9 col-xs-9">
          <div class="progress progress-bar-margin">
            <div class="progress-bar progress-bar-info" [style.width]="goal.completionPercentage+'%'">
              <span class="sr-only"></span>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-3 text-nowrap">{{goal.completionPercentage}} %</div>
      </div>
    </div>
  </div>

</ng-template>