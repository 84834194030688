import { CreateGoalDraftKeyResultDto } from "./create-goal-draft-key-result.dto";
import { CreateGoalKeyResultDto } from "./create-goal-key-result.dto";
import { GoalPriority, GoalType, GoalVisibility } from "./goal.model";

export class CreateGoalDraftDto {

  constructor(
    public title: string | null,
    public type: GoalType | null,
    public departmentId: number | null,
    public officeLocationId: number | null,
    public endDate: Date | null,
    public visibility: GoalVisibility | null,
    public priority: GoalPriority | null,
    public tagIds: Array<number>,
    public ownerIds: Array<number>,
    public keyResults: Array<CreateGoalDraftKeyResultDto>,
    public alignmentId: number | null,
    public developmentNeeds: string | null
  ) { }

}