import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { User } from 'app/models/user/user.model';
import { Interest, InterestCategory } from 'app/models/interest.model';
import Swal from 'sweetalert2';
import { UserAPIService } from 'app/shared/api/user.api.service';
import { Globals } from 'app/shared/globals/globals';
import { EditProfileService } from 'app/profile/profile-components/edit/edit-profile.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';

declare var $: any;

type InterestTypes = ('INTERESTS_PERSONAL' | 'INTERESTS_PROFESSIONAL')
@Component({
  selector: 'app-employee-sign-up-interests',
  templateUrl: './interests.component.html',
  styleUrls: ['./interests.component.css']
})
export class InterestsComponent implements OnInit, OnDestroy {
  private readonly ngUnsubscribe$: Subject<void> = new Subject<void>();
  readonly maxInterests = 50;

  @Input() user!: User;
  @Input() userInterests: Interest[] = [];
  @Input() categories: InterestCategory[] = [];
  @Input() minimumInterests!: number;
  @Input() step?: any;
  @Input() formType?: string;

  @Output() userInterestsChange: EventEmitter<Interest[]> = new EventEmitter<Interest[]>();

  type: InterestTypes = 'INTERESTS_PERSONAL';
  selectedCategory?: InterestCategory;

  subscriptionCanChange!: Subscription;

  innerCategories: InterestCategory[] = [];
  innerInterests: Interest[] = [];

  loading: boolean = false;

  constructor(
    private userAPIService: UserAPIService,
    private globals: Globals,
    private editProfileService: EditProfileService) {
    this.editProfileService.getCanChange()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(res => {
        // check for unsaved changes
        if (this.checkForUnsavedChanges()) {
          Swal.fire({
            title: 'Unsaved Changes',
            text: 'Your changes will not be saved if you leave!',
            imageUrl: 'assets/img/swal-icons/Frankli_sure_icon-46.svg',
            imageWidth: 140,
            imageHeight: 140,
            confirmButtonColor: '#54c6bb',
            showCancelButton: true
          }).then(val => {
            if (val.dismiss) {
              return false;
            }

            this.editProfileService.sendChange(res);
          });
        } else {
          this.editProfileService.sendChange(res);
        }
      });
  }

  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }

  getData() {
    this.type = this.step.current;
    this.innerInterests = JSON.parse(JSON.stringify(this.userInterests));
    this.innerCategories = JSON.parse(JSON.stringify(this.categories));

    this.removeSelected(this.innerInterests);

    this.selectedCategory = this.innerCategories[0];
  }

  removeSelected(selected: Interest[]) {
    if (selected.length > 0) {
      this.innerCategories.forEach(c => {
        c.interests = c.interests.filter(ci => selected.find(i => i.name === ci.name) === undefined)
      })
    }
  }

  selectCategory(category: InterestCategory) {
    // Category to display
    this.selectedCategory = category;
  }

  addInterest(interest: Interest) {
    if (this.innerInterests.length < this.maxInterests) {
      // remove interest from categories as it is selected
      this.removeFromArray(this.selectedCategory!.interests, interest);
      interest.category = this.selectedCategory!.name;

      // add to selected interests
      this.innerInterests.push(interest);
    } else {
      Swal.fire({
        title: 'You can only select ' + this.maxInterests + ' interests!',
        text: 'You can remove one and add another below.',
        imageUrl: 'assets/img/swal-icons/Frankli_sure_icon-46.svg',
        imageWidth: 140,
        imageHeight: 140,
        confirmButtonColor: '#54c6bb'
      })
    }
  }

  removeInterest(interest: Interest) {
    // remove interest from selected interests
    this.removeFromArray(this.innerInterests, interest);

    // add to original interest
    for (let i = 0; i < this.innerCategories.length; i++) {
      if (this.innerCategories[i].name === interest.category) {
        this.innerCategories[i].interests.push(interest);
        break;
      }
    }

  }

  removeFromArray(interests: Array<Interest>, interest: Interest) {
    const index = interests.indexOf(interest, 0);
    if (index > -1) {
      interests.splice(index, 1);
    }
  }

  previous() {
    this.step.complete -= 1;
    if (this.step.current === 'INTERESTS_PERSONAL') {
      this.step.current = 'PROFILE_PICTURE'
    } else if (this.step.current === 'INTERESTS_PROFESSIONAL') {
      this.step.current = 'INTERESTS_PERSONAL';
    };
  }

  next() {
    this.step.complete += 1;
    this.step.current = 'INTERESTS_PROFESSIONAL';
  }

  save() {
    const route = this.getSaveRoute(this.step.current);

    if (route !== undefined) {
      this.loading = true;
      route.subscribe(
        (user) => {
          this.globals.user = user;
          this.user = user;

          this.updateInterests(this.innerInterests)

          $.notify('Your profile has been successfully updated');
          this.loading = false;
        }, (_error: HttpErrorResponse) => {
          this.loading = false;
        }
      );
    }

  }

  getSaveRoute(step: InterestTypes): (Observable<User> | undefined) {
    switch (step) {
      case 'INTERESTS_PERSONAL':
        return this.userAPIService.updatePersonalInterests(this.innerInterests)
      case 'INTERESTS_PROFESSIONAL':
        return this.userAPIService.updateProfessionalInterests(this.innerInterests)
      default:
        return undefined;
    }
  }

  checkForUnsavedChanges() {
    if (this.innerInterests.length !== this.userInterests.length) {
      return true;
    }

    let bool = false;

    this.userInterests.forEach(interest => {
      if (this.innerInterests[this.userInterests.indexOf(interest)].id !== interest.id) {
        bool = true;
        return true;
      }
    });

    return bool;
  }

  complete() {
    this.step.complete += 1;
    this.step.current = 'SIGNUP_COMPLETE';
  }

  updateInterests(interests: Interest[]) {
    this.userInterests = interests;
    this.userInterestsChange.emit(interests);
  }
}

