import { SurveyQuestionType } from '@app/models/survey-question-type.enum';
import { QuestionBankQuestionCategory } from '@app/models/evaluation/question-bank-question-category.enum';

export const assessmentQuestions = [
  {
    colour: '#008168',
    groupTitle: 'Significantly Exceeds Expectations',
    groupDescription: 'Delivered exceptional added value to business beyond scope',
    value: '5',
    questionType: SurveyQuestionType.RANKED_ONE_TO_FIVE,
    answerIndex: 0,
    category: QuestionBankQuestionCategory.MANAGER_REVIEW
  },
  {
    colour: '#54c7ba',
    groupTitle: 'Exceeds Expectations',
    groupDescription: 'Delivered differentiated value within business/work area/team',
    value: '4',
    questionType: SurveyQuestionType.RANKED_ONE_TO_FIVE,
    answerIndex: 1,
    category: QuestionBankQuestionCategory.MANAGER_REVIEW
  },
  {
    colour: '#ffbd36',
    groupTitle: 'Meets Expectations',
    groupDescription: 'Delivered positive value to business/work area/team',
    value: '3',
    questionType: SurveyQuestionType.RANKED_ONE_TO_FIVE,
    answerIndex: 2,
    category: QuestionBankQuestionCategory.MANAGER_REVIEW
  },
  {
    colour: '#f75757',
    groupTitle: 'Mostly Met Expectations',
    groupDescription: 'Delivered some added value to business/work area/team',
    value: '2',
    questionType: SurveyQuestionType.RANKED_ONE_TO_FIVE,
    answerIndex: 3,
    category: QuestionBankQuestionCategory.MANAGER_REVIEW
  },
  {
    colour: '#d0352c',
    groupTitle: 'Did Not Meet Expectations',
    groupDescription: 'Did not add value and/or negative impact',
    value: '1',
    questionType: SurveyQuestionType.RANKED_ONE_TO_FIVE,
    answerIndex: 4,
    category: QuestionBankQuestionCategory.MANAGER_REVIEW
  },
  {
    colour: '#008168',
    groupTitle: 'Top Performer',
    groupDescription: 'Role model. Hard to see how their performance could be improved upon',
    value: '4',
    questionType: SurveyQuestionType.PERFORMER,
    answerIndex: 0,
    category: QuestionBankQuestionCategory.MANAGER_REVIEW
  },
  {
    colour: '#54c7ba',
    groupTitle: 'Above Average Performer',
    groupDescription: 'Consistently delivers and has demonstrated strong potential in their role',
    value: '3',
    questionType: SurveyQuestionType.PERFORMER,
    answerIndex: 1,
    category: QuestionBankQuestionCategory.MANAGER_REVIEW
  },
  {
    colour: '#ffbd36',
    groupTitle: 'Average Performer',
    groupDescription: 'Could apply themselves more to their current role',
    value: '2',
    questionType: SurveyQuestionType.PERFORMER,
    answerIndex: 2,
    category: QuestionBankQuestionCategory.MANAGER_REVIEW
  },
  {
    colour: '#f75757',
    groupTitle: 'Below Average Performer',
    groupDescription: 'Performs below what is expected of them in their current role. Needs further support',
    value: '1',
    questionType: SurveyQuestionType.PERFORMER,
    answerIndex: 3,
    category: QuestionBankQuestionCategory.MANAGER_REVIEW
  },
  {
    colour: '#008168',
    groupTitle: 'Exceeds Expectations',
    groupDescription: 'Role model. Hard to see how their performance could be improved upon',
    value: '3',
    questionType: SurveyQuestionType.EXPECTATIONS,
    answerIndex: 0,
    category: QuestionBankQuestionCategory.MANAGER_REVIEW
  },
  {
    colour: '#ffbd36',
    groupTitle: 'Meets Expectations',
    groupDescription: 'Could apply themselves more to their current role',
    value: '2',
    questionType: SurveyQuestionType.EXPECTATIONS,
    answerIndex: 1,
    category: QuestionBankQuestionCategory.MANAGER_REVIEW
  },
  {
    colour: '#FF6F68',
    groupTitle: 'Below Expectations',
    groupDescription: 'Performs below what is expected of them in their current role. Needs further support',
    value: '1',
    questionType: SurveyQuestionType.EXPECTATIONS,
    answerIndex: 2,
    category: QuestionBankQuestionCategory.MANAGER_REVIEW
  },{
    colour: '#008168',
    groupTitle: 'Significantly Exceeds Expectations',
    groupDescription: 'Delivered exceptional added value to business beyond scope',
    value: '5',
    questionType: SurveyQuestionType.RANKED_ONE_TO_FIVE,
    answerIndex: 0,
    category: QuestionBankQuestionCategory.SELF_REFLECTION
  },
  {
    colour: '#54c7ba',
    groupTitle: 'Exceeds Expectations',
    groupDescription: 'Delivered differentiated value within business/work area/team',
    value: '4',
    questionType: SurveyQuestionType.RANKED_ONE_TO_FIVE,
    answerIndex: 1,
    category: QuestionBankQuestionCategory.SELF_REFLECTION
  },
  {
    colour: '#ffbd36',
    groupTitle: 'Meets Expectations',
    groupDescription: 'Delivered positive value to business/work area/team',
    value: '3',
    questionType: SurveyQuestionType.RANKED_ONE_TO_FIVE,
    answerIndex: 2,
    category: QuestionBankQuestionCategory.SELF_REFLECTION
  },
  {
    colour: '#f75757',
    groupTitle: 'Mostly Met Expectations',
    groupDescription: 'Delivered some added value to business/work area/team',
    value: '2',
    questionType: SurveyQuestionType.RANKED_ONE_TO_FIVE,
    answerIndex: 3,
    category: QuestionBankQuestionCategory.SELF_REFLECTION
  },
  {
    colour: '#d0352c',
    groupTitle: 'Did Not Meet Expectations',
    groupDescription: 'Did not add value and/or negative impact',
    value: '1',
    questionType: SurveyQuestionType.RANKED_ONE_TO_FIVE,
    answerIndex: 4,
    category: QuestionBankQuestionCategory.SELF_REFLECTION
  },
  {
    colour: '#008168',
    groupTitle: 'Top Performer',
    groupDescription: 'Role model. Hard to see how their performance could be improved upon',
    value: '4',
    questionType: SurveyQuestionType.PERFORMER,
    answerIndex: 0,
    category: QuestionBankQuestionCategory.SELF_REFLECTION
  },
  {
    colour: '#54c7ba',
    groupTitle: 'Above Average Performer',
    groupDescription: 'Consistently delivers and has demonstrated strong potential in their role',
    value: '3',
    questionType: SurveyQuestionType.PERFORMER,
    answerIndex: 1,
    category: QuestionBankQuestionCategory.SELF_REFLECTION
  },
  {
    colour: '#ffbd36',
    groupTitle: 'Average Performer',
    groupDescription: 'Could apply themselves more to their current role',
    value: '2',
    questionType: SurveyQuestionType.PERFORMER,
    answerIndex: 2,
    category: QuestionBankQuestionCategory.SELF_REFLECTION
  },
  {
    colour: '#f75757',
    groupTitle: 'Below Average Performer',
    groupDescription: 'Performs below what is expected of them in their current role. Needs further support',
    value: '1',
    questionType: SurveyQuestionType.PERFORMER,
    answerIndex: 3,
    category: QuestionBankQuestionCategory.SELF_REFLECTION
  },
  {
    colour: '#008168',
    groupTitle: 'Exceeds Expectations',
    groupDescription: 'Role model. Hard to see how their performance could be improved upon',
    value: '3',
    questionType: SurveyQuestionType.EXPECTATIONS,
    answerIndex: 0,
    category: QuestionBankQuestionCategory.SELF_REFLECTION
  },
  {
    colour: '#ffbd36',
    groupTitle: 'Meets Expectations',
    groupDescription: 'Could apply themselves more to their current role',
    value: '2',
    questionType: SurveyQuestionType.EXPECTATIONS,
    answerIndex: 1,
    category: QuestionBankQuestionCategory.SELF_REFLECTION
  },
  {
    colour: '#FF6F68',
    groupTitle: 'Below Expectations',
    groupDescription: 'Performs below what is expected of them in their current role. Needs further support',
    value: '1',
    questionType: SurveyQuestionType.EXPECTATIONS,
    answerIndex: 2,
    category: QuestionBankQuestionCategory.SELF_REFLECTION
  },
];

export const questionDataConstants = {
  eNPS: [
    {
      colour: '#F75757',
      name: 'Detractor',
      value: '1',
    },
    {
      colour: '#F75757',
      name: 'Detractor',
      value: '2',
    },
    {
      colour: '#F75757',
      name: 'Detractor',
      value: '3',
    },
    {
      colour: '#F75757',
      name: 'Detractor',
      value: '4',
    },
    {
      colour: '#F75757',
      name: 'Detractor',
      value: '5',
    },
    {
      colour: '#F75757',
      name: 'Detractor',
      value: '6',
    },
    {
      colour: '#ffbd36',
      name: 'Neutral',
      value: '7',
    },
    {
      colour: '#ffbd36',
      name: 'Neutral',
      value: '8',
    },
    {
      colour: '#54C6BB',
      name: 'Promoter',
      value: '9',
    },
    {
      colour: '#54C6BB',
      name: 'Promoter',
      value: '10',
    }
  ]
}
