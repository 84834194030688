<div class="col-md-12">
  <div *ngIf="goals.length > 0">
    <div class="header">
      <h4 class="title text-left" [class.m-bottom-neg]="userProfile.id === globals.user.id">{{(globals.user.id === userProfile?.id)?'':userProfile?.firstName+"'s "}}Personal Goals</h4>
      <a *ngIf="userProfile.id === globals.user.id" class="view-all-link clickable" [routerLink]="'/goals'">View all Goals</a>
      <hr class="m-top-0 m-bottom-0"/>
    </div>
    <div class="content goals-scroll-area text-center">
      <div class="row" *ngFor="let goal of goals">
        <div class="col-md-12">
          <div class="card clickable clickable-hover m-bottom-15">
            <div class="content" [routerLink]="'goals/individual/' + goal.id">
              <div class="row row-height">
                <div class="col-md-1">
                  <span [class]="'icon-priority priority-' + goal.priority">{{goal.priority}}</span>
                </div>

                <div class="col-md-4">
                  <span class="font-bold">{{goal.title}}</span>
                </div>

                <div class="col-md-2 ">
                  <span>{{goal.keyResults.length}} key results</span>
                </div>

                <div class="col-md-3">
                  <div class="progress-container">
                    <div class="progress">
                      <div class="progress-bar progress-bar-info" [style.width]="goal.completionPercentage+'%'">
                        <span class="sr-only"></span>
                      </div>
                    </div>
                  </div>
                  <div class="progress-label">{{goal.completionPercentage}}%</div>
                </div>

                <div class="col-md-2 text-center">
                  <!-- <span class="m-right-25 goal-due">Due on {{goal.endDate | date:'d MMMM y'}}</span> -->
                  <span class="goal-due">Due on {{goal.endDate | date:'dd/MM/yy'}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="goals.length === 0 && !loading">
    <div class="header">
      <h4 class="title text-left">Goals</h4>
      <hr class="less-padding" />
    </div>
    <div class="content text-center">
      <div class="row" *ngIf="goals.length === 0 && userProfile.id !== user.id && !loading">
        <p>
          <img class="icon-padding new-icon-height" src="/assets/img/NoGoals.svg" />
          <br> {{userProfile.firstName}} has yet to set any goals to public</p>
      </div>
      <div class="row" *ngIf="goals.length === 0 && userProfile.id === user.id && !loading">
        <div class="col-md-4">
          <img class="new-icon-height" src="/assets/img/NoSearchFound.svg" />
        </div>
        <div class="col-md-8 text-left">
          <div class="text-green text-bold m-bottom-20">Did you know, goal-setting is linked to higher achievement?</div>
          <div class="text-bold m-bottom-20">When setting goals - Put goals in writing, Self-commit, Be specific, Stretch for difficulty, Seek feedback and support</div>
          <div>
            <span class="text-bold">What are you waiting for?</span>
            <button class="pull-right btn-frankli-round btn-frankli-green-inverted" [routerLink]="'/goals'" [queryParams]="{action: 'create'}">Set a Goal</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>