import { Routes } from '@angular/router';
import { ProfileComponent } from './profile-components/profile.component';
import { UserComponent } from './profile-components/user/user.component';
import { EditComponent } from './profile-components/edit/edit.component';
import { GoalsAllComponent } from 'app/goals/goals-components/goals-all/goals-all.component';
import { GoalsIndividualComponent } from 'app/goals/goals-components/goals-individual/goals-individual.component';
import { ProfilePraiseFullComponent } from './profile-components/profile-praise-full/profile-praise-full.component';

export const ProfileRoutes: Routes = [
  {
    path: '',
    component: ProfileComponent,
    children: [
      { path: 'edit', component: EditComponent },
      { path: 'edit/:part', component: EditComponent },
      {
        path: '', component: UserComponent, data: { label: 'My Profile' }, children: [
          { path: 'goals/individual/:id', component: GoalsIndividualComponent, data: { label: 'Goal' } },
          {
            path: 'connect',
            loadChildren: () => import('../connect/connect.module').then(m => m.ConnectModule)
          },
          {
            path: 'feedback',
            loadChildren: () => import('app/feedback/feedback.module').then(m => m.FeedbackModule)
          }
        ]
      },
      {
        path: ':id', component: UserComponent, data: { label: 'Profile' }, children: [
          { path: 'goals/individual/:id', component: GoalsIndividualComponent, data: { label: 'Goals' } },
          {
            path: 'goals/all/:userId', component: GoalsAllComponent, data: { label: 'Goals' }, children: [
              { path: 'individual/:id', component: GoalsIndividualComponent, data: { label: 'Goal' } }
            ]
          },
          {
            path: 'connect',
            loadChildren: () => import('../connect/connect.module').then(m => m.ConnectModule)
          },
          {
            path: 'feedback',
            loadChildren: () => import('app/feedback/feedback.module').then(m => m.FeedbackModule)
          },
          { path: 'praise', component: ProfilePraiseFullComponent, data: { label: 'Praise' } }
        ]
      }
    ]
  },
];

