import { Injectable } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';
import { SortType } from 'app/models/pagination/sort-type.model';
import * as moment from 'moment-timezone';
@Injectable()
export class PaginationService {
  private subjectArrayDisplay = new Subject<Array<any>>();
  private subjectArrayFiltered = new Subject<Array<any>>();

  private subjectInit = new Subject<string>();

  constructor() {
  }

  getArrayFiltered(): Observable<Array<any>> {
    return this.subjectArrayFiltered.asObservable();
  }

  getArrayDisplay(): Observable<Array<any>> {
    return this.subjectArrayDisplay.asObservable();
  }

  getInit() {
    return this.subjectInit.asObservable();
  }

  sendArrayFiltered(array: Array<any>) {
    this.subjectArrayFiltered.next(array);
  }

  sendArrayDisplay(array: Array<any>) {
    this.subjectArrayDisplay.next(array);
  }

  sendInit() {
    this.subjectInit.next('INIT');
  }


  sortByString(sortType: SortType, sortTypeValue: string, array: Array<any>, field: string): Array<any> {
    if (sortType.name === sortTypeValue && sortType.direction === 'ascending') {
      sortType.direction = 'descending';
      array.reverse();
    } else {
      sortType.name = sortTypeValue;
      sortType.direction = 'ascending';
      array
        .sort((a, b) => (a[field]).localeCompare(b[field]));
    }
    return array;
  }

  sortByNumber(sortType: SortType, sortTypeValue: string, array: Array<any>, field: string): Array<any> {
    if (sortType.name === sortTypeValue && sortType.direction === 'ascending') {
      sortType.direction = 'descending';
      array.reverse();
    } else {
      sortType.name = sortTypeValue;
      sortType.direction = 'ascending';
      array.sort((a, b) => a[field] - b[field]);
    }
    return array;
  }

  sortByDate(sortType: SortType, sortTypeValue: string, array: Array<any>, field: string): Array<any> {
    if (sortType.name === sortTypeValue && sortType.direction === 'ascending') {
      sortType.direction = 'descending';
      array.reverse();
    } else {
      sortType.name = sortTypeValue;
      sortType.direction = 'ascending';
      array = array.sort((a, b) => {
        const ageA = this.toDateNumber(a[field]);
        const ageB = this.toDateNumber(b[field]);
        if (ageA > ageB) {
          return -1;
        } else if (ageA < ageB) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    return array;
  }

  filterByString(array: Array<any>, arrayFiltered: Array<any>, field: string, filterValue: string): Array<any> {
    const sarg = filterValue.trim().toLocaleLowerCase();
    if (sarg !== '') {
      arrayFiltered = [];
      arrayFiltered = array.filter(x => {
        return x[field].toLowerCase().includes(sarg);
      });
    } else {
      arrayFiltered = array.filter(x => x);
    }
    return arrayFiltered;
  }

  private toDateNumber(dateStr) {
    return moment().diff(dateStr, 'days');
  }

}
