import { Component, Input, OnInit } from '@angular/core';
import { User } from 'app/models/user/user.model';
import { UserAPIService } from 'app/shared/api/user.api.service';
import { Globals } from 'app/shared/globals/globals';
import { Router } from '@angular/router';
import { UserCompleteSignUp } from 'app/models/user/user-complete-sign-up.dto';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-employee-sign-up-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.css'],
})
export class CompleteComponent implements OnInit {
  @Input() user !: User;
  loading = true;
  error = false;

  constructor(
    private userAPIService: UserAPIService,
    private globals: Globals,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.update();
  }

  update() {
    this.loading = true;

    const userCompleteSignUp = new UserCompleteSignUp(this.user);

    this.userAPIService.updateUserCompleteSignUp(userCompleteSignUp).subscribe(response => {
      this.userAPIService.getUserInit().subscribe(init => {
        this.router.navigateByUrl('/dashboard');
        this.error = false;
        this.loading = false;
      }, (failure: HttpErrorResponse) => {
        this.error = true;
        this.loading = false;
      });
    },
      (failure: HttpErrorResponse) => {
        this.error = true;
        this.loading = false;
      });
  }

}

