import { Component, Input, OnInit } from '@angular/core';
import { CompanyFeatures } from '@app/models/company.model';
import { CompanyWording } from '@app/models/company/company-wording/company-wording.model';
import { PreferredContactMethod } from '@app/models/user/preferred-contact-method.model';
import { Globals } from '@app/shared/globals/globals';
import { User } from '@sentry/browser';

@Component({
  selector: 'app-person-display',
  templateUrl: './person-display.component.html',
  styleUrls: ['./person-display.component.css']
})
export class PersonDisplayComponent implements OnInit {
  @Input() user?: User;
  @Input() background?: string; // NOTE: May need to modify this if we add images later
  @Input() imgTitle?: string;

  eCompanyFeatures = CompanyFeatures;
  ePreferredContactMethod = PreferredContactMethod;

  nameWorkingRemotely = 'Working remotely';
  companyWording: CompanyWording;

  constructor(
    public globals: Globals
  ) {
    this.companyWording = this.globals.company.companyWording;
    this.background = undefined;
    this.imgTitle = undefined;
    this.user = undefined;
  }

  ngOnInit() {
  }

  openLinkedIn(event: Event, url: string) {
    window.open(`https://www.${url}`, '_blank');
    event.stopPropagation();
  }

  openTeams(event: Event, emailAddr: string) {
    window.open(`https://teams.microsoft.com/l/chat/0/0?users=${emailAddr}`, '_blank');
    event.stopPropagation();
  }

  openEmail(event: Event, emailAddr: string) {
    window.open(`mailto:${emailAddr}`, '_self');
    event.stopPropagation();
  }

  openPhone(event: Event, phoneNo: string) {
    window.open(`tel:${phoneNo}`, '_self');
    event.stopPropagation();

  }

  openTwitter(event: Event, handle: string) {
    window.open(`https://twitter.com/${handle}`, '_blank');
    event.stopPropagation();
  }
}
