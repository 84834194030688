import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Globals } from '../globals/globals';

@Injectable()
export class AuthenticatedGuard implements CanActivate {

  constructor(
    private globals: Globals,
    private router: Router
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.globals.user === undefined) {
      this.router.navigate(['/login'], { queryParams: { redirect: state.url } });
      return false;
    }
    return true;
  }
}
