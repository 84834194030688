import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Goal, GoalType } from 'app/models/goals/goal.model';
import { GoalsAPIService } from 'app/shared/api/goals.api.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GoalCreateService } from '../goals-create/goals-create.service';
import { forkJoin, Subscription } from 'rxjs';
import { Globals } from 'app/shared/globals/globals';
import { RoleName } from 'app/models/role.model';
import { CompanyFeatures } from 'app/models/company.model';
import { HttpErrorResponse } from '@angular/common/http';
import { OfficeLocationGoalOverview } from 'app/models/goals/office-location-goal-overview.model';
import { DepartmentGoalOverview } from 'app/models/goals/department-goal-overview.model';
import { GoalsModalComponent } from '../goals-modal/goals-modal.component';
import { Breadcrumb } from 'app/models/breadcrumb.model';
import { BreadcrumbService } from 'app/shared/breadcrumbs/breadcrumbs.service';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { GoalOverviewPages } from '@app/models/goals/goal-overview-pages.model';
import { CompanyAPIService } from '@app/shared/api/company.api.service';
import { User } from '@app/models/user/user.model';
import { UserAPIService } from '@app/shared/api/user.api.service';
import { CompanyWording } from '@app/models/company/company-wording/company-wording.model';
import { GoalDraft } from '@app/models/goals/goal-draft.model';

declare var $: any;

enum OverviewFilter {
  ACTIVE = 'ACTIVE',
  COMPLETE = 'COMPLETE',
  ARCHIVED = 'ARCHIVED',
  ALL = 'ALL',
}

interface State {
  loading: boolean,
  error: boolean
}

interface TypeOption {
  key: string;
  name: string;
  description: string;
}

@Component({
  selector: 'app-goals-overview',
  templateUrl: './goals-overview.component.html',
  styleUrls: ['./goals-overview.component.css'],
})
export class GoalsOverviewComponent implements OnInit, OnDestroy {
  @ViewChild('modalCreate') modalCreate!: GoalsModalComponent;
  state: State;

  default = 'Please select one...';
  departmentOverViewList: Array<DepartmentGoalOverview>;
  officeLocationOverViewList: Array<OfficeLocationGoalOverview>;

  eRoleName = RoleName;
  eCompanyFeatures = CompanyFeatures;

  eOverviewType = GoalOverviewPages;
  typeOptions: TypeOption[];
  eOverviewFilter = OverviewFilter;

  create = true;
  createType: GoalType | null = null;
  modalCreateShown: boolean;

  pageSelect: FormControl;
  officeLocationId: number | null;

  goals: Array<Goal>;
  goalsActive: Array<Goal>;
  goalsComplete: Array<Goal>;
  goalsArchived: Array<Goal>;
  goalsSaved: Array<GoalDraft>;

  departmentId: number | null;

  managerFilter: FormControl;
  managerList: User[];

  show: boolean;
  subscriptions: Array<Subscription>;

  action: string;
  breadcrumb: Breadcrumb;

  paginationPage: number;

  goalsViewing: Goal[];
  typeFilter: AbstractControl;
  companyWording: CompanyWording;
  
  constructor(
    public globals: Globals,
    private goalsAPIService: GoalsAPIService,
    public route: ActivatedRoute,
    private router: Router,
    private goalCreateService: GoalCreateService,
    private breadcrumbService: BreadcrumbService,
    private companyAPIService: CompanyAPIService,
    private userAPIService: UserAPIService
  ) {
    this.breadcrumb = this.breadcrumbService.init(this.route);
    this.companyWording = this.globals.company.companyWording;
    this.typeOptions = [];

    this.state = {
      loading: true,
      error: false
    };

    this.subscriptions = [];
    this.goals = [];
    this.goalsActive = [];
    this.goalsComplete = [];
    this.goalsArchived = [];
    this.goalsSaved = [];
    this.goalsViewing = [];
    this.managerList = [];
    this.departmentOverViewList = [];
    this.officeLocationOverViewList = [];

    this.officeLocationId = null;

    this.show = false;
    this.modalCreateShown = true;

    this.action = String(this.route.snapshot.queryParams['action']);

    this.typeFilter = new FormControl(OverviewFilter.ACTIVE, []);
    this.pageSelect = new FormControl(GoalOverviewPages.MY_GOALS, [Validators.required]);
    this.typeFilter = new FormControl(OverviewFilter.ALL, [Validators.required]);
    this.managerFilter = new FormControl(this.globals.user.managerId, [Validators.required]);

    this.departmentId = null;

    this.typeFilter.valueChanges.subscribe((v) => {
      switch (v) {
        case OverviewFilter.ACTIVE:
          this.goalsViewing = this.goalsActive;
          break;
        case OverviewFilter.ARCHIVED:
          this.goalsViewing = this.goalsArchived;
          break;
        case OverviewFilter.COMPLETE:
          this.goalsViewing = this.goalsComplete;
          break;
        default:
          this.goalsViewing = [...this.goalsComplete, ...this.goalsActive, ...this.goalsArchived]
          break;
      }
    });

    this.managerFilter.valueChanges.subscribe((v) => {
      if (v.id) {
        this.initTeam(v.id);
      }
    })

    this.pageSelect.valueChanges.subscribe((v) => {
      this.paginationPage = 1;
      this.init();
    })

    this.subscriptions.push(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if (this.route.children.length === 0) {
            this.action = String(this.route.snapshot.queryParams['action']);
            if (this.action === 'create') {
              if (this.modalCreate) {
                this.modalCreate.show();
                this.router.navigate([], {
                  queryParams: {
                    action: null
                  },
                  queryParamsHandling: 'merge'
                })
              }
            }
            this.init();
          }
        }
      })
    );

    this.paginationPage = 1;
  }

  ngOnInit() {
    this.typeOptions = this.getTypeOptions();

    switch (this.action) {
      case 'create':
        if (this.modalCreate) {
          this.modalCreate.show();
        }
        break;
      case 'active':
        this.typeFilter.setValue(OverviewFilter.ACTIVE);
        break;
      case 'archived':
        this.typeFilter.setValue(OverviewFilter.ARCHIVED);
        break;
      case 'complete':
        this.typeFilter.setValue(OverviewFilter.COMPLETE);
        break;
    }

    this.subscriptions.push(
      this.goalCreateService.getGoalCreated().subscribe(goal => {
        let url = '/goals?create=true&type=';
        switch (goal.type) {
          case GoalType.PERSONAL_OPERATIONAL:
            url += 'personal';
            break;
          case GoalType.DEPARTMENT:
            url += 'department';
            if (goal.department !== null) {
              url += '&departmentId=' + goal.department.id;
            }
            break;
          case GoalType.COMPANY:
            url += 'company';
            break;
          default:
            url = '/goals?create=true';
            break;
        }
        this.init();
      }),
    );
    this.subscriptions.push(
      this.goalCreateService.getGoalSaved().subscribe(goal => {
        this.pageSelect.setValue(GoalOverviewPages.DRAFTS)
        // this.init();
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.breadcrumbService.remove(this.breadcrumb);
  }

  getTypeOptions(): TypeOption[] {
    const output: TypeOption[] = [];

    for (const key in GoalOverviewPages) {
      if (GoalOverviewPages.hasOwnProperty(key)) {
        if (GoalOverviewPages[key] === GoalOverviewPages.OFFICE_LOCATION) {
          if (this.globals.hasFeature(CompanyFeatures.GOAL_OFFICE_LOCATION)) {
            output.push({
              key: GoalOverviewPages[key],
              name: GoalOverviewPages[key],
              description: this.getDescriptionForTab(key)
            });
          }
        } else if (GoalOverviewPages[key] === GoalOverviewPages.ADMIN_GOAL) {
          if (this.globals.hasRole(RoleName.GOAL_COMPANY)) {
            output.push({
              key: GoalOverviewPages[key],
              name: GoalOverviewPages[key],
              description: this.getDescriptionForTab(key)
            });
          }
        } else if(GoalOverviewPages[key] === GoalOverviewPages.DEPARTMENT) {
          output.push({
            key: GoalOverviewPages[key],
            name: this.companyWording.department + ' Goals',
            description: this.getDescriptionForTab(key)
          });
        } 
        else if(GoalOverviewPages[key] === GoalOverviewPages.TEAM) {
          output.push({
            key: GoalOverviewPages[key],
            name: this.companyWording.team + ' Goals',
            description: this.getDescriptionForTab(key)
          });
        } 
        else {

          output.push({
            key: GoalOverviewPages[key],
            name: GoalOverviewPages[key],
            description: this.getDescriptionForTab(key)
          });
        }
      }
    }

    return output;
  }

  getDescriptionForTab(key: string) {
    switch (GoalOverviewPages[key]) {
      case GoalOverviewPages.ADMIN_GOAL:
        return 'View all your companys goals as an admin';
      case GoalOverviewPages.COMPANY:
        return 'View all company level goals';
      case GoalOverviewPages.DEPARTMENT:
        return `View all ${this.companyWording.department.toLowerCase()} level goals`;
      case GoalOverviewPages.DRAFTS:
        return 'View all draft goals you have saved';
      case GoalOverviewPages.MY_GOALS:
        return 'View all goals you are an owner of';
      case GoalOverviewPages.OFFICE_LOCATION:
        return 'View all site level goals';
      case GoalOverviewPages.PERSONAL:
        return 'View all personal operational and developmental goals';
      case GoalOverviewPages.TAGS:
        return 'View all goals grouped by tag';
      case GoalOverviewPages.TEAM:
        return `View all ${this.companyWording.team.toLowerCase()} level goals`;
      default:
        return '';
    }
  }

  paginationChanged(e: number) {
    this.paginationPage = e;
  }

  private init() {
    this.typeFilter.setValue(OverviewFilter.ACTIVE);
    this.managerFilter.setValue(this.globals.user.managerId);
    this.state.loading = true;
    this.state.error = false;

    switch (this.pageSelect.value) {
      case GoalOverviewPages.MY_GOALS:
        this.initMyGoals();
        break;
      case GoalOverviewPages.PERSONAL:
        this.initPersonal();
        break;
      case GoalOverviewPages.TEAM:
        this.userAPIService.getAllUsers().subscribe(users => {
          this.managerList = this.parseManagers(users).concat(this.globals.user).sort((a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`));
          const currentUserManager = users.find(u => u.id === this.globals.user.managerId);
          this.managerFilter.setValue(currentUserManager ? currentUserManager : this.managerList[0]);
        });
        break;
      case GoalOverviewPages.COMPANY:
        this.initCompany();
        break;
      case GoalOverviewPages.DEPARTMENT:
        this.initDepartments();
        break;
      case GoalOverviewPages.DRAFTS:
        this.initDrafts();
        break;
      case GoalOverviewPages.OFFICE_LOCATION:
        this.initOfficeLocations();
        break;
      case GoalOverviewPages.TAGS:
      case GoalOverviewPages.ADMIN_GOAL:
        // case GoalOverviewPages.DEPARTMENT:
        this.state.loading = false;
        break;
      default:
        this.pageSelect.setValue(GoalOverviewPages.MY_GOALS);
        this.initMyGoals();
        break;
    }
  }

  populateAllGoals(active: Array<Goal>, complete: Array<Goal>, archived: Array<Goal>) {
    this.goalsActive = Goal.getGoalArrayCompletionPercentage(active);
    this.goalsComplete = Goal.getGoalArrayCompletionPercentage(complete);
    this.goalsArchived = Goal.getGoalArrayCompletionPercentage(archived);

    this.goals = [...this.goalsActive, ...this.goalsComplete, ...this.goalsArchived];

    this.typeFilter.setValue(this.typeFilter.value);
  }

  private initPersonal() {
    forkJoin([
      this.goalsAPIService.getPersonalGoalsActive(),
      this.goalsAPIService.getPersonalGoalsComplete(),
      this.goalsAPIService.getPersonalGoalsArchived(),
    ]).subscribe(([active, complete, archived]) => {
      // active = Goal.getGoalArrayCompletionPercentage(active);
      // complete = Goal.getGoalArrayCompletionPercentage(complete);
      // archived = Goal.getGoalArrayCompletionPercentage(archived);
      this.populateAllGoals(active, complete, archived);
    }, (failure: HttpErrorResponse) => {
      this.state.error = true;
    }, () => {
      this.state.loading = false;
    });
  }

  private initTeam(managerId: number) {
    forkJoin([
      this.goalsAPIService.getTeamGoalsActiveByManagerId(managerId),
      this.goalsAPIService.getTeamGoalsCompleteByManagerId(managerId),
      this.goalsAPIService.getTeamGoalsArchivedByManagerId(managerId)
      // this.goalsAPIService.getTeamGoalsActive(),
      // this.goalsAPIService.getTeamGoalsComplete(),
      // this.goalsAPIService.getTeamGoalsArchived()
    ]).subscribe(([active, complete, archived]) => {
      this.populateAllGoals(active, complete, archived);
    }, (failure: HttpErrorResponse) => {
      this.state.error = true;
    }, () => {
      this.state.loading = false;
    });
  }

  parseManagers(users: User[]): User[] {
    const managerIds = users.map(u => u.managerId);
    const managerIdsUnique = Array.from(new Set(managerIds));
    const managers = users.filter(u => managerIdsUnique.includes(u.id));
    return managers;
  }

  private initOfficeLocations() {
    this.goalsAPIService.getOfficeLocationGoalOverview().subscribe(officeLocationOverviewList => {
      this.officeLocationOverViewList = officeLocationOverviewList.sort((a, b) => a.officeLocation.name.localeCompare(b.officeLocation.name));

      this.officeLocationId = this.getOfficeLocationId();

      this.officeLocationOverViewList.forEach(o => {
        o.goals = Goal.getGoalArrayCompletionPercentage(o.goals);
      })

      this.changeOfficeLocation();
    }, (failure: HttpErrorResponse) => {
      this.state.error = true;
    }, () => {
      this.state.loading = false;
    });
  }

  private getOfficeLocationId(): number | null {
    if (this.globals.user.officeLocation !== null) {
      return this.globals.user.officeLocation.id;
    }
    const firstOfficeOverview = this.officeLocationOverViewList.find(o => o !== undefined);
    if (firstOfficeOverview) {
      return firstOfficeOverview.officeLocation.id;
    }
    return null;
  }

  private initCompany() {
    forkJoin([
      this.goalsAPIService.getCompanyGoalsActive(),
      this.goalsAPIService.getCompanyGoalsComplete(),
      this.goalsAPIService.getCompanyGoalsArchived(),
    ]).subscribe(([active, complete, archived]) => {
      // active = Goal.getGoalArrayCompletionPercentage(active);
      // complete = Goal.getGoalArrayCompletionPercentage(complete);
      // archived = Goal.getGoalArrayCompletionPercentage(archived);
      this.populateAllGoals(active, complete, archived);
      this.state.loading = false;
    }, (failure: HttpErrorResponse) => {
      this.state.error = true;
      this.state.loading = false;
    });
  }

  private initDrafts() {
    this.goalsAPIService.getDraftGoals().subscribe(response => {
      this.goalsSaved = response;
      this.state.loading = false;
    }, (failure: HttpErrorResponse) => {
      this.state.error = true;
      this.state.loading = false;
    });
  }

  private initMyGoals() {
    forkJoin([
      this.goalsAPIService.getIndividualGoals()
    ]).subscribe(([goals]) => {
      this.populateAllGoals(
        goals.filter(goal => goal.complete === false && goal.archived === false),
        goals.filter(goal => goal.complete === true && goal.archived === false),
        goals.filter(goal => goal.archived === true)
      );
      this.state.loading = false;
    }, (failure: HttpErrorResponse) => {
      this.state.error = true;
      this.state.loading = false;
    });
  }

  private initDepartments() {
    forkJoin([
      this.goalsAPIService.getDepartmentGoalOverviewOld(),
      this.companyAPIService.getOrgByType('Department')
    ])
      .subscribe(([departmentOverviewList, departments]) => {
        // Split archived and not archived
        const nonarchived = departmentOverviewList.filter(o => o.archived === false);
        const archived = departmentOverviewList.filter(o => o.archived === true).map(o => {
          o.department.name = `[archived] ${o.department.name}`;
          return o;
        });

        // [archived]

        this.departmentOverViewList.length = 0;

        this.departmentOverViewList.push(...nonarchived.sort((a, b) => {
          return a.department.name.localeCompare(b.department.name);
        }));
        this.departmentOverViewList.push(...archived.sort((a, b) => {
          return a.department.name.localeCompare(b.department.name);
        }));

        const departmentAll: DepartmentGoalOverview = {
          department: {
            id: -1,
            name: 'All',
            level: 0,
            parentOrg: null,
            organisationalUnitType: {
              id: 2,
              name: 'Department'
            },
            managerUserId: -1,
            feedbackUserId: -1
          },
          goals: [],
          archived: false
        }

        this.departmentOverViewList.forEach(d => {
          d.goals = Goal.getGoalArrayCompletionPercentage(d.goals);
          departmentAll.goals = [...departmentAll.goals!, ...d.goals]
        });
        this.departmentOverViewList.unshift(departmentAll);

        // Default to current users department (unless the department is archived)
        const firstDepartmentOverview = this.departmentOverViewList.find(d => d !== undefined);
        if (firstDepartmentOverview) {
          this.departmentId = firstDepartmentOverview.department.id;
        } else {
          this.departmentId = -1;
        }
        if (this.globals.user.organisationalUnit !== null) {
          if (departments.some(d => d.id === this.globals.user.organisationalUnit!.id)) {
            this.departmentId = this.globals.user.organisationalUnit.id;
          }
        }

        // Update department
        this.changeDepartment();
        // this.getAllStats();
        this.state.loading = false;

      }, (failure: HttpErrorResponse) => {
        this.state.error = true;
        this.state.loading = false;
      });
  }

  changeOfficeLocation() {
    const selectedOfficeLocation = this.officeLocationOverViewList.find(o => o.officeLocation.id === this.officeLocationId);

    if (selectedOfficeLocation) {
      this.populateAllGoals(
          selectedOfficeLocation!.goals.filter(g => g.archived === false && g.complete === false),
          selectedOfficeLocation!.goals.filter(g => g.archived === false && g.complete === true),
          selectedOfficeLocation!.goals.filter(g => g.archived === true),
      );
    }
  }

  setType(val: GoalOverviewPages) {
    this.pageSelect.setValue(val);
  }

  changeDepartment() {
    const selectedDepartment = this.departmentOverViewList.find(o => o.department.id === this.departmentId);

    if (selectedDepartment) {
      this.populateAllGoals(
          selectedDepartment!.goals.filter(g => g.archived === false && g.complete === false),
          selectedDepartment!.goals.filter(g => g.archived === false && g.complete === true),
          selectedDepartment!.goals.filter(g => g.archived === true),
      );
    }
  }

  openGoalCreate(type: (GoalOverviewPages | undefined) = undefined) {
    this.modalCreateShown = false;

    setTimeout(() => {
      switch (type) {
        case GoalOverviewPages.COMPANY:
          this.createType = GoalType.COMPANY;
          break;
        case GoalOverviewPages.DEPARTMENT:
          this.createType = GoalType.DEPARTMENT;
          break;
        case GoalOverviewPages.OFFICE_LOCATION:
          this.createType = GoalType.OFFICE_LOCATION;
          break;
        // case GoalOverviewPages.PERSONAL:
        //   this.createType = GoalType.PERSONAL_DEVELOPMENTAL;
        default:
          this.createType = null;
          break;
      }

      this.modalCreateShown = true;

      setTimeout(() => {
        this.modalCreate.show();
      }, 100);
    }, 100);
  }

  adminGoalsEmit(goals: any[]) {
    this.populateAllGoals(
      goals.filter(g => g.archived === false && g.complete === false),
      goals.filter(g => g.archived === false && g.complete === true),
      goals.filter(g => g.archived === true)
    )
  }
}
