import { Injectable } from '@angular/core';
import { Globals } from '@app/shared/globals/globals';
import { UserAPIService } from '@app/shared/api/user.api.service';
import { EmailAPIService } from '@app/shared/api/email.api.service';
import { environment } from '../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { getParameterByName } from '@app/shared/utils/helpers';

@Injectable()
export class StartUpService {
  constructor(
    private globals: Globals,
    private userAPIService: UserAPIService,
    private emailAPIService: EmailAPIService
  ) { }

  /**
   * Populate globals before app init (if user session exists)
   */
  load(): Promise<void> {
    return new Promise((resolve: any) => {

      // JWT Access token
      const token = localStorage.getItem('access_token');

      // Check for referrer token and remove it from url and history
      // We want to remove it from history so user doesn't bookmark the url etc and inflate click numbers
      const referrerType = getParameterByName('referrer-type');
      const referrerToken = getParameterByName('referrer-token');
      if (referrerType !== null && referrerToken !== null) {
        let url = window.location.href;
        url = url.replace('referrer-type=' + referrerType, '');
        url = url.replace('referrer-token=' + referrerToken, '');
        window.history.replaceState({}, document.title, url);
      }

      if (token || environment.mock.enabled) {
        this.userAPIService.getUserInit().subscribe(() => {
          if (referrerType !== null && referrerToken !== null) {
            if (referrerType === 'email') {
              // If failure, fail silently
              this.emailAPIService.createClick(referrerToken!).subscribe(() => { }, (failure: HttpErrorResponse) => { });
            } else {
              // Add more referrer types later
            }
          }
          resolve(true);
        }, (failure: HttpErrorResponse) => {
          resolve(true);
        });
      } else {
        if (referrerType !== null && referrerToken !== null) {
          if (referrerType === 'email') {
            // If failure, fail silently
            this.emailAPIService.createAnonymousClick(referrerToken!).subscribe(() => { }, (failure: HttpErrorResponse) => { });;
          } else {
            // Add more referrer types later
          }
        }
        resolve(true);
      }
    })
  }
}

export function startUpServiceFactory(startUpService: StartUpService): () => void {
  return () => startUpService.load();
}
