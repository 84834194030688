import { Component, Input, OnInit } from '@angular/core';
import { PraiseItem } from '@app/models/feedback/praise.model';
import { User } from '@app/models/user/user.model';
import { UserAPIService } from '@app/shared/api/user.api.service';

@Component({
  selector: 'app-feedback-praise-display',
  templateUrl: './feedback-praise-display.component.html',
  styleUrls: ['./feedback-praise-display.component.css']
})
export class FeedbackPraiseDisplayComponent implements OnInit {
  @Input() praise: PraiseItem;

  @Input() showSourceUser: boolean;
  @Input() showDestinationUser: boolean;

  sourceUser?: User;
  destinationUser?: User;

  constructor(
    private userAPIService: UserAPIService
  ) {
    this.praise = undefined!;
    this.destinationUser = undefined;

    this.showSourceUser = true;
    this.showDestinationUser = true;
  }

  ngOnInit() {
    if (this.showSourceUser) {
      this.getSourceUser();
    }
    if (this.showDestinationUser) {
      this.getDestinationUser();
    }
  }

  getSourceUser() {
    if (this.praise.sourceUserId) {
      this.userAPIService.getById(this.praise.sourceUserId).subscribe(
        (user) => {
          this.sourceUser = user;
        }
      );
    }
  }

  getDestinationUser() {
    if (this.praise.praiseUserId) {
      this.userAPIService.getById(this.praise.praiseUserId).subscribe(
        (user) => {
          this.destinationUser = user;
        }
      );
    }
  }
}
