import { GoalTemplate } from '@app/models/goals/goal-template.model';
import { GoalKeyResultType } from '@app/models/goals/goal-key-result-type.model';

export const mockGoalTemplates: GoalTemplate[] = [
  {
    id: 1,
    companyId: 1,
    title: 'Increase financial forecast in UK',
    results: [
      {
        id: 1,
        title: 'Better reporting',
        type: GoalKeyResultType.BINARY,
        unit: null,
        unitPlacement: null
      },
      {
        id: 2,
        title: 'Hire new CFO',
        type: GoalKeyResultType.BINARY,
        unit: null,
        unitPlacement: null
      }
    ]
  },
  {
    id: 2,
    companyId: 1,
    title: 'Hire a new marketing team',
    results: [
      {
        id: 3,
        title: 'Finalise job description',
        type: GoalKeyResultType.BINARY,
        unit: null,
        unitPlacement: null
      },
    ]
  },
  {
    id: 3,
    companyId: 1,
    title: 'Limit down time to 30 minutes a month',
    results: [
      {
        id: 4,
        title: 'Run diagnostic tests',
        type: GoalKeyResultType.BINARY,
        unit: null,
        unitPlacement: null
      }
    ]
  }
];