<img 
  [src]="src | secure | async"
  [ngClass]="class"
  [alt]="alt"
  [title]="title"
  [style.border-color]="borderColor"
  [style.width]="width"
  [style.height]="width"
  [routerLink]="routerLink? routerLink: null"
  [class.default-user-img]="showBackgroundImage"
  *ngIf="src.indexOf('/api/files/get/') >= 0"
/>
<img
  src="{{ src }}"
  [ngClass]="class"
  [alt]="alt"
  [title]="title"
  [style.border-color]="borderColor"
  [style.width]="width"
  [style.height]="width"
  [routerLink]="routerLink? routerLink: null"
  [class.default-user-img]="showBackgroundImage"
  *ngIf="!(src.indexOf('/api/files/get/') >= 0)"
/>
