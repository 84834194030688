import { PeerFeedback, PeerFeedbackType } from '@app/models/feedback/peer-feedback.model';
import * as moment from 'moment';
import { mockUsers } from '@app/mock/api/data/mockUsers';

export const mockPeerFeedback: PeerFeedback[] = [
  {
    id: 1,
    sender: mockUsers[0],
    recipient: mockUsers[1],
    observation: 'You handled an important customer very well yesterday',
    reason: 'Heard some good feedback about you from the customer',
    suggestion: 'Keep it up!',
    type: PeerFeedbackType.ENCOURAGING,
    timestamp: moment().subtract(1, 'week').toDate(),
    viewable: true
  },
  {
    id: 2,
    sender: mockUsers[0],
    recipient: mockUsers[2],
    observation: 'Recent project pitch improvements',
    reason: 'You did a good job on the pitch but I felt that you rushed the prep and the message could have been tighter',
    suggestion: 'Run your pitch by me in future prior to the conference.',
    type: PeerFeedbackType.HELPFUL,
    timestamp: moment().subtract(1, 'week').toDate(),
    viewable: true
  },
  {
    id: 3,
    sender: mockUsers[1],
    recipient: mockUsers[0],
    observation: 'Presentation went well but you got the numbers wrong',
    reason: 'It\'s important our numbers stack up for leadership',
    suggestion: 'Go through the deck & share for review in advance.',
    type: PeerFeedbackType.HELPFUL,
    timestamp: moment().subtract(1, 'week').toDate(),
    viewable: true
  },
  {
    id: 4,
    sender: mockUsers[2],
    recipient: mockUsers[0],
    observation: 'Quality of meetings have improved recently',
    reason: 'Ever since you started chairing these meetings, they\'ve been progressing very smoothly',
    suggestion: 'Keep chairing those meetings!',
    type: PeerFeedbackType.ENCOURAGING,
    timestamp: moment().subtract(1, 'week').toDate(),
    viewable: true
  }
];