import { SelfReflection } from '@app/models/evaluation/self-reflection.model';
import { mockEvaluationCycles } from '@app/mock/api/data/mockEvaluationCycles';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { mapSelfReflectionAnswers } from '@app/mock/api/mock-helpers';

export const mockSelfReflections: SelfReflection[] = [
    {
        id: 1,
        evaluationCycleId: mockEvaluationCycles[0].id,
        sourceUserId: mockUsers[1].id,
        lastEditTimestamp: new Date(),
        completed: true,
        selfQuestionsAnswered: mapSelfReflectionAnswers(mockEvaluationCycles[0].selfReflectionQuestions)
    },
    {
        id: 2,
        evaluationCycleId: mockEvaluationCycles[0].id,
        sourceUserId: mockUsers[2].id,
        lastEditTimestamp: new Date(),
        completed: true,
        selfQuestionsAnswered: mapSelfReflectionAnswers(mockEvaluationCycles[0].selfReflectionQuestions)
    },
    {
        id: 3,
        evaluationCycleId: mockEvaluationCycles[0].id,
        sourceUserId: mockUsers[3].id,
        lastEditTimestamp: new Date(),
        completed: true,
        selfQuestionsAnswered: mapSelfReflectionAnswers(mockEvaluationCycles[0].selfReflectionQuestions)
    },
    {
        id: 4,
        evaluationCycleId: mockEvaluationCycles[1].id,
        sourceUserId: mockUsers[0].id,
        lastEditTimestamp: new Date(),
        completed: true,
        selfQuestionsAnswered: mapSelfReflectionAnswers(mockEvaluationCycles[0].selfReflectionQuestions)
    },
];