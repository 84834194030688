import { Company, CompanyState } from '@app/models/company.model';
import { mockAddresses } from '@app/mock/api/data/mockAddresses';
import { mockOfficeLocations } from '@app/mock/api/data/mockOfficeLocations';
import { mockCompanyFeatures } from '@app/mock/api/data/mockCompanyFeatures';
import { mockCompanyWording } from '@app/mock/api/data/mockCompanyWording';

export const mockCompanies: Company[] = [
    {
        id: 1,
        name: 'Frankli Demo',
        loginEnabled: true,
        featureLocked: true,
        address: mockAddresses[0],
        features: mockCompanyFeatures,
        state: CompanyState.FULL,
        fiscalYear: new Date(2000, 1, 1),
        officeLocations: mockOfficeLocations,
        setUpComplete: true,
        setUpGroups: [],
        version: 0,
        trial: null,
        companyWording: mockCompanyWording,
        configuration: {
            showSandboxBanner: false
        }
    }
];