import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { User } from 'app/models/user/user.model';
import { Globals } from 'app/shared/globals/globals';
import { CompanyFeatures } from 'app/models/company.model';
import { UserAPIService } from 'app/shared/api/user.api.service';
import { Interest } from 'app/models/interest.model';
import { NavigationEnd, Router } from '@angular/router';
import { PreferredContactMethod } from '@app/models/user/preferred-contact-method.model';

enum PageTabs {
  NONE = 'NONE',
  CONTACT = 'CONTACT',
  PERSONAL = 'PERSONAL',
  PROFESSIONAL = 'PROFESSIONAL'
}

interface SimilarInterests {
  interest: Interest;
  users: Array<User>
}

@Component({
  selector: 'app-profile-contact-interests',
  templateUrl: './profile-contact-interests.component.html',
  styleUrls: ['./profile-contact-interests.component.css'],
})
export class ProfileContactInterestsComponent implements OnInit, OnDestroy {
  @Input() userProfile !: User;

  loading: boolean;
  personalInterests: Array<SimilarInterests>;
  professionalInterests: Array<SimilarInterests>;
  lastTab: string;
  tabOpen: string;
  ePageTabs = PageTabs;

  user: User;

  subscriptions = [];

  eFeature = CompanyFeatures;
  ePreferredContactMethod = PreferredContactMethod

  displayCount: number;

  constructor(
    public globals: Globals,
    private router: Router,
    private userAPIService: UserAPIService) {
    this.user = this.globals.user;
    this.tabOpen = this.ePageTabs.CONTACT;
    this.lastTab = this.ePageTabs.NONE;
    this.personalInterests = [];
    this.professionalInterests = [];
    this.loading = true;
    this.displayCount = 5;
    this.subscriptions.push(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.ngOnInit();
        }
      }),
    );
  }

  ngOnInit() {
    this.loading = true;
    this.personalInterests = [];
    this.professionalInterests = [];
    this.userAPIService.getAllUsers().subscribe(users => {
      users = users.filter(u => u.id !== this.userProfile.id);
      this.userProfile.personalInterests.forEach(pi => {
        const interest = {
          interest: pi,
          users: new Array<User>(),
        };
        users.forEach((u: any) => {
          if (u.interests!.map((ui: Interest) => ui.id).includes(pi.id)) {
            interest.users.push(u);
          }
        });
        this.personalInterests.push(interest);
      });
      this.userProfile.professionalInterests.forEach(pi => {
        const interest = {
          interest: pi,
          users: new Array<User>(),
        };
        users.forEach((u: any) => {
          if (u.interests!.map((ui: Interest) => ui.id).includes(pi.id)) {
            interest.users.push(u);
          }
        });
        this.professionalInterests.push(interest);
      });
      this.loading = false;
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => {
      if (x) {
        x.unsubscribe();
      }
    })
  }

  switchTab(tab: string) {
    this.tabOpen = tab;
    this.displayCount = 5;
  }

  loadMore() {
    this.displayCount += 5;
  }

  toggleExpand() {
    if (this.tabOpen === this.ePageTabs.NONE) {
      this.tabOpen = this.lastTab;
    } else {
      this.lastTab = this.tabOpen;
      this.tabOpen = this.ePageTabs.NONE;
    }
  }

  navigateToUser(id: number) {
    this.router.navigate(['/profile/', id])
  }
}
