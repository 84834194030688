import { Address } from 'app/models/address.model';
import { CompanyConfiguration } from './company/company-configuration/company-configuration.model';
import { CompanySetUpStepGroup } from './company/company-set-up-step-group.model';
import { CompanyTrial } from './company/company-trial.model';
import { CompanyWording } from './company/company-wording/company-wording.model';

class CompanyFilter {
  /**
   * Filter a company by type
   * @param customers
   * @param type
   */
  byType(companies: Array<Company>, type: 'full-or-trial' | 'full' | 'trial' | 'sandbox' | null): Array<Company> {
    if (type === null) {
      return companies;
    }

    switch (type) {
      case 'full-or-trial': {
        return companies
          .filter(company => company.state === CompanyState.FULL);
      }
      case 'full': {
        return companies
          .filter(company => company.state === CompanyState.FULL)
          .filter(company => company.trial === null);
      }

      case 'trial': {
        return companies
          .filter(company => company.state === CompanyState.FULL)
          .filter(company => company.trial !== null);
      }

      case 'sandbox': {
        return companies
          .filter(company => company.state === CompanyState.DEMO);
      }
    }
  }
}

export class Company {
  static Filter: CompanyFilter = new CompanyFilter();

  id: number;
  version: number;
  address: Address;
  name: string;
  officeLocations: Array<OfficeLocation>;
  features: Array<CompanyFeature>;
  state: CompanyState;
  trial: CompanyTrial | null;
  fiscalYear: Date;
  setUpGroups: Array<CompanySetUpStepGroup>;
  setUpComplete: boolean;
  featureLocked: boolean;
  loginEnabled: boolean;
  companyWording: CompanyWording;
  configuration: CompanyConfiguration;
}

export class OfficeLocation {
  id: number | null;
  name: string;
  officeAddress: Address | null;

  constructor(id: number | null, name: string, address: Address) {
    this.id = id;
    this.name = name;
    this.officeAddress = address;
  }
}

export class AllCompaniesResponse {
  content: Array<Company>;
  last: boolean;
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
  sort: null;
  numberOfElements: number;
  first: true;
}

export class CompanyFeature {
  id: number;
  feature: CompanyFeatures;
  enabled: boolean;
}

export enum CompanyFeatures {
  ANALYTICS = 'ANALYTICS',
  CONNECT = 'CONNECT',
  CONNECT_COACH_MENTOR = 'CONNECT_COACH_MENTOR',
  CONNECT_SOCIALISE = 'CONNECT_SOCIALISE',

  FEEDBACK = 'FEEDBACK',
  FEEDBACK_UNPROMPTED = 'FEEDBACK_UNPROMPTED',
  FEEDBACK_PRAISE = 'FEEDBACK_PRAISE',
  FEEDBACK_IDEAS = 'FEEDBACK_IDEAS',
  FEEDBACK_PEER = 'FEEDBACK_PEER',
  FEEDBACK_REQUESTS = 'FEEDBACK_REQUESTS',

  GOALS = 'GOALS',

  HELP = 'HELP',

  PEOPLE_DIRECTORY = 'PEOPLE_DIRECTORY',
  SURVEYS = 'SURVEYS',

  ORG_CHART = 'ORG_CHART',
  ORG_CHART_GOALS_INFO = 'ORG_CHART_GOALS_INFO',

  ONE_TO_ONE = 'ONE_TO_ONE',

  GOAL_PRIORITY = 'GOAL_PRIORITY',
  GOAL_TEMPLATE = 'GOAL_TEMPLATE',
  GOAL_OFFICE_LOCATION = 'GOAL_OFFICE_LOCATION',

  EVALUATION_CYCLES = 'EVALUATION_CYCLES',
  PEER_NOMINATIONS = 'PEER_NOMINATIONS',

  COMPETENCIES = 'COMPETENCIES',
  GRADES = 'GRADES',
  TODOS = 'TODOS',

  SSO = 'SSO',
  SSO_MICROSOFT = 'SSO_MICROSOFT',
  SSO_GOOGLE = 'SSO_GOOGLE',

  ENPS = 'ENPS',
  FEEDBACK_BANK = 'FEEDBACK_BANK',
  FEEDBACK_EXTERNAL_REQUESTS = 'FEEDBACK_EXTERNAL_REQUESTS',
  PEER_FEEDBACK_MANAGER_ACCESS = 'PEER_FEEDBACK_MANAGER_ACCESS',


}

export enum CompanyState {
  FULL = 'FULL',
  NEW = 'NEW',
  ARCHIVED = 'ARCHIVED',
  DEMO = 'DEMO',
  ARCHIVED_DEMO = 'ARCHIVED_DEMO'
}

export class UpdateCompanyFiscalYearDto {
  constructor(
    private fiscalYear: Date
  ) { }
}
