import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Globals } from '../globals/globals';
import { Router } from '@angular/router';
import { RoleName } from 'app/models/role.model';
import { CompanyFeatures } from 'app/models/company.model';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { Subject } from 'rxjs';
import { NotificationService } from '../globals/notification.service';
import { takeUntil } from 'rxjs/operators';
import { clone } from '@app/shared/utils/helpers';
import { FrankliNotification } from '@app/models/notifications/frankli-notification.model';
import { CompanyWording } from '@app/models/company/company-wording/company-wording.model';
import { TitleCasePipe } from '@angular/common';

declare var $: any;

interface RouteInfo {
  path: string;
  title: string;
  iconType: string;
  feature: CompanyFeatures | null;
  children: Array<RouteInfo> | null;
  locked: boolean;
}

@Component({
  selector: 'app-sidebar-cmp',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class EmployeeSidebarComponent implements OnInit, AfterViewInit, OnDestroy {
  employeeRoutes: Array<RouteInfo> = [
    {
      path: '/dashboard',
      title: 'Dashboard',
      iconType: 'fal fa-fw fa-chart-pie',
      feature: null,
      children: null,
      locked: false
    },
    {
      path: '',
      title: 'People',
      iconType: 'fal fa-fw fa-user-friends',
      feature: null,
      locked: false,
      children: [
        {
          path: 'people-directory',
          title: 'People Directory',
          iconType: 'fal fa-fw fa-id-card',
          feature: CompanyFeatures.PEOPLE_DIRECTORY,
          children: null,
          locked: false
        }, {
          path: 'org-chart',
          title: 'Org Chart',
          iconType: 'fal fa-fw fa-network-wired',
          feature: CompanyFeatures.ORG_CHART,
          children: null,
          locked: false
        },
      ],
    },
    {
      path: '/feedback/',
      title: 'Feedback',
      iconType: 'fal fa-fw fa-bullhorn',
      feature: CompanyFeatures.FEEDBACK,
      children: null,
      locked: false
    }, {
      path: '/goals/',
      title: 'Goals',
      iconType: 'fal fa-fw fa-bullseye',
      feature: CompanyFeatures.GOALS,
      children: null,
      locked: false
    }, {
      path: '/surveys',
      title: 'Surveys & Polls',
      iconType: 'fal fa-fw fa-poll',
      feature: CompanyFeatures.SURVEYS,
      children: null,
      locked: false
    }, {
      path: '/one-to-one',
      title: '1:1 Meetings',
      iconType: 'fal fa-fw fa-calendar-day',
      feature: CompanyFeatures.ONE_TO_ONE,
      children: null,
      locked: false
    },
    {
      path: '/connect/',
      title: 'Connect',
      iconType: 'fal fa-fw fa-infinity',
      feature: CompanyFeatures.CONNECT,
      children: null,
      locked: false
    },
    {
      path: '/evaluations/',
      title: 'My Reviews',
      iconType: 'fal fa-fw fa-clipboard-user',
      feature: CompanyFeatures.EVALUATION_CYCLES,
      children: null,
      locked: false
    },
  ];
  managerRoutes: Array<RouteInfo>;
  adminRoutes: Array<RouteInfo> = [
    {
      path: '/admin/dashboard',
      title: 'Admin',
      iconType: 'fal fa-fw fa-sliders-v',
      feature: null,
      children: null,
      locked: false
    },
  ];
  frankliAdminRoutes: Array<RouteInfo> = [
    {
      path: '/frankli-admin',
      title: 'Frankli Admin',
      iconType: 'fal fa-fw fa-cog',
      feature: null,
      children: null,
      locked: false
    },
  ];
  generalRoutes: Array<RouteInfo> = [
    {
      path: 'https://help.frankli.io',
      title: 'Help',
      iconType: 'fal fa-fw fa-question-circle',
      feature: CompanyFeatures.HELP,
      children: null,
      locked: false
    },
  ];
  employeeMenuItems: Array<RouteInfo> = [];
  managerMenuItems: Array<RouteInfo> = [];
  adminMenuItems: Array<RouteInfo> = [];
  generalMenuItems: Array<RouteInfo> = [];
  frankliAdminMenuItems: Array<RouteInfo> = [];
  eRoleName = RoleName;
  eCompanyFeatures = CompanyFeatures;
  notifications: Array<FrankliNotification>;
  destroy$: Subject<boolean> = new Subject<boolean>();
  searchValue: string;
  companyWording: CompanyWording;

  constructor(
    public globals: Globals,
    private router: Router,
    private sidebarService: SidebarService,
    private notificationService: NotificationService, 
    private titleCasePipe: TitleCasePipe
  ) {
    this.companyWording = this.globals.company.companyWording;
    this.setupManagerRoutes();
    this.searchValue = '';

    this.sidebarService.getUpdate().pipe(takeUntil(this.destroy$)).subscribe(() => this.filter());

    this.notifications = new Array<FrankliNotification>();

    /**
     * Ensure notifications are populated
     */
    if (this.notificationService.isConnected() === true) {
      this.init();
    } else {
      this.notificationService.isConnected$().pipe(takeUntil(this.destroy$)).subscribe(initialised => {
        if (initialised === true) {
          this.init();
        }
      });
    }

    /**
     * This subscribes the navbar component to the receive live notifications via the notification service
     */
    this.notificationService.getNotifications$().pipe(takeUntil(this.destroy$)).subscribe((notification) => {
      if (notification.eventStatus === 'READ') {
        this.notifications = this.notifications.filter(n => n.id !== notification.id);
      } else {
        this.notifications.unshift(notification);
      }
    });
  }

  setupManagerRoutes(){
    this.managerRoutes = [
      {
        path: '',
        title: this.titleCasePipe.transform(this.companyWording.team),
        iconType: 'fal fa-fw fa-users',
        feature: null,
        locked: false,
        children: [
          {
            path: '/manage/inbound-feedback',
            title: `${this.titleCasePipe.transform(this.companyWording.team)} Feedback`,
            iconType: 'fal fa-fw fa-comments-alt',
            feature: CompanyFeatures.FEEDBACK,
            children: null,
            locked: false
          },
          {
            path: '/manage/people',
            title: `${this.titleCasePipe.transform(this.companyWording.team)} View`,
            iconType: 'fal fa-fw fa-eye',
            feature: null,
            locked: false,
            children: null
          },
          {
            path: '/goals/manager',
            title: `${this.titleCasePipe.transform(this.companyWording.team)} Goals`,
            iconType: 'fal fa-fw fa-bullseye',
            feature: CompanyFeatures.GOALS,
            locked: false,
            children: null
          },
          {
            path: '/manage/meetings',
            title: `${this.titleCasePipe.transform(this.companyWording.team)} 1:1s`,
            iconType: 'fal fa-fw fa-calendar',
            feature: CompanyFeatures.ONE_TO_ONE,
            locked: false,
            children: null
          },
          // { path: '/manage/analytics', title: 'Analytics', iconType: 'fal fa-fw fa-chart-bar', feature: CompanyFeatures.ANALYTICS, locked: false, children: null },
          {
            path: '/manage/evaluations',
            title: `${this.titleCasePipe.transform(this.companyWording.team)} Reviews`,
            iconType: 'fal fa-fw fa-circle-notch',
            feature: CompanyFeatures.EVALUATION_CYCLES,
            children: null,
            locked: false,
          },
        ],
      },
    ];
  }
  isNotMobileMenu() {
    return !($(window).width() >= 992);
  }

  ngOnInit() {
    this.filter();
  }

  ngAfterViewInit() {
    const $sidebarParent = $('.sidebar .nav > li.active .collapse li.active > a').parent().parent().parent();

    const collapseId = $sidebarParent.siblings('a').attr('href');

    $(collapseId).collapse('show');
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  navigateToProfile() {
    this.router.navigate(['/profile']).then();
  }

  logout(): void {
    this.router.navigateByUrl('/logout').then();
  }

  checkIfManager(): Boolean {
    if (this.globals.user && this.globals.user.roles) {
      return this.globals.user.roles.some(r =>
        r.name === RoleName.MANAGER ||
        r.name === RoleName.ADMIN ||
        r.name === RoleName.FRANKLI_ADMIN
      );
    } else {
      return false;
    }
  }

  checkIfAdmin(): Boolean {
    if (this.globals.user && this.globals.user.roles) {
      return this.globals.user.roles.some(r => r.name === RoleName.ADMIN || r.name === RoleName.FRANKLI_ADMIN);
    } else {
      return false;
    }
  }

  checkIfFrankliAdmin(): Boolean {
    if (this.globals.user && this.globals.user.roles) {
      return this.globals.user.roles.some(r => r.name === RoleName.FRANKLI_ADMIN);
    } else {
      return false;
    }
  }

  filter() {
    const routeFilter = (route: RouteInfo) => {
      if (!route.children) {
        if (!route.feature) {
          return true;
        } else {
          if (this.globals.hasFeature(route.feature)) {
            return true;
          } else if (!this.globals.hasFeature(route.feature) && this.globals.company && this.globals.company.featureLocked) {
            route.locked = true;
            return true;
          }
        }
      } else {
        // Assign new children as a result of a recursive call to the filter
        return (route.children = route.children.filter(routeFilter)).length > 0;
      }
    }
    // We clone the routes so that they remain immutable
    this.employeeMenuItems = this.employeeRoutes.map(clone).filter(routeFilter);
    this.managerMenuItems = this.managerRoutes.map(clone).filter(routeFilter);
    this.adminMenuItems = this.adminRoutes.map(clone).filter(routeFilter);
    this.frankliAdminMenuItems = this.frankliAdminRoutes.map(clone).filter(routeFilter);
    this.generalMenuItems = this.generalRoutes.map(clone).filter(routeFilter);
  }

  navigateToRoute(route: string) {
    this.router.navigateByUrl(route).then();
  }

  init() {
    const getN = this.notificationService.getNotifications();
    for (let i = 0; i < getN.length; i++) {
      const notification = getN[i];
      if (notification.eventStatus === 'READ') {
        this.notifications = this.notifications.filter(n => n.id !== notification.id);
      } else {
        this.notifications.unshift(notification);
      }
    }
    this.notifications.sort((a, b) => b.id - a.id);
  }

  checkEnter(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.searchValue !== '' && this.searchValue) {
      const sarg = this.searchValue;
      this.searchValue = '';
      this.router.navigate(['/search-results'], { queryParams: { q: sarg } }).then();
    }
  }
}
