import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PaginationService } from './pagination.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pagination-controls',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})

export class PaginationComponent implements OnInit, OnDestroy {
  subscription: Subscription
  currentPage = 1;
  pages = [];

  displayAmountStart = 0;
  displayAmountEnd = 0;
  displayAmountTotal = 0;

  dataArray: Array<any> = [];
  dataArrayDisplay: Array<any> = [];

  @Input() amountToDisplay: any;

  constructor(private paginationService: PaginationService) {
    this.subscription = this.paginationService.getArrayFiltered().subscribe(dataArray => {
      this.dataArray = dataArray;
      this.update();
    });
  }

  ngOnInit() {
    this.paginationService.sendInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  nextPage() {
    if (this.currentPage < this.getLastPage()) {
      this.currentPage++;
      this.updateChangedPage();
    }
  }

  firstPage() {
    if (this.currentPage > 1) {
      this.currentPage = 1;
      this.updateChangedPage();
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updateChangedPage();
    }
  }

  lastPage() {
    if (this.currentPage < this.getLastPage()) {
      this.currentPage = this.getLastPage();
      this.updateChangedPage();
    }
  }

  getLastPage() {
    if (this.amountToDisplay === 'All') {
      return 1;
    }
    return Math.ceil(this.dataArray.length / this.amountToDisplay);
  }

  getLastOnPage(): number {
    const amount = this.getAmountPerPage();
    let end = amount * this.currentPage;
    if (end > this.dataArray.length) {
      end = this.dataArray.length;
    }
    return end;
  }

  getAmountPerPage(): number {
    let amount;
    if (this.amountToDisplay === 'All') {
      amount = this.dataArray.length;
    } else {
      amount = this.amountToDisplay;
    }
    return amount;
  }

  goToPage(page) {
    this.currentPage = page;
    this.updateChangedPage();
  }

  updateTable() {
    this.dataArrayDisplay = [];

    const end = this.getLastOnPage();

    if (this.amountToDisplay === 'All') {
      this.dataArrayDisplay = this.dataArray.filter(item => item);
    } else {
      const start = this.amountToDisplay * (this.currentPage - 1);
      for (let i = start; i < end; i++) {
        this.dataArrayDisplay.push(this.dataArray[i]);
      }
    }
  }

  updatePages() {
    const pages = this.getLastPage();
    this.pages = [];
    for (let i = 0; i < pages; i++) {
      this.pages.push((i + 1));
    }
  }

  updateText() {
    this.resetIfError();
    const end = this.getLastOnPage();
    const amount = this.getAmountPerPage();

    this.displayAmountTotal = this.dataArray.length;
    this.displayAmountStart = (amount * (this.currentPage - 1)) + 1;
    this.displayAmountEnd = end;
  }


  updateTableChangedPage() {
    this.dataArrayDisplay = []
    const end = this.getLastOnPage();
    if (this.amountToDisplay !== 'All') {
      const start = this.amountToDisplay * (this.currentPage - 1);
      for (let i = start; i < end; i++) {
        this.dataArrayDisplay.push(this.dataArray[i]);
      }
    }
  }

  updateChangedPage() {
    this.updateText();
    this.updateTableChangedPage();
    this.updatePages();
    this.paginationService.sendArrayDisplay(this.dataArrayDisplay);
  }

  update() {
    this.updateText();
    this.updateTable();
    this.updatePages();
    this.paginationService.sendArrayDisplay(this.dataArrayDisplay);
  }

  // if on page greater than 1 then amount drops so that only 1 page can be made, this resets it to page 1
  resetIfError() {
    const end = this.getLastOnPage();
    const amount = this.getAmountPerPage();
    const start = (amount * (this.currentPage - 1)) + 1;

    if (start > end) {
      this.currentPage = 1;
    }
  }

}
