export class StateError {
  status: boolean;
  message: string;

  constructor() {
    this.status = false;
    this.message = '';
  }
}

/**
 * State management object, has 3 possible states; loading, success and failure
 * @param loading: boolean - the initial state of the object;
 */
export class State {
  loading: boolean;
  error: StateError;

  constructor(loading: boolean) {
    this.loading = loading;
    this.error = new StateError();
  }

  /**
   * Sets the object to a successful state
   */
  setSuccess() {
    this.loading = false;
    this.error.status = false;
    this.error.message = '';
  }

  /**
   * Sets the object to a failed state
   */
  setFailure(message: string) {
    this.loading = false;
    this.error.status = true;
    this.error.message = message;
  }

  setLoading() {
    this.loading = true;
    this.error.status = false;
    this.error.message = '';
  }

  /**
   * Returns true if in loading state, else returns false
   */
  isLoading(): boolean {
    if (this.loading === true) {
      return true;
    }
    return false;
  }

  /**
   * Returns true if in successful state, else returns false
   */
  isSuccessful(): boolean {
    if (this.loading === false && this.error.status === false) {
      return true;
    }
    return false;
  }

  /**
   * Returns true if in failed state, else returns false
   */
  isFailure() {
    if (this.loading === false && this.error.status === true) {
      return true;
    }
    return false;
  }
}
