import { Goal, GoalPriority, GoalStatus, GoalType, GoalVisibility } from './goal.model';
import { GoalKeyResult } from './goal-key-result.model';
import { User } from 'app/models/user/user.model';
import { GoalTag } from './goal-tag.model';
import { OrganisationalUnit } from '../organisational-unit.model';
import { OfficeLocation } from '../company.model';
import { GoalActivityType } from './goal-activity.model';

export class GoalCommentDto {
  id: number | null;
  comment: string | null;

  constructor(id: number | null, comment: string | null) {
    this.id = id;
    this.comment = comment;
  }
}

export class GoalProgressDto {
  status: GoalStatus;
  keyResults: Array<GoalKeyResult>;
  progress: string | null;

  constructor(status: GoalStatus, keyResults: Array<GoalKeyResult>, progress: string) {
    this.status = status;
    this.keyResults = keyResults;
    this.progress = progress;
  }
}

export class GoalStatusDto {
  goalActivityType: GoalActivityType;

  constructor(goalActivityType: GoalActivityType) {
    this.goalActivityType = goalActivityType;
  }
}

export class GoalUpdateDto {
  title: string;
  type: GoalType;
  department: OrganisationalUnit | null;
  officeLocation: OfficeLocation | null;
  endDate: Date;
  visibility: GoalVisibility;
  priority: GoalPriority;
  tags: Array<GoalTag>;
  keyResults: Array<GoalKeyResult>;
  owners: Array<User>
  status: GoalStatus;
  alignment: number | null;
  developmentNeeds: string | null;

  constructor(goal: Goal) {
    this.title = goal.title;
    this.type = goal.type;
    this.department = goal.department;
    this.officeLocation = goal.officeLocation;
    this.endDate = goal.endDate;
    this.priority = goal.priority;
    this.tags = goal.tags;
    this.keyResults = goal.keyResults;
    this.owners = goal.owners;
    this.status = goal.status;
    this.alignment = goal.alignment;
    this.visibility = goal.visibility;

    // department and office locations
    if (goal.type === GoalType.DEPARTMENT) {
      this.department = goal.department;
      this.officeLocation = null;
    } else if (goal.type === GoalType.OFFICE_LOCATION) {
      this.officeLocation = goal.officeLocation;
      this.department = null;
    } else {
      this.department = null;
      this.officeLocation = null;
    }

    // development needs
    if (goal.type === GoalType.PERSONAL_DEVELOPMENTAL) {
      this.developmentNeeds = goal.developmentNeeds;
    } else {
      this.developmentNeeds = null;
    }
  }
}

