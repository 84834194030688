import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Goal } from 'app/models/goals/goal.model';
import { GoalsAPIService } from 'app/shared/api/goals.api.service';
import { GoalCommentDto } from 'app/models/goals/goal.dto';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { Globals } from 'app/shared/globals/globals';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GoalActivitiy, GoalActivityType } from '@app/models/goals/goal-activity.model';
import { GoalKeyResultType } from '@app/models/goals/goal-key-result-type.model';
import { GoalKeyResultMeasureUnitPlacement } from '@app/models/goals/goal-key-result-measure-unit-placement.model';

declare var $: any;

@Component({
  'selector': 'app-goals-individual-activity-component',
  'templateUrl': './goals-individual-activity.component.html',
  'styleUrls': ['./goals-individual-activity.component.css']
})
export class GoalsIndividualActivityComponent implements OnInit {
  readonly eGoalKeyResultType = GoalKeyResultType;
  readonly eGoalKeyResultMeasureUnitPlacement = GoalKeyResultMeasureUnitPlacement;
  @Input() goal !: Goal;
  @Output() refresh: EventEmitter<boolean> = new EventEmitter();


  eGoalActivityType = GoalActivityType;

  inputComment = '';
  commentLoading = false;
  commentDeleting: number | null = null;

  form: FormGroup;
  submitted: boolean;
  comment: AbstractControl;

  activity: Array<GoalActivitiy>;

  constructor(
    private goalsAPIService: GoalsAPIService,
    private globals: Globals,
    private formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group({
      comment: ['', [Validators.required, Validators.maxLength(255)]]
    });
    this.comment = this.form.controls.comment;
    this.submitted = false;
    this.activity = new Array<GoalActivitiy>();
  }

  ngOnInit() {
    this.activity = this.goal.activity.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB.getTime() - dateA.getTime();
    });
  }


  addComment() {
    this.commentLoading = true;
    this.submitted = true;

    if (this.form.valid) {
      const goalCommentDto = new GoalCommentDto(null, this.comment.value);
      this.goalsAPIService.addComment(this.goal.id, goalCommentDto).subscribe((goal: Goal) => {
        this.goal.activity = goal.activity.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB.getTime() - dateA.getTime();
        });
        $.notify('Your comment has been successfully added');
        this.commentLoading = false;
        this.comment.setValue('');
        this.submitted = false;
      }, (_failure: HttpErrorResponse) => {
        this.commentLoading = false;
      });
    } else {
      Swal.fire({
        title: 'Comment must be between 1 and 255 characters in length',
        imageUrl: 'assets/img/swal-icons/Frankli_sure_icon-46.svg',
        imageWidth: 140,
        imageHeight: 140,
        confirmButtonColor: '#54C6BB'
      })
      this.commentLoading = false;
    }

  }

  deleteComment(id: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This action will delete the comment and cannot be undone',
      // type: 'warning', // FIXME:REMOVED: Removed in swal package update
      showCancelButton: true,
      confirmButtonColor: '#54c6bb',
      confirmButtonText: 'Delete comment'
    }).then((result) => {
      if (result.value) {
        this.commentDeleting = id;
        const goalCommentDto = new GoalCommentDto(id, null);
        this.goalsAPIService.deleteComment(this.goal.id, goalCommentDto).subscribe((goal: Goal) => {
          this.goal.activity = goal.activity.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateB.getTime() - dateA.getTime();
          });
          $.notify('The comment has been successfully deleted');
          this.commentDeleting = null;
        }, (_failure: HttpErrorResponse) => {
          this.commentDeleting = null;
        });
      }
    });
  }

  unsavedChanges() {
    if (this.comment.value.trim().length > 0) {
      return true;
    }
    return false;
  }

  resetInput() {
    if (!this.commentLoading) {
      this.form.reset();
    }
  }
}
