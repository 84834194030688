import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from 'app/models/user/user.model';
import { OfficeLocation } from 'app/models/company.model';
import { UserAPIService } from '../../api/user.api.service';
import { Globals } from 'app/shared/globals/globals';
import Swal from 'sweetalert2';
import { EditProfileService } from 'app/profile/profile-components/edit/edit-profile.service';
import { Subscription } from 'rxjs';
import { CountriesPrimary, CountriesRest, Country } from 'app/shared/utils/countries.util';
import { UserLocationDetails } from 'app/models/user/user-location-details.dto';
import { SignUpStepType } from '@app/models/sign-up/sign-up-step-type.model';
import * as moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';

declare var $: any;

@Component({
  selector: 'app-employee-location-details',
  templateUrl: './location-details.component.html',
  styleUrls: ['./location-details.component.css'],
})
export class LocationDetailsComponent implements OnInit, OnDestroy {
  @Input() user: User;
  @Input() officeLocations: Array<OfficeLocation>;
  @Input() formType: 'SIGN_UP' | 'EDIT';
  @Input() step;

  form: FormGroup;
  submitted: boolean;

  homeAddress: AbstractControl;
  city: AbstractControl;
  country: AbstractControl;
  officeLocation: AbstractControl;
  postalCode: AbstractControl;
  timezone: AbstractControl;

  subscriptionCanChange: Subscription;

  countriesPrimary: Array<Country>;
  countriesRest: Array<Country>;
  timezones: Array<string>;

  default = 'Please Select...';

  loading = false;

  charactersMaxLength = 255;
  charactersMaxLengthPhoneNumber = 17;

  constructor(
    private formBuilder: FormBuilder,
    private userAPIService: UserAPIService,
    private globals: Globals,
    private editProfileService: EditProfileService,
  ) {
    this.countriesPrimary = CountriesPrimary;
    this.countriesRest = CountriesRest;
    this.timezones = moment.tz.names().filter(x => x.substring(0, 4) !== 'Etc/');

    this.subscriptionCanChange = this.editProfileService.getCanChange().subscribe(res => {
      // check for unsaved changes
      if (this.checkForUnsavedChanges()) {
        Swal.fire({
          title: 'Unsaved Changes',
          text: 'Your changes will not be saved if you leave!',
          imageUrl: 'assets/img/swal-icons/Frankli_sure_icon-46.svg',
          imageWidth: 140,
          imageHeight: 140,
          confirmButtonColor: '#54c6bb',
          showCancelButton: true,
        }).then(val => {
          if (val.value) {
            this.editProfileService.sendChange(res);
          }
        });
      } else {
        this.editProfileService.sendChange(res);
      }
    });
    this.form = this.formBuilder.group({
      homeAddress: ['', [Validators.maxLength(this.charactersMaxLength)]],
      city: ['', [Validators.maxLength(this.charactersMaxLength)]],
      country: ['', Validators.required],
      officeLocation: [null, []],
      postalCode: ['', [Validators.maxLength(this.charactersMaxLength)]],
      timezone: [null, Validators.required]
    });

    this.homeAddress = this.form.controls.homeAddress;
    this.city = this.form.controls.city;
    this.country = this.form.controls.country;
    this.officeLocation = this.form.controls.officeLocation;
    this.postalCode = this.form.controls.postalCode;
    this.timezone = this.form.controls.timezone;
  }

  ngOnInit() {
    this.populateWithUserData();

    if (this.user.homeAddress.country === undefined
      || this.user.homeAddress.country === null || this.user.homeAddress.country === '') {
      this.country.setValue(this.default);
    }

    if (this.user.officeLocation === null) {
      this.officeLocation.setValue(null);
    } else if (this.user.officeLocation === undefined || this.user.officeLocation.name === '') {
      this.officeLocation.setValue(this.default);
    }
  }

  onFormSubmit() {
    this.submitted = true;
    if (this.form.valid && this.officeLocation.value !== this.default && this.country.value !== this.default) {
      if (this.formType === 'EDIT') {
        this.loading = true;
        this.saveDataToUserObject();
        const details = new UserLocationDetails(this.user);
        this.userAPIService.updateLocationDetails(details).subscribe(user => {
          this.globals.user = user;
          this.user = user;
          $.notify('Your profile has been successfully updated');
          this.loading = false;
        }, (_error: HttpErrorResponse) => {
          this.loading = false;
        });
      } else if (this.formType === 'SIGN_UP') {
        // TODO: change to post and update state
        this.saveDataToUserObject();
        this.step.complete += 1;
        this.step.current = SignUpStepType.PERSONAL_DETAILS;
      }
    }
  }

  // Populate form with data if user is navigating back to this page
  populateWithUserData() {
    if (this.user.homeAddress.street) {
      this.homeAddress.setValue(this.user.homeAddress.street);
    }
    if (this.user.homeAddress.city) {
      this.city.setValue(this.user.homeAddress.city);
    }
    if (this.user.homeAddress.country) {
      this.country.setValue(this.user.homeAddress.country);
    }
    if (this.user.homeAddress.postalCode) {
      this.postalCode.setValue(this.user.homeAddress.postalCode);
    }
    if (this.user.homeAddress.timezone) {
      this.timezone.setValue(this.user.homeAddress.timezone);
    }
    if (this.user.officeLocation) {
      this.officeLocation.setValue(this.user.officeLocation.id);
    }
  }

  saveDataToUserObject() {
    this.user.homeAddress.street = this.homeAddress.value;
    this.user.homeAddress.city = this.city.value;
    this.user.homeAddress.country = this.country.value;
    this.user.homeAddress.postalCode = this.postalCode.value;
    this.user.homeAddress.timezone = this.timezone.value;

    if (this.officeLocation.value === null) {
      this.user.officeLocation = null;
    } else {
      const officeLocation = this.officeLocations.find(ol => ol.id === this.officeLocation.value);
      if (officeLocation !== undefined) {
        this.user.officeLocation = officeLocation;
      }
    }
  }

  ngOnDestroy() {
    this.subscriptionCanChange.unsubscribe();
  }

  checkForUnsavedChanges(): boolean {
    if (this.form.valid) {
      return (
        this.user.homeAddress.street !== this.homeAddress.value
        || this.user.homeAddress.city !== this.city.value
        || this.user.homeAddress.country !== this.country.value
        || this.user.homeAddress.postalCode !== this.postalCode.value
        || this.user.homeAddress.timezone !== this.timezone.value
        || (this.officeLocation.value === null && this.user.officeLocation !== null)
        || (this.officeLocation.value !== null && this.user.officeLocation === null)
      );
    } else {
      return false;
    }
  }
}
