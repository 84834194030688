<div class="signup-container-outer">
  <div class="signup-container">
    <div class="signup-row">
      <div class="col-left">
        <div class="header-image">
          <img src="/assets/img/Frankli_logo_primary.png" />
        </div>

        <ng-container *ngIf="!loading; else stateLoading;">
          <ng-container *ngIf="!error; else stateError;">
            <ng-container [ngSwitch]="step.current">
              <app-choose-password *ngSwitchCase="eSignUpStepType.CHOOSE_PASSWORD" [step]="step"></app-choose-password>
              <app-employee-location-details *ngSwitchCase="eSignUpStepType.LOCATION_DETAILS" [step]="step" [user]="user" [formType]="'SIGN_UP'" [officeLocations]="officeLocations"></app-employee-location-details>
              <app-employee-sign-up-personal-details *ngSwitchCase="eSignUpStepType.PERSONAL_DETAILS" [step]="step" [user]="user" [formType]="'SIGN_UP'"></app-employee-sign-up-personal-details>
              <app-employee-sign-up-profile-details *ngSwitchCase="eSignUpStepType.PROFILE_DETAILS" [step]="step" [user]="user" [formType]="'SIGN_UP'"></app-employee-sign-up-profile-details>
              <app-employee-sign-up-complete *ngSwitchCase="eSignUpStepType.SIGNUP_COMPLETE" [user]="user"></app-employee-sign-up-complete>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div class="col-right">
        <div class="carousel-items">
          <div *ngFor="let c of carousels; index as i; first as isFirst" [class.hidden]="step.complete != i">
            <div class="text-center p-bottom-15">
              <div class="carousel-img" [class.first-image]="isFirst">
                <img src="{{c.src}}" alt="connectivity"/>
              </div>
            </div>

            <div class="carousel-title">{{c.msg}}</div>

            <div class="carousel-description">{{c.desc}}</div>
          </div>
        </div>
        <div class="carousel-button-row">
          <div class="carousel-button" *ngFor="let b of carousels; index as i;" [class.active]='(step.complete == i)'>{{i+1}}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- #region - ALTERNATE PAGE STATES -->
<ng-template #stateLoading>
  <div class="text-center">
    <span class="fal fa-spin fa-spinner fa-4x fa-fw"></span>
  </div>
</ng-template>

<ng-template #stateError>
  <div class="text-center">
    {{errorMessage}}
  </div>
</ng-template>
<!-- #endregion -->