import { Role } from "@app/models/role.model";

export class UserMinimalSort {
    /**
     * Sorts an array of users by full name name
     * @param users
     */
    public static sortByFullName(users: Array<UserMinimal>): Array<UserMinimal> {
        return users.sort((a, b) => (a.firstName + ' ' + a.lastName).localeCompare((b.firstName + ' ' + b.lastName)));
    }
}

export interface UserMinimal {
    id: number;
    managerId: number;
    organisationalUnitId?: number;
    officeLocationId?: number;
    positionId?: number;
    email: string;
    firstName: string;
    lastName: string;
    imageUrl: string;
    linkedIn: string;
    twitterHandle: string;
    teamsEmail: string;
    phoneNumber: string;
    roles: Array<Role>;
}