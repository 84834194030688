import { GoalTag } from 'app/models/goals/goal-tag.model';
import { User } from '../user/user.model';
import { ActivityReaction } from '@app/models/activity-reaction.model';

export class PraiseItem {
  id: number;
  praiseUserId: number;
  praiseUserFirstName: string;
  praiseUserLastName: string;
  sourceUserId: number;
  sourceUserFirstName: string;
  sourceUserLastName: string;
  message: string;
  timestamp: Date;
  sourceUserImageUrl?: string;
  hidden: boolean;
  values?: Array<GoalTag>;
  reactions: Array<ActivityReaction>;

  praiseUser?: User;
  sourceUser?: User;

  constructor(id?: number,
    praiseUserId?: number,
    praiseUserFirstName?: string,
    praiseUserLastName?: string,
    sourceUserId?: number,
    sourceUserFirstName?: string,
    sourceUserLastName?: string,
    message?: string,
    timestamp?: Date,
    hidden?: boolean,
    values?: Array<GoalTag>) {
    this.id = id;
    this.praiseUserId = praiseUserId;
    this.praiseUserFirstName = praiseUserFirstName;
    this.praiseUserLastName = praiseUserLastName;
    this.sourceUserId = sourceUserId;
    this.sourceUserFirstName = sourceUserFirstName;
    this.sourceUserLastName = sourceUserLastName;
    this.message = message;
    this.timestamp = timestamp;
    this.hidden = hidden;
    this.values = values;
  }
}

export class CreatePraiseDto {
  constructor(
    private praiseUserId: number,
    private message: string,
    private values: Array<GoalTag>
  ) { }
}

export class UpdatePraiseDto {
  constructor(
    private message: string,
    private values: Array<GoalTag>
  ) { }
}
