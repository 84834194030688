export enum SurveyQuestionType {
  TEXT = 'TEXT', // It should always be possible to add text 0
  SINGLE_CHOICE = 'SINGLE_CHOICE', // Radio button from list of options 1
  MULTI_CHOICE = 'MULTI_CHOICE', // Choose as many 2
  RANKED_ONE_TO_TEN = 'RANKED_ONE_TO_TEN', // 1 to 10 3
  RANKED_SMILES = 'RANKED_SMILES', // Sad, Okay, Happy 4
  LIKERT_SCALE = 'LIKERT_SCALE', // Strongly disagree to Strongly agree 5
  EXPECTATIONS = 'EXPECTATIONS', // Below Expectations to exceeds Expectations 6
  PERFORMER = 'PERFORMER', // Below Average to Top Performer 7
  RANKED_ONE_TO_FIVE = 'RANKED_ONE_TO_FIVE', // 1-5 rating 8
  LIKERT_SCALE_COMMENT = 'LIKERT_SCALE_COMMENT', // 9 
  TEXT_BOX = 'TEXT_BOX', // 10
  TEXT_AREA = 'TEXT_AREA', // 11
  ENPS = 'ENPS', // 12
}
