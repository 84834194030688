<div class="password-reset-container">

  <!-- Form -->
  <ng-container *ngIf="state.complete === false">

    <!-- Submit -->
    <ng-container *ngIf="state.error === false">
      <div class="card">
        <form [formGroup]="emailForm" (ngSubmit)="emailFormSubmit()">

          <div class="header text-center password-reset-header">
            <h4 class="text-uppercase">Forgot your password?</h4>
          </div>

          <div class="content">
            <div class="row m-bottom-30">
              <div class="col-md-12">
                <div class="frankli-form-group text-left"
                  [class.frankli-form-group-invalid]='email.invalid && state.submitted'
                  [class.frankli-form-group-filled]='email.value.length > 0'>

                  <label for="email">Work Email</label>
                  <input type="email" id="email" name="email" class="form-control" placeholder="name@company.com"
                    autocomplete="email" [formControl]="email">
                  <ng-container *ngIf="email.invalid && state.submitted === true">
                    <div class="frankli-form-group-alert">
                      <div *ngIf="email.hasError('required')">Email address is required.</div>
                      <div *ngIf="email.hasError('pattern') && !email.hasError('required')">Must be a valid email
                        address.</div>
                    </div>
                  </ng-container>

                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 text-center">
                <button *ngIf="state.loading === false" class="btn-frankli-round btn-frankli-green" type="submit"
                  angulartics2On="click" angularticsAction="Send Reset Instructions" angularticsCategory="Support">
                  Send reset instructions</button>
                <button *ngIf="state.loading === true" class="btn-frankli-round btn-frankli-green">
                  <span class="fal fa-spin fa-spinner"></span>
                </button>
              </div>
            </div>
          </div>

        </form>
      </div>
    </ng-container>

    <!-- Error -->
    <ng-container *ngIf="state.error === true">
      <div class="card">
        <div class="header text-center">
          <p>
            <img class="icon-padding new-icon-height" src="/assets/img/Failure.svg" />
            <br />
            {{state.errorMessage}}
          </p>
        </div>
        <div class="content m-top-10">
          <div class="row text-center">
            <div class="col-md-12">
              <button (click)="resetForm()" class="btn-frankli-round btn-frankli-green">Try again?</button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

  </ng-container>

  <!-- Success -->
  <ng-container *ngIf="state.complete === true">
    <div class="card">
      <div class="header text-center p-top-0">
        <p>
          <img class="icon-padding new-icon-height" src="/assets/img/Success.svg" />
          <br />
          If your email is in our system, you have been sent password reset instructions.<br /> Please check your email
        </p>
      </div>
      <div class="content m-top-15">
        <div class="row text-center">
          <div class="col-md-12">
            <button routerLink="/login" class="btn-frankli-round btn-frankli-green">Log in</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

</div>