import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User, UserInit, UserServerSide } from 'app/models/user/user.model';
import { CreateUserDto } from 'app/models/user/create-user.dto';
import { Role } from 'app/models/role.model';
import { UserPersonalDetails } from 'app/models/user/user-personal-details.model';
import { UserProfileDetails } from 'app/models/user/user-profile-details.model';
import { Interest } from 'app/models/interest.model';
import { catchError, map, tap } from 'rxjs/operators';
import { UserAdministrateDto } from 'app/models/user/administrate-user.dto';
import { OfficeLocation } from 'app/models/company.model';
import { UserCompleteSignUp } from 'app/models/user/user-complete-sign-up.dto';
import { UserLocationDetails } from 'app/models/user/user-location-details.dto';
import { environment } from 'environments/environment';
import { Globals } from '../globals/globals';
import { Angulartics2Amplitude } from 'angulartics2/amplitude';
import { ChangePasswordDto } from 'app/models/user/change-password.dto';
import { CompanyAPIService } from './company.api.service';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { UserService } from '@app/shared/api/interfaces/user.service';

@Injectable()
export class UserAPIService implements UserService {

  private readonly BASE_URL = 'api/user/';
  httpHeaders: HttpHeaders;
  constructor(
    private http: HttpClient,
    private globals: Globals,
    private angulartics2Amplitude: Angulartics2Amplitude,
    private companyAPIService: CompanyAPIService
  ) {
    this.httpHeaders = new HttpHeaders();
    this.httpHeaders.append('Content-Type', 'application/json');
  }

  getMe(): Observable<User> {
    const url = this.BASE_URL + 'me';
    return this.http.get<UserServerSide>(url).pipe(map(u => new User(u)));
  }

  getById(id: number): Observable<User> {
    const url = this.BASE_URL + id;
    return this.http.get<UserServerSide>(url).pipe(map(u => new User(u)));
  }

  getAllUsers(): Observable<Array<User>> {
    const url = this.BASE_URL + 'all';
    return this.http.get<Array<User>>(url);
  }

  getAllUsersMinimal(): Observable<Array<UserMinimal>> {
    const url = this.BASE_URL + 'all/minimal';
    return this.http.get<Array<UserMinimal>>(url);
  }

  /**
   * Returns a list of all active, pending and invited users other than the currently logged in user
   */
  getAllOtherUsers(): Observable<Array<User>> {
    const url = '/api/user/other';
    return this.http.get<Array<User>>(url);
  }

  /**
   * Returns a list of all active, pending and invited users other than the currently
   * logged in user with their manager's name populated
   */
  getOtherUsersAndManagers(): Observable<Array<User>> {
    const url = this.BASE_URL + 'all/manager';
    return this.http.get<Array<User>>(url);
  }

  getAllUsersOnboarded(): Observable<Array<User>> {
    const url = this.BASE_URL + 'onboarded';
    return this.http.get<Array<User>>(url);
  }

  countAllUsersOnboarded(): Observable<number> {
    const url = this.BASE_URL + 'onboarded/count';
    return this.http.get<number>(url);
  }

  create(user: CreateUserDto): Observable<UserServerSide> {
    const url = this.BASE_URL;
    return this.http.post<UserServerSide>(url, user, {headers: this.httpHeaders});
  }

  /**
   * Creates an individual bulk upload user (must have dependencies sorted by bulk upload service first)
   * @param user
   */
  createBulkUploadUser(user: CreateUserDto): Observable<UserServerSide> {
    const url = `${this.BASE_URL}bulk-upload`;
    return this.http.post<UserServerSide>(url, user, {headers: this.httpHeaders});
  }

  /**
   * Creates users from a list of bulk upload users
   * @param users
   */
  createAllBulkUploadUsers(users: Array<CreateUserDto>): Observable<Array<UserServerSide>> {
    const url = `${this.BASE_URL}bulk-upload/all`;
    return this.http.post<Array<UserServerSide>>(url, users, {headers: this.httpHeaders});
  }


  getAllRoles(): Observable<Array<Role>> {
    const url = this.BASE_URL + 'roles';
    return this.http.get<Array<Role>>(url);
  }

  updatePassword(changePasswordDto: ChangePasswordDto): Observable<User> {
    const url = this.BASE_URL + 'password';
    return this.http.put<UserServerSide>(url, changePasswordDto).pipe(map(u => new User(u)));
  }

  updatePasswordFirstTimeIn(password: String): Observable<User> {
    const url = this.BASE_URL + 'password/signup';
    return this.http.put<UserServerSide>(url, password).pipe(map(u => new User(u)));
  }

  updateUserOfficeLocation(officeLocation: OfficeLocation | null): Observable<User> {
    const url = this.BASE_URL + 'office-location';
    return this.http.put<UserServerSide>(url, officeLocation).pipe(map(u => new User(u)));
  }

  getUsersSimilar(): Observable<Array<UserServerSide>> {
    const url = this.BASE_URL + 'similar';
    return this.http.get<Array<UserServerSide>>(url);
  }

  getUsersByManagerMe(): Observable<Array<User>> {
    const url = this.BASE_URL + 'manager/me';
    return this.http.get<Array<User>>(url);
  }

  getUsersByManagerId(managerId: number): Observable<Array<User>> {
    const url = this.BASE_URL + 'manager/' + managerId;
    return this.http.get<Array<User>>(url);
  }

  searchUsers(sarg: string): Observable<Array<User>> {
    const url = this.BASE_URL + 'search';
    return this.http.post<Array<User>>(url, sarg);
  }

  updateLocationDetails(user: UserLocationDetails): Observable<User> {
    const url = this.BASE_URL + 'update/location-details';
    return this.http.post<UserServerSide>(url, user).pipe(map(u => new User(u)));
  }

  updatePersonalDetails(user: UserPersonalDetails): Observable<User> {
    const url = this.BASE_URL + 'update/personal-details';
    return this.http.post<UserServerSide>(url, user).pipe(map(u => new User(u)));
  }

  updateProfileDetails(user: UserProfileDetails): Observable<User> {
    const url = this.BASE_URL + 'update/profile-details';
    return this.http.post<UserServerSide>(url, user).pipe(map(u => new User(u)));
  }

  updateProfilePicture(file: any, fileExtension: string): Observable<User> {
    const url = this.BASE_URL + 'update/profile-picture';

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('fileExtension', fileExtension);

    return this.http.post<UserServerSide>(url, formData).pipe(map(u => new User(u)));
  }

  updatePersonalInterests(interests: Array<Interest>): Observable<User> {
    const url = this.BASE_URL + 'update/interests-personal';
    return this.http.post<UserServerSide>(url, interests).pipe(map(u => new User(u)));
  }

  updateProfessionalInterests(interests: Array<Interest>): Observable<User> {
    const url = this.BASE_URL + 'update/interests-professional';
    return this.http.post<UserServerSide>(url, interests).pipe(map(u => new User(u)));
  }

  updateUserCompleteSignUp(userCompleteSignUp: UserCompleteSignUp): Observable<User> {
    const url = this.BASE_URL + 'sign-up';
    return this.http.put<UserServerSide>(url, userCompleteSignUp).pipe(map(u => new User(u)));
  }

  resetUserPassword(email: string): Observable<number> {
    const url = this.BASE_URL + 'password/reset';
    return this.http.post<number>(url, email);
  }

  updatePasswordOnReset(password: String): Observable<User> {
    const url = this.BASE_URL + 'password/reset';
    return this.http.put<UserServerSide>(url, password).pipe(map(u => new User(u)));
  }

  getUserHash() {
    const url = this.BASE_URL + 'hash';
    return this.http.get(url, {responseType: 'text'});
  }


  /**
   * Gets the app initialisation details and updates amplitude
   */
  getUserInit(): Observable<UserInit> {
    const url = this.BASE_URL + 'init';

    return this.http.get<any>(url).pipe(
        map(ui => {
          ui.company = this.companyAPIService.mapCompany(ui.company);
          return {
            user: new User(ui.user),
            company: ui.company,
            access_token: ''
          }
        }),
        tap((init: UserInit) => {
          this.globals.init(init);
          if (environment.production) {
            this.angulartics2Amplitude.setUsername(this.globals.user.email);
          }
        }),
        catchError((error: any) => {
          return throwError(error);
        })
    );
  }

  archiveUser(userId: number): Observable<User> {
    const url = this.BASE_URL + 'archive/' + userId;
    return this.http.post<User>(url, null);
  }

  unarchiveUser(userId: number, role: Role): Observable<User> {
    const url = this.BASE_URL + 'unarchive/' + userId;
    return this.http.post<User>(url, role);
  }

  getAllUsersAdmin(): Observable<Array<User>> {
    const url = this.BASE_URL + 'all/admin';
    return this.http.get<Array<User>>(url);
  }

  revokeAccess(userId: number): Observable<User> {
    const url = this.BASE_URL + 'revoke/' + userId;
    return this.http.post<User>(url, userId);
  }

  unrevokeAccessUser(userId: number, role: Role): Observable<User> {
    const url = this.BASE_URL + 'unrevoke/' + userId;
    return this.http.post<User>(url, role);
  }

  administrateUser(userId: number, userAdminstrateDto: UserAdministrateDto) {
    const url = this.BASE_URL + 'administrate/' + userId;
    return this.http.post<User>(url, userAdminstrateDto);
  }

  downloadUserData(): Observable<any> {
    const url = this.BASE_URL + 'download/all';
    return this.http.get(url, {responseType: 'blob'});
  }

  /**
   * Sends a user an invite email provided their status is pending or invited
   */
  inviteUser(userId: number): Observable<User> {
    const url = this.BASE_URL + 'invite/' + userId;
    return this.http.post<User>(url, null);
  }

  /**
   * Sends an invite email to all pending users
   */
  inviteAllPendingUsers(): Observable<Array<User>> {
    const url = this.BASE_URL + 'invite/all';
    return this.http.post<Array<User>>(url, null);
  }

  userRefresh(): Observable<any> {
    const url = this.BASE_URL + 'refresh';
    return this.http.post<any>(url, null);
  }

  getUsersOnboardedNotInCycles(): Observable<Array<User>> {
    const url = this.BASE_URL + 'evaluation';
    return this.http.get<Array<User>>(url);
  }

  // Socialise
  getUsersByInterestId(id: number): Observable<Array<User>> {
    const url = `${this.BASE_URL}interests/${id}`;
    return this.http.get<Array<UserServerSide>>(url).pipe(map(u => this.usersServerToClient(u)));
  }

  getUsersSocialise(): Observable<Array<User>> {
    const url = `${this.BASE_URL}socialise`;
    return this.http.get<Array<UserServerSide>>(url).pipe(map(u => this.usersServerToClient(u)));
  }

  updateUserInterestsSocialise(interests: Array<Interest>) {
    const url = `${this.BASE_URL}interests/socialise`;
    return this.http.put<UserServerSide>(url, interests, {headers: this.httpHeaders}).pipe(map(u => new User(u)));
  }

  updateUserInterestsCoach(interests: Array<Interest>) {
    const url = `${this.BASE_URL}interests/coach`;
    return this.http.put<UserServerSide>(url, interests).pipe(map(u => new User(u)));
  }

  updateUserInterestsMentor(interests: Array<Interest>) {
    const url = `${this.BASE_URL}interests/mentor`;
    return this.http.put<UserServerSide>(url, interests).pipe(map(u => new User(u)));
  }

  frankliAdminExportAllManagers(): Observable<any> {
    const url = this.BASE_URL + 'frankli-admin/export/manager';
    return this.http.get(url, {responseType: 'blob'});
  }

  frankliAdminExportAllAdmins(): Observable<any> {
    const url = this.BASE_URL + 'frankli-admin/export/admin';
    return this.http.get(url, {responseType: 'blob'});
  }

  private usersServerToClient(users: Array<UserServerSide>) {
    const usersReturn = new Array<User>();
    users.forEach(u => {
      const user = new User(u);
      usersReturn.push(user);
    });
    return usersReturn;
  }
}

