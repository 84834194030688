import { MentoringRequest } from '@app/models/connect/mentoring-request/mentoring-request.model';
import { MentoringRequestStatus } from '@app/models/connect/type/mentoring-request-status.enum';
import * as moment from 'moment';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { mockConnectSkills } from '@app/mock/api/data/mockConnectSkills';
import { mockMentoringRequestAnswers } from '@app/mock/api/data/mockConnectQuestionsAnswered';

export const mockMentoringRequests: MentoringRequest[] = [
  {
    id: 1,
    createdTimestamp: moment().subtract(1, 'week').toDate(),
    lastEditTimestamp: moment().subtract(1, 'week').toDate(),
    companyId: 1,
    menteeManagerId: mockUsers[1].managerId,
    status: MentoringRequestStatus.MENTOR_APPROVED,
    mentorId: mockUsers[0].id,
    mentor: mockUsers[0],
    menteeId: mockUsers[1].id,
    mentee: mockUsers[1],
    skills: [
      mockConnectSkills[4],
      mockConnectSkills[5],
    ],
    answers: mockMentoringRequestAnswers,
    managerComment: '',
    mentorComment: ''
  },
  {
    id: 2,
    createdTimestamp: moment().subtract(1, 'week').toDate(),
    lastEditTimestamp: moment().subtract(1, 'week').toDate(),
    companyId: 1,
    menteeManagerId: mockUsers[0].managerId,
    status: MentoringRequestStatus.MENTOR_APPROVED,
    mentorId: mockUsers[4].id,
    mentor: mockUsers[4],
    menteeId: mockUsers[0].id,
    mentee: mockUsers[0],
    skills: [
      mockConnectSkills[7],
      mockConnectSkills[8],
    ],
    answers: mockMentoringRequestAnswers,
    managerComment: '',
    mentorComment: ''
  },
  {
    id: 3,
    createdTimestamp: moment().subtract(1, 'week').toDate(),
    lastEditTimestamp: moment().subtract(1, 'week').toDate(),
    companyId: 1,
    menteeManagerId: mockUsers[2].managerId,
    status: MentoringRequestStatus.PENDING,
    mentorId: mockUsers[2].id,
    mentor: mockUsers[2],
    menteeId: mockUsers[6].id,
    mentee: mockUsers[6],
    skills: [
      mockConnectSkills[7],
      mockConnectSkills[8],
    ],
    answers: mockMentoringRequestAnswers,
    managerComment: '',
    mentorComment: ''
  }
];