import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserState } from '@app/models/user/user.model';
import { Globals } from 'app/shared/globals/globals';

/**
 * Redirects to password reset if user.passwordReset === true
 */
@Injectable()
export class PasswordResetGuard {

  constructor(
    private globals: Globals,
    private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // this.globals.user.userState === UserState.INVITED || this.globals.user.userState === UserState.PENDING
    if (this.globals.user.passwordReset && !this.globals.signedInWithSSO && this.globals.user.userState !== UserState.INVITED && this.globals.user.userState !== UserState.PENDING) {
      this.router.navigate(['/reset/password']);
      return false;
    }
    return true;
  }
}
