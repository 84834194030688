<router-outlet></router-outlet>
<ng-container *ngIf="route.children.length === 0">
  <div class="content">
    <ng-container *ngIf="!state.loading; else stateLoading">
      <ng-container *ngIf="!state.error; else stateError">
        <!-- Stats -->
        <div class="card p-left-10 p-right-10 p-top-10" *ngIf="rootUser">
          <div class="row stats-row">
            <div class="col-md-3 col-sm-6 col-xs-12" [title]="'You and your ' + companyWording.team.toLowerCase() + ' have a total of ' + rootUser.goalsCount + ' goals '">
              <div class="stat-col">
                <span class="fal fa-bullseye"></span>
                <span class="stats-text">
                  <span><b>{{rootUser.goalsCount}}</b> Total Goals</span>
                </span>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12" [title]="'You and your ' + companyWording.team.toLowerCase() + ' have ' + rootUser.activeCount + ' active goals'">
              <div class="stat-col">
                <span class="fal fa-calendar-alt"></span>
                <span class="stats-text">
                  <span><b>{{rootUser.activeCount}}</b> Active Goals</span>
                </span>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12" [title]="'You and your ' + companyWording.team.toLowerCase() + ' have completed ' + rootUser.completeCount + ' goals'">
              <div class="stat-col">
                <span class="fal fa-check-circle"></span>
                <span class="stats-text">
                  <span><b>{{rootUser.completeCount}}</b> Complete Goals</span>
                </span>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12" [title]="'You and your ' + companyWording.team.toLowerCase() + '\'s goals have an average completion of ' + rootUser.averageCompletion + '%'">
              <div class="stat-col">
                <span class="progress progress-round">
                  <span class="progress-left">
                    <span class="progress-bar border-primary" [style.transform]="roundProgress.left"></span>
                  </span>
                  <span class="progress-right">
                    <span class="progress-bar border-primary" [style.transform]="roundProgress.right"></span>
                  </span>
                </span>
                <span class="stats-text">
                  <span><b>{{rootUser.averageCompletion}}%</b> Average Completion</span>
                </span>
              </div>
            </div>
          </div>
          <!-- <app-goal-stats calculated='EXTERNAL' [statsInput]="topStats"></app-goal-stats> -->
        </div>

        <div class="card">
          <!-- Universal filter and column toggle -->
          <div class="text-center p-left-15 p-right-15 p-top-15 p-bottom-10 p-relative">
            <span class="align-ceo-button" *ngIf="userIsAdmin()" (click)="toggleViewAsAdmin()">
              <label class="m-right-5">View as Admin</label>
              <input type="checkbox" [checked]="viewAsAdmin.value">
            </span>

            <span class="center-container">
              <!-- (Search / Filter) -->
              <span class="uni-filter-container">
                <app-universal-filter [filterOptions]="filterOptions" [searchProps]="searchProps" (resultEmitNested)="universalFilterEmit($event)" #universalFilter></app-universal-filter>
              </span>

              <!-- Column Toggle -->
              <span class="col-toggle-container">
                <app-column-toggle [titles]="columns" #columnToggle></app-column-toggle>
              </span>
            </span>
          </div>

          <ng-container *ngIf="columnToggle && (directReportsFiltered.length > 0); else noSearchResults">
            <!-- Table -->
            <div class="scroll-x">
              <table width="100%">
                <!-- Table headings -->
                <tr class="frankli-table frankli-table-small frankli-table-header noselect">
                  <th></th> <!-- Spacing for dropdown -->
                  <th (click)="doSort(eTableColumn.NAME)" *ngIf="columnToggle.getColumnEnabled(eTableColumn.NAME)"
                    [class.sorting-ascending]="sortType.column === eTableColumn.NAME && !sortType.ascending"
                    [class.sorting-descending]="sortType.column === eTableColumn.NAME && sortType.ascending">
                    {{eTableColumn.NAME}}
                  </th>
                  <th  *ngIf="columnToggle.getColumnEnabled(eTableColumn.NAME)"></th>
                  <th class="text-center" (click)="doSort(eTableColumn.GOAL_COUNT)"
                    *ngIf="columnToggle.getColumnEnabled(eTableColumn.GOAL_COUNT)"
                    [class.sorting-ascending]="sortType.column === eTableColumn.GOAL_COUNT && !sortType.ascending"
                    [class.sorting-descending]="sortType.column === eTableColumn.GOAL_COUNT && sortType.ascending">
                    {{eTableColumn.GOAL_COUNT}}
                  </th>
                  <th class="text-center" (click)="doSort(eTableColumn.ACTIVE_GOAL_COUNT)"
                    *ngIf="columnToggle.getColumnEnabled(eTableColumn.ACTIVE_GOAL_COUNT)"
                    [class.sorting-ascending]="sortType.column === eTableColumn.ACTIVE_GOAL_COUNT && !sortType.ascending"
                    [class.sorting-descending]="sortType.column === eTableColumn.ACTIVE_GOAL_COUNT && sortType.ascending">
                    {{eTableColumn.ACTIVE_GOAL_COUNT}}
                  </th>
                  <th class="text-center" (click)="doSort(eTableColumn.COMPLETE_GOAL_COUNT)"
                    *ngIf="columnToggle.getColumnEnabled(eTableColumn.COMPLETE_GOAL_COUNT)"
                    [class.sorting-ascending]="sortType.column === eTableColumn.COMPLETE_GOAL_COUNT && !sortType.ascending"
                    [class.sorting-descending]="sortType.column === eTableColumn.COMPLETE_GOAL_COUNT && sortType.ascending">
                    {{eTableColumn.COMPLETE_GOAL_COUNT}}
                  </th>
                  <th class="text-center" (click)="doSort(eTableColumn.TOTAL_OBJECTIVES)"
                    *ngIf="columnToggle.getColumnEnabled(eTableColumn.TOTAL_OBJECTIVES)"
                    [class.sorting-ascending]="sortType.column === eTableColumn.TOTAL_OBJECTIVES && !sortType.ascending"
                    [class.sorting-descending]="sortType.column === eTableColumn.TOTAL_OBJECTIVES && sortType.ascending">
                    {{eTableColumn.TOTAL_OBJECTIVES}}
                  </th>
                  <th (click)="doSort(eTableColumn.AVERAGE_COMPLETION)"
                    *ngIf="columnToggle.getColumnEnabled(eTableColumn.AVERAGE_COMPLETION)"
                    [class.sorting-ascending]="sortType.column === eTableColumn.AVERAGE_COMPLETION && !sortType.ascending"
                    [class.sorting-descending]="sortType.column === eTableColumn.AVERAGE_COMPLETION && sortType.ascending">
                    {{eTableColumn.AVERAGE_COMPLETION}}
                  </th>
                  <th class="text-center" *ngIf="columnToggle.getColumnEnabled(eTableColumn.ACTIONS)">Actions</th>
                </tr>
  
                <!-- Table Data -->
                <ng-container *ngFor="let report of directReportsDisplay; trackBy: trackByFn;" [ngTemplateOutlet]="tableRow" [ngTemplateOutletContext]="{report: report, sub: 0}"></ng-container>
  
                <tr *ngIf="columnToggle.checkAllDisabled()">
                  <td class="text-center">
                    <div class="m-top-20 m-bottom-20">No columns selected to display, you can choose which columns to show by clicking the <span class="fal fa-columns text-info"></span> icon above</div>
                  </td>
                </tr>
              </table>
            </div>
  
            <!-- Paginatioon -->
            <app-pagination-new-component *ngIf="!columnToggle.checkAllDisabled()" class="m-top-10" [arrayData]="directReportsFiltered" [arrayDisplay]="directReportsDisplay" [amountToDisplay]="100" #pagination></app-pagination-new-component>
          </ng-container>

          <!-- No searches found -->
          <ng-template #noSearchResults>
            <div class="text-center p-bottom-15">
              <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
              <br>
              <p> There are no users available for this search</p>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<!-- #region - ALTERNATE PAGE STATES -->
<!-- LOADING -->
<ng-template #stateLoading>
  <div class="card">
    <div class="p-top-15 p-bottom-15 text-center">
      <span class="fal fa-spin fa-spinner fa-3x fa-fw"></span>
    </div>
  </div>
</ng-template>

<!-- ERROR -->
<ng-template #stateError>
  <div class="card">
    <div class="p-top-15 p-bottom-15 text-center">
      <p>
        <img class="icon-padding new-icon-height" src="/assets/img/Error.svg" />
        <br />
        An error occurred while getting your peoples goals:
        <br />
        {{state.errorMessage}}
      </p>
      <span class="btn-frankli-round btn-frankli-green" [routerLink]="'/dashboard'">
        Back to your dashboard?
      </span>
    </div>
  </div>
</ng-template>

<!-- #endregion -->

<!-- #region table row -->
<ng-template #tableRow let-report='report' let-sub='sub'>
  <ng-container *ngIf="columnToggle">
    <!-- Table Data -->
    <tr class="team-table-row" [class.tr-not-in-filters]="report.requiredTreeUser">
      <td *ngFor="let indent of [].constructor(sub); index as si" class="text-center" width="50px"></td>
  
      <!-- Dropdown -->
      <td class="text-center" *ngIf="!columnToggle.checkAllDisabled()" width="50px" (click)="report.dropdownOpen = !report.dropdownOpen">
        <span class="fal fa-chevron-right dropdown-toggle frankli-view expand-on-hover" [class.dropdown-opened]="report.dropdownOpen" *ngIf="report.directReports.length > 0"></span>
      </td>
  
      <!-- Name -->
      <td [title]="report.user.firstName + ' ' + report.user.lastName" class="p-left-5 p-right-5" width="50" *ngIf="columnToggle.getColumnEnabled(eTableColumn.NAME)">
        <span class="owner-img m-bottom-5 m-top-5" [routerLink]="['/profile/',report.user.id]">
          <app-profile-photo [src]="report.user.imageUrl" *ngIf="report.user.imageUrl"></app-profile-photo>
          <img src="../../assets/img/default.svg" *ngIf="!report.user.imageUrl">
        </span>
      </td>
  
      <td *ngIf="columnToggle.getColumnEnabled(eTableColumn.NAME)" (click)="report.dropdownOpen = !report.dropdownOpen">
        <span>{{ report.user.firstName }} {{report.user.lastName }}</span>
      </td>
  
      <!-- Goal Count -->
      <td class="text-center" *ngIf="columnToggle.getColumnEnabled(eTableColumn.GOAL_COUNT)" width="60px">
        <span *ngIf="report.goalsCount > 0" [title]="report.goalsCount + ' goals'">{{report.goalsCount}}</span>
      </td>
  
      <!-- Active Goal Count -->
      <td class="text-center" *ngIf="columnToggle.getColumnEnabled(eTableColumn.ACTIVE_GOAL_COUNT)" width="60px">
        <span *ngIf="report.goalsCount > 0" [title]="report.activeCount">{{report.activeCount}}</span>
      </td>
  
      <!-- Complete Goal Count -->
      <td class="text-center" *ngIf="columnToggle.getColumnEnabled(eTableColumn.COMPLETE_GOAL_COUNT)" width="80px">
        <span *ngIf="report.goalsCount > 0" [title]="report.completeCount">{{report.completeCount}}</span>
      </td>
  
      <!-- Total Objective Count -->
      <td class="text-center" *ngIf="columnToggle.getColumnEnabled(eTableColumn.TOTAL_OBJECTIVES)" width="130px">
        <ng-container *ngIf="report.goalsCount > 0; else noGoalsInfo">
          <span [title]="report.objectiveCount">{{report.objectiveCount}}</span>
        </ng-container>
        <ng-template #noGoalsInfo>
          <span class="text-nowrap">This person has no goals</span>
        </ng-template>
      </td>
  
      <!-- Average Completion -->
      <td class="text-nowrap" *ngIf="columnToggle.getColumnEnabled(eTableColumn.AVERAGE_COMPLETION)" width="190px">
        <ng-container *ngIf="report.goalsCount > 0">
          <div class="progress m-bottom-0 min-width-progress d-inline-block">
            <div class="progress-bar progress-bar-info" [style.width]="report.averageCompletion+'%'">
              <span class="sr-only"></span>
            </div>
          </div>
          <div class="m-left-10 m-right-5 d-inline-block">{{report.averageCompletion}}%</div>
        </ng-container>
      </td>
  
      <!-- Actions -->
      <td class="text-center action-icons" *ngIf="columnToggle.getColumnEnabled(eTableColumn.ACTIONS)" width="140px">
        <span (click)="openUserGoals(report)" class="fal fa-bullseye frankli-view expand-on-hover" *ngIf="report.goalsCountSelf > 0"
          [tooltip]="'View ' + report.user.firstName + '\'s goals'" placement="left" container="body"
          angulartics2On="click" angularticsAction="TeamGoalsViewUser" angularticsCategory="Support">
        </span>
      </td>
    </tr>
  
    <!-- Dropdown contents -->
    <tr *ngIf="report.dropdownOpen && !columnToggle.checkAllDisabled() && (report.directReports.length > 0)">
      <td colspan="50">
        <ng-container *ngIf="(report.directReports.length > 0); else noGoals">
          <div class="scroll-x">
            <table width="100%" class="goals-subtable">
              <ng-container *ngFor="let report of report.directReports; trackBy: trackByFn;" [ngTemplateOutlet]="tableRow" [ngTemplateOutletContext]="{report : report, sub: (sub+1)}"></ng-container>
            </table>
          </div>
        </ng-container>
        <ng-template #noGoals>
          <span class="m-left-20">This person does not have any goals</span>
        </ng-template>
      </td>
    </tr>
  </ng-container>
</ng-template>
<!-- #endregion -->

<!-- #region - User goals modal -->
<app-modal #userGoalsModal [background]="'light'" [width]="'80%'">
  <div class="modal-body-content">
    <ng-container *ngIf="viewingUser">
      <!-- Stats -->
      <div class="goal-modal-stats">
        <app-goal-stats [goals]="viewingUser.goals" [progInclComplete]="true"></app-goal-stats>
      </div>
  
      <!-- Table -->
      <div class="goal-modal-table">
        <app-goals-all-table-component [goals]="viewingUser.goals" (navigatedToGoal)="closeGoalModal()" [showUniversalFilter]="true" [stickyPagination]="true" #userGoalsTable></app-goals-all-table-component>
      </div>
    </ng-container>
  </div>
</app-modal>
<!-- #endregion -->