import { TitleCasePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CompanyWording } from '@app/models/company/company-wording/company-wording.model';
import { CreateGoalDraftKeyResultDto } from '@app/models/goals/create-goal-draft-key-result.dto';
import { CreateGoalDraftDto } from '@app/models/goals/create-goal-draft.dto';
import { CreateGoalKeyResultDto } from '@app/models/goals/create-goal-key-result.dto';
import { CreateGoalDto } from '@app/models/goals/create-goal.dto';
import { GoalDraft } from '@app/models/goals/goal-draft.model';
import { GoalKeyResultMeasureUnitPlacement } from '@app/models/goals/goal-key-result-measure-unit-placement.model';
import { FrankliValidators } from '@app/shared/validators/validators';
import { CompanyFeatures, OfficeLocation } from 'app/models/company.model';
import { GoalKeyResult } from 'app/models/goals/goal-key-result.model';
import { GoalTag } from 'app/models/goals/goal-tag.model';
import { GoalTemplate } from 'app/models/goals/goal-template.model';
import { Goal, GoalPriority, GoalStatus, GoalType, GoalVisibility } from 'app/models/goals/goal.model';
import { OrganisationalUnit } from 'app/models/organisational-unit.model';
import { RoleName } from 'app/models/role.model';
import { User } from 'app/models/user/user.model';
import { CompanyAPIService } from 'app/shared/api/company.api.service';
import { GoalsAPIService } from 'app/shared/api/goals.api.service';
import { UserAPIService } from 'app/shared/api/user.api.service';
import { Globals } from 'app/shared/globals/globals';
import * as moment from 'moment-timezone';
import { forkJoin, Observable, Subject } from 'rxjs';
import { debounceTime, finalize, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { GoalCreateService } from './goals-create.service';

declare var $: any;
@Component({
  selector: 'app-goals-create',
  templateUrl: './goals-create.component.html',
  styleUrls: ['./goals-create.component.css']
})
export class GoalsCreateComponent implements OnInit, OnDestroy {
  @Input() modal !: any;
  @Input() mode: 'CREATE' | 'EDIT_DRAFT' = 'CREATE';
  @Input() goal !: Goal;
  @Input() draftGoal: GoalDraft | null = null;
  @Input() userId: number | null = null;
  @Input() startType: GoalType | null = null;

  private readonly ngUnsubscribe$: Subject<void> = new Subject<void>();
  eFeature = CompanyFeatures;

  eRoleName = RoleName;
  eGoalType = GoalType;
  eGoalVisibility = GoalVisibility;
  eGoalPriority = GoalPriority;
  eGoalStatus = GoalStatus;

  valuesGoalType: Array<any> = [];
  valuesGoalPriority = GoalPriority;
  valuesGoalVisibility: Array<any> = [];
  alignedGoals: Array<Goal>;

  tagFilter = {
    value: '',
    all: new Array<GoalTag>(),
    filtered: new Array<GoalTag>()
  };

  users: User[];
  ownerFilter = {
    value: '',
    all: new Array<User>(),
    filtered: new Array<User>()
  };

  departments: OrganisationalUnit[];
  officeLocations: OfficeLocation[];
  goals: Goal[];
  alignmentLoading: boolean;

  tagsSelected: GoalTag[];
  ownersSelected: User[];

  templates: GoalTemplate[];
  templatesTitle: string[];
  templatesResult: string[];

  form!: FormGroup;

  submitted = false;
  createLoading = false;
  saveLoading = false;

  loading = true;
  error = false;

  minEndDate: moment.Moment;
  companyWording: CompanyWording;

  constructor(
    private formBuilder: FormBuilder,
    private goalsAPIService: GoalsAPIService,
    private userAPIService: UserAPIService,
    private companyAPIService: CompanyAPIService,
    public globals: Globals,
    private goalCreateService: GoalCreateService, 
    private titleCasePipe: TitleCasePipe
  ) {
    this.companyWording = this.globals.company.companyWording;

    this.minEndDate = moment();

    this.departments = [];
    this.goals = [];
    this.users = [];
    this.alignedGoals = [];
    this.officeLocations = []
    this.templates = [];
    this.templatesTitle = [];
    this.templatesResult = [];
    this.tagsSelected = [];
    this.ownersSelected = [];
    this.alignmentLoading = true;
    this.initForm();
  }

  ngOnInit() {
    this.resetForm();
    this.goalCreateService.getReset().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(got => this.resetForm());
    this.goalCreateService.getGoalCopied().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(goal => this.onCopy(goal));
    this.goalCreateService.getGoalTemplateCopied().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(template => this.onCopyTemplate(template));
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }

  initForm() {
    this.form = this.formBuilder.group({
      goalTitle: ['', [Validators.required, Validators.maxLength(255)]],
      goalType: [null, Validators.required],
      goalDepartment: [null],
      goalOfficeLocation: [null],
      goalAlignment: [null],
      developmentNeeds: ['', Validators.maxLength(255)],
      endDate: ['', Validators.required],
      visibility: [null, Validators.required],
      priority: ['', Validators.required],
      keyResults: this.formBuilder.array([ // FormArray of FormGroups
        this.initKeyResult()
      ]),
      distribute: [false, []]
    }, { validators: this.departmentValidator() });

    this.form.controls.goalTitle.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(e => {
      const template = this.templates.find(t => t.title === this.form.controls.goalTitle.value.trim());
      if (template !== undefined) {
        this.templatesResult = template.results.map(t => t.title);
      }
    });

    this.form.controls.goalType.valueChanges.pipe(takeUntil(this.ngUnsubscribe$), debounceTime(250)).subscribe(e => {
      // Visibility
      if (
        this.form.controls.goalType.value === GoalType.PERSONAL_OPERATIONAL
        || this.form.controls.goalType.value === GoalType.PERSONAL_DEVELOPMENTAL
        || this.form.controls.goalType.value === GoalType.TEAM
        || this.form.controls.goalType.value === GoalType.COMPANY
      ) {
        this.setVisibility([GoalVisibility.PUBLIC, GoalVisibility.PRIVATE]);
        // Visibility Draft
        if (this.mode === 'EDIT_DRAFT' && this.draftGoal !== null) {
          this.form.controls.visibility.setValue(this.draftGoal.visibility);
        } else {
          this.form.controls.visibility.setValue(null);
        }
      } else if (
        this.form.controls.goalType.value === GoalType.DEPARTMENT
        || this.form.controls.goalType.value === GoalType.OFFICE_LOCATION
      ) {
        this.setVisibility([GoalVisibility.PUBLIC]);
        this.form.controls.visibility.setValue(GoalVisibility.PUBLIC);
      }

      // Department
      if (this.form.controls.goalType.value === GoalType.DEPARTMENT) {
        let d: number | null = null;
        if (this.mode === 'EDIT_DRAFT' && this.draftGoal !== null) {
          if (this.draftGoal.department !== null) {
            if (this.departments.some(department => department.id === this.draftGoal!.department!.id)) {
              d = this.draftGoal.department.id;
            }
          }
        } else {
          if (this.globals.user.organisationalUnit !== null) {
            if (this.departments.some(department => department.id === this.globals.user.organisationalUnit!.id)) {
              d = this.globals.user.organisationalUnit.id;
            }
          }
        }
        this.form.controls.goalDepartment.setValue(d);
      }

      // Office Location
      if (this.form.controls.goalType.value === GoalType.OFFICE_LOCATION) {
        let o: number | null = null;
        if (this.mode === 'EDIT_DRAFT' && this.draftGoal !== null) {
          if (this.draftGoal.officeLocation !== null) {
            o = this.draftGoal.officeLocation.id!;
          }
        } else {
          if (this.globals.user.officeLocation !== null) {
            o = this.globals.user.officeLocation.id!;
          }
        }
        this.form.controls.goalOfficeLocation.setValue(o);
      }

      // Owners
      if (this.form.controls.goalType.value === GoalType.PERSONAL_DEVELOPMENTAL
        || this.form.controls.goalType.value === GoalType.PERSONAL_OPERATIONAL) {

        // // if user not in list, add them
        const included = this.ownersSelected.some(u => u.id === this.globals.user.id);
        if (included === false && this.globals.hasRole(RoleName.GOAL_COMPANY) === false) {
          const user = this.users.find(u => u.id === this.globals.user.id);
          if (user !== undefined) {
            this.addOwner(user);
          }
        }

      }

      // Alignment

      /*
        PERSONAL_DEVELOPMENTAL - none
        PERSONAL_OPERATIONAL - TEAM, DEPARTMENT, OFFICE_LOCATION, COMPANY
        TEAM - DEPARTMENT, OFFICE_LOCATION, COMPANY
        DEPARTMENT - OFFICE_LOCATION, COMPANY
        OFFICE_LOCATION - DEPARTMENT, COMPANY
        COMPANY - none
      */

      const subscriptions: Array<Observable<Array<Goal>>> = new Array<Observable<Array<Goal>>>();
      let alignmentSubscription: Observable<Goal> | null = null;
      this.alignmentLoading = true;

      if (this.mode === 'EDIT_DRAFT' && this.draftGoal !== null) {
        this.form.controls.goalAlignment.setValue(this.draftGoal.alignment);
        if (this.draftGoal.alignment !== null) {
          alignmentSubscription = this.goalsAPIService.getGoalById(this.draftGoal.alignment);
        }
      } else {
        this.form.controls.goalAlignment.setValue(null);
      }

      if (
        this.form.controls.goalType.value === GoalType.PERSONAL_OPERATIONAL
        || this.form.controls.goalType.value === GoalType.PERSONAL_DEVELOPMENTAL
      ) {
        subscriptions.push(this.goalsAPIService.getTeamGoalsActive());
        subscriptions.push(this.goalsAPIService.getDepartmentGoalsActive());
        if (this.globals.hasFeature(CompanyFeatures.GOAL_OFFICE_LOCATION)) {
          subscriptions.push(this.goalsAPIService.getOfficeLocationGoalsActive());
        }
        subscriptions.push(this.goalsAPIService.getCompanyGoalsActive());
      } else if (this.form.controls.goalType.value === GoalType.TEAM) {
        subscriptions.push(this.goalsAPIService.getDepartmentGoalsActive());
        if (this.globals.hasFeature(CompanyFeatures.GOAL_OFFICE_LOCATION)) {
          subscriptions.push(this.goalsAPIService.getOfficeLocationGoalsActive());
        }
        subscriptions.push(this.goalsAPIService.getCompanyGoalsActive());
      } else if (this.form.controls.goalType.value === GoalType.DEPARTMENT) {
        if (this.globals.hasFeature(CompanyFeatures.GOAL_OFFICE_LOCATION)) {
          subscriptions.push(this.goalsAPIService.getOfficeLocationGoalsActive());
        }
        subscriptions.push(this.goalsAPIService.getCompanyGoalsActive());
      } else if (this.form.controls.goalType.value === GoalType.OFFICE_LOCATION) {
        subscriptions.push(this.goalsAPIService.getDepartmentGoalsActive());
        subscriptions.push(this.goalsAPIService.getCompanyGoalsActive());
      }

      if (subscriptions.length > 0) {
        forkJoin(subscriptions).pipe(takeUntil(this.form.controls.goalType.valueChanges)).subscribe((response) => {
          this.goals = new Array<Goal>().concat(...response); // flatten array of arrays of goals

          // If aligned goal hasn't already been pulled in
          if (alignmentSubscription !== null && !this.goals.some(g => g.id === this.draftGoal!.alignment)) {

            alignmentSubscription.pipe(finalize(() => {
              // If aligned goal isn't in list then it isn't valid so remove
              if (this.form.controls.goalAlignment.value !== null && !this.goals.some(g => {
                return g.id === this.form.controls.goalAlignment.value;
              })) {
                this.form.controls.goalAlignment.setValue(null);
              }

              if (this.goals.length === 0) {
                this.form.controls.goalAlignment.disable();
              } else {
                this.form.controls.goalAlignment.enable();
              }

              this.alignmentLoading = false;
            })).subscribe(alignedGoal => {
              this.goals.unshift(alignedGoal);
            }, (failure: HttpErrorResponse) => {
            });
          } else {
            // If aligned goal isn't in list then it isn't valid so remove
            if (this.form.controls.goalAlignment.value !== null && !this.goals.some(g => {
              return g.id === this.form.controls.goalAlignment.value;
            })) {
              this.form.controls.goalAlignment.setValue(null);
            }

            if (this.goals.length === 0) {
              this.form.controls.goalAlignment.disable();
            } else {
              this.form.controls.goalAlignment.enable();
            }

            this.alignmentLoading = false;
          }

        }, (failure: HttpErrorResponse) => {
          // TODO: failure state
          this.alignmentLoading = false;
        });
      } else {
        this.alignmentLoading = false;
      }

    });

  }

  initKeyResult(): FormGroup {
    return this.formBuilder.group({
      id: ['', []],
      result: ['', [Validators.required, Validators.maxLength(255)]],
      type: [null, [Validators.required]],
      measureStartValue: ['', [Validators.required, Validators.pattern(/^(\d{1,22})(.\d{1,2})?$/)]],
      measureGoalValue: ['', [Validators.required, Validators.pattern(/^(\d{1,22})(.\d{1,2})?$/)]],
      endDate: ['', []],
      measureUnit: ['', []],
      measureUnitPlacement: [GoalKeyResultMeasureUnitPlacement.BEFORE, []]
    }, {
        validator: FrankliValidators.goalCompletionValidator,
      });
  }

  onCopy(goal: Goal) {
    this.form.controls.goalTitle.setValue(goal.title);
    this.form.controls.priority.setValue(goal.priority);

    if (this.canSetGoalType(goal.type)) {
      this.form.controls.goalType.setValue(goal.type);
    }

    this.form.controls.visibility.setValue(goal.visibility.toString());

    // Get an array of formGroups
    const keyResultControls = goal.keyResults.map(kr => this.initKeyResult());

    // Replace existing with a new FormArray made of the above FormGroups
    this.form.controls.keyResults = this.formBuilder.array(keyResultControls);

    // Populate key results with values from copied goal
    const formArray = this.form.controls.keyResults as FormArray;
    goal.keyResults.forEach((kr, i) => {
      const formGroup = formArray.controls[i] as FormGroup;

      setTimeout(() => {
        formGroup.controls.result.setValue(kr.result);
        formGroup.controls.type.setValue(kr.type);
        formGroup.controls.measureStartValue.setValue(kr.measureStartValue);
        formGroup.controls.measureGoalValue.setValue(kr.measureGoalValue);
        formGroup.controls.measureUnit.setValue(kr.measureUnit);
        formGroup.controls.measureUnitPlacement.setValue(kr.measureUnitPlacement);
      }, 250);
    });
  }

  onCopyTemplate(template: GoalTemplate) {
    this.form.controls.goalTitle.setValue(template.title);

    // Get an array of formGroups
    const keyResultControls = template.results.map(kr => this.initKeyResult());

    // Replace existing with a new FormArray made of the above FormGroups
    this.form.controls.keyResults = this.formBuilder.array(keyResultControls);

    // Populate key results with values from copied goal
    const formArray = this.form.controls.keyResults as FormArray;
    template.results.forEach((r, i) => {
      const formGroup = formArray.controls[i] as FormGroup;
      setTimeout(() => {
        formGroup.controls.result.setValue(r.title);
        formGroup.controls.type.setValue(r.type);
        formGroup.controls.measureUnit.setValue(r.unit);
        formGroup.controls.measureUnitPlacement.setValue(r.unitPlacement);

      }, 250);
    });
  }

  canSetGoalType(type: GoalType): boolean {
    switch (type) {
      case GoalType.COMPANY:
        return this.globals.hasRole(RoleName.GOAL_COMPANY);
      case GoalType.DEPARTMENT:
        return (!!this.globals.user.organisationalUnit);
      case GoalType.OFFICE_LOCATION:
        return (this.globals.user.officeLocation && this.globals.hasFeature(CompanyFeatures.GOAL_OFFICE_LOCATION))
      default:
        return true;
    }

    return false;
  }

  // TODO: This is verbose and could probably resort to using Reactive Forms for default values in most cases
  resetForm(): void {
    this.submitted = false;
    this.createLoading = false;
    this.saveLoading = false;
    this.loading = true;

    this.tagFilter.value = '';
    this.tagFilter.all = new Array<GoalTag>();
    this.tagFilter.filtered = new Array<GoalTag>();
    this.tagsSelected = new Array<GoalTag>();

    this.ownerFilter.value = '';
    this.ownerFilter.all = new Array<User>();
    this.ownerFilter.filtered = new Array<User>();
    this.ownersSelected = new Array<User>();

    this.valuesGoalVisibility = new Array<any>();
    this.valuesGoalVisibility.push(GoalVisibility.PUBLIC);
    this.valuesGoalVisibility.push(GoalVisibility.PRIVATE);

    // Add goal types to the list
    this.valuesGoalType = new Array<any>();
    this.valuesGoalType.push({
      value: GoalType.PERSONAL_OPERATIONAL,
      name: 'Personal Operational'
    });
    this.valuesGoalType.push({
      value: GoalType.PERSONAL_DEVELOPMENTAL,
      name: 'Personal Developmental'
    });
    this.valuesGoalType.push({
      value: GoalType.TEAM,
      name: this.companyWording.team
    });

    // If user has an office location or is a goal admin AND Site goals are enabled
    if ((this.globals.user.officeLocation !== null || this.globals.user.roles.some(role => role.name === RoleName.GOAL_COMPANY)) && this.globals.hasFeature(CompanyFeatures.GOAL_OFFICE_LOCATION)) {
      this.valuesGoalType.push({
        value: GoalType.OFFICE_LOCATION,
        name: 'Site'
      });
    }

    // if user has a department or is a goal admin
    if (this.globals.user.organisationalUnit !== null || this.globals.user.roles.some(role => role.name === RoleName.GOAL_COMPANY)) {
      this.valuesGoalType.push({
        value: GoalType.DEPARTMENT,
        name: this.companyWording.department
      });
    }

    // if user is a goal admin
    if (this.globals.user.roles.some(role => role.name === RoleName.GOAL_COMPANY)) {
      this.valuesGoalType.push({
        value: GoalType.COMPANY,
        name: 'Company'
      });
    }

    this.form.controls.endDate.setValue(null);
    // end - Dates

    this.form.controls.visibility.setValue(null);
    this.form.controls.priority.setValue(GoalPriority.NOT_SET);
    this.form.controls.goalType.setValue(this.startType);

    forkJoin([
      this.goalsAPIService.getTags(),
      this.userAPIService.getAllUsers(),
      this.companyAPIService.getOrgByType('Department'),
      this.companyAPIService.getAllOfficeLocations(),
      this.goalsAPIService.getGoalTemplates()
    ]).subscribe(([tags, users, orgs, officeLocations, templates]) => {
      // tags
      this.tagFilter.all = new Array<GoalTag>();
      tags.forEach(t => {
        this.tagFilter.all.push(t);
      });
      this.tagsSelected = [];

      // user list
      this.users = users;
      this.ownersSelected = [];

      this.ownerFilter.all = new Array<User>();
      this.users.forEach(u => {
        this.ownerFilter.all.push(u);

        if (this.mode !== 'EDIT_DRAFT') {
          if (u.id === this.globals.user.id) {
            this.addOwner(u);
          }
        }
      });

      // departments
      this.departments.length = 0;
      orgs.forEach(o => {
        this.departments.push(o);
      });

      // office locations
      this.officeLocations = [];
      officeLocations.forEach(o => {
        this.officeLocations.push(o);
      });

      this.templates = templates;
      this.templatesTitle = templates.map(t => t.title);

      if (this.mode === 'EDIT_DRAFT') {
        this.resetFormDraft();
        this.loading = false;
      } else {
        // if creating goal for a specific user
        if (this.userId !== null) {
          // alert(this.userId);
          this.form.controls.goalType.setValue(GoalType.PERSONAL_OPERATIONAL);
          this.ownersSelected.forEach(o => this.removeOwner(o));
          const user = this.users.find(u => u.id === this.userId);
          if (user !== undefined) {
            this.addOwner(user);
          }
        }
        this.loading = false;
      }

    }, (failure: HttpErrorResponse) => {
      console.log(failure.error);
      this.error = true;
      this.loading = false;
    });


  }

  resetFormDraft(): void {
    if (this.draftGoal !== null) {

      // title
      if (this.draftGoal.title !== null) {
        this.form.controls.goalTitle.setValue(this.draftGoal.title);
      }

      // type
      if (this.draftGoal.type !== null) {
        this.form.controls.goalType.setValue(this.draftGoal.type);

        if (this.draftGoal.type === GoalType.COMPANY) {
          if (!this.valuesGoalType.some(type => type.value === GoalType.COMPANY)) {
            this.form.controls.goalType.setValue(null);
          }
        } else if (this.draftGoal.type === GoalType.DEPARTMENT && this.draftGoal.department !== null) {
          this.form.controls.goalDepartment.setValue(this.draftGoal.department.id)
        }
      }

      this.form.controls.endDate.setValue(this.draftGoal.endDate);

      this.form.controls.visibility.setValue(this.draftGoal.visibility);

      if (this.draftGoal.priority !== null) {
        this.form.controls.priority.setValue(this.draftGoal.priority);
      }

      if (this.draftGoal.developmentNeeds !== null) {
        this.form.controls.developmentNeeds.setValue(this.draftGoal.developmentNeeds);
      }

      this.ownerFilter.all.forEach(o => {
        this.draftGoal!.owners.forEach(g => {
          if (o.id === g.id) {
            this.addOwner(o);
          }
        });
      });

      this.tagFilter.all.forEach(t => {
        this.draftGoal!.tags.forEach(g => {
          if (t.id === g.id) {
            this.addTag(t);
          }
        });
      });

      if (this.draftGoal.keyResults.length > 0) {
        // Get an array of formGroups
        const keyResultControls = this.draftGoal.keyResults.map(kr => this.initKeyResult());

        // Replace existing with a new FormArray made of the above FormGroups
        this.form.controls.keyResults = this.formBuilder.array(keyResultControls);

        // Populate key results with values from copied goal
        const formArray = this.form.controls.keyResults as FormArray;
        this.draftGoal.keyResults.forEach((kr, i) => {
          const formGroup = formArray.controls[i] as FormGroup;
          setTimeout(() => {
            formGroup.controls.id.setValue(kr.id);
            formGroup.controls.result.setValue(kr.result);
            formGroup.controls.type.setValue(kr.type);
            formGroup.controls.measureStartValue.setValue(kr.measureStartValue);
            formGroup.controls.measureGoalValue.setValue(kr.measureGoalValue);
            formGroup.controls.endDate.setValue(kr.endDate);
            formGroup.controls.measureUnit.setValue(kr.measureUnit);
            formGroup.controls.measureUnitPlacement.setValue(kr.measureUnitPlacement);
          }, 250);
        });
      } else {
        this.form.controls.keyResults = this.formBuilder.array([this.initKeyResult()]);
      }
    }
  }

  addKeyResult(): void {
    const control = <FormArray>this.form.controls.keyResults;
    control.push(this.initKeyResult());
  }

  editAddKeyResult(result: GoalKeyResult): void {
    const control = <FormArray>this.form.controls.keyResults;

    const f = this.initKeyResult();

    f.controls.id.setValue(result.id);
    f.controls.result.setValue(result.result);
    f.controls.type.setValue(result.type);
    f.controls.measureStartValue.setValue(result.measureStartValue);
    f.controls.measureGoalValue.setValue(result.measureGoalValue);
    f.controls.endDate.setValue(result.endDate);

    control.push(f);
  }

  removeKeyResult(i: number): void {
    const control = <FormArray>this.form.controls.keyResults;
    control.removeAt(i);
  }

  setVisibility(visibility: Array<GoalVisibility>): void {
    while (this.valuesGoalVisibility.length) {
      this.valuesGoalVisibility.pop();
    }
    visibility.forEach(v => {
      this.valuesGoalVisibility.push(v);
    })
  }

  getKeyResultsFromForm(): Array<GoalKeyResult> {
    const results = new Array<GoalKeyResult>();
    // converting form array to itterate of the form groups to key out each goalKeyResult
    const formArray = <FormArray>this.form.controls.keyResults;
    formArray.controls.forEach(i => {
      const formGroup = <FormGroup>i;
      const goalKeyResult = new GoalKeyResult();
      goalKeyResult.id = formGroup.controls.id.value
      goalKeyResult.result = formGroup.controls.result.value;
      goalKeyResult.type = formGroup.controls.type.value;
      goalKeyResult.measureStartValue = formGroup.controls.measureStartValue.value;
      goalKeyResult.measureCurrentValue = formGroup.controls.measureStartValue.value;
      goalKeyResult.measureGoalValue = formGroup.controls.measureGoalValue.value;
      goalKeyResult.measureUnit = formGroup.controls.measureUnit.value;
      goalKeyResult.measureUnitPlacement = formGroup.controls.measureUnitPlacement.value;
      // TODO: Change type from Date to Date | null
      goalKeyResult.endDate = this.formDateToDateObject(formGroup.controls.endDate);
      results.push(goalKeyResult);
    });

    return results;
  }

  formDateToDateObject(control: AbstractControl): Date | null {
    let date = control.value;
    if (date !== null) {
      // ensure date is in utc format to avoid timezone issues
      date = moment.utc(date).toDate();
    }

    return date;
  }

  addTag(tag: GoalTag): void {
    if (!this.tagsSelected.includes(tag)) {
      // add tag to selected
      this.tagsSelected.push(tag);
      // remove tag from list
      this.tagFilter.all = this.tagFilter.all.filter(t => t.id !== tag.id);
      this.clearTagFilter();
    }
  }

  removeTag(tag: GoalTag): void {
    // add tag to all
    this.tagFilter.all.push(tag);
    // remove from selected
    this.tagsSelected = this.tagsSelected.filter(t => {
      return t !== tag;
    });
    this.filterTags(this.tagFilter.value);
  }

  filterTags(e: any): void {
    if (this.tagFilter.value.trim() === '') {
      this.tagFilter.filtered = [];
    } else {
      this.tagFilter.filtered = this.tagFilter.all.filter(tag => {
        return tag.text.toLocaleLowerCase().includes(e.toLocaleLowerCase());
      }).sort((a, b) => {
        return +(a.text.toLocaleLowerCase() > b.text.toLocaleLowerCase())
      });
    }
  }

  clearTagFilter(): void {
    this.tagFilter.value = '';
    this.filterTags(this.tagFilter.value);
  }

  addOwner(user: User) {
    if (!this.ownersSelected.includes(user)) {
      this.ownersSelected.push(user);

      this.ownerFilter.all = this.ownerFilter.all.filter(o => o.id !== user.id);

      this.clearOwnerFilter();
    }
  }

  /**
   * Removes a user from `ownerSelected` and adds the user `ownerFilter.all`
   */
  removeOwner(user: User) {
    this.ownersSelected = this.ownersSelected.filter(u => {
      return u !== user;
    });
    this.ownerFilter.all.push(user);
    this.filterOwners();
  }

  filterOwners(): void {
    if (this.ownerFilter.value.trim() === '') {
      this.ownerFilter.filtered = [];
    } else {
      this.ownerFilter.filtered = User.sort.byFullName(
        this.ownerFilter.all.filter(u => {
          const name = (u.firstName + ' ' + u.lastName);
          if (name) {
            return name.toLocaleLowerCase().includes(this.ownerFilter.value.toLocaleLowerCase())
          }

          return false;
        })
      );
    }
  }

  clearOwnerFilter() {
    this.ownerFilter.value = '';
    this.filterOwners();
  }

  saveGoal() {
    this.submitted = true;
    if (this.isValid()) {
      this.createLoading = true;

      const goal = new Goal();
      goal.title = this.form.controls.goalTitle.value;
      goal.type = this.form.controls.goalType.value;
      if (goal.type === this.eGoalType.DEPARTMENT) {
        let department = null;

        this.departments.forEach(d => {
          if (d.id === this.form.controls.goalDepartment.value) {
            department = d;
          }
        })
        goal.department = department;
      } else {
        goal.department = null;
      }

      if (goal.type === this.eGoalType.OFFICE_LOCATION) {
        let officeLocation = null;

        this.officeLocations.forEach(o => {
          if ((o.id + '') === (this.form.controls.goalOfficeLocation.value + '')) {
            officeLocation = o;
          }
        })
        goal.officeLocation = officeLocation;
      } else {
        goal.officeLocation = null;
      }

      goal.endDate = this.formDateToDateObject(this.form.controls.endDate)!;
      goal.visibility = this.form.controls.visibility.value;
      goal.priority = this.form.controls.priority.value.toUpperCase();
      goal.tags = this.tagsSelected;

      goal.alignment = this.form.controls.goalAlignment.value;
      goal.developmentNeeds = this.form.controls.developmentNeeds.value;

      goal.keyResults = this.getKeyResultsFromForm();

      goal.owners = this.ownersSelected;

      goal.complete = false;
      goal.archived = false;


      // build request
      let request = null;
      let successMessage: string | null = null;
      if (this.mode === 'CREATE') {
        console.log(goal.department);
        const createGoalDto = new CreateGoalDto(
          goal.title,
          goal.type,
          goal.department === null ? null : goal.department.id,
          goal.officeLocation === null ? null : goal.officeLocation.id,
          goal.endDate,
          goal.visibility,
          goal.priority,
          goal.tags.map(t => t.id),
          goal.owners.map(o => o.id),
          this.getKeyResultsFromForm().map(r => {
            return new CreateGoalKeyResultDto(
              r.result,
              r.endDate,
              r.type,
              r.measureGoalValue,
              r.measureStartValue,
              r.measureUnit,
              r.measureUnitPlacement              
            );
          }),
          goal.alignment,
          goal.developmentNeeds
        );
        console.log(createGoalDto);
        request = this.goalsAPIService.createGoal(createGoalDto);
        successMessage = 'Your goal has been successfully created';
      } else if (this.mode === 'EDIT_DRAFT' && this.draftGoal !== null) {
        request = this.goalsAPIService.createGoalFromDraftGoal(this.draftGoal.id!, goal);
        successMessage = 'Your goal has been successfully created';
      }

      if (request !== null) {
        request.subscribe(response => {
          $.notify(successMessage);
          this.goalCreateService.sendGoalCreated(response);
          this.goalCreateService.sendGoalUpdated(response);
          this.resetForm();
          this.modal.hide();
        }, (_failure: HttpErrorResponse) => {
          this.createLoading = false;
        });
      }
    }
  }

  saveDraft() {
    this.saveLoading = true;

    const title = this.form.controls.goalTitle.value;

    let type = null;
    if (this.form.controls.goalType.value !== null) {
      type = this.form.controls.goalType.value.toUpperCase();
    }

    let department = null;
    if (type === GoalType.DEPARTMENT) {
      this.departments.forEach(d => {
        if (d.id === this.form.controls.goalDepartment.value) {
          department = d;
        }
      })
    }

    let officeLocation = null;
    if (type === GoalType.OFFICE_LOCATION) {
      this.officeLocations.forEach(o => {
        if ((o.id + '') === (this.form.controls.goalOfficeLocation.value + '')) {
          officeLocation = o;
        }
      })
    }

    const endDate = this.formDateToDateObject(this.form.controls.endDate);
    const goalPriority = this.form.controls.priority.value.toUpperCase();
    const goalVisibility = this.form.controls.visibility.value;
    const tags = this.tagsSelected;

    // strip out all key results with no name
    const keyResults = this.getKeyResultsFromForm().filter(keyResult => keyResult.result.trim() !== '');

    let validResults = true;
    for (let i = 0; i < keyResults.length; i++) {
      if (isNaN(keyResults[i].measureStartValue) || keyResults[i].measureStartValue.toString().trim().length === 0) {
        validResults = false;
      } else {
        if (keyResults[i].measureStartValue % 1 !== 0) {
          validResults = false;
        }
      }

      if (isNaN(keyResults[i].measureGoalValue) || keyResults[i].measureGoalValue.toString().trim().length === 0) {
        validResults = false;
      } else {
        if (keyResults[i].measureGoalValue % 1 !== 0) {
          validResults = false;
        }
      }
    }

    if (validResults === false) {
      Swal.fire({
        title: 'Key result targets not valid',
        text: 'Target values must be a valid whole number',
        imageUrl: 'assets/img/swal-icons/Frankli_cancel_icon-45.svg',
        imageWidth: 140,
        imageHeight: 140,
        confirmButtonColor: '#54c6bb'
      });
      this.saveLoading = false;
    } else {
      const owners = this.ownersSelected;
      const alignment = this.form.controls.goalAlignment.value;
      const developmentNeeds = this.form.controls.developmentNeeds.value;

      console.log(this.mode);
      console.log(this.draftGoal);
      if (this.mode === 'EDIT_DRAFT' && this.draftGoal !== null) {
        const goalDraft = new GoalDraft(
          null,
          title,
          type,
          department,
          officeLocation,
          endDate,
          goalVisibility,
          goalPriority,
          tags,
          keyResults,
          owners,
          alignment,
          developmentNeeds
        );

        goalDraft.id = this.draftGoal.id;
        if (goalDraft.id !== null) {
          this.goalsAPIService.updateDraftGoalById(goalDraft.id, goalDraft).subscribe(response => {
            $.notify('Your draft has been successfully updated');
            this.goalCreateService.sendGoalSaved(response);
            this.resetForm();
            this.modal.hide();
          }, (_failure: HttpErrorResponse) => {
            this.saveLoading = false;
          });
        }
      } else {
        const createGoalDraftDto = new CreateGoalDraftDto(
          title,
          type,
          department === null ? null : department.id,
          officeLocation === null ? null : officeLocation.id,
          endDate,
          goalVisibility,
          goalPriority,
          tags.map(t => t.id),
          owners.map(o => o.id),
          this.getKeyResultsFromForm().map(r => {
            return new CreateGoalDraftKeyResultDto(
              r.result,
              r.endDate,
              r.type,
              r.measureGoalValue,
              r.measureStartValue,
              r.measureUnit,
              r.measureUnitPlacement              
            );
          }),
          alignment,
          developmentNeeds
        );
        console.log(createGoalDraftDto)
        this.goalsAPIService.createDraftGoal(createGoalDraftDto).subscribe(response => {
          $.notify('Your draft has been successfully created');
          this.modal.hide();
        }, (_failure: HttpErrorResponse) => {
          this.saveLoading = false;
        });
      }
    }
  }

  isValid() {
    if (this.form.valid === true) {
      if (
        this.form.controls.visibility.value !== null
        && this.form.controls.goalType.value !== null
        && (<FormArray>this.form.controls.keyResults).controls.length > 0
      ) {
        // check goal end date after key result end date

        if (this.validateEndDates() === false) {
          return false;
        }

        return this.isValidOwners();
      }
    }
    return false;
  }

  validateEndDates(): boolean {
    const results = (<FormArray>this.form.controls.keyResults).controls;
    for (let i = 0; i < results.length; i++) {
      const resultEndDate = results[i].value.endDate;
      const goalEndDate = this.form.controls.endDate.value;

      if (resultEndDate !== null) {
        const g = moment(goalEndDate);
        const r = moment(resultEndDate);

        if (g.diff(r, 'days') < 0) {
          return false;
        }
      }
    }
    return true;
  }

  isValidOwners(): boolean {
    if (this.ownersSelected.length > 0) {
      if (this.form.controls.goalType.value === GoalType.PERSONAL_OPERATIONAL || this.form.controls.goalType.value === GoalType.PERSONAL_DEVELOPMENTAL) {
        if (this.ownersSelected.some(u => u.id === this.globals.user.id) === true) {
          return true;
        } else if (this.ownersSelected.some(u => u.managerId === this.globals.user.id) === true) {
          return true;
        } else if (this.globals.hasRole(RoleName.GOAL_COMPANY)) {
          return true;
        }

      } else {
        return true;
      }
    }

    return false;
  }

  getGoalAlignmentInfoText(type: GoalType) {
    switch (type) {
      case GoalType.PERSONAL_DEVELOPMENTAL:
      case GoalType.PERSONAL_OPERATIONAL:
        return `Personal goals can be aligned to '${this.companyWording.team}' goals, 'Site' goals, '${this.companyWording.department}' goals and 'Company' goals`;
      case GoalType.TEAM:
        return `${this.titleCasePipe.transform(this.companyWording.team)} goals can be aligned to 'Site' goals, '${this.companyWording.department}' goals and 'Company' goals`;
      case GoalType.OFFICE_LOCATION:
        return `Site goals can be aligned to '${this.companyWording.department}' goals and 'Company' goals`;
      case GoalType.DEPARTMENT:
        return `${this.titleCasePipe.transform(this.companyWording.department)} goals can be aligned to \'Site\' goals and \'Company\' goals`;
      case GoalType.COMPANY:
        return 'Company goals cannot be aligned to other goals';
      default:
        return '';
    }
  }

  /**
   * Return true if the goal type is NOT a 'Department Goal' or the Goal Type IS 'Department Goal' and a valid department has been selected
   */
  departmentValidator = (): ValidatorFn => (control: AbstractControl): { [key: string]: any } | null => {
    const form = control as FormGroup;
    const goalType = form.controls.goalType as FormControl;
    const goalDepartment = form.controls.goalDepartment as FormControl;

    // If goal is not a department goal, return valid
    if (goalType.value !== GoalType.DEPARTMENT) {
      return null;
    }

    // Invalid if null
    if (goalDepartment.value === null) {
      return { departmentRequired: { value: goalDepartment.value } }
    }

    // Invalid if archived
    if (!this.departments.some(department => department.id === goalDepartment.value)) {
      return { departmentRequired: { value: goalDepartment.value } }
    }

    return null;
  }

}
