import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CompanyWording } from '@app/models/company/company-wording/company-wording.model';
import { FilterMethod, FilterOption } from '@app/models/universal-filter-option.model';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Globals } from '../globals/globals';

interface Category {
  name: string;
  group: string,
  matchingIDs: number[]; // TODO: Possbily allow any type here and allow property to be defined on @Input() to allow for basic objects to be passed in
  enabled: boolean;
  filterMethod: FilterMethod
}

interface DisplayCategory {
  key: string;
  name: string;
}

export interface DefaultFilter {
  [key: string]: string[];
}

export interface NestedOutputResult {
  nonMatchesIncluded: number[];
  tree: NestedOutput;
}
export interface NestedOutput { // Recursive object of IDs
  [id: string]: NestedOutput
}
@Component({
  selector: 'app-universal-filter',
  templateUrl: './universal-filter.component.html',
  styleUrls: ['./universal-filter.component.css']
})
export class UniversalFilterComponent implements OnInit, OnChanges, OnDestroy {
  @Input() placeholderText: string; // Placeholder text for the searchbox
  @Input() searchControl: FormControl; // Not required but can pass in a formcontrol if access is needed to it from parent
  @Input() filterOptions: FilterOption[]; // All data
  @Input() searchProps: string[]; // Used to exclude data from the dropdown that can still be searched (User names, goal titles, etc.)
  @Input() defaultFilters: DefaultFilter;

  @Output() resultEmit: EventEmitter<number[]>;
  @Output() resultEmitNested: EventEmitter<NestedOutputResult>;
  usingNestedChecks: boolean;

  result: number[];
  resultNested: NestedOutputResult;

  cachedSearchFilter: {
    search: string;
    filter: {
      [column: string]: Category[];
    };
  }

  categories: {
    [column: string]: Category[];
  };
  categoryKeys: DisplayCategory[];
  allIDs: number[];

  filtersSelected: number;

  firstTime: boolean;

  searchSubscription!: Subscription;

  notInNestedResultsIDs: number[];
  companyWording: CompanyWording;

  constructor(private globals: Globals) {
    this.companyWording = this.globals.company.companyWording;
    this.placeholderText = 'Search or filter';
    this.searchControl = new FormControl('', []);
    this.filterOptions = [];
    this.searchProps = [];
    this.categories = {};
    this.categoryKeys = [];
    this.allIDs = [];
    this.notInNestedResultsIDs = [];
    this.resultEmit = new EventEmitter<number[]>();
    this.resultEmitNested = new EventEmitter<NestedOutputResult>();
    this.result = [];
    this.cachedSearchFilter = {
      search: '',
      filter: {}
    }
    this.filtersSelected = 0;
    this.defaultFilters = {};
    this.firstTime = true;
    this.usingNestedChecks = false;
    this.resultNested = {
      nonMatchesIncluded: [],
      tree: {}
    };
  }

  ngOnInit() {
    // Listen for search value changing
    this.searchSubscription = this.searchControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(res => {
        this.doSearchAndFilter();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    const optionsChanges = changes['filterOptions'];
    if (optionsChanges) {
      if (optionsChanges.previousValue !== optionsChanges.currentValue) {
        this.init();
      }
    }
  }

  ngOnDestroy() {
    this.searchSubscription.unsubscribe();
  }

  init() {
    this.allIDs = this.getAllIds(this.filterOptions);
    this.mapData();
  }

  getAllIds(filterOptions: FilterOption[]): number[] {
    let output: number[] = [];

    filterOptions.forEach(fo => {
      output.push(fo.id);

      if (fo.nestedItems) {
        output = [...output, ...this.getAllIds(fo.nestedItems)];
      }
    });

    return output;
  }

  resetData() {
    for (const key in this.categories) {
      if (this.categories.hasOwnProperty(key)) {
        this.categories[key].forEach(v => {
          v.matchingIDs = [];
        })
      }
    }
  }

  mapData() {
    this.resetData();

    if (this.filterOptions.length > 0) {
      this.categoryKeys = this.getFilterProps(this.filterOptions[0]);
      this.usingNestedChecks = this.checkIfUsingNested(this.filterOptions);

      // Loop over each piece of data
      this.getCategoriesForOption(this.filterOptions);

      this.sortCategories();
      this.cachedSearchFilter.filter = this.categories;
      this.firstTime = false;

      this.doSearchAndFilter();
    }
  }

  getFilterProps(item: FilterOption) : DisplayCategory[] {
    return Object.keys(item.properties)
            .filter(k => (k !== 'id' && !this.searchProps.includes(k)))
            .map(k =>  { 
              return {key: k, name: k.toLowerCase() === 'department' ? this.companyWording.department : k} as DisplayCategory});
  }

  checkIfUsingNested(items: FilterOption[]) {
    return this.filterOptions.map(fo => fo.nestedItems).some(ni => ((ni !== undefined) && (ni !== null)));
  }

  getCategoriesForOption(filterOptions: FilterOption[]) {
    // Loop over all the search properties for current object
    // Get categories for nested items)

    if (filterOptions) {
      filterOptions.forEach(fo => {
        this.categoryKeys.forEach(key => this.addValuesFromFilterOption(fo, key.key));

        if (this.usingNestedChecks && fo.nestedItems && fo.nestedItems.length > 0) {
          this.getCategoriesForOption(fo.nestedItems);
        }
      });
    }
  }

  addValuesFromFilterOption(fo: FilterOption, key: string) {
    // Value of the prop - Set to 'None' if not defined
    const data = fo.properties[key];
    const value = ((data && data.value) ? data!.value : 'None'); // Value of the prop
    const filterMethod = ((data !== undefined && data !== null && data.filterMethod) ? data!.filterMethod : FilterMethod.AND); // Value of the prop

    // If column doesnt exist in categories yet add it
    if (!this.categories.hasOwnProperty(key)) {
      this.categories[key] = [];
    }

    // Check if value has appeared previously
    const index = this.categories[key].findIndex(ck => ck.name === value);
    if (index > -1) {
      if (!this.categories[key][index].matchingIDs.includes(fo.id)) {
        this.categories[key][index].matchingIDs.push(fo.id);
      }
      return;
    }

    this.categories[key].push({
      name: value,
      group: key,
      matchingIDs: [fo.id],
      enabled: ((this.firstTime && this.isDefaultFilter(key, value)) ? true : false),
      filterMethod: filterMethod
    });
  }

  sortCategories() {
    this.categoryKeys.forEach(ck => {
      if (this.categories[ck.key]) {
        this.categories[ck.key] = this.categories[ck.key].sort((a, b) => {
          const valA = (a.name ? a.name.toString().toLocaleLowerCase() : a.name);
          const valB = (b.name ? b.name.toString().toLocaleLowerCase() : b.name);
  
          if (valA === 'none') {
            return 2;
          }
          if (valB === 'none') {
            return -2
          }
  
          if (valA < valB) {
            return 1;
          }
          if (valA > valB) {
            return -1;
          }
  
          return 0;
        });
      }
    });
  }

  // Check if prop is included in default filters
  isDefaultFilter(key: string, value: string) {
    if (Object.prototype.hasOwnProperty.call(this.defaultFilters, key)) {
      const arr = this.defaultFilters[key];
      return (arr.includes(value.toLowerCase()));
    }

    return false;
  }

  // Deselet all props in filter dropdown
  resetFilter(event?: any) {
    if (event) {
      event.stopPropagation(); // Stop dropdown from closing
    }

    // Set all enabled props to false (deselect all)
    this.categoryKeys.forEach(key => {
      this.categories[key.key].forEach(val => {
        val.enabled = false;
      });
    });

    // Set cache value
    this.cachedSearchFilter.filter = this.categories;

    // Do search+filter
    this.doSearchAndFilter();
  }

  // Toggle a filter
  toggleProp(event: any, key: string, index: number) {
    event.stopPropagation(); // Stop dropdown from closing

    // Toggle filter
    this.categories[key][index].enabled = !this.categories[key][index].enabled;

    // Do search+filter
    this.doSearchAndFilter();
  }

  doSearchAndFilter() {
    // Get sarg
    const sarg = this.searchControl.value.toLocaleLowerCase();

    // Set cache
    this.cachedSearchFilter = {
      search: sarg,
      filter: this.categories
    }

    let result = this.doFilter();

    if (!this.usingNestedChecks) {
      result = this.doSearch(result, sarg);
      this.setResult(result);
    } else {
      // const filterIdsNested = this.getMatchingIdsForActiveFiltersNested();
      this.notInNestedResultsIDs = [];
      const output = this.doSearchFilterForNestedData(this.filterOptions, result, sarg);
      this.setResultNested(output);
    }
  }

  // #region - FILTERING
  doFilter() {
    this.filtersSelected = 0;

    // Build a list of enabled props
    let result: number[] = this.doFilterProcessing();

    // Get unique values
    result = Array.from(new Set(result).values());

    // Return Result
    return ((this.filtersSelected === 0) ? this.allIDs : result)
  }

  doFilterProcessing(): number[] {
    const resultsRaw: number[][] = [];

    this.categoryKeys.forEach(key => {
      // Get values for category
      const category = this.categories[key.key];

      if (category) {
        const method = category[0].filterMethod;
        let categoryResults: number[] = [];
        // let categoryReduced: number[] = []
        const selectedValues = category.filter(value => value.enabled);
        this.filtersSelected += selectedValues.length;

        // Run the desired filter
        // EXAMPLE:
        // Data = [a1, a2, a3, b1, b2, c1, ab1]
        // (A and B) = [ab1] <-- Has both A and B (excludes values with just A or B and c1)
        // (A or B) = [a1, a2, a3, b1, b2, ab1] <-- Has either A or B (excludes c1)
        if (selectedValues.length > 0) {
          switch (method) {
            case FilterMethod.AND:
              // Get matches with all checked filters
              categoryResults = this.getResultsAND(selectedValues);
            break;
            case FilterMethod.OR:
              // Get matches with any checked filters (A or B)
              categoryResults = this.getResultsOR(selectedValues);
              break;
            }

          resultsRaw.push(categoryResults);
        }
      }
    });

    // Aggregate all results
    return (resultsRaw.length > 0) ? this.reduceResults(resultsRaw) : [];
  }

  reduceResults(resultGroups: number[][]): number[] {
    return resultGroups.reduce((p, c) => p.filter(e => c.includes(e)));
  }

  getResultsAND(categoriesAND: Category[]): number[] {
    let resultAND: number[] = this.allIDs;
    categoriesAND.forEach(category => {
      resultAND = resultAND.filter(id => category.matchingIDs.includes(id));
    })
    return resultAND;
  }

  getResultsOR(categoriesOR: Category[]): number[] {
    let resultOR: number[] = [];
    categoriesOR.forEach(category => {
      resultOR = [...resultOR, ... category.matchingIDs];
    })

    return resultOR;
  }
  // #endregion

  // Perform search with current search argument
  doSearch(matches: number[], sarg: string) {
    if (sarg.length > 0) {
      // Loop over each piece of data and check it's search properties for the search arg
      this.filterOptions.forEach(item => {
        if (matches.includes(item.id)) {
          const value = this.searchProps.map(prop => (item.properties[prop] ? item.properties[prop]!.value.toLocaleLowerCase() : '')).join('');

          // If not there, trim it
          // TODO: This seems a bit weird
          if (!value.includes(sarg)) {
            matches = matches.filter(m => m !== item.id);
          }
        }
      });
    }

    return matches;
  }

  doSearchFilterForNestedData(nestedItems: FilterOption[], filterIds: number[], sarg: string): NestedOutput {
    const output: NestedOutput = {};

    // Loop over all nestedItems
    nestedItems.forEach(fo => {
      const inFilters = this.checkFilterOptionForFilter(fo, filterIds); // Check if in filter options
      const inSearch = this.checkFilterOptionForSarg(fo, sarg); // Check if in search arguments

      // If this option has nested options
      const nestedData: NestedOutput = ((fo.nestedItems) ? this.doSearchFilterForNestedData(fo.nestedItems, filterIds, sarg) : null!);
      let nestedDataLength = 0;
      for (const key in nestedData) {
        if (nestedData.hasOwnProperty(key)) {
          nestedDataLength += 1;
        }
      }

      const itemMatchesFilters = (inFilters && inSearch);

      // Add to output if in search/filter OR child is in search/filter
      if ((itemMatchesFilters || (nestedDataLength > 0))) {

        // Add to array if in tree but not in search/filter results
        if (!itemMatchesFilters && (nestedDataLength > 0)) {
          this.notInNestedResultsIDs.push(fo.id);
        }

        output[fo.id] = (nestedData ? nestedData : {}); // Set empty object if nestedOutput isnt defined
      }
    });

    return output;
  }

  checkFilterOptionForFilter(fo: FilterOption, filterIds: number[]) {
    return filterIds.includes(fo.id);
  }

  checkFilterOptionForSarg(fo: FilterOption, sarg: string): boolean {
    if (sarg.length === 0) { // If no sarg, return true;
      return true;
    }

    // Build a big string of all the search properties combined
    let searchString = '';
    this.searchProps.forEach(sp => {
      const value = fo.properties[sp];
      if (value) {
        searchString += value.value
      }
    });

    // If that string includes the sarg
    return searchString.toLocaleLowerCase().includes(sarg);
  }

  // Set the result and emit to parent
  setResult(val: number[]) {
    this.result = val;
    this.resultEmit.emit(val);
  }

  setResultNested(val: NestedOutput) {
    const output = {
      nonMatchesIncluded: this.notInNestedResultsIDs,
      tree: val
    };

    this.resultNested = output;
    this.resultEmitNested.emit(output);
  }

  // Get the number of enabled values in a category
  getCategorySelectedCount(key: string) {
    const count = this.categories[key].filter(v => v.enabled).length;

    if (count > 0) {
      return count;
    } else {
      return undefined;
    }
  }

  trackByFnKeys(item: DisplayCategory, index: number) {
    return item.key;
  }

  trackByFnCategories(item: Category, index: number) {
    return item.name;
  }
}
