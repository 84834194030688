import { ManagerReviewAccess } from '@app/models/evaluation/manager-review-access.model';
import { mockEvaluationCycles } from '@app/mock/api/data/mockEvaluationCycles';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { mockEvaluationFeedback } from '@app/mock/api/data/mockEvaluationFeedback';

export const mockManagerReviewAccesses: ManagerReviewAccess[] = [
    {
        id: 1,
        companyId: 1,
        adminUserId: mockUsers[0].id,
        evaluationCycleId: mockEvaluationCycles[0].id,
        feedbackUserId: mockUsers[2].id,
        feedbackUserManagerId: mockUsers[0].id,
        managerReview: mockEvaluationFeedback[0],
        locked: false,
        shared: false,
        sharedTimestamp: null,
        viewedTimestamp: null,
        unlockedTimestamp: new Date()
    }
];