<router-outlet></router-outlet>
<ng-container *ngIf="route.children.length === 0">
  <ng-container *ngIf="!state.loading; else stateLoading">
    <ng-container *ngIf="!state.error; else stateError">
      <div class="row m-left-0 m-right-0" [class.sidebar-expanded]="sidebarOpen">
        <!-- Sidebar -->
        <div class="col-filter-sidebar">
          <div class="card m-top-sidebar">

            <!-- Search -->
            <div class="search-collapse-container">
              <div class="main-search-container">
                <input type="search" class="round-search form-control" placeholder="Search by name..." [formControl]="filters.search">
                <i class="fal fa-search form-control-feedback"></i>
              </div>
              <div class="sidebar-collapse-icon-outer">
                <span class="fal fa-fw fa-chevron-left frankli-view pull-left sidebar-collapse-icon" [class.sidebar-icon-collapsed]="!sidebarOpen" [title]="sidebarOpen ? 'Collapse sidebar' : 'Expand sidebar'" (click)="toggleSidebarCollapsed()"></span>
              </div>
            </div>
  
            <!-- Filters -->
            <div class="category-area">
              <!-- Everyone -->
              <div class="p-bottom-10">
                <div class="category-body noselect">
                  <div class="category-option" *ngIf="users.length > 0;" (click)="clearFilters()" [class.cat-option-selected]="users.length === usersFiltered.length">
                    <span class="icon-number">{{users.length}}</span>
                    <span class="category-text">Everyone</span>
                  </div>
                  <div class="category-option" *ngIf="adminIDs.length > 0;" (click)="toggleAdminFilterSelected()" [class.cat-option-selected]="filters.admins">
                    <span class="icon-number">{{adminIDs.length}}</span>
                    <span class="category-text">Admins</span>
                  </div>
                </div>
              </div>

              <!-- Users filters -->
              <div class="p-bottom-10" *ngIf="globals.user">
                <div class="category-title">
                  <span class="title-text">My {{companyWording.teamPlural | lowercase}}:</span>
                </div>
                <div class="category-body noselect">
                  <div class="category-option" *ngIf="myTeams.department; let department;" (click)="filterSelected($event, 'departments', department.id)" [class.cat-option-selected]="filters.departments[department.id]">
                    <span class="icon-number" *ngIf="department.peopleCount > 0" >{{department.peopleCount}}</span>
                    <span class="category-text">{{department.name}}</span>
                  </div>
                  <div class="category-option" *ngIf="myTeams.site; let site;" (click)="filterSelected($event, 'sites', site.id)" [class.cat-option-selected]="filters.sites[site.id]">
                    <span *ngIf="site.peopleCount > 0" class="icon-number">{{site.peopleCount}}</span>
                    <span class="category-text">{{site.name}}</span>
                  </div>
                  <div class="category-option" *ngIf="myTeams.manager; let manager;" (click)="filterSelected($event, 'managers', manager.id)" [class.cat-option-selected]="filters.managers[manager.id]">
                    <span *ngIf="manager.peopleCount > 0" class="icon-number">{{manager.peopleCount}}</span>
                    <span class="category-text">{{manager.name}}</span>
                  </div>
                  <div class="category-option" *ngIf="myTeams.managerMe; let managerMe;" (click)="filterSelected($event, 'managers', managerMe.id)" [class.cat-option-selected]="filters.managers[managerMe.id]">
                    <span *ngIf="managerMe.peopleCount > 0" class="icon-number">{{managerMe.peopleCount}}</span>
                    <span class="category-text">My direct reports</span>
                  </div>
                </div>
              </div>

              <!-- Departments -->
              <ng-container [ngTemplateOutlet]="filterCategory" [ngTemplateOutletContext]="{title: companyWording.departmentPlural, source: departments, filterProp: 'departments', searchControl: 'departmentsSearch', searchPlaceholder: 'Search by ' + companyWording.department.toLowerCase()}"></ng-container>

              <!-- Sites -->
              <ng-container [ngTemplateOutlet]="filterCategory" [ngTemplateOutletContext]="{title: 'Sites', source: sites, filterProp: 'sites', searchControl: 'sitesSearch', searchPlaceholder: 'Search by site'}"></ng-container>

              <!-- Managers -->
              <ng-container [ngTemplateOutlet]="filterCategory" [ngTemplateOutletContext]="{title: 'Managers', source: managers, filterProp: 'managers', searchControl: 'managersSearch', searchPlaceholder: 'Search by manager'}"></ng-container>

              <ng-template #filterCategory let-title="title" let-source="source" let-filterProp="filterProp" let-searchControl="searchControl" let-searchPlaceholder="searchPlaceholder">
                <div class="p-bottom-10">
                  <div class="category-title" (click)="toggleCategoryExpanded(title)">
                    <span class="fal fa-fw fa-chevron-right frankli-view expand-on-hover dropdown-icon" [class.dropdown-icon-expanded]="categoryExpanded[title]"></span>
                    <span class="title-text">{{title}}:</span>
                  </div>
                  <div class="category-body noselect" *ngIf="categoryExpanded[title]">
                    <div class="sidebar-category-filter" *ngIf="searchPlaceholder && source.length > 6">
                      <input type="search" class="round-search form-control" [placeholder]="searchPlaceholder" [formControl]="filters[searchControl]">
                      <i class="fal fa-search form-control-feedback"></i>
                    </div>
                    <div class="category-item-list">
                      <ng-container *ngFor="let item of source">
                        <div class="category-option" *ngIf="(item.peopleCount > 0) && (filters[filterProp+'Search'].invalid || item.name.toLowerCase().includes(filters[searchControl].value.toLowerCase()))"  (click)="filterSelected($event, filterProp, item.id)" [class.cat-option-selected]="filters[filterProp][item.id]">
                          <span class="icon-number">{{item.peopleCount}}</span>
                          <span class="category-text">{{item.name}}</span>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      
        <!-- People grid -->
        <div class="col-people-grid">
          <div>
            <!-- Title -->
            <div class="main-header">
              <div class="page-title d-inline-block">
                <span>People</span>
                <!-- <span *ngIf="usersFiltered.length > 0">({{usersFiltered.length}})</span> -->
              </div>
              <span title="Export all users to CSV" class="fal fa-fw fa-cloud-download frankli-view clickable pull-right export-icon" download (click)="exportPeople()" *ngIf="canExport"></span>
            </div>
  
            <!-- Grid -->
            <ng-container *ngIf="usersFiltered.length > 0; else noUsers">
              <div class="padding-col-outer">
                <div class="row m-left-0 m-right-0">
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 padding-col" *ngFor="let user of usersDisplay; index as i;">
                    <div class="user-item" [routerLink]="['profile/', user.id]">
                      <app-person-display [user]="user" [background]="user.cardColor" [imgTitle]="user.imgTitle"></app-person-display>
                    </div>
                  </div>
                </div>
              </div>

              <div [class.hidden]="usersFiltered.length < 30">
                <app-pagination-new-component [arrayData]="usersFiltered" [arrayDisplay]="usersDisplay" [amountToDisplay]="30" [amountsToDisplay]="[30, 60, 90, 120]" #pagination></app-pagination-new-component>
              </div>
            </ng-container>
            <ng-template #noUsers>
              <div class="p-top-15 p-bottom-15 text-center">
                <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
                <br>
                <p>No people found. Try changing your filters.</p>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  
  <!-- #region - ALTERNATE PAGE STATES -->
  <ng-template #stateLoading>
    <div class="card p-top-15 p-bottom-15 text-center">
      <span class="fal fa-spin fa-spinner fa-2x fa-fw"></span>
    </div>
  </ng-template>
  
  <ng-template #stateError>
    <div class="card p-top-15 p-bottom-15 text-center">
      <p>
        <img class="icon-padding new-icon-height" src="/assets/img/Error.svg" />
        <br/>
        <span>An error occurred while getting your people.</span>
        <br/>
        <span>{{state.errorMessage}}</span>
      </p>
      <span class="btn-frankli-round btn-frankli-green" [routerLink]="'/dashboard'">
        Back to your dashboard?
      </span>
    </div>
  </ng-template>
  <!-- #endregion -->
</ng-container>
