import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreatePraiseDto, PraiseItem, UpdatePraiseDto } from 'app/models/feedback/praise.model';
import { ReactionType } from '@app/models/activity-reaction.model';
import { PraiseService } from '@app/shared/api/interfaces/praise.service';

@Injectable()
export class PraiseAPIService implements PraiseService {
  private readonly BASE_URL = 'api/praise';
  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(private http: HttpClient) { }

  /**
   * Create Feedback
   * @param praiseItem
   */
  submitPraise(createPraiseDto: CreatePraiseDto): Observable<PraiseItem> {
    const url = this.BASE_URL;
    return this.http.post<PraiseItem>(url, createPraiseDto);
  }

  /**
   * Get praise where the current user is the source user (sender)
   */
  getPraiseSent(): Observable<Array<PraiseItem>> {
    const url = `${this.BASE_URL}/sent`;
    return this.http.get<Array<PraiseItem>>(url);
  }

  /**
   * Get praise where the current user is the source user (sender) by ID
   * @param id
   */
  getPraiseSentById(id: number): Observable<PraiseItem> {
    const url = `${this.BASE_URL}/sent/${id}`;
    return this.http.get<PraiseItem>(url);
  }

  /**
   * Get praise where the current user is the praise user (recipient)
   */
  getPraiseReceived(): Observable<Array<PraiseItem>> {
    const url = `${this.BASE_URL}/received`
    return this.http.get<Array<PraiseItem>>(url);
  }

  /**
   * Get praise where the current user is the praise user (recipient) by ID
   */
  getPraiseReceivedById(id: number): Observable<PraiseItem> {
    const url = `${this.BASE_URL}/received/${id}`
    return this.http.get<PraiseItem>(url);
  }

  /**
   * Get praise received by user id
   * @param userId
   */
  getPraiseReceivedByUserId(userId: number): Observable<Array<PraiseItem>> {
    const url = `${this.BASE_URL}/received/user/${userId}`
    return this.http.get<Array<PraiseItem>>(url);
  }

  /**
   * Get praise where the current user is the destination user (manager)
   */
  getPraiseByDestinationUser(): Observable<Array<PraiseItem>> {
    const url = `${this.BASE_URL}/submitted/manager/me`;
    return this.http.get<Array<PraiseItem>>(url);
  }

  /**
   * Get praise where the current user is the destination user (manager) by ID
   * @param id
   */
  getPraiseByDestinationUserAndId(id: number): Observable<PraiseItem> {
    const url = `${this.BASE_URL}/submitted/manager/id/${id}`;
    return this.http.get<PraiseItem>(url);
  }

  /**
   * Used to toggle praise from visibile to invisible or edit the praise text
   * @id ID of praise to update
   * @param updatePraiseDto
   */
  updatePraiseById(id: number, updatePraiseDto: UpdatePraiseDto): Observable<PraiseItem> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.put<PraiseItem>(url, updatePraiseDto);
  }

  /**
   * Delete a praise item
   * @param id ID of praise to delete
   */
  deletePraiseById(id: number): Observable<PraiseItem> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.delete<PraiseItem>(url);
  }

  /**
   * Used to toggle praise from visibile to invisible or edit the praise text
   * @id ID of praise to update
   */
  togglePraiseVisibleById(id: number): Observable<PraiseItem> {
    const url = `${this.BASE_URL}/${id}/toggle`;
    return this.http.post<PraiseItem>(url, null);
  }

  toggleReactionForPraiseByIdAndReactionType(id: number, type: ReactionType): Observable<PraiseItem> {
    const url = `${this.BASE_URL}/${id}/react/${type}`;
    return this.http.post<PraiseItem>(url, null);
  }
}
