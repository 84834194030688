<div class="row">
  <div class="col-xs-12">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-xs-12 col-md-6">
          <div class="frankli-form-group" [class.frankli-form-group-invalid]="homeAddress.invalid && submitted"
            [class.frankli-form-group-filled]='homeAddress.value.length > 0'>
            <label for="home-address">Home Address (optional)</label>
            <input id="home-address" class='form-control' name="homeAddress" [maxlength]="charactersMaxLength"
              type="text" placeholder="Home Address" [formControl]="homeAddress">
            <div class="m-top-5 frankli-form-group-alert">
              <div *ngIf="homeAddress.invalid && submitted && homeAddress.hasError('required')">
                Address is required
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-md-6">
          <div class="frankli-form-group" [class.frankli-form-group-invalid]='city.invalid && submitted'
            [class.frankli-form-group-filled]='city.value.length > 0'>
            <label for="city">City (optional)</label>
            <input id="city" class='form-control' name="city" type="text" [maxlength]="charactersMaxLength"
              placeholder="City" [formControl]="city">
            <div class="m-top-5 frankli-form-group-alert">
              <div *ngIf="city.invalid && submitted && city.hasError('required')">
                City is required
              </div>
              <div *ngIf="city.invalid && submitted && city.hasError('maxLength')">
                Max {{charactersMaxLength}} characters
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-6">
          <div class="frankli-form-group" [class.frankli-form-group-invalid]='country.invalid && submitted'
            [class.frankli-form-group-filled]='country.value.length > 0'>
            <label for="country">Country</label>
            <select id="country" class='form-control' [formControl]="country">
              <option disabled selected [value]="default">{{default}}</option>
              <option *ngFor="let country of countriesPrimary" [value]="country.name">{{country.name}}
              </option>
              <option disabled>__________</option>
              <option *ngFor="let country of countriesRest" [value]="country.name">{{country.name}}
              </option>
            </select>
            <div class="m-top-5 frankli-form-group-alert">
              <div *ngIf="(country.invalid || country.value === default) && submitted">
                Country is required
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-md-6">
          <div class="frankli-form-group" [class.frankli-form-group-invalid]='postalCode.invalid && submitted'
            [class.frankli-form-group-filled]='postalCode.value.length > 0'>
            <label for="postal-code">Postal Code (optional)</label>
            <input id="postal-code" class='form-control' type="text" [maxlength]="charactersMaxLength"
              placeholder="Postal Code" [formControl]="postalCode">
            <div class="m-top-5 frankli-form-group-alert">
              <div *ngIf="postalCode.invalid && submitted && postalCode.hasError('required')">
                Postal Code is required
              </div>
              <div *ngIf="postalCode.invalid && submitted && postalCode.hasError('maxLength')">
                Max {{charactersMaxLength}} characters
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-md-6">
          <div class="frankli-form-group frankli-form-group-filled"
            [class.frankli-form-group-invalid]='officeLocation.invalid && submitted'>
            <label for="office-location">Office Location</label>
            <select id="office-location" class='form-control' [formControl]="officeLocation">
              <option [ngValue]="null">No Office Location</option>
              <option [ngValue]="location.id" *ngFor="let location of officeLocations">
                {{location.name}}</option>
            </select>
            <div class="m-top-5 frankli-form-group-alert">
              <div
                *ngIf="(officeLocation.invalid || officeLocation.value === default) && submitted && officeLocation.value === default">
                Please select an option
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-md-6">
          <div class="frankli-form-group frankli-form-group-filled"
            [class.frankli-form-group-invalid]='timezone.invalid && submitted'>
            <label for="office-location">Timezone</label>
            <select id="office-location" class='form-control' [formControl]="timezone">
              <option disabled [ngValue]="null">Please select...</option>
              <option [ngValue]="timezone" *ngFor="let timezone of timezones">
                {{timezone}}</option>
            </select>
            <div class="m-top-5 frankli-form-group-alert">
              <div *ngIf="submitted && timezone.invalid">
                Please select an option
              </div>
            </div>
          </div>
        </div>
      </div>

    </form>
  </div>

  <div class="col-xs-12 m-top-30">
    <ng-container [ngSwitch]="formType">
      <ng-container *ngSwitchCase="'EDIT'">
        <button *ngIf="!loading" (click)="onFormSubmit()"
          class="btn-frankli-round btn-frankli-green pull-right">Save</button>
        <button *ngIf="loading" class="btn-frankli-round btn-frankli-green pull-right">
          <span class="fal fa-spin fa-spinner"></span>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'SIGN_UP'">
        <button class="btn-frankli-round btn-frankli-green pull-right" (click)="onFormSubmit()">Next</button>
      </ng-container>
    </ng-container>
  </div>
</div>