import { QuestionBankQuestion } from '@app/models/evaluation/question-bank-question.model';
import { SurveyQuestionType } from '@app/models/survey-question-type.enum';
import { QuestionBankQuestionCategory } from '@app/models/evaluation/question-bank-question-category.enum';

export const mockSelfReflectionQuestionBankQuestions: QuestionBankQuestion[] = [
    {
        id: 1,
        questionText: 'What have you accomplished in this review period?',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.TEXT,
        category: QuestionBankQuestionCategory.SELF_REFLECTION
    },
    {
        id: 2,
        questionText: 'What are 2-3 attributes where you have demonstrated development over the past few months? Give some examples of how you have grown.',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.TEXT,
        category: QuestionBankQuestionCategory.SELF_REFLECTION
    },
    {
        id: 3,
        questionText: 'What are 2-3 attributes where you\'d like to focus further development? Describe how you plan to get there.',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.TEXT,
        category: QuestionBankQuestionCategory.SELF_REFLECTION
    },
    {
        id: 4,
        questionText: 'What accomplishments are you proud of?',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.TEXT,
        category: QuestionBankQuestionCategory.SELF_REFLECTION
    },
    {
        id: 5,
        questionText: 'To what extent did you meet your goals?',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.TEXT,
        category: QuestionBankQuestionCategory.SELF_REFLECTION
    },
    {
        id: 6,
        questionText: 'What area would you most like to improve on in the coming months?',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.TEXT,
        category: QuestionBankQuestionCategory.SELF_REFLECTION
    },
    {
        id: 7,
        questionText: 'Provide an example of one company value you bring to life. Please be specific.',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.TEXT,
        category: QuestionBankQuestionCategory.SELF_REFLECTION
    },
    {
        id: 8,
        questionText: 'Is your manager action oriented? How well do they drive results?',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.TEXT,
        category: QuestionBankQuestionCategory.SELF_REFLECTION
    },
    {
        id: 9,
        questionText: 'Does your manager accept feedback?',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.TEXT,
        category: QuestionBankQuestionCategory.SELF_REFLECTION
    },
    {
        id: 10,
        questionText: 'Does your manager communicate effectively?',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.TEXT,
        category: QuestionBankQuestionCategory.SELF_REFLECTION
    },
    {
        id: 11,
        questionText: 'How well does your manager support your professional & personal growth?',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.TEXT,
        category: QuestionBankQuestionCategory.SELF_REFLECTION
    },
    {
        id: 12,
        questionText: 'Does your manager hold you & your peers accountable for delivering quality work on time?',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.TEXT,
        category: QuestionBankQuestionCategory.SELF_REFLECTION
    }
];

export const mockSelfReflectionAssessmentGroupQuestionBankQuestions: QuestionBankQuestion[] = [
    {
        id: 14,
        questionText: 'How would you rate your overall performance during this review period?',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.PERFORMER,
        category: QuestionBankQuestionCategory.SELF_REFLECTION
    },
    {
        id: 15,
        questionText: 'How would you rate your overall performance during this review period?',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.EXPECTATIONS,
        category: QuestionBankQuestionCategory.SELF_REFLECTION
    },
    {
        id: 16,
        questionText: 'How would you rate your overall performance during this review period?',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.RANKED_ONE_TO_FIVE,
        category: QuestionBankQuestionCategory.SELF_REFLECTION
    }
];

export const mockPeerReviewQuestionBankQuestions: QuestionBankQuestion[] = [
    {
        id: 17,
        questionText: 'Given what I know of this person\'s performance, I would always want him or her on my team',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.LIKERT_SCALE_COMMENT,
        category: QuestionBankQuestionCategory.PEER_REVIEW
    },
    {
        id: 18,
        questionText: 'What are this teammate\'s key strengths?',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.TEXT,
        category: QuestionBankQuestionCategory.PEER_REVIEW
    },
    {
        id: 19,
        questionText: 'What are this teammate\'s areas of growth?',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.TEXT,
        category: QuestionBankQuestionCategory.PEER_REVIEW
    },
    {
        id: 20,
        questionText: 'Was this teammate able to assist you as expected?',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.TEXT,
        category: QuestionBankQuestionCategory.PEER_REVIEW
    },
    {
        id: 21,
        questionText: 'Which 3 qualities impressed you the most?',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.TEXT,
        category: QuestionBankQuestionCategory.PEER_REVIEW
    },
    {
        id: 22,
        questionText: 'What should he or she improve on?',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.TEXT,
        category: QuestionBankQuestionCategory.PEER_REVIEW
    },
];

export const mockManagerReviewQuestionBankQuestions: QuestionBankQuestion[] = [
    {
        id: 23,
        questionText: 'This person is ready for a promotion today',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.LIKERT_SCALE_COMMENT,
        category: QuestionBankQuestionCategory.MANAGER_REVIEW
    },
    {
        id: 24,
        questionText: 'I would do anything possible to retain this person, if this person resigned today',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.LIKERT_SCALE_COMMENT,
        category: QuestionBankQuestionCategory.MANAGER_REVIEW
    },
    {
        id: 25,
        questionText: 'I can always rely on this person to solve the most challenging issues',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.LIKERT_SCALE_COMMENT,
        category: QuestionBankQuestionCategory.MANAGER_REVIEW
    },
    {
        id: 26,
        questionText: 'What are this person\'s key strength? Please give specific examples',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.TEXT,
        category: QuestionBankQuestionCategory.MANAGER_REVIEW
    },
    {
        id: 27,
        questionText: 'What are this person\'s key areas of growth? Please give specific examples',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.TEXT,
        category: QuestionBankQuestionCategory.MANAGER_REVIEW
    },
    {
        id: 28,
        questionText: 'This person in an effective communicator and demonstrates strong communication skills.',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.LIKERT_SCALE_COMMENT,
        category: QuestionBankQuestionCategory.MANAGER_REVIEW
    },
    {
        id: 29,
        questionText: 'What has been the highlight of their performance in this period?',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.TEXT,
        category: QuestionBankQuestionCategory.MANAGER_REVIEW
    },
    {
        id: 30,
        questionText: 'What steps should they be taking to improve their performance?',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.TEXT,
        category: QuestionBankQuestionCategory.MANAGER_REVIEW
    },
    {
        id: 31,
        questionText: 'Are there any additional responsibilities that they could take on?',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.TEXT,
        category: QuestionBankQuestionCategory.MANAGER_REVIEW
    }
];

export const mockManagerReviewAssessmentGroupQuestionBankQuestions: QuestionBankQuestion[] = [
    {
        id: 32,
        questionText: 'How would you rate this person\'s overall performance during this review period?',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.PERFORMER,
        category: QuestionBankQuestionCategory.MANAGER_REVIEW
    },
    {
        id: 33,
        questionText: 'How would you rate person\'s overall performance during this review period?',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.EXPECTATIONS,
        category: QuestionBankQuestionCategory.MANAGER_REVIEW
    },
    {
        id: 34,
        questionText: 'How would you rate person\'s overall performance during this review period?',
        archived: false,
        defaultQuestion: true,
        surveyQuestionType: SurveyQuestionType.RANKED_ONE_TO_FIVE,
        category: QuestionBankQuestionCategory.MANAGER_REVIEW
    }
];

export const mockQuestionBankQuestions: QuestionBankQuestion[] = [
    ...mockSelfReflectionQuestionBankQuestions,
    ...mockSelfReflectionAssessmentGroupQuestionBankQuestions,
    ...mockPeerReviewQuestionBankQuestions,
    ...mockManagerReviewQuestionBankQuestions,
    ...mockManagerReviewAssessmentGroupQuestionBankQuestions
];