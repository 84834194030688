<div class="row">

  <div class="col-xs-12">
    <form [formGroup]="form">
      <!-- Date of birth & birthday reminder -->
      <div class="row">
        <div class="col-sm-6">
          <div class="frankli-form-group frankli-form-group-filled"
            [class.frankli-form-group-invalid]="form.controls.birthdayDate.invalid && submitted">
            <label>Date of Birth (optional) <i class=" clickable fal fa-question-circle"
                (click)="showHelp()"></i></label>
            <app-date-picker-old-component [formControl]="form.controls.birthdayDate"
              [invalid]="form.controls.birthdayDate.invalid" [index]="1" [emptyText]="'DD/MM/YYYY'" [outputTime]="false"
              [minDate]="minDate" [maxDate]="maxDate" [horizontalPosition]="'left'">
            </app-date-picker-old-component>
            <div class="frankli-form-group-alert">
              <ng-container *ngIf="submitted && form.controls.birthdayDate.invalid">
                <div *ngIf="form.controls.birthdayDate.hasError('required')">Required</div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="col-sm-6 margin-top-wrap">
          <div class="checkbox">
            <input id="checkboxReminder" type="checkbox" [formControl]="form.controls.birthdayReminder">
            <label for="checkboxReminder"> Birthday Reminder</label>
          </div>
        </div>
      </div>

      <!-- Preferred contact method -->
      <div class="row">
        <div class="col-sm-6">
          <div class="frankli-form-group frankli-form-group-filled"
            [class.frankli-form-group-invalid]="form.controls.preferredContact.invalid && submitted">
            <label for="prefer-contact">Preferred Contact Method</label>
            <select id="prefer-contact" class="form-control" [formControl]="form.controls.preferredContact">
              <option [ngValue]="undefined" disabled default *ngIf="!form.controls.preferredContact.value">Please
                select...</option>
              <option [ngValue]="ePreferredContactMethod.NONE">None</option>
              <option [ngValue]="ePreferredContactMethod.IN_PERSON">In Person</option>
              <option [ngValue]="ePreferredContactMethod.EMAIL">Email</option>
              <option [ngValue]="ePreferredContactMethod.PHONE">Phone</option>
              <option [ngValue]="ePreferredContactMethod.TEXT">Text</option>
              <option [ngValue]="ePreferredContactMethod.MICROSOFT_TEAMS">Microsoft Teams</option>
              <!-- <option [ngValue]="ePreferredContactMethod.SLACK">Slack</option> -->
              <!-- <option [ngValue]="ePreferredContactMethod.TWITTER">Twitter</option> -->
              <!-- <option [ngValue]="ePreferredContactMethod.LINKEDIN">LinkedIn</option> -->
            </select>
            <div class="m-top-5 frankli-form-group-alert">
              <ng-container *ngIf="submitted && form.controls.preferredContact.invalid">
                <div *ngIf="form.controls.preferredContact.hasError('maxlength')">Required</div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <!-- Contact number and Twitter handle -->
      <div class="row">
        <div class="col-sm-6">
          <div class="frankli-form-group"
            [class.frankli-form-group-invalid]="form.controls.contactNumber.invalid && submitted"
            [class.frankli-form-group-filled]="form.controls.contactNumber.value">
            <label for="contact-number">Phone Number (optional)</label>
            <input id="contact-number" class="form-control" type="text" [maxlength]="charactersMaxLengthPhoneNumber"
              placeholder="Phone Number" [formControl]="form.controls.contactNumber">
            <div class="m-top-5 frankli-form-group-alert">
              <ng-container *ngIf="submitted && form.controls.contactNumber.invalid">
                <div *ngIf="form.controls.contactNumber.hasError('maxlength')">Must be less than
                  {{charactersMaxLengthPhoneNumber}} characters</div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="frankli-form-group"
            [class.frankli-form-group-invalid]="form.controls.twitterHandle.invalid && submitted"
            [class.frankli-form-group-filled]="form.controls.twitterHandle.value">
            <label for="twitter-handle">Twitter Handle (optional)</label>
            <input id="twitter-handle" class="form-control" type="text" [maxlength]="16" placeholder="Twitter Handle"
              [formControl]="form.controls.twitterHandle">
            <div class="m-top-5 frankli-form-group-alert">
              <ng-container *ngIf="submitted && form.controls.twitterHandle.invalid">
                <div *ngIf="form.controls.twitterHandle.hasError('maxlength')">Must be less than 15 characters (16 if
                  @
                  is used at start)</div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <!-- LinkedIn URL and MS Teams Email -->
      <div class="row">
        <div class="col-sm-6">
          <div class="frankli-form-group"
            [class.frankli-form-group-invalid]="form.controls.linkedIn.invalid && submitted"
            [class.frankli-form-group-filled]="form.controls.linkedIn.value">
            <label for="linkedin-url">LinkedIn URL (optional)</label>
            <input id="linkedin-url" class="form-control" type="text" [maxlength]="255" placeholder="LinkedIn URL"
              [formControl]="form.controls.linkedIn">
            <div class="m-top-5 frankli-form-group-alert">
              <ng-container *ngIf="submitted && form.controls.linkedIn.invalid">
                <div *ngIf="form.controls.linkedIn.hasError('maxlength')">Must be less than 255 characters</div>
                <div *ngIf="form.controls.linkedIn.hasError('pattern')">Must be a link to linkedIn.com</div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="frankli-form-group"
            [class.frankli-form-group-invalid]="form.controls.teamsEmail.invalid && submitted"
            [class.frankli-form-group-filled]="form.controls.teamsEmail.value">
            <label for="teams-email">Microsoft Teams Email (optional) <i class=" clickable fal fa-question-circle"
                [tooltip]="teamsEmailExplanation"></i></label>
            <ng-template #teamsEmailExplanation>
              <div>You must add your Microsoft Teams email separate from your account email. You can leave this blank
                if
                you don't want people contacting you via Teams.</div>
            </ng-template>
            <input id="teams-email" class="form-control" type="text" [maxlength]="255"
              placeholder="Microsoft Teams Email" [formControl]="form.controls.teamsEmail">
            <div class="m-top-5 frankli-form-group-alert">
              <ng-container *ngIf="submitted && form.controls.teamsEmail.invalid">
                <div *ngIf="form.controls.teamsEmail.hasError('maxlength')">Must be less than 255 characters</div>
                <div *ngIf="form.controls.teamsEmail.hasError('pattern')">Must be a valid email</div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="col-xs-12 m-top-10">
    <ng-container [ngSwitch]="formType">
      <ng-container *ngSwitchCase="'EDIT'">
        <button *ngIf="!loading" class="btn-frankli-round btn-frankli-green pull-right"
          (click)="onFormSubmit()">Save</button>
        <button *ngIf="loading" class="btn-frankli-round btn-frankli-green pull-right">
          <span class="fal fa-spin fa-spinner"></span>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'SIGN_UP'">
        <button class="btn-frankli-round btn-frankli-green pull-right" (click)="onFormSubmit()">Next</button>
      </ng-container>
    </ng-container>
  </div>

</div>