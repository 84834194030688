import { PraiseItem } from '@app/models/feedback/praise.model';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { mockGoalTags } from '@app/mock/api/data/mockGoalTags';

export const mockPraise: PraiseItem[] =[
    {
        id: 1,
        praiseUserId: mockUsers[1].id,
        praiseUserFirstName: mockUsers[1].firstName,
        praiseUserLastName: mockUsers[1].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 2,
        praiseUserId: mockUsers[2].id,
        praiseUserFirstName: mockUsers[2].firstName,
        praiseUserLastName: mockUsers[2].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 3,
        praiseUserId: mockUsers[3].id,
        praiseUserFirstName: mockUsers[3].firstName,
        praiseUserLastName: mockUsers[3].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 4,
        praiseUserId: mockUsers[4].id,
        praiseUserFirstName: mockUsers[4].firstName,
        praiseUserLastName: mockUsers[4].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 5,
        praiseUserId: mockUsers[5].id,
        praiseUserFirstName: mockUsers[5].firstName,
        praiseUserLastName: mockUsers[5].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 6,
        praiseUserId: mockUsers[6].id,
        praiseUserFirstName: mockUsers[6].firstName,
        praiseUserLastName: mockUsers[6].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 7,
        praiseUserId: mockUsers[7].id,
        praiseUserFirstName: mockUsers[7].firstName,
        praiseUserLastName: mockUsers[7].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 8,
        praiseUserId: mockUsers[8].id,
        praiseUserFirstName: mockUsers[8].firstName,
        praiseUserLastName: mockUsers[8].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 9,
        praiseUserId: mockUsers[9].id,
        praiseUserFirstName: mockUsers[9].firstName,
        praiseUserLastName: mockUsers[9].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 10,
        praiseUserId: mockUsers[10].id,
        praiseUserFirstName: mockUsers[10].firstName,
        praiseUserLastName: mockUsers[10].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },

    {
        id: 10,
        praiseUserId: mockUsers[10].id,
        praiseUserFirstName: mockUsers[10].firstName,
        praiseUserLastName: mockUsers[10].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 11,
        praiseUserId: mockUsers[11].id,
        praiseUserFirstName: mockUsers[11].firstName,
        praiseUserLastName: mockUsers[11].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 12,
        praiseUserId: mockUsers[12].id,
        praiseUserFirstName: mockUsers[12].firstName,
        praiseUserLastName: mockUsers[12].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 13,
        praiseUserId: mockUsers[13].id,
        praiseUserFirstName: mockUsers[13].firstName,
        praiseUserLastName: mockUsers[13].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 14,
        praiseUserId: mockUsers[14].id,
        praiseUserFirstName: mockUsers[14].firstName,
        praiseUserLastName: mockUsers[14].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 15,
        praiseUserId: mockUsers[15].id,
        praiseUserFirstName: mockUsers[15].firstName,
        praiseUserLastName: mockUsers[15].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 16,
        praiseUserId: mockUsers[16].id,
        praiseUserFirstName: mockUsers[16].firstName,
        praiseUserLastName: mockUsers[16].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 17,
        praiseUserId: mockUsers[17].id,
        praiseUserFirstName: mockUsers[17].firstName,
        praiseUserLastName: mockUsers[17].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 18,
        praiseUserId: mockUsers[18].id,
        praiseUserFirstName: mockUsers[18].firstName,
        praiseUserLastName: mockUsers[18].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 19,
        praiseUserId: mockUsers[19].id,
        praiseUserFirstName: mockUsers[19].firstName,
        praiseUserLastName: mockUsers[19].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 20,
        praiseUserId: mockUsers[20].id,
        praiseUserFirstName: mockUsers[20].firstName,
        praiseUserLastName: mockUsers[20].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 21,
        praiseUserId: mockUsers[21].id,
        praiseUserFirstName: mockUsers[21].firstName,
        praiseUserLastName: mockUsers[21].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 22,
        praiseUserId: mockUsers[22].id,
        praiseUserFirstName: mockUsers[22].firstName,
        praiseUserLastName: mockUsers[22].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 23,
        praiseUserId: mockUsers[23].id,
        praiseUserFirstName: mockUsers[23].firstName,
        praiseUserLastName: mockUsers[23].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 24,
        praiseUserId: mockUsers[24].id,
        praiseUserFirstName: mockUsers[24].firstName,
        praiseUserLastName: mockUsers[24].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 25,
        praiseUserId: mockUsers[25].id,
        praiseUserFirstName: mockUsers[25].firstName,
        praiseUserLastName: mockUsers[25].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 26,
        praiseUserId: mockUsers[26].id,
        praiseUserFirstName: mockUsers[26].firstName,
        praiseUserLastName: mockUsers[26].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 27,
        praiseUserId: mockUsers[27].id,
        praiseUserFirstName: mockUsers[27].firstName,
        praiseUserLastName: mockUsers[27].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 28,
        praiseUserId: mockUsers[28].id,
        praiseUserFirstName: mockUsers[28].firstName,
        praiseUserLastName: mockUsers[28].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 29,
        praiseUserId: mockUsers[29].id,
        praiseUserFirstName: mockUsers[29].firstName,
        praiseUserLastName: mockUsers[29].lastName,
        sourceUserId: mockUsers[0].id,
        sourceUserFirstName: mockUsers[0].firstName,
        sourceUserLastName: mockUsers[0].lastName,
        message: 'Great work on the latest project!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 30,
        praiseUserId: mockUsers[0].id,
        praiseUserFirstName: mockUsers[0].firstName,
        praiseUserLastName: mockUsers[0].lastName,
        sourceUserId: mockUsers[1].id,
        sourceUserFirstName: mockUsers[1].firstName,
        sourceUserLastName: mockUsers[1].lastName,
        message: 'Great job on finding Frankli for us!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    },
    {
        id: 31,
        praiseUserId: mockUsers[0].id,
        praiseUserFirstName: mockUsers[0].firstName,
        praiseUserLastName: mockUsers[0].lastName,
        sourceUserId: mockUsers[2].id,
        sourceUserFirstName: mockUsers[2].firstName,
        sourceUserLastName: mockUsers[2].lastName,
        message: 'We were recently nominated for a Best Place to Work award and since i\'ve joined the company I can see the direct impact Trixy has on the leadership team, our company culture and the employees as a whole. Thank you Trixy!',
        timestamp: new Date(),
        hidden: false,
        values: mockGoalTags,
        reactions: []
    }
];