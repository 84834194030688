import { Injectable } from '@angular/core';
import { MockService } from '@app/mock/api/mock-service';
import { HttpRequest } from '@angular/common/http';
import { OneToOneService } from '@app/shared/api/interfaces/one-to-one.service';
import { CreateTodoDto, Todo, UpdateTodoDto } from '@app/models/todos/todos.model';
import { OneToOneDirectReportInfo } from '@app/models/one-to-one/one-to-one-direct-report-info.model';
import { TalkingPoint } from '@app/models/one-to-one/talking-point.model';
import { OneToOneMeetingFile } from '@app/models/one-to-one/one-to-one-meeting-file';
import { OneToOneMeeting } from '@app/models/one-to-one/one-to-one-meeting.model';
import { TalkingPointTemplate } from '@app/models/one-to-one/talking-point-template.model';
import { OneToOneSchedule } from '@app/models/one-to-one/one-to-one-schedule.model';
import { Observable } from 'rxjs';
import { Globals } from '@app/shared/globals/globals';
import { clone, sanitizeUrl } from '@app/shared/utils/helpers';
import { mockOneToOneSchedules } from '@app/mock/api/data/mockOneToOneSchedules';
import { OneToOneStatus } from '@app/models/one-to-one/one-to-one-status.model';
import { mockTalkingPointTemplates } from '@app/mock/api/data/mockTalkingPointTemplates';
import { mockOneToOneMeetings } from '@app/mock/api/data/mockOneToOneMeetings';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { OneToOneMeetingStatus } from '@app/models/one-to-one/one-to-one-meeting-status.model';

@Injectable()
export class OneToOneMockService implements MockService, OneToOneService {
    constructor(private readonly globals: Globals) { }

    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);
        const urlSegments = url.split('/');

        switch (true) {
            case url.endsWith('api/one-to-one/schedule/me'):
                return this.getOneToOneSchedulesForUserMe();
            case url.match(/api\/one-to-one\/schedule\/me\/\d+$/) !== null:
                const myScheduleId = +urlSegments[4];
                return this.getMyOneToOneScheduleById(myScheduleId);
            case url.match(/api\/one-to-one\/schedule\/managed\/\d+$/) !== null:
                const managedScheduleId = +urlSegments[4];
                return this.getOneToOneScheduleByIdManaged(managedScheduleId);
            case url.match(/api\/one-to-one\/schedule\/manager\/\d+$/) !== null:
                const scheduleManagerId = +urlSegments[4];
                return this.getOneToOneSchedulesByManagerId(scheduleManagerId);
            case url.endsWith('api/one-to-one/schedule/manage/direct'):
                return this.getOneToOneDirectReportInfo();
            case url.match(/api\/one-to-one\/schedules\/\d+$/) !== null:
                const scheduleId = +urlSegments[3];
                return this.getOneToOneScheduleById(scheduleId);
            case url.endsWith('api/one-to-one/schedules'):
                return this.getOneToOneSchedulesForManagerMe();
            case url.endsWith('api/one-to-one/schedules/archived'):
                return this.getArchivedOneToOneSchedulesForManagerMe();
            case url.endsWith('api/one-to-one/schedules/archived/user/me'):
                return this.getArchivedOneToOneSchedulesForUserMe();
            case url.match(/api\/one-to-one\/\d+\/meeting\/\d+\/prev\/talking-point/) !== null:
                const prevScheduleId = +urlSegments[2];
                const prevMeetingId = +urlSegments[4];
                return this.getIncompleteFromPreviousTalkingPoints(prevScheduleId, prevMeetingId);
            case url.match(/api\/one-to-one\/template\/\d+$/) !== null && req.method === 'GET':
                const templateId = +urlSegments[3];
                return this.getTalkingPointTemplateById(templateId);
            case url.match(/api\/one-to-one\/template\/name\/\D+$/) !== null:
                const templateName = urlSegments[4];
                return this.getTalkingPointTemplateByName(templateName);
            case url.endsWith('api/one-to-one/template/default'):
                return this.getDefaultTalkingPointTemplate();
            case url.endsWith('api/one-to-one/template') && req.method === 'GET':
                return this.getTalkingPointTemplates();
            case url.match(/api\/one-to-one\/meeting\/\d+\/file/) !== null:
                const fileMeetingId = +urlSegments[3];
                return this.getFilesByMeetingId(fileMeetingId);
            case url.endsWith('api/one-to-one/schedule/meeting/oldest'):
                return this.getOldestOneToOneMeeting();
            case url.match(/api\/one-to-one\/schedule\/cycle\/\d+$/) !== null:
                const evaluationCycleId = +urlSegments[4];
                return this.getScheduleForCycleIdAndMeManager(evaluationCycleId);
            case url.match(/api\/one-to-one\/schedule\/\d+\/cycle\/\d+$/) !== null:
                const cycleUserId = +urlSegments[3];
                const cycleId = +urlSegments[5];
                return this.getScheduleForDirectReportAndCycleId(cycleUserId, cycleId);
            case url.match(/api\/one-to-one\/meetings\/post-review\/\d+$/) !== null:
                return this.getPostReviewMeetingsByCycleId(0);
        }
    }

    getScheduleForCycleIdAndMeManager(cycleId: number): OneToOneSchedule | Observable<OneToOneSchedule> {
        return mockOneToOneSchedules.find(s => s.evaluationCycleId === cycleId && s.manager.id === this.globals.user.managerId && s.user.id === this.globals.user.id) || null;
    }

    getScheduleForDirectReportAndCycleId(userId: number, cycleId: number): OneToOneSchedule | Observable<OneToOneSchedule> {
        return mockOneToOneSchedules.find(s => s.evaluationCycleId === cycleId && s.manager.id === this.globals.user.id && s.user.id === userId) || null;
    }

    getArchivedOneToOneSchedulesForManagerMe(): Observable<Array<OneToOneSchedule>> | OneToOneSchedule[] {
        return mockOneToOneSchedules.filter(s => s.manager.id === this.globals.user.id && s.status === OneToOneStatus.ARCHIVED);
    }

    getArchivedOneToOneSchedulesForUserMe(): Observable<Array<OneToOneSchedule>> | OneToOneSchedule[] {
        return mockOneToOneSchedules.filter(s => s.user.id === this.globals.user.id && s.status === OneToOneStatus.ARCHIVED);
    }

    getDefaultTalkingPointTemplate(): Observable<TalkingPointTemplate> | TalkingPointTemplate {
        return mockTalkingPointTemplates.find(t => t.defaultTemplate);
    }

    getFilesByMeetingId(meetingId: number): Observable<Array<OneToOneMeetingFile>> | OneToOneMeetingFile[] {
        return [];
    }

    getIncompleteFromPreviousTalkingPoints(scheduleId: number, meetingId: number): Observable<Array<TalkingPoint>> | TalkingPoint[] {
        return [];
        // return mockOneToOneMeetings.find(m => m.id === meetingId).talkingPoints.filter(tp => !tp.actioned);
    }

    getMyOneToOneScheduleById(scheduleId: number): Observable<OneToOneSchedule> | OneToOneSchedule {
        return mockOneToOneSchedules.find(s => s.id === scheduleId);
    }

    getOldestOneToOneMeeting(): Observable<OneToOneMeeting> | OneToOneMeeting {
        return mockOneToOneMeetings.sort((a, b) =>
            new Date(b.meetingTimestamp).getTime() - new Date(a.meetingTimestamp).getTime())
            .find(g => g !== undefined);
    }

    getOneToOneDirectReportInfo(): Observable<Array<OneToOneDirectReportInfo>> | OneToOneDirectReportInfo[] {
        const oneToOneDirectReportInfos: OneToOneDirectReportInfo[] = [];

        const myDirectReports = mockUsers.filter(u => u.id !== this.globals.user.id && u.managerId === this.globals.user.id);
        for (const directReport of myDirectReports) {
            let meetingCount = 0;
            let lastMeetingDate: Date;
            const directReportSchedules = mockOneToOneSchedules.filter(s => s.manager.id === directReport.id || s.user.id === directReport.id);
            for (const schedule of directReportSchedules) {
                const completedMeetings = schedule.meetingList.filter(m => [OneToOneMeetingStatus.COMPLETED, OneToOneMeetingStatus.AUTO_COMPLETED].includes(m.status));
                meetingCount += completedMeetings.length;
                if (completedMeetings.length > 0) {
                    let lastMeetingDateInSchedule = new Date(Math.max(...completedMeetings.map(m => new Date(m.meetingTimestamp).getTime())));
                    if (!lastMeetingDate || lastMeetingDateInSchedule > lastMeetingDate) {
                        lastMeetingDate = lastMeetingDateInSchedule;
                    }
                }
            }

            const drDirectReports = mockUsers.filter(u => u.id !== directReport.id && u.managerId === directReport.id);
            oneToOneDirectReportInfos.push({
                user: directReport,
                directScheduleList: directReportSchedules,
                directReports: drDirectReports,
                lastMeeting: lastMeetingDate || null,
                meetings: meetingCount,
                completed: 0,
                autocompleted: 0
            });
        }
        return oneToOneDirectReportInfos;
    }

    getOneToOneScheduleById(scheduleId: number): Observable<OneToOneSchedule> | OneToOneSchedule {
        const oneToOneSchedule = mockOneToOneSchedules.find(s => s.id === scheduleId);
        oneToOneSchedule.meetingList.sort((a, b) => a.id < b.id ? -1 : a.id > b.id ? 1 : 0);
        return oneToOneSchedule;
    }

    getOneToOneScheduleByIdManaged(scheduleId: number): Observable<OneToOneSchedule> | OneToOneSchedule {
        return mockOneToOneSchedules.find(s => s.id === scheduleId);
    }

    getOneToOneSchedulesByManagerId(managerId: number): Observable<Array<OneToOneSchedule>> | OneToOneSchedule[] {
        return mockOneToOneSchedules.filter(s => s.manager.id === managerId);
    }

    getOneToOneSchedulesForManagerMe(): Observable<Array<OneToOneSchedule>> | OneToOneSchedule[] {
        const oneToOneSchedules = mockOneToOneSchedules.filter(s => s.manager.id === this.globals.user.id);
        oneToOneSchedules.forEach(s => s.meetingList.sort((a, b) => a.id < b.id ? -1 : a.id > b.id ? 1 : 0));
        return oneToOneSchedules;
    }

    getOneToOneSchedulesForUserMe(): Observable<Array<OneToOneSchedule>> | OneToOneSchedule[] {
        const oneToOneSchedules = mockOneToOneSchedules.filter(s => s.user.id === this.globals.user.id);
        oneToOneSchedules.forEach(s => s.meetingList.sort((a, b) => a.id < b.id ? -1 : a.id > b.id ? 1 : 0));
        return oneToOneSchedules;
    }

    getTalkingPointTemplateById(id: number): Observable<TalkingPointTemplate> | TalkingPointTemplate {
        return mockTalkingPointTemplates.find(t => t.id === id);
    }

    getTalkingPointTemplateByName(name: string): Observable<TalkingPointTemplate> | TalkingPointTemplate {
        return mockTalkingPointTemplates.find(t => t.name.toLowerCase() === name.toLowerCase());
    }

    getTalkingPointTemplates(): Observable<Array<TalkingPointTemplate>> | TalkingPointTemplate[] {
        return mockTalkingPointTemplates;
    }

    // No Ops listed below
    actionOneToOneMeetingTalkingPoint(talkingPoints: TalkingPoint, meetingId: number): Observable<Array<TalkingPoint>> {
        return undefined;
    }

    addActionPoint(actionPoint: CreateTodoDto, scheduleId: number, userId: number): Observable<Todo> {
        return undefined;
    }

    addOneToOneMeetingTalkingPoint(talkingPoints: TalkingPoint, meetingId: number): Observable<Array<TalkingPoint>> {
        return undefined;
    }

    archiveSchedule(scheduleId: number): Observable<OneToOneSchedule> {
        return undefined;
    }

    bumpOneToOneMeetingTalkingPoint(talkingPoints: TalkingPoint, meetingId: number): Observable<Array<TalkingPoint>> {
        return undefined;
    }

    commentOneToOneMeetingTalkingPoint(talkingPoints: TalkingPoint, meetingId: number): Observable<Array<TalkingPoint>> {
        return undefined;
    }

    completeActionPoint(scheduleId: number, userId: number, todoId: number): Observable<Todo> {
        return undefined;
    }

    createOneToOneSchedule(schedule: OneToOneSchedule): Observable<OneToOneSchedule> {
        return undefined;
    }

    createTalkingPointTemplate(talkingPointTemplate: TalkingPointTemplate): Observable<TalkingPointTemplate> {
        return undefined;
    }

    deleteActionPoint(scheduleId: number, userId: number, todoId: number): Observable<Todo> {
        return undefined;
    }

    deleteFileByMeetingIdAndFileName(meetingId: number, filename: string): Observable<OneToOneMeetingFile> {
        return undefined;
    }

    deleteSchedule(scheduleId: number): Observable<OneToOneSchedule> {
        return undefined;
    }

    deleteTalkingPointTemplateById(id: number): Observable<TalkingPointTemplate> {
        return undefined;
    }

    incompleteActionPoint(scheduleId: number, userId: number, todoId: number): Observable<Todo> {
        return undefined;
    }

    pauseSchedule(scheduleId: number): Observable<OneToOneSchedule> {
        return undefined;
    }

    removeOneToOneMeetingTalkingPoint(talkingPoints: TalkingPoint, meetingId: number): Observable<Array<TalkingPoint>> {
        return undefined;
    }

    resumeSchedule(scheduleId: number): Observable<OneToOneSchedule> {
        return undefined;
    }

    updateActionPoint(actionPoint: UpdateTodoDto, scheduleId: number, userId: number, todoId: number): Observable<Todo> {
        return undefined;
    }

    updateMeetingTimestamp(oneToOneMeeting: OneToOneMeeting, meetingId: number): Observable<OneToOneMeeting> {
        return undefined;
    }

    updateOneToOneMeetingPrivateNotes(scheduleId: number, privateNotes: string, meetingId: number, autosave: boolean): Observable<OneToOneMeeting> {
        return undefined;
    }

    updateOneToOneMeetingSharedNotes(scheduleId: number, sharedNotes: string, meetingId: number, autosave: boolean): Observable<OneToOneMeeting> {
        return undefined;
    }

    updateOneToOneMeetingStatus(scheduleId: number, meeting: OneToOneMeeting): Observable<OneToOneMeeting> {
        return undefined;
    }

    updateOneToOneSchedule(schedule: OneToOneSchedule, justNextMeeting: boolean): Observable<OneToOneSchedule> {
        return undefined;
    }

    updateTalkingPointTemplate(id: number, talkingPointTemplate: TalkingPointTemplate): Observable<TalkingPointTemplate> {
        return undefined;
    }

    uploadFileForMeeting(meetingId: number, file: File): Observable<OneToOneMeetingFile> {
        return undefined;
    }

    getPostReviewMeetingsByCycleId(cycleId: number) {
        return [];
    }
}
