<div class="display-container" *ngIf="feedback">
  <!-- Sent by X to Y on Date -->
  <div class="area-metadata" *ngIf="(feedback.sender && showSourceUser) || (feedback.responses && showDestinationUser) || (feedback.timestamp)">
    <span>Sent</span>

    <!-- Source user -->
    <span class="text-nowrap" *ngIf="feedback.sender && showSourceUser">
      <span>by</span>
      <span class="area-source-user">
        <span class="user-picture">
          <app-profile-photo [src]="feedback.sender.imageUrl"></app-profile-photo>
        </span>
        <span>{{feedback.sender.firstName}} {{feedback.sender.lastName}}</span>
      </span>
    </span>

    <!-- Regarding user -->
    <span class="text-nowrap" *ngIf="feedback.regarding && showSourceUser">
      <span>regarding</span>
      <span class="area-source-user">
        <span class="user-picture">
          <app-profile-photo [src]="feedback.regarding.imageUrl"></app-profile-photo>
        </span>
        <span>{{feedback.regarding.firstName}} {{feedback.regarding.lastName}}</span>
      </span>
    </span>

    <!-- Destination -->
    <span *ngIf="feedback.responses && showDestinationUser">
      <span>to</span>
      <ng-container *ngFor="let response of feedback.responses; first as isFirst; last as isLast;">
        <ng-container *ngIf="!isFirst">
          <span *ngIf="!isLast">,</span>
          <span *ngIf="isLast">and</span>
        </ng-container>

        <!-- Internal user -->
        <span class="area-destination-user" *ngIf="response.user">
          <span class="user-picture">
            <app-profile-photo [src]="response.user.imageUrl"></app-profile-photo>
          </span>
          <span>{{response.user.firstName}} {{response.user.lastName}}</span>
        </span>

        <!-- External user -->
        <span class="area-destination-user" *ngIf="response.externalUser">
          <span class="email-icon-container">
            <span class="fal fa-fw fa-envelope"></span>
          </span>
          <span>{{response.externalUser.email}}</span>
        </span>
      </ng-container>
    </span>

    <!-- Timestamp -->
    <span class="text-nowrap" *ngIf="feedback.timestamp">
      <span>on</span>
      <span class="area-timestamp">{{feedback.timestamp | date}}</span>
    </span>
  </div>

  <!-- Question -->
  <div class="area-question">
    <div class="feedback-question">Ask a specific question</div>
    <div class="feedback-answer">
        <ng-container *ngFor="let question of feedback.questions; index as index">
          <div>
            <strong>Q{{index + 1}}.</strong>
            <span>{{question.questionText}}</span>
          </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #noAnswer>
  <div>N/A</div>
</ng-template>