import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { PraiseItem, UpdatePraiseDto } from 'app/models/feedback/praise.model';
import Swal from 'sweetalert2';
import { User } from 'app/models/user/user.model';
import { Globals } from 'app/shared/globals/globals';
import { CompanyFeatures } from 'app/models/company.model';
import { PraiseAPIService } from 'app/shared/api/praise.api.service';
import { UserAPIService } from 'app/shared/api/user.api.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GoalTag } from 'app/models/goals/goal-tag.model';
import { GoalsAPIService } from 'app/shared/api/goals.api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityReaction, ReactionType } from '@app/models/activity-reaction.model';
import { ModalComponent } from '@app/shared/modal/modal.component';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { HtmlPipe } from '@app/shared/pipes/html.pipe';

declare var $: any;

enum ModalTab {
  ALL = 'ALL',
  LIKE = 'LIKE',
  CELEBRATE = 'CELEBRATE',
  LOVE = 'LOVE'
}

interface ReactionUser {
  reaction: ActivityReaction;
  user: User;
}

@Component({
  selector: 'app-profile-praise',
  templateUrl: './profile-praise.component.html',
  styleUrls: ['./profile-praise.component.css']
})
export class ProfilePraiseComponent implements OnInit, OnChanges {
  readonly largeEditorMinLength = 10;
  readonly largeEditorMaxLengthSoft = 1000;
  readonly largeEditorMaxLengthHard = 2000;
  readonly editorToolbar = 'undo redo | formatselect | bold italic underline strikethrough | help';

  @Input() userProfile!: User;
  @Output() clickGivePraise: EventEmitter<any> = new EventEmitter();
  @ViewChild('reactionUserModal') reactionUserModal?: ModalComponent;
  @ViewChildren('pop') popovers!: QueryList<PopoverDirective>;

  user: User;
  globals: Globals;
  modalTab: ModalTab;
  eFeature = CompanyFeatures;
  eReactionType = ReactionType;
  eModalTab = ModalTab;
  loading = true;
  error = false;
  reactionUsers: Array<ReactionUser>;
  reactionUsersDisplay: Array<ReactionUser>;
  praiseList: Array<PraiseItem>;
  availableValues: Array<GoalTag>;
  selectedValues: Array<GoalTag>;
  imgList: Array<any>;
  labelText = '';
  titleText = '';
  praise = '';
  submittedPraise = false;
  mouseInPop = false;
  maxLengthPraise = 1000;
  minLengthPraise = 1;
  editorStyles = {
    'overflow-y': 'auto',
    'max-height': '120px',
    'min-height': '120px',
  }

  editingId: number | null;
  editForm!: FormGroup;

  constructor(
    private _globals: Globals,
    private _praiseAPIService: PraiseAPIService,
    private _userAPIService: UserAPIService,
    private _goalsAPIService: GoalsAPIService,
    private _formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef
  ) {
    this.globals = _globals;
    this.user = this.globals.user;
    this.praiseList = [];
    this.imgList = [];
    this.availableValues = [];
    this.selectedValues = [];
    this.reactionUsers = [];
    this.reactionUsersDisplay = [];
    this.editingId = null;
    this.modalTab = ModalTab.ALL;
  }

  ngOnInit() {
    this._goalsAPIService.getTags('company_value').subscribe(resList => {
      console.log(resList);
      resList.forEach(res => {
        res.selected = false;
      });
      this.availableValues = resList;
    });
  }

  toggleValue(value: GoalTag) {
    value.selected = !value.selected;
  }

  ngOnChanges() {
    this.getPraise();
  }

  noPraise() {
    this.praise = '';
    Swal.fire({
      title: 'Praise cancelled!',
      text: 'No praise will be posted',
      confirmButtonColor: '#54c6bb',
      imageUrl: 'assets/img/swal-icons/Frankli_cancel_icon-45.svg',
      imageWidth: 140,
      imageHeight: 140,
    })
  }

  getPraise() {
    const funcpraise = this.user.id === this.userProfile.id
      ? this._praiseAPIService.getPraiseReceived() : this._praiseAPIService.getPraiseReceivedByUserId(this.userProfile.id);
    funcpraise.subscribe((praiseArr) => {
      this.praiseList = [];
      this.imgList = [];
      this.praiseList = praiseArr.filter(x => !x.hidden).slice(0).slice(-5).reverse();
      this.praiseList.forEach(p => {
        p.sourceUserImageUrl = '../../assets/img/default.svg';
        this._userAPIService.getById(p.sourceUserId).subscribe((res) => {
          p.sourceUserImageUrl = res.imageUrl;
        });
      });
    });
    this.loading = false;
  }

  removePraise(praise: PraiseItem) {
    Swal.fire({
      title: 'Remove praise?',
      text: `Your praise will be removed from ${this.userProfile.firstName}\'s profile.`,
      confirmButtonColor: '#54c6bb',
      showCancelButton: true,
      imageUrl: 'assets/img/swal-icons/Frankli_sure_icon-46.svg',
      imageWidth: 140,
      imageHeight: 140,
    }).then(result => {
      if (result.value) {
        this._praiseAPIService.deletePraiseById(praise.id).subscribe(() => {
          $.notify({
            icon: 'fal fa-thumbs-up',
            message: 'Praise successfully deleted',
          }, {
            type: 'frankli-blue',
            placement: {
              from: 'top',
              align: 'right',
            },
          });
          this.getPraise();
        });
      }
    });
  }

  hidePraise(praise: PraiseItem) {
    Swal.fire({
      title: 'Hide praise?',
      text: 'Praise will be hidden from your public profile.',
      confirmButtonColor: '#54c6bb',
      showCancelButton: true,
      imageUrl: 'assets/img/swal-icons/Frankli_sure_icon-46.svg',
      imageWidth: 140,
      imageHeight: 140,
    }).then(result => {
      if (result.value) {
        this._praiseAPIService.togglePraiseVisibleById(praise.id).subscribe(res => {
          console.log(res);
          this.getPraise();
        })
      }
    });
  }

  editPraise(praise: PraiseItem) {
    this.clearValues();
    this.editingId = praise.id;

    // Init form
    this.editForm = this._formBuilder.group({
      editMessage: new FormControl(praise.message, [Validators.required, this.softMinValidation(this.largeEditorMinLength), this.softMaxValidation(this.largeEditorMaxLengthSoft), Validators.maxLength(this.largeEditorMaxLengthHard)]),
    });

    this.editForm.controls.editMessage.valueChanges.subscribe(res => {
      this.cdRef.detectChanges();
    });

    // Values
    const selectedValues = praise.values || [];
    const selectedValueIds = selectedValues.map(value => value.id) || [];
    this.availableValues.forEach(value => {
      if (selectedValueIds.includes(value.id)) {
        this.toggleValue(value);
      }
    });
  }

  cancelEditing() {
    this.editingId = null;
    this.clearValues();
  }

  softMinValidation(min: number) {
    return (control: AbstractControl) => {
      const value = control.value;
      if (value) {
        const htmlPipe = new HtmlPipe();
        const valueNoTags = htmlPipe.transform(value);
        if (valueNoTags.length < min) {
          return {
            softmin: true,
          };
        }
      }

      return null!;
    }
  }

  softMaxValidation(max: number) {
    return (control: AbstractControl) => {
      const value = control.value;
      if (value) {
        const htmlPipe = new HtmlPipe();
        const valueNoTags = htmlPipe.transform(value);
        if (valueNoTags.length > max) {
          return {
            softmax: true,
          };
        }
      }

      return null!;
    }
  }

  clearValues() {
    this.availableValues.forEach(val => val.selected = false);
  }

  savePraise(praise: PraiseItem) {
    this.submittedPraise = true;
    if (this.editForm.valid) {
      const updatePraiseDto = new UpdatePraiseDto(
          this.editForm.controls.editMessage.value,
          this.availableValues.filter(val => val.selected === true)
      );
      this._praiseAPIService.updatePraiseById(praise.id, updatePraiseDto).subscribe(() => {
        $.notify('Praise successfully updated!');
        this.submittedPraise = false;
        this.getPraise();
        this.cancelEditing();
      });
    }
  }

  givePraise() {
    this.clickGivePraise.emit(true);
  }

  viewAllPraise() {
    if (this.userProfile.id === this.globals.user.id) {
      this.router.navigate(
        ['feedback/history'],
        { queryParams: {type: 'praise', filter: 'received'}}
      )
    } else {
      this.router.navigate(
        ['./praise'],
        { relativeTo: this.route });
    }
  }

  toggleReaction(praise: PraiseItem, type: ReactionType) {
    this._praiseAPIService.toggleReactionForPraiseByIdAndReactionType(praise.id, type).subscribe(praise => {
      const idx = this.praiseList.findIndex(p => p.id === praise.id);
      this.praiseList[idx].reactions = praise.reactions;
      if (this.getUniqueReactions(praise.reactions).has(type)) {
        $.notify(`Successfully ${type.toLowerCase()}d ${praise.praiseUserFirstName}'s praise!`);
      }
      setTimeout(() => this.popovers.forEach(popover => popover.hide()), 200);
    });
  }

  userHasReacted(praise: PraiseItem, type?: ReactionType): boolean {
    if (praise.reactions && praise.reactions.length > 0) {
      return !!praise.reactions
      .filter(r => r.userId === this.globals.user.id)
      .find(r => (type ? (r.type === type) : true));
    } else {
      return false;
    }
  }

  getUniqueReactions(reactions: Array<ActivityReaction>): Set<ReactionType> {
    return new Set(reactions.map(r => r.type));
  }

  loadReactionUserModal(reactions: Array<ActivityReaction>) {
    const newReactionUsers = new Array<ReactionUser>();
    this.reactionUsers = newReactionUsers;
    reactions.forEach(reaction =>
        this._userAPIService.getById(reaction.userId).subscribe(user => {
          newReactionUsers.push({
            user,
            reaction
          });
        })
    );
    this.reactionUsersDisplay = this.reactionUsers;
    this.modalTab = ModalTab.ALL;
    if (this.reactionUserModal) {
      this.reactionUserModal.show();
    }
  }

  changeTab(tab: ModalTab) {
    if (tab !== ModalTab.ALL) {
      const tabAsType = ReactionType[tab];
      this.reactionUsersDisplay = this.getReactionUsersForType(tabAsType);
    } else {
      this.reactionUsersDisplay = this.reactionUsers;
    }
    this.modalTab = tab;
  }

  getReactionUsersForType(type: ReactionType): Array<ReactionUser> {
    return this.reactionUsers.filter(ru => ru.reaction.type === type);
  }

  // To prevent duplicate popovers, we need this method to hide popovers that are no longer referenced
  hideOtherPopovers(event: PopoverDirective, praise: PraiseItem) {
    const currentPop = this.popovers.find(p => p.popoverContext.praise === praise);
    if (currentPop) {
      const otherPopsShown = this.popovers.filter(p => p.isOpen && p !== currentPop);
      if (otherPopsShown.length > 0) {
        otherPopsShown.forEach(p => p.hide());
      }
    }
    this.checkMousePosition();
  }

  // If mouse not over element, hide pop
  checkMousePosition() {
    setTimeout(() => {
      if (!this.mouseInPop) {
        this.popovers.forEach(popover => popover.hide());
      } else {
        this.checkMousePosition();
      }
    }, 4000);
  }
}
