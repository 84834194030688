import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthAPIService } from '../auth/auth.api.service';
import { Globals } from '../globals/globals';
import { ErrorService } from './error.service';

@Injectable()
export class HttpInterceptors implements HttpInterceptor {
  constructor(private errorService: ErrorService, private globals: Globals, private authService: AuthAPIService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const serverVersionHeader = event.headers.get('x-server-version');

          if (serverVersionHeader && serverVersionHeader !== environment.version) {
            if (event.url && event.url!.indexOf('/health') > 0 && environment.version === '1.0.2') {
              this.authService.logout()
            }

            console.log('event setting updateStatus');
            this.errorService.setUpdateStatus(true);
          }
        }
        return event;
      }),
      catchError((failure: HttpErrorResponse) => {
        const failureUrl = failure.url;
        if (failureUrl === null) {
          return throwError(failure);
        }

        switch (failure.status) {
          // no internet connection
          case 0:
            this.errorService.sendStatus(0);
            break;
          // logged out
          case 200:
            if ((<string>failure.error.text).toLocaleLowerCase().substring(0, 15) === '<!doctype html>') {
              this.errorService.sendStatus(200);
            }
            break;
          case 401:
            // NOTE: This is for switching to JWT from cookies. TODO: Remove once switched fully
            if (failure.url && failure.url.indexOf('/connect') >= 0) {

            } else {
              this.authService.logout();
            }
            break;
          // nginx up but server down
          case 502:
            if (failure.url && failure!.url.indexOf('version.json') >= 0) {

            } else {
              this.errorService.sendStatus(502);
            }
            break;
          // server down
          case 503:
            this.errorService.sendStatus(503);
            break;
          // proxy down
          case 504:
            this.errorService.sendStatus(504);
            break;
        }
        return throwError(failure);
      })
    );
  }
}
