import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class StatusAPIService {
  http: HttpClient;
  baseURL = '/api/status/';

  constructor(private _http: HttpClient) {
    this.http = _http;
  }

  getStatus(): Observable<null> {
    const url = this.baseURL;
    return this.http.get<null>(url);
  }

}
