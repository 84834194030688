import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from 'app/shared/globals/globals';

export enum FileFolderName {
  TEST = 'test',
  EVALUATIONS = 'evalations',
  BULK_UPLOAD = 'bulk-upload'
}

/**
 * Files are stored in S3 under the following prefix `/<companyId>/<prefix>/<userId>/<fileName>.<file-extension>`
 */
@Injectable()
export class FileAPIService {

  private readonly BASE_URL = '/api/files';

  constructor(private http: HttpClient, private globals: Globals) {
  }

  /**
   * Store public file, accessible by anyone
   * @param file 
   * @param fileExtension 
   * @returns 
   */
  storePublicResource(file: any, fileExtension: string): Observable<Array<string>> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('fileExtension', fileExtension);
    const url = '/api/files/post';
    return this.http.post<Array<string>>(url, formData);
  }

  /**
   * @param url 
   * @returns 
   */
  getAndConvert(url: string): Observable<string> {
    return new Observable<string>((observer) => {

      observer.next('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
      const { next, error } = observer;

      this.http
        .get(url, {
          responseType: 'blob'
        }).subscribe(response => {
          const reader = new FileReader();

          reader.readAsDataURL(response);
          reader.onloadend = function () {
            observer.next(reader.result as string);
          };
        });

      return { unsubscribe() { } };
    });
  }

  get(url: string): Observable<Blob> {
    return this.http
      .get(url, {
        responseType: 'blob'
      })
  }

  deleteFile(filename: string) {
    const url = '/api/files/delete/' + filename;
    return this.http.get(url);
  }

  getResourceNamesByFolderName(folder: FileFolderName): Observable<Array<string>> {
    const url = `${this.BASE_URL}/summary?prefix=${folder}`;
    return this.http.get<Array<string>>(url);
  }

  getResourceNamesByFolderNameAndUserId(folder: FileFolderName, userId: number): Observable<Array<string>> {
    const url = `${this.BASE_URL}/summary/${userId}?prefix=${folder}`;
    return this.http.get<Array<string>>(url);
  }

  getAllResourceNamesByFolderName(folder: FileFolderName): Observable<Array<string>> {
    const url = `${this.BASE_URL}/summary/all?prefix=${folder}`;
    return this.http.get<Array<string>>(url);
  }

  getResourceByFolderNameAndFileName(folder: FileFolderName, filename: string): Observable<File> {
    const url = '/api/files?prefix=' + folder + '&filename=' + filename;
    return this.http.get<File>(url);
  }

  /**
   * Store private file, accessible only to the user
   * @param userId 
   * @param file 
   * @param prefix 
   * @returns 
   */
  storePrivateResourceByUserIdAndPrefix(userId: number, file: File, prefix: string): Observable<null> {
    const url = `/api/files/${userId}?prefix=${prefix}`;
    const formData: FormData = new FormData();
    formData.append('file', file);

    // TODO: get upload progress reporting working correctly
    /*
    const request = new HttpRequest('POST', url, formData, {
      reportProgress: true,
      responseType: 'text'
    });
    */

    return this.http.post<null>(url, formData);
  }

  getResourceUrlByFolderNameAndFileName(folder: FileFolderName, filename: string): string {
    return `/api/files?prefix=${folder}&filename=${filename}`;
  }

  getResourceUrlByFolderNameAndFileNameAndUserId(folder: FileFolderName, filename: string, userId: number): string {
    return `/api/files/${userId}?prefix=${folder}&filename=${filename}`;
  }

  removeResourceByUserIdAndFolderNameAndFileName(userId: number, folder: FileFolderName, filename: string): Observable<null> {
    const url = `/api/files/${userId}?prefix=${folder}&filename=${filename}`;
    return this.http.delete<null>(url);
  }

  // BULK UPLOAD

  bulkUploadArchive(file: File): Observable<HttpEvent<{}>> {
    const URL = `${this.BASE_URL}/bulk-upload/archive`

    return this.saveFileWithProgress(file, URL);
  }

  public saveFileWithProgress(file: File, url: string): Observable<HttpEvent<{}>> { // TODO: This doesnt handle errors well
    const formdata: FormData = new FormData();
    formdata.append('file', file);

    const req = new HttpRequest('POST', url, formdata, {
      reportProgress: true,
      responseType: 'text'
    });
    return this.http.request(req);
  }

}
