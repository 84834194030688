import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CompanyWording } from '@app/models/company/company-wording/company-wording.model';
import { GoalActivityType } from '@app/models/goals/goal-activity.model';
import { GoalKeyResultMeasureUnitPlacement } from '@app/models/goals/goal-key-result-measure-unit-placement.model';
import { GoalKeyResultType } from '@app/models/goals/goal-key-result-type.model';
import { FrankliValidators } from '@app/shared/validators/validators';
import { Breadcrumb } from 'app/models/breadcrumb.model';
import { CompanyFeatures, OfficeLocation } from 'app/models/company.model';
import { GoalKeyResult } from 'app/models/goals/goal-key-result.model';
import { GoalTag } from 'app/models/goals/goal-tag.model';
import { GoalStatusDto, GoalUpdateDto } from 'app/models/goals/goal.dto';
import {
  Goal,
  GoalPriority,
  GoalStatus,
  GoalType,
  GoalVisibility,
} from 'app/models/goals/goal.model';
import { OrganisationalUnit } from 'app/models/organisational-unit.model';
import { RoleName } from 'app/models/role.model';
import { User } from 'app/models/user/user.model';
import { CompanyAPIService } from 'app/shared/api/company.api.service';
import { GoalsAPIService } from 'app/shared/api/goals.api.service';
import { UserAPIService } from 'app/shared/api/user.api.service';
import { BreadcrumbService } from 'app/shared/breadcrumbs/breadcrumbs.service';
import { Globals } from 'app/shared/globals/globals';
import * as moment from 'moment-timezone';
import { forkJoin, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { GoalCreateService } from '../goals-create/goals-create.service';
import { GoalsIndividualActivityComponent } from './goals-individual-activity/goals-individual-activity.component';
import { GoalsIndividualUpdateComponent } from './goals-individual-update/goals-individual-update.component';

interface State {
  loading: boolean,
  error: boolean,
  submitted: boolean
  destroyed: boolean;
}

declare var $: any;
@Component({
  selector: 'app-goals-individual',
  templateUrl: './goals-individual.component.html',
  styleUrls: ['./goals-individual.component.css']
})
export class GoalsIndividualComponent implements OnInit, OnDestroy {
  readonly eGoalKeyResultType = GoalKeyResultType;
  readonly eGoalKeyResultMeasureUnitPlacement = GoalKeyResultMeasureUnitPlacement;
  @Input() showControls = true;

  @ViewChild(GoalsIndividualUpdateComponent) updateComponent?: GoalsIndividualUpdateComponent;
  @ViewChild(GoalsIndividualActivityComponent) activityComponent?: GoalsIndividualActivityComponent;

  eFeature = CompanyFeatures;

  subscriptions: Subscription[];
  state: State;

  eGoalVisibility = GoalVisibility;
  eGoalStatus = GoalStatus;
  eGoalType = GoalType;
  eGoalActivityType = GoalActivityType;
  eGoalPriority = GoalPriority;

  departments: OrganisationalUnit[];
  officeLocations: OfficeLocation[];

  goalId!: number;

  // For alignment
  goalsTeam: Goal[]
  goalsDepartment: Goal[]
  goalsOfficeLocation: Goal[]
  goalsCompany: Goal[]
  goals: Goal[] // all goals for alignment

  goal!: Goal;
  user!: User;

  departmentName: string;
  officeLocationName: string;

  alignedGoals: Goal[];
  alignedTo: Goal | null;

  // Edit Form //
  formEdit!: FormGroup;

  goalTitle!: FormControl;
  goalType!: FormControl;
  goalDepartment!: FormControl;
  goalOfficeLocation!: FormControl;
  goalAlignment!: FormControl;
  developmentNeeds!: FormControl;
  visibility!: FormControl;
  priority!: FormControl;
  endDate!: FormControl;

  editing: {
    all: boolean,
    owners: boolean,
    tags: boolean
  }

  users: User[]

  ownerFilter: {
    value: string,
    all: User[],
    filtered: User[],
    selected: User[]
  }
  tagFilter: {
    value: string,
    all: GoalTag[],
    filtered: GoalTag[],
    selected: GoalTag[]
  }

  roundProgress: {
    left: string,
    right: string
  }

  breadcrumb: Breadcrumb;

  topExpanded: boolean;

  userIsOwner: boolean;

  minDate: moment.Moment;

  editingResultsWarningVisible: boolean;

  get keyResults(): FormArray {
    return <FormArray>this.formEdit.controls.keyResults;
  }
  companyWording: CompanyWording;

  constructor(
    private goalsAPIService: GoalsAPIService,
    private userAPIService: UserAPIService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private router: Router,
    private goalCreateService: GoalCreateService,
    public globals: Globals,
    private cdRef: ChangeDetectorRef,
    private companyAPIService: CompanyAPIService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.companyWording = this.globals.company.companyWording;

    this.minDate = moment();

    this.roundProgress = {
      left: 'rotate(0)',
      right: 'rotate(0)'
    };
    this.state = {
      loading: true,
      error: false,
      submitted: false,
      destroyed: false
    };
    this.editing = {
      all: false,
      owners: false,
      tags: false,
    };
    this.ownerFilter = {
      value: '',
      all: [],
      filtered: [],
      selected: []
    };
    this.tagFilter = {
      value: '',
      all: [],
      filtered: [],
      selected: []
    };

    this.subscriptions = [];
    this.alignedGoals = [];
    this.officeLocations = [];
    this.departments = [];
    this.goalsTeam = [];
    this.goalsDepartment = [];
    this.goalsOfficeLocation = [];
    this.goalsCompany = [];
    this.goals = [];
    this.users = [];

    this.alignedTo = null;
    this.alignedTo = null;

    this.userIsOwner = false;
    this.topExpanded = false;
    this.editingResultsWarningVisible = true;

    this.departmentName = '';
    this.officeLocationName = '';

    this.breadcrumb = this.breadcrumbService.init(this.route);
  }

  unsavedChanges() {
    const changes = this.checkUnsaved();
    this.globals.hasUnsavedChanges = changes;
    return changes;
  }

  checkUnsaved(): boolean {
    this.cdRef.detectChanges();

    if (this.state.error) {
      return false;
    }

    if (this.editing.all) {
      return true;
    }

    if (this.updateComponent && this.updateComponent.unsavedChanges()) {
      return true;
    }

    if (this.activityComponent && this.activityComponent.unsavedChanges()) {
      return true;
    }

    return false;
  }

  // #region - LIFECYCLE HOOKS
  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {
    this.state.destroyed = true;
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.breadcrumbService.remove(this.breadcrumb);
  }
  // #endregion

  getData() {
    this.goalId = this.getGoalId()!;

    this.load();

    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if (this.route.children.length === 0) {
            this.getData();
          }
        }
      }),
      this.goalCreateService.getGoalUpdated().subscribe(() => this.load())
    ]
  }

  doError(message: string) {
    // TODO: Add custom message from function param
    this.state.error = true;
    this.state.loading = false;
  }

  getGoalId(): (number | undefined) {
    const goalId = this.route.snapshot.paramMap.get('id');

    if (goalId === null || isNaN(+goalId) === true) {
      this.doError('TODO:')
      return undefined;
    }

    return +goalId;
  }

  refresh($event: boolean) {
    if ($event === true) {
      this.state.loading = true;
      this.state.error = false;
      this.load();
    }
  }

  private initFormEdit(goal: Goal) {
    this.state.submitted = false;

    let departmentId = null;
    if (goal.department !== null) {
      if (this.departments.some(d => d.id === goal.department!.id)) {
        departmentId = goal.department.id;
      }
    }

    this.formEdit = this.formBuilder.group({
      goalTitle: new FormControl(goal.title, [Validators.required, Validators.maxLength(255)]),
      endDate: new FormControl(goal.endDate, [Validators.required]),
      visibility: new FormControl(goal.visibility, [Validators.required]),
      priority: new FormControl(goal.priority, [Validators.required]),
      goalType: new FormControl({
        value: goal.type,
        disabled: (this.alignedGoals.length > 0)
      }, [Validators.required]),
      goalAlignment: new FormControl(goal.alignment),
      goalDepartment: new FormControl(departmentId),
      goalOfficeLocation: new FormControl(goal.officeLocation ? goal.officeLocation.id : null),
      developmentNeeds: new FormControl(goal.developmentNeeds, [Validators.maxLength(255)]),
      keyResults: this.formBuilder.array([])
    });

    this.goalTitle = this.formEdit.controls.goalTitle as FormControl;
    this.goalType = this.formEdit.controls.goalType as FormControl;
    this.goalDepartment = this.formEdit.controls.goalDepartment as FormControl;
    this.goalOfficeLocation = this.formEdit.controls.goalOfficeLocation as FormControl;
    this.goalAlignment = this.formEdit.controls.goalAlignment as FormControl;
    this.developmentNeeds = this.formEdit.controls.developmentNeeds as FormControl;
    this.endDate = this.formEdit.controls.endDate as FormControl;
    this.visibility = this.formEdit.controls.visibility as FormControl;
    this.priority = this.formEdit.controls.priority as FormControl;

    goal.keyResults.forEach(result => {
      this.editAddKeyResult(this.keyResults, result);
    });

    // init selected owners
    this.ownerFilter.selected = goal.owners.filter(o => o);
    this.ownerFilter.all = this.ownerFilter.all.filter(o => !goal.owners.some(u => u.id === o.id));

    // init selected tags
    this.tagFilter.selected = goal.tags.filter(t => t);
    this.tagFilter.all = this.tagFilter.all.filter(o => !this.tagFilter.selected.some(u => u.id === o.id));

    // set up listeners
    this.goalType.valueChanges.subscribe(type => {
      this.goalAlignment.setValue(null);
      this.goals = this.getGoalsForAlignment(type);
      switch (type) {
        case GoalType.PERSONAL_DEVELOPMENTAL:
          this.developmentNeeds.setValue('');
          break;
        case GoalType.PERSONAL_OPERATIONAL:
          break;
        case GoalType.TEAM:
          break;
        case GoalType.DEPARTMENT:
          this.visibility.setValue(GoalVisibility.PUBLIC);
          break;
        case GoalType.OFFICE_LOCATION:
          this.visibility.setValue(GoalVisibility.PUBLIC);
          break;
        case GoalType.COMPANY:
          break;
      }
    });
  }

  private initKeyResult(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(null, []),
      result: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      type: new FormControl(null, [Validators.required]),
      measureCurrentValue: new FormControl(0, [Validators.required, Validators.pattern(/^(\d{1,22})(.\d{1,2})?$/)]),
      measureStartValue: new FormControl(0, [Validators.required, Validators.pattern(/^(\d{1,22})(.\d{1,2})?$/)]),
      measureGoalValue: new FormControl(1, [Validators.required, Validators.pattern(/^(\d{1,22})(.\d{1,2})?$/)]),
      endDate: new FormControl(null, []),
      reversed: new FormControl(true, []),
      measureUnit: new FormControl('', []),
      measureUnitPlacement: new FormControl(GoalKeyResultMeasureUnitPlacement.BEFORE, [])
    }, {
      validator: FrankliValidators.goalCompletionValidator,
    });
  }

  private editAddKeyResult(formArray: FormArray, result: GoalKeyResult) {
    const formControl = this.initKeyResult();

    formControl.controls.id.setValue(result.id);
    formControl.controls.result.setValue(result.result);
    formControl.controls.type.setValue(result.type);
    formControl.controls.measureCurrentValue.setValue(result.measureCurrentValue);
    formControl.controls.measureStartValue.setValue(result.measureStartValue);
    formControl.controls.measureGoalValue.setValue(result.measureGoalValue);
    formControl.controls.endDate.setValue(result.endDate);
    formControl.controls.reversed.setValue(result.reversed);
    formControl.controls.measureUnit.setValue(result.measureUnit);
    formControl.controls.measureUnitPlacement.setValue(result.measureUnitPlacement);
    // TODO: move whole form types to enums
    // TODO: remove code duplication
    formControl.controls.type.valueChanges.subscribe(type => {

      let current = 0;
      let start = 0;
      let target = 0;

      switch (type) {
        case GoalKeyResultType.BINARY:
          current = 0;
          start = 0;
          target = 1;
          break;
        case GoalKeyResultType.NUMERIC:
          current = 0;
          start = 0;
          target = 100;
          break;
        default:
          break;
      }
      formControl.controls.measureCurrentValue.setValue(current);
      formControl.controls.measureStartValue.setValue(start);
      formControl.controls.measureGoalValue.setValue(target);
    });

    formArray.push(formControl);
  }

  addKeyResult(formArray: FormArray) {
    const formControl = this.initKeyResult();

    formControl.controls.type.valueChanges.subscribe(type => {

      let current = 0;
      let start = 0;
      let target = 0;

      switch (type) {
        case GoalKeyResultType.BINARY:
          current = 0;
          start = 0;
          target = 1;
          break;
        case GoalKeyResultType.NUMERIC:
          current = 0;
          start = 0;
          target = 100;
          break;
        default:
          break;
      }
      formControl.controls.measureCurrentValue.setValue(current);
      formControl.controls.measureStartValue.setValue(start);
      formControl.controls.measureGoalValue.setValue(target);
    });

    formArray.push(formControl);
  }

  private init(goal: Goal) {
    goal.keyResults.sort((a, b) => {
      const ageA = new Date(a.endDate!).getTime();
      const ageB = new Date(b.endDate!).getTime();
      if (ageA > ageB) {
        return 1;
      } else if (ageA < ageB) {
        return -1;
      } else {
        return 0;
      }
    });
    goal.activity.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB.getTime() - dateA.getTime();
    });
    this.goal = Goal.getGoalCompletionPercentage(goal);
    this.getRoundProgress(goal.completionPercentage);
  }

  private load() {
    this.userIsOwner = false;
    this.state.loading = true;
    this.alignedTo = null;
    this.alignedGoals = [];

    forkJoin(
      this.userAPIService.getAllUsers(),
      this.goalsAPIService.getTags(),
      this.goalsAPIService.getTeamGoalsActive(),
      this.goalsAPIService.getDepartmentGoalsActive(),
      this.goalsAPIService.getOfficeLocationGoalsActive(),
      this.goalsAPIService.getCompanyGoalsActive(),
      this.companyAPIService.getOrgByType('Department'),
      this.companyAPIService.getAllOfficeLocations(),
      this.goalsAPIService.getGoalById(this.goalId),
      this.goalsAPIService.getAlignedGoals(this.goalId)
    ).subscribe(([users, tags, teamGoals, departmentGoals, officeLocationGoals, companyGoals, departments, officeLocations, goal, alignedGoals]) => {
      this.users = User.sort.byFullName(users);
      this.ownerFilter.all = this.users.filter(u => u);
      this.tagFilter.all = tags;

      this.goalsTeam = teamGoals;
      this.goalsDepartment = departmentGoals;
      this.goalsOfficeLocation = officeLocationGoals;
      this.goalsCompany = companyGoals;
      this.departments = departments;
      this.officeLocations = officeLocations;

      this.alignedGoals = Goal.getGoalArrayCompletionPercentage(alignedGoals);

      if (goal.department && goal.department.name) {
        this.departmentName = goal.department.name;
      }

      if (goal.officeLocation) {
        this.officeLocationName = goal.officeLocation.name;
      }

      if (!this.state.destroyed && goal.title) {
        this.breadcrumb = this.breadcrumbService.updateCurrentBreadcrumb(goal.title, null);
      }

      this.showControls = (
        goal.owners.some((o: User) => o.id === this.globals.user.id)
        || this.globals.user.roles.some(role => { return role.name === RoleName.GOAL_COMPANY })
      );

      this.userIsOwner = !!(this.globals.user && goal && goal.owners && goal.owners.map(o => o.id).includes(this.globals.user.id));

      this.init(goal);

      if (goal.alignment !== null) {
        this.goalsAPIService.getGoalById(goal.alignment).subscribe(alignedTo => {
          this.alignedTo = Goal.getGoalCompletionPercentage(alignedTo);
          this.goals = this.getGoalsForAlignment(this.goal.type);
          this.state.loading = false;
        }, (failure: HttpErrorResponse) => {
          this.state.error = true;
          this.state.loading = false;
        });
      } else {
        this.goals = this.getGoalsForAlignment(this.goal.type);
        this.state.loading = false;
      }
    }, (failure: HttpErrorResponse) => {
      this.state.error = true;
      this.state.loading = false;
    }, () => {
      this.initFormEdit(this.goal);
    });


  }

  private getGoalsForAlignment(type: GoalType): Array<Goal> {
    let goals: Array<Goal> = new Array<Goal>();
    switch (type) {
      case GoalType.PERSONAL_DEVELOPMENTAL:
        goals = [...this.goalsTeam, ...this.goalsDepartment, ...this.goalsOfficeLocation, ...this.goalsCompany];
        break;
      case GoalType.PERSONAL_OPERATIONAL:
        goals = [...this.goalsTeam, ...this.goalsDepartment, ...this.goalsOfficeLocation, ...this.goalsCompany];
        break;
      case GoalType.TEAM:
        goals = [...this.goalsDepartment, ...this.goalsOfficeLocation, ...this.goalsCompany];
        break;
      case GoalType.DEPARTMENT:
        goals = [...this.goalsOfficeLocation, ...this.goalsCompany];
        break;
      case GoalType.OFFICE_LOCATION:
        goals = [...this.goalsDepartment, ...this.goalsCompany];
        break;
      case GoalType.COMPANY:
        goals = [];
        break;
    }

    if (this.alignedTo !== null
      && !goals.some(goal => goal.id === this.alignedTo!.id)
      && this.isValidAlignment(type, this.alignedTo.type)
    ) {
      goals.unshift(this.alignedTo);
    }

    return goals.filter(g => g.id !== this.goal.id);
  }

  private isValidAlignment(goalType: GoalType, alignedGoalType: GoalType): boolean {
    switch (goalType) {
      case GoalType.PERSONAL_DEVELOPMENTAL:
      case GoalType.PERSONAL_OPERATIONAL:
        if (alignedGoalType === GoalType.TEAM
          || alignedGoalType === GoalType.DEPARTMENT
          || alignedGoalType === GoalType.OFFICE_LOCATION
          || alignedGoalType === GoalType.COMPANY) {
          return true;
        }
        break;
      case GoalType.TEAM:
        if (alignedGoalType === GoalType.DEPARTMENT
          || alignedGoalType === GoalType.OFFICE_LOCATION
          || alignedGoalType === GoalType.COMPANY) {
          return true;
        }
        break;
      case GoalType.DEPARTMENT:
        if (alignedGoalType === GoalType.OFFICE_LOCATION || alignedGoalType === GoalType.COMPANY) {
          return true;
        }
        break;
      case GoalType.OFFICE_LOCATION:
        if (alignedGoalType === GoalType.DEPARTMENT || alignedGoalType === GoalType.COMPANY) {
          return true;
        }
        break;
      case GoalType.COMPANY:
        break;
    }

    return false;
  }
  // #endregion

  // #region - goal actions
  complete() {
    // Populate edit key results count and form groups
    // TODO: Loading
    // TODO: Error handling
    Swal.fire({
      title: 'Complete goal?',
      text: 'The goal will be marked as complete.',
      imageUrl: 'assets/img/swal-icons/Frankli_sure_icon-46.svg',
      imageWidth: 140,
      imageHeight: 140,
      showCancelButton: true,
      confirmButtonColor: '#54c6bb',
      confirmButtonText: 'Complete goal!'
    }).then((result) => {
      if (result.value) {
        const goalStatusDto = new GoalStatusDto(GoalActivityType.COMPLETE)
        this.goalsAPIService.updateStatus(this.goalId, goalStatusDto).subscribe((response: Goal) => {
          this.init(response);
          $.notify('This goal has been marked as complete');

          this.router.navigateByUrl('/goals?type=personal');
        });
      } else {
        Swal.fire({
          title: 'Cancelled',
          text: 'Goal will not be marked as complete',
          imageUrl: 'assets/img/swal-icons/Frankli_cancel_icon-45.svg',
          imageWidth: 140,
          imageHeight: 140,
          confirmButtonColor: '#54c6bb'
        })
      }
    });
  }

  archive() {
    let message = '';
    if (this.goal.owners.length > 1) {
      message += 'This goal will be archived for all goal owners, not just yourself. \n';
    }

    Swal.fire({
      title: 'Archive Goal?',
      text: message,
      imageUrl: 'assets/img/swal-icons/Frankli_sure_icon-46.svg',
      imageWidth: 140,
      imageHeight: 140,
      showCancelButton: true,
      confirmButtonColor: '#54c6bb',
      confirmButtonText: 'Archive goal'
    }).then((result) => {
      if (result.value) {
        const goalStatusDto = new GoalStatusDto(GoalActivityType.ARCHIVE)
        this.goalsAPIService.updateStatus(this.goalId, goalStatusDto).subscribe((response: Goal) => {
          this.init(response);
          $.notify('This goal has been successfully archived');
          this.router.navigateByUrl('/goals?type=personal');
        });
      } else {
        Swal.fire({
          title: 'Cancelled',
          text: 'Goal will not be archived',
          imageUrl: 'assets/img/swal-icons/Frankli_cancel_icon-45.svg',
          imageWidth: 140,
          imageHeight: 140,
          confirmButtonColor: '#54c6bb'
        })
      }
    });
  }

  unarchive() {
    // TODO: Loading
    // TODO: Error handling
    Swal.fire({
      title: 'Unarchive Goal?',
      text: 'The goal will no longer be marked as archived.',
      imageUrl: 'assets/img/swal-icons/Frankli_sure_icon-46.svg',
      imageWidth: 140,
      imageHeight: 140,
      showCancelButton: true,
      confirmButtonColor: '#54c6bb',
      confirmButtonText: 'Unarchive goal'
    }).then((result) => {
      if (result.value) {
        const goalStatusDto = new GoalStatusDto(GoalActivityType.UNARCHIVE)
        this.goalsAPIService.updateStatus(this.goalId, goalStatusDto).subscribe((response: Goal) => {
          this.init(response);
          $.notify('This goal is no longer archived');
        })
      } else {
        Swal.fire({
          title: 'Cancelled',
          text: 'Goal will not be unarchived',
          imageUrl: 'assets/img/swal-icons/Frankli_cancel_icon-45.svg',
          imageWidth: 140,
          imageHeight: 140,
          confirmButtonColor: '#54c6bb'
        })
      }
    });
  }

  active() {
    // TODO: Loading
    // TODO: Error handling
    Swal.fire({
      title: 'Mark goal as active Goal?',
      text: 'The goal will be marked as active.',
      imageUrl: 'assets/img/swal-icons/Frankli_sure_icon-46.svg',
      imageWidth: 140,
      imageHeight: 140,
      showCancelButton: true,
      confirmButtonColor: '#54c6bb',
      confirmButtonText: 'Reactivate goal'
    }).then((result) => {
      if (result.value) {
        const goalStatusDto = new GoalStatusDto(GoalActivityType.INCOMPLETE)
        this.goalsAPIService.updateStatus(this.goalId, goalStatusDto).subscribe((response: Goal) => {
          this.init(response);
          $.notify('This goal has been marked as active');
        });
      } else {
        Swal.fire({
          title: 'Cancelled',
          text: 'Goal will not be marked as active',
          imageUrl: 'assets/img/swal-icons/Frankli_cancel_icon-45.svg',
          imageWidth: 140,
          imageHeight: 140,
          confirmButtonColor: '#54c6bb'
        })
      }
    });
  }

  delete() {
    // TODO: Loading
    // TODO: Error handling
    let message = '';
    if (this.goal.owners.length > 1) {
      message += 'This goal will be deleted for all goal owners, not just yourself. \n';
    }
    if (this.alignedGoals.length > 0) {
      message += 'This goal has goals aligned to it, this alignment will be removed. \n';
    }
    message += 'This cannot be undone.';

    Swal.fire({
      title: 'Delete this goal?',
      text: message,
      imageUrl: 'assets/img/swal-icons/Frankli_sure_icon-46.svg',
      imageWidth: 140,
      imageHeight: 140,
      showCancelButton: true,
      confirmButtonColor: '#54c6bb',
      confirmButtonText: 'Delete goal'
    }).then((result) => {
      if (result.value) {
        this.goalsAPIService.deleteGoal(this.goal.id).subscribe(() => {
          $.notify('The goal has been successfully deleted');
          this.router.navigateByUrl('/goals?type=personal');
        });
      } else {
        Swal.fire({
          title: 'Cancelled',
          text: 'Goal will not be deleted',
          imageUrl: 'assets/img/swal-icons/Frankli_cancel_icon-45.svg',
          imageWidth: 140,
          imageHeight: 140,
          confirmButtonColor: '#54c6bb'
        })
      }
    });
  }
  // #endregion

  getPercentage(result: GoalKeyResult) {

    const percentage = result.getProgress();
    return percentage.toFixed(0);
  }

  goToAlignedGoal(goal: Goal) {
    const url = `/goals/individual/${goal.id}`;
    this.router.navigateByUrl(url);
  }

  focusElement(e: ElementRef) {
    this.cdRef.detectChanges();
    e.nativeElement.focus();
  }

  // Enable editing goal
  enableEdit() {
    if (!this.editing.all) {
      this.globals.hasUnsavedChanges = true;
      this.editing.all = true;
      this.editingResultsWarningVisible = true;
      // Check for errors and push to service if valid
    } else if (this.editing.all) {
      this.state.submitted = true;

      const department = this.departments.find(d => d.id === this.goalDepartment.value);
      const officeLocation = this.officeLocations.find(o => o.id === this.goalOfficeLocation.value);

      if (this.formValid() === true) {
        // Create goal object
        const goal: Goal = {
          id: this.goal.id,
          creationDate: this.goal.creationDate,
          completionDate: this.goal.completionDate,
          complete: this.goal.complete,
          archived: this.goal.archived,
          activity: this.goal.activity,
          status: this.goal.status,
          completionPercentage: this.goal.completionPercentage,
          title: this.goalTitle.value,
          type: this.goalType.value,
          department: ((department !== undefined) ? department : null),
          officeLocation: ((officeLocation !== undefined) ? officeLocation : null),
          endDate: this.formDateToDateObject(this.endDate)!,
          visibility: this.visibility.value,
          priority: this.priority.value,
          keyResults: [],
          tags: this.tagFilter.selected,
          owners: this.ownerFilter.selected,
          alignment: this.goalAlignment.value,
          developmentNeeds: this.developmentNeeds.value
        }

        // Add key results
        const results = new Array<GoalKeyResult>();
        // converting form array to itterate of the form groups to key out each goalKeyResult
        const formArray = <FormArray>this.formEdit.controls.keyResults;
        formArray.controls.forEach(i => {

          const formGroup = <FormGroup>i;

          const goalKeyResult = new GoalKeyResult();
          goalKeyResult.id = formGroup.controls.id.value
          goalKeyResult.result = formGroup.controls.result.value;
          goalKeyResult.type = formGroup.controls.type.value;
          goalKeyResult.measureCurrentValue = formGroup.controls.measureCurrentValue.value;
          goalKeyResult.measureStartValue = formGroup.controls.measureStartValue.value;
          goalKeyResult.measureGoalValue = formGroup.controls.measureGoalValue.value;
          goalKeyResult.endDate = this.formDateToDateObject(formGroup.controls.endDate);
          goalKeyResult.reversed = (goalKeyResult.measureStartValue > goalKeyResult.measureGoalValue);
          if (!goalKeyResult.id) {
            goalKeyResult.measureCurrentValue = formGroup.controls.measureStartValue.value;
          }
          goalKeyResult.measureUnit = formGroup.controls.measureUnit.value;
          goalKeyResult.measureUnitPlacement = formGroup.controls.measureUnitPlacement.value;
          results.push(goalKeyResult);
        });

        goal.keyResults = results;

        // validate end date is after all result end dates
        let validDates = true;
        goal.keyResults.forEach(result => {
          if (this.validateEndDate(goal.endDate, result.endDate) === false) {
            validDates = false;
          }
        })

        if (validDates === true) {
          // Push to service
          this.postEditedGoal(goal);
          this.editing.all = false;
          this.editing.owners = false;
          this.editing.tags = false;
        } else {
          Swal.fire({
            title: 'Please correct the errors below',
            text: 'Key result end date must be before Goal end date',
            // type: 'warning', // FIXME:REMOVED: Removed in swal package update
            confirmButtonColor: '#54c6bb'
          });
        }

        // Swal a notification with all the errors
      }
    }
  }

  postEditedGoal(g: Goal) {
    const goalUpdateDto = new GoalUpdateDto(g);

    const request = this.goalsAPIService.updateGoal(g.id, goalUpdateDto);

    if (this.formEdit.valid) {
      this.state.loading = true;
      if (request !== null) {
        request.subscribe(() => {
          $.notify('Your goal has been successfully updated');
          this.refresh(true);
        }, (_failure: HttpErrorResponse) => {
          this.state.loading = false;
        });
      }
    }
  }

  private formValid(): boolean {
    let valid = true;
    let message = '';

    if (this.formEdit.invalid) {
      valid = false;
      message = '';
    }

    // department required for department goal
    if (this.keyResults.controls.length === 0) {
      valid = false;
      message = 'A goal requires at least 1 key result';
    }

    // department required for department goal
    if (this.goalType.value === GoalType.DEPARTMENT && this.goalDepartment.value === null) {
      valid = false;
      message = 'A ' + this.companyWording.department.toLowerCase() + ' goal requires you select a ' + this.companyWording.department.toLowerCase();
    }

    // owner required
    if (this.ownerFilter.selected.length === 0) {
      valid = false;
      message = 'At least 1 goal owner is required';
    }

    // owner needs to be self or DR if not admin
    if (!this.globals.hasRole(RoleName.GOAL_COMPANY) && !this.ownerFilter.selected.some(o => {
      return (o.id === this.globals.user.id || o.managerId === this.globals.user.id);
    })) {
      valid = false;
      message = 'At least 1 goal owner needs to be yourself or someone you manage';
    }

    if (valid === false) {
      Swal.fire({
        title: 'Please correct the errors below',
        text: message,
        // type: 'warning', // FIXME:REMOVED: Removed in swal package update
        confirmButtonColor: '#54c6bb'
      });
      return false;
    } else {
      return true;
    }

  }

  // Cancel editing goal
  cancelEdit() {
    this.state.loading = true;

    // Reset goal and FormControls
    this.initFormEdit(this.goal);

    // Reset all edit toggles
    this.editing = {
      all: false,
      owners: false,
      tags: false,
    }

    this.state.loading = false;
  }

  deleteKeyResult(formArray: FormArray, index: number) {
    formArray.removeAt(index);
  }

  private formDateToDateObject(control: AbstractControl): Date | null {
    let date = control.value;
    if (date !== null) {
      // ensure date is in utc format to avoid timezone issues
      date = moment.utc(date).toDate();
    }

    return date;
  }

  //#region - Owner searching and editing
  toggleEditOwners() {
    this.editing.owners = !this.editing.owners;
    this.ownerFilter.value = '';
    this.filterOwners(this.ownerFilter.value);
  }

  filterOwners(e: any) {
    if (this.ownerFilter.value.trim() === '') {
      this.ownerFilter.filtered = [];
    } else {
      this.ownerFilter.filtered = User.sort.byFullName(
        this.ownerFilter.all
          .filter(owner => {
            return (owner.firstName + ' ' + owner.lastName).toLocaleLowerCase().includes(e.toLocaleLowerCase())
          })
      );
    }
  }

  addOwner(user: User) {
    if (!this.ownerFilter.selected.some(u => u.id === user.id)) {
      this.ownerFilter.selected.push(user);

      this.ownerFilter.all = this.ownerFilter.all.filter(o => o.id !== user.id);

      this.clearOwnerFilter();
      this.editing.owners = false;
    }
  }

  removeOwner(user: User) {
    this.ownerFilter.selected = this.ownerFilter.selected.filter(u => {
      return u !== user;
    });
    this.ownerFilter.all.push(user);
    this.filterOwners(this.ownerFilter.value);
  }

  clearOwnerFilter() {
    this.ownerFilter.value = '';
    this.filterOwners(this.ownerFilter.value);
  }

  openOwner(o: User) {
    this.router.navigate([(`/profile/${o.id}`)])
  }

  //#endregion

  //#region - Tag searching and editing
  toggleEditTags() {
    this.editing.tags = !this.editing.tags;
    this.tagFilter.value = '';
  }

  addTag(tag: GoalTag): void {
    if (!this.tagFilter.selected.includes(tag)) {
      // add tag to selected
      this.tagFilter.selected.push(tag);
      // remove tag from list
      this.tagFilter.all = this.tagFilter.all.filter(t => t.id !== tag.id);
      this.clearTagFilter();
      this.editing.tags = false;
    }
  }

  removeTag(tag: GoalTag): void {
    // add tag to all
    this.tagFilter.all.push(tag);
    // remove from selected
    this.tagFilter.selected = this.tagFilter.selected.filter(t => {
      return t !== tag;
    });
    this.filterTags(this.tagFilter.value);
  }

  filterTags(e: any): void {
    if (this.tagFilter.value.trim() === '') {
      this.tagFilter.filtered = [];
    } else {
      this.tagFilter.filtered = this.tagFilter.all
        .filter(tag => {
          return tag.text.toLocaleLowerCase().includes(e.toLocaleLowerCase());
        })
        .filter(tag => {
          return !(this.tagFilter.selected.find(t => { return t.id === tag.id }))
        }).sort((a, b) => {
          return +(a.text.toLocaleLowerCase() > b.text.toLocaleLowerCase())
        });
    }
  }

  clearTagFilter(): void {
    this.tagFilter.value = '';
    this.filterTags(this.tagFilter.value);
  }

  openTag(t: GoalTag) {
    this.router.navigate([`/goals`], { queryParams: { type: 'tags', tagId: t.id } })
  }
  //#endregion

  private validateEndDate(goalEndDate: Date, resultEndDate: Date | null): boolean {
    if (resultEndDate === null) {
      return true
    }
    if (goalEndDate.getTime() >= resultEndDate.getTime()) {
      return true;
    }
    return false;
  }

  getRoundProgress(progress: number) {
    let left = 0;
    let right = 0;
    if (progress > 0) {
      if (progress <= 50) {
        right = (progress / 100 * 360)
      } else {
        right = 180;
        left = ((progress - 50) / 100 * 360)
      }
    }
    this.roundProgress = {
      left: `rotate(${left}deg)`,
      right: `rotate(${right}deg)`
    };
  }

  getDaysLeft(d: Date): string {
    try {

      const a = moment(d);
      const b = moment(new Date());

      const difference = a.diff(b, 'days');
      if (difference === 0) {
        return 'Due today';
      } else if (difference === 1) {
        return '1 day remaining';
      } else if (difference > 1) {
        return difference + ' days remaining';
      } else if (difference === -1) {
        return '1 day overdue';
      } else if (difference < -1) {
        return (-1 * difference) + ' days overdue';
      }

      return '';

    } catch (e) {
      // TODO: error thrown on goal end date updated due to end date being in a different format before posting
      // quick fix since release pending
      return '';
    }
  }

  getAbstractAsFormGroup(abstractControl: AbstractControl): FormGroup {
    return <FormGroup>abstractControl;
  }

  getProgressWidth(keyResult: FormGroup): number {
    const currentValue = keyResult.controls.measureCurrentValue.value;
    const startValue = keyResult.controls.measureStartValue.value;
    const goalValue = keyResult.controls.measureGoalValue.value;

    const currentMinusStart = (currentValue - startValue);
    const goalMinusStart = (goalValue - startValue);
    let value = ((currentMinusStart / goalMinusStart) * 100)

    // Make sure there's a value or it wont show
    if (value <= 0) {
      return 0.01;
    }

    // Constrain the value to the progress bar
    if (value >= 100) {
      return 100;
    }

    return value;
  }
}
