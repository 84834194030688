<div class="display-container" *ngIf="feedback">
  <!-- Sent by X to Y on Date -->
  <div class="area-metadata" *ngIf="(!feedback.anonymous && feedback.sourceUser && showSourceUser) || (feedback.destinationUser && showDestinationUser) || (feedback.timestamp)">
    <span>Sent</span>

    <!-- Destination -->
    <span class="text-nowrap" *ngIf="feedback.destinationUser && showDestinationUser">
      <span>to</span>
      <span class="area-destination-user">
        <span class="user-picture">
          <app-profile-photo [src]="feedback.destinationUser.imageUrl"></app-profile-photo>
        </span>
        <span>{{feedback.destinationUser.firstName}} {{feedback.destinationUser.lastName}}</span>
      </span>
    </span>

    <!-- Source user -->
    <span class="text-nowrap" *ngIf="!feedback.anonymous && feedback.sourceUser && showSourceUser">
      <span>by</span>
      <span class="area-source-user">
        <span class="user-picture">
          <app-profile-photo [src]="feedback.sourceUser.imageUrl"></app-profile-photo>
        </span>
        <span>{{feedback.sourceUser.firstName}} {{feedback.sourceUser.lastName}}</span>
      </span>
    </span>

    <!-- Timestamp -->
    <span class="text-nowrap" *ngIf="feedback.timestamp">
      <span>on</span>
      <span class="area-timestamp">{{feedback.timestamp | date}}</span>
    </span>
  </div>

  <!-- Feeling -->
  <div class="area-feeling">
    <div class="feedback-question">How are you feeling?</div>
    <div class="feedback-answer">
      <ng-container *ngIf="feedback.feeling; else noAnswer;">
        <ng-container *ngIf="(feedback.feeling.text !== 'Other'); else displayFeelingCustom">
          <div>{{feedback.feeling.text}}</div>
        </ng-container>
        <ng-template #displayFeelingCustom>
          <div>{{feedback.feelingCustom}}</div>
        </ng-template>
      </ng-container>
    </div>
  </div>
  
  <!-- Context -->
  <div class="area-context">
    <div class="feedback-question">In what context?</div>
    <div class="feedback-answer">
      <ng-container *ngIf="feedback.context; else noAnswer;">
        <ng-container *ngIf="(feedback.context.text !== 'Other'); else displayContextCustom">
          <div>{{feedback.context.text}}</div>
        </ng-container>
        <ng-template #displayContextCustom>
          <div>{{feedback.contextCustom}}</div>
        </ng-template>
      </ng-container>
    </div>
  </div>
  
  <!-- impact -->
  <div class="area-impact">
    <div class="feedback-question">How is this impacting you?</div>
    <div class="feedback-answer">
      <ng-container *ngIf="feedback.impact; let answer; else noAnswer;">
        <app-rich-display [text]="answer"></app-rich-display>
      </ng-container>
    </div>
  </div>
  
  <!-- Proceed -->
  <div class="area-proceed">
    <div class="feedback-question">How would you like to proceed?</div>
    <div class="feedback-answer">
      <ng-container *ngIf="feedback.proceed; else noAnswer;">
        <ng-container *ngIf="(feedback.proceed.text !== 'Other'); else displayProceedCustom">
          <div>{{feedback.proceed.text}}</div>
        </ng-container>
        <ng-template #displayProceedCustom>
          <div>{{feedback.proceedCustom}}</div>
        </ng-template>
      </ng-container>
    </div>
  </div>
  
  <!-- Message -->
  <div class="area-message">
    <div class="feedback-question">Any further thoughts?</div>
    <div class="feedback-answer">
      <ng-container *ngIf="feedback.message; let answer; else noAnswer;">
        <app-rich-display [text]="answer"></app-rich-display>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #noAnswer>
  <div>N/A</div>
</ng-template>