import { GoalAnalytics } from '@app/models/analytics/goal-analytics.model';

export const mockGoalAnalytics: GoalAnalytics = {
    all: {
        name: 'All',
        entityId: 13,
        totalGoals: 43,
        averageProgress: 41,
        usersWithGoalsCount: 19,
        usersWithoutGoals: [],
        priorities: {
            P1: 25,
            P2: 6,
            P3: 5,
            P4: 2,
            P5: 0,
            NOT_SET: 5
        },
        total: {
            JANUARY: 11,
            FEBRUARY: 1,
            MARCH: 6,
            APRIL: 4,
            MAY: 1,
            JUNE: 8,
            JULY: 1,
            AUGUST: 0,
            SEPTEMBER: 2,
            OCTOBER: 4,
            NOVEMBER: 2,
            DECEMBER: 3
        },
        status: {
            OFF_TRACK: 2,
            PROGRESSING: 5,
            ON_TRACK: 36
        }
    },
    company: {
        name: 'Company',
        entityId: 13,
        totalGoals: 2,
        averageProgress: 87,
        usersWithGoalsCount: 3,
        usersWithoutGoals: [],
        priorities: {
            P1: 2,
            P2: 0,
            P3: 0,
            P4: 0,
            P5: 0,
            NOT_SET: 0
        },
        total: {
            JANUARY: 0,
            FEBRUARY: 0,
            MARCH: 2,
            APRIL: 0,
            MAY: 0,
            JUNE: 0,
            JULY: 0,
            AUGUST: 0,
            SEPTEMBER: 0,
            OCTOBER: 0,
            NOVEMBER: 0,
            DECEMBER: 0
        },
        status: {
            OFF_TRACK: 0,
            PROGRESSING: 0,
            ON_TRACK: 2
        }
    },
    personal: {
        name: 'Personal',
        entityId: 13,
        totalGoals: 19,
        averageProgress: 31,
        usersWithGoalsCount: 13,
        usersWithoutGoals: [],
        priorities: {
            P1: 7,
            P2: 3,
            P3: 2,
            P4: 2,
            P5: 0,
            NOT_SET: 5
        },
        total: {
            JANUARY: 2,
            FEBRUARY: 1,
            MARCH: 3,
            APRIL: 1,
            MAY: 0,
            JUNE: 4,
            JULY: 1,
            AUGUST: 0,
            SEPTEMBER: 2,
            OCTOBER: 3,
            NOVEMBER: 2,
            DECEMBER: 0
        },
        status: {
            OFF_TRACK: 1,
            PROGRESSING: 2,
            ON_TRACK: 16
        }
    },
    sites: [
        {
            name: 'Frankli HQ',
            entityId: 422,
            totalGoals: 1,
            averageProgress: 49,
            usersWithGoalsCount: 2,
            usersWithoutGoals: [],
            priorities: {
                P1: 1,
                P2: 0,
                P3: 0,
                P4: 0,
                P5: 0,
                NOT_SET: 0
            },
            total: {
                JANUARY: 0,
                FEBRUARY: 0,
                MARCH: 0,
                APRIL: 0,
                MAY: 0,
                JUNE: 0,
                JULY: 0,
                AUGUST: 0,
                SEPTEMBER: 0,
                OCTOBER: 0,
                NOVEMBER: 0,
                DECEMBER: 1
            },
            status: {
                OFF_TRACK: 1,
                PROGRESSING: 0,
                ON_TRACK: 0
            }
        }
    ],
    departments: [
        {
            name: 'People & Culture',
            entityId: 92,
            totalGoals: 8,
            averageProgress: 37,
            usersWithGoalsCount: 2,
            usersWithoutGoals: [],
            priorities: {
                P1: 6,
                P2: 1,
                P3: 1,
                P4: 0,
                P5: 0,
                NOT_SET: 0
            },
            total: {
                JANUARY: 5,
                FEBRUARY: 0,
                MARCH: 0,
                APRIL: 1,
                MAY: 0,
                JUNE: 1,
                JULY: 0,
                AUGUST: 0,
                SEPTEMBER: 0,
                OCTOBER: 1,
                NOVEMBER: 0,
                DECEMBER: 0
            },
            status: {
                OFF_TRACK: 0,
                PROGRESSING: 3,
                ON_TRACK: 5
            }
        },
        {
            name: 'Leadership',
            entityId: 90,
            totalGoals: 4,
            averageProgress: 73,
            usersWithGoalsCount: 5,
            usersWithoutGoals: [],
            priorities: {
                P1: 4,
                P2: 0,
                P3: 0,
                P4: 0,
                P5: 0,
                NOT_SET: 0
            },
            total: {
                JANUARY: 1,
                FEBRUARY: 0,
                MARCH: 0,
                APRIL: 1,
                MAY: 0,
                JUNE: 0,
                JULY: 0,
                AUGUST: 0,
                SEPTEMBER: 0,
                OCTOBER: 0,
                NOVEMBER: 0,
                DECEMBER: 2
            },
            status: {
                OFF_TRACK: 0,
                PROGRESSING: 0,
                ON_TRACK: 4
            }
        },
        {
            name: 'Marketing',
            entityId: 163,
            totalGoals: 6,
            averageProgress: 53,
            usersWithGoalsCount: 1,
            usersWithoutGoals: [],
            priorities: {
                P1: 4,
                P2: 1,
                P3: 1,
                P4: 0,
                P5: 0,
                NOT_SET: 0
            },
            total: {
                JANUARY: 3,
                FEBRUARY: 0,
                MARCH: 1,
                APRIL: 1,
                MAY: 1,
                JUNE: 0,
                JULY: 0,
                AUGUST: 0,
                SEPTEMBER: 0,
                OCTOBER: 0,
                NOVEMBER: 0,
                DECEMBER: 0
            },
            status: {
                OFF_TRACK: 0,
                PROGRESSING: 0,
                ON_TRACK: 6
            }
        },
        {
            name: 'Sales',
            entityId: 317,
            totalGoals: 3,
            averageProgress: 23,
            usersWithGoalsCount: 1,
            usersWithoutGoals: [],
            priorities: {
                P1: 1,
                P2: 1,
                P3: 1,
                P4: 0,
                P5: 0,
                NOT_SET: 0
            },
            total: {
                JANUARY: 0,
                FEBRUARY: 0,
                MARCH: 0,
                APRIL: 0,
                MAY: 0,
                JUNE: 3,
                JULY: 0,
                AUGUST: 0,
                SEPTEMBER: 0,
                OCTOBER: 0,
                NOVEMBER: 0,
                DECEMBER: 0
            },
            status: {
                OFF_TRACK: 0,
                PROGRESSING: 0,
                ON_TRACK: 3
            }
        }
    ],
    team: {
        name: 'Team',
        entityId: 13,
        totalGoals: 0,
        averageProgress: 0,
        usersWithGoalsCount: 0,
        usersWithoutGoals: [],
        priorities: {
            P1: 0,
            P2: 0,
            P3: 0,
            P4: 0,
            P5: 0,
            NOT_SET: 0
        },
        total: {
            JANUARY: 0,
            FEBRUARY: 0,
            MARCH: 0,
            APRIL: 0,
            MAY: 0,
            JUNE: 0,
            JULY: 0,
            AUGUST: 0,
            SEPTEMBER: 0,
            OCTOBER: 0,
            NOVEMBER: 0,
            DECEMBER: 0
        },
        status: {
            OFF_TRACK: 0,
            PROGRESSING: 0,
            ON_TRACK: 0
        }
    }
}

export const mockGoalAnalyticsAlternate: GoalAnalytics = {
    all: {
        name: 'All',
        entityId: 13,
        totalGoals: 25,
        averageProgress: 65,
        usersWithGoalsCount: 23,
        usersWithoutGoals: [],
        priorities: {
            P1: 18,
            P2: 2,
            P3: 1,
            P4: 2,
            P5: 0,
            NOT_SET: 2
        },
        total: {
            JANUARY: 8,
            FEBRUARY: 1,
            MARCH: 3,
            APRIL: 4,
            MAY: 6,
            JUNE: 1,
            JULY: 10,
            AUGUST: 2,
            SEPTEMBER: 12,
            OCTOBER: 5,
            NOVEMBER: 6,
            DECEMBER: 8
        },
        status: {
            OFF_TRACK: 2,
            PROGRESSING: 10,
            ON_TRACK: 13
        }
    },
    company: {
        name: 'Company',
        entityId: 13,
        totalGoals: 2,
        averageProgress: 92,
        usersWithGoalsCount: 6,
        usersWithoutGoals: [],
        priorities: {
            P1: 0,
            P2: 0,
            P3: 0,
            P4: 2,
            P5: 0,
            NOT_SET: 0
        },
        total: {
            JANUARY: 1,
            FEBRUARY: 1,
            MARCH: 0,
            APRIL: 0,
            MAY: 0,
            JUNE: 0,
            JULY: 0,
            AUGUST: 0,
            SEPTEMBER: 0,
            OCTOBER: 0,
            NOVEMBER: 0,
            DECEMBER: 0
        },
        status: {
            OFF_TRACK: 0,
            PROGRESSING: 1,
            ON_TRACK: 1
        }
    },
    personal: {
        name: 'Personal',
        entityId: 13,
        totalGoals: 31,
        averageProgress: 25,
        usersWithGoalsCount: 21,
        usersWithoutGoals: [],
        priorities: {
            P1: 10,
            P2: 2,
            P3: 2,
            P4: 9,
            P5: 0,
            NOT_SET: 8
        },
        total: {
            JANUARY: 5,
            FEBRUARY: 6,
            MARCH: 1,
            APRIL: 7,
            MAY: 5,
            JUNE: 0,
            JULY: 6,
            AUGUST: 8,
            SEPTEMBER: 1,
            OCTOBER: 8,
            NOVEMBER: 1,
            DECEMBER: 5
        },
        status: {
            OFF_TRACK: 2,
            PROGRESSING: 4,
            ON_TRACK: 10
        }
    },
    sites: [
        {
            name: 'Frankli HQ',
            entityId: 422,
            totalGoals: 1,
            averageProgress: 35,
            usersWithGoalsCount: 13,
            usersWithoutGoals: [],
            priorities: {
                P1: 0,
                P2: 0,
                P3: 0,
                P4: 1,
                P5: 0,
                NOT_SET: 0
            },
            total: {
                JANUARY: 0,
                FEBRUARY: 0,
                MARCH: 0,
                APRIL: 0,
                MAY: 0,
                JUNE: 0,
                JULY: 1,
                AUGUST: 0,
                SEPTEMBER: 0,
                OCTOBER: 0,
                NOVEMBER: 0,
                DECEMBER: 0
            },
            status: {
                OFF_TRACK: 0,
                PROGRESSING: 0,
                ON_TRACK: 1
            }
        }
    ],
    departments: [
        {
            name: 'People & Culture',
            entityId: 92,
            totalGoals: 10,
            averageProgress: 52,
            usersWithGoalsCount: 5,
            usersWithoutGoals: [],
            priorities: {
                P1: 4,
                P2: 2,
                P3: 0,
                P4: 4,
                P5: 0,
                NOT_SET: 0
            },
            total: {
                JANUARY: 3,
                FEBRUARY: 0,
                MARCH: 0,
                APRIL: 2,
                MAY: 0,
                JUNE: 2,
                JULY: 0,
                AUGUST: 0,
                SEPTEMBER: 0,
                OCTOBER: 3,
                NOVEMBER: 0,
                DECEMBER: 0
            },
            status: {
                OFF_TRACK: 3,
                PROGRESSING: 0,
                ON_TRACK: 7
            }
        },
        {
            name: 'Leadership',
            entityId: 90,
            totalGoals: 5,
            averageProgress: 76,
            usersWithGoalsCount: 10,
            usersWithoutGoals: [],
            priorities: {
                P1: 3,
                P2: 0,
                P3: 1,
                P4: 0,
                P5: 1,
                NOT_SET: 0
            },
            total: {
                JANUARY: 2,
                FEBRUARY: 0,
                MARCH: 1,
                APRIL: 0,
                MAY: 0,
                JUNE: 0,
                JULY: 0,
                AUGUST: 0,
                SEPTEMBER: 2,
                OCTOBER: 0,
                NOVEMBER: 0,
                DECEMBER: 0
            },
            status: {
                OFF_TRACK: 0,
                PROGRESSING: 3,
                ON_TRACK: 2
            }
        },
        {
            name: 'Marketing',
            entityId: 163,
            totalGoals: 12,
            averageProgress: 26,
            usersWithGoalsCount: 1,
            usersWithoutGoals: [],
            priorities: {
                P1: 5,
                P2: 4,
                P3: 2,
                P4: 0,
                P5: 1,
                NOT_SET: 0
            },
            total: {
                JANUARY: 2,
                FEBRUARY: 0,
                MARCH: 1,
                APRIL: 5,
                MAY: 2,
                JUNE: 0,
                JULY: 1,
                AUGUST: 0,
                SEPTEMBER: 0,
                OCTOBER: 1,
                NOVEMBER: 0,
                DECEMBER: 0
            },
            status: {
                OFF_TRACK: 0,
                PROGRESSING: 3,
                ON_TRACK: 9
            }
        },
        {
            name: 'Sales',
            entityId: 317,
            totalGoals: 4,
            averageProgress: 12,
            usersWithGoalsCount: 2,
            usersWithoutGoals: [],
            priorities: {
                P1: 2,
                P2: 1,
                P3: 0,
                P4: 0,
                P5: 0,
                NOT_SET: 1
            },
            total: {
                JANUARY: 2,
                FEBRUARY: 0,
                MARCH: 0,
                APRIL: 0,
                MAY: 0,
                JUNE: 0,
                JULY: 1,
                AUGUST: 0,
                SEPTEMBER: 1,
                OCTOBER: 0,
                NOVEMBER: 0,
                DECEMBER: 0
            },
            status: {
                OFF_TRACK: 0,
                PROGRESSING: 1,
                ON_TRACK: 3
            }
        }
    ],
    team: {
        name: 'Team',
        entityId: 13,
        totalGoals: 0,
        averageProgress: 0,
        usersWithGoalsCount: 0,
        usersWithoutGoals: [],
        priorities: {
            P1: 0,
            P2: 0,
            P3: 0,
            P4: 0,
            P5: 0,
            NOT_SET: 0
        },
        total: {
            JANUARY: 0,
            FEBRUARY: 0,
            MARCH: 0,
            APRIL: 0,
            MAY: 0,
            JUNE: 0,
            JULY: 0,
            AUGUST: 0,
            SEPTEMBER: 0,
            OCTOBER: 0,
            NOVEMBER: 0,
            DECEMBER: 0
        },
        status: {
            OFF_TRACK: 0,
            PROGRESSING: 0,
            ON_TRACK: 0
        }
    }
}
