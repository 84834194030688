import * as moment from "moment";
import { OfficeLocation } from "../company.model";
import { OrganisationalUnit } from "../organisational-unit.model";
import { User } from "../user/user.model";
import { GoalActivityServerside } from "./goal-activity-serverside.model";
import { GoalKeyResultMeasureUnitPlacement } from "./goal-key-result-measure-unit-placement.model";
import { GoalKeyResultType } from "./goal-key-result-type.model";
import { GoalStatus, GoalType, GoalVisibility, GoalPriority } from "./goal.model";

export class GoalActivitiy {
  id: number;
  user: User;
  date: Date;
  message: string;
  type: GoalActivityType;
  progressUpdate: GoalProgressUpdate | null;
  editUpdate: GoalActivityEdit | null;

  constructor(goalActivityServerside: GoalActivityServerside) {
    this.id = goalActivityServerside.id;
    this.user = goalActivityServerside.user;
    this.date = moment.utc(goalActivityServerside.date).toDate();
    this.message = goalActivityServerside.message;
    this.type = goalActivityServerside.type;
    this.progressUpdate = goalActivityServerside.progressUpdate;
    this.editUpdate = goalActivityServerside.editUpdate;
  }
}

export interface GoalProgressUpdate {
  id: number;
  status: GoalProgressUpdateStatus | null;
  keyResults: Array<GoalProgressUpdateKeyResult>;
}

export interface GoalProgressUpdateStatus {
  id: number;
  previous: GoalStatus;
  current: GoalStatus;
}

export interface GoalProgressUpdateKeyResult {
  id: number;
  keyResultId: number | null;
  name: string;
  type: GoalKeyResultType;
  currentValuePrevious: number;
  currentValueCurrent: number;
  targetValuePrevious: number;
  targetValueCurrent: number;
  measureUnit: string;
  measureUnitPlacement: GoalKeyResultMeasureUnitPlacement;
}

export interface GoalActivityEdit {
  id: number;

  titlePrevious: string | null;
  titleCurrent: string | null;

  typePrevious: GoalType | null;
  typeCurrent: GoalType | null;

  departmentPrevious: OrganisationalUnit | null;
  departmentCurrent: OrganisationalUnit | null;

  officeLocationPrevious: OfficeLocation | null;
  officeLocationCurrent: OfficeLocation | null;

  endDatePrevious: Date | null;
  endDateCurrent: Date | null;

  visibilityPrevious: GoalVisibility | null;
  visibilityCurrent: GoalVisibility | null;

  priorityPrevious: GoalPriority | null;
  priorityCurrent: GoalPriority | null;

  addedOwners: Array<User>;
  removedOwners: Array<User>;

  keyResultsRemoved: Array<string>;
  keyResultsAdded: Array<string>;
  keyResultsUpdated: Array<GoalActivityEditKeyResult>;
}

export interface GoalActivityEditKeyResult {
  id: number;

  resultPrevious: String | null;
  resultCurrent: String | null;

  measureTypePrevious: String | null;
  measureTypeCurrent: String | null;

  measureCurrentValuePrevious: number | null;
  measureCurrentValueCurrent: number | null;

  measureGoalValuePrevious: number | null;
  measureGoalValueCurrent: number | null;

  endDatePrevious: Date | null;
  endDateCurrent: Date | null;
}

export enum GoalActivityType {
  COMMENT = 'COMMENT',
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE',
  ARCHIVE = 'ARCHIVE',
  KEY_RESULT = 'KEY_RESULT',
  UNARCHIVE = 'UNARCHIVE',
  PROGRESS_UPDATE = 'PROGRESS_UPDATE',
  STATUS_UPDATE = 'STATUS_UPDATE'
}