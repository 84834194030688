<div class="row">

  <div class="col-xs-12">
    <form [formGroup]="form">

      <div class="row">
        <div class="col-xs-12 col-md-5">

          <!-- Profile Picture -->
          <div class="frankli-form-group frankli-form-group-filled m-top-15">
            <label>Profile Picture</label>
            <app-employee-upload-profile-picture-component [user]="user">
            </app-employee-upload-profile-picture-component>
          </div>

          <!-- Gender -->
          <div class="frankli-form-group frankli-form-group-filled m-top-15">
            <label>Gender</label>
            <div class="row">
              <div class="col-xs-6">
                <div class="radio">
                  <input type="radio" name="radioGender" id="radioMale" [value]="eUserGender.MALE"
                         [formControl]="gender">
                  <label for="radioMale">Male</label>
                </div>
              </div>
              <div class="col-xs-6">
                <div class="radio">
                  <input type="radio" name="radioGender" id="radioFemale" [value]="eUserGender.FEMALE"
                         [formControl]="gender">
                  <label for="radioFemale">Female</label>
                </div>
              </div>
              <div class="col-xs-6">
                <div class="radio">
                  <input type="radio" name="radioGender" id="radioOther" [value]="eUserGender.OTHER"
                         [formControl]="gender">
                  <label for="radioOther">Other</label>
                </div>
              </div>
              <div class="col-xs-6">
                <div class="radio">
                  <input type="radio" name="radioGender" id="radioUnspecified" [value]="eUserGender.UNSPECIFIED"
                         [formControl]="gender">
                  <label for="radioUnspecified">Prefer not to say</label>
                </div>
              </div>
            </div>
            <div *ngIf="gender.invalid && submitted" class="frankli-form-group-alert">
              <div *ngIf="gender.hasError('required')">Gender is required.</div>
            </div>
          </div>

        </div>

        <div class="col-xs-12 col-md-7">
          <!-- User bio -->
          <div class="frankli-form-group frankli-form-group-filled m-top-15">
            <label for="about-me">About me</label>
            <app-editor-response id="about-me" formControlName="description" [toolbar]="editorToolbar" [maxCharacters]="USER_CONSTANTS.DESCRIPTION_MAX_LENGTH" [hardMax]="USER_CONSTANTS.DESCRIPTION_MAX_LENGTH_RAW" [height]="250" [columnIndent]="false" [enforceCharacterLimit]="false"></app-editor-response>
            <div class="frankli-form-group-alert m-top-5">
              <ng-container *ngIf="description.invalid">
                <ng-container *ngIf="submitted">
                  <div *ngIf="description.hasError('required')">You must enter a description</div>
                </ng-container>
                <div *ngIf="description.hasError('maxlength')">Too much formatting</div>
                <div *ngIf="description.hasError('softmax')">Your submission must be between {{USER_CONSTANTS.DESCRIPTION_MIN_LENGTH}} and {{USER_CONSTANTS.DESCRIPTION_MAX_LENGTH}} characters.</div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>


    </form>
  </div>

  <!-- buttons -->
  <div class="col-xs-12 m-top-30">
    <ng-container [ngSwitch]="formType">
      <ng-container *ngSwitchCase="'EDIT'">
        <button *ngIf="!loading" class="btn-frankli-round btn-frankli-green pull-right"
                (click)="onFormSubmit()">Save</button>
        <button *ngIf="loading" class="btn-frankli-round btn-frankli-green pull-right">
          <span class="fal fa-spin fa-spinner"></span>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'SIGN_UP'">
        <button class="btn-frankli-round btn-frankli-gray pull-left" (click)="previous()">Previous</button>
        <button class="btn-frankli-round btn-frankli-green pull-right" (click)="onFormSubmit()">Next</button>
      </ng-container>
    </ng-container>
  </div>

</div>