import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OneToOneMeetingFile } from '@app/models/one-to-one/one-to-one-meeting-file';
import { TalkingPointTemplate } from '@app/models/one-to-one/talking-point-template.model';
import { CreateTodoDto, Todo, UpdateTodoDto } from '@app/models/todos/todos.model';
import { OneToOneDirectReportInfo } from 'app/models/one-to-one/one-to-one-direct-report-info.model';
import { OneToOneMeeting } from 'app/models/one-to-one/one-to-one-meeting.model';
import { OneToOneSchedule, PostReviewCycleOneToOneView } from 'app/models/one-to-one/one-to-one-schedule.model';
import { TalkingPoint } from 'app/models/one-to-one/talking-point.model';
import { Observable } from 'rxjs';
import { OneToOneService } from '@app/shared/api/interfaces/one-to-one.service';

@Injectable()
export class OneToOneAPIService implements OneToOneService {
  BASE_URL = 'api/one-to-one/';
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  html_headers = new HttpHeaders({
    'Content-Type': 'text/html',
  });
  constructor(private http: HttpClient) {
  }

  getOneToOneSchedulesForUserMe(): Observable<Array<OneToOneSchedule>> {
    const url = this.BASE_URL + 'schedule/me';
    return this.http.get<Array<OneToOneSchedule>>(url);
  }

  getMyOneToOneScheduleById(scheduleId: number): Observable<OneToOneSchedule> {
    const url = this.BASE_URL + 'schedule/me/' + scheduleId;
    return this.http.get<OneToOneSchedule>(url);
  }

  getOneToOneScheduleById(scheduleId: number): Observable<OneToOneSchedule> {
    const url = this.BASE_URL + 'schedules/' + scheduleId;
    return this.http.get<OneToOneSchedule>(url);
  }

  getOneToOneScheduleByIdManaged(scheduleId: number): Observable<OneToOneSchedule> {
    const url = this.BASE_URL + 'schedule/managed/' + scheduleId;
    return this.http.get<OneToOneSchedule>(url);
  }

  getOneToOneSchedulesByManagerId(managerId: number): Observable<Array<OneToOneSchedule>> {
    const url = this.BASE_URL + 'schedule/manager/' + managerId;
    return this.http.get<Array<OneToOneSchedule>>(url);
  }

  getOneToOneDirectReportInfo(): Observable<Array<OneToOneDirectReportInfo>> {
    const url = this.BASE_URL + 'schedule/manage/direct';
    return this.http.get<Array<OneToOneDirectReportInfo>>(url);
  }

  createOneToOneSchedule(schedule: OneToOneSchedule): Observable<OneToOneSchedule> {
    const url = this.BASE_URL + 'schedule/' + schedule.user.id;
    return this.http.post<OneToOneSchedule>(url, schedule, {headers: this.headers});
  }

  updateOneToOneSchedule(schedule: OneToOneSchedule, justNextMeeting: boolean): Observable<OneToOneSchedule> {
    const url = this.BASE_URL + 'schedule/' + schedule.id + '/update?justNextMeeting=' + justNextMeeting;
    return this.http.put<OneToOneSchedule>(url, schedule, {headers: this.headers});
  }

  updateOneToOneMeetingSharedNotes(scheduleId: number, sharedNotes: string, meetingId: number, autosave: boolean): Observable<OneToOneMeeting> {
    if (!sharedNotes) {
      sharedNotes = '<p></p>';
    }
    const url = this.BASE_URL + scheduleId + '/meeting/' + meetingId + '/update/shared-notes?autosave=' + autosave;
    return this.http.put<OneToOneMeeting>(url, sharedNotes, {headers: this.html_headers});
  }

  updateOneToOneMeetingStatus(scheduleId: number, meeting: OneToOneMeeting): Observable<OneToOneMeeting> {
    const url = this.BASE_URL + scheduleId + '/meeting/' + meeting.id + '/update';
    return this.http.put<OneToOneMeeting>(url, meeting, {headers: this.headers});
  }

  updateOneToOneMeetingPrivateNotes(scheduleId: number, privateNotes: string, meetingId: number, autosave: boolean): Observable<OneToOneMeeting> {
    if (!privateNotes) {
      privateNotes = '<p></p>';
    }
    const url = this.BASE_URL + scheduleId + '/meeting/' + meetingId + '/update/private-notes?autosave=' + autosave;
    return this.http.put<OneToOneMeeting>(url, privateNotes, {headers: this.html_headers});
  }

  addOneToOneMeetingTalkingPoint(talkingPoints: TalkingPoint, meetingId: number): Observable<Array<TalkingPoint>> {
    const url = this.BASE_URL + 'meeting/' + meetingId + '/update/talking-point';
    return this.http.put<Array<TalkingPoint>>(url, talkingPoints, {headers: this.headers});
  }

  updateMeetingTimestamp(oneToOneMeeting: OneToOneMeeting, meetingId: number): Observable<OneToOneMeeting> {
    const url = this.BASE_URL + 'meeting/' + meetingId + '/update/timestamp';
    return this.http.put<OneToOneMeeting>(url, oneToOneMeeting, {headers: this.headers});
  }

  removeOneToOneMeetingTalkingPoint(talkingPoints: TalkingPoint, meetingId: number): Observable<Array<TalkingPoint>> {
    const url = this.BASE_URL + 'meeting/' + meetingId + '/remove/talking-point';
    return this.http.put<Array<TalkingPoint>>(url, talkingPoints, {headers: this.headers});
  }

  bumpOneToOneMeetingTalkingPoint(talkingPoints: TalkingPoint, meetingId: number): Observable<Array<TalkingPoint>> {
    const url = this.BASE_URL + 'meeting/' + meetingId + '/bump/talking-point';
    return this.http.put<Array<TalkingPoint>>(url, talkingPoints, {headers: this.headers});
  }

  actionOneToOneMeetingTalkingPoint(talkingPoints: TalkingPoint, meetingId: number): Observable<Array<TalkingPoint>> {
    const url = this.BASE_URL + 'meeting/' + meetingId + '/action/talking-point';
    return this.http.put<Array<TalkingPoint>>(url, talkingPoints, {headers: this.headers});
  }

  commentOneToOneMeetingTalkingPoint(talkingPoints: TalkingPoint, meetingId: number): Observable<Array<TalkingPoint>> {
    const url = this.BASE_URL + 'meeting/' + meetingId + '/comment/talking-point';
    return this.http.put<Array<TalkingPoint>>(url, talkingPoints, {headers: this.headers});
  }

  getOneToOneSchedulesForManagerMe(): Observable<Array<OneToOneSchedule>> {
    const url = this.BASE_URL + 'schedules';
    return this.http.get<Array<OneToOneSchedule>>(url);
  }

  getArchivedOneToOneSchedulesForManagerMe(): Observable<Array<OneToOneSchedule>> {
    const url = this.BASE_URL + 'schedules/archived';
    return this.http.get<Array<OneToOneSchedule>>(url);
  }

  getArchivedOneToOneSchedulesForUserMe(): Observable<Array<OneToOneSchedule>> {
    const url = this.BASE_URL + 'schedules/archived/user/me';
    return this.http.get<Array<OneToOneSchedule>>(url);
  }

  getIncompleteFromPreviousTalkingPoints(scheduleId: number, meetingId: number): Observable<Array<TalkingPoint>> {
    const url = this.BASE_URL + scheduleId + '/meeting/' + meetingId + '/prev/talking-point';
    return this.http.get<Array<TalkingPoint>>(url);
  }

  addActionPoint(actionPoint: CreateTodoDto, scheduleId: number, userId: number): Observable<Todo> {
    const url = this.BASE_URL + 'schedule/' + scheduleId + '/add/action-point/' + userId;
    return this.http.put<Todo>(url, actionPoint, {headers: this.headers});
  }

  updateActionPoint(actionPoint: UpdateTodoDto, scheduleId: number, userId: number, todoId: number): Observable<Todo> {
    const url = this.BASE_URL + 'schedule/' + scheduleId + '/update/action-point/' + userId + '/' + todoId;
    return this.http.patch<Todo>(url, actionPoint, {headers: this.headers});
  }

  completeActionPoint(scheduleId: number, userId: number, todoId: number): Observable<Todo> {
    const url = this.BASE_URL + 'schedule/' + scheduleId + '/complete/action-point/' + userId + '/' + todoId;
    return this.http.patch<Todo>(url, {headers: this.headers});
  }

  incompleteActionPoint(scheduleId: number, userId: number, todoId: number): Observable<Todo> {
    const url = this.BASE_URL + 'schedule/' + scheduleId + '/incomplete/action-point/' + userId + '/' + todoId;
    return this.http.patch<Todo>(url, {headers: this.headers});
  }

  deleteActionPoint(scheduleId: number, userId: number, todoId: number): Observable<Todo> {
    const url = this.BASE_URL + 'schedule/' + scheduleId + '/delete/action-point/' + userId + '/' + todoId;
    return this.http.delete<Todo>(url, {headers: this.headers});
  }

  pauseSchedule(scheduleId: number): Observable<OneToOneSchedule> {
    const url = this.BASE_URL + 'schedule/' + scheduleId + '/pause';
    return this.http.put<OneToOneSchedule>(url, null, {headers: this.headers});
  }

  resumeSchedule(scheduleId: number): Observable<OneToOneSchedule> {
    const url = this.BASE_URL + 'schedule/' + scheduleId + '/resume';
    return this.http.put<OneToOneSchedule>(url, null, {headers: this.headers});
  }

  archiveSchedule(scheduleId: number): Observable<OneToOneSchedule> {
    const url = this.BASE_URL + 'schedule/' + scheduleId + '/archive';
    return this.http.put<OneToOneSchedule>(url, null, {headers: this.headers});
  }

  deleteSchedule(scheduleId: number): Observable<OneToOneSchedule> {
    const url = this.BASE_URL + 'schedule/' + scheduleId;
    return this.http.delete<OneToOneSchedule>(url, {headers: this.headers});
  }

  getTalkingPointTemplateById(id: number): Observable<TalkingPointTemplate> {
    const url = `${this.BASE_URL}template/${id}`;
    return this.http.get<TalkingPointTemplate>(url);
  }

  getTalkingPointTemplateByName(name: string): Observable<TalkingPointTemplate> {
    const url = `${this.BASE_URL}template/name/${name}`;
    return this.http.get<TalkingPointTemplate>(url);
  }

  getDefaultTalkingPointTemplate(): Observable<TalkingPointTemplate> {
    const url = `${this.BASE_URL}template/default`;
    return this.http.get<TalkingPointTemplate>(url);
  }

  getTalkingPointTemplates(): Observable<Array<TalkingPointTemplate>> {
    const url = `${this.BASE_URL}template`;
    return this.http.get<Array<TalkingPointTemplate>>(url);
  }

  createTalkingPointTemplate(talkingPointTemplate: TalkingPointTemplate): Observable<TalkingPointTemplate> {
    const url = `${this.BASE_URL}template`;
    return this.http.put<TalkingPointTemplate>(url, talkingPointTemplate);
  }

  updateTalkingPointTemplate(id: number, talkingPointTemplate: TalkingPointTemplate): Observable<TalkingPointTemplate> {
    const url = `${this.BASE_URL}template/${id}`;
    return this.http.patch<TalkingPointTemplate>(url, talkingPointTemplate);
  }

  deleteTalkingPointTemplateById(id: number): Observable<TalkingPointTemplate> {
    const url = `${this.BASE_URL}template/${id}`;
    return this.http.delete<TalkingPointTemplate>(url);
  }

  uploadFileForMeeting(meetingId: number, file: File): Observable<OneToOneMeetingFile> {
    const url = `${this.BASE_URL}meeting/${meetingId}/file`;
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<OneToOneMeetingFile>(url, formData);
  }

  getFilesByMeetingId(meetingId: number): Observable<Array<OneToOneMeetingFile>> {
    const url = `${this.BASE_URL}meeting/${meetingId}/file`;
    return this.http.get<Array<OneToOneMeetingFile>>(url);
  }

  deleteFileByMeetingIdAndFileName(meetingId: number, filename: string): Observable<OneToOneMeetingFile> {
    const url = `${this.BASE_URL}meeting/${meetingId}/file/${filename}`;
    return this.http.delete<OneToOneMeetingFile>(url);
  }

  getOldestOneToOneMeeting(): Observable<OneToOneMeeting> {
    const url = `${this.BASE_URL}schedule/meeting/oldest`;
    return this.http.get<OneToOneMeeting>(url);
  }
  getScheduleForCycleIdAndMeManager(cycleId: number): Observable<OneToOneSchedule> {
    const url = `${this.BASE_URL}schedule/cycle/${cycleId}`;
    return this.http.get<OneToOneSchedule>(url);
  }
  getScheduleForDirectReportAndCycleId(userId: number, cycleId: number): Observable<OneToOneSchedule> {
    const url = `${this.BASE_URL}schedule/${userId}/cycle/${cycleId}`;
    return this.http.get<OneToOneSchedule>(url);
  }

  getPostReviewMeetingsByCycleId(cycleId: number): Observable<PostReviewCycleOneToOneView[]> {
    const url = this.BASE_URL + 'meetings/post-review/' + cycleId;
    return this.http.get<PostReviewCycleOneToOneView[]>(url);
  }
}
