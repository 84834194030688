import { Injectable } from '@angular/core';
import { MockService } from '@app/mock/api/mock-service';
import { CompetencyService } from '@app/shared/api/interfaces/competency.service';
import { HttpRequest } from '@angular/common/http';
import { Competency, CreateCompetencyDto, UpdateCompetencyDto } from '@app/models/competencies/competency.model';
import {
    CompetencyCategory,
    CreateCompetencyCategoryDto,
    UpdateCompetencyCategoryDto
} from '@app/models/competencies/competency-category.model';
import { Observable } from 'rxjs';
import { mockCompetencies } from '@app/mock/api/data/mockCompetencies';
import { mockCompetencyCategories } from '@app/mock/api/data/mockCompetencyCategories';
import { sanitizeUrl } from '@app/shared/utils/helpers';

@Injectable()
export class CompetencyMockService implements MockService, CompetencyService {
    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);

        switch (true) {
            case url.endsWith('api/competency') && req.method === 'GET':
                return this.getAllCompetencies();
            case url.match(/api\/competency\/\d+$/) !== null && req.method === 'GET':
                const competencyId = +url.split('/')[2];
                return this.getCompetencyById(competencyId);
            case url.endsWith('api/competency/category') && req.method === 'GET':
                return this.getAllCompetencyCategories();
            case url.match(/api\/competency\/category\/\d+$/) !== null && req.method === 'GET':
                const competencyCategoryId = +url.split('/')[3];
                return this.getCompetencyById(competencyCategoryId);
        }
    }

    getAllCompetencies(): Observable<Array<Competency>> | Competency[] {
        return mockCompetencies;
    }

    getAllCompetencyCategories(): Observable<Array<CompetencyCategory>> | CompetencyCategory[] {
        return mockCompetencyCategories;
    }

    getCompetencyById(id: number): Observable<Competency> | Competency {
        return mockCompetencies.find(c => c.id === id);
    }

    getCompetencyCategoryById(id: number): Observable<CompetencyCategory> | CompetencyCategory {
        return mockCompetencyCategories.find(c => c.id === id);
    }

    // No Ops listed below
    importCompetencies(file: File): Observable<Array<Competency>> {
        return undefined;
    }

    updateCompetency(id: number, competency: UpdateCompetencyDto): Observable<Competency> {
        return undefined;
    }

    updateCompetencyCategory(id: number, competencyCategory: UpdateCompetencyCategoryDto): Observable<CompetencyCategory> {
        return undefined;
    }

    createCompetency(competency: CreateCompetencyDto): Observable<Competency> {
        return undefined;
    }

    createCompetencyCategory(competencyCategory: CreateCompetencyCategoryDto): Observable<CompetencyCategory> {
        return undefined;
    }

    deleteCompetency(id: number): Observable<Competency> {
        return undefined;
    }

    deleteCompetencyCategory(id: number): Observable<CompetencyCategory> {
        return undefined;
    }
}