import { OfficeLocation } from "../company.model";
import { OrganisationalUnit } from "../organisational-unit.model";
import { User } from "../user/user.model";
import { GoalKeyResult } from "./goal-key-result.model";
import { GoalTag } from "./goal-tag.model";
import { GoalType, GoalVisibility, GoalPriority } from "./goal.model";

export class GoalDraft {
  id: number | null;
  title: string | null;
  type: GoalType | null;
  department: OrganisationalUnit | null;
  officeLocation: OfficeLocation | null;
  endDate: Date | null;
  visibility: GoalVisibility | null;
  priority: GoalPriority | null;

  tags: Array<GoalTag>;
  keyResults: Array<GoalKeyResult>;
  owners: Array<User>;

  alignment: number | null;
  developmentNeeds: string | null;

  constructor(
    id: number | null,
    title: string | null,
    type: GoalType | null,
    department: OrganisationalUnit | null,
    officeLocation: OfficeLocation | null,
    endDate: Date | null,
    visibility: GoalVisibility | null,
    priority: GoalPriority | null,
    tags: Array<GoalTag>,
    keyResults: Array<GoalKeyResult>,
    owners: Array<User>,
    alignment: number | null,
    developmentNeeds: string | null
  ) {
    this.id = id;
    this.title = title;
    this.type = type;
    this.department = department;
    this.officeLocation = officeLocation;
    this.endDate = endDate;
    this.visibility = visibility;
    this.priority = priority;
    this.tags = tags;
    this.keyResults = keyResults;
    this.owners = owners;
    this.alignment = alignment;
    this.developmentNeeds = developmentNeeds;
  }
}