import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GoalKeyResultMeasureUnitPlacement } from '@app/models/goals/goal-key-result-measure-unit-placement.model';
import { GoalKeyResultType } from '@app/models/goals/goal-key-result-type.model';
import { CompanyFeatures } from 'app/models/company.model';
import { Goal } from 'app/models/goals/goal.model';
import { Globals } from 'app/shared/globals/globals';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-goals-create-key-result',
  templateUrl: './goals-create-key-result.component.html',
  styleUrls: ['./goals-create-key-result.component.css']
})
export class GoalsCreateKeyResultComponent implements OnInit, OnDestroy {
  private readonly ngUnsubscribe$: Subject<void> = new Subject<void>();
  readonly eGoalKeyResultType = GoalKeyResultType;
  readonly eGoalKeyResultMeasureUnitPlacement = GoalKeyResultMeasureUnitPlacement;

  @Input() keyResultForm!: FormGroup;
  @Output() keyResultFormChange = new EventEmitter<FormGroup>();
  @Input() submitted!: boolean;
  @Input() resultIndex!: number;
  @Input() mode!: string;
  @Input() inputGoal!: Goal
  @Input() templateValues!: Array<string>;
  @Output() cancelAddKeyResult = new EventEmitter();

  globals: Globals;
  eFeature = CompanyFeatures;

  minDate: moment.Moment;

  constructor(globals: Globals) {
    this.globals = globals;
    this.minDate = moment();
  }

  ngOnInit() {

    // if create mode or adding a new goal when in edit mode
    if (this.mode === 'CREATE' || (this.mode === 'EDIT_DRAFT' && this.keyResultForm.controls.id.value === '') || (this.mode === 'EDIT' && this.keyResultForm.controls.id.value === '')) {
      this.keyResultForm.controls.id.setValue(null);
      this.keyResultForm.controls.result.setValue('');
      this.keyResultForm.controls.type.setValue(GoalKeyResultType.BINARY);
      this.keyResultForm.controls.measureStartValue.setValue(0);
      this.keyResultForm.controls.measureGoalValue.setValue(1);
      this.keyResultForm.controls.endDate.setValue(null);
      this.keyResultForm.controls.measureUnit.setValue('');
      this.keyResultForm.controls.measureUnitPlacement.setValue(GoalKeyResultMeasureUnitPlacement.AFTER);
    }

    this.keyResultForm.controls.type.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(e => {
        if (this.keyResultForm.controls.type.value === GoalKeyResultType.BINARY) {
          this.keyResultForm.controls.measureStartValue.setValue(0);
          this.keyResultForm.controls.measureGoalValue.setValue(1);
        } else {
          this.keyResultForm.controls.measureStartValue.setValue(0);
          this.keyResultForm.controls.measureGoalValue.setValue(100);
        }
      })
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }

  cancel() {
    this.cancelAddKeyResult.emit(this.resultIndex);
  }

  isNumber(value: any) {
    const number = value;
    if (isNaN(number)) {
      return false;
    } else {
      return true;
    }
  }

}
