import { appVersion } from './version';

export const environment = {
    production: true,
    websocketUrl: 'ws://localhost:8282/livesync',
    sso: {
        redirectUri: 'http://localhost:8282/callback',
        logoutUri: 'http://localhost:8282',
        microsoft: {
            clientId: '56711260-943e-4081-9e4e-5babef909539',
        },
        google: {
            clientId: '995821072743-jv6o94fk2ipgva5bljet1it2aie9ftd7.apps.googleusercontent.com',
        }
    },
    sentry: {
        enabled: false,
        environment: 'demo',
        key: 'https://c07fba51f1fb4eb1903d83f2551e07d2@sentry.io/1231287',
    },
    intercom: {
        id: 'yrb9n1sk'
    },
    password: {
        minimumLength: 8,
        maximumLength: 100
    },
    integrations: {
        slack: {
            enabled: true
        }
    },
    mock: {
        enabled: true,
        apiUrl: 'https://beta.frankli.io'
    },
    version: appVersion
}