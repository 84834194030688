<div>
  <ol class="breadcrumb" *ngIf="breadcrumbs.length > 1"  [attr.data-intercom-target]="'Breadcrumbs'">
    <ng-container *ngFor="let breadcrumb of breadcrumbs; index as i">
      <li *ngIf="i+1 < breadcrumbs.length" class="clickable">
        <a [routerLink]="breadcrumb.route" [queryParams]="breadcrumb.queryParams">
          {{breadcrumb.label}}
        </a>
      </li>
      <li *ngIf="i+1 === breadcrumbs.length" class="active">
        {{breadcrumb.label}}
      </li>
    </ng-container>
  </ol>
</div>