import { GoalTag } from '@app/models/goals/goal-tag.model';
import { TagType } from '@app/models/goals/goal.model';
import { mockUsers } from '@app/mock/api/data/mockUsers';

export const mockGoalTags: GoalTag[] = [
    {
        id: 1,
        text: 'We Care',
        type: TagType.COMPANY_VALUE,
        description: 'We care',
        creator: mockUsers[0],
    },
    {
        id: 2,
        text: 'We Delight',
        type: TagType.COMPANY_VALUE,
        description: 'We delight',
        creator: mockUsers[0],
    },
    {
        id: 3,
        text: 'One Team',
        type: TagType.COMPANY_VALUE,
        description: 'One team',
        creator: mockUsers[0],
    },
    {
        id: 4,
        text: 'Access to Opportunity',
        type: TagType.CATEGORY,
        description: ' Access to Opportunity',
        creator: mockUsers[0],
    },
    {
        id: 5,
        text: 'Agile',
        type: TagType.CATEGORY,
        description: 'Agile',
        creator: mockUsers[0],
    }
];