import { MockService } from '@app/mock/api/mock-service';
import { Goal, GoalType, GoalVisibility } from '@app/models/goals/goal.model';
import { mockGoals } from '@app/mock/api/data/mockGoals';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { Globals } from '@app/shared/globals/globals';
import { GoalService } from '@app/shared/api/interfaces/goals.service';
import { GoalDepartmentOverview } from '@app/models/goals/goal-department-overview.model';
import { GoalCommentDto, GoalProgressDto, GoalStatusDto, GoalUpdateDto } from '@app/models/goals/goal.dto';
import { DepartmentGoalOverview } from '@app/models/goals/department-goal-overview.model';
import { GoalTag } from '@app/models/goals/goal-tag.model';
import { OfficeLocationGoalOverview } from '@app/models/goals/office-location-goal-overview.model';
import { GoalDirectReportOverview } from '@app/models/goals/goal-direct-report-overview.model';
import { GoalTemplate } from '@app/models/goals/goal-template.model';
import { Observable } from 'rxjs';
import { mockGoalDrafts } from '@app/mock/api/data/mockGoalDrafts';
import { mockGoalTemplates } from '@app/mock/api/data/mockGoalTemplates';
import { mockGoalTags } from '@app/mock/api/data/mockGoalTags';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { mockOrganisationalUnits } from '@app/mock/api/data/mockOrganisationalUnits';
import { mockOfficeLocations } from '@app/mock/api/data/mockOfficeLocations';
import { sanitizeUrl } from '@app/shared/utils/helpers';
import { GoalDraft } from '@app/models/goals/goal-draft.model';
import { CreateGoalDraftDto } from '@app/models/goals/create-goal-draft.dto';
import { CreateGoalDto } from '@app/models/goals/create-goal.dto';

@Injectable()
export class GoalMockService implements MockService, GoalService {
    constructor(private readonly globals: Globals) { }

    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);

        switch (true) {
            case url.match(/api\/goal\/\d+$/) !== null:
                const goalId = +url.split('/')[2];
                return this.getGoalById(goalId);
            case url.endsWith('api/goal/individual'):
                return this.getIndividualGoals();
            case url.endsWith('api/goal/all'):
                return this.getGoalsPublicAll();
            case url.endsWith('api/goal/completion'):
                return this.getGoalsOverviewPercentage();
            case url.endsWith('api/goal/admin'):
                return this.getAdminGoals();
            case url.endsWith('api/goal/draft/all'):
                return this.getDraftGoals();
            case url.match(/api\/goal\/draft\/\d+$/) !== null:
                const draftGoalId = +url.split('/')[3];
                return this.getDraftGoalById(draftGoalId);
            case url.endsWith('api/goal/template') && req.method === 'GET':
                return this.getGoalTemplates();
            case url.match(/api\/goal\/aligned\/\d+$/) !== null:
                const alignedGoalId = +url.split('/')[3];
                return this.getAlignedGoals(alignedGoalId);
            case url.match(/api\/goal\/individual\/\d+$/) !== null:
                const individualGoalUserId = +url.split('/')[3];
                return this.getIndividualGoalsByUserId(individualGoalUserId);
            case url.match(/api\/goal\/individual\/\d+\/active/) !== null:
                const activeIndividualGoalUserId = +url.split('/')[3];
                return this.getIndividualActiveGoalsByUserId(activeIndividualGoalUserId);
            case url.match(/api\/goal\/individual\/\d+\/archived/) !== null:
                const archivedIndividualGoalUserId = +url.split('/')[3];
                return this.getIndividualArchivedGoalsByUserId(archivedIndividualGoalUserId);
            case url.match(/api\/goal\/individual\/\d+\/complete/) !== null:
                const completedIndividualGoalUserId = +url.split('/')[3];
                return this.getIndividualArchivedGoalsByUserId(completedIndividualGoalUserId);
            case url.match(/api\/goal\/manager\/\d+\/\d+$/) !== null:
                const managerId = +url.split('/')[3];
                const managerGoalId = +url.split('/')[4];
                return this.managerGetGoalById(managerId, managerGoalId);
            case url.endsWith('api/goal/personal'):
                return this.getPersonalGoals();
            case url.match(/api\/goal\/personal\/\d+$/) !== null:
                const publicGoalUserId = +url.split('/')[3];
                return this.getPublicPersonalGoals(publicGoalUserId);
            case url.endsWith('api/goal/personal/active'):
                return this.getPersonalGoalsActive();
            case url.endsWith('api/goal/personal/archived'):
                return this.getPersonalGoalsArchived();
            case url.endsWith('api/goal/personal/complete'):
                return this.getPersonalGoalsComplete();
            case url.endsWith('api/goal/company'):
                return this.getCompanyGoals();
            case url.endsWith('api/goal/company/active'):
                return this.getCompanyGoalsActive();
            case url.endsWith('api/goal/company/archived'):
                return this.getCompanyGoalsArchived();
            case url.endsWith('api/goal/company/complete'):
                return this.getCompanyGoalsComplete();
            case url.endsWith('api/goal/manage/direct'):
                return this.getTeamGoalsOverview(this.globals.user.id);
            case url.match(/api\/goal\/manage\/direct\/\d+$/) !== null:
                const teamMemberId = +url.split('/')[4];
                return this.getTeamGoalsOverview(teamMemberId);
            case url.endsWith('api/goal/manage/direct/ceo'):
                return this.getTeamGoalsOverviewCeo();
            case url.endsWith('api/goal/team'):
                return this.getTeamGoals();
            case url.endsWith('api/goal/team/active'):
                return this.getTeamGoalsActive();
            case url.endsWith('api/goal/team/archived'):
                return this.getTeamGoalsArchived();
            case url.endsWith('api/goal/team/complete'):
                return this.getTeamGoalsComplete();
            case url.match(/api\/goal\/team\/\d+\/active/) !== null:
                const activeTeamGoalUserId = +url.split('/')[3];
                return this.getTeamGoalsActiveByManagerId(activeTeamGoalUserId);
            case url.match(/api\/goal\/team\/\d+\/archived/) !== null:
                const archivedTeamGoalUserId = +url.split('/')[3];
                return this.getTeamGoalsArchivedByManagerId(archivedTeamGoalUserId);
            case url.match(/api\/goal\/team\/\d+\/complete/) !== null:
                const completeTeamGoalUserId = +url.split('/')[3];
                return this.getTeamGoalsCompleteByManagerId(completeTeamGoalUserId);
            case url.endsWith('api/goal/office-location'):
                return this.getOfficeLocationGoals();
            case url.endsWith('api/goal/office-location/active'):
                return this.getOfficeLocationGoalsActive();
            case url.endsWith('api/goal/office-location/archived'):
                return this.getOfficeLocationGoalsArchived();
            case url.endsWith('api/goal/office-location/complete'):
                return this.getOfficeLocationGoalsComplete();
            case url.match(/api\/goal\/office-location\/\d+$/) !== null:
                const goalOfficeLocationId = +url.split('/')[3];
                return this.getOfficeLocationGoalsByOfficeLocationId(goalOfficeLocationId);
            case url.match(/api\/goal\/office-location\/\d+\/active/) !== null:
                const activeGoalOfficeLocationId = +url.split('/')[3];
                return this.getOfficeLocationGoalsActiveByOfficeLocationId(activeGoalOfficeLocationId);
            case url.match(/api\/goal\/office-location\/\d+\/archived/) !== null:
                const archivedGoalOfficeLocationId = +url.split('/')[3];
                return this.getOfficeLocationGoalsArchivedByOfficeLocationId(archivedGoalOfficeLocationId);
            case url.match(/api\/goal\/office-location\/\d+\/complete/) !== null:
                const completedGoalOfficeLocationId = +url.split('/')[3];
                return this.getOfficeLocationGoalsCompleteByOfficeLocationId(completedGoalOfficeLocationId);
            case url.endsWith('api/goal/overview/departments'):
                return this.getDepartmentGoalsOverview(this.globals.user.organisationalUnit.id);
            case url.match(/api\/goal\/overview\/departments\/\d+$/) !== null:
                const overviewDepartmentId = +url.split('/')[4];
                return this.getDepartmentGoalsOverview(overviewDepartmentId);
            case url.endsWith('api/goal/departments/overview'):
                return this.getDepartmentGoalOverviewOld();
            case url.endsWith('api/goal/department'):
                return this.getDepartmentGoals();
            case url.endsWith('api/goal/department/active'):
                return this.getDepartmentGoalsActive();
            case url.endsWith('api/goal/department/archived'):
                return this.getDepartmentGoalsArchived();
            case url.endsWith('api/goal/department/complete'):
                return this.getDepartmentGoalsComplete();
            case url.match(/api\/goal\/department\/\d+$/) !== null:
                const goalDepartmentId = +url.split('/')[3];
                return this.getDepartmentGoalsByDepartmentId(goalDepartmentId);
            case url.match(/api\/goal\/department\/\d+\/active/) !== null:
                const activeGoalDepartmentId = +url.split('/')[3];
                return this.getDepartmentGoalsActiveByDepartmentId(activeGoalDepartmentId);
            case url.match(/api\/goal\/department\/\d+\/archived/) !== null:
                const archivedGoalDepartmentId = +url.split('/')[3];
                return this.getDepartmentGoalsArchivedByDepartmentId(archivedGoalDepartmentId);
            case url.match(/api\/goal\/department\/\d+\/complete/) !== null:
                const completedGoalDepartmentId = +url.split('/')[3];
                return this.getDepartmentGoalsCompleteByDepartmentId(completedGoalDepartmentId);
            case url.endsWith('api/goal/oldest'):
                return this.getOldestGoal();
            case url.endsWith('api/goal/office-locations/overview'):
                return this.getOfficeLocationGoalOverview();
            case url.endsWith('api/goal/tags') && req.method === 'GET':
                return this.getTags();
            case url.match(/api\/goal\/tags\?type=\D+$/) !== null:
                const type = req.params.get('type');
                return this.getTags(type);
            case url.match(/api\/goal\/tags\/\d+\/all/) !== null:
                const goalTagId = +url.split('/')[3];
                return this.getTagGoals(goalTagId);
            case url.match(/api\/goal\/tags\/\d+\/active/) !== null:
                const activeGoalTagId = +url.split('/')[3];
                return this.getTagGoalsActive(activeGoalTagId);
            case url.match(/api\/goal\/tags\/\d+\/archived/) !== null:
                const archivedGoalTagId = +url.split('/')[3];
                return this.getTagGoalsArchived(archivedGoalTagId);
            case url.match(/api\/goal\/tags\/\d+\/complete/) !== null:
                const completedGoalTagId = +url.split('/')[3];
                return this.getTagGoalsComplete(completedGoalTagId);
        }
    }

    getIndividualGoals(): Array<Goal> {
        return mockGoals.filter(g => g.owners.map(o => o.id).includes(this.globals.user.id));
    }

    getAdminGoals(): Observable<Array<Goal>> | Goal[] {
        return mockGoals;
    }

    getAlignedGoals(id: number): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.alignment === id);
    }

    getCompanyGoals(): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.type === GoalType.COMPANY);
    }

    getCompanyGoalsActive(): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.type === GoalType.COMPANY && !g.archived && !g.complete);
    }

    getCompanyGoalsArchived(): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.type === GoalType.COMPANY && g.archived);
    }

    getCompanyGoalsComplete(): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.type === GoalType.COMPANY && g.complete);
    }

    getDepartmentGoalOverviewOld(): Observable<Array<DepartmentGoalOverview>> | DepartmentGoalOverview[] {
        return mockOrganisationalUnits.map(orgUnit => ({
            department: orgUnit,
            goals: mockGoals.filter(g => g.type === GoalType.DEPARTMENT && g.department.id === orgUnit.id),
            archived: false
        }));
    }

    getDepartmentGoals(): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.type === GoalType.DEPARTMENT);
    }

    getDepartmentGoalsActive(): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.type === GoalType.DEPARTMENT && !g.archived && !g.complete);
    }

    getDepartmentGoalsActiveByDepartmentId(id: number): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.type === GoalType.DEPARTMENT && !g.archived && !g.complete && g.department.id === id);
    }

    getDepartmentGoalsArchived(): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.type === GoalType.DEPARTMENT && g.archived);
    }

    getDepartmentGoalsArchivedByDepartmentId(id: number): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.type === GoalType.DEPARTMENT && g.archived && g.department.id === id);
    }

    getDepartmentGoalsByDepartmentId(id: number): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.type === GoalType.DEPARTMENT && g.department.id === id);
    }

    getDepartmentGoalsComplete(): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.type === GoalType.DEPARTMENT && g.complete);
    }

    getDepartmentGoalsCompleteByDepartmentId(id: number): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.type === GoalType.DEPARTMENT && g.complete && g.department.id === id);
    }

    getDepartmentGoalsOverview(id: number): GoalDepartmentOverview {
        const parentDepartment = mockOrganisationalUnits.find(o => o.id === id);
        const cumulativeGoals = mockGoals.filter(g => g.type === GoalType.DEPARTMENT && g.department.id === parentDepartment.id);
        const goalCountSelf = cumulativeGoals.length;
        const childDepartments = mockOrganisationalUnits.filter(o => o.parentOrg && o.parentOrg.id === parentDepartment.id);
        const overviews: GoalDepartmentOverview[] = [];

        for (const childDepartment of childDepartments) {
            cumulativeGoals.push(...mockGoals.filter(g => g.type === GoalType.DEPARTMENT && g.department.id === childDepartment.id));
            overviews.push(this.getDepartmentGoalsOverview(childDepartment.id));
        }

        const averageAndCount = Goal.getAverageAndCount(cumulativeGoals);
        return {
            department: parentDepartment,
            activeCount: averageAndCount[2],
            completeCount: averageAndCount[3],
            goalsCount: cumulativeGoals.length,
            goalsCountSelf: goalCountSelf,
            averageCompletion: averageAndCount[0],
            objectiveCount: averageAndCount[1],
            childDepartments: overviews
        };
    }

    getDraftGoalById(id: number): Observable<GoalDraft> | GoalDraft {
        return mockGoalDrafts.find(gd => gd.id === id);
    }

    getDraftGoals(): Observable<Array<GoalDraft>> | GoalDraft[] {
        return mockGoalDrafts;
    }

    getGoalById(id: number): Observable<Goal> | Goal {
        return mockGoals.find(g => g.id === id);
    }

    getGoalTemplates(): Observable<Array<GoalTemplate>> | GoalTemplate[] {
        return mockGoalTemplates;
    }

    getGoalsOverviewPercentage(): Observable<Array<number>> | number[] {
        const averageAndCountPersonal = Goal.getAverageAndCount(mockGoals.filter(g =>
            [GoalType.PERSONAL_DEVELOPMENTAL, GoalType.PERSONAL_OPERATIONAL].includes(g.type) &&
            g.owners.map(o => o.id).includes(this.globals.user.id))
        );

        const averageAndCountDepartment = Goal.getAverageAndCount(mockGoals.filter(g =>
            g.type === GoalType.DEPARTMENT &&
            g.department.id === this.globals.user.organisationalUnit.id)
        );

        const averageAndCountCompany = Goal.getAverageAndCount(mockGoals.filter(g => g.type === GoalType.COMPANY));

        return [
            averageAndCountPersonal[0],
            averageAndCountDepartment[0],
            averageAndCountCompany[0]
        ];
    }

    getGoalsPublicAll(): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.visibility === GoalVisibility.PUBLIC);
    }

    getIndividualActiveGoalsByUserId(id: number): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.owners.map(o => o.id).includes(id) && !g.archived && !g.complete);
    }

    getIndividualArchivedGoalsByUserId(id: number): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.owners.map(o => o.id).includes(id) && g.archived);
    }

    getIndividualCompleteGoalsByUserId(id: number): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.owners.map(o => o.id).includes(id) && g.complete);
    }

    getIndividualGoalsByUserId(id: number): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.owners.map(o => o.id).includes(id));
    }

    getOfficeLocationGoalOverview(): Observable<Array<OfficeLocationGoalOverview>> | OfficeLocationGoalOverview[] {
        return mockOfficeLocations.map(officeLocation => ({
            officeLocation: officeLocation,
            goals: mockGoals.filter(g => g.type === GoalType.OFFICE_LOCATION && g.officeLocation.id === officeLocation.id)
        }));
    }

    getOfficeLocationGoals(): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.type === GoalType.OFFICE_LOCATION);
    }

    getOfficeLocationGoalsActive(): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.type === GoalType.OFFICE_LOCATION && !g.archived && !g.complete);
    }

    getOfficeLocationGoalsActiveByOfficeLocationId(id: number): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.type === GoalType.OFFICE_LOCATION && !g.archived && !g.complete && g.officeLocation.id === id);
    }

    getOfficeLocationGoalsArchived(): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.type === GoalType.OFFICE_LOCATION && g.archived);
    }

    getOfficeLocationGoalsArchivedByOfficeLocationId(id: number): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.type === GoalType.OFFICE_LOCATION && g.archived && g.officeLocation.id === id);
    }

    getOfficeLocationGoalsByOfficeLocationId(id: number): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.type === GoalType.OFFICE_LOCATION && g.officeLocation.id === id);
    }

    getOfficeLocationGoalsComplete(): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.type === GoalType.OFFICE_LOCATION && g.complete);
    }

    getOfficeLocationGoalsCompleteByOfficeLocationId(id: number): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.type === GoalType.OFFICE_LOCATION && g.complete && g.officeLocation.id === id);
    }

    getOldestGoal(): Observable<Goal> | Goal {
        return mockGoals.sort((a, b) => new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime()).find(g => g !== undefined)!;
    }

    getPersonalGoals(): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g =>
            g.owners.map(o => o.id).includes(this.globals.user.id) &&
            [GoalType.PERSONAL_DEVELOPMENTAL, GoalType.PERSONAL_OPERATIONAL].includes(g.type)
        );
    }

    getPersonalGoalsActive(): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g =>
            g.owners.map(o => o.id).includes(this.globals.user.id) &&
            [GoalType.PERSONAL_DEVELOPMENTAL, GoalType.PERSONAL_OPERATIONAL].includes(g.type) &&
            !g.archived && !g.complete
        );
    }

    getPersonalGoalsArchived(): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g =>
            g.owners.map(o => o.id).includes(this.globals.user.id) &&
            [GoalType.PERSONAL_DEVELOPMENTAL, GoalType.PERSONAL_OPERATIONAL].includes(g.type) &&
            g.archived
        );
    }

    getPersonalGoalsComplete(): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g =>
            g.owners.map(o => o.id).includes(this.globals.user.id) &&
            [GoalType.PERSONAL_DEVELOPMENTAL, GoalType.PERSONAL_OPERATIONAL].includes(g.type) &&
            g.complete
        );
    }

    getPublicPersonalGoals(id: number): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g =>
            g.owners.map(o => o.id).includes(id) &&
            [GoalType.PERSONAL_DEVELOPMENTAL, GoalType.PERSONAL_OPERATIONAL].includes(g.type) &&
            g.visibility === GoalVisibility.PUBLIC
        );
    }

    getTagGoals(id: number): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.tags.map(t => t.id).includes(id));
    }

    getTagGoalsActive(id: number): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.tags.map(t => t.id).includes(id) && !g.archived && !g.complete);
    }

    getTagGoalsArchived(id: number): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.tags.map(t => t.id).includes(id) && g.archived);
    }

    getTagGoalsComplete(id: number): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.tags.map(t => t.id).includes(id) && g.complete);
    }

    getTags(type?: string): Observable<Array<GoalTag>> | GoalTag[] {
        return type ? mockGoalTags.filter(t => t.type === type) : mockGoalTags;
    }

    getTeamGoals(): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.type === GoalType.TEAM);
    }

    getTeamGoalsActive(): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.type === GoalType.TEAM && !g.archived && !g.complete);
    }

    getTeamGoalsActiveByManagerId(managerId: number): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g =>
            g.type === GoalType.TEAM &&
            g.owners.some(o => o.managerId === managerId) &&
            !g.archived && !g.complete
        );
    }

    getTeamGoalsArchived(): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.type === GoalType.TEAM && g.archived);
    }

    getTeamGoalsArchivedByManagerId(managerId: number): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g =>
            g.type === GoalType.TEAM &&
            g.owners.some(o => o.managerId === managerId) &&
            g.archived
        );
    }

    getTeamGoalsComplete(): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g => g.type === GoalType.TEAM && g.complete);
    }

    getTeamGoalsCompleteByManagerId(managerId: number): Observable<Array<Goal>> | Goal[] {
        return mockGoals.filter(g =>
            g.type === GoalType.TEAM &&
            g.owners.some(o => o.managerId === managerId) &&
            g.complete
        );
    }

    getTeamGoalsOverview(id: number): GoalDirectReportOverview[] {
        const manager = mockUsers.find(u => u.id === id);
        const cumulativeGoals = mockGoals.filter(g => g.owners.includes(manager));
        const goalCountSelf = cumulativeGoals.length;
        const overviews: GoalDirectReportOverview[] = [];

        const directReports = mockUsers.filter(u => u.managerId === manager.id && u.id !== u.managerId);
        for (const directReport of directReports) {
            cumulativeGoals.push(...mockGoals.filter(g => g.owners.includes(directReport)));
            for (const overview of this.getTeamGoalsOverview(directReport.id)) {
                overviews.push(overview);
            }
        }

        const averageAndCount = Goal.getAverageAndCount(cumulativeGoals);

        return [
            {
                user: manager,
                activeCount: averageAndCount[2],
                completeCount: averageAndCount[3],
                goalsCount: cumulativeGoals.length,
                goalsCountSelf: goalCountSelf,
                averageCompletion: Math.round((averageAndCount[0] + Number.EPSILON) * 100) / 100,
                objectiveCount: averageAndCount[1],
                directReports: overviews
            }
        ]
    }

    getTeamGoalsOverviewCeo(): GoalDirectReportOverview[] {
        const managerOverviews: GoalDirectReportOverview[] = [];
        for (const topOfOrg of mockUsers.filter(u => u.id === u.managerId)) {
            for (const overview of this.getTeamGoalsOverview(topOfOrg.id)) {
                managerOverviews.push(overview);
            }
        }
        return managerOverviews;
    }

    managerGetGoalById(userId: number, goalId: number): Observable<Goal> | Goal {
        return mockGoals.find(g => g.id === goalId);
    }

    // No Ops listed below
    addComment(id: number, goalCommentDto: GoalCommentDto): Observable<Goal> {
        return undefined;
    }

    createDraftGoal(createGoalDraftDto: CreateGoalDraftDto): Observable<GoalDraft> {
        return undefined;
    }

    createGoalFromDraftGoal(id: number, goal: Goal): Observable<Goal> {
        return undefined;
    }

    createGoalTemplate(goalTemplate: GoalTemplate): Observable<GoalTemplate> {
        return undefined;
    }

    createTag(tag: GoalTag): Observable<Array<GoalTag>> {
        return undefined;
    }

    deleteComment(id: number, goalCommentDto: GoalCommentDto): Observable<Goal> {
        return undefined;
    }

    deleteDraftGoalById(id: number): Observable<GoalDraft> {
        return undefined;
    }

    deleteGoal(id: number): any {
    }

    deleteGoalTemplate(templateId: number): Observable<GoalTemplate> {
        return undefined;
    }

    deleteTag(id: number): Observable<GoalTag> {
        return undefined;
    }

    createGoal(createGoalDto: CreateGoalDto): Observable<Goal> {
        return undefined;
    }

    updateDraftGoalById(id: number, draftGoal: GoalDraft): Observable<GoalDraft> {
        return undefined;
    }

    updateGoal(id: number, goalUpdateDto: GoalUpdateDto): Observable<Goal> {
        return undefined;
    }

    updateGoalTemplate(templateId: number, goalTemplate: GoalTemplate): Observable<GoalTemplate> {
        return undefined;
    }

    updateProgress(id: number, goalProgressDto: GoalProgressDto): any {
        return undefined;
    }

    updateStatus(id: number, goalStatusDto: GoalStatusDto): any {
        return undefined;
    }

    updateTag(id: number, tag: GoalTag): Observable<GoalTag> {
        return undefined;
    }
}