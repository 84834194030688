<div class="row">
  <div class="col-md-10 col-md-offset-1">
    <div class="card">
      <br />
      <div class="header text-center">
        Edit Profile
      </div>
      <br />

      <!-- NAV -->
      <ul *ngIf="loading === false && error === false" class="nav nav-justified">
        <li class="clickable" (click)="setStep(eEditProfileTab.PERSONAL_DETAILS)">
          <a [class.text-info]="tab === eEditProfileTab.PERSONAL_DETAILS" class="nav-link" angulartics2On="click"
            angularticsAction="EditProfilePersonalDetails" angularticsCategory="Profile">
            <i class="fal fa-user-circle"></i>
            <br /> Personal Details
          </a>
        </li>
        <li class="clickable" (click)="setStep(eEditProfileTab.PROFILE_DETAILS)">
          <a [class.text-info]="tab === eEditProfileTab.PROFILE_DETAILS" class="nav-link" angulartics2On="click"
            angularticsAction="EditProfileProfileDetails" angularticsCategory="Profile">
            <i class="fal fa-id-card"></i>
            <br /> Profile Details
          </a>
        </li>
        <li class="clickable" (click)="setStep(eEditProfileTab.LOCATION_DETAILS)">
          <a [class.text-info]="tab === eEditProfileTab.LOCATION_DETAILS" class="nav-link" angulartics2On="click"
            angularticsAction="EditProfileLocationDetails" angularticsCategory="Profile">
            <i class="fal fa-map-marker-alt"></i>
            <br /> Location Details
          </a>
        </li>
        <li class="clickable" (click)="setStep(eEditProfileTab.INTERESTS_PERSONAL)">
          <a [class.text-info]="tab === eEditProfileTab.INTERESTS_PERSONAL" class="nav-link" angulartics2On="click"
            angularticsAction="EditProfilePersonalInterests" angularticsCategory="Profile">
            <i class="fal fa-mug-hot"></i>
            <br /> Personal Interests
          </a>
        </li>
        <li class="clickable" (click)="setStep(eEditProfileTab.INTERESTS_PROFESSIONAL)">
          <a [class.text-info]="tab === eEditProfileTab.INTERESTS_PROFESSIONAL" class="nav-link" angulartics2On="click"
            angularticsAction="EditProfileProfessionalInterests" angularticsCategory="Profile">
            <i class="fal fa-briefcase"></i>
            <br /> Professional Interests
          </a>
        </li>
      </ul>

      <!-- FORM -->
      <div class="edit-profile-container">

        <!-- Loading -->
        <div *ngIf="loading === true && error === false" class="text-center p-bottom-25">
          <span class="fal fa-spin fa-spinner fa-3x fa-fw"></span>
        </div>

        <!-- Forms -->
        <div *ngIf="loading === false && error === false">
          <app-employee-sign-up-personal-details *ngIf="tab === eEditProfileTab.PERSONAL_DETAILS" [user]="user"
            [formType]="'EDIT'">
          </app-employee-sign-up-personal-details>
          <app-employee-sign-up-profile-details *ngIf="tab === eEditProfileTab.PROFILE_DETAILS" [user]="user"
            [formType]="'EDIT'">
          </app-employee-sign-up-profile-details>
          <app-employee-location-details *ngIf='tab === eEditProfileTab.LOCATION_DETAILS' [user]="user"
            [formType]="'EDIT'" [officeLocations]='officeLocations'></app-employee-location-details>
          <app-employee-sign-up-interests *ngIf="tab === eEditProfileTab.INTERESTS_PERSONAL" [user]="user"
            [formType]="'EDIT'" [(userInterests)]="user.personalInterests" [minimumInterests]="minimumInterests"
            [categories]="personalInterests" [step]="{current: 'INTERESTS_PERSONAL'}"></app-employee-sign-up-interests>
          <app-employee-sign-up-interests *ngIf="tab === eEditProfileTab.INTERESTS_PROFESSIONAL" [user]="user"
            [formType]="'EDIT'" [(userInterests)]="user.professionalInterests" [minimumInterests]="minimumInterests"
            [categories]="professionalInterests" [step]="{current: 'INTERESTS_PROFESSIONAL'}">
          </app-employee-sign-up-interests>
        </div>

        <!-- Error -->
        <div class="text-center" *ngIf="loading === false && error === true">
          <img class="icon-padding new-icon-height" src="/assets/img/Error.svg" />
          <br>
          {{errorMessage}}
        </div>

      </div>
    </div>
  </div>
</div>