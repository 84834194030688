import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { Angulartics2Module } from 'angulartics2';
import { PaginationModule } from 'app/shared/pagination/pagination.module';
import { SharedModule } from 'app/shared/shared.module';

import { GoalsAllComponent } from './goals-all/goals-all.component';
import { GoalsAllTableComponent } from './goals-all-table/goals-all-table.component';
import { GoalsIndividualComponent } from './goals-individual/goals-individual.component';
import { GoalsIndividualActivityComponent } from './goals-individual/goals-individual-activity/goals-individual-activity.component';
import { GoalsIndividualUpdateComponent } from './goals-individual/goals-individual-update/goals-individual-update.component';
import { GoalsCreateComponent } from './goals-create/goals-create.component';
import { GoalsCreateKeyResultComponent } from './goals-create/goals-create-key-result/goals-create-key-result.component';
import { GoalsModalComponent } from './goals-modal/goals-modal.component';
import { GoalsModalSidebarComponent } from './goals-modal/goals-modal-sidebar/goals-modal-sidebar.component';
import { GoalsModalHeaderComponent } from './goals-modal/goals-modal-header/goals-modal-header.component';
import { GoalsComponent } from './goals.component';
import { GoalsOverviewComponent } from './goals-overview/goals-overview.component';
import { GoalsOverviewDraftsComponent } from './goals-overview/goals-overview-drafts/goals-overview-drafts.component';
import { GoalManagerAdminComponent } from './goal-manager-admin/goal-manager-admin.component';
import { GoalManagerTagsComponent } from './goal-manager-tags/goal-manager-tags.component';
import { GoalOverviewManagerComponent } from './goal-overview-manager/goal-overview-manager.component';

import { GoalCreateService } from './goals-create/goals-create.service';
import { GoalsOverviewDepartmentsComponent } from './goals-overview/goals-overview-departments/goals-overview-departments.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

const components = [
  GoalsAllComponent,
  GoalsAllTableComponent,
  GoalsIndividualComponent,
  GoalsIndividualActivityComponent,
  GoalsIndividualUpdateComponent,
  GoalsCreateComponent,
  GoalsCreateKeyResultComponent,
  GoalsModalComponent,
  GoalsModalSidebarComponent,
  GoalsModalHeaderComponent,
  GoalsComponent,
  GoalsOverviewComponent,
  GoalsOverviewDraftsComponent,
  GoalManagerAdminComponent,
  GoalManagerTagsComponent,
  GoalOverviewManagerComponent,
  GoalsOverviewDepartmentsComponent
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    PipesModule,
    Angulartics2Module,
    PaginationModule,
    SharedModule,
    TooltipModule.forRoot()
  ],
  declarations: components,
  exports: components,
  providers: [
    GoalCreateService, 
    TitleCasePipe
  ]
})
export class GoalsComponentsModule { }
