<nav #navbar class="navbar navbar-default">
  <div class="navbar-header">

    <!-- Logo for mobile -->
    <div class="frankli-nav-header" *ngIf="isMobileMenu()">
      <div class="img-container">
        <img src="/assets/img/Frankli_logo_primary_white.png" alt="Frankli" />
      </div>
    </div>

    <button type="button" class="navbar-toggle" data-toggle="collapse" (click)="sidebarToggle()">
      <span class="sr-only">Toggle navigation</span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>

  </div>
  <div class="collapse navbar-collapse">
    <div class="" *ngIf="!isMobileMenu()">
      <div class="navbar-form navbar-left navbar-search-form nav-search-bg round-search" role="search">
        <div class="input-group">
          <span class="input-group-addon">
            <i class="fal fa-search"></i>
          </span>
          <input type="search" class="form-control nav-search-bg" placeholder="Search People or Interests"
            [(ngModel)]="searchValue" maxlength="100" angulartics2On="click" angularticsAction="ClickSearchNavbar"
            angularticsCategory="Support" [attr.data-intercom-target]="'Search Bar'" (keyup)="checkEnter($event)" />
        </div>
      </div>

      <ul class="nav navbar-nav navbar-right noselect">

        <!-- universal create button -->
        <li class="dropdown dropdown-with-icons" *ngIf="
            globals.hasFeature(eCompanyFeatures.FEEDBACK) ||
            globals.hasFeature(eCompanyFeatures.GOALS) ||
            globals.hasFeature(eCompanyFeatures.SURVEYS) ||
            globals.hasFeature(eCompanyFeatures.ONE_TO_ONE)
          ">
          <a class="navbar-user clickable dropdown-toggle m-top-5i" data-toggle="dropdown">
            <button class="btn-frankli-round btn-frankli-small btn-frankli-green-inverted btn-universal-create" angulartics2On="click"
              angularticsAction="ClickCreateButtonUCB" angularticsCategory="Navbar"
              [attr.data-intercom-target]="'Universal Create'"><span
                class="fal fa-plus m-right-5"></span>Create</button>
          </a>
          <ul class="dropdown-menu dropdown-with-icons create-dropdown">
            <ng-container *ngIf="globals.hasFeature(eCompanyFeatures.GOALS)">
              <li>
                <a [routerLink]="['/goals']" [queryParams]="{action: 'create'}" angulartics2On="click"
                  angularticsAction="ClickCreateGoalsUCB" angularticsCategory="Navbar"
                  [attr.data-intercom-target]="'Create Goals'">
                  <i class="fal fa-bullseye"></i>Goal
                </a>
              </li>
            </ng-container>
            <ng-container *ngIf="globals.hasFeature(eCompanyFeatures.ONE_TO_ONE)">
              <li>
                <a [routerLink]="['/one-to-one/create']" angulartics2On="click" angularticsAction="ClickOneToOneUCB"
                  angularticsCategory="Navbar" [attr.data-intercom-target]="'Create 1:1'">
                  <i class="fal fa-calendar-day"></i>1:1 Meeting
                </a>
              </li>
              <li class="divider"></li>
            </ng-container>
            <ng-container *ngIf="globals.hasFeature(eCompanyFeatures.FEEDBACK)">
              <li *ngIf="globals.hasFeature(eCompanyFeatures.FEEDBACK_PRAISE)">
                <a [routerLink]="['/feedback/give/praise']" angulartics2On="click" angularticsAction="ClickPraiseUCB"
                  angularticsCategory="Navbar" [attr.data-intercom-target]="'Create Praise'">
                  <i class="fal fa-thumbs-up"></i>Praise
                </a>
              </li>
              <li *ngIf="globals.hasFeature(eCompanyFeatures.FEEDBACK_IDEAS)">
                <a [routerLink]="['/feedback/give/ideas']" angulartics2On="click" angularticsAction="ClickIdeasUCB"
                  angularticsCategory="Navbar" [attr.data-intercom-target]="'Create Ideas'">
                  <i class="fal fa-lightbulb"></i>Idea
                </a>
              </li>
              <li *ngIf="globals.hasFeature(eCompanyFeatures.FEEDBACK_PEER)">
                <a [routerLink]="['/feedback/give/peers']" angulartics2On="click"
                  angularticsAction="ClickPeerFeedbackUCB" angularticsCategory="Navbar"
                  [attr.data-intercom-target]="'Create Peer Feedback'">
                  <i class="fal fa-user-check"></i>Peer Feedback
                </a>
              </li>
              <li *ngIf="globals.hasFeature(eCompanyFeatures.FEEDBACK_REQUESTS)">
                <a [routerLink]="['/feedback/request']" angulartics2On="click"
                  angularticsAction="ClickRequestFeedbackUCB" angularticsCategory="Navbar"
                  [attr.data-intercom-target]="'Create Feedback Request'">
                  <i class="fal fa-undo"></i>Request Feedback
                </a>
              </li>
              <li *ngIf="globals.hasFeature(eCompanyFeatures.FEEDBACK_UNPROMPTED)">
                <a [routerLink]="['/feedback/give/unprompted']" angulartics2On="click"
                  angularticsAction="ClickUnpromptedFeedbackUCB" angularticsCategory="Navbar"
                  [attr.data-intercom-target]="'Create Unprompted Feedback'">
                  <i class="fal fa-bullhorn"></i>Unprompted Feedback
                </a>
              </li>
              <li class="divider"></li>
            </ng-container>
            <ng-container
              *ngIf="globals.hasFeature(eCompanyFeatures.SURVEYS) && (globals.hasRole(eRoleName.FRANKLI_ADMIN) || globals.hasRole(eRoleName.ADMIN) || globals.hasRole(eRoleName.MANAGER))">
              <li>
                <a [routerLink]="['/surveys/overview/sent']" angulartics2On="click" angularticsAction="ClickSurveyUCB"
                  angularticsCategory="Navbar" [attr.data-intercom-target]="'Create Survey'">
                  <i class="fal fa-clipboard"></i>Survey
                </a>
              </li>
            </ng-container>
            <ng-container *ngIf="globals.hasRole(eRoleName.FRANKLI_ADMIN) || globals.hasRole(eRoleName.ADMIN)">
              <li>
                <a [routerLink]="['/admin/dashboard/company/company-view/new-user']" angulartics2On="click"
                  angularticsAction="ClickPersonUCB" angularticsCategory="Navbar"
                  [attr.data-intercom-target]="'Add People'">
                  <i class="fal fa-user"></i>New Person
                </a>
              </li>
            </ng-container>
          </ul>
        </li>

        <!-- help center -->
        <li>
          <a title="Help Center" target="_blank" href="http://help.frankli.io" angulartics2On="click"
            angularticsAction="ClickHelpCenterNav" angularticsCategory="Navbar"
            [attr.data-intercom-target]="'Open Help Center'">
            <i class="fal fa-question-circle"></i>
          </a>
        </li>

        <!-- New features notification -->
        <ng-container *ngIf="newFeatures; let features">
          <li *ngIf="features.unreadCount > 0 && features.all.length > 0">
            <a title="New Features" angulartics2On="click" angularticsAction="ClickNewFeaturesNotificationNav" angularticsCategory="Navbar" data-intercom-target="View new features" (click)="openNewFeatures()">
              <span class="fal fa-fw fa-gift frankli-view"></span>
              <span class="notification" *ngIf="features.unreadCount > 0">{{(features.unreadCount > 9) ? '9+' : features.unreadCount}}</span>
              <span></span>
            </a>
          </li>
        </ng-container>

        <!-- general notifications -->
        <li class="dropdown">
          <a href="#" class="dropdown-toggle" data-toggle="dropdown" (click)="openNotificationsPanel()">
            <i class="fal fa-bell notif-icon"></i>
            <span class="notification" *ngIf="(notifications.length + tasks.length) > 0 && (notifications.length + tasks.length) < 9">{{(notifications.length + tasks.length)}}</span>
            <span class="notification" *ngIf="(notifications.length + tasks.length) >= 9">9+</span>
            <p class="hidden-md hidden-lg">
              Notifications
              <b class="caret"></b>
            </p>
          </a>
        </li>

        <!-- User info -->
        <li *ngIf="globals.user">
          <a class="navbar-user clickable" angulartics2On="click" angularticsAction="ClickProfileNavbar"
            angularticsCategory="Navbar" [attr.data-intercom-target]="'View Profile'">
            <div [routerLink]="['/profile']" class="picture">
              <span class="profile-pic">
                <app-profile-photo
                [src]="globals.user.imageUrl"
                ></app-profile-photo>
              </span>
            </div>
            <div [routerLink]="['/profile']" class="m-left-10 m-right-10 text-capitalize">{{globals.user.firstName}}</div>
          </a>
        </li>

        <!-- general -->
        <li class="dropdown dropdown-with-icons">
          <a class="navbar-user clickable dropdown-toggle" data-toggle="dropdown">
            <i class="fal fa-chevron-down"></i>
          </a>
          <ul class="dropdown-menu dropdown-with-icons">
            <li>
              <a [routerLink]="['/profile']" angulartics2On="click" angularticsAction="ViewProfileDDL"
                angularticsCategory="Navbar" [attr.data-intercom-target]="'View Profile Dropdown'">
                <i class="fal fa-user"></i>View Profile
              </a>
            </li>
            <li class="divider"></li>
            <li>
              <a [routerLink]="['/profile/edit']" angulartics2On="click" angularticsAction="EditProfileDDL"
                angularticsCategory="Navbar" [attr.data-intercom-target]="'Edit Profile Dropdown'">
                <i class="fal fa-edit"></i>Edit Profile
              </a>
            </li>
            <li class="divider"></li>
            <li *ngIf="!isMock">
              <a routerLink="/settings/password" angulartics2On="click" angularticsAction="ClickChangePasswordNavbar"
                angularticsCategory="Support" [attr.data-intercom-target]="'Change Password Dropdown'">
                <i class="fal fa-lock-alt"></i> Change Password
              </a>
            </li>
            <ng-container *ngIf="newFeatures.all.length > 0">
              <li class="divider"></li>
              <li (click)="openNewFeatures()">
                <a angulartics2On="click" angularticsAction="ClickNewFeaturesNavbar"
                  angularticsCategory="Support" [attr.data-intercom-target]="'New Features Dropdown'">
                  <span class="new-features-item">
                    <i class="fal fa-gift"></i>
                  </span>
                  What's new?
                </a>
              </li>
            </ng-container>
            <li class="divider"></li>
            <li (click)="logout()" *ngIf="!isMock">
              <a angulartics2On="click" angularticsAction="LogoutNavbar" angularticsCategory="Support"
                [attr.data-intercom-target]="'Click LogOut'">
                <i class="fal fa-times-circle"></i> Log out
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!-- #region - Notifications/Task Popup -->
  <app-navbar-notifications-component [notificationsShown]="notificationsShown" [connected]="connected" [notifications]="notifications" [tasks]="tasks" [markingAsRead]="markingAsRead"
(markAllAsRead)="onMarkAllAsRead($event)"
(markAsRead)="onMarkAsRead($event)"
(markAsViewed)="onMarkAsViewed($event)"></app-navbar-notifications-component>
<!-- #endregion -->

<!-- New features modal -->
<ng-container *ngIf="newFeatures && newFeatures.all.length > 0">
  <app-modal [width]="'60%'" [minWidth]="'400px'" #newFeaturesModal>
    <div class="modal-body-content text-left">
      <app-new-features-modal [features]="newFeatures.all"></app-new-features-modal>
    </div>
  </app-modal>
</ng-container>
