export class Address {
  id: number | null;
  street: string;
  city: string;
  district: string;
  postalCode: string;
  country: string;
  timezone: string;

  constructor(street: string, city: string, district: string, postalCode: string, country: string, timezone: string) {
    this.id = null;
    this.street = street;
    this.district = district;
    this.city = city;
    this.postalCode = postalCode;
    this.country = country;
    this.timezone = timezone;
  }
  
}
