import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-ten-scale',
  templateUrl: './ten-scale.component.html',
  styleUrls: ['./ten-scale.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TenScaleComponent),
    multi: true
  }]
})
export class TenScaleComponent implements ControlValueAccessor {
  @Input() disabled: boolean;
  selection = 1;
  propagateChange = (_: any) => { };

  writeValue(value: any) {
    if (value !== undefined) {
      this.selection = value;
    } else {
      value = 0;
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }

  select(event: any) {
    if (!this.disabled) {
      this.selection = event.target.value;
      this.propagateChange(this.selection);
    }
  }
  cleanSelect(selection: number) {
    if (selection < 1) {
      return 'N/A ';
    } else {
      return selection;
    }
  }

}
