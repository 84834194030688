export const deepClone = <T>(array: Array<T>): Array<T> => JSON.parse(JSON.stringify(array));

export const clone = <T>(o: T): T => Object.assign({}, o);

export const shuffle = <T>(array: Array<T>): Array<T> => {
    let currentIndex = array.length;
    let temporaryValue: T
    let randomIndex: number;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

export const groupBy = function<TItem>(xs: TItem[], key: string) : {[key: string]: TItem[]} {
    return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}

export const sanitizeUrl = (url: string): string => url
    .replace(/^\//, "") // Match slash at beginning of URL
    .replace(/\/$/, ""); // Match slash at end of URL

export const getParameterByName = (name: string): string | null => {
    name = name.replace(/[\[\]]/g, '\\$&');

    const url = window.location.href;
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);

    if (!results) {
        return null;
    }

    if (!results[2]) {
        return '';
    }

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}