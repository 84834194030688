// TODO: Uncomment all the stuff in this and the template when backend routes are created

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ColumnToggleComponent } from '@app/shared/column-toggle/column-toggle.component';
import { PaginationNewComponent } from '@app/shared/pagination/pagination-new/pagination-new.component';
import { FilterMethod, FilterOption } from '@app/models/universal-filter-option.model';
import { CompanyFeatures } from '@app/models/company.model';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '@app/shared/breadcrumbs/breadcrumbs.service';
import { GoalsAPIService } from '@app/shared/api/goals.api.service';
import { Globals } from '@app/shared/globals/globals';
import { Breadcrumb } from '@app/models/breadcrumb.model';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { GoalDepartmentOverview } from '@app/models/goals/goal-department-overview.model';
import { ModalComponent } from '@app/shared/modal/modal.component';
import { Goal, GoalType } from '@app/models/goals/goal.model';
import { OrganisationalUnit } from '@app/models/organisational-unit.model';
import { CompanyWording } from '@app/models/company/company-wording/company-wording.model';

// #region - types
enum TableColumn {
  NAME = 'Name',
  GOAL_COUNT = 'Goals',
  ACTIVE_GOAL_COUNT = 'Active',
  COMPLETE_GOAL_COUNT = 'Complete',
  TOTAL_OBJECTIVES = 'Total Objectives',
  AVERAGE_COMPLETION = 'Average Completion',
  ACTIONS = 'Actions'
}

interface PageState {
  loading: boolean;
  error: boolean;
  errorMessage: string;
}

interface PageDepartment extends GoalDepartmentOverview {
  dropdownOpen: boolean;
  childDepartments: PageDepartment[];
}

interface Sort {
  column: TableColumn;
  ascending: boolean;
}
// #endregion

@Component({
  selector: 'app-goals-overview-departments',
  templateUrl: './goals-overview-departments.component.html',
  styleUrls: ['./goals-overview-departments.component.css']
})
export class GoalsOverviewDepartmentsComponent implements OnInit, OnDestroy {
  @Input() page: number;
  @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('columnToggle') columnToggle!: ColumnToggleComponent;
  @ViewChild('pagination') pagination!: PaginationNewComponent;
  @ViewChild('departmentGoalsModal') departmentGoalsModal!: ModalComponent;

  breadcrumb: Breadcrumb;
  state: PageState;

  // Stats
  rootDepartment: PageDepartment;
  roundProgress: {
    left: string;
    right: string;
  }

  // Table data
  eTableColumn = TableColumn;
  columns: string[];

  departments: PageDepartment[];
  departmentsFiltered: PageDepartment[];
  departmentsDisplay: PageDepartment[];

  // Sorting
  sortType: Sort;

  // Universal filter
  filterOptions: FilterOption[];
  searchProps: string[];
  lastFilterResult: number[];

  eGoalType = GoalType;
  eFeature = CompanyFeatures

  // Modal
  viewingDepartment: {
    department: OrganisationalUnit;
    goals: Goal[];
  }
  loadingDepartmentGoals: boolean;
  companyWording: CompanyWording;

  constructor(
    public route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private goalsAPIService: GoalsAPIService,
    public globals: Globals
  ) {
    this.companyWording = this.globals.company.companyWording;
    this.page = 1;
    this.rootDepartment = undefined!;
    this.roundProgress = {
      left: 'rotate(0)',
      right: 'rotate(0)'
    }

    this.departments = [];
    this.departmentsFiltered = [];
    this.departmentsDisplay = [];

    this.sortType = {
      column: TableColumn.ACTIONS,
      ascending: true
    }

    this.viewingDepartment = {
      department: undefined!,
      goals: []
    }
    this.loadingDepartmentGoals = false;

    this.filterOptions = [];
    this.searchProps = [];
    this.lastFilterResult = [];

    this.columns = Object.keys(TableColumn).map(c => TableColumn[c]);

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    }
    this.breadcrumb = this.breadcrumbService.init(this.route);
  }

  // #region - LIFECYCLE HOOKS
  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {
    this.breadcrumbService.remove(this.breadcrumb);
  }
  // #endregion

  // Get the initial page data
  getData() {
    forkJoin(
      this.goalsAPIService.getDepartmentGoalsOverview()
    ).subscribe(([departmentGoalsOverview]) => {
      // Trigger empty state if no stats found
      if (!departmentGoalsOverview) {
        return this.displayError('Failed to get ' + this.companyWording.department.toLowerCase() + ' goals');
      }

      this.rootDepartment = departmentGoalsOverview as PageDepartment;
      this.rootDepartment.dropdownOpen = false;
      this.getRoundProgress(this.rootDepartment.averageCompletion);

      this.rootDepartment.childDepartments = this.convertReportArrayToPageUser(this.rootDepartment.childDepartments)
      this.departments = [this.rootDepartment];
      this.departmentsFiltered = this.departments;

      this.getUniversalFilterData();
      this.doSort(TableColumn.NAME);

      this.state.loading = false;
    }, (err: Error) => {
      this.displayError(err.message);
    });
  }

  displayError(message: string) {
    this.state = {
      loading: false,
      error: true,
      errorMessage: message
    }
  }

  convertReportArrayToPageUser(departmentGoalsOverview: GoalDepartmentOverview[]): PageDepartment[] {
    const departments: PageDepartment[] = departmentGoalsOverview as PageDepartment[];

    departments.forEach(dept => {
      dept.dropdownOpen = false;
      dept.childDepartments.forEach(tg => tg.dropdownOpen = false);
    });

    return departments;
  }

  getUniversalFilterData() {
    this.filterOptions = [];
    const inputOptions: FilterOption[] = [];

    this.departments.forEach(dr => {
      const option: FilterOption = {
        id: dr.department.id, // departments ID, for returning a result that can be filtered
        properties: {
          'department_name': {value: dr.department.name, filterMethod: FilterMethod.OR} // Users name - searchable
        }
      }

      option.properties['goals'] = {value: this.getRangeFilter(dr.goalsCount), filterMethod: FilterMethod.OR};
      option.properties['active_goals'] = {value: this.getRangeFilter(dr.activeCount), filterMethod: FilterMethod.OR};
      option.properties['complete_goals'] = {value: this.getRangeFilter(dr.completeCount), filterMethod: FilterMethod.OR};
      option.properties['average_completion'] = {value: this.getPercentRangeFilter(dr.averageCompletion), filterMethod: FilterMethod.OR};
      option.properties['child_departments'] = {value: this.getChildDeptNames(dr.childDepartments).join(' '), filterMethod: FilterMethod.OR};

      inputOptions.push(option);
    })

    this.searchProps = ['department_name', 'child_departments'];
    this.filterOptions = inputOptions;
  }

  getChildDeptNames(departments: PageDepartment[]) {
    let deptNames: string[] = [];
    departments.forEach(d => {
      deptNames.push(d.department.name)
      if (d.childDepartments.length > 0) {
        deptNames = [...deptNames, ...this.getChildDeptNames(d.childDepartments)]
      }
    });
    return deptNames;
  }

  getRangeFilter(count: number) {
    if (count > 100) {
      return '100+';
    }

    if (count > 50) {
      return '51-100';
    }

    if (count > 20) {
      return '21-50';
    }

    if (count > 10) {
      return '11-20';
    }

    if (count > 5) {
      return '6-10'
    }

    if (count > 0) {
      return '1-5'
    }
    return 'None';
  }

  getPercentRangeFilter(progress: number) {
    if (progress > 80) {
      return '81-100';
    }

    if (progress > 60) {
      return '61-80';
    }

    if (progress > 40) {
      return '41-60';
    }

    if (progress > 20) {
      return '21-40'
    }

    if (progress > 0) {
      return '1-20'
    }

    return '0%';
  }

  // #region - SORTING AND FILTERING
  doSort(column: TableColumn) {
    if (column === this.sortType.column) {
      this.sortType.ascending = !this.sortType.ascending;
      this.departmentsFiltered = this.departmentsFiltered.reverse();
    } else {
      this.sortType = {
        column: column,
        ascending: false
      }
      this.departmentsFiltered = this.sortByColumn(column);
    }

    if (this.pagination) {
      this.pagination.update();
    }
  }

  sortByColumn(column: TableColumn): PageDepartment[] {
    switch (column) {
      case TableColumn.NAME:
        return this.departments.sort((a, b) => {
          return ((a.department.name > b.department.name) ? 1 : -1);
        });
      case TableColumn.GOAL_COUNT:
        return this.departments.sort((a, b) => {
          if (a.goalsCount === b.goalsCount) {
            return 0;
          };
          return ((a.goalsCount > b.goalsCount) ? 1 : -1);
        });
      case TableColumn.ACTIVE_GOAL_COUNT:
        return this.departments.sort((a, b) => {
          return ((a.activeCount > b.activeCount) ? 1 : -1);
        });
      case TableColumn.COMPLETE_GOAL_COUNT:
        return this.departments.sort((a, b) => {
          return ((a.completeCount > b.completeCount) ? 1 : -1);
        })
      case TableColumn.TOTAL_OBJECTIVES:
        return this.departments.sort((a, b) => {
        return ((a.objectiveCount > b.objectiveCount) ? 1 : -1);
        });
      case TableColumn.AVERAGE_COMPLETION:
        return this.departments.sort((a, b) => {
          return ((a.averageCompletion > b.averageCompletion) ? 1 : -1);
        });
      default: // Unknown or name col
        return this.departments.sort((a, b) => {
          return ((a.department.name > b.department.name) ? 1 : -1);
        });
    }
  }


  universalFilterEmit(result: number[]) {
    this.lastFilterResult = result;
    this.departmentsFiltered = this.departments.filter(dr => result.includes(dr.department.id));
  }
  // #endregion

  getDaysRemaining(endDate: string) {
    const end = new Date(endDate);

    const a = moment(end);
    const b = moment(new Date());

    const difference = a.diff(b, 'days');
    if (difference === 0) {
      return 'Due today';
    } else if (difference === 1) {
      return '1 day remaining';
    } else if (difference > 1) {
      return difference + ' days remaining';
    } else if (difference === -1) {
      return '1 day overdue';
    } else if (difference < -1) {
      return (-1 * difference) + ' days overdue';
    }

    return '';
  }

  getDaysRemainingTotal(endDate: string) {
    const end = new Date(endDate);

    const a = moment(end);
    const b = moment(new Date());

    return a.diff(b, 'days');
  }

  opendepartmentGoals(department: PageDepartment) {
    if (department && !this.loadingDepartmentGoals) {
      this.loadingDepartmentGoals = true;
      this.goalsAPIService.getDepartmentGoalsByDepartmentId(department.department.id).subscribe(goals => {
        goals = goals.filter(g => !g.archived)
        this.viewingDepartment = {
          department: department.department,
          goals: Goal.getGoalArrayCompletionPercentage(goals)
        };
        this.departmentGoalsModal.show();
      }, () => {}, () => this.loadingDepartmentGoals = false)
    }
  }

  getRoundProgress(progress: number) {
    let left = 0;
    let right = 0;
    if (progress > 0) {
      if (progress <= 50) {
        right = (progress / 100 * 360)
      } else {
        right = 180;
        left = ((progress - 50) / 100 * 360)
      }
    }
    this.roundProgress = {
      left: `rotate(${left}deg)`,
      right: `rotate(${right}deg)`
    };
  }

  closeGoalModal() {
    this.departmentGoalsModal.hide();
  }

  paginationChanged(e: any) {
    this.pageChanged.emit(e);
  }
}
