<div class="round-search" role="search">
  <div class="input-group">
    <span class="input-group-addon">
      <!-- Button w/ icon -->
      <button type="button"
        id="dropdownMenuButton" class="dropdown-toggle"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="fal fa-fw fa-caret-down" *ngIf="filtersSelected === 0"></span>
        <span class="filters-selected" *ngIf="filtersSelected > 0">{{filtersSelected}}</span>
      </button>

      <!-- Dropdown -->
      <div class="dropdown-menu col-toggle-dropdown" aria-labelledby="dropdownMenuButton">
        <div class="menu-item clickable check-container caret-right reset-filter" (click)="resetFilter($event)">
          <button type="button" class="btn-reset-filter">Reset</button>
        </div>
        <div *ngFor="let key of categoryKeys; trackBy: trackByFnKeys" class="menu-item check-container caret-right noselect default">
          <label>{{key.name | underscoreToSpace | titlecase}} <span class="amountSelected"
              *ngIf="getCategorySelectedCount(key.key); let count">({{count}})</span></label>
          <div class="dropdown-submenu">
            <!-- TODO: May need to add an option here for AND and OR instead of defaulting in code -->
            <div class="check-container clickable" *ngFor="let val of categories[key.key]; trackBy: trackByFnCategories; index as i"
              (click)="toggleProp($event, key.key, i)">
              <input type="checkbox" class="clickable" [checked]="val.enabled" />
              <label class="m-left-5 clickable">{{val.name | underscoreToSpace}}</label>
            </div>
          </div>
        </div>
      </div>
    </span>

    <!-- Search box -->
    <input type="search" class="form-control nav-search-bg" [placeholder]="placeholderText"
      [formControl]="searchControl" maxlength="100" />
  </div>
</div>