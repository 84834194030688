import { Competency } from '@app/models/competencies/competency.model';
import { mockCompetencyCategories } from '@app/mock/api/data/mockCompetencyCategories';

export const mockCompetencies: Competency[] = [
    {
        id: 1,
        name: "Demonstrate Values",
        description: "Ensure all all times that you uphold our values of customer first, we care and constant innovation during your work.",
        category: mockCompetencyCategories[0],
        creatorId: 1
    },
    {
        id: 2,
        name: "Lead by Example",
        description: "At all times we lead by example in our work and demonstrate effective leadership skills encompassing empathy, communication, active listening, strategic thinking & building high performing teams.",
        category: mockCompetencyCategories[1],
        creatorId: 1
    }
];