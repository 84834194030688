import { MockService } from '@app/mock/api/mock-service';
import { Deadline } from '@app/models/deadlines/deadline.model';
import { mockDeadlines } from '@app/mock/api/data/mockDeadlines';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { WelcomeService } from '@app/shared/api/interfaces/welcome.service';
import { sanitizeUrl } from '@app/shared/utils/helpers';

@Injectable()
export class WelcomeMockService implements MockService, WelcomeService {
    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);

        switch (true) {
            case url.endsWith('pi/welcome/deadlines'):
                return this.getDeadlines();
        }
    }

    getDeadlines(): Array<Deadline> {
        return mockDeadlines;
    }
}