import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { SharedProfileModule } from 'app/shared/profile/shared-profile.module';
import { Angulartics2Module } from 'angulartics2';
import { SharedModule } from 'app/shared/shared.module';
import { GoalsComponentsModule } from 'app/goals/goals-components/goals-components.module';

import { ProfileComponent } from './profile.component';
import { EditComponent } from './edit/edit.component';
import { SignUpComponent } from 'app/shared/sign-up/sign-up.component';
import { PersonalDetailsComponent } from 'app/shared/sign-up/personal-details/personal-details.component';
import { InterestsComponent } from 'app/shared/sign-up/interests/interests.component';
import { ProfileDetailsComponent } from 'app/shared/sign-up/profile-details/profile-details.component';
import { CompleteComponent } from 'app/shared/sign-up/complete/complete.component';
import { ChoosePasswordComponent } from 'app/shared/sign-up/choose-password/choose-password.component';
import { ProfilePictureComponent } from '@app/shared/sign-up/profile-details/profile-picture/profile-picture.component';
import { LocationDetailsComponent } from 'app/shared/sign-up/location-details/location-details.component';
import { UserComponent } from './user/user.component';

import { MapsAPIService } from 'app/shared/api/maps.api.service';
import { SurveyFormComponentsModule } from '@app/shared/survey-components/survey-form-components.module';
import { ProfilePraiseFullComponent } from './profile-praise-full/profile-praise-full.component';
import { CreateInterestModalComponent } from '@app/shared/sign-up/interests/create-interest-modal/create-interest-modal.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

const components = [
  ProfileComponent,
  EditComponent,
  SignUpComponent,
  PersonalDetailsComponent,
  InterestsComponent,
  CreateInterestModalComponent,
  ProfileDetailsComponent,
  CompleteComponent,
  ChoosePasswordComponent,
  ProfilePictureComponent,
  LocationDetailsComponent,
  UserComponent,
  ProfilePraiseFullComponent
]

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        SweetAlert2Module,
        LeafletModule,
        SharedProfileModule,
        Angulartics2Module,
        SharedModule,
        GoalsComponentsModule,
        SurveyFormComponentsModule,
        PopoverModule,
        TooltipModule
    ],
  declarations: components,
  exports: components,
  providers: [
    MapsAPIService
  ]
})
export class ProfileComponentsModule { }
