import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { environment } from '../../../../environments/environment';

declare var tinymce: any;

@Component({
  selector: 'app-editor-response',
  templateUrl: './editor-response.component.html',
  styleUrls: ['./editor-response.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: EditorResponseComponent,
    multi: true,
  }]
})
export class EditorResponseComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() disabled = false;
  @Input() display = false;
  @Input() resize: boolean;
  @Input() height: number | string;
  @Input() maxCharacters: number;
  // @deprecated
  @Input() maxlength?: number
  @Input() hardMax: number;
  @Input() inline: boolean;
  @Output() autoEvent: EventEmitter<boolean>;
  @Input() wordcountChanged: EventEmitter<number>;
  @ViewChild('editor') editorElement!: ElementRef;
  @Input() toolbar: string;
  @Input() plugins: string;
  @Input() contextMenu: string;
  @Input() columnIndent: boolean;
  @Input() showErrorBorder: boolean;
  @Input() enforceCharacterLimit: boolean;
  @Input() placeholder: string;
  @Input() autofocus: boolean;

  wordcount: number;

  warnWordcount: boolean;

  cachedValue: string;

  editor: any;
  _value: string;

  loading: boolean;

  onChange = (_: any) => {};
  onTouched = () => {};

  get value(): string {
    return this._value;
  }

  set value(val: string) {
    if (this._value !== val && !this.disabled) {
      this.cachedValue = val;
      this._value = val;
      this.onChange(val);
      this.autoEvent.emit(true);
    }
  }

  constructor(
      private cdRef: ChangeDetectorRef
  ) {
    this._value = '';

    this.resize = false;
    this.warnWordcount = false;
    this.inline = false;
    this.columnIndent = true;
    this.showErrorBorder = false;
    this.autofocus = true;
    this.enforceCharacterLimit = true;

    this.loading = true;

    this.autoEvent = new EventEmitter<boolean>();
    this.wordcountChanged = new EventEmitter<number>();

    this.wordcount = 0;
    this.height = 300;
    this.maxCharacters = 2000;
    this.hardMax = 40000;
    this.cachedValue = '';
    this.contextMenu = 'paste spellchecker tinymcespellchecker';
    this.toolbar = 'undo redo | formatselect | \
    bold italic underline strikethrough | \
    alignleft aligncenter alignright | \
    bullist numlist | \
    outdent indent | \
    table | \
    help';

    this.plugins = 'lists table help wordcount';
    this.placeholder = '';
  }

  ngOnInit() {
    if (this.maxCharacters > this.hardMax) {
      this.hardMax = this.maxCharacters + 500
    }

    if (this.maxlength) {
      console.error('Dont use [maxlength] on editor-response - use [maxCharacters] instead')
    }
  }

  ngOnDestroy() {
    this.removeEditor();
  }

  ngAfterViewInit() {
    this.initEditor();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['height']) {
      if (!changes['height'].firstChange) {
        this.removeEditor();
        setTimeout(() => {
          this.initEditor();
        }, 10);
      }
    }
  }

  // Set value with emit - TODO: Is this used?
  setValue(val: any) {
  }

  // Set value without emitting events
  writeValue(value: any) {
    if (value === null || value === undefined) {
      value = '';
    }

    this._value = value;
    setTimeout(() => {
      this.trySetEditorValue(value);
    }, 1);
  }

  // Register callbacks
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  setWordCount(count: number) {
    this.wordcount = count;
    this.wordcountChanged.emit(count);
    this.cdRef.detectChanges();
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled

    if (this.editor && this.editorElement) {
      this.disabled = isDisabled

      if (isDisabled) {
        this.editor.mode.set('readonly');
        // tinymce.activeEditor.setMode('readonly');
      } else {
        this.editor.mode.set('design');
        // tinymce.activeEditor.setMode('design');
      }
    }

    // this.initEditor();
  }

  initEditor() {
    const comp = this;
    if (this.editorElement && this.editorElement.nativeElement) {
      tinymce.init({
        target: this.editorElement.nativeElement,
        base_url: '/tinymce', // For loading TinyMCE locally
        suffix: '.min',       // For loading TinyMCE locally
        theme_url: '/tinymce/themes/silver/theme.min.js', // Load theme locally
        // skin_url: '/tinymce/skins/ui/oxide/', // Load skin locally
        height: this.height,
        // theme: 'advanced', // TODO: Need proxy for this
        menubar: false,
        browser_spellcheck: true,
        gecko_spellcheck: true,
        plugins: [ this.plugins ],
        toolbar: this.toolbar,
        toolbar_items_size : 'small',
        statusbar: false,
        contextmenu: this.contextMenu,
        visual: false, // Removes dotted borders on table with unspecified borders and makes solid,
        inline: this.inline,
        placeholder: this.placeholder,
        mobile: { theme: 'mobile' },
        setup: (editor: any) => {
          comp.editor = editor;

          // Event handling
          editor.on('init', (e: any) => {
            comp.loading = false;
            editor.setContent(comp._value)
            comp.setWordCount(editor.plugins.wordcount.body.getCharacterCount());
            editor.getBody().setAttribute('contenteditable', !comp.disabled);
            if (comp.disabled) {
              editor.setMode('readonly')
            } else {
              editor.setMode('design')
            }
          });
          editor.on('KeyDown', (e: any) => {
            this.warnWordcount = false;
            if (e.ctrlKey) { // Allow any keyboard shortcuts with ctrl
              return true;
            }

            switch (e.key) {
              case 'Backspace': // Allow backspace
              case 'ArrowLeft': // Allow Arrows
              case 'ArrowRight':
              case 'ArrowUp':
              case 'ArrowDown':
                return true;
              default:
                if ( // Stop characters if over limit
                    this.enforceCharacterLimit &&
                    (
                        (this.wordcount >= this.maxCharacters)
                        || (editor.getContent().length >= this.hardMax)
                        || (editor.plugins.wordcount.body.getCharacterCount() >= this.maxCharacters)
                    )
                ) {
                  this.warnWordcount = true;
                  e.preventDefault();
                  e.stopPropagation();
                  return false;
                }
                break;
            }
          });
          editor.on('KeyUp', (e: any) => {
            const value = editor.getContent();
            if (value !== this.cachedValue) {
              this.value = value;
              this.setWordCount(editor.plugins.wordcount.body.getCharacterCount());
            }
          });
        },
        table_default_attributes: {
          border: '1px solid black'
        },
        elementpath: false,
        valid_styles : { '*' : 'font-size,font-weight,font-style,text-decoration,border' },
        resize: this.resize,
        auto_focus: this.autofocus,
        external_plugins: environment.mock.enabled ? {} : { // Disable plugins on the public sandbox
          'tinymcespellchecker': '/assets/tinymce_plugins/tinymcespellchecker/plugin.min.js',
          'powerpaste': '/assets/tinymce_plugins/powerpaste/plugin.min.js'
        },
        spellchecker_language: 'en_us',
        spellchecker_rpc_url: 'api/spellcheck',
        theme_advanced_resizing : true,
        theme_advanced_resize_horizontal : false,
        autoresize_min_height: this.height,
        autoresize_max_height: 800,
        content_style: `
          .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
            color: #AAAAAA;
          }
        `
      });
    }
  }

  removeEditor() {
    if (this.editor) {
      this.editor.remove();
    }
  }

  trySetEditorValue(value: string) {
    if (value === null || value === undefined) {
      value = '';
    }

    if (this.editor) {
      this.editor.setContent(value);
    }
  }
}
