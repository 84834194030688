import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateTodoDto, Todo, UpdateTodoDto } from 'app/models/todos/todos.model';
import { DateService } from '../utils/date.service';
import { map } from 'rxjs/operators';
import { TodoService } from '@app/shared/api/interfaces/todo.service';

@Injectable()
export class TodoAPIService implements TodoService {
  BASE_URL = 'api/todo';
  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(
    private http: HttpClient,
    private dateService: DateService
  ) { }


  /**
   * maps returned todo to Parses Utc dates to localdate
   * @param Todo
   */
  mapTodo(todo: Todo): Todo {
    todo.creationDate = this.dateService.parseUtcToLocal(todo.creationDate);

    if (todo.dueDate !== null) {
      todo.dueDate = this.dateService.parseUtcToLocal(todo.dueDate);
    }

    if (todo.completionDate !== null) {
      todo.completionDate = this.dateService.parseUtcToLocal(todo.completionDate);
    }

    return todo;
  }

  /**
   * Get Todos
   */
  getTodos(): Observable<Array<Todo>> {
    const url = `${this.BASE_URL}/`;
    return this.http.get<Array<Todo>>(url).pipe(map(response => response.map(t => t = this.mapTodo(t))));
  }

  /**
   * Get completed Todos
   */
  getTodosComplete(): Observable<Array<Todo>> {
    const url = `${this.BASE_URL}/complete`;
    return this.http.get<Array<Todo>>(url).pipe(map(response => response.map(t => t = this.mapTodo(t))));
  }

  /**
   * Get incomplete todos
   */
  getTodosIncomplete(): Observable<Array<Todo>> {
    const url = `${this.BASE_URL}/incomplete`;
    return this.http.get<Array<Todo>>(url).pipe(map(response => response.map(t => t = this.mapTodo(t))));
  }


  /**
   * Create a todo
   * @param todo
   */
  createTodo(todo: CreateTodoDto) {
    const url = `${this.BASE_URL}/`;
    return this.http.post<Todo>(url, todo).pipe(map(t => t = this.mapTodo(t)));
  }

  /**
   * Mark todo as complete
   * @param id
   */
  markTodoAsCompleteById(id: number) {
    const url = `${this.BASE_URL}/complete/${id}`;
    return this.http.post<Todo>(url, null).pipe(map(t => t = this.mapTodo(t)));
  }

  /**
   * Mark todo as incomplete
   * @param id
   */
  markTodoAsIncompleteById(id: number) {
    const url = `${this.BASE_URL}/incomplete/${id}`;
    return this.http.post<Todo>(url, null).pipe(map(t => t = this.mapTodo(t)));
  }

  /**
   * Delete a todo
   * @param id
   */
  deleteTodoById(id: number) {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.delete<Todo>(url).pipe(map(t => t = this.mapTodo(t)));
  }

  /**
   * Update a todo
   * @param id
   */
  updateTodo(id: number, updateTodoDto: UpdateTodoDto) {
    const url = `${this.BASE_URL}/${id}`
    return this.http.put<Todo>(url, updateTodoDto).pipe(map(t => t = this.mapTodo(t)));
  }

}
