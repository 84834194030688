import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { Angulartics2Module } from 'angulartics2';

import { DatePickerOldComponent } from './utils/date-picker-old/date-picker-old.component';
import { FormInputDropdownComponent } from './utils/form-input-dropdown/form-input-dropdown.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs/breadcrumbs.component';
import { RouterModule } from '@angular/router';
import { ColumnToggleComponent } from './column-toggle/column-toggle.component';
import { ModalComponent } from './modal/modal.component';
import { NotFoundComponent } from './not-found/not-found.component';

import { ProfilePhotoComponent } from './profile-photo/profile-photo.component';
import { RichDisplayComponent } from './rich-display/rich-display.component';
import { UniversalFilterComponent } from './universal-filter/universal-filter.component';
import { GoalDropdownComponent } from './goal-dropdown/goal-dropdown.component';
import { GoalStatsComponent } from './goals-stats/goals-stats.component';
import { UserPickerComponent } from './user-picker/user-picker.component';
import { EditorResponseComponent } from './survey-components/editor-response/editor-response.component';

import { MultipleChoiceReportingComponent } from './multiple-choice-reporting/multiple-choice-reporting.component';
import { PersonDisplayComponent } from './components/person-display/person-display.component';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DateRangePickerComponent } from './utils/date-range-picker/date-range-picker.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { DatePickerComponent } from './utils/date-picker/date-picker.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TimePickerComponent } from './utils/time-picker/time-picker.component';
import { DateTimePickerComponent } from './utils/date-time-picker/date-time-picker.component';
import { FeedbackUnpromptedDisplayComponent } from './feedback/feedback-unprompted-display/feedback-unprompted-display.component';
import { FeedbackIdeaDisplayComponent } from './feedback/feedback-idea-display/feedback-idea-display.component';
import { FeedbackPraiseDisplayComponent } from './feedback/feedback-praise-display/feedback-praise-display.component';
import { FeedbackPeerDisplayComponent } from './feedback/feedback-peer-display/feedback-peer-display.component';
import { FeedbackRequestDisplayComponent } from './feedback/feedback-request-display/feedback-request-display.component';

import { SessionLogChartComponent } from './session-log/session-log-table/session-log-table.component';
import { SessionLogWeeklyBreakdownComponent } from './session-log/session-log-weekly-breakdown/session-log-weekly-breakdown.component';
import { FeedbackUploadDisplayComponent } from './feedback/feedback-upload-display/feedback-upload-display.component';
import { UploadedFileDisplayComponent } from './uploaded-file-display/uploaded-file-display.component';
import { CompetencyModalComponent } from '@app/shared/competency-modal/competency-modal.component';
import { SessionLogUserStickinessOverviewDailyComponent } from './session-log/session-log-user-stickiness/session-log-user-stickiness-overview-daily/session-log-user-stickiness-overview-daily.component';
import { SessionLogUserStickinessOverviewMonthlyComponent } from './session-log/session-log-user-stickiness/session-log-user-stickiness-overview-monthly/session-log-user-stickiness-overview-monthly.component';
import { SessionLogUserStickinessOverviewWeeklyComponent } from './session-log/session-log-user-stickiness/session-log-user-stickiness-overview-weekly/session-log-user-stickiness-overview-weekly.component';
import { RequestSandboxComponent } from './request-sandbox/request-sandbox.component';
import { UploadedFileLinkPreviewComponent } from './uploaded-file-link-preview/uploaded-file-link-preview.component';
import { FeedbackRequestResponseSingleComponent } from './feedback/feedback-request-response-single/feedback-request-response-single.component';

const components = [
  DatePickerOldComponent, // TODO: Remove this
  RichDisplayComponent,
  FormInputDropdownComponent,
  BreadcrumbsComponent,
  FormInputDropdownComponent,
  ColumnToggleComponent,
  ModalComponent,
  ProfilePhotoComponent,
  NotFoundComponent,
  UniversalFilterComponent,
  GoalDropdownComponent,
  GoalStatsComponent,
  UserPickerComponent,
  EditorResponseComponent,
  GoalStatsComponent,
  MultipleChoiceReportingComponent,
  PersonDisplayComponent,
  FileUploadComponent,
  DateRangePickerComponent,
  DatePickerComponent,
  TimePickerComponent,
  DateTimePickerComponent,
  SessionLogChartComponent,
  SessionLogWeeklyBreakdownComponent,

  FeedbackUnpromptedDisplayComponent,
  FeedbackIdeaDisplayComponent,
  FeedbackPraiseDisplayComponent,
  FeedbackPeerDisplayComponent,
  FeedbackRequestDisplayComponent,
  FeedbackUploadDisplayComponent,

  UploadedFileDisplayComponent,
  UploadedFileLinkPreviewComponent,

  SessionLogWeeklyBreakdownComponent,
  CompetencyModalComponent,
  SessionLogUserStickinessOverviewDailyComponent,
  SessionLogUserStickinessOverviewWeeklyComponent,
  SessionLogUserStickinessOverviewMonthlyComponent,
  RequestSandboxComponent,
  FeedbackRequestResponseSingleComponent
]

const importAndExport = [
  FormsModule,
  ReactiveFormsModule,
  SweetAlert2Module,
  Angulartics2Module,
  PipesModule
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TooltipModule.forRoot(),
    AngularMyDatePickerModule,
    TimepickerModule.forRoot(),
    ...importAndExport
  ],
  providers: [],
  declarations: components,
  exports: [
    ...components,
    ...importAndExport
  ]
})
export class SharedModule { }

