import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NextSurveyScheduleDate } from '@app/models/survey/next-survey-schedule-date.model';
import { PublishedEnpsSummary } from '@app/models/survey/published-enps-summary.model';
import { SurveyRange } from '@app/models/survey/survey-range.model';
import { CompletedSurveyLocations } from 'app/models/survey/completed-survey-locations.model';
import { CompletedSurvey } from 'app/models/survey/completed-survey.model';
import { PublishedSurvey, PublishedSurveyFull } from 'app/models/survey/publish-survey.model';
import { SurveyQuestion } from 'app/models/survey/question.model';
import { SurveyResultStats } from 'app/models/survey/result-stats.model';
import { Survey } from 'app/models/survey/survey.model';
import { ViewablePublishedSurvey } from 'app/models/survey/viewable-published-survey.model';
import { User } from 'app/models/user/user.model';
import { Observable } from 'rxjs';
import { SurveyService } from '@app/shared/api/interfaces/survey.service';

@Injectable()
export class SurveyAPIService implements SurveyService {
  constructor(private http: HttpClient) { }

  getPublishedSurveyById(id: number): Observable<PublishedSurveyFull> {
    const url = '/api/survey/published/' + id;
    return this.http.get<PublishedSurveyFull>(url);
  }

  getPublishedSurveyByEnpsId(id: number): Observable<PublishedSurveyFull> {
    const url = '/api/survey/enps/' + id;
    return this.http.get<PublishedSurveyFull>(url);
  }

  getPublishedEnpsSurveySummaryById(id: number, range: SurveyRange): Observable<PublishedEnpsSummary>{
    const url = `/api/survey/published/${id}/enps`;
    return this.http.post<PublishedEnpsSummary>(url, range);
  }

  getById(id: number): Observable<Survey> {
    const url = '/api/survey';
    const params = new HttpParams().append('id', String(id));
    return this.http.get<Survey>(url, { params });
  }

  getSurveys(): Observable<Array<Survey>> {
    const url = '/api/surveys';
    return this.http.get<Array<Survey>>(url);
  }

  getTemplateSurveys(): Observable<Array<Survey>> {
    const url = '/api/survey/templates';
    return this.http.get<Array<Survey>>(url);
  }

  getPublishedSurveys(): Observable<Array<PublishedSurveyFull>> {
    const url = '/api/survey/published/';
    return this.http.get<Array<PublishedSurveyFull>>(url);
  }

  getPublishedSurveysToUser(userId: number): Observable<Array<PublishedSurveyFull>> {
    const url = '/api/survey/published/all';
    const params = new HttpParams().append('userId', String(userId));
    return this.http.get<Array<PublishedSurveyFull>>(url, { params });
  }

  getPublishedSurveysBySurveyId(surveyId: number) {
    const url = `/api/survey/${surveyId}/published/`;
    return this.http.get<Array<PublishedSurvey>>(url);
  }

  getViewablePublishedSurveys(surveyId: number): Observable<Array<ViewablePublishedSurvey>> {
    const url = `/api/survey/${surveyId}/published/list`;
    return this.http.get<Array<ViewablePublishedSurvey>>(url);
  }

  createSurvey(survey: Survey): Observable<Survey> {
    const url = '/api/survey';
    return this.http.post<Survey>(url, survey);
  }

  updateSurvey(survey: Survey): Observable<Survey> {
    const url = `/api/survey/${survey.id}`;
    return this.http.put<Survey>(url, survey);
  }

  saveSurvey(survey: Survey): Observable<Survey> {
    if (survey.id) {
      return this.http.put<Survey>(`/api/survey/${survey.id}`, survey);
    } else {
      return this.http.post<Survey>('/api/survey', survey);
    }
  }

  updatePublishedSurvey(publishedSurvey: PublishedSurveyFull){
    return this.http.put<PublishedSurveyFull>(`/api/survey/published/${publishedSurvey.id}`, publishedSurvey);
  }

  getAllQuestions(): Observable<Array<SurveyQuestion>> {
    const url = '/api/survey/questions';
    return this.http.get<Array<SurveyQuestion>>(url);
  }

  getByQuestionId(id: number): Observable<SurveyQuestion> {
    const url = '/api/survey/questions';
    const params = new HttpParams().append('questionId', String(id));
    return this.http.get<SurveyQuestion>(url, { params });
  }

  updateSurveyQuestions(id: number, questionIds: Array<number>): Observable<Survey> {
    const url = '/api/survey/' + id + '/question';
    return this.http.put<Survey>(url, {questionIds})
  }

  publishSurvey(id: number, publishedSurvey: PublishedSurvey, publishNow: boolean): Observable<PublishedSurveyFull> {
    const url = `/api/survey/${id}/publish`;
    const params = new HttpParams().append('publishNow', String(publishNow));
    return this.http.post<PublishedSurveyFull>(url, publishedSurvey, { params });
  }

  addCompletedSurvey(completedSurvey: CompletedSurvey): Observable<CompletedSurvey> {
    const url = '/api/survey/published/complete';
    return this.http.post<CompletedSurvey>(url, completedSurvey);
  }

  getSurveyResponses(publishedSurveyId: number): Observable<Array<CompletedSurvey>> {
    const url = 'api/survey/published/' + publishedSurveyId + '/complete';
    return this.http.get<Array<CompletedSurvey>>(url);
  }

  getEnpsSurveyResponses(publishedSurveyId: number, range: SurveyRange) {
    const url = 'api/survey/published/' + publishedSurveyId + '/completeenps';
    return this.http.post<Array<CompletedSurvey>>(url, range);
  }

  getCompletedSurveyLocation(publishedSurveyId: number): Observable<CompletedSurveyLocations> {
    const url = 'api/survey/published/' + publishedSurveyId + '/locations';
    return this.http.get<CompletedSurveyLocations>(url);
  }

  archiveSurvey(surveyId: number): Observable<Survey> {
    const url = 'api/survey/' + surveyId + '/archive';
    return this.http.post<Survey>(url, {});
  }

  getSurveyResultStats(): Observable<Array<SurveyResultStats>> {
    const url = 'api/survey/published/stats';
    return this.http.get<Array<SurveyResultStats>>(url);
  }

  getSurveyResultStatsAdmin(): Observable<Array<SurveyResultStats>> {
    const url = 'api/survey/published/stats/admin';
    return this.http.get<Array<SurveyResultStats>>(url);
  }

  getAssignedPublishedSurveysUser(userId: number): Observable<Array<PublishedSurveyFull>> {
    const url = 'api/survey/published';
    const params = new HttpParams().append('userId', String(userId));
    return this.http.get<Array<PublishedSurveyFull>>(url, { params });
  }

  favouritePublishedSurvey(publishedSurveyId: number): Observable<PublishedSurvey> {
    const url = 'api/survey/published/favourite/' + publishedSurveyId;
    return this.http.put<PublishedSurvey>(url, {});
  }

  remindPublishedSurvey(publishedSurveyId: number): Observable<PublishedSurvey> {
    const url = 'api/survey/published/remind/' + publishedSurveyId;
    return this.http.put<PublishedSurvey>(url, {});
  }

  getSurveyRecipientsByPublishedSurveyId(publishedSurveyId: number): Observable<Array<User>> {
    const url = 'api/survey/published/' + publishedSurveyId + '/recipients';
    return this.http.get<Array<User>>(url);
  }

  getSurveyResults(publishedSurveyId: number) {
    const url = `api/survey/published/${publishedSurveyId}/download`;
    return this.http.get(url, {responseType: 'blob'});
  }

  getNextSurveyDate(publishedSurveyId: number) {
    const url = 'api/survey/published/' + publishedSurveyId + '/nextSurveyDate';
    return this.http.get<NextSurveyScheduleDate>(url);
  }
}
