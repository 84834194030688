export enum DeadlineType {
  SURVEY = 'SURVEY',
  ONE_TO_ONE = 'ONE_TO_ONE',
  GOAL = 'GOAL',
  FEEDBACK_REQUEST = 'FEEDBACK_REQUEST',
  TODO = 'TODO',
  POLL = 'POLL',
  EVALUATION_NOMINATIONS_REMAINING = 'EVALUATION_NOMINATIONS_REMAINING',
  EVALUATION_NOMINATION_FROM_DIRECT_REPORT_PENDING_APPROVAL = 'EVALUATION_NOMINATION_FROM_DIRECT_REPORT_PENDING_APPROVAL',
  EVALUATION_REQUEST_PENDING_APPROVAL = 'EVALUATION_REQUEST_PENDING_APPROVAL',
  EVALUATION_PEER_REVIEW_INCOMPLETE = 'EVALUATION_PEER_REVIEW_INCOMPLETE',
  EVALUATION_SELF_REFLECTION_INCOMPLETE = 'EVALUATION_SELF_REFLECTION_INCOMPLETE',
  EVALUATION_MANAGER_REVIEW_INCOMPLETE = 'EVALUATION_MANAGER_REVIEW_INCOMPLETE',
  COACHING_REQUEST_MANAGER = 'COACHING_REQUEST_MANAGER',
  COACHING_REQUEST_COACH = 'COACHING_REQUEST_COACH',
  COACH_APPLICATION = 'COACH_APPLICATION',
  ENPS_SURVEY = 'ENPS_SURVEY',
  MENTORING_REQUEST_MANAGER = 'MENTORING_REQUEST_MANAGER',
  MENTORING_REQUEST_MENTOR = 'MENTORING_REQUEST_MENTOR'
}
