import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserAPIService } from 'app/shared/api/user.api.service';
import { environment } from 'environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-choose-password',
  templateUrl: './choose-password.component.html',
  styleUrls: ['./choose-password.component.css']
})

export class ChoosePasswordComponent implements OnInit {
  @Input() step;
  minimumPasswordLength = environment.password.minimumLength;
  maximumPasswordLength = environment.password.maximumLength;

  passwordChangeForm: FormGroup;
  newPassword: AbstractControl;
  confirmPassword: AbstractControl;

  submitted: boolean;
  passwordChangeError: boolean;
  passwordChangeErrorMessage: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private formBuilder: FormBuilder,
    private userAPIService: UserAPIService,
  ) {
    this.passwordChangeForm = this.setupForm();
    this.newPassword = this.passwordChangeForm.controls.newPassword;
    this.confirmPassword = this.passwordChangeForm.controls.confirmPassword;
    this.submitted = false;
    this.passwordChangeError = false;
    this.passwordChangeErrorMessage = '';
  }

  ngOnInit() {
  }

  setupForm(): FormGroup {
    return this.formBuilder.group({
      newPassword: ['', [
        Validators.required,
        Validators.minLength(environment.password.minimumLength),
        Validators.maxLength(environment.password.maximumLength)
      ]],
      confirmPassword: ['', [
        Validators.required,
        Validators.minLength(environment.password.minimumLength),
        Validators.maxLength(environment.password.maximumLength)
      ]],
    });
  }

  change() {
    this.submitted = true;
    if (this.passwordChangeForm.valid === true) {
      if (this.newPassword.value !== this.confirmPassword.value) {
        this.passwordChangeError = true;
        this.passwordChangeErrorMessage = `Passwords don't match.`;
      } else {
        this.passwordChangeError = false;
        this.userAPIService.updatePasswordFirstTimeIn(this.newPassword.value).subscribe(user => {
          this.step.complete += 1;
          this.step.current = 'LOCATION_DETAILS';
        });
      }
    }
  }
}
