import { MockService } from '@app/mock/api/mock-service';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { CompanyService } from '@app/shared/api/interfaces/company.service';
import { Company, CompanyFeature, OfficeLocation, UpdateCompanyFiscalYearDto } from '@app/models/company.model';
import { CompanySetUpStepGroup } from '@app/models/company/company-set-up-step-group.model';
import { CompanySetUpStep, CompanySetUpStepType } from '@app/models/company/company-set-up-step.model';
import { OrgChartUserDto } from '@app/models/user/org-chart-user.model';
import { OrganisationalUnit } from '@app/models/organisational-unit.model';
import { UpdateCompanyFeaturesDto } from '@app/models/company/update-company-features-dto';
import { Observable } from 'rxjs';
import { User } from '@app/models/user/user.model';
import { Globals } from '@app/shared/globals/globals';
import { mockOrganisationalUnits } from '@app/mock/api/data/mockOrganisationalUnits';
import { mockOfficeLocations } from '@app/mock/api/data/mockOfficeLocations';
import { mockCompanies } from '@app/mock/api/data/mockCompanies';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { mockCompanySetUpStepGroups } from '@app/mock/api/data/mockCompanySetUpStepGroups';
import { sanitizeUrl } from '@app/shared/utils/helpers';

@Injectable()
export class CompanyMockService implements MockService, CompanyService {
    constructor(private readonly globals: Globals) { }

    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);

        switch (true) {
            case url.endsWith('api/company/me'):
                return this.getMe();
            case url.endsWith('api/company/org'):
                return this.getOrg();
            case url.endsWith('api/company/org?type=Department'):
                return this.getOrgByType('Department');
            case url.endsWith('api/company/office-locations'):
                return this.getAllOfficeLocations();
            case url.endsWith('api/company/org-chart'):
                return this.getCompanyOrgChart();
            case url.endsWith('api/company/set-up-steps'):
                return this.getSetUpSteps();
            case url.match(/api\/company\/manager\/\?orgunitId=\d+$/) !== null:
                const orgUnitId = +req.params.get('orgunitId');
                return this.getManagerForOrganisationalUnit(orgUnitId);
        }
    }

    getAllOfficeLocations(): Observable<OfficeLocation[]> | OfficeLocation[] {
        return mockOfficeLocations;
    }

    getById(id: number): Observable<Company> | Company {
        return mockCompanies.find(c => c.id === id);
    }

    getCompanyOrgChart(): Observable<Array<OrgChartUserDto>> | OrgChartUserDto[] {
        const ceo = mockUsers.find(u => u.id === 1);
        return [
            {
                userId: ceo.id,
                managerId: ceo.id,
                userInfo: {
                    name: ceo.firstName + ' ' + ceo.lastName,
                    imageURL: ceo.imageUrl,
                    position: ceo.position.name,
                    location: ceo.officeLocation.name
                },
                goalInfo: null,
                user: null,
                desc: null,
                children: this.getOrgChartDirectReports(ceo),
                collapsed: true,
                showPercentages: false
            }
        ];
    }

    private getOrgChartDirectReports(manager: User): OrgChartUserDto[] {
        return mockUsers.filter(u => u.id !== manager.id && u.managerId === manager.id).map(u => ({
            userId: u.id,
            managerId: u.id,
            userInfo: {
                name: u.firstName + ' ' + u.lastName,
                imageURL: u.imageUrl,
                position: u.position.name,
                location: u.officeLocation.name
            },
            goalInfo: null, // TODO Add Goal Info
            user: null,
            desc: null,
            children: this.getOrgChartDirectReports(u),
            collapsed: true,
            showPercentages: false
        }));
    }

    getManagerForOrganisationalUnit(orgUnitId: number): Observable<User> | User {
        const orgUnit = mockOrganisationalUnits.find(org => org.id === orgUnitId);
        return mockUsers.find(u => u.id === orgUnit.managerUserId);
    }

    getMe(): Observable<Company> | Company {
        return this.globals.company;
    }

    getOrg(): Observable<OrganisationalUnit[]> | OrganisationalUnit[] {
        return mockOrganisationalUnits;
    }

    getOrgByType(type: string): Observable<OrganisationalUnit[]> | OrganisationalUnit[] {
        return mockOrganisationalUnits;
    }

    getSetUpSteps(): Observable<Array<CompanySetUpStepGroup>> | CompanySetUpStepGroup[] {
        return mockCompanySetUpStepGroups;
    }

    // No Ops are listed below
    createOfficeLocation(officeLocation: OfficeLocation): Observable<OfficeLocation> {
        return undefined;
    }

    createOrganisationalUnit(organisationalUnit: OrganisationalUnit): Observable<OrganisationalUnit> {
        return undefined;
    }

    deleteOfficeLocation(id: number): Observable<OfficeLocation> {
        return undefined;
    }

    deleteOrganisationalUnit(organisationalUnit: OrganisationalUnit): Observable<OrganisationalUnit> {
        return undefined;
    }

    skipCompanySetUpStepType(type: CompanySetUpStepType): Observable<CompanySetUpStep> {
        return undefined;
    }

    updateFeatures(features: Array<CompanyFeature>): Observable<Array<CompanyFeature>> {
        return undefined;
    }

    updateFeaturesForId(companyId: number, dto: UpdateCompanyFeaturesDto): Observable<Array<CompanyFeature>> {
        return undefined;
    }

    updateFiscalYear(updateCompanyFiscalYearDto: UpdateCompanyFiscalYearDto): Observable<Company> {
        return undefined;
    }

    updateOfficeLocation(officeLocation: OfficeLocation): Observable<OfficeLocation> {
        return undefined;
    }

    updateOrganisationalUnit(organisationalUnit: OrganisationalUnit): Observable<OrganisationalUnit> {
        return undefined;
    }

}