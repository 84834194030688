import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-photo',
  templateUrl: './profile-photo.component.html',
  styleUrls: ['./profile-photo.component.css']
})
export class ProfilePhotoComponent implements OnInit {
  @Input() src: string;
  @Input() class = '';
  @Input() title = '';
  @Input() alt = '';
  @Input() borderColor: string;
  @Input() routerLink: string;
  @Input() width: string;
  @Input() showBackgroundImage: boolean;

  constructor(private el: ElementRef) {
    this.src = '';
    this.borderColor = '';
    this.routerLink = null!;
    this.width = '';
    this.showBackgroundImage = false;
  }

  ngOnInit() {

    const yourNativeElement: HTMLElement = this.el.nativeElement,
      parentElement: HTMLElement = yourNativeElement.parentElement!;
    // get all children and move them out of the element
    while (yourNativeElement.firstChild) {
      parentElement.insertBefore(yourNativeElement.firstChild, yourNativeElement);
    }
    // remove the empty element(the host)
    parentElement.removeChild(yourNativeElement);
  }

}
