import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent {
  @Input() background: 'light' | 'dark' | 'none' = 'dark';
  @Input() width = '45%';
  @Input() minWidth = 'unset';

  @Output() modalShown: EventEmitter<boolean>;
  @Output() modalHidden: EventEmitter<boolean>;

  public visible = false;
  public visibleAnimate = false;

  constructor() {
    this.modalShown = new EventEmitter<boolean>();
    this.modalHidden = new EventEmitter<boolean>();
  }

  public show(): void {
    this.visible = true;
    this.modalShown.emit(true);
    setTimeout(() => this.visibleAnimate = true, 100);
  }

  public hide(): void {
    this.visibleAnimate = false;
    this.modalHidden.emit(true);
    setTimeout(() => this.visible = false, 300);
  }

  public onContainerClicked(event: MouseEvent): void {
    if ((<HTMLElement>event.target).classList.contains('modal')) {
      this.hide();
    }
  }
}
