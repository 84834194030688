import { Observable, of, throwError } from 'rxjs';
import { HttpResponse, HttpStatusCode } from '@angular/common/http';
import { MockService } from '@app/mock/api/mock-service';
import { CompanyMockService } from '@app/mock/api/services/company.mock.service';
import { AuthMockService } from '@app/mock/api/services/auth.mock.service';
import { TodoMockService } from '@app/mock/api/services/todo.mock.service';
import { WelcomeMockService } from '@app/mock/api/services/welcome.mock.service';
import { GoalMockService } from '@app/mock/api/services/goal.mock.service';
import { NewFeatureMockService } from '@app/mock/api/services/new-feature.mock.service';
import { NewsActivityMockService } from '@app/mock/api/services/news-activity.mock.service';
import { Injectable } from '@angular/core';
import { InterestMockService } from '@app/mock/api/services/interest.mock.service';
import { CompetencyMockService } from '@app/mock/api/services/competency.mock.service';
import { PositionMockService } from '@app/mock/api/services/position.mock.service';
import { PraiseMockService } from '@app/mock/api/services/praise.mock.service';
import { IdeaMockService } from '@app/mock/api/services/idea.mock.service';
import { FeedbackMockService } from '@app/mock/api/services/feedback.mock.service';
import { AnalyticsMockService } from '@app/mock/api/services/analytics.mock.service';
import { NotificationMockService } from '@app/mock/api/services/notification.mock.service';
import { EvaluationCycleMockService } from '@app/mock/api/services/evaluation-cycle.mock.service';
import { OneToOneMockService } from '@app/mock/api/services/one-to-one.mock.service';
import { PollMockService } from '@app/mock/api/services/poll.mock.service';
import { QuestionBankMockService } from '@app/mock/api/services/question-bank.mock.service';
import { SurveyMockService } from '@app/mock/api/services/survey.mock.service';
import { ConnectMockHandler } from '@app/mock/api/services/connect/connect.mock.handler';
import { SettingsMockService } from '@app/mock/api/services/settings.mock.service';
import { SpellcheckMockService } from '@app/mock/api/services/spellcheck.mock.service';
import { CalendarMockService } from '@app/mock/api/services/calendar.mock.service';
import { UserMockHandler } from '@app/mock/api/services/user/user.mock.handler';

@Injectable()
export class MockApiService {
    // Note that the key must match the base path of each route.
    // For complex routes such as those for Connect, create a handler service to hand off the route to a mock service.
    // E.g: The key for 'api/interests' would be 'interests'.
    public readonly mockServices: Record<string, MockService> = {
        'authuser': this.authMockService,
        'company': this.companyMockService,
        'user': this.userMockHandler,
        'todo': this.todoMockService,
        'welcome': this.welcomeMockService,
        'goal': this.goalMockService,
        'new-features': this.newFeatureMockService,
        'news': this.newsActivityMockService,
        'interests': this.interestMockService,
        'competency': this.competencyMockService,
        'position': this.positionMockService,
        'praise': this.praiseMockService,
        'idea': this.ideaMockService,
        'feedback': this.feedbackMockService,
        'statistics': this.analyticsMockService,
        'notifications': this.notificationMockService,
        'connect': this.connectMockHandler,
        'question-bank': this.questionBankMockService,
        'evaluation': this.evaluationCycleMockService,
        'assessment-group-info': this.evaluationCycleMockService,
        'one-to-one': this.oneToOneMockService,
        'poll': this.pollMockService,
        'survey': this.surveyMockService,
        'surveys': this.surveyMockService, // This is included separately because the api/survey root uses a query param and should really be changed
        'settings': this.settingsMockService,
        'spellcheck': this.spellcheckMockService,
        'calendar': this.calendarMockService
    }

    /**
     * Routes that are allowed through the filter
     */
    public readonly allowedRoutes: Array<string> = [
        'api/customer-accounts/demo'
    ]

    /**
     * Routes that ignore the error handler
     */
    public readonly excludeErrorHandler: Array<string> = [
        'api/customer-accounts/demo'
    ]


    constructor(
        private readonly authMockService: AuthMockService,
        private readonly companyMockService: CompanyMockService,
        private readonly userMockHandler: UserMockHandler,
        private readonly todoMockService: TodoMockService,
        private readonly welcomeMockService: WelcomeMockService,
        private readonly goalMockService: GoalMockService,
        private readonly newFeatureMockService: NewFeatureMockService,
        private readonly newsActivityMockService: NewsActivityMockService,
        private readonly interestMockService: InterestMockService,
        private readonly competencyMockService: CompetencyMockService,
        private readonly positionMockService: PositionMockService,
        private readonly praiseMockService: PraiseMockService,
        private readonly ideaMockService: IdeaMockService,
        private readonly feedbackMockService: FeedbackMockService,
        private readonly analyticsMockService: AnalyticsMockService,
        private readonly notificationMockService: NotificationMockService,
        private readonly connectMockHandler: ConnectMockHandler,
        private readonly evaluationCycleMockService: EvaluationCycleMockService,
        private readonly oneToOneMockService: OneToOneMockService,
        private readonly pollMockService: PollMockService,
        private readonly questionBankMockService: QuestionBankMockService,
        private readonly surveyMockService: SurveyMockService,
        private readonly settingsMockService: SettingsMockService,
        private readonly spellcheckMockService: SpellcheckMockService,
        private readonly calendarMockService: CalendarMockService,
    ) { }

    static ok(body?: any): Observable<HttpResponse<any>> {
        return of(
            new HttpResponse({
                status: HttpStatusCode.Ok,
                body
            })
        );
    }

    static error(message: string, error: string): Observable<never> {
        return throwError({
            message: message,
            error: error,
            status: HttpStatusCode.NotModified
        });
    }
}