import { TalkingPointTemplate } from '@app/models/one-to-one/talking-point-template.model';
import {
    mockCareerFocusedTemplateTalkingPoints,
    mockCoachingTemplateTalkingPoints,
    mockEmployeeLedTemplateTalkingPoints, mockGoalOwnersTemplateTalkingPoints,
    mockJobSatisfactionTemplateTalkingPoints,
    mockManagerLedTemplateTalkingPoints,
    mockMentoringTemplateTalkingPoints,
    mockPerformanceReviewTemplateTalkingPoints,
    mockTeamAndCompanyTemplateTalkingPoints,
    mockWorkLifeBalanceTemplateTalkingPoints
} from '@app/mock/api/data/mockOneToOneRecommendedTalkingPoints';

export const mockTalkingPointTemplates: TalkingPointTemplate[] = [
    {
        id: 1,
        companyId: 1,
        createdTimestamp: new Date(),
        lastEditTimestamp: new Date(),
        name: 'Mentoring',
        defaultTemplate: false,
        description: 'An opportunity for a mentoring session between two people.',
        allowAdmin: true,
        allowManager: true,
        allowEmployee: true,
        talkingPoints: mockMentoringTemplateTalkingPoints
    },
    {
        id: 2,
        companyId: 1,
        createdTimestamp: new Date(),
        lastEditTimestamp: new Date(),
        name: 'Coaching',
        defaultTemplate: false,
        description: 'An opportunity for a coaching session between two people.',
        allowAdmin: true,
        allowManager: true,
        allowEmployee: true,
        talkingPoints: mockCoachingTemplateTalkingPoints
    },
    {
        id: 3,
        companyId: 1,
        createdTimestamp: new Date(),
        lastEditTimestamp: new Date(),
        name: 'Employee Led Conversation',
        defaultTemplate: true,
        description: 'An opportunity for a focused regular one to one between two people.',
        allowAdmin: true,
        allowManager: true,
        allowEmployee: true,
        talkingPoints: mockEmployeeLedTemplateTalkingPoints
    },
    {
        id: 4,
        companyId: 1,
        createdTimestamp: new Date(),
        lastEditTimestamp: new Date(),
        name: 'Performance Review Outcome',
        defaultTemplate: false,
        description: 'An opportunity to discuss the outcome of a recent review cycle in a focused manner.',
        allowAdmin: true,
        allowManager: true,
        allowEmployee: false,
        talkingPoints: mockPerformanceReviewTemplateTalkingPoints
    },
    {
        id: 5,
        companyId: 1,
        createdTimestamp: new Date(),
        lastEditTimestamp: new Date(),
        name: 'Manager Led Conversation',
        defaultTemplate: false,
        description: 'An opportunity to focus the conversations on your people\'s priorities and how you can support these.',
        allowAdmin: true,
        allowManager: true,
        allowEmployee: false,
        talkingPoints: mockManagerLedTemplateTalkingPoints
    },
    {
        id: 6,
        companyId: 1,
        createdTimestamp: new Date(),
        lastEditTimestamp: new Date(),
        name: 'Focused on Career',
        defaultTemplate: false,
        description: 'An opportunity to focus in on career priorities.',
        allowAdmin: true,
        allowManager: true,
        allowEmployee: false,
        talkingPoints: mockCareerFocusedTemplateTalkingPoints
    },
    {
        id: 7,
        companyId: 1,
        createdTimestamp: new Date(),
        lastEditTimestamp: new Date(),
        name: 'Job Satisfaction',
        defaultTemplate: false,
        description: 'An opportunity to discuss points related to satisfaction in role.',
        allowAdmin: true,
        allowManager: true,
        allowEmployee: false,
        talkingPoints: mockJobSatisfactionTemplateTalkingPoints
    },
    {
        id: 8,
        companyId: 1,
        createdTimestamp: new Date(),
        lastEditTimestamp: new Date(),
        name: 'Team and Company',
        defaultTemplate: false,
        description: 'An opportunity to focus in on team and company performance.',
        allowAdmin: true,
        allowManager: true,
        allowEmployee: false,
        talkingPoints: mockTeamAndCompanyTemplateTalkingPoints
    },
    {
        id: 9,
        companyId: 1,
        createdTimestamp: new Date(),
        lastEditTimestamp: new Date(),
        name: 'Work-life Balance',
        defaultTemplate: false,
        description: 'An opportunity to discuss aspects relating to work life balance.',
        allowAdmin: true,
        allowManager: true,
        allowEmployee: false,
        talkingPoints: mockWorkLifeBalanceTemplateTalkingPoints
    },
    {
        id: 10,
        companyId: 1,
        createdTimestamp: new Date(),
        lastEditTimestamp: new Date(),
        name: 'Goal Owners',
        defaultTemplate: false,
        description: 'An opportunity to formalise discussion between goal owners.',
        allowAdmin: true,
        allowManager: true,
        allowEmployee: true,
        talkingPoints: mockGoalOwnersTemplateTalkingPoints
    },
];