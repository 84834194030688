<div>
  <div class="content">
    <div class="row">
      <div class="col-md-2 col-md-offset-10 text-right" [class.hidden]="goals.length === 0">
        <div class="frankli-form-group d-inline-block">
          <app-column-toggle [titles]="columnTitles" #columnToggle></app-column-toggle>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="goals.length > 0">
    <div class="scroll-x">
      <table width="100%">
        <tr class="frankli-table frankli-table-header frankli-table-header-large noselect">
          <th *ngIf="columnToggle.getColumnEnabled('title')">Title</th>
          <th class="text-center" *ngIf="columnToggle.getColumnEnabled('priority') && globals.hasFeature(eFeature.GOAL_PRIORITY)">Priority</th>
          <th class="text-center" *ngIf="columnToggle.getColumnEnabled('type')">Type</th>
          <th class="text-center" *ngIf="columnToggle.getColumnEnabled('visibility')">Visibility</th>
          <th class="text-center" *ngIf="columnToggle.getColumnEnabled('key results')">Key Results</th>
          <th class="text-center" *ngIf="columnToggle.getColumnEnabled('owners')">Owners</th>
          <th class="text-center" *ngIf="columnToggle.getColumnEnabled('tags')">Tags</th>
          <th class="text-center" *ngIf="columnToggle.getColumnEnabled('end date')">End Date</th>
          <th class="text-center" *ngIf="columnToggle.getColumnEnabled('actions')">Actions</th>
        </tr>
        <tr class="frankli-table frankli-table-single-line" *ngFor="let goal of goalsDisplayed">
          <td *ngIf="columnToggle.getColumnEnabled('title')">
            <span>{{goal.title}}</span>
          </td>
        
          <td class="text-center"*ngIf="columnToggle.getColumnEnabled('priority') && globals.hasFeature(eFeature.GOAL_PRIORITY)"
          [title]="goal.priority">
            <span>{{goal.priority}}</span>
          </td>
        
          <td class="text-center" *ngIf="columnToggle.getColumnEnabled('type')">
            <span [title]="'Personal goal'" class="fal fa-user" *ngIf="goal.type === eGoalType.PERSONAL_OPERATIONAL"></span>
            <span [title]="'Personal developmental goal'" class="fal fa-graduation-cap" *ngIf="goal.type === eGoalType.PERSONAL_DEVELOPMENTAL"></span>
            <span [title]="companyWording.department +  ' goal' | titlecase" class="fal fa-briefcase" *ngIf="goal.type === eGoalType.DEPARTMENT"></span>
            <span [title]="'Company goal'" class="fal fa-building" *ngIf="goal.type === eGoalType.COMPANY"></span>
          </td>
        
          <td class="text-center" *ngIf="columnToggle.getColumnEnabled('visibility')" [title]="goal.visibility">
            <span class="fal fa-eye frankli-view expand-on-hover" *ngIf="goal.visibility === eGoalVisibility.PUBLIC"></span>
            <span class="fal fa-eye-slash frankli-view expand-on-hover" *ngIf="goal.visibility === eGoalVisibility.PRIVATE"></span>
          </td>
        
          <td class="text-center" *ngIf="columnToggle.getColumnEnabled('key results')" [title]="goal.keyResults.length + ' key results'">
            <span>{{goal.keyResults.length}}</span>
          </td>
        
          <td class="text-center" *ngIf="columnToggle.getColumnEnabled('owners')" [title]="goal.owners.length + ' goal owners'">
            <div class="draft-goal-owners-area" *ngFor="let owner of goal.owners; let i = index">
              <span class="user-icon">
                <app-profile-photo
                *ngIf="i < 4"
                [title]="owner.firstName + ' ' + owner.lastName" 
                [src]="owner.imageUrl"
                [routerLink]="'/profile/' + owner.id"
                ></app-profile-photo>
              </span>
            </div>
            <div *ngIf="goal.owners.length > 4" class="user-icon-more" [title]="'+ ' + (goal.owners.length - 4) + ' more'">
              +{{goal.owners.length - 4}}
            </div>
            <span *ngIf="goal.owners.length === 0">No goal owners</span>
          </td>
        
          <td class="text-center" *ngIf="columnToggle.getColumnEnabled('tags')" [title]="goal.tags.length + ' tags'">
            <span>{{goal.tags.length}}</span>
          </td>
        
          <td class="text-center" *ngIf="columnToggle.getColumnEnabled('end date')" [title]="goal.endDate | date: 'dd/MM/yyyy'">
            <span>{{goal.endDate | date: 'dd/MM/yyyy'}}</span>
          </td>
          
          <td class="text-center action-icons" *ngIf="columnToggle.getColumnEnabled('actions')">
            <span class="fal fa-edit frankli-edit expand-on-hover" [title]="'Edit'"
              (click)="editDraftGoal(goal)"></span>
            <span *ngIf="state.deleting === false || (state.deleting === true && state.id !== goal.id)"
                  class="fal fa-trash frankli-close expand-on-hover" [title]="'Delete'"
              (click)="deleteDraftGoal(goal)"></span>
            <span *ngIf="state.deleting === true && state.id == goal.id" class="fal fa-spin fa-spinner"
                [title]="'Deleting...'"></span>
          </td>
        </tr>
    
        <tr *ngIf="columnToggle.checkAllDisabled()">
          <td class="text-center">
            <div class="m-top-20 m-bottom-20">No columns selected to display, you can choose which columns to show by clicking the <span class="fal fa-columns text-info"></span> icon above</div>
          </td>
        </tr>
      </table>
    </div>
    
    <app-pagination-new-component *ngIf="!columnToggle.checkAllDisabled()" class="m-top-10" [arrayData]="goals" [arrayDisplay]="goalsDisplayed" [currentPage]="page" (pageChanged)="paginationChanged($event)" #pagination></app-pagination-new-component>  
  </div>
  
  <div class="content text-center" *ngIf="goals.length === 0">
    <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
    <br/>
    <span class="m-top-10 m-bottom-10">No Draft Goals</span>
  </div>
  
  <app-goal-modal #modalEditDraft>
    <div class="app-goal-modal-header">
      <app-goals-modal-header [modal]="modalEditDraft" [headerText]="'Edit draft goal'"></app-goals-modal-header>
    </div>
    <div class="app-goal-modal-body">
      <app-goals-create [modal]="modalEditDraft" [mode]="'EDIT_DRAFT'" [draftGoal]="selectedGoal"></app-goals-create>
    </div>
    <div class="app-goal-modal-sidebar">
      <app-goals-modal-sidebar [modal]="modalEditDraft"></app-goals-modal-sidebar>
    </div>
  </app-goal-modal>
</div>