import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { RoleName } from 'app/models/role.model';
import { Globals } from '../globals/globals';

@Injectable()
export class UserArchivedGuard implements CanActivate {
  constructor(
    private globals: Globals,
    private router: Router
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.globals.user.roles.some(role => role.name === RoleName.ARCHIVED)) {
      this.router.navigateByUrl('/archived');
      return false;
    }
    return true;
  }
}
