import { SurveyQuestionCategory } from '@app/models/survey/question-category.model';

export const mockSurveyQuestionCategories: SurveyQuestionCategory[] = [
  {
    id: 11,
    name: "Health and Wellbeing"
  },
  {
    id: 7,
    name: "Our Culture"
  },
  {
    id: 3,
    name: "Management support"
  },
  {
    id: 6,
    name: "Your Role"
  },
  {
    id: 5,
    name: "People & Teams"
  },
  {
    id: 4,
    name: "Leadership"
  },
  {
    id: 9,
    name: "Comments"
  },
  {
    id: 12,
    name: "Exit Interview"
  },
  {
    id: 1,
    name: "Engagement"
  },
  {
    id: 2,
    name: "Growth & Development"
  },
  {
    id: 10,
    name: "Company Performance"
  },
  {
    id: 13,
    name: "Training Evaluation"
  },
  {
    id: 14,
    name: "Covid Response"
  },
  {
    id: 15,
    name: "eNPS"
  }
];