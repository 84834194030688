import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileUploadValue } from '@app/models/file-upload-value.model';
import Swal from 'sweetalert2';

// TODO:
// - Prop to allow selection of multiple files

const FILE_SIZE_MULTIPLIER = 1048576 // 1024 * 1024 --> 50 * this = 50MB

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true
    }
  ]
})
export class FileUploadComponent implements ControlValueAccessor {
  @ViewChild('uploadInput') uploadInput?: ElementRef<HTMLInputElement>;

  @Input() maxFileSizeMB = 50; // Default to 50MB

  _value: FileUploadValue;

  get value(): FileUploadValue {
    return this._value;
  }

  set value(v: FileUploadValue) {
    this._value = v;
    this.onChange(v);
  }

  onChange: Function = () => {};

  constructor(private host: ElementRef<HTMLInputElement>) {
    this._value = null!;
  }

  // Clear file input
  writeValue(value: FileUploadValue): void {
    this.host.nativeElement.value = '';
    this._value = value;
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
  }

  fileChanged(event: any) {
    const comp = this;
    const reader = new FileReader();

    const files = event.target.files;

    if (files.length > 0) {
      const file = files[0];

      if (file.size > (this.maxFileSizeMB * FILE_SIZE_MULTIPLIER)) {
        Swal.fire({
          title: `Your file is too large`,
          text: `(Max size ${this.maxFileSizeMB}MB)`,
          imageUrl: 'assets/img/swal-icons/Frankli_cancel_icon-45.svg',
          imageWidth: 140,
          imageHeight: 140,
          confirmButtonColor: '#2DA1B3'
        });
        return;
      }

      const extension = file.name.split('.').pop().toLowerCase();

      reader.readAsDataURL(file);
      reader.onload = () => {
        const dataURL = reader.result as string;
        this.value = {
          file: file,
          extension: extension,
          dataURL: dataURL
        }
      };
      // this.onChange(file);
    }
    // const file = event && event.item(0);
  }

  changeFile() {
    if (this.uploadInput) {
      this.uploadInput.nativeElement.click();
    }
  }

  removeFile() {
    this.value = null!;
  }
}
