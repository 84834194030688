<div class="display-container" *ngIf="feedback">
  <!-- Sent by X to Y on Date -->
  <div class="area-metadata" *ngIf="(feedback.sender && showSourceUser) || (feedback.recipient && showDestinationUser) || (feedback.timestamp)">
    <span>Sent</span>

    <!-- Destination -->
    <span class="text-nowrap" *ngIf="feedback.recipient && showDestinationUser">
      <span>to</span>
      <span class="area-destination-user">
        <span class="user-picture">
          <app-profile-photo [src]="feedback.recipient.imageUrl"></app-profile-photo>
        </span>
        <span>{{feedback.recipient.firstName}} {{feedback.recipient.lastName}}</span>
      </span>
    </span>

    <!-- Source user -->
    <span class="text-nowrap" *ngIf="feedback.sender && showSourceUser">
      <span>by</span>
      <span class="area-source-user">
        <span class="user-picture">
          <app-profile-photo [src]="feedback.sender.imageUrl"></app-profile-photo>
        </span>
        <span>{{feedback.sender.firstName}} {{feedback.sender.lastName}}</span>
      </span>
    </span>

    <!-- Timestamp -->
    <span class="text-nowrap" *ngIf="feedback.timestamp">
      <span>on</span>
      <span class="area-timestamp">{{feedback.timestamp | date}}</span>
    </span>
  </div>

  <!-- Observation -->
  <div class="area-observation">
    <div class="feedback-question">Recently, I've observed that</div>
    <div class="feedback-answer">
      <ng-container *ngIf="feedback.observation; let answer; else noAnswer;">
        <app-rich-display [text]="answer"></app-rich-display>
      </ng-container>
    </div>
  </div>

  <!-- Reason -->
  <div class="area-reason">
    <div class="feedback-question">I mention this because</div>
    <div class="feedback-answer">
      <ng-container *ngIf="feedback.reason; let answer; else noAnswer;">
        <app-rich-display [text]="answer"></app-rich-display>
      </ng-container>
    </div>
  </div>

  <!-- Suggestion -->
  <div class="area-suggestion">
    <div class="feedback-question">One suggestion I have</div>
    <div class="feedback-answer">
      <ng-container *ngIf="feedback.suggestion; let answer; else noAnswer;">
        <app-rich-display [text]="answer"></app-rich-display>
      </ng-container>
    </div>
  </div>

  <!-- Type -->
  <div class="area-type">
    <div class="feedback-question">I hope you find this feedback</div>
    <div class="feedback-answer">
      <ng-container *ngIf="feedback.type; else noAnswer;">
        <ng-container [ngSwitch]="feedback.type">
          <div *ngSwitchCase="ePeerFeedbackType.ENCOURAGING">Encouraging</div>
          <div *ngSwitchCase="ePeerFeedbackType.HELPFUL">Helpful</div>
          <div *ngSwitchCase="ePeerFeedbackType.USEFUL">Useful</div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #noAnswer>
  <div>N/A</div>
</ng-template>