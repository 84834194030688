import { Injectable } from '@angular/core';
import { Company, CompanyFeatures } from 'app/models/company.model';
import { RoleName } from 'app/models/role.model';
import { User, UserInit } from 'app/models/user/user.model';
import { Subject } from 'rxjs';

type SidebarCollapseValue = 'true' | 'false';
@Injectable()
export class Globals {
  user!: User;
  company!: Company;
  welcomeViewed: boolean;
  hasUnsavedChanges: boolean;
  signedInWithSSO: boolean;
  public $sidebarCollapseToggled = new Subject<{previous: SidebarCollapseValue, next: SidebarCollapseValue}>();

  get sidebarCollapsed(): boolean {
    return (localStorage.getItem('sidebar_collapsed') === 'true'); // This has to be a string because you can't store booleans in localStorage
  }
  set sidebarCollapsed(collapsed: boolean) {
    const oldValue = localStorage.getItem('sidebar_collapsed') as SidebarCollapseValue;
    const newValue = collapsed.toString() as SidebarCollapseValue;
    localStorage.setItem('sidebar_collapsed', newValue);
    this.$sidebarCollapseToggled.next({
      previous: oldValue,
      next: newValue
    });
  }

  constructor() {
    this.welcomeViewed = true;
    this.hasUnsavedChanges = false;
    this.signedInWithSSO = false;
  }

  public init(init: UserInit) {
    this.user = init.user;
    this.company = init.company;
  }

  public updateUser(u: User) {
    this.user.birthdayReminder = u.birthdayReminder;
    this.user.dateOfBirth = u.dateOfBirth;
    this.user.description = u.description;
    this.user.email = u.email;
    this.user.firstName = u.firstName;
    this.user.userState = u.userState;
    this.user.gender = u.gender;
    this.user.homeAddress = u.homeAddress;
    this.user.id = u.id;
    this.user.imageUrl = u.imageUrl;
    this.user.lastName = u.lastName;
    this.user.officeLocation = u.officeLocation;
    this.user.organisationalUnit = u.organisationalUnit;
    this.user.personalInterests = u.personalInterests;
    this.user.phoneNumber = u.phoneNumber;
    this.user.position = u.position;
    this.user.preferredContactMethod = u.preferredContactMethod;
    this.user.professionalInterests = u.professionalInterests;
    this.user.roles = u.roles;
    this.user.startDate = u.startDate;
    this.user.version = u.version;
    this.user.twitterHandle = u.twitterHandle;
  }

  public updateCompany(company: Company) {
    this.company.name = company.name;
    this.company.state = company.state;
    this.company.features = company.features;
    this.company.address = company.address;
    this.company.officeLocations = company.officeLocations;
    this.company.id = company.id;
  }

  hasPermission(feature: CompanyFeatures) {
    console.warn('Using deprecated hasPermission function')
    return this.hasFeature(feature);
  }

  /**
   * returns true if the current company has the provided feature enabled, otherwise returns false
   * @param feature
   */
  public hasFeature(feature: CompanyFeatures): boolean {
    if (this.company && this.company.features) {
      return this.company.features.some(f => {
        return (f.enabled === true && f.feature === feature);
      });
    } else {
      // TODO: API request to update company object?
    }

    return false;
  }

  /**
   * returns true if the current user has the provided role, otherwise returns false
   * @param role
   */
  public hasRole(role: RoleName): boolean {
    if (this.user && this.user.roles) {
      return this.user.roles.some(r => r.name === role);
    } else {
      return false;
    }
  }

  public clear(): void {
    this.user = undefined!;
    this.company = undefined!;
    localStorage.clear();
  }
}
