export enum FrankliNotificationType {
  SURVEY_NOTIFICATION = 'SURVEY_NOTIFICATION',
  COMPLETED_SURVEY = 'COMPLETED_SURVEY',
  NEW_JOINER = 'NEW_JOINER',
  FEEDBACK_RECEIVED_MANAGER = 'FEEDBACK_RECEIVED_MANAGER',
  IDEA_RECEIVED_MANAGER = 'IDEA_RECEIVED_MANAGER',
  PRAISE_RECEIVED_MANAGER = 'PRAISE_RECEIVED_MANAGER',
  PRAISE_RECEIVED_USER = 'PRAISE_RECEIVED_USER',
  FEEDBACK_REPLY = 'FEEDBACK_REPLY',
  BIRTHDAY = 'BIRTHDAY',
  WORK_ANNIVERSARY_1_YEAR = 'WORK_ANNIVERSARY_1_YEAR',
  FEEDBACK_SENT_EMPLOYEE = 'FEEDBACK_SENT_EMPLOYEE',
  FEEDBACK_OPENED_EMPLOYEE = 'FEEDBACK_OPENED_EMPLOYEE',
  PRAISE_SENT_EMPLOYEE = 'PRAISE_SENT_EMPLOYEE',
  PRAISE_OPENED_EMPLOYEE = 'PRAISE_OPENED_EMPLOYEE',
  IDEA_SENT_EMPLOYEE = 'IDEA_SENT_EMPLOYEE',
  IDEA_OPENED_EMPLOYEE = 'IDEA_OPENED_EMPLOYEE',
  GOAL_COMMENT = 'GOAL_COMMENT',
  GOAL_KEY_RESULT = 'GOAL_KEY_RESULT',
  GOAL_STATUS = 'GOAL_STATUS',
  GOAL_NEW_OWNER = 'GOAL_NEW_OWNER',
  GOAL_COMPLETE = 'GOAL_COMPLETE',
  GOAL_UPDATE = 'GOAL_UPDATE',
  GOAL_CREATE = 'GOAL_CREATE',
  GOAL_REMINDER = 'GOAL_REMINDER',
  REFRESH = 'REFRESH',
  PROFILE_PIC_REMINDER = 'PROFILE_PIC_REMINDER',
  ONE_TO_ONE_INVITE = 'ONE_TO_ONE_INVITE',
  ONE_TO_ONE_DUE = 'ONE_TO_ONE_DUE',
  ONE_TO_ONE_DUE_MANAGER = 'ONE_TO_ONE_DUE_MANAGER',
  ONE_TO_ONE_SCHEDULE_EDIT = 'ONE_TO_ONE_SCHEDULE_EDIT',
  GOAL_KEY_RESULT_REMINDER = 'GOAL_KEY_RESULT_REMINDER',
  ONE_TO_ONE_DUE_COMPLETED = 'ONE_TO_ONE_DUE_COMPLETED',
  ONE_TO_ONE_DUE_COMPLETED_MANAGER = 'ONE_TO_ONE_DUE_COMPLETED_MANAGER',
  USER_REFRESH = 'USER_REFRESH',
  PEER_FEEDBACK_SENT = 'PEER_FEEDBACK_SENT',
  PEER_FEEDBACK_RECEIVED = 'PEER_FEEDBACK_RECEIVED',
  PEER_FEEDBACK_OPENED = 'PEER_FEEDBACK_OPENED',
  FEEDBACK_REQUEST_SENT = 'FEEDBACK_REQUEST_SENT',
  FEEDBACK_REQUEST_SEEN = 'FEEDBACK_REQUEST_SEEN',
  FEEDBACK_REQUEST_RECEIVED = 'FEEDBACK_REQUEST_RECEIVED',
  FEEDBACK_REQUEST_APPROVED = 'FEEDBACK_REQUEST_APPROVED',
  FEEDBACK_REQUEST_DECLINED = 'FEEDBACK_REQUEST_DECLINED',
  GOAL_DELETED = 'GOAL_DELETED',
  EVALUATION_CYCLE_INVITATION = 'EVALUATION_CYCLE_INVITATION',
  EVALUATION_CYCLE_INVITATION_MANAGER = 'EVALUATION_CYCLE_INVITATION_MANAGER',
  EVALUATION_CYCLE_REQUEST_MANAGER = 'EVALUATION_CYCLE_REQUEST_MANAGER',
  EVALUATION_CYCLE_SELF_REFLECTION_COMPLETE_MANAGER = 'EVALUATION_CYCLE_SELF_REFLECTION_COMPLETE_MANAGER',
  EVALUATION_CYCLE_CLOSED_MANAGER = 'EVALUATION_CYCLE_CLOSED_MANAGER',
  EVALUATION_CYCLE_SELF_REFLECTION_REMINDER = 'EVALUATION_CYCLE_SELF_REFLECTION_REMINDER',
  EVALUATION_CYCLE_REQUEST_REMINDER = 'EVALUATION_CYCLE_REQUEST_REMINDER',
  EVALUATION_CYCLE_REQUEST = 'EVALUATION_CYCLE_REQUEST',
  ADMIN_INVITE_REMINDER = 'ADMIN_INVITE_REMINDER',
  GOAL_DUE = 'GOAL_DUE',
  TODO_DUE = 'TODO_DUE',
  EVALUATION_CYCLE_STEP_START_REFLECTION = 'EVALUATION_CYCLE_STEP_START_REFLECTION',
  EVALUATION_CYCLE_STEP_START_PEER = 'EVALUATION_CYCLE_STEP_START_PEER',
  EVALUATION_CYCLE_STEP_START_MANAGER = 'EVALUATION_CYCLE_STEP_START_MANAGER',
  EVALUATION_CYCLE_STEP_END_REFLECTION = 'EVALUATION_CYCLE_STEP_END_REFLECTION',
  EVALUATION_CYCLE_STEP_END_PEER = 'EVALUATION_CYCLE_STEP_END_PEER',
  EVALUATION_CYCLE_STEP_END_MANAGER = 'EVALUATION_CYCLE_STEP_END_MANAGER',
  EVALUATION_CYCLE_MANAGER_REVIEW_REQUEST = 'EVALUATION_CYCLE_MANAGER_REVIEW_REQUEST',
  EVALUATION_CYCLE_360_REQUEST_DECLINED_BY_USER = 'EVALUATION_CYCLE_360_REQUEST_DECLINED_BY_USER',
  EVALUATION_CYCLE_360_REQUEST_DECLINED_BY_MANAGER = 'EVALUATION_CYCLE_360_REQUEST_DECLINED_BY_MANAGER',
  WORK_ANNIVERSARY_1_MONTH = 'WORK_ANNIVERSARY_1_MONTH',
  WORK_ANNIVERSARY_3_MONTH = 'WORK_ANNIVERSARY_3_MONTH',
  WORK_ANNIVERSARY_6_MONTH = 'WORK_ANNIVERSARY_6_MONTH',
  WORK_ANNIVERSARY_X_YEARS = 'WORK_ANNIVERSARY_X_YEARS',
  COMPANY_SET_UP_STEP_COMPLETE = 'COMPANY_SET_UP_STEP_COMPLETE',
  MANAGER_REVIEW_SHARED = 'MANAGER_REVIEW_SHARED',
  COACHING_REQUEST_PENDING_MANAGER_APPROVAL = 'COACHING_REQUEST_PENDING_MANAGER_APPROVAL',
  COACHING_REQUEST_DECLINED_BY_MANAGER = 'COACHING_REQUEST_DECLINED_BY_MANAGER',
  COACHING_REQUEST_DECLINED_BY_COACH_FOR_COACHEE = 'COACHING_REQUEST_DECLINED_BY_COACH_FOR_COACHEE',
  COACHING_REQUEST_APPROVED_BY_MANAGER = 'COACHING_REQUEST_APPROVED_BY_MANAGER',
  COACHING_REQUEST_APPROVED_BY_COACH_FOR_COACHEE = 'COACHING_REQUEST_APPROVED_BY_COACH_FOR_COACHEE',
  COACHING_REQUEST_PENDING_COACH_APPROVAL = 'COACHING_REQUEST_PENDING_COACH_APPROVAL',
  COACHING_REQUEST_CLOSED_BY_COACH_FOR_COACHEE = 'COACHING_REQUEST_CLOSED_BY_COACH_FOR_COACHEE',
  PRAISE_REACTION = 'PRAISE_REACTION',
  POLL_NOTIFICATION = 'POLL_NOTIFICATION',
  POLL_REMINDER = 'POLL_REMINDER',
  POLL_COMPLETED = 'POLL_COMPLETED',
  BULK_UPLOAD_CREATE_COMPLETE = 'BULK_UPLOAD_CREATE_COMPLETE',
  BULK_UPLOAD_CREATE_FAILED = 'BULK_UPLOAD_CREATE_FAILED',
  MANAGER_REVIEW_SHARED_READY = 'MANAGER_REVIEW_SHARED_READY',
  MANAGER_REVIEW_SHARED_REMINDER = 'MANAGER_REVIEW_SHARED_REMINDER',
  ONE_TO_ONE_CALENDAR_ACCEPTED = 'ONE_TO_ONE_CALENDAR_ACCEPTED',
  ONE_TO_ONE_CALENDAR_DECLINED = 'ONE_TO_ONE_CALENDAR_DECLINED',
  ONE_TO_ONE_CALENDAR_TENTATIVELY_ACCEPTED = 'ONE_TO_ONE_CALENDAR_TENTATIVELY_ACCEPTED',
  ONE_TO_ONE_NOTES_UPDATED = 'ONE_TO_ONE_NOTES_UPDATED',
  ONE_TO_ONE_MEETING_STARTED = 'ONE_TO_ONE_MEETING_STARTED',
  ONE_TO_ONE_MEETING_COMPLETED = 'ONE_TO_ONE_MEETING_COMPLETED',
  COACHING_REQUEST_CLOSED_BY_COACH_FOR_MANAGER = 'COACHING_REQUEST_CLOSED_BY_COACH_FOR_MANAGER',
  COACHING_REQUEST_APPROVED_BY_COACH_FOR_MANAGER = 'COACHING_REQUEST_APPROVED_BY_COACH_FOR_MANAGER',
  COACH_APPLICATION_PENDING_MANAGER_APPROVAL = 'COACH_APPLICATION_PENDING_MANAGER_APPROVAL',
  COACH_APPLICATION_DECLINED_BY_MANAGER = 'COACH_APPLICATION_DECLINED_BY_MANAGER',
  COACH_APPLICATION_APPROVED_BY_MANAGER = 'COACH_APPLICATION_APPROVED_BY_MANAGER',
  COACH_APPLICATION_AMENDMENT_PENDING_MANAGER_APPROVAL = 'COACH_APPLICATION_AMENDMENT_PENDING_MANAGER_APPROVAL',
  COACH_APPLICATION_AMENDMENT_DECLINED_BY_MANAGER = 'COACH_APPLICATION_AMENDMENT_DECLINED_BY_MANAGER',
  COACH_APPLICATION_AMENDMENT_APPROVED_BY_MANAGER = 'COACH_APPLICATION_AMENDMENT_APPROVED_BY_MANAGER',
  COACHING_REQUEST_DECLINED_BY_COACH_FOR_MANAGER = 'COACHING_REQUEST_DECLINED_BY_COACH_FOR_MANAGER',
  COACHING_REQUEST_CLOSED_BY_MANAGER_FOR_COACH = 'COACHING_REQUEST_CLOSED_BY_MANAGER_FOR_COACH',
  INTEREST_PENDING_ADMIN_APPROVAL = 'INTEREST_PENDING_ADMIN_APPROVAL',
  INTEREST_APPROVED_BY_ADMIN = 'INTEREST_APPROVED_BY_ADMIN',
  INTEREST_DECLINED_BY_ADMIN = 'INTEREST_DECLINED_BY_ADMIN',
  MENTORING_REQUEST_PENDING_MANAGER_APPROVAL = 'MENTORING_REQUEST_PENDING_MANAGER_APPROVAL',
  MENTORING_REQUEST_DECLINED_BY_MANAGER = 'MENTORING_REQUEST_DECLINED_BY_MANAGER',
  MENTORING_REQUEST_DECLINED_BY_MENTOR_FOR_MENTEE = 'MENTORING_REQUEST_DECLINED_BY_MENTOR_FOR_MENTEE',
  MENTORING_REQUEST_APPROVED_BY_MANAGER = 'MENTORING_REQUEST_APPROVED_BY_MANAGER',
  MENTORING_REQUEST_APPROVED_BY_MENTOR_FOR_MENTEE = 'MENTORING_REQUEST_APPROVED_BY_MENTOR_FOR_MENTEE',
  MENTORING_REQUEST_PENDING_MENTOR_APPROVAL = 'MENTORING_REQUEST_PENDING_MENTOR_APPROVAL',
  MENTORING_REQUEST_CLOSED_BY_MENTOR_FOR_MENTEE = 'MENTORING_REQUEST_CLOSED_BY_MENTOR_FOR_MENTEE',
  MENTORING_REQUEST_CLOSED_BY_MENTOR_FOR_MANAGER = 'MENTORING_REQUEST_CLOSED_BY_MENTOR_FOR_MANAGER',
  MENTORING_REQUEST_APPROVED_BY_MENTOR_FOR_MANAGER = 'MENTORING_REQUEST_APPROVED_BY_MENTOR_FOR_MANAGER',
  MENTORING_REQUEST_DECLINED_BY_MENTOR_FOR_MANAGER = 'MENTORING_REQUEST_DECLINED_BY_MENTOR_FOR_MANAGER',
  MENTORING_REQUEST_CLOSED_BY_MANAGER_FOR_MENTOR = 'MENTORING_REQUEST_CLOSED_BY_MANAGER_FOR_MENTOR',
  SURVEY_ENPS_NOTIFICATION = 'SURVEY_ENPS_NOTIFICATION',
  FRANKLI_TEST_NOTIFICATION = 'FRANKLI_TEST_NOTIFICATION',
  FRANKLI_TEST_TASK = 'FRANKLI_TEST_TASK',
  FEEDBACK_EXTERNAL_REQUEST_APPROVED = 'FEEDBACK_EXTERNAL_REQUEST_APPROVED',
  FEEDBACK_EXTERNAL_REQUEST_DECLINED = 'FEEDBACK_EXTERNAL_REQUEST_DECLINED',
}
