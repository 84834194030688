<div (click)="onContainerClicked($event)" class="modal fade" tabindex="-1" [ngClass]="{'in': visibleAnimate}"
     [ngStyle]="{'display': visible ? 'block' : 'none', 'opacity': visibleAnimate ? 1 : 0}"
     [class.modal-background-dark]="background === 'dark'"
     [class.modal-background-light]="background === 'light'"
     [class.modal-background-none]="background === 'none'"
>
  <div class="modal-dialog">
    <div class="modal-content" [style.width]="width" [style.minWidth]="minWidth">
      <div class="modal-body">
        <div class="modal-close" (click)="hide()">
          <span class="fal fa-times"></span>
        </div>
        <div class="modal-body-content">
          <ng-container *ngIf="competency">
            <div class="text-center">
              <h5>{{competency.name}}</h5>
            </div>

            <div class="frankli-form-group frankli-form-group-filled m-bottom-15">
              <label>Category</label>
              <div>{{competency.category.name}}</div>
            </div>

            <div class="frankli-form-group frankli-form-group-filled">
              <label>Description</label>
              <app-rich-display [text]="competency.description"></app-rich-display>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="modal-dismiss" (click)="hide()"></div>
  </div>
</div>