import { Injectable } from '@angular/core';
import { MockService } from '@app/mock/api/mock-service';
import { HttpRequest } from '@angular/common/http';
import { PollService } from '@app/shared/api/interfaces/poll.service';
import { Observable } from 'rxjs';
import { Poll } from '@app/models/poll/poll.model';
import { PollResponse } from '@app/models/poll/poll-response.model';
import { sanitizeUrl } from '@app/shared/utils/helpers';
import { mockPolls } from '@app/mock/api/data/mockPolls';
import { Globals } from '@app/shared/globals/globals';
import { mockPollResponses } from '@app/mock/api/data/mockPollResponses';

@Injectable()
export class PollMockService implements MockService, PollService {
    constructor(private readonly globals: Globals) { }

    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);

        switch (true) {
            case url.endsWith('api/poll') && req.method === 'GET':
                return this.getAllPolls();
            case url.match(/api\/poll\/\d+$/) !== null && req.method === 'GET':
                const pollId = +url.split('/')[2];
                return this.getPollById(pollId);
            case url.match(/api\/poll\/responses\/\d+$/) !== null:
                const respondedPollId = +url.split('/')[3];
                return this.getPollResponses(respondedPollId);
            case url.endsWith('api/poll/assigned'):
                return this.getAssignedPolls();
            case url.endsWith('api/poll/published'):
                return this.getAllPollsPublishedToUser();
        }
    }

    getAllPolls(): Observable<Array<Poll>> | Poll[] {
        return mockPolls;
    }

    getAllPollsPublishedToUser(): Observable<Array<Poll>> | Poll[] {
        return mockPolls.filter(p => p.creatorId === this.globals.user.id);
    }

    getAssignedPolls(): Observable<Array<Poll>> | Poll[] {
        return mockPolls.filter(p => p.recipients.includes(this.globals.user.id));
    }

    getPollById(id: number): Observable<Poll> | Poll {
        return mockPolls.find(p => p.id === id);
    }

    getPollResponses(id: number): Observable<PollResponse[]> | PollResponse[] {
        return mockPollResponses.filter(r => r.pollId === id);
    }

    // No Ops listed below
    archivePoll(id: number, updatePollDto: Poll): Observable<Poll> {
        return undefined;
    }

    createPoll(createPollDto: Poll): Observable<Poll> {
        return undefined;
    }

    createPollResponse(createPollResponse: PollResponse): Observable<PollResponse> {
        return undefined;
    }

    deletePoll(id: number): Observable<Poll> {
        return undefined;
    }
}