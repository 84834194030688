import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor() {
    if (environment.sentry.enabled) {
      Sentry.init({
        dsn: environment.sentry.key,
        environment: environment.sentry.environment,
        integrations: [new Integrations.Dedupe()]
      });
    }
  }

  handleError(error: Error) {
    if (environment.sentry.enabled) {
      Sentry.captureException(error);
    }
    console.error("Handled Error", error);
  }
}