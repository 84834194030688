import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FrankliEventStatus } from '@app/models/notifications/frankli-event-status.enum';
import { FrankliNotificationType } from '@app/models/notifications/frankli-event-type.enum';
import { FrankliNotification } from '@app/models/notifications/frankli-notification.model';
import { RoleName } from 'app/models/role.model';
import { NotificationAPIService } from 'app/shared/api/events.api.service';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { UserAPIService } from '../api/user.api.service';
import { RxStompState, WebsocketService } from '../api/websocket.service';
import { SidebarService } from '../sidebar/sidebar.service';
import { DateService } from '../utils/date.service';
import { Globals } from './globals';

/**
 * A service to interface with the stomp service and the events api service
 */
@Injectable({ providedIn: 'root' })
export class NotificationService {
  private notifications: Array<FrankliNotification>;
  private notifications$ = new Subject<FrankliNotification>();
  private websocketConnection$: Subscription;

  // Connected
  private connected$: Subject<boolean> = new Subject<boolean>();
  private connected: boolean = false;

  public actionNotification$ = new Subject<FrankliNotification>();
  
  constructor(
    private eventsAPI: NotificationAPIService,
    private websocketService: WebsocketService,
    private dateService: DateService,
    private userAPIService: UserAPIService,
    private globals: Globals,
    private router: Router,
    private sidebarService: SidebarService
  ) {
    this.notifications = new Array<FrankliNotification>();

    /**
    * handles notifications and removes any notifications not meant for the user, this shouldn't happen but just added as
    * a precaution
    */
    this.websocketConnection$ = this.websocketService.getConnection$().subscribe((notification: FrankliNotification) => {
      if (notification.eventStatus === FrankliEventStatus.READ) {
        this.publishLiveNotification(notification);
      } else {
        if (notification.eventType === FrankliNotificationType.USER_REFRESH || notification.eventType === FrankliNotificationType.REFRESH) {
          this.handleUserRefresh(notification);
        } else {
          this.publishLiveNotification(notification);
        }
      }
    });

    /**
     * Set connection status
     */
    this.websocketService.getConnectionState$().subscribe((state: RxStompState) => {
      // console.log('Websocket state: ' + state);
      // Set state to open, get notifications
      if (state === RxStompState.OPEN) {
        this.eventsAPI.getEvents().subscribe(events => {

          // check if refresh needed
          const refresh = events.some(event => {
            return event.eventType === FrankliNotificationType.USER_REFRESH && event.eventStatus === FrankliEventStatus.NEW;
          });
          if (refresh === true) {
            this.userAPIService.userRefresh().subscribe(response => {
              // user privileges refreshed
            }, (failure: HttpErrorResponse) => {
              console.log('Error refreshing')
            });
          }

          // filter user refresh, refresh and read
          events = events.filter(event => event.eventType !== FrankliNotificationType.USER_REFRESH);
          events = events.filter(event => event.eventType !== FrankliNotificationType.REFRESH);
          events = events.filter(event => event.eventStatus !== FrankliEventStatus.READ);
          events = events.filter(event => !event.action);
          events = events.map(event => this.parseNotification(event));

          // clear previous
          this.clear();

          // add all events
          events.forEach(event => {
            this.notifications.push(event);
          });

          // sort notifications by id
          this.notifications.sort((a, b) => { return b.id - a.id });

          // set connected
          this.connected = true;
          this.connected$.next(true);

        }, (failure: HttpErrorResponse) => {
          // TODO: Handle error here
          this.connected = true;
          this.connected$.next(true);
        });
      }

      // Set state to closed, clear notifications
      if (state === RxStompState.CLOSED) {
        this.connected = false;
        this.connected$.next(false);
        this.clear();
      }
    });

  }

  handleUserRefresh(notification: FrankliNotification){
    forkJoin(
      this.userAPIService.getUserInit(),
      this.userAPIService.userRefresh()
    ).subscribe(([init, refresh]) => {
      if (this.globals.user.roles.some(e => e.name === RoleName.ACCESS_REVOKED)) {
        this.router.navigateByUrl('/revoked');
      } else if (this.globals.user.roles.some(e => e.name === RoleName.ARCHIVED)) {
        this.router.navigateByUrl('/archived');
      } {
        this.sidebarService.sendUpdate();
      }

      // user privileges refreshed successfully

    }, (failure: HttpErrorResponse) => {
      console.log('Error refreshing')
    });
  }

  private publishLiveNotification(notification: FrankliNotification) {
    const n = this.parseNotification(notification)
    
    if(notification.action){
      this.actionNotification$.next(notification);
    } else if (notification.eventStatus === FrankliEventStatus.NEW) {
      this.notifications.unshift(n);
      this.notifications$.next(n);
    } else {
      this.notifications = this.notifications.filter(e => e.id !== notification.id);
      this.notifications$.next(n);
    }
  }

  private parseNotification(notification: FrankliNotification): FrankliNotification {
    if (!notification.timestamp) {
      notification.timestamp = new Date();
    } else {
      notification.timestamp = this.dateService.parseUtcToLocal(<Date>notification.timestamp);
    }
    return notification;
  }

  /**
  * Returns a list of the user's current notifications
  */
  public getNotifications(): Array<FrankliNotification> {
    return this.notifications;
  }

  /**
  * An observable that published notifications when they come in over the websocket connection
  */
  public getNotifications$(): Observable<FrankliNotification> {
    return this.notifications$.asObservable();
  }

  /**
  * An observable that published true when the websocket connection has been successfully opened, and false when successfully closed
  */
  public isConnected$(): Observable<boolean> {
    return this.connected$.asObservable();
  }

  /**
  * Returns true if websocket connection is open, otherwise returns false
  */
  public isConnected(): boolean {
    return this.connected;
  }

  /**
   * Removes all stored notifications
   */
  public clear(): void {
    this.notifications.length = 0;
  }

  /**
   * Marks a notifications as read (exlcuding tasks)
   * @param id
   */
  public markAsRead(id: number): void {
    this.eventsAPI.markAsRead(id).subscribe(notification => {
      this.publishLiveNotification(notification);
    }, (failure: HttpErrorResponse) => {
      // nothing
    });
  }

  /**
   * Marks all notifications as read (exlcuding tasks)
   */
  public markAllAsRead(): void {
    this.eventsAPI.markAllAsRead().subscribe(notifications => {
      notifications.forEach(notification => {
        this.publishLiveNotification(notification);
      })
    }, (failure: HttpErrorResponse) => {
      // nothing
    });
  }

}
