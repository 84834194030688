import { GoalKeyResultMeasureUnitPlacement } from "./goal-key-result-measure-unit-placement.model";
import { GoalKeyResultType } from "./goal-key-result-type.model";

export class CreateGoalDraftKeyResultDto {

  constructor(
    public result: string | null,
    public endDate: Date | null,
    public type: GoalKeyResultType | null,
    public measureGoalValue: number | null,
    public measureStartValue: number | null,
    public measureUnit: string | null,
    public measureUnitPlacement: GoalKeyResultMeasureUnitPlacement | null
  ) { }

}