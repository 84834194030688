import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreatePositionDto, Position, UpdatePositionDto } from 'app/models/position/position.model';
import { PositionService } from '@app/shared/api/interfaces/position.service';

@Injectable()
export class PositionAPIService implements PositionService {
  private readonly BASE_URL = 'api/position';
  private readonly HEADERS = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(
    private http: HttpClient
  ) { }

  getAllPositions(): Observable<Array<Position>> {
    const url = `${this.BASE_URL}`;
    return this.http.get<Array<Position>>(url);
  }

  getPositionById(id: number): Observable<Position> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.get<Position>(url);
  }

  createPosition(createPositionDto: CreatePositionDto): Observable<Position> {
    const url = `${this.BASE_URL}`;
    return this.http.post<Position>(url, createPositionDto, {headers: this.HEADERS});
  }

  updatePosition(id: number, updatePositionDto: UpdatePositionDto): Observable<Position> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.put<Position>(url, updatePositionDto, {headers: this.HEADERS});
  }

  deletePosition(id: number): Observable<Position> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.delete<Position>(url, {headers: this.HEADERS});
  }

  importPosition(file: File): Observable<Array<Position>> {
    const url = `${this.BASE_URL}/import`;

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<Array<Position>>(url, formData);
  }
}
