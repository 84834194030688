import { User, UserGender } from 'app/models/user/user.model';

export class UserProfileDetails {
  description: string;
  gender: UserGender;

  constructor(user: User) {
    this.description = user.description;
    this.gender = user.gender;
  }
}
