import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { User } from 'app/models/user/user.model';
import { SettingsAPIService } from 'app/shared/api/settings.api.service';
import { FileAPIService } from 'app/shared/api/file.api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserAPIService } from '../../../api/user.api.service';
import { Globals } from '../../../globals/globals';
import { EditProfileService } from 'app/profile/profile-components/edit/edit-profile.service';
import { ModalComponent } from '@app/shared/modal/modal.component';
import { State } from '@app/shared/utils/state.model';
import { SwalUtils } from '@app/shared/utils/swal.utils';

declare var $: any;
declare var cropper: any;

@Component({
  selector: 'app-employee-upload-profile-picture-component',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.css'],
})
export class ProfilePictureComponent implements OnInit, OnDestroy {
  public readonly ACCEPTED_IMAGE_TYPES = '.jpeg, .png, .jpg';
  public MAX_FILE_SIZE: number = 0;
  public MAX_FILE_SIZE_DESCRIPTION: string = '';
  public readonly stateUpload = new State(false);

  @Input() user !: User;
  @ViewChild('modalCropper') modalCropper!: ModalComponent;
  @ViewChild('imageInput') imageInput!: HTMLInputElement;

  extension: string = '';

  constructor(
    private fileAPIService: FileAPIService,
    private settingsService: SettingsAPIService,
    private userAPIService: UserAPIService,
    private globals: Globals,
    private editProfileService: EditProfileService
  ) {
    this.getFileUploadLimit();
  }

  ngOnInit() { }

  ngOnDestroy() { }

  private getFileUploadLimit() {
    this.settingsService.getMaxFileUploadSize().subscribe(size => {
      this.MAX_FILE_SIZE_DESCRIPTION = size;
      this.MAX_FILE_SIZE = parseInt(this.MAX_FILE_SIZE_DESCRIPTION, 10);
      switch (this.MAX_FILE_SIZE_DESCRIPTION.split(/[0-9]+/)[1].toUpperCase()) {
        case ('KB'):
          this.MAX_FILE_SIZE *= 1024;
          break;
        case ('MB'):
          this.MAX_FILE_SIZE *= 1024 * 1024;
          break;
      }
    });
  }

  public loadCropper($event: any): void {
    // Reset state
    this.stateUpload.setSuccess();

    // Shop cropper
    this.modalCropper.show();

    const files = $event.target.files;
    if (files.length === 0) {
      return;
    }

    const file = files[0];
    this.extension = file.name.split('.').pop().toLowerCase();

    const canvas = document.getElementById('canvasImageCrop') as HTMLCanvasElement;
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = function () {
      const dataURL = reader.result as string;

      // used to get image width
      const img = new Image();
      img.src = dataURL as string;
      img.onload = () => {
        // set correct canvas aspect ratio and size
        const width = img.width;
        const height = img.height;
        const ratio = width / height;
        const canvasHeight = 270;
        canvas.height = canvasHeight;
        canvas.width = (canvasHeight * ratio);
        canvas.style.background = '#ffffff';

        // init cropper
        cropper.start(document.getElementById('canvasImageCrop'), 1);
        cropper.showImage(dataURL);
        cropper.startCropping();
      };
    };

    reader.onerror = function (error) {
      alert('Error loading image');
    };
  }

  public uploadImage(): void {
    if (this.stateUpload.isLoading()) {
      return;
    }

    this.stateUpload.setLoading();

    const component = this;
    const uri = cropper.getCroppedImageSrc();
    const image = new Image();
    image.src = uri;
    image.onload = function () {
      // 400 can be decreased down to 200 provided we don't use this image at a size of larger than 200px
      const compressedURI = component.compress(image, 400);
      // Going to leave this hear just incase we need to compare
      /*
      const blob = component.dataURItoBlob(uri);
      console.log('URI Length:');
      console.log(uri.length);
      console.log('Image size:');
      console.log(blob.size);
      */
      const compressedBlob = component.dataURItoBlob(compressedURI);
      /*
      console.log('Compressed URI Length:');
      console.log(compressedURI.length);
      console.log('Compressed Image size:');
      console.log(compressedBlob.size);
      */
      component.sendFile(compressedBlob);
      component.clearInput();
    };
  }

  public cancelImageUpload(): void {
    this.modalCropper.hide();
    this.clearInput();
  }

  public clearInput(): void {
    // cropper starts on change event of input file
    // if user selects an image, cancels and then selects same image change event wont fire
    // as the file has not technically changed, so we need to clear the input on dismiss
    // most browser compatible answer from https://stackoverflow.com/questions/20549241/how-to-reset-input-type-file
    this.imageInput.value = '';
    if (!/safari/i.test(navigator.userAgent)) {
      this.imageInput.type = '';
      this.imageInput.type = 'file';
    }
  }

  private sendFile(file: Blob): void {

    this.userAPIService.updateProfilePicture(file, this.extension).subscribe(user => {
      this.globals.user = user;
      this.user.imageUrl = user.imageUrl;
      this.cancelImageUpload();
      $.notify('Your profile has been successfully updated');
    }, (_failure: HttpErrorResponse) => {
      this.cancelImageUpload();
    });
  }

  // from here https://stackoverflow.com/questions/12168909/blob-from-dataurl
  private dataURItoBlob(dataURI: string): Blob {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);
    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  }


  // from https://stackoverflow.com/a/37579385 and modified to what we need
  private compress(source_img_obj: any, maxWidth: any): string { // TODO: Type these
    const mime_type = 'image/jpeg';

    let natW = source_img_obj.naturalWidth;
    let natH = source_img_obj.naturalHeight;
    const ratio = natH / natW;
    if (natW > maxWidth) {
      natW = maxWidth;
      natH = ratio * maxWidth;
    }

    const cvs = document.createElement('canvas');
    cvs.width = natW;
    cvs.height = natH;

    const ctx = cvs.getContext('2d')!.drawImage(source_img_obj, 0, 0, natW, natH);
    return cvs.toDataURL(mime_type);
  }

}
