import { Pipe, PipeTransform } from '@angular/core';
import { KeyValue } from '@angular/common';

@Pipe({ name: 'keyValueAlphabeticalPipe' })
export class KeyValueAlphabeticalPipe implements PipeTransform {
  previousValue!: any;
  resultCached!: Array<KeyValue<string, string>>;

  transform(value: any): Array<KeyValue<string, string>> {
    // Skip calculation if value hasn't changed
    if ((value === this.previousValue) && this.resultCached) {
      return this.resultCached;
    }
    this.previousValue = value;

    const strings = Object.keys(value).filter(key => isNaN(+key));
    const stringsSorted = strings.sort((a, b) => a.localeCompare(b));
    const keyValues = stringsSorted.map(s => { 
      return { key: s, value: s }
    })

    this.resultCached = keyValues;
    return keyValues;
  }

}
