import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PaginationComponent } from './pagination.component';
import { PaginationService } from './pagination.service';
import { PaginationNewComponent } from './pagination-new/pagination-new.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [RouterModule, CommonModule, FormsModule],
  declarations: [PaginationComponent, PaginationNewComponent],
  exports: [PaginationComponent, PaginationNewComponent],
  providers: [PaginationService]
})

export class PaginationModule { }
