import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Poll } from 'app/models/poll/poll.model';
import { PollResponse } from 'app/models/poll/poll-response.model';
import { PollService } from '@app/shared/api/interfaces/poll.service';

@Injectable()
export class PollAPIService implements PollService {
  private readonly BASE_URL = 'api/poll';
  private readonly HEADERS = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(
    private http: HttpClient,
  ) {
  }

  getAllPolls(): Observable<Array<Poll>> {
    const url = `${this.BASE_URL}`;
    return this.http.get<Array<Poll>>(url);
  }

  getPollById(id: number): Observable<Poll> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.get<Poll>(url);
  }

  getPollResponses(id: number): Observable<PollResponse[]> {
    const url = `${this.BASE_URL}/responses/${id}`;
    return this.http.get<PollResponse[]>(url);
  }

  createPoll(createPollDto: Poll): Observable<Poll> {
    const url = `${this.BASE_URL}`;
    return this.http.post<Poll>(url, createPollDto, {headers: this.HEADERS});
  }

  updatePoll(id: number, updatePollDto: Poll): Observable<Poll>{
    const url = `${this.BASE_URL}/${id}`
    return this.http.put<Poll>(url, updatePollDto, {headers: this.HEADERS});
  }

  createPollResponse(createPollResponse: PollResponse): Observable<PollResponse> {
    const url = `${this.BASE_URL}/respond/${createPollResponse.pollId}`;
    return this.http.post<PollResponse>(url, createPollResponse, {headers: this.HEADERS});
  }

  archivePoll(id: number, updatePollDto: Poll): Observable<Poll> {
    const url = `${this.BASE_URL}/${id}/archive`;
    return this.http.put<Poll>(url, updatePollDto, {headers: this.HEADERS});
  }

  deletePoll(id: number): Observable<Poll> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.delete<Poll>(url, {headers: this.HEADERS});
  }

  getAssignedPolls(): Observable<Array<Poll>> {
    const url = `${this.BASE_URL}/assigned`;
    return this.http.get<Array<Poll>>(url);
  }

  getAllPollsPublishedToUser(): Observable<Array<Poll>> {
    const url = `${this.BASE_URL}/published`;
    return this.http.get<Array<Poll>>(url);
  }

}
