import { MockService } from '@app/mock/api/mock-service';
import { CalendarService } from '@app/shared/api/interfaces/calendar.service';
import { Schedule } from '@app/models/one-to-one/schedule.model';
import { HttpRequest } from '@angular/common/http';
import { sanitizeUrl } from '@app/shared/utils/helpers';

export class CalendarMockService implements MockService, CalendarService {
    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);
        switch (true) {
            case url.endsWith('api/calendar/availability'):
                return this.getAvailability('', '', '', '');
            case url.endsWith('api/calendar/connected'):
                return this.isConnected();
        }
    }

    getAvailability(email: string, startDateTime: String, endDateTime: String, timezone: String): Schedule[] {
        return [];
    }

    isConnected(): boolean {
        return false;
    }

}