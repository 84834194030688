import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { forkJoin } from 'rxjs';
import { CompanyFeatures } from 'app/models/company.model';
import { GoalsAPIService } from 'app/shared/api/goals.api.service';
import { Goal, GoalHelperFunctions, GoalStatus, GoalType } from 'app/models/goals/goal.model';
import { Globals } from 'app/shared/globals/globals';
import { GoalTag } from 'app/models/goals/goal-tag.model';
import * as moment from 'moment-timezone';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleName } from 'app/models/role.model';
import { CompanyWording } from '@app/models/company/company-wording/company-wording.model';


enum SortType { NAME, ACTIVE, COMPLETE, COMPLETION, GOALS, OBJECTIVES };
enum SortDirection { ASCENDING, DESCENDING };
interface Sort { type: SortType, direction: SortDirection };
interface State { loading: boolean, error: boolean };

interface TagWithGoals {
  tag: GoalTag,
  submenuOpened: Boolean,
  tagGoals: Array<Goal>
  goalCount: number;
}

@Component({
  selector: 'app-goal-manager-tags',
  templateUrl: './goal-manager-tags.component.html',
  styleUrls: ['./goal-manager-tags.component.css']
})
export class GoalManagerTagsComponent implements OnInit, AfterViewChecked {
  @Input() page: number;
  @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();

  eFeature = CompanyFeatures;
  sort: Sort = {
    type: SortType.NAME,
    direction: SortDirection.DESCENDING
  };

  eSortType = SortType;
  eSortDirection = SortDirection;
  eGoalType = GoalType;
  eGoalStatus = GoalStatus;

  state: State = {
    loading: true,
    error: false
  };

  amountToDisplay: any = 10;

  searchValue: string;

  tags: Array<TagWithGoals> = new Array<TagWithGoals>();
  tagsFiltered: Array<TagWithGoals> = new Array<TagWithGoals>();
  tagsDisplay: Array<TagWithGoals> = new Array<TagWithGoals>();

  maxGoalAmount: number;

  eGoalHelperFunctions = GoalHelperFunctions;
  companyWording: CompanyWording;
  constructor(
    private goalsAPIService: GoalsAPIService,
    private cdRef: ChangeDetectorRef,
    private globals: Globals,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.companyWording = this.globals.company.companyWording;
    this.tags = [];
    this.tagsFiltered = [];
    this.searchValue = '';
  }

  ngOnInit() {
    let init;

    if (this.globals.hasRole(RoleName.GOAL_COMPANY)) {
      init = forkJoin([
        this.goalsAPIService.getAdminGoals(),
        this.goalsAPIService.getTags()
      ]);
    } else {
      init = forkJoin([
        this.goalsAPIService.getGoalsPublicAll(),
        this.goalsAPIService.getTags()
      ]);
    }


    this.maxGoalAmount = 30;
    init.subscribe(([goals, tags]) => {

      let counter = 0;
      tags.forEach(t => {
        const x = {
          tag: t,
          submenuOpened: false,
          tagGoals: Goal.getGoalArrayCompletionPercentage(goals.filter(g => g.tags.some(tag => tag.text === t.text))),
          goalCount: 0
        };
        x.tagGoals = Goal.sort.byEndDate(x.tagGoals, 'ascending');

        // ensures the graph scales well
        if (x.tagGoals.length > this.maxGoalAmount) {
          this.maxGoalAmount = x.tagGoals.length;
        }

        this.tags.push(x);
        this.tagsFiltered.push(x);
        counter++;
      })
      this.search();
      this.state.loading = false;
    }, error => {
      this.state.error = true;
      this.state.loading = false;
    });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  paginationChanged(e: any) {
    this.pageChanged.emit(e);
  }

  update(): void {
    this.tagsFiltered = this.filter();
    if (this.sort.type === SortType.NAME) {
      this.sort.type = SortType.NAME
      this.sort.direction = SortDirection.ASCENDING
    }
  }

  //////////////////////////
  // FILTER
  //////////////////////////

  search(): void {
    // fixes a timing issue with ngModelChange firing before the model updates
    setTimeout(() => {
      this.update();
    })
  }

  filter(): Array<TagWithGoals> {
    let outputTags = this.tags;
    outputTags = this.filterByName(outputTags, this.searchValue);
    outputTags.forEach(t => {
      t.submenuOpened = false;
    })
    return outputTags;
  }

  filterByName(data: Array<TagWithGoals>, name: string): Array<TagWithGoals> {
    name = name.trim().toLowerCase();

    if (name === '') {
      return data;
    } else {
      return data.filter(d => {
        const fullName = (d.tag.text).toLowerCase();
        return (fullName.includes(name));
      });
    }
  }

  //////////////////////////
  // SORT
  //////////////////////////

  reset(): void {
    this.searchValue = '';
    this.amountToDisplay = 10;
    this.sort.type = SortType.NAME;
    this.sort.direction = SortDirection.DESCENDING;
    this.tagsFiltered = this.filter();
    this.sortByName();
    this.search();
  }

  sortByName(): void {
    this.tagsFiltered = this.filter();

    if (this.sort.type === SortType.NAME && this.sort.direction === SortDirection.ASCENDING) {
      this.sort.direction = SortDirection.DESCENDING;
      this.tagsFiltered.reverse();
    } else {
      this.sort.type = SortType.NAME;
      this.sort.direction = SortDirection.ASCENDING;
      // this.tagsFiltered = User.sort.byFullName(this.tagsFiltered);
    }

  }


  toggleSubMenu(index: number) {
    this.tagsFiltered[index].submenuOpened = !this.tagsFiltered[index].submenuOpened;
  }

  getDaysRemaining(endDate: string) {
    const end = new Date(endDate);

    const a = moment(end);
    const b = moment(new Date());

    const difference = a.diff(b, 'days');
    if (difference === 0) {
      return 'Due today';
    } else if (difference === 1) {
      return '1 day remaining';
    } else if (difference > 1) {
      return difference + ' days remaining';
    } else if (difference === -1) {
      return '1 day overdue';
    } else if (difference < -1) {
      return (-1 * difference) + ' days overdue';
    }

    return '';
  }

  getTitleGoalStatus(goal: Goal) {
    if (goal.complete === true) {
      return 'Complete';
    } else {
      return goal.status.toString().replace('_', ' ');
    }
  }

  getDaysRemainingTotal(endDate: string) {
    const end = new Date(endDate);

    const a = moment(end);
    const b = moment(new Date());

    return a.diff(b, 'days');
  }

  navigateToGoal(goal: Goal) {
    this.router.navigate(['individual/' + goal.id], { relativeTo: this.route });
  }
}
