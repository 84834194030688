import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IdeaItem } from 'app/models/feedback/idea.model';
import { IdeaService } from '@app/shared/api/interfaces/idea.service';

@Injectable()
export class IdeaAPIService implements IdeaService {
  baseURL = 'api/idea/';
  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(private http: HttpClient) { }

  submitIdea(ideaItem: IdeaItem) {
    const url = this.baseURL + 'submit/';
    return this.http.post<IdeaItem>(url, ideaItem, {headers: this.headers});
  }

  getIdeasSubmitted(): Observable<Array<IdeaItem>> {
    const url = this.baseURL + 'submitted';
    return this.http.get<Array<IdeaItem>>(url);
  }

  getIdeaSubmittedById(id: number): Observable<IdeaItem> {
    const url = this.baseURL + 'submitted/' + id;
    return this.http.get<IdeaItem>(url);
  }

  getIdeaSubmittedByUserId(id: number): Observable<Array<IdeaItem>> {
    const url = this.baseURL + 'submitted/user/' + id;
    return this.http.get<Array<IdeaItem>>(url);
  }

  getIdeasByManagerMe(): Observable<Array<IdeaItem>> {
    const url = this.baseURL + 'submitted/manager/me';
    return this.http.get<Array<IdeaItem>>(url);
  }

  getIdeaByManagerAndId(id: number): Observable<IdeaItem> {
    const url = this.baseURL + 'submitted/manager/id/' + id;
    return this.http.get<IdeaItem>(url);
  }

}
