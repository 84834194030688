import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PraiseAPIService } from '@app/shared/api/praise.api.service';
import { PraiseItem } from '@app/models/feedback/praise.model';
import { forkJoin } from 'rxjs';
import { UserAPIService } from '@app/shared/api/user.api.service';
import { Breadcrumb } from '@app/models/breadcrumb.model';
import { BreadcrumbService } from '@app/shared/breadcrumbs/breadcrumbs.service';
import { ActivityReaction, ReactionType } from '@app/models/activity-reaction.model';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Globals } from '@app/shared/globals/globals';
import { User } from '@app/models/user/user.model';
import { ModalComponent } from '@app/shared/modal/modal.component';

declare var $: any;

enum ModalTab {
  ALL = 'ALL',
  LIKE = 'LIKE',
  CELEBRATE = 'CELEBRATE',
  LOVE = 'LOVE'
}

interface ReactionUser {
  reaction: ActivityReaction;
  user: User;
}

@Component({
  selector: 'app-profile-praise-full',
  templateUrl: './profile-praise-full.component.html',
  styleUrls: ['./profile-praise-full.component.css']
})
export class ProfilePraiseFullComponent implements OnInit, OnDestroy {
  @ViewChild('reactionUserModal') reactionUserModal: ModalComponent;
  @ViewChildren('pop') popovers: QueryList<PopoverDirective>;
  praiseList: PraiseItem[];
  modalTab: ModalTab;
  eModalTab = ModalTab;
  eReactionType = ReactionType;
  breadcrumb: Breadcrumb
  loading: boolean;
  error: boolean;
  mouseInPop = false;
  reactionUsers: Array<ReactionUser>;
  reactionUsersDisplay: Array<ReactionUser>;

  constructor(
    private route: ActivatedRoute,
    private globals: Globals,
    private praiseAPIService: PraiseAPIService,
    private userAPIService: UserAPIService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.loading = true;
    this.error = false;
    this.reactionUsers = [];
    this.reactionUsersDisplay = [];
    this.praiseList = [];
    this.breadcrumb = this.breadcrumbService.init(this.route);
    this.modalTab = ModalTab.ALL;
  }

  ngOnInit() {
    const id = this.route.parent!.snapshot.params['id'];
    if (id) {
      this.getData(id)
    }
  }

  ngOnDestroy() {
    this.breadcrumbService.remove(this.breadcrumb);
  }

  getData(id: number) {
    forkJoin([
      this.praiseAPIService.getPraiseReceivedByUserId(id),
      this.userAPIService.getAllUsers()
    ]).subscribe(([praise, users]) => {
      praise = praise.map(p => {
        p.praiseUser = users.find(u => u.id === p.praiseUserId);
        p.sourceUser = users.find(u => u.id === p.sourceUserId);

        if (p.sourceUser) {
          p.sourceUserImageUrl = p.sourceUser.imageUrl;
        }

        return p;
      })
      this.praiseList = praise;
    }, () => {
      this.error = true;
    }, () => {
      this.loading = false;
    })
  }

  toggleReaction(praise: PraiseItem, type: ReactionType) {
    this.praiseAPIService.toggleReactionForPraiseByIdAndReactionType(praise.id, type).subscribe(praise => {
      const idx = this.praiseList.findIndex(p => p.id === praise.id);
      this.praiseList[idx].reactions = praise.reactions;
      if (this.getUniqueReactions(praise.reactions).has(type)) {
        $.notify(`Successfully ${type.toLowerCase()}d ${praise.praiseUserFirstName}'s praise!`);
      }
      setTimeout(() => this.popovers.forEach(popover => popover.hide()), 200);
    });
  }

  userHasReacted(praise: PraiseItem, type?: ReactionType): boolean {
    return !!praise.reactions.find(r => {
      if (r.userId === this.globals.user.id) {
        if (type) {
          return r.type === type;
        } else {
          return true;
        }
      }
    });
  }

  getUniqueReactions(reactions: Array<ActivityReaction>): Set<ReactionType> {
    return new Set(reactions.map(r => r.type));
  }

  loadReactionUserModal(reactions: Array<ActivityReaction>) {
    const newReactionUsers = new Array<ReactionUser>();
    this.reactionUsers = newReactionUsers;
    reactions.forEach(reaction =>
        this.userAPIService.getById(reaction.userId).subscribe(user => {
          newReactionUsers.push({
            user,
            reaction
          });
        })
    );
    this.reactionUsersDisplay = this.reactionUsers;
    this.modalTab = ModalTab.ALL;
    this.reactionUserModal.show();
  }

  changeTab(tab: ModalTab) {
    if (tab !== ModalTab.ALL) {
      const tabAsType = ReactionType[tab];
      this.reactionUsersDisplay = this.getReactionUsersForType(tabAsType);
    } else {
      this.reactionUsersDisplay = this.reactionUsers;
    }
    this.modalTab = tab;
  }

  getReactionUsersForType(type: ReactionType): Array<ReactionUser> {
    return this.reactionUsers.filter(ru => ru.reaction.type === type);
  }

  // To prevent duplicate popovers, we need this method to hide popovers that are no longer referenced
  hideOtherPopovers(event: PopoverDirective, praise: PraiseItem) {
    const currentPop = this.popovers.find(p => p.popoverContext.praise === praise);
    if (currentPop) {
      const otherPopsShown = this.popovers.filter(p => p.isOpen && p !== currentPop);
      if (otherPopsShown.length > 0) {
        otherPopsShown.forEach(p => p.hide());
      }
    }
    this.checkMousePosition();
  }

  // If mouse not over element, hide pop
  checkMousePosition() {
    setTimeout(() => {
      if (!this.mouseInPop) {
        this.popovers.forEach(popover => popover.hide());
      } else {
        this.checkMousePosition();
      }
    }, 4000);
  }
}
