import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'underscoreToSpace',
})

export class UnderscoreToSpacePipe implements PipeTransform {
  previousValue!: string;
  resultCached!: string;

  transform(value: string) {
    // Skip calculation if value hasn't changed
    if ((value === this.previousValue) && this.resultCached) {
      return this.resultCached;
    }
    this.previousValue = value;

    if (value) {
      value = value.toString().replace(/_/g, ' ');
    } else {
      value = '';
    }

    this.resultCached = value;
    return value;
  }
}
