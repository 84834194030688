<router-outlet></router-outlet>
<ng-container *ngIf="route.children.length === 0">

  <!-- Title and tab select -->
  <div class="row m-bottom-10">
    <div class="col-sm-6">
      <span class="h4 title" *ngIf="pageSelect.value !== 'Team Goals' && pageSelect.value !== 'Department Goals'">{{pageSelect.value}}</span>
      <span class="h4 title" *ngIf="pageSelect.value === 'Departmen Goals'">{{companyWording.department | titlecase}} Goals </span>
      <span class="h4 title" *ngIf="pageSelect.value === 'Team Goals'">{{companyWording.team | titlecase}} Goals</span>
      <!-- <span class="main-title">{{pageSelect.value}}</span> -->
      <!-- <span class="dropdown type-dropdown">
        <button id="dropdownMenuButton">
          <span class="fal fa-ellipsis-h"></span>
        </button>
        <div class="dropdown-menu">
          <div class="dropdown-item" *ngFor="let ptype of typeOptions" (click)="setType(ptype)">{{ptype}}</div>
        </div>
      </span> -->
    </div>
    <div class="col-sm-6 text-right">
      <button class="btn-frankli-round btn-frankli-green" (click)="openGoalCreate()" angulartics2On="click"
        angularticsAction="ShowGoalsCreateModal" angularticsCategory="Goals"
        [attr.data-intercom-target]="'Create A Goal'">Create a Goal</button>
    </div>
  </div>

  <ng-container *ngIf="!state.loading; else stateLoading">
    <ng-container *ngIf="!state.error; else stateError">
      <ng-container [ngSwitch]="pageSelect.value">
        <!-- Other pages -->
        <ng-container *ngIf="(pageSelect.value !== eOverviewType.DRAFTS) && (pageSelect.value !== eOverviewType.TAGS)">
          <div class="card">
            <div class="p-left-10 p-right-10 p-top-10">
              <!-- Goal Stats -->
              <app-goal-stats [goals]="goals"></app-goal-stats>
            </div>
          </div>
        </ng-container>

        <div class="card">
          <!-- Navigation tabs -->
          <ul id="nav-tabs" class="nav nav-justified collapse open-on-md noselect">
            <li *ngFor="let ptype of typeOptions; first as isFirst; last as isLast;" (click)="setType(ptype.key)" [tooltip]="ptype.description" placement="top">
              <a [class.text-info]="pageSelect.value === ptype.key" class="nav-link">{{ptype.name}}</a>
            </li>
          </ul>

          <!-- Goal admin page -->
          <app-goal-manager-admin *ngSwitchCase="eOverviewType.ADMIN_GOAL" [page]="paginationPage" (pageChanged)="paginationChanged($event)" (goalsEmit)="adminGoalsEmit($event)"></app-goal-manager-admin>

          <!-- Drafts page -->
          <app-goals-overview-drafts-component *ngSwitchCase="eOverviewType.DRAFTS" [goals]="goalsSaved" [page]="paginationPage" (pageChanged)="paginationChanged($event)"></app-goals-overview-drafts-component>

          <!-- Tags page -->
          <app-goal-manager-tags *ngSwitchCase="eOverviewType.TAGS" [page]="paginationPage" (pageChanged)="paginationChanged($event)"></app-goal-manager-tags>

          <!-- Departments page -->
          <!-- <app-goals-overview-departments *ngSwitchCase="eOverviewType.DEPARTMENT" [page]="paginationPage" (pageChanged)="paginationChanged($event)"></app-goals-overview-departments> -->

          <ng-container *ngSwitchDefault>
            <!-- Filters - TODO: Move inside goals-all-table and replace with universal filter -->
            <div class="p-left-15 p-right-15 p-top-10 p-bottom-10">
              <!-- Department -->
              <div class="frankli-form-group frankli-form-group-filled filter-item" *ngIf="pageSelect.value === eOverviewType.DEPARTMENT">
                <label for="department">{{companyWording.department | titlecase}}</label>
                <select id="department" class="form-control" [(ngModel)]="departmentId"
                  (ngModelChange)="changeDepartment()">
                  <option [ngValue]="overview.department.id" *ngFor="let overview of departmentOverViewList">
                    {{overview.department.name}} ({{overview.goals.length}})
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </option>
                </select>
              </div>

              <!-- Office Locations -->
              <div class="frankli-form-group frankli-form-group-filled filter-item" *ngIf="pageSelect.value === eOverviewType.OFFICE_LOCATION && officeLocationOverViewList.length > 0">
                <label for="officeLocation">Site</label>
                <select id="officeLocation" class="form-control" [(ngModel)]="officeLocationId" (ngModelChange)="changeOfficeLocation()">
                  <option [ngValue]="overview.officeLocation.id" *ngFor="let overview of officeLocationOverViewList">
                    {{overview.officeLocation.name}} ({{overview.goals.length}})
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </option>
                </select>
              </div>

              <!-- Teams -->
              <div class="frankli-form-group frankli-form-group-filled filter-item filter-item-manager" *ngIf="pageSelect.value === eOverviewType.TEAM">
                <label for="filter">Manager / Person</label>
                <app-user-picker [userPool]="managerList" [formControl]="managerFilter"></app-user-picker>
              </div>

              <!-- Goal Status -->
              <div class="frankli-form-group frankli-form-group-filled filter-item" *ngIf="(pageSelect.value !== eOverviewType.DEPARTMENT || (pageSelect.value === eOverviewType.DEPARTMENT && departmentId !== null) || (pageSelect.value === eOverviewType.OFFICE_LOCATION && officeLocationId !== null)) && goals.length > 0">
                <label for="filter">Filter by</label>
                <select id="filter" class="form-control" [formControl]="typeFilter">
                  <option [ngValue]="eOverviewFilter.ACTIVE">Active ({{goalsActive.length}})</option>
                  <option [ngValue]="eOverviewFilter.COMPLETE">Complete ({{goalsComplete.length}})</option>
                  <option [ngValue]="eOverviewFilter.ARCHIVED">Archived ({{goalsArchived.length}})</option>
                  <option [ngValue]="eOverviewFilter.ALL">All ({{goals.length}})</option>
                </select>
              </div>
            </div>

            <!-- Table -->
            <ng-container *ngIf="((goals.length > 0)||(goalsActive.length > 0)||(goalsComplete.length > 0)||(goalsArchived.length > 0)); else stateEmpty">
              <app-goals-all-table-component [type]="pageSelect.value" [goals]="goalsViewing" [page]="paginationPage" (pageChanged)="paginationChanged($event)" (openCreateModal)="openGoalCreate(pageSelect.value)"></app-goals-all-table-component>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<!-- #region - ALTERNATE STATES -->
<!-- LOADING -->
<ng-template #stateLoading>
  <div class="m-top-15 m-bottom-15 text-center">
    <span class="fal fa-spin fa-spinner fa-3x fa-fw"></span>
  </div>
</ng-template>

<!-- ERROR -->
<ng-template #stateError>
  <div class="m-top-15 m-bottom-15 text-center">
    <p>
      <img class="icon-padding new-icon-height" src="/assets/img/Error.svg" />
      <br>
      An error occurred while getting your goals</p>
    <span class="btn-frankli-round btn-frankli-green" [routerLink]="'/dashboard'">
      Back to your dashboard?
    </span>
  </div>
</ng-template>

<!-- EMPTY -->
<ng-template #stateEmpty>
  <!-- No goals my goals tab -->
  <div class="text-center m-left-20 m-right-20 p-bottom-20" *ngIf="pageSelect.value === eOverviewType.MY_GOALS">
    <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
    <br>
    <p>Looks like you are not an owner of any goals. Why not get started now?</p>
    <button class="btn-frankli-round btn-frankli-green" (click)="openGoalCreate(pageSelect.value)"
      angulartics2On="click" angularticsAction="ShowGoalsCreateModal"
      [attr.data-intercom-target]="'Create A Goal Empty'" angularticsCategory="Goals">Create a
      Goal
    </button>
  </div>

  <!-- No goals personal tab -->
  <div class="text-center m-left-20 m-right-20 p-bottom-20" *ngIf="pageSelect.value === eOverviewType.PERSONAL">
    <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
    <br>
    <p>All is quiet with your personal goals. Why not get started now?</p>
    <button class="btn-frankli-round btn-frankli-green" (click)="openGoalCreate(pageSelect.value)"
      angulartics2On="click" angularticsAction="ShowGoalsCreateModal"
      [attr.data-intercom-target]="'Create A Goal Personal'" angularticsCategory="Goals">Create a
      Goal</button>
  </div>

  <!-- No goals personal tab -->
  <div class="text-center m-left-20 m-right-20 p-bottom-20" *ngIf="pageSelect.value === eOverviewType.TEAM">
    <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
    <br>
    <p>Looks like you have no {{companyWording.team | lowercase}} goals set yet. Why not create one?</p>
    <button class="btn-frankli-round btn-frankli-green" (click)="openGoalCreate(pageSelect.value)"
      angulartics2On="click" angularticsAction="ShowGoalsCreateModal"
      [attr.data-intercom-target]="'Create A Goal Team'" angularticsCategory="Goals">Create a
      Goal</button>
  </div>

  <!-- No site goals -->
  <div class="text-center m-left-20 m-right-20 p-bottom-20"
    *ngIf="pageSelect.value === eOverviewType.OFFICE_LOCATION && goals.length === 0 && officeLocationOverViewList.length > 0">
    <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
    <br>
    <p>Looks like this site has no goals set yet. To view another site's goals, choose from the
      available dropdown or create one for it.</p>
    <button class="btn-frankli-round btn-frankli-green" (click)="openGoalCreate(pageSelect.value)"
      [attr.data-intercom-target]="'Create A Goal Dept'" angulartics2On="click" angularticsAction="ShowGoalsCreateModal"
      angularticsCategory="Goals">Create a
      Goal</button>
  </div>

  <div class="text-center m-left-20 m-right-20 p-bottom-20"
       *ngIf="pageSelect.value === eOverviewType.OFFICE_LOCATION && goals.length === 0 && officeLocationOverViewList.length === 0">
    <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
    <br>
    <p>Looks like your company has not set up any sites yet.</p>
  </div>

  <!-- No department goals -->
  <div class="text-center m-left-20 m-right-20 p-bottom-20"
    *ngIf="pageSelect.value === eOverviewType.DEPARTMENT && goals.length === 0">
    <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
    <br>
    <p>Looks like this {{companyWording.department | titlecase}} has no goals set yet. To view another {{companyWording.department | titlecase}}'s goals, choose from the
      available dropdown or create one for it.</p>
    <button class="btn-frankli-round btn-frankli-green" (click)="openGoalCreate(pageSelect.value)"
      [attr.data-intercom-target]="'Create A Goal Site'" angulartics2On="click" angularticsAction="ShowGoalsCreateModal"
      angularticsCategory="Goals">Create a
      Goal</button>
  </div>

  <!-- No goals company tab no permission to create -->
  <div class="text-center m-left-20 m-right-20 p-bottom-20"
    *ngIf="pageSelect.value === eOverviewType.COMPANY && !globals.hasRole(eRoleName.GOAL_COMPANY)">
    <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
    <br>
    <p>There are currently no company goals set for you to view.</p>
  </div>

  <!-- No goals company tab w/ permission to create -->
  <div class="text-center m-left-20 m-right-20 p-bottom-20"
    *ngIf="pageSelect.value === eOverviewType.COMPANY && globals.hasRole(eRoleName.GOAL_COMPANY)">
    <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
    <br>
    <p>Looks like your company has no goals set yet. Why not create one?</p>
    <button class="btn-frankli-round btn-frankli-green" (click)="openGoalCreate(pageSelect.value)"
      [attr.data-intercom-target]="'Create A Goal Company'" angulartics2On="click"
      angularticsAction="ShowGoalsCreateModal" angularticsCategory="Goals">Create a
      Goal</button>
  </div>
</ng-template>
<!-- #endregion -->

<!-- Create modal -->
<app-goal-modal #modalCreate>
  <div class="app-goal-modal-header">
    <app-goals-modal-header [modal]="modalCreate" [headerText]="'Create a new goal'"></app-goals-modal-header>
  </div>
  <div class="app-goal-modal-body" *ngIf="modalCreateShown">
    <app-goals-create [modal]="modalCreate" [mode]="'CREATE'" [startType]="createType"></app-goals-create>
  </div>
  <div class="app-goal-modal-sidebar">
    <app-goals-modal-sidebar [modal]="modalCreate"></app-goals-modal-sidebar>
  </div>
</app-goal-modal>