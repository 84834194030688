import { PollResponse } from '@app/models/poll/poll-response.model';
import { mockPolls } from '@app/mock/api/data/mockPolls';
import * as moment from 'moment';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { mockPollQuestions } from '@app/mock/api/data/mockPollQuestions';

export const mockPollResponses: PollResponse[] = [
  {
    id: 1,
    pollId: mockPolls[0].id,
    pollQuestionAnswers: [
      {
        questionId: mockPollQuestions[0].id,
        textAnswer: '3'
      },
      {
        questionId: mockPollQuestions[1].id,
        textAnswer: '2'
      },
      {
        questionId: mockPollQuestions[2].id,
        textAnswer: '4'
      }
    ],
    startedDateTime: moment().subtract(2, 'days').toDate(),
    startedDateTimeSeconds: moment().subtract(2, 'days').toDate().getTime(),
    completedDateTime: moment().subtract(1, 'day').toDate(),
    officeLocation: mockUsers[1].officeLocation
  },
  {
    id: 2,
    pollId: mockPolls[0].id,
    pollQuestionAnswers: [
      {
        questionId: mockPollQuestions[0].id,
        textAnswer: '4'
      },
      {
        questionId: mockPollQuestions[1].id,
        textAnswer: '4'
      },
      {
        questionId: mockPollQuestions[2].id,
        textAnswer: '4'
      }
    ],
    startedDateTime: moment().subtract(2, 'days').toDate(),
    startedDateTimeSeconds: moment().subtract(2, 'days').toDate().getTime(),
    completedDateTime: moment().subtract(1, 'day').toDate(),
    officeLocation: mockUsers[2].officeLocation
  },
  {
    id: 3,
    pollId: mockPolls[0].id,
    pollQuestionAnswers: [
      {
        questionId: mockPollQuestions[0].id,
        textAnswer: '3'
      },
      {
        questionId: mockPollQuestions[1].id,
        textAnswer: '4'
      },
      {
        questionId: mockPollQuestions[2].id,
        textAnswer: '1'
      }
    ],
    startedDateTime: moment().subtract(2, 'days').toDate(),
    startedDateTimeSeconds: moment().subtract(2, 'days').toDate().getTime(),
    completedDateTime: moment().subtract(1, 'day').toDate(),
    officeLocation: mockUsers[3].officeLocation
  }
];