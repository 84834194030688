import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Goal } from '@app/models/goals/goal.model';
import * as moment from 'moment';

interface StatsInternal {
  dueIn30DaysCount: number;
  percentComplete: number;
  completed3Months: number;
}

@Component({
  selector: 'app-goal-stats',
  templateUrl: './goals-stats.component.html',
  styleUrls: ['./goals-stats.component.css']
})
export class GoalStatsComponent implements OnInit, OnChanges {
  @Input() goals: Goal[];
  @Input() calculated: ('INTERNAL' | 'EXTERNAL');
  @Input() statsInput: StatsInternal;
  @Input() progInclComplete: boolean;

  goalsActive: Goal[];
  goalsComplete: Goal[];
  goalsArchived: Goal[];

  stats: StatsInternal;

  roundProgress: {
    left: string;
    right: string;
  }

  refreshFunction = () => {};

  constructor() {
    this.progInclComplete = false;

    this.statsInput = {
      dueIn30DaysCount: 0,
      percentComplete: 0,
      completed3Months: 0
    }

    this.goals = [];
    this.goalsActive = [];
    this.goalsComplete = [];
    this.goalsArchived = [];

    this.stats = {
      dueIn30DaysCount: 0,
      percentComplete: 0,
      completed3Months: 0
    }
    this.roundProgress = {
      left: 'rotate(0)',
      right: 'rotate(0)'
    }
    this.calculated = 'INTERNAL';
  }

  ngOnInit() {
    if (this.calculated === 'EXTERNAL') {
      this.refreshFunction = this.getStatsFromInput;
    } else {
      this.refreshFunction = this.splitAndGetStats;
    }

    this.refreshFunction();
  }

  ngOnChanges() {
    this.refreshFunction();
  }

  getStatsFromInput() {
    this.stats = this.statsInput;
    this.getRoundProgress(this.stats.percentComplete);
  }

  splitAndGetStats() {
    this.splitGoals()
    this.getAllStatsFromGoals();
  }

  splitGoals() {
    this.goalsActive = this.goals.filter(g => !g.complete && !g.archived);
    this.goalsComplete = this.goals.filter(g => g.complete && !g.archived)
    this.goalsArchived = this.goals.filter(g => g.archived);
  }

  getAllStatsFromGoals() {
    this.stats = {
      percentComplete: this.getPercentComplete(),
      dueIn30DaysCount: this.getDue30Days(),
      completed3Months: this.getCompleted3Months()
    }
    this.getRoundProgress(this.stats.percentComplete);
  }

  getPercentComplete(): number {
    let goalsIn = this.goalsActive;
    if (this.progInclComplete) {
      goalsIn = [...goalsIn, ...this.goalsComplete]
    }

    if (goalsIn.length > 0) {
      // console.info(goalsIn)
      const percentages = goalsIn.map(g => g.completionPercentage);
      const total = percentages.reduce((a, b) => (a + b), 0);
      const average = (total / percentages.length);
      this.getRoundProgress(average);
      if (isNaN(average)) {
        console.error('YOU FORGOT TO GET THE COMPLETION PERCENTAGE OF THE GOALS')
      }
      return Math.round(average);
    }

    return 0;
  }

  getDue30Days() {
    const today = moment().startOf('day');
    const dueSoon = this.goalsActive.filter(g => (moment(g.endDate).startOf('day').diff(today, 'days') <= 31));
    return dueSoon.length;
  }

  getCompleted3Months() {
    const today = moment().startOf('day');
    const completedRecently = this.goalsComplete.filter(g => (moment(g.completionDate).endOf('day').diff(today, 'months') <= 3))
    return completedRecently.length;
  }

  getRoundProgress(progress: number) {
    let left = 0;
    let right = 0;
    if (progress > 0) {
      if (progress <= 50) {
        right = (progress / 100 * 360)
      } else {
        right = 180;
        left = ((progress - 50) / 100 * 360)
      }
    }
    this.roundProgress = {
      left: `rotate(${left}deg)`,
      right: `rotate(${right}deg)`
    };
  }
}
