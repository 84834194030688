import { FrankliActivityServerSide } from './frankli-activity-server-side.model';
import * as moment from 'moment';

export class FrankliActivity {
  public userId: number;
  public companyId: number;
  public frankliActivityType: string;
  public activityForeignId: number | null;
  public timestamp: Date;

  constructor(frankliActivityServerSide: FrankliActivityServerSide) {
    this.userId = frankliActivityServerSide.userId;
    this.companyId = frankliActivityServerSide.companyId;
    this.frankliActivityType = frankliActivityServerSide.frankliActivityType;
    this.activityForeignId = frankliActivityServerSide.activityForeignId;
    this.timestamp = moment.utc(frankliActivityServerSide.timestamp).toDate();
  }

}
