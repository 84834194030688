<div [formGroup]="form" class="frankli-form-group" [class.frankli-form-group-filled]="!nonFilledQuestions.includes(question.type) || question.value" #formQuestion>
  <!-- Question -->
  <h5>
    <b>{{question.displayQuestionNumber ? question.key + '. ' : ''}}{{question.label}} {{question.required ? '' : '(Optional)'}}</b>
  </h5>

  <div [ngSwitch]="question.type" [class.frankli-form-group-invalid]="form.controls[question.key].invalid && isSubmitted">
    <ng-container *ngSwitchCase="eSurveyQuestionType.TEXT_AREA">
        <ng-container *ngIf="form.enabled">
            <textarea [formControlName]="question.key" [id]="question.key"  class="form-control noresize"></textarea>
            <div class="f-right character-count d-inline-block" [class.text-danger]="form.controls[question.key].value.length > question.maxCharacters">
              {{form.controls[question.key].value.length}}/{{question.maxCharacters}}
            </div>
        </ng-container>

        <ng-container *ngIf="form.disabled">
            <p>{{form.controls[question.key].value}}</p>
        </ng-container>
    </ng-container>
  </div>
  <div class="frankli-form-group-alert">
    <span class="text-danger" *ngIf="form.controls[question.key].errors && isSubmitted">{{errorMessage}}</span>
  </div>
</div>
