import { Role, RoleName } from '@app/models/role.model';

// We only need the roles we want in the sandbox
export const mockRoles: Role[] = [
    {
        id: 1,
        name: RoleName.USER
    },
    {
        id: 2,
        name: RoleName.MANAGER
    },
    {
        id: 3,
        name: RoleName.ADMIN
    },
    {
        id: 4,
        name: RoleName.GOAL_COMPANY
    },
    {
        id: 5,
        name: RoleName.SURVEY_COMPANY
    }
];