<div *ngIf="loading === false && error === false">
  <form [formGroup]="form" autocomplete="off">
    <div class="p-top-15 p-left-15 p-right-30 m-bottom-form">
      <!-- Goal title and type -->
      <div class="row">
        <div class="col-md-8">
          <div class="frankli-form-group"
            [class.frankli-form-group-invalid]="form.controls.goalTitle.invalid && submitted"
            [class.frankli-form-group-filled]="form.controls.goalTitle.value">
            <label class="modal-label" for="goal-title"><span class="fal fa-bullseye"></span> Goal Objective</label>
            <input formControlName="goalTitle" id="goal-title" class="form-control" />
            <div class="frankli-form-group-alert">
              <ng-container *ngIf="submitted && form.controls.goalTitle.invalid">
                <div *ngIf="form.controls.goalTitle.hasError('required')">Required</div>
                <div *ngIf="form.controls.goalTitle.hasError('maxlength')">Must be less than 255 characters</div>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="frankli-form-group frankli-form-group-filled"
            [class.frankli-form-group-invalid]="form.controls.goalType.invalid && submitted">
            <label class="modal-label" for="goal-type"><span class="fal fa-cog"></span> Goal Type</label>
            <select class="form-control" formControlName="goalType" id="goal-type">
              <option disabled selected [ngValue]="null">Please Select...</option>
              <option *ngFor="let type of valuesGoalType" [value]="type.value">{{type.name}}</option>
            </select>
            <div class="frankli-form-group-alert">
              <ng-container *ngIf="submitted && form.controls.goalType.invalid">
                <div *ngIf="form.controls.goalType.hasError('required')">Required</div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="alert alert-warning type-warning" *ngIf="form.controls.goalType.value === eGoalType.DEPARTMENT">
        Note: {{companyWording.department | titlecase}} goals are visible to the entire company
      </div>

      <div class="alert alert-warning type-warning" *ngIf="form.controls.goalType.value === eGoalType.OFFICE_LOCATION">
        Note: Site goals are visible to the entire company
      </div>

      <!-- Department -->
      <div class="frankli-form-group frankli-form-group-filled m-top-10"
        [class.frankli-form-group-invalid]="form.hasError('departmentRequired') && submitted"
        *ngIf="form.controls.goalType.value === eGoalType.DEPARTMENT">
        <label class="modal-label" for="department">
          <span class="fal fa-briefcase"></span>{{companyWording.department | titlecase}}</label>
        <select class="form-control" formControlName="goalDepartment" id="department">
          <option disabled selected [ngValue]="null">Please Select...</option>
          <option *ngFor="let department of departments" [ngValue]="department.id">{{department.name}}</option>
        </select>
        <div class="frankli-form-group-alert">
          <ng-container *ngIf="submitted && form.hasError('departmentRequired')">
            <div>Required</div>
          </ng-container>
        </div>
      </div>

      <!-- Office Location -->
      <div class="frankli-form-group frankli-form-group-filled m-top-10"
        [class.frankli-form-group-invalid]="form.controls.goalOfficeLocation.invalid && submitted"
        *ngIf="form.controls.goalType.value === eGoalType.OFFICE_LOCATION">
        <label class="modal-label" for="department">
          <span class="fal fa-briefcase"></span>Site</label>
        <select class="form-control" formControlName="goalOfficeLocation" id="department">
          <option disabled selected [ngValue]="null">Please Select...</option>
          <option *ngFor="let officeLocation of officeLocations" [value]="officeLocation.id">{{officeLocation.name}}
          </option>
        </select>
        <div class="frankli-form-group-alert">
          <ng-container *ngIf="submitted && form.controls.goalOfficeLocation.invalid">
            <div *ngIf="form.controls.goalOfficeLocation.hasError('required')">Required</div>
          </ng-container>
        </div>
      </div>

      <!-- Goal Alignment -->
      <ng-container
        *ngIf="form.controls.goalType.value !== eGoalType.COMPANY">
        <div class="frankli-form-group frankli-form-group-filled m-top-10"
          [class.frankli-form-group-invalid]="form.controls.goalAlignment.invalid && submitted"
          *ngIf="!alignmentLoading; else stateAlignmentLoading">
          <label class="modal-label" for="alignment"><span class="fal fa-handshake"></span> Align Goal (optional) <span *ngIf="form.controls.goalType.value" [tooltip]="getGoalAlignmentInfoText(form.controls.goalType.value)" placement="top" container="body" class="fal fa-info-circle icon-inform"></span></label>
          <select class="form-control" formControlName="goalAlignment" id="alignment">
            <option [ngValue]="null">No Alignment</option>
            <option *ngFor="let goal of goals" [ngValue]="goal.id">
              <ng-container *ngIf="goal.type === eGoalType.TEAM">
                [{{companyWording.team | titlecase}}]
              </ng-container>
              <ng-container *ngIf="goal.type === eGoalType.DEPARTMENT">
                [{{companyWording.department | titlecase}}]
              </ng-container>
              <ng-container *ngIf="goal.type === eGoalType.OFFICE_LOCATION">
                [Site]
              </ng-container>
              <ng-container *ngIf="goal.type === eGoalType.COMPANY">
                [Company]
              </ng-container>
              {{ '  ' + goal.title}}
            </option>
          </select>
        </div>
        <ng-template #stateAlignmentLoading>
          <div>
            <label class="modal-label">
              <span class="fal fa-handshake"></span> Align Goal (optional)</label>
            <div class="select-loading">
              <span class="fal fa-spin fa-spinner"></span>
            </div>
          </div>
        </ng-template>
      </ng-container>

      <!-- Key Results  -->
      <div formArrayName="keyResults">
        <div class="row text-center">
          <div *ngIf="form.controls.keyResults.length === 0">
            <h4 class="text-danger m-top-20 m-bottom-20">At least one key result is required</h4>
            <span class="btn-frankli-round btn-frankli-green btn-frankli-small" (click)="addKeyResult()">Add key
              result?</span>
          </div>
        </div>
        <div *ngFor="let keyResult of form.controls.keyResults.controls; index as i;">
          <div [formGroupName]="i">
            <app-goals-create-key-result (cancelAddKeyResult)="removeKeyResult($event)"
              [(keyResultForm)]="form.controls.keyResults.controls[i]" [mode]="mode" [inputGoal]="goal"
              [resultIndex]="i" [submitted]="submitted" [templateValues]="templatesResult">
            </app-goals-create-key-result>
          </div>
        </div>
        <div *ngIf="form.controls.keyResults.length > 0" class="m-top-10 m-bottom-20 text-center">
          <button class="btn-frankli-round btn-frankli-green btn-frankli-small" (click)="addKeyResult()">Add
            another?</button>
        </div>
      </div>

      <!-- Visibility, Priority, End date -->
      <div class="row m-top-25">
        <!-- Visibility -->
        <div class="col-md-4">
          <div class="frankli-form-group frankli-form-group-filled"
            [class.frankli-form-group-invalid]="form.controls.visibility.invalid && submitted">
            <label class="modal-label" for="visibility"><span class="fal fa-lock"></span> Visibility</label>
            <select class="form-control" formControlName="visibility" id="visibility">
              <option disabled selected [ngValue]="null">Please Select...</option>
              <option *ngFor="let visibility of valuesGoalVisibility" [value]="visibility">{{visibility}}</option>
            </select>
            <div class="frankli-form-group-alert">
              <ng-container *ngIf="form.controls.visibility.invalid && submitted">
                <div *ngIf="form.controls.visibility.hasError('required')">Required</div>
              </ng-container>
            </div>
          </div>
        </div>

        <!-- Priority -->
        <div class="col-md-4" *ngIf="globals.hasFeature(eFeature.GOAL_PRIORITY)">
          <div class="frankli-form-group frankli-form-group-filled"
            [class.frankli-form-group-invalid]="form.controls.priority.invalid && submitted">
            <label class="modal-label" for="priority"><span class="fal fa-exclamation-circle"></span> Priority
              (optional)</label>
            <select class="form-control" formControlName="priority" id="priority">
              <option [ngValue]="eGoalPriority.NOT_SET">Not set</option>
              <option [ngValue]="eGoalPriority.P1">P1</option>
              <option [ngValue]="eGoalPriority.P2">P2</option>
              <option [ngValue]="eGoalPriority.P3">P3</option>
              <option [ngValue]="eGoalPriority.P4">P4</option>
              <option [ngValue]="eGoalPriority.P5">P5</option>
            </select>
            <div class="frankli-form-group-alert">
              <ng-container *ngIf="submitted && form.controls.priority.invalid">
                <div *ngIf="form.controls.priority.hasError('required')">Required</div>
              </ng-container>
            </div>
          </div>
        </div>

        <!-- End Date -->
        <div class="col-md-4">
          <div class="frankli-form-group frankli-form-group-filled"
            [class.frankli-form-group-invalid]="(form.controls.endDate.invalid || validateEndDates() === false) && submitted">
            <label class="modal-label" for="datetimepicker-999"><span class="fal fa-clock"></span> End Date</label>
            <app-date-picker-old-component [formControl]="form.controls.endDate" [invalid]="form.controls.endDate.invalid" [index]="999" [minDate]="minEndDate"
              [emptyText]="'No date'" [verticalPosition]="'bottom'"></app-date-picker-old-component>
            <div class="frankli-form-group-alert">
              <ng-container *ngIf="submitted && (form.controls.endDate.invalid || !validateEndDates())">
                <div *ngIf="form.controls.endDate.hasError('required')">Required</div>
                <div *ngIf="!validateEndDates()">Must be after key result end dates</div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <!-- Development Needs -->
      <div class="frankli-form-group frankli-form-group-filled m-top-10"
        [class.frankli-form-group-invalid]="form.controls.developmentNeeds.invalid && submitted"
        *ngIf="form.controls.goalType.value === eGoalType.PERSONAL_DEVELOPMENTAL">
        <label class="modal-label"><span class="fal fa-graduation-cap"></span> Development Needs</label>
        <textarea rows="3" placeholder="What are your development needs?" formControlName="developmentNeeds"
          class="form-control text-area-fix"></textarea>
        <div class="frankli-form-group-alert">
          <ng-container *ngIf="submitted && form.controls.developmentNeeds.invalid">
            <div *ngIf="form.controls.developmentNeeds.hasError('required')">Required</div>
            <div *ngIf="form.controls.developmentNeeds.hasError('maxlength')">Must be less than 255 characters</div>
          </ng-container>
        </div>
      </div>

      <!-- Tags -->
      <div class="m-top-10 frankli-form-group frankli-form-group-placeholder-shown frankli-form-group-filled">
        <label class="modal-label"><span class="fal fa-tag"></span> Tags (optional)</label>
        <div class="m-top-10">Tags can help make organising and searching your goals easier.</div>

        <!-- Search box -->
        <div class="frankli-form-group frankli-form-group-placeholder-shown input-group m-top-10" id="tagSearch">
          <span class="input-group-addon box-left">
            <span class="fal fa-search"></span>
          </span>
          <input type="text" value="" class="form-control" [(ngModel)]="tagFilter.value"
            [ngModelOptions]="{standalone: true}" (ngModelChange)="filterTags($event)"
            placeholder="Search by typing here...">
          <span class="input-group-addon box-right clickable" (click)="clearTagFilter()" tooltip="Clear search" placement="top" container="body">
            <span class="fal fa-times"></span>
          </span>
        </div>

        <!-- Search Output -->
        <div class="light-border" *ngIf="tagFilter.filtered.length > 0">
          <!-- this allows you to add a custom tag if its not in the list, don't think its needed though -->
          <!-- <div *ngIf="!tagFilter.filtered.includes(tagFilter.value) && tagFilter.value.trim().length > 2">
            <span class="tag tag-new noselect" (click)="addTag(tagFilter.value)">
              <span class="fal fa-tag"></span>
              <span class="tag-text">{{tagFilter.value}}</span>
            </span>
          </div> -->
          <span [title]="tag.text + ': ' + tag.description" class="tag noselect" *ngFor="let tag of tagFilter.filtered"
            (click)="addTag(tag)">
            <span class="fal fa-tag"></span>
            <span class="tag-text">{{tag.text}}</span>
          </span>

          <div class="text-center m-top-25 m-bottom-25" *ngIf="tagFilter.filtered.length === 0">
            No tags found for this search
          </div>
        </div>

        <div class="frankli-form-group-alert"></div>

        <!-- Tags - Selected -->
        <span class="tag tag-info text-center noselect"
          *ngFor="let tag of tagsSelected" (click)="removeTag(tag)">
          <span class="fal fa-tag"></span>
          <span class="tag-text">{{tag.text}}</span>
          <span class="fal fa-times tag-btn" tooltip="Remove tag" placement="right" container="body"></span>
        </span>
      </div>

      <!-- Owners -->
      <div class="m-top-10 frankli-form-group frankli-form-group-placeholder-shown frankli-form-group-filled"
        [class.frankli-form-group-invalid]="submitted && !isValidOwners()">
        <label class="modal-label"><span class="fal fa-users"></span> Owner (1 or more Required)</label> 
        <div class="m-top-10">Owners are the people directly involved in completing the goal</div>

        <!-- Search box -->
        <div class="input-group m-top-10" id="ownerSearch">
          <span class="input-group-addon box-left">
            <span class="fal fa-search"></span>
          </span>
          <input type="text" value="" class="form-control" [(ngModel)]="ownerFilter.value"
            [ngModelOptions]="{standalone: true}" (ngModelChange)="filterOwners($event)"
            placeholder="Search by typing here...">
          <span class="input-group-addon clickable box-right" (click)="clearOwnerFilter()" tooltip="Clear search" placement="top" container="body">
            <span class="fal fa-times"></span>
          </span>
        </div>

        <!-- Search Output -->
        <div class="light-border" *ngIf="ownerFilter.filtered.length > 0">
          <span class="owner noselect" *ngFor="let owner of ownerFilter.filtered" (click)="addOwner(owner)">
            <span class="owner-img">
              <app-profile-photo [src]="owner.imageUrl"></app-profile-photo>
            </span>
            <span class="owner-text">{{owner.firstName}} {{owner.lastName}}</span>
          </span>
          <div class="text-center m-top-25 m-bottom-25" *ngIf="ownerFilter.filtered.length === 0">
            No people found for this search
          </div>
        </div>

        <div class="frankli-form-group-alert">
          <!-- TODO: Change isValidOwners to custom validator -->
          <ng-container *ngIf="submitted && !isValidOwners()">
            <div>Required</div>
            <div
              *ngIf="form.controls.goalType.value === eGoalType.PERSONAL_OPERATIONAL || form.controls.goalType.value === eGoalType.PERSONAL_DEVELOPMENTAL">
              A personal goal must have either yourself or someone you manage as an owner.
            </div>
          </ng-container>
        </div>

        <!-- Owners - Selected -->
        <span class="owner owner-info owner-selected text-center noselect" *ngFor="let owner of ownersSelected"
          (click)="removeOwner(owner)">
          <span class="owner-img">
            <app-profile-photo [src]="owner.imageUrl"></app-profile-photo>
          </span>
          <span class="owner-text">{{owner.firstName}} {{owner.lastName}}</span>
          <span class="lh-40" tooltip="Remove owner" placement="right" container="body">
            <span class="fal fa-times owner-btn m-right-10"></span>
          </span>
        </span>
      </div>
  
      <!-- Distribute goal - TODO: Enable this once FDS-1866 is done -->
      <div class="m-top-10 frankli-form-group frankli-form-group-filled" *ngIf="(false === true) && form.controls.goalType.value === eGoalType.OFFICE_LOCATION">
        <label class="modal-label"><span class="fal fa-share"></span> Distribute goal to owners</label>
        <div class="m-top-10">If selected, all owners will receive their own version of this goal with only them as the
          owner</div>

        <div class="m-top-10">
          <label class="switch distribute-switch">
            <input type="checkbox" id="distributeGoal" name="distributeGoal" formControlName="distribute">
            <span class="slider round"></span>
          </label>

          <label for="distributeGoal" class="distribute-label"> Distribute goal</label>
        </div>
      </div>
    </div>

    <!-- Action buttons -->
    <div class="button-row text-right" [ngSwitch]="mode">
      <!-- Create -->
      <ng-container *ngSwitchCase="'CREATE'">
        <span class="text-danger m-right-10" *ngIf="submitted && !isValid()">There are errors above</span>
        <!-- Save Draft Goal-->
        <button *ngIf="saveLoading === false" (click)="saveDraft()" class="btn-frankli-round btn-frankli-gray-inverted"
          angulartics2On="click" angularticsAction="Save Draft" angularticsCategory="Goals">
          Save Draft</button>
        <button *ngIf="saveLoading === true" class="btn-frankli-round btn-frankli-gray-inverted">
          <span class="fal fa-spin fa-spinner"></span>
        </button>
        <!-- Create Goal -->
        <button type="submit" class="btn-frankli-round btn-frankli-green" *ngIf="createLoading === false"
          angulartics2On="click" angularticsAction="Create Goal" angularticsCategory="Goals" (click)="saveGoal()">Create
          Goal</button>
        <button class="btn-frankli-round btn-frankli-green" *ngIf="createLoading === true">
          <span class="fal fa-spin fa-spinner"></span>
        </button>
      </ng-container>

      <!-- Edit -->
      <ng-container *ngSwitchCase="'EDIT'">
        <span class="text-danger" *ngIf="submitted && !isValid()">There are errors above</span>
        <button type="submit" class="btn-frankli-round btn-frankli-green" *ngIf="createLoading === false"
          angulartics2On="click" angularticsAction="Update Goal" angularticsCategory="Goals" (click)="saveGoal()">Update
          Goal</button>
        <button class="btn-frankli-round btn-frankli-green" *ngIf="createLoading === true">
          <span class="fal fa-spin fa-spinner"></span>
        </button>
      </ng-container>

      <!-- Edit Draft -->
      <ng-container *ngSwitchCase="'EDIT_DRAFT'">
        <span class="text-danger" *ngIf="submitted && !isValid()">There are errors above</span>
        <!-- Save Draft Goal-->
        <button *ngIf="saveLoading === false" (click)="saveDraft()" class="btn-frankli-round btn-frankli-gray-inverted"
          angulartics2On="click" angularticsAction="Update Draft" angularticsCategory="Goals">
          Save Draft</button>
        <button *ngIf="saveLoading === true" class="btn-frankli-round btn-frankli-gray-inverted">
          <span class="fal fa-spin fa-spinner"></span>
        </button>
        <!-- Create Goal -->
        <button type="submit" class="btn-frankli-round btn-frankli-green" *ngIf="createLoading === false"
          angulartics2On="click" angularticsAction="Create Goal From Draft" angularticsCategory="Goals"
          (click)="saveGoal()">Create
          Goal</button>
        <button class="btn-frankli-round btn-frankli-green" *ngIf="createLoading === true">
          <span class="fal fa-spin fa-spinner"></span>
        </button>
      </ng-container>

    </div>
  </form>
</div>

<!-- LOADING -->
<div class="m-top-25 m-bottom-15" *ngIf="loading === true && error === false">
  <div class=" text-center">
    <span class="fal fa-spin fa-spinner fa-3x fa-fw"></span>
  </div>
</div>

<!-- ERROR -->
<div class="m-top-25 m-bottom-15" *ngIf="loading === false && error === true">
  <div class="text-center" *ngIf="loading === false && error === true">
    <p>
      <img class="icon-padding new-icon-height" src="/assets/img/Error.svg" />
      <br>
      An error occurred while getting your goals</p>
    <span class="btn-frankli-round btn-frankli-green" [routerLink]="'/dashboard'">
      Back to your dashboard?
    </span>
  </div>
</div>