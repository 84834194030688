import * as moment from "moment";
import { GoalKeyResultMeasureUnitPlacement } from "./goal-key-result-measure-unit-placement.model";
import { GoalKeyResultServerside } from "./goal-key-result-serverside.model";
import { GoalKeyResultType } from "./goal-key-result-type.model";

export class GoalKeyResult {
  id: number;
  result: string;
  type: GoalKeyResultType;
  measureStartValue: number;
  measureCurrentValue: number;
  measureGoalValue: number;
  endDate: Date | null;
  reversed: boolean;
  measureUnit: string;
  measureUnitPlacement: GoalKeyResultMeasureUnitPlacement;

  // TODO: remove undefined later once goals-create.component.ts is updated to use CreateGoalDto and UpdateGoalDto instead
  constructor(goalKeyResultServerside?: GoalKeyResultServerside) {
    if (goalKeyResultServerside) {
      this.id = goalKeyResultServerside.id;
      this.result = goalKeyResultServerside.result;
      this.type = goalKeyResultServerside.type;
      this.measureStartValue = goalKeyResultServerside.measureStartValue;
      this.measureCurrentValue = goalKeyResultServerside.measureCurrentValue;
      this.measureGoalValue = goalKeyResultServerside.measureGoalValue;
      this.endDate = (goalKeyResultServerside.endDate === null) ? null : moment.utc(goalKeyResultServerside.endDate).toDate();
      this.reversed = goalKeyResultServerside.reversed;
      this.measureUnit = goalKeyResultServerside.measureUnit;
      this.measureUnitPlacement = goalKeyResultServerside.measureUnitPlacement;
    }
  }

  getProgress(): number {
    let progress =
      ((this.measureStartValue - this.measureCurrentValue) /
        (this.measureStartValue - this.measureGoalValue)) *
      100;

    if (progress > 100) {
      progress = 100;
    }

    if (progress < 0) {
      progress = 0
    }

    return progress;
  }
}
