import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import {AuthAPIService, SSOProvider} from '@app/shared/auth/auth.api.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private authService: AuthAPIService,
    private router: Router
  ) { }

  ngOnInit() {
    const code = this.route.snapshot.queryParamMap.get('code');
    const state = this.route.snapshot.queryParamMap.get('state');

    if (code !== null && state !== null) {
      this.authService.authenticateSSO({ authorizationCode: code, provider: SSOProvider[state] }).subscribe(() => {
        this.router.navigateByUrl('/');
      }, (error: HttpErrorResponse) => {
        this.handleError(error.error);
      });
    } else {
      this.handleError('Something went wrong when trying to sign you in.');
    }
  }

  private handleError(message: string) {
    localStorage.setItem('sso_error', `${Date.now()}: ${message}`);
    this.router.navigateByUrl('/');
  }

}
