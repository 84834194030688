import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { UserAPIService } from 'app/shared/api/user.api.service';

interface State { loading: boolean, error: boolean, errorMessage: string, submitted: boolean, complete: boolean }

@Component({
  selector: 'app-reset-password-email',
  templateUrl: './reset-password-email.component.html',
  styleUrls: ['./reset-password-email.component.css']
})
export class ResetPasswordEmailComponent implements OnInit {
  state: State;
  emailForm: FormGroup;
  email: AbstractControl;

  constructor(
    private formBuilder: FormBuilder,
    private userAPIService: UserAPIService
  ) {
    this.state = {
      loading: false,
      error: false,
      errorMessage: '',
      submitted: false,
      complete: false
    };
    this.emailForm = this.setupEmailForm();
    this.email = this.emailForm.controls['email'];
  }

  ngOnInit() { }

  private setupEmailForm(): FormGroup {
    return this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(/^(.*).@(.*).\..+$/)]],
    });
  }

  emailFormSubmit() {
    this.state.submitted = true;
    if (this.emailForm.valid === true) {
      this.state.loading = true;
      this.userAPIService.resetUserPassword(this.email.value).subscribe(() => {
        this.state.loading = false;
        this.state.complete = true;
      }, (failure: HttpErrorResponse) => {
        this.state.error = true;
        this.state.errorMessage = failure.error;
        this.state.loading = false;
      });
    }
  }

  resetForm() {
    this.state = {
      loading: false,
      error: false,
      errorMessage: '',
      submitted: false,
      complete: false
    };
    this.emailForm = this.setupEmailForm();
    this.email = this.emailForm.controls['email'];
  }

}
