<!-- Upload image -->
<div class="profile-picture">
  <span class="pic-container">
    <app-profile-photo [src]="user.imageUrl" [alt]="''"></app-profile-photo>
    <div (click)="imageInput.click()" class="pic-upload-icon" title="Add a profile picture">
      <span class="fal fa-fw fa-camera-alt"></span>
    </div>
  </span>
</div>
<div>
  <!-- TODO: Finalize image file size and image types -->
  <input class="hidden" #imageInput type="file" [accept]="ACCEPTED_IMAGE_TYPES" (change)="loadCropper($event)" />
</div>


<!-- Modal -->
<app-modal [width]="'auto'" #modalCropper [background]="'light'">
  <div class="modal-body-content">
    <div class="row">
      <div class="col-xs-12">
        <span class="modal-header-text">Upload Image</span>
      </div>
    </div>

    <hr class="row" />

    <div [class.hidden]="stateUpload.isLoading()">
      <!-- Image cropper -->
      <div class="row">
        <div class="col-xs-12 text-center">
          <canvas id="canvasImageCrop">Your browser does not support this feature.</canvas>
        </div>
      </div>

      <!-- Cropper options -->
      <div class="row m-top-15">
        <div class="col-xs-6 text-left">
          <button class="btn-frankli-round btn-frankli-cancel" (click)="cancelImageUpload()" angulartics2On="click"
            angularticsAction="CancelUploadedProfilePicture" angularticsCategory="Onboarding">Cancel</button>
        </div>
        <div class="col-xs-6 text-right">
          <button class="btn-frankli-round btn-frankli-green" (click)="uploadImage()" angulartics2On="click"
            angularticsAction="UploadedProfilePicture" angularticsCategory="Onboarding">
            <span class="fal fa-cloud-upload"></span>
            Upload
          </button>
        </div>
      </div>

    </div>

    <div [class.hidden]="stateUpload.isLoading() === false">
      <div class="row">
        <div class="col-xs-12 text-center">
          <span class="fal fa-fw fa-spin fa-spinner"></span>
          Uploading image...
        </div>
      </div>
    </div>

  </div>
</app-modal>