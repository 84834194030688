import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'app/shared/globals/globals';
import { catchError, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Angulartics2Amplitude } from 'angulartics2/amplitude';
import { User, UserInit } from 'app/models/user/user.model';
import { NotificationService } from '../globals/notification.service';
import { CompanyAPIService } from '../api/company.api.service';
import { SessionLogService } from '../session-log/session-log.service';
import { SessionEventType } from '@app/models/session-log/session-event-type.model';
import { AuthService } from '@app/shared/api/interfaces/auth.service';

export enum SSOProvider {
  MICROSOFT = 'MICROSOFT',
  GOOGLE = 'GOOGLE'
}

export interface Credentials { email: string, password: string }
export interface SSOCredentials { authorizationCode: string, provider: SSOProvider }

@Injectable()
export class AuthAPIService implements AuthService {

  constructor(
    private http: HttpClient,
    private globals: Globals,
    private router: Router,
    private angulartics2Amplitude: Angulartics2Amplitude,
    private notificationService: NotificationService,
    private companyAPIService: CompanyAPIService,
    private sessionLogService: SessionLogService
  ) { }

  authenticate(credentials: Credentials): Observable<Object> {
    return this.http.post<any>('/authuser', credentials).pipe(
      map(ui => {
        ui.company = this.companyAPIService.mapCompany(ui.company);
        return {
          user: new User(ui.user),
          company: ui.company,
          access_token: ui.accessToken
        }
      }),
      tap((init: UserInit) => {
        localStorage.setItem('access_token', init.access_token);
        this.globals.init(init);
        this.globals.welcomeViewed = false;
        this.globals.signedInWithSSO = false;
        if (environment.production) {
          this.angulartics2Amplitude.setUsername(this.globals.user.email);
        }
        this.sessionLogService.log(SessionEventType.SESSION_START_LOGIN);
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  authenticateSSO(credentials: SSOCredentials): Observable<Object> {
    return this.http.post<any>('/sso', credentials).pipe(
      map(ui => {
        return {
          user: new User(ui.user),
          company: ui.company,
          access_token: ui.accessToken
        }
      }),
      tap((init: UserInit) => {
        localStorage.setItem('access_token', init.access_token);
        this.globals.init(init);
        this.globals.welcomeViewed = false;
        this.globals.signedInWithSSO = true;
        if (environment.production) {
          this.angulartics2Amplitude.setUsername(this.globals.user.email);
        }
        this.sessionLogService.log(SessionEventType.SESSION_START_LOGIN);

      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  logout() {
    if (localStorage.getItem('access_token')) {
      this.sessionLogService.log(SessionEventType.SESSION_END_LOGOUT);
    }
    localStorage.removeItem('access_token');
    this.globals.clear();
    this.router.navigateByUrl('/login');
    this.notificationService.clear();
  }

}
