import { MockService } from '@app/mock/api/mock-service';
import { HttpRequest } from '@angular/common/http';
import { PermissionService } from '@app/shared/api/interfaces/permission.service';
import { Observable } from 'rxjs';
import { User } from '@app/models/user/user.model';
import { sanitizeUrl } from '@app/shared/utils/helpers';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { mockRoles } from '@app/mock/api/data/mockRoles';

export class PermissionMockService implements MockService, PermissionService {
  handleRoute(req: HttpRequest<any>): any {
    const url = sanitizeUrl(req.urlWithParams);

    switch (true) {
      case url.match(/api\/user\/permission\/\D+$/) !== null && req.method === 'GET':
        const permissionKey = url.split('/')[3];
        return this.getUsersWithPermission(permissionKey);
    }
  }

  getUsersWithPermission(key: string): Observable<Array<User>> | Array<User> {
    if (key === 'goal') {
      return mockUsers.filter(u => u.roles.includes(mockRoles[3]))
    }
    if (key === 'survey') {
      return mockUsers.filter(u => u.roles.includes(mockRoles[4]))
    }
    return [];
  }

  // No Ops listed below
  updatePermission(key: string, users: Array<User>): Observable<Array<User>> {
    return undefined;
  }
}