import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NavbarComponent } from '../../navbar/navbar.component';
import { AuthAPIService } from '@app/shared/auth/auth.api.service';
import {CompanyFeatures} from '@app/models/company.model';
import {environment} from '../../../../environments/environment';
import {Globals} from '@app/shared/globals/globals';

declare var $: any;

@Component({
  selector: 'app-sign-up-layout',
  templateUrl: './sign-up-layout.component.html',
  styleUrls: ['./sign-up-layout.component.css']
})

export class SignUpLayoutComponent implements OnInit {
  sidebarVisible: boolean;
  toggleButton: Element;

  @ViewChild(NavbarComponent) navbar !: NavbarComponent;

  constructor(
    private router: Router,
    private element: ElementRef,
    private authService: AuthAPIService,
    private globals: Globals
  ) {
    this.sidebarVisible = false;
  }

  ngOnInit() {
    const body = document.getElementsByTagName('body')[0];

    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];

    // make sure sidebar closes on navigation
    try {
      body.classList.remove('nav-open');
      this.toggleButton.classList.remove('toggled');
    } catch (e) {

    }
    this.sidebarVisible = false;
  }

  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  logout() {
    this.authService.logout();
    // if (this.globals.company.features.find(f => f.feature === CompanyFeatures.SSO).enabled) {
    //   this.authService.logoutSSO();
    // } else {
    //   this.authService.logout();
    // }
  }

  private sidebarOpen() {
    const body = document.getElementsByTagName('body')[0];
    const context = this;
    setTimeout(function () {
      try {
        context.toggleButton.classList.add('toggled');
      } catch (e) {

      }
    }, 500);
    body.classList.add('nav-open');
    this.sidebarVisible = true;
  }

  private sidebarClose() {
    const body = document.getElementsByTagName('body')[0];

    // TODO: fix this issue so the correct button shows
    try {
      this.toggleButton.classList.remove('toggled');

    } catch (e) {

    }

    body.classList.remove('nav-open');
    this.sidebarVisible = false;
  }
}

