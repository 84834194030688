import { User } from 'app/models/user/user.model';
import * as moment from 'moment';
import { OfficeLocation } from '../company.model';
import { OrganisationalUnit } from '../organisational-unit.model';
import { GoalActivitiy, GoalActivityType, GoalProgressUpdateKeyResult } from './goal-activity.model';
import { GoalKeyResult } from './goal-key-result.model';
import { GoalServerside } from './goal-serverside.model';
import { GoalTag } from './goal-tag.model';

class GoalSort {
  /**
   * Sorts an array of goals by title alphabetically
   * @param goals
   * @param direction
   */
  byTitle(
    goals: Array<Goal>,
    direction: 'ascending' | 'descending'
  ): Array<Goal> {
    const response = goals.sort((goalA, goalB) => {
      const a = goalA.title.toLocaleLowerCase();
      const b = goalB.title.toLocaleLowerCase();

      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });

    if (direction === 'descending') {
      response.reverse();
    }

    return response;
  }

  /**
   * Sorts an array of goals by type alphabetically
   * @param goals
   * @param direction
   */
  byType(
    goals: Array<Goal>,
    direction: 'ascending' | 'descending'
  ): Array<Goal> {
    const response = goals.sort((goalA, goalB) => {
      const a = goalA.type.toString().toLocaleLowerCase();
      const b = goalB.type.toString().toLocaleLowerCase();

      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });

    if (direction === 'descending') {
      response.reverse();
    }

    return response;
  }

  /**
   * Sorts an array of goals by priority alphabetically
   * @param goals
   * @param direction
   */
  byPriority(
    goals: Array<Goal>,
    direction: 'ascending' | 'descending'
  ): Array<Goal> {
    let priorityList = goals.filter((x) => x.priority !== 'NOT_SET');
    let notSetList = goals.filter((x) => x.priority === 'NOT_SET');
    let response;
    priorityList = priorityList.sort((goalA, goalB) => {
      const a = goalA.priority.toString().toLocaleLowerCase();
      const b = goalB.priority.toString().toLocaleLowerCase();

      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });

    if (direction === 'descending') {
      priorityList.reverse();
    }
    notSetList = notSetList.sort((goalA, goalB) => {
      const a = new Date(goalA.endDate).valueOf();
      const b = new Date(goalB.endDate).valueOf();
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });
    if (direction === 'descending') {
      notSetList.reverse();
    }
    response =
      direction === 'descending'
        ? notSetList.concat(priorityList)
        : priorityList.concat(notSetList);
    return response;
  }

  /**
   * Sorts an array of goals by total keyresults
   * @param goals
   * @param direction
   */
  byKeyResults(
    goals: Array<Goal>,
    direction: 'ascending' | 'descending'
  ): Array<Goal> {
    const response = goals.sort((goalA, goalB) => {
      const a = goalA.keyResults.length;
      const b = goalB.keyResults.length;

      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });

    if (direction === 'descending') {
      response.reverse();
    }

    return response;
  }

  /**
   * Sorts an array of goals by end date
   * @param goals
   * @param direction
   */
  byEndDate(
    goals: Array<Goal>,
    direction: 'ascending' | 'descending'
  ): Array<Goal> {
    const response = goals.sort((goalA, goalB) => {
      const a = new Date(goalA.endDate).valueOf();
      const b = new Date(goalB.endDate).valueOf();
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });

    if (direction === 'descending') {
      response.reverse();
    }

    return response;
  }

  /**
   * Sorts an array of goals by end date
   * @param goals
   * @param direction
   */
  byProgress(
    goals: Array<Goal>,
    direction: 'ascending' | 'descending'
  ): Array<Goal> {
    goals = Goal.getGoalArrayCompletionPercentage(goals);

    const response = goals.sort((goalA, goalB) => {
      const a = goalA.completionPercentage;
      const b = goalB.completionPercentage;
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });

    if (direction === 'descending') {
      response.reverse();
    }

    return response;
  }
}

export class Goal {
  static sort: GoalSort = new GoalSort();

  id: number;
  title: string;
  type: GoalType;
  department: OrganisationalUnit | null;
  officeLocation: OfficeLocation | null;
  creationDate: Date;
  endDate: Date;
  completionDate: Date | null;
  visibility: GoalVisibility;
  priority: GoalPriority;
  tags: Array<GoalTag>;
  keyResults: Array<GoalKeyResult>;
  owners: Array<User>;
  complete: boolean;
  archived: boolean;
  activity: Array<GoalActivitiy>;
  completionPercentage: number;
  status: GoalStatus;
  developmentNeeds: string | null;
  alignment: number | null;

  // TODO: remove undefined later once goals-create.component.ts is updated to use CreateGoalDto and UpdateGoalDto instead
  constructor(goalServerside?: GoalServerside) {
    if (goalServerside) {
      this.id = goalServerside.id;
      this.title = goalServerside.title;
      this.type = goalServerside.type;
      this.department = goalServerside.department;
      this.officeLocation = goalServerside.officeLocation;
      this.creationDate = moment.utc(goalServerside.creationDate).toDate();
      this.endDate = moment.utc(goalServerside.endDate).toDate();
      this.completionDate = (goalServerside.completionDate === null) ? null : moment.utc(goalServerside.completionDate).toDate();
      this.visibility = goalServerside.visibility;
      this.priority = goalServerside.priority;
      this.tags = goalServerside.tags;
      this.keyResults = goalServerside.keyResults.map(r => new GoalKeyResult(r));
      this.owners = goalServerside.owners;
      this.complete = goalServerside.complete;
      this.archived = goalServerside.archived;
      this.activity = goalServerside.activity.map(a => new GoalActivitiy(a));
      this.completionPercentage = goalServerside.completionPercentage;
      this.status = goalServerside.status;
      this.developmentNeeds = goalServerside.developmentNeeds;
      this.alignment = goalServerside.alignment;
    }
  }

  static getGoalArrayCompletionPercentage(goals: Array<Goal>) {
    goals = goals.map((g) => this.getGoalCompletionPercentage(g));
    return goals;
  }

  static getGoalArrayCompletionPercentageFromActivity(goals: Array<Goal>) {
    for (const goal of goals) {
      const keyResultsLatestUpdates: Array<GoalProgressUpdateKeyResult> = new Array<
        GoalProgressUpdateKeyResult
      >();
      // Loop through all KR updates and keep the latest one for a particular KR in the above array
      for (const activity of goal.activity) {
        if (
          activity.type === GoalActivityType.PROGRESS_UPDATE &&
          activity.progressUpdate
        ) {
          for (const keyResultUpdate of activity.progressUpdate.keyResults) {
            // NOTE: All newly created KRs will have a Key result ID associated with them, but old ones will not.
            const updateIndex = keyResultsLatestUpdates.every(
              (u) => u.keyResultId === null
            )
              ? keyResultsLatestUpdates
                .map((u) => u.name)
                .indexOf(keyResultUpdate.name)
              : keyResultsLatestUpdates
                .map((u) => u.keyResultId)
                .indexOf(keyResultUpdate.keyResultId);
            if (updateIndex !== -1) {
              keyResultsLatestUpdates[updateIndex] = keyResultUpdate;
            } else {
              // Check if current version of the goal still has this key result, otherwise ignore it as it has been deleted
              // NOTE: All newly created KRs will have a Key result ID associated with them, but old ones will not.
              const existingIndex =
                keyResultUpdate.id === null
                  ? goal.keyResults
                    .map((kr) => kr.result)
                    .indexOf(keyResultUpdate.name)
                  : goal.keyResults
                    .map((kr) => kr.id)
                    .indexOf(keyResultUpdate.keyResultId);
              if (existingIndex !== -1) {
                keyResultsLatestUpdates.push(keyResultUpdate);
              }
            }
          }
        }
      }
      // Edit existing key results with updated values
      for (const keyResult of goal.keyResults) {
        // NOTE: All newly created KRs will have a Key result ID associated with them, but old ones will not.
        const krIndex = keyResultsLatestUpdates.every(
          (u) => u.keyResultId === null
        )
          ? keyResultsLatestUpdates.map((u) => u.name).indexOf(keyResult.result)
          : keyResultsLatestUpdates
            .map((u) => u.keyResultId)
            .indexOf(keyResult.id);
        if (krIndex !== -1) {
          keyResult.measureCurrentValue =
            keyResultsLatestUpdates[krIndex].currentValueCurrent;
          keyResult.measureGoalValue =
            keyResultsLatestUpdates[krIndex].targetValueCurrent;
        }
      }
      // Need this here to cover an edge case for goals without KR progress updates
      if (
        goal.activity.length === 1 &&
        goal.activity[0].type === GoalActivityType.CREATE
      ) {
        // TODO: This won't cover cases where there was an initial value set for a KR
        goal.completionPercentage = 0;
      } else {
        this.getGoalCompletionPercentage(goal);
      }
    }
  }

  static getGoalCompletionPercentage(goal: Goal): Goal {
    const values: number[] = goal.keyResults.map((k) => {
      let progress = ((k.measureStartValue - k.measureCurrentValue) / (k.measureStartValue - k.measureGoalValue)) * 100;

      if (progress > 100) {
        progress = 100;
      } else if (progress < 0) {
        progress = 0;
      }

      return progress;
    });

    goal.completionPercentage = this.getAverage(values);

    return goal;
  }

  private static getAverage(values: number[]): number {
    let total = 0;
    values.forEach((v) => {
      if (!isNaN(v)) {
        total += v;
      }
    });
    return Number((total / values.length).toFixed(2));
  }

  static getAverageAndCount(goals: Goal[]): number[] {
    let count = 0;
    let total = 0;
    let activeCount = 0;
    let completeCount = 0;
    for (const goal of goals) {
      if (!goal.complete) {
        activeCount++;
        for (const keyResult of goal.keyResults) {
          const current = keyResult.measureCurrentValue;
          const max = keyResult.measureGoalValue;

          let percentage = current > max ? 100.0 : (current / max) * 100.0;

          total += Math.floor(percentage);
          count++;
        }
      } else {
        completeCount++;
      }
    }

    return [
      count > 0 ? total / count : 0,
      count,
      activeCount,
      completeCount
    ];
  }
}

export enum GoalVisibility {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export enum GoalPriority {
  NOT_SET = 'NOT_SET',
  P1 = 'P1',
  P2 = 'P2',
  P3 = 'P3',
  P4 = 'P4',
  P5 = 'P5',
}

export enum GoalType {
  PERSONAL_DEVELOPMENTAL = 'PERSONAL_DEVELOPMENTAL',
  PERSONAL_OPERATIONAL = 'PERSONAL_OPERATIONAL',
  DEPARTMENT = 'DEPARTMENT',
  COMPANY = 'COMPANY',
  OFFICE_LOCATION = 'OFFICE_LOCATION',
  TEAM = 'TEAM',
}

export enum GoalStatus {
  OFF_TRACK = 'OFF_TRACK',
  PROGRESSING = 'PROGRESSING',
  ON_TRACK = 'ON_TRACK',
}

export interface GoalsOverview {
  user: User;
  goals: number;
  active: number;
  complete: number;
  objectives: number;
  completion: number;
}

export enum TagType {
  CATEGORY = 'CATEGORY',
  COMPANY_VALUE = 'COMPANY_VALUE',

  // TODO: Remove this once goals and competenices are merged and old competency tags are removed
  COMPETENCY = 'COMPETENCY',
}

export const GoalHelperFunctions = {
  getStatusTitle(goal: Goal) {
    // Archived
    if (goal.archived) {
      return 'Archived';
    }

    // Complete
    if (goal.complete === true) {
      return 'Complete';
    }

    // Off track
    if (goal.status === GoalStatus.OFF_TRACK) {
      return 'Off track';
    }

    // Progressing
    if (goal.status === GoalStatus.PROGRESSING) {
      return 'Progressing';
    }

    // On Track
    if (goal.status === GoalStatus.ON_TRACK) {
      return 'On track';
    }
  },

  getStatusHighlight(goal: Goal) {
    // Archived
    if (goal.archived) {
      return 'goal-status-highlight-archived';
    }

    // Complete
    if (goal.complete === true) {
      return 'goal-status-highlight-complete';
    }

    // Off track
    if (goal.status === GoalStatus.OFF_TRACK) {
      return 'goal-status-highlight-off-track';
    }

    // Progressing
    if (goal.status === GoalStatus.PROGRESSING) {
      return 'goal-status-highlight-progressing';
    }

    // On Track
    if (goal.status === GoalStatus.ON_TRACK) {
      return 'goal-status-highlight-on-track';
    }
  },
};
