import { EvaluationFeedback } from '@app/models/evaluation/evaluation-feedback.model';
import { mockEvaluationCycles } from '@app/mock/api/data/mockEvaluationCycles';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { mapEvaluationFeedbackAnswers } from '@app/mock/api/mock-helpers';

export const mockEvaluationFeedback: EvaluationFeedback[] = [
    {
        id: 1,
        evaluationCycleId: mockEvaluationCycles[0].id,
        sourceUserId: mockUsers[0].id,
        feedbackUserId: mockUsers[2].id,
        lastEditTimestamp: new Date(),
        completed: true,
        questionsAnswered: mapEvaluationFeedbackAnswers(mockEvaluationCycles[0].managerReviewQuestions)
    },
    {
        id: 2,
        evaluationCycleId: mockEvaluationCycles[1].id,
        sourceUserId: mockUsers[0].id,
        feedbackUserId: mockUsers[0].id,
        lastEditTimestamp: new Date(),
        completed: true,
        questionsAnswered: mapEvaluationFeedbackAnswers(mockEvaluationCycles[0].managerReviewQuestions)
    },
];