import { Injectable, NgZone } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SwalUtils } from '@app/shared/utils/swal.utils';
import { sanitizeUrl } from '@app/shared/utils/helpers';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private zone: NgZone) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        const url = req.url;
        const sanitizedUrl = sanitizeUrl(url); // Remove trailing slashes from URL

        // Check if route is not allowed to throw a generic error SWAL
        // Will need to add regex matching in future if the whitelisted routes need parameters
        const whitelistedRoutes: string[] = [
          'authuser',
          'api/connect/mentors/current-user',
          'api/connect/coaches/current-user',
          'api/email/click',
          'api/email/click/anonymous',
          'api/session/event',
          'api/customer-accounts/demo'
          // 'api/notifications/{notificationId}/click' create regex pattern for this
        ];

        if (!whitelistedRoutes.includes(sanitizedUrl)) {
          this.zone.run(() => SwalUtils.displayErrorSwal(error.error)).then();
        }

        return throwError(error);
      })
    );
  }
}