<div [ngClass]="columnIndent ? 'row' : ''">
  <div [ngClass]="columnIndent ? 'col-xs-10 col-xs-offset-1' : ''">
    <div class="p-relative">
      <div class="background-line"></div>
      <div class="row p-relative m-left-0 m-right-0 m-bottom-20" [class.display]="display === true" [class.enabled]="!disabled">
        <div (click)="select(1)" [class.likert-selected]="selection === 1" class="circle-container col-option">
          <div class="fill-1 circle">
            <div class="inner-circle">
              <span class="fal fa-check likert-icon" *ngIf="showIcons"></span>
            </div>
          </div>
          <div class="likert-label">Strongly Disagree</div>
        </div>
        <div (click)="select(2)" [class.likert-selected]="selection === 2" class="circle-container col-option">
          <div class="fill-2 circle">
            <div class="inner-circle">
              <span class="fal fa-check likert-icon" *ngIf="showIcons"></span>
            </div>
          </div>
          <div class="likert-label">Disagree</div>
        </div>
        <div (click)="select(3)" [class.likert-selected]="selection === 3" class="circle-container col-option">
          <div class="fill-3 circle">
            <div class="inner-circle">
              <span class="fal fa-check likert-icon" *ngIf="showIcons"></span>
            </div>
          </div>
          <div class="likert-label">Neutral</div>
        </div>
        <div (click)="select(4)" [class.likert-selected]="selection === 4" class="circle-container col-option">
          <div class="fill-4 circle">
            <div class="inner-circle">
              <span class="fal fa-check likert-icon" *ngIf="showIcons"></span>
            </div>
          </div>
          <div class="likert-label">Agree</div>
        </div>
        <div (click)="select(5)" [class.likert-selected]="selection === 5" class="circle-container col-option">
          <div class="fill-5 circle">
            <div class="inner-circle">
              <span class="fal fa-check likert-icon" *ngIf="showIcons"></span>
            </div>
          </div>
          <div class="likert-label">Strongly Agree</div>
        </div>
      </div>

      <!-- Display -->
      <!-- <div class="row" *ngIf="display === true">
        <div class="col-xs-4 col-xs-offset-4 text-center">
          <p>{{sentences[selection]}}</p>
        </div>
      </div> -->

      <ng-container *ngIf="commentEnabled">
        <!-- Comment Input -->
        <div class="row p-bottom-20 likert-comment" *ngIf="!display">
          <div class="col-xs-10 col-xs-offset-1">
            <div class="frankli-form-group frankli-form-group-filled" [class.frankli-form-group-invalid]="((commentMinLength && (comment?.length < commentMinLength!)) || (commentMaxLength && (comment?.length > commentMaxLength!)))">
              <textarea class="form-control" rows="3" [(ngModel)]="comment" (ngModelChange)="commentChanged($event)" [disabled]="disabled"></textarea>
              <ng-container  *ngIf="commentMaxLength !== null">
                <p class="character-count" [class.text-danger]="(commentMaxLength && (comment?.length > commentMaxLength))">{{comment?.length}} / {{commentMaxLength}}</p>
              </ng-container>
            </div>
          </div>
        </div>

        <!-- Comment Display -->
        <div class="row p-bottom-20" *ngIf="display">
          <div class="col-xs-10 col-xs-offset-1">
            <span>{{comment}}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
