import { CompanyFeature, CompanyFeatures } from '@app/models/company.model';

export const mockCompanyFeatures: CompanyFeature[] = [
    {
        id: 1,
        feature: CompanyFeatures.ANALYTICS,
        enabled: true
    },
    {
        id: 2,
        feature: CompanyFeatures.CONNECT,
        enabled: true
    },
    {
        id: 3,
        feature: CompanyFeatures.CONNECT_SOCIALISE,
        enabled: true
    },
    {
        id: 4,
        feature: CompanyFeatures.CONNECT_COACH_MENTOR,
        enabled: true
    },
    {
        id: 5,
        feature: CompanyFeatures.FEEDBACK,
        enabled: true
    },
    {
        id: 6,
        feature: CompanyFeatures.FEEDBACK_IDEAS,
        enabled: true
    },
    {
        id: 7,
        feature: CompanyFeatures.FEEDBACK_PEER,
        enabled: true
    },
    {
        id: 8,
        feature: CompanyFeatures.FEEDBACK_UNPROMPTED,
        enabled: true
    },
    {
        id: 9,
        feature: CompanyFeatures.GOALS,
        enabled: true
    },
    {
        id: 10,
        feature: CompanyFeatures.HELP,
        enabled: true
    },
    {
        id: 11,
        feature: CompanyFeatures.PEOPLE_DIRECTORY,
        enabled: true
    },
    {
        id: 12,
        feature: CompanyFeatures.SURVEYS,
        enabled: true
    },
    {
        id: 13,
        feature: CompanyFeatures.ORG_CHART,
        enabled: true
    },
    {
        id: 14,
        feature: CompanyFeatures.ORG_CHART_GOALS_INFO,
        enabled: true
    },
    {
        id: 15,
        feature: CompanyFeatures.ONE_TO_ONE,
        enabled: true
    },
    {
        id: 16,
        feature: CompanyFeatures.GOAL_PRIORITY,
        enabled: true
    },
    {
        id: 17,
        feature: CompanyFeatures.GOAL_TEMPLATE,
        enabled: true
    },
    {
        id: 18,
        feature: CompanyFeatures.GOAL_OFFICE_LOCATION,
        enabled: true
    },
    {
        id: 19,
        feature: CompanyFeatures.FEEDBACK_PEER,
        enabled: true
    },
    {
        id: 20,
        feature: CompanyFeatures.FEEDBACK_REQUESTS,
        enabled: true
    },
    {
        id: 21,
        feature: CompanyFeatures.EVALUATION_CYCLES,
        enabled: true
    },
    {
        id: 22,
        feature: CompanyFeatures.PEER_NOMINATIONS,
        enabled: true
    },
    {
        id: 23,
        feature: CompanyFeatures.COMPETENCIES,
        enabled: true
    },
    {
        id: 24,
        feature: CompanyFeatures.GRADES,
        enabled: false
    },
    {
        id: 25,
        feature: CompanyFeatures.TODOS,
        enabled: true
    },
    {
        id: 26,
        feature: CompanyFeatures.SSO,
        enabled: false
    },
    {
        id: 27,
        feature: CompanyFeatures.SSO_GOOGLE,
        enabled: false
    },
    {
        id: 28,
        feature: CompanyFeatures.SSO_MICROSOFT,
        enabled: false
    },
    {
        id: 29,
        feature: CompanyFeatures.ENPS,
        enabled: false // Can re-enable once we add an ENPS survey to the mock data
    },
    {
        id: 30,
        feature: CompanyFeatures.FEEDBACK_PRAISE,
        enabled: true
    },
    {
        id: 31,
        feature: CompanyFeatures.FEEDBACK_BANK,
        enabled: false
    },
    {
        id: 32,
        feature: CompanyFeatures.FEEDBACK_EXTERNAL_REQUESTS,
        enabled: true
    },
    {
        id: 33,
        feature: CompanyFeatures.PEER_FEEDBACK_MANAGER_ACCESS,
        enabled: true
    }
];