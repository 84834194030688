<div class="card">
  <div class="content p-bottom-0 p-top-0">
    <div class="row">
      <div class="col-xs-12">
        <h4 class="goal-activity-label">Goal Activity</h4>
      </div>
    </div>

    <div class="row m-bottom-30" *ngIf="goal.complete === false && goal.archived === false">
      <div class="col-xs-12 comment-area">

        <form [formGroup]="form" (ngSubmit)="addComment()">
          <div class="frankli-form-group" [class.frankli-form-group-filled]="comment.value.length > 0"
            [class.frankli-form-group-invalid]="form.invalid && submitted">
            <label for="comment">
              <i class="fal fa-comment"></i> Post a comment?
            </label>
            <div class="input-group w-100" *ngIf="commentLoading === false">
              <input class="form-control" [formControl]="comment" id="comment">
              <div class="input-group-addon clickable text-reset" (click)="resetInput()" *ngIf="comment.value.length > 0"><span class="fal fa-undo"></span></div>
            </div>
            <ng-container *ngIf="commentLoading === true">
              <div class="row">
                <div class="col-xs-12 text-center m-top-15">
                  Adding comment... <span class="fal fa-spin fa-spinner fa-fw"></span>
                </div>
              </div>
            </ng-container>
          </div>
          <div *ngIf="comment.value.length > 0">
            <small class="text-left">Press ENTER to post</small>
            <small class="text-right character-count" [class.text-danger]="comment.value.length > 255">{{comment.value.length}} / 255</small>
          </div>
        </form>

      </div>
    </div>

    <hr class="m-bottom-0" />
    <div class="activity-scrollable activity-margin-fix">
      <div class="m-right-30" *ngFor="let activity of goal.activity">
        <div class="row activity-row" *ngIf="commentDeleting !== activity.id">
          <div class="col-md-9 word-wrap">

            <ng-container
              *ngIf="activity.type !== eGoalActivityType.COMMENT && activity.type !== eGoalActivityType.PROGRESS_UPDATE && activity.type !== eGoalActivityType.STATUS_UPDATE && activity.type !== eGoalActivityType.KEY_RESULT && activity.type !== eGoalActivityType.EDIT">
              <span class="img-comment">
                <app-profile-photo
                [src]="activity.user.imageUrl"
                [routerLink]="'/profile/' + activity.user.id"
                ></app-profile-photo>
              </span>
              <strong class="clickable" [routerLink]="'/profile/' + activity.user.id"> {{activity.user.firstName}}
                {{activity.user.lastName}}
              </strong>
              <app-rich-display [text]="activity.message"></app-rich-display>
            </ng-container>

            <ng-container *ngIf="activity.type === eGoalActivityType.PROGRESS_UPDATE || activity.type === eGoalActivityType.STATUS_UPDATE">
              <span class="img-comment">
                <app-profile-photo
                [src]="activity.user.imageUrl"
                [routerLink]="'/profile/' + activity.user.id"
                ></app-profile-photo>
              </span>
              <strong class="clickable" [routerLink]="'/profile/' + activity.user.id"> {{activity.user.firstName}}
                {{activity.user.lastName}}
              </strong>
              made a progress update
              <ng-container *ngIf="activity.message.length > 0">
                <p class="comment m-top-15">Comment:
                  <app-rich-display [text]="activity.message"></app-rich-display>
                </p>
              </ng-container>

              <ng-container *ngIf="activity.progressUpdate!.status !== null">
                <p class="comment m-top-30">
                  Updated the status from
                  <strong>{{activity.progressUpdate!.status!.previous.replace('_',' ')}}</strong> to
                  <strong>{{activity.progressUpdate!.status!.current.replace('_',' ')}}</strong>
                </p>
              </ng-container>

              <!-- Key result updated -->
              <ng-container *ngFor="let keyResult of activity.progressUpdate.keyResults">

                <p class="comment m-top-30">
                  <strong>
                    {{keyResult.name}}
                  </strong>
                </p>

                <!-- Current Value -->
                <ng-container
                  *ngIf="keyResult.currentValueCurrent !== keyResult.currentValuePrevious">
                  <p class="comment">
                    Updated the current value from
                    <ng-container *ngIf="keyResult.type === eGoalKeyResultType.BINARY">
                      <strong>{{ (keyResult.currentValuePrevious === 0) ? 'Complete' : 'Incomplete' }}</strong>
                      to
                      <strong>{{ (keyResult.currentValueCurrent === 0) ? 'Complete' : 'Incomplete' }}</strong>
                    </ng-container>
                    <ng-container *ngIf="keyResult.type === eGoalKeyResultType.NUMERIC">
                      <strong>
                        {{keyResult.measureUnitPlacement === eGoalKeyResultMeasureUnitPlacement.BEFORE ? keyResult.measureUnit : ''}}
                        {{keyResult.currentValuePrevious}}
                        {{keyResult.measureUnitPlacement === eGoalKeyResultMeasureUnitPlacement.AFTER ? keyResult.measureUnit : ''}}
                      </strong>
                      to
                      <strong>
                        {{keyResult.measureUnitPlacement === eGoalKeyResultMeasureUnitPlacement.BEFORE ? keyResult.measureUnit : ''}}
                        {{keyResult.currentValueCurrent}}
                        {{keyResult.measureUnitPlacement === eGoalKeyResultMeasureUnitPlacement.AFTER ? keyResult.measureUnit : ''}}
                      </strong>
                    </ng-container>
                  </p>
                </ng-container>

              </ng-container>
            </ng-container>

            <ng-container *ngIf="activity.type === eGoalActivityType.COMMENT">
              <span class="img-comment">
                <app-profile-photo
                [src]="activity.user.imageUrl"
                [routerLink]="'/profile/' + activity.user.id"
                ></app-profile-photo>
              </span>
              <strong class="clickable" [routerLink]="'/profile/' + activity.user.id">{{activity.user.firstName}}
                {{activity.user.lastName}}</strong>
              commented:
              <br />
              <div class="comment">
                <app-rich-display [text]="activity.message"></app-rich-display>
              </div>
            </ng-container>

            <ng-container *ngIf="activity.type === eGoalActivityType.KEY_RESULT">
              <span class="img-comment">
                <app-profile-photo
                [src]="activity.user.imageUrl"
                [routerLink]="'/profile/' + activity.user.id"
                ></app-profile-photo>
              </span>
              <strong class="clickable" [routerLink]="'/profile/' + activity.user.id">{{activity.user.firstName}}
                {{activity.user.lastName}}</strong>
              made a progress update
              <br />
              <div class="comment">
                <app-rich-display [text]="activity.message"></app-rich-display>
              </div>
            </ng-container>

            <ng-container *ngIf="activity.type === eGoalActivityType.EDIT">
              <span class="img-comment">
                <app-profile-photo
                [src]="activity.user.imageUrl"
                [routerLink]="'/profile/' + activity.user.id"
                ></app-profile-photo>
              </span>
              <strong class="clickable" [routerLink]="'/profile/' + activity.user.id"> {{activity.user.firstName}}
                {{activity.user.lastName}}
              </strong>
              <app-rich-display [text]="activity.message"></app-rich-display>
              <ng-container *ngIf="activity.editUpdate !== null">
                <!-- Title -->
                <ng-container *ngIf="activity.editUpdate!.titleCurrent !== null">
                  <p class="comment m-top-30">
                    Changed the title from
                    <strong>{{activity.editUpdate!.titlePrevious}}</strong> to
                    <strong>{{activity.editUpdate!.titleCurrent}}</strong>
                  </p>
                </ng-container>

                <!-- Type -->
                <ng-container *ngIf="activity.editUpdate!.typeCurrent !== null">
                  <p class="comment m-top-30">
                    Changed the type from
                    <strong>{{activity.editUpdate!.typePrevious!.toString().replace('_',' ')}}</strong> to
                    <strong>{{activity.editUpdate!.typeCurrent!.toString().replace('_',' ')}}</strong>
                  </p>
                </ng-container>

                <!-- End Date -->
                <ng-container *ngIf="activity.editUpdate!.endDateCurrent !== null">
                  <p class="comment m-top-30">
                    Changed the end date from
                    <strong>{{activity.editUpdate!.endDatePrevious | date:'d MMMM y'}}</strong> to
                    <strong>{{activity.editUpdate!.endDateCurrent | date:'d MMMM y'}}</strong>
                  </p>
                </ng-container>

                <!-- Visibility -->
                <ng-container *ngIf="activity.editUpdate!.visibilityCurrent !== null">
                  <p class="comment m-top-30">
                    Changed the visibility from
                    <strong>{{activity.editUpdate!.visibilityPrevious}}</strong> to
                    <strong>{{activity.editUpdate!.visibilityCurrent}}</strong>
                  </p>
                </ng-container>

                <!-- Priority -->
                <ng-container *ngIf="activity.editUpdate!.priorityCurrent !== null">
                  <p class="comment m-top-30">
                    Changed the priority from
                    <strong>{{activity.editUpdate!.priorityPrevious!.toString().replace('_',' ')}}</strong> to
                    <strong>{{activity.editUpdate!.priorityCurrent!.toString().replace('_',' ')}}</strong>
                  </p>
                </ng-container>

                <!-- Added Owners -->
                <ng-container *ngIf="activity.editUpdate!.addedOwners.length > 0">
                  <p class="comment m-top-30">
                    Added as a goal owner
                    <ng-container *ngFor="let owner of activity.editUpdate!.addedOwners">
                      <span class="img-comment">
                        <app-profile-photo
                        [src]="owner.imageUrl"
                        [routerLink]="'/profile/' + owner.id"
                        ></app-profile-photo>
                      </span>
                      <strong class="clickable" [routerLink]="'/profile/' + owner.id"> {{owner.firstName}}
                        {{owner.lastName}}
                      </strong>
                    </ng-container>
                  </p>
                </ng-container>

                <!-- Removed Owners -->
                <ng-container *ngIf="activity.editUpdate!.removedOwners.length > 0">
                  <p class="comment m-top-30">
                    Removed as a goal owner
                    <ng-container *ngFor="let owner of activity.editUpdate!.removedOwners">
                      <span class="img-comment">
                        <app-profile-photo
                        [src]="owner.imageUrl"
                        [routerLink]="'/profile/' + owner.id"
                        ></app-profile-photo>
                      </span>
                      <strong class="clickable" [routerLink]="'/profile/' + owner.id"> {{owner.firstName}}
                        {{owner.lastName}}
                      </strong>
                    </ng-container>
                  </p>
                </ng-container>

                <!-- Results Added -->
                <ng-container *ngIf="activity.editUpdate!.keyResultsAdded.length > 0">
                  <p class="comment m-top-30">
                    Results Added
                    <ng-container *ngFor="let result of activity.editUpdate!.keyResultsAdded">
                      <br />
                      <strong>{{result}}</strong>
                    </ng-container>
                  </p>
                </ng-container>

                <!-- Results Removed -->
                <ng-container *ngIf="activity.editUpdate!.keyResultsRemoved.length > 0">
                  <p class="comment m-top-30">
                    Results Removed
                    <ng-container *ngFor="let result of activity.editUpdate!.keyResultsRemoved">
                      <br />
                      <strong>{{result}}</strong>
                    </ng-container>
                  </p>
                </ng-container>

                <!-- Results Updated -->
                <ng-container *ngIf="activity.editUpdate!.keyResultsUpdated.length > 0">
                  <p class="comment m-top-30" *ngFor="let result of activity.editUpdate!.keyResultsUpdated">
                    <ng-container *ngIf="result.resultPrevious !== null">
                      <br />
                      Changed the result from
                      <strong>{{result.resultPrevious}}</strong> to <strong>{{result.resultCurrent}}</strong>
                    </ng-container>

                    <!-- TODO: Add this back later
                      <ng-container *ngIf="result.measureTypePrevious !== null">
                        <br />
                        Changed the measure type from
                        <strong>{{result.measureTypePrevious}}</strong> to <strong>{{result.measureTypeCurrent}}</strong>
                      </ng-container>
                    -->
                    <ng-container *ngIf="result.measureCurrentValuePrevious !== null">
                      <br />
                      Changed the current value from
                      <strong>{{result.measureCurrentValuePrevious}}</strong> to
                      <strong>{{result.measureCurrentValueCurrent}}</strong>
                    </ng-container>
                    <ng-container *ngIf="result.measureGoalValuePrevious !== null">
                      <br />
                      Changed the goal value from
                      <strong>{{result.measureGoalValuePrevious}}</strong> to
                      <strong>{{result.measureGoalValueCurrent}}</strong>
                    </ng-container>
                    <ng-container *ngIf="result.endDatePrevious !== null && result.endDateCurrent !== null">
                      <br />
                      Changed the end date from
                      <strong>{{(result.endDatePrevious) === null ? 'N/A': result.endDatePrevious | date:'d MMMM y'}}</strong>
                      to
                      <strong>{{(result.endDatePrevious) === null ? 'N/A': result.endDatePrevious | date:'d MMMM y'}}</strong>
                    </ng-container>
                  </p>
                </ng-container>

              </ng-container>
            </ng-container>

          </div>
          <div class="col-md-3 text-right">
            <div class="row">
              <div
                [class.col-md-10]='(activity.type === eGoalActivityType.COMMENT && activity.user.id === globals.user.id)'
                [class.col-md-12]='!(activity.type === eGoalActivityType.COMMENT && activity.user.id === globals.user.id)'>
                {{activity.date | date:'hh:mm aaaaa\'m\''}}
                <br /> {{activity.date | date:'d MMMM y'}}
              </div>
              <div class="col-md-2 m-top-10">
                <span *ngIf="(activity.type === eGoalActivityType.COMMENT && activity.user.id === globals.user.id)"
                  class="fal fa-times frankli-close expand-on-hover" (click)="deleteComment(activity.id)"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="row activity-row" *ngIf="commentDeleting === activity.id">
          <div class="col-xs-12 text-center">
            Deleting comment... <span class="fal fa-spin fa-spinner fa-fw"></span>
          </div>
        </div>
        <hr />

      </div>
    </div>

  </div>
</div>