<!-- Form -->
<form [formGroup]="passwordChangeForm" (ngSubmit)="change()">

  <!-- new password -->
  <div [class.frankli-form-group-invalid]='newPassword.invalid && submitted'
    [class.frankli-form-group-filled]='newPassword.value.length > 0' class="frankli-form-group">
    <label for="newPassword">New password</label>
    <input type="password" id="newPassword" name="newPassword" class="form-control" placeholder="New password"
      [formControl]="newPassword">
    <div class="m-top-5 frankli-form-group-alert">
      <ng-container *ngIf="newPassword.invalid && submitted">
        <div *ngIf="newPassword.hasError('required')">
          New password is required.
        </div>
        <div *ngIf="newPassword.hasError('minlength')">
          Password must be at least {{minimumPasswordLength}} characters long.
        </div>
        <div *ngIf="newPassword.hasError('maxlength')">
          Password cannot be more than {{maximumPasswordLength}} characters long.
        </div>
      </ng-container>
    </div>
  </div>

  <!-- confirm password -->
  <div [class.frankli-form-group-invalid]='confirmPassword.invalid && submitted'
    [class.frankli-form-group-filled]='confirmPassword.value.length > 0' class="frankli-form-group">
    <label for="ConfirmPassword">Confirm new password</label>
    <input type="password" id="ConfirmPassword" name="confirmPassword" class="form-control"
      placeholder="Confirm new password" [formControl]="confirmPassword">
    <div class="m-top-5 frankli-form-group-alert">
      <ng-container *ngIf="confirmPassword.invalid && submitted">
        <div *ngIf="confirmPassword.hasError('required')">
          Confirm password is required.
        </div>
        <div *ngIf="confirmPassword.hasError('minlength')">
          Password must be at least {{minimumPasswordLength}} characters long.
        </div>
        <div *ngIf="confirmPassword.hasError('maxlength')">
          Password cannot be more than {{maximumPasswordLength}} characters long.
        </div>
      </ng-container>
    </div>
  </div>

  <!-- error -->
  <div *ngIf="passwordChangeError" class="frankli-form-group-alert m-top-5">
    <div>
      {{passwordChangeErrorMessage}}
    </div>
  </div>

  <!-- submit -->
  <div class="row m-top-15 text-center">
    <button type="submit" class="btn-frankli-round btn-frankli-green">Confirm password</button>
  </div>

</form>