import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// TODO:
// - Add datepicker component
// - Add timepicker component
// - Sandwich the two together
// - Add all the NG_VALUE_ACCESSOR stuff
@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DateTimePickerComponent),
    multi: true,
  }],
})
export class DateTimePickerComponent implements ControlValueAccessor, OnInit {

  dateValue: Date;
  timeValue: Date;

  onChange = (_: any) => {};
  onTouched = () => {};

  constructor() { }

  ngOnInit() {
  }

  writeValue() {
    // TODO: Set value without callback
  }

  // #region - CALLBACK REGISTRATION
  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  // #endregion
}
