import { Component, Input, OnInit } from '@angular/core';
import { IdeaEffect } from '@app/models/feedback/idea-effect.model';
import { IdeaItem } from '@app/models/feedback/idea.model';
import { User } from '@app/models/user/user.model';
import { UserAPIService } from '@app/shared/api/user.api.service';

@Component({
  selector: 'app-feedback-idea-display',
  templateUrl: './feedback-idea-display.component.html',
  styleUrls: ['./feedback-idea-display.component.css']
})
export class FeedbackIdeaDisplayComponent implements OnInit {
  @Input() idea: IdeaItem;

  @Input() showSourceUser: boolean;
  @Input() showDestinationUser: boolean;

  sourceUser?: User;
  destinationUser?: User;

  eIdeaEffect = IdeaEffect;

  constructor(
    private userAPIService: UserAPIService
  ) {
    this.idea = undefined!;
    this.destinationUser = undefined;

    this.showSourceUser = true;
    this.showDestinationUser = true;
  }

  ngOnInit() {
    if (this.showSourceUser) {
      this.getSourceUser();
    }
    if (this.showDestinationUser) {
      this.getDestinationUser();
    }
  }

  getSourceUser() {
    if (this.idea.sourceUserId) {
      this.userAPIService.getById(this.idea.sourceUserId).subscribe(
        (user) => {
          this.sourceUser = user;
        }
      );
    }
  }

  getDestinationUser() {
    this.userAPIService.getById(this.idea.destinationUserId).subscribe(
      (user) => {
        this.destinationUser = user;
      }
    );
  }

}
