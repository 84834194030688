import { Position } from '@app/models/position/position.model';
import { mockCompetencies } from '@app/mock/api/data/mockCompetencies';
import { deepClone } from '@app/shared/utils/helpers';

export const mockPositions: Position[] = [
    {
        id: 1,
        name: "CEO",
        grade: "",
        competencies: deepClone(mockCompetencies)
    },
    {
        id: 2,
        name: "COO",
        grade: "",
        competencies: deepClone(mockCompetencies)
    },
    {
        id: 3,
        name: "Head of People",
        grade: "",
        competencies: deepClone(mockCompetencies)
    },
    {
        id: 4,
        name: "Head of Product",
        grade: "",
        competencies: deepClone(mockCompetencies)
    },
    {
        id: 5,
        name: "Head of Finance",
        grade: "",
        competencies: deepClone(mockCompetencies)
    },
    {
        id: 6,
        name: "Head of Insights",
        grade: "",
        competencies: deepClone(mockCompetencies)
    },
    {
        id: 7,
        name: "Head of Operations",
        grade: "",
        competencies: deepClone(mockCompetencies)
    },
    {
        id: 8,
        name: "Human Resource Manager",
        grade: "",
        competencies: deepClone(mockCompetencies)
    },
    {
        id: 9,
        name: "Team Manager",
        grade: "",
        competencies: deepClone(mockCompetencies)
    },
    {
        id: 10,
        name: "Frontend Developer",
        grade: "",
        competencies: deepClone(mockCompetencies)
    },
    {
        id: 11,
        name: "Backend Developer",
        grade: "",
        competencies: deepClone(mockCompetencies)
    },
    {
        id: 12,
        name: "UX Designer",
        grade: "",
        competencies: deepClone(mockCompetencies)
    },
    {
        id: 13,
        name: "Web Designer",
        grade: "",
        competencies: deepClone(mockCompetencies)
    },
    {
        id: 14,
        name: "Area Manager",
        grade: "",
        competencies: deepClone(mockCompetencies)
    },
    {
        id: 15,
        name: "Android Developer",
        grade: "",
        competencies: deepClone(mockCompetencies)
    },
    {
        id: 16,
        name: "iOS Developer",
        grade: "",
        competencies: deepClone(mockCompetencies)
    },
    {
        id: 17,
        name: "Support Staff",
        grade: "",
        competencies: deepClone(mockCompetencies)
    }
];