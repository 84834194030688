// TODO: Possibly add icon, background colour, etc depending on styling requirements

export interface FilterOption {
  id: number;
  properties: {
    [name: string]: FilterValue | undefined;
  },
  nestedItems?: FilterOption[];
}

export interface FilterValue {
  value: any;
  filterMethod?: FilterMethod;
}

export enum FilterMethod {
  AND = 'AND',
  OR = 'OR'
}

export const FilterCreation = {
  getRangeFilter(count: number) {
    if (count > 100) {
      return '100+';
    }

    if (count > 50) {
      return '51-100';
    }

    if (count > 20) {
      return '21-50';
    }

    if (count > 10) {
      return '11-20';
    }

    if (count > 5) {
      return '6-10'
    }

    if (count > 0) {
      return '1-5'
    }

    return 'None';
  },

  getPercentRangeFilter(progress: number) {
    if (progress > 80) {
      return '81-100%';
    }

    if (progress > 60) {
      return '61-80%';
    }

    if (progress > 40) {
      return '41-60%';
    }

    if (progress > 20) {
      return '21-40%'
    }

    if (progress > 0) {
      return '1-20%'
    }

    return '0%';
  }
}
