import { Component } from '@angular/core';
import { GoalCreateService } from '../goals-create/goals-create.service';

@Component({
  selector: 'app-goal-modal',
  templateUrl: './goals-modal.component.html',
  styleUrls: ['./goals-modal.css']
})
export class GoalsModalComponent {

  public visible = false;
  public visibleAnimate = false;
  public sidebarCollapsed = false;

  constructor(private goalCreateService: GoalCreateService) { }

  public show(): void {
    this.visible = true;
    setTimeout(() => this.visibleAnimate = true, 100);
  }

  public hide(): void {
    this.visibleAnimate = false;
    this.goalCreateService.sendReset();
    setTimeout(() => this.visible = false, 300);
  }

  public onContainerClicked(event: MouseEvent): void {
    if ((<HTMLElement>event.target).classList.contains('modal')) {
      this.hide();
    }
  }

  public toggleSidebar() {
    this.sidebarCollapsed = !this.sidebarCollapsed;
  }
}
