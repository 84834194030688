import { Component, Input, ViewChild } from '@angular/core';
import { GoalsModalComponent } from '@app/goals/goals-components/goals-modal/goals-modal.component';
import { CreateInterestDto, InterestCategory } from '@app/models/interest.model';
import { HttpErrorResponse } from '@angular/common/http';
import { InterestAPIService } from '@app/shared/api/interest.api.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { TitleCasePipe } from '@angular/common';
import { Globals } from '@app/shared/globals/globals';
import { RoleName } from '@app/models/role.model';

declare var $: any;

enum PageConfig {
  NONE = 'NONE',
  NEW = 'NEW',
  EXISTING = 'EXISTING'
}

@Component({
  selector: 'app-create-interest-modal',
  templateUrl: './create-interest-modal.component.html',
  styleUrls: ['./create-interest-modal.component.css'],
  providers: [
    TitleCasePipe
  ]
})
export class CreateInterestModalComponent {
  @Input() categories: InterestCategory[];
  @Input() step!: 'INTERESTS_PERSONAL' | 'INTERESTS_PROFESSIONAL';
  @ViewChild('modal') modal!: GoalsModalComponent;

  type: string;
  createForm!: FormGroup;
  createInterestCategory!: FormControl;
  createInterestName!: FormControl;
  categoryForm!: FormGroup;
  categoryName!: FormControl;
  isNotAnAdmin: boolean;
  state: {
    loading: boolean,
    error: boolean,
    submitted: boolean,
    creating: boolean,
    category: {
      submitted: boolean,
      loading: boolean
      alreadyExists: boolean
    }
  }

  ePageConfig = PageConfig;
  configSelected: PageConfig;

  private static buildCategoryForm(formBuilder: FormBuilder): FormGroup {
    return formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
    });
  }

  constructor(private interestAPIService: InterestAPIService, private formBuilder: FormBuilder, private titleCase: TitleCasePipe, private globals: Globals) {
    this.type = '';
    this.categories = [];
    this.state = {
      loading: true,
      error: false,
      submitted: false,
      creating: false,
      category: {
        submitted: false,
        loading: false,
        alreadyExists: false
      }
    }
    this.isNotAnAdmin = !this.globals.hasRole(RoleName.ADMIN) && !this.globals.hasRole(RoleName.FRANKLI_ADMIN);

    this.configSelected = PageConfig.NONE;
  }

  show() {
    this.type = this.step === 'INTERESTS_PERSONAL' ? 'Personal' : 'Professional';
    this.modal.show();

    // create form
    this.createForm = this.buildCreateForm(this.formBuilder);
    this.createInterestName = this.createForm.controls.name as FormControl;
    this.createInterestCategory = this.createForm.controls.category as FormControl;

    // category form
    this.categoryForm = CreateInterestModalComponent.buildCategoryForm(this.formBuilder);
    this.categoryName = this.categoryForm.controls.name as FormControl;

    if (this.categories.length === 0) {
      this.configSelected = PageConfig.NEW;
    }

    this.state.loading = false;
  }

  private buildCreateForm(formBuilder: FormBuilder): FormGroup {
    return formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      type: [this.type, [Validators.required]],
      category: [null, [Validators.required]],
    });
  }

  private resetCreateForm(): void {
    this.state.submitted = false;
    this.createInterestName.setValue('');
    this.createInterestCategory.setValue(null);
  }

  public submitCreateForm() {
    this.state.creating = true;
    this.state.submitted = true;
    if (this.createForm.valid) {
      const category = this.createInterestCategory.value;
      const createInterestDto = new CreateInterestDto(
          this.type,
          this.titleCase.transform(this.createInterestName.value),
          this.titleCase.transform(category.name)
      );
      this.interestAPIService.createInterest(createInterestDto).subscribe(interest => {
        this.resetCreateForm();
        this.state.creating = false;
        if (this.isNotAnAdmin) {
          $.notify('Interest submitted for admin approval');
        } else {
          const idx = this.categories.map(c => c.name).indexOf(interest.category);
          this.categories[idx].interests.push(interest);
          $.notify('Interest successfully added');
        }
        this.modal.hide();
      }, (_error: HttpErrorResponse) => {
        this.state.creating = false;
      });
    } else {
      this.state.creating = false;
    }
  }

  private resetCategoryForm(): void {
    this.state.category.submitted = false;
    this.state.category.alreadyExists = false;
    this.categoryName.setValue('');
  }

  checkCategoryNameExists(name: string) {
    const parseText = (text: string) => text.trim().toLowerCase();
    return this.categories.map(c => parseText(c.name)).includes(parseText(name));
  }

  public submitCategoryForm() {
    this.state.category.submitted = true;
    this.state.category.loading = true;
    this.state.category.alreadyExists = false;

    if (this.categoryForm.valid) {
      const categoryNameAlreadyExists = this.checkCategoryNameExists(this.categoryName.value)

      if (categoryNameAlreadyExists) {
        this.state.category.alreadyExists = true;
        return;
      }

      const newCategory = {
        name: this.titleCase.transform(this.categoryName.value),
        icon: '',
        interests: []
      };
      this.categories.push(newCategory);
      this.categories = this.categories.sort((a, b) => a.name.localeCompare(b.name));
      this.createInterestCategory.setValue(newCategory);

      this.state.category.loading = false;

      this.resetCategoryForm();

      $.notify(`Category ${newCategory.name} successfully added`);
      this.configSelected = PageConfig.EXISTING;
    } else {
      this.state.category.loading = false;
    }
  }

  selectConfig(config: PageConfig) {
    this.configSelected = config;
  }

  onModalClosed() {
    this.configSelected = PageConfig.NONE;
  }

  cancelCreation() {
    this.selectConfig(PageConfig.NONE);
    this.resetCategoryForm();
    this.resetCreateForm();
  }
}
