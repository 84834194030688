import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

declare var $: any;

export interface DateRangePickerValue {
  start: (Date | moment.Moment),
  end: (Date | moment.Moment),
  label: string
}

// TODO: https://www.daterangepicker.com/#config
// - Add all remaining locale options
@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DateRangePickerComponent,
    multi: true,
  }]
})
export class DateRangePickerComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('dateRangePicker') dateRangePicker?: ElementRef;

  @Input() disabled: boolean;
  @Input() showDropdowns: boolean;
  @Input() showWeekNumbers: boolean;
  @Input() showISOWeekNumbers: boolean;
  @Input() singleDatePicker: boolean;
  @Input() timePicker: boolean;
  @Input() timePicker24Hour: boolean;
  @Input() timePickerSeconds: boolean;
  @Input() autoApply: boolean;
  @Input() alwaysShowCalendars: boolean;
  @Input() showCustomRangeLabel: boolean;
  @Input() autoUpdateInput: boolean;
  @Input() linkedCalendars: boolean;

  @Input() timePickerIncrement: number;

  @Input() name: string;
  @Input() opens: 'left' | 'right' | 'center';
  @Input() drops: 'down' | 'up' | 'auto';
  @Input() format: string;
  @Input() placeholder: string;

  @Input() minDate: (moment.Moment | Date);
  @Input() maxDate: (moment.Moment | Date);
  @Input() ranges: { [rangeName: string]: (Array<Date> | Array<moment.Moment>)};
  @Input() maxSpan: {
    days?: number,
    months?: number,
    years?: number
  };

  @Input() openPickerEvent?: EventEmitter<boolean>;

  @Input() dateInvalidFunction: (date: Date) => boolean; // Return true for whenever date is invalid

  _value: DateRangePickerValue;
  onChange = (_: any) => {};
  onTouched = () => {};


  constructor() {
    this.name = 'daterangepicker';
    this.opens = 'left';
    this.drops = 'auto';
    this.format = 'DD/MM/YYYY';
    this.placeholder = '';

    this.disabled = false;
    this.showDropdowns = false;
    this.showWeekNumbers = false;
    this.showISOWeekNumbers = false;
    this.singleDatePicker = false;
    this.timePicker = false;
    this.timePicker24Hour = false;
    this.timePickerSeconds = false;
    this.autoApply = false;
    this.alwaysShowCalendars = false;
    this.showCustomRangeLabel = true;
    this.autoUpdateInput = true;
    this.linkedCalendars = true;

    this.timePickerIncrement = 1;

    this.minDate = moment().startOf('month').subtract(2, 'year');
    this.maxDate = moment().endOf('day').add(2, 'year');

    this._value = {
      start: moment().startOf('day').subtract(3, 'months'),
      end: moment().startOf('day'),
      label: 'Default'
    };

    this.ranges = {
      'Last Month': [moment().startOf('day').subtract(1, 'month').startOf('month'), moment().startOf('day')],
      'Last 3 Months': [moment().startOf('day').subtract(3, 'months').startOf('month'), moment().startOf('day')],
      'Last 6 Months': [moment().startOf('day').subtract(6, 'months').startOf('month'), moment().startOf('day')],
      'Last Year': [moment().startOf('day').subtract(1, 'year').startOf('month'), moment().startOf('day')]
    };
    this.maxSpan = {};

    this.dateInvalidFunction = (date: Date) => {
      return false;
    };
  }

  // #region - LIFECYCLE HOOKS
  ngOnInit() {
    if (this.openPickerEvent) {
      this.openPickerEvent.subscribe((res: boolean) => this.openPicker())
    }
  }

  ngOnDestroy() {
    if (this.dateRangePicker && $(this.dateRangePicker.nativeElement).data('daterangepicker')) {
      $(this.dateRangePicker.nativeElement).data('daterangepicker').remove();
    }
  }

  ngAfterViewInit() {
    this.initPicker()
  }
  // #endregion

  // #region - PICKER INIT
  initPicker() {
    if (this.dateRangePicker) {
      const picker = $(this.dateRangePicker.nativeElement);
      const comp = this;

      // TODO: More props to add
      // - minYear: number
      // - maxYear: number
      picker.daterangepicker({
        opens: this.opens,
        drops: this.drops,
        startDate: moment(this.value.start),
        endDate: moment(this.value.end),
        minDate: moment(this.minDate),
        maxDate: moment(this.maxDate),
        showDropdowns: this.showDropdowns,
        showWeekNumbers: this.showWeekNumbers,
        showISOWeekNumbers: this.showISOWeekNumbers,
        singleDatePicker: this.singleDatePicker,
        timePicker: this.timePicker,
        timePicker24Hour: this.timePicker24Hour,
        timePickerSeconds: this.timePickerSeconds,
        timePickerIncrement: this.timePickerIncrement,
        alwaysShowCalendars: this.alwaysShowCalendars,
        showCustomRangeLabel: this.showCustomRangeLabel,
        linkedCalendars: this.linkedCalendars,
        autoUpdateInput: this.autoUpdateInput,
        autoApply: this.autoApply,
        maxSpan: this.maxSpan,
        ranges: this.ranges,
        buttonClasses: 'btn btn-frankli-round',
        applyButtonClasses: 'btn-frankli-green',
        cancelButtonClasses: 'btn-frankli-gray-inverted',
        locale: {
          format: this.format
        },
        isInvalidDate: (date: Date) => this.dateInvalidFunction(date),
      }, (start: moment.Moment, end: moment.Moment, label: string) => {
        this.value = {
          start: start.toDate(),
          end: end.toDate(),
          label: label
        };
      });
    }
  }
  // #endregion

  get value(): DateRangePickerValue {
    return this._value;
  }

  set value(val: DateRangePickerValue) {
    if (!this.disabled && this._value !== val) {
      this._value = val;
      this.onChange(val);
    }
  }

  // Set value with emit - TODO: Is this used?
  setValue(val: any) {
    this.value = val;
  }

  // Set value without emitting events
  writeValue(value: any) {
    this._value = value;
  }

  // Register callbacks
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;

    // TODO: Disable picker
  }

  openPicker() {
    if (this.dateRangePicker && this.dateRangePicker.nativeElement) {
      const comp = $(this.dateRangePicker.nativeElement)
      if (comp.data('daterangepicker')) {
        $(this.dateRangePicker.nativeElement).data('daterangepicker').toggle();
      }
    }
  }
}
