import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserAPIService } from '@app/shared/api/user.api.service';
import { ActivatedRoute } from '@angular/router';
import { Globals } from '@app/shared/globals/globals';
import { HttpErrorResponse } from '@angular/common/http';
import { BreadcrumbService } from 'app/shared/breadcrumbs/breadcrumbs.service';
import { Breadcrumb } from 'app/models/breadcrumb.model';
import { CompanyFeatures, OfficeLocation } from 'app/models/company.model';
import { RoleName } from 'app/models/role.model';
import { CompanyAPIService } from '@app/shared/api/company.api.service';
import { forkJoin } from 'rxjs';
import { OrganisationalUnit } from '@app/models/organisational-unit.model';
import { FormControl, Validators } from '@angular/forms';
import { UserMinimal, UserMinimalSort } from '@app/models/user/user-minimal.model';
import { PositionAPIService } from '@app/shared/api/position.api.service';
import { Position } from '@app/models/position/position.model';
import { CompanyWording } from '@app/models/company/company-wording/company-wording.model';
import { PaginationNewComponent } from '@app/shared/pagination/pagination-new/pagination-new.component';

// #region - INTERFACES, TYPES, ENUMS
interface PageState {
  loading: boolean;
  error: boolean;
  errorMessage: string;
}

interface PageDepartment extends OrganisationalUnit {
  peopleCount: number;
}

interface PageSite extends OfficeLocation {
  peopleCount: number;
}

interface PageUser extends UserMinimal {
  imgTitle: string;
  cardColor: string;
  name: string;
  peopleCount: number;
  organisationalUnit: OrganisationalUnit;
  officeLocation: OfficeLocation;
  position: Position;
}

interface PageFilters {
  search: FormControl,
  sitesSearch: FormControl,
  departmentsSearch: FormControl,
  managersSearch: FormControl,
  sites: {
    [siteId: number]: boolean;
  },
  departments: {
    [deptId: number]: boolean;
  },
  managers: {
    [deptId: number]: boolean;
  },
  admins: boolean
}
// #endregion
@Component({
  selector: 'app-people-directory',
  templateUrl: './people-directory.component.html',
  styleUrls: ['./people-directory.component.css'],
})
export class PeopleDirectoryComponent implements OnInit, OnDestroy {
  @ViewChild('pagination') pagination?: PaginationNewComponent;
  state: PageState;

  eFeatures = CompanyFeatures;
  users: PageUser[];
  usersFiltered: PageUser[];
  usersDisplay: PageUser[];

  pageNumber = 0;
  pageSize = 50;

  departments: PageDepartment[];
  sites: PageSite[];
  positions: Position[];
  managers: PageUser[];
  myTeams: {
    department?: PageDepartment;
    site?: PageSite;
    manager?: PageUser;
    managerMe?: PageUser;
  }

  canExport: boolean;

  breadcrumb: Breadcrumb;

  sidebarOpen: boolean;

  filters: PageFilters;

  // colours: string[] = ['#54C6BB', '#FD384E', '#FFEA83', '#893f90'];
  colours: {
    ADMIN: string,
    MANAGER: string,
    USER: string
  } = {
    ADMIN: '#893f90', // Purple for admin
    MANAGER: '#567fc0', // Blue for managers
    USER: '#73c3bb' // Teal for users
  }

  adminIDs: number[];

  companyWording: CompanyWording;

  categoryExpanded: {
    [title: string]: boolean;
  }

  constructor(
    private userAPIService: UserAPIService,
    public globals: Globals,
    public route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private companyAPIService: CompanyAPIService,
    private positionAPIService: PositionAPIService
  ) {
    this.sidebarOpen = true;
    this.canExport = false;

    this.sites = [];
    this.departments = [];
    this.managers = [];
    this.users = [];
    this.usersFiltered = [];
    this.usersDisplay = [];
    this.adminIDs = [];
    this.positions = [];

    this.myTeams = {
      department: undefined,
      site: undefined,
      manager: undefined,
      managerMe: undefined
    }

    this.filters = {
      search: new FormControl('', [Validators.required]),
      sitesSearch: new FormControl('', [Validators.required]),
      departmentsSearch: new FormControl('', [Validators.required]),
      managersSearch: new FormControl('', [Validators.required]),
      sites: {},
      departments: {},
      managers: {},
      admins: false
    }

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    }

    this.breadcrumb = this.breadcrumbService.init(this.route);
    if (this.globals.user.roles.some(role => role.name === RoleName.ADMIN || role.name === RoleName.FRANKLI_ADMIN)) {
      this.canExport = true;
    }

    this.companyWording = this.globals.company.companyWording;

    this.categoryExpanded = {};
    this.categoryExpanded[this.companyWording.departmentPlural] = false;
    this.categoryExpanded['Sites'] = false;
    this.categoryExpanded['Managers'] = false;
  }

  // #region - LIFECYCLE HOOKS
  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {
    this.breadcrumbService.remove(this.breadcrumb);
  }
  // #endregion

  // #region - DATA GETS
  getData() {
    forkJoin([
      this.userAPIService.getAllUsersMinimal(),
      this.companyAPIService.getAllDepartments(),
      this.companyAPIService.getAllOfficeLocations(),
      this.positionAPIService.getAllPositions()
    ]).subscribe(([users, departments, sites, positions]) => {
      this.processDepartments(departments);
      this.processSites(sites);
      this.processPositions(positions);
      this.processUsers(users); // Process users last because we need the departments/sites/positions populated

      this.updateAllUserCounts();
      this.getMyTeams();
      this.updateSearchAndFilters();
      this.filters.search.valueChanges.subscribe(_sarg => this.updateSearchAndFilters());
    },
    (error: HttpErrorResponse) => this.doError(error.message),
    () => this.state.loading = false);
  }

  private processUsers(users: UserMinimal[]) {
    this.users = this.mapMultipleUsersPageUser(UserMinimalSort.sortByFullName(users));
    this.adminIDs = this.getAdminIDsFromUserArray(this.users);
    this.managers = this.getManagersFromUserArray(users).sort((a, b) => a.name.localeCompare(b.name));
  }

  private processDepartments(departments: OrganisationalUnit[]) {
    this.departments = this.mapMultipleDepartmentPageDepartment(departments).sort((a, b) => a.name.localeCompare(b.name));
    this.departments.push({
      id: -1,
      name: 'No ' + this.companyWording.department,
      peopleCount: 0,
      level: null!,
      parentOrg: null!,
      organisationalUnitType: null!,
      managerUserId: null!,
      feedbackUserId: null!
    });
  };

  private processSites(sites: OfficeLocation[]) {
    this.sites = this.mapMultipleSitePageSite(sites).sort((a, b) => a.name.localeCompare(b.name));
    this.sites.push({
      id: -1,
      name: 'No site',
      peopleCount: 0,
      officeAddress: null!
    });
  }

  private processPositions(positions: Position[]) {
    // Bare-bones for now. Could be something we add to the sidebar in future
    this.positions = positions;
  }

  getManagersFromUserArray(users: UserMinimal[]): PageUser[] {
    const managerIDs = Array.from(new Set(users.map(u => u.managerId)));
    const managerUsers = users.filter(u => managerIDs.includes(u.id));
    return this.mapMultipleUsersPageUser(managerUsers);
  }
  // #endregion

  // #region - MAPPING DATA
  mapMultipleDepartmentPageDepartment(departments: OrganisationalUnit[]): PageDepartment[] {
    return departments.map(d => this.mapDepartmentPageDepartment(d));
  }
  mapDepartmentPageDepartment(department: OrganisationalUnit): PageDepartment {
    const output = department as PageDepartment;

    if (output) {
      output.peopleCount = 0;
    }

    return output;
  }

  mapMultipleSitePageSite(sites: OfficeLocation[]): PageSite[] {
    return sites.map(s => this.mapSitePageSite(s));
  }
  mapSitePageSite(site: OfficeLocation): PageSite {
    const output = site as PageSite;

    if (output) {
      output.peopleCount = 0;
    }

    return output;
  }

  mapMultipleUsersPageUser(users: UserMinimal[]): PageUser[] {
    return users.map(m => this.mapUserPageUser(m));
  }
  mapUserPageUser(user: UserMinimal): PageUser {
    const output = user as PageUser;

    if (output) {
      output.name = `${output.firstName} ${output.lastName}`;
      output.peopleCount = 0;
      output.cardColor = this.getCardColour(output);
      output.imgTitle = this.getImgTitle(output);
      output.organisationalUnit = (output.organisationalUnitId ? this.departments.find(d => d.id === output.organisationalUnitId) : null)!;
      output.officeLocation = (output.officeLocationId ? this.sites.find(s => s.id === output.officeLocationId) : null)!;
      output.position = (output.positionId ? this.positions.find(p => p.id === output.positionId) : null)!;
    }

    return output;
  }
  // #endregion

  // #region - USER COUNTS
  updateAllUserCounts() {
    this.departments = this.getUserCountForDepartmentsArray(this.departments);
    this.sites = this.getUserCountForSitesArray(this.sites);
    this.managers = this.getUserCountForManagersArray(this.managers);
  }

  getUserCountForDepartmentsArray(departments: PageDepartment[]): PageDepartment[] {
    return departments.map(d => {
      d.peopleCount = this.getUserCountForDepartmentId(d.id);
      return d;
    });
  }
  getUserCountForDepartmentId(id: number | null): number {
    const idUsing = ((id === -1) ? null : id);
    return this.users.filter(user => (user.organisationalUnitId && (user.organisationalUnitId === idUsing))).length;
  }

  getUserCountForSitesArray(sites: PageSite[]): PageSite[] {
    return sites.map(s => {
      s.peopleCount = this.getUserCountForSiteId(s.id);
      return s;
    })
  }
  getUserCountForSiteId(id: number | null): number {
    const idUsing = ((id === -1) ? null : id);
    return this.users.filter(user => (user.officeLocationId && (user.officeLocationId === idUsing))).length;
  }

  getUserCountForManagersArray(managers: PageUser[]): PageUser[] {
    return managers.map(m => {
      m.peopleCount = this.getUserCountForManagerId(m.id);
      return m;
    });
  }
  getUserCountForManagerId(id: number | null): number {
    const idUsing = ((id === -1) ? null : id);
    return this.users.filter(user => (user.managerId === idUsing) && (user.managerId !== user.id)).length;
  }
  // #endregion

  // #region - MY TEAMS
  getMyTeams() {
    // Manager
    if (this.globals.user.managerId && (this.globals.user.managerId !== this.globals.user.id)) {
      this.myTeams.manager = this.managers.find(m => (m.id === this.globals.user.managerId));
      // this.filters['managers'][this.globals.user.managerId] = true;
    }

    // Department
    if (this.globals.user.organisationalUnit && this.globals.user.organisationalUnit.id) {
      this.myTeams.department = this.departments.find(d => d.id === this.globals.user.organisationalUnit!.id);
      // this.filters['departments'][this.globals.user.organisationalUnit.id] = true;
    }

    // Sites
    if (this.globals.user.officeLocation && this.globals.user.officeLocation.id) {
      this.myTeams.site = this.sites.find(s => (s.id === this.globals.user.officeLocation!.id));
      // this.filters['sites'][this.globals.user.officeLocation.id] = true;
    }

    const managerMe = this.managers.find(m => m.id === this.globals.user.id);
    if (managerMe) {
      this.myTeams.managerMe = managerMe;
    }
  }
  // #endregion

  // #region - FILTERS
  updateSearchAndFilters() {
    let users = this.users;

    // Do filtering
    users = this.doFilter(users);

    // Do searching
    users = this.doSearch(users);

    this.usersFiltered = users;

    this.refreshPagination();
  }

  refreshPagination() {
    if (this.pagination) {
      this.pagination.update();
    }
  }

  doFilter(users: PageUser[]): PageUser[] {
    const selectedSites = this.getSelectedFilterPropIDs('sites');
    const selectedDepts = this.getSelectedFilterPropIDs('departments');
    const selectedManagers = this.getSelectedFilterPropIDs('managers');
    const selectedAdmins = this.filters.admins;

    return users

    // Filter sites
    .filter(u => {
      // If no sites selected, skip filter
      if (selectedSites.length === 0) {
        return u;
      }

      // If the user has a site and it's selected
      if (u.officeLocationId) {
        if (selectedSites.includes(u.officeLocationId.toString())) {
          return u;
        }
      // If the user has no site and "No site" is selected
      } else if (selectedSites.includes('-1')) {
        return u;
      }
    })

    // Filter departments
    .filter(u => {
      // If no departments selected, skip filter
      if (selectedDepts.length === 0) {
        return u;
      }

      // If the user has a dept and it's selected
      if (u.organisationalUnitId) {
        if (selectedDepts.includes(u.organisationalUnitId.toString())) {
          return u;
        }
      // If the user has no dept and "no dept" selected
      } else if (selectedDepts.includes('-1')) {
        return u;
      }
    })

    // Filter managers
    .filter(u => {
      // If no managers selected, skip filter
      if (selectedManagers.length === 0) {
        return u;
      }

      // If the user has a manager and they're selected
      if (u.managerId && (u.managerId !== u.id)) {
        if (selectedManagers.includes(u.managerId.toString())) {
          return u;
        }
      // If the user has no manager and "no manager" selected
      } else if (selectedManagers.includes('-1')) {
        return u;
      }
    })

    // Filter admins
    .filter(u => {
      // If admin filter not selected, skip filter
      if (!selectedAdmins) {
        return u;
      }

      // If admin filter selected, return only admins
      if (this.adminIDs.includes(u.id)) {
        return u;
      }
    })

  }

  doSearch(users: PageUser[]): PageUser[] {
    const searchArg = (this.filters.search.value as string).trim().toLowerCase();

    return users
    .filter(u => {
      // If invalid (no search) then skip searching
      if (this.filters.search.invalid) {
        return u;
      }

      // Do search
      const searchParams = `${u.firstName} ${u.lastName}`.trim().toLowerCase();
      if (searchParams.includes(searchArg)) {
        return u;
      }
    });
  }

  getSelectedFilterPropIDs(arrayProp: ('departments' | 'sites' | 'managers')) {
    const selectedIDs = [];
    for (const itemID in this.filters[arrayProp]) {
      if (this.filters[arrayProp].hasOwnProperty(itemID)) {
        const checked = this.filters[arrayProp][itemID];
        if (checked) {
          selectedIDs.push(itemID);
        }
      }
    }

    return selectedIDs;
  }

  filterSelected(event: MouseEvent, arrayProp: ('departments' | 'sites' | 'managers'), id: number) {
    // If control key is held, keep all other filters as is and toggle clicked one
    if (event.ctrlKey || event.metaKey) {
      this.toggleItemSelected(arrayProp, id, true);
      return;
    }

    const filterSelected = this.checkFilterSelected(arrayProp, id);
    if (filterSelected) {
      // this.disableAllFiltersInCategory(arrayProp, true);
      this.clearFilters();
    } else {
      // Otherwise, disable all other filters in category and select  this one
      // this.disableAllFiltersInCategory(arrayProp, false);
      this.clearFilters();
      this.setFilterSelected(arrayProp, id, true, true);
    }
  }

  toggleItemSelected(arrayProp: ('departments' | 'sites' | 'managers'), id: number, doUpdate: boolean = true) {
    if (this.filters[arrayProp].hasOwnProperty(id)) {
      const currentValue = this.checkFilterSelected(arrayProp, id)
      this.setFilterSelected(arrayProp, id, !currentValue);
    } else {
      this.setFilterSelected(arrayProp, id, true);
    }

    if (doUpdate) {
      this.updateSearchAndFilters();
    }
  }

  disableAllFiltersInCategory(arrayProp: ('departments' | 'sites' | 'managers'), doUpdate: boolean = true) {
    for (const id in this.filters[arrayProp]) {
      if (this.filters[arrayProp].hasOwnProperty(id)) {
        this.setFilterSelected(arrayProp, +id, false);
      }
    }

    if (doUpdate) {
      this.updateSearchAndFilters();
    }
  }

  checkFilterSelected(arrayProp: ('departments' | 'sites' | 'managers'), id: number): boolean {
    if (this.filters[arrayProp] && this.filters[arrayProp].hasOwnProperty(id)) {
      return this.filters[arrayProp][id];
    }

    return false;
  }

  setFilterSelected(arrayProp: ('departments' | 'sites' | 'managers'), id: number, value: boolean, doUpdate: boolean = true) {
    this.filters[arrayProp][id] = value;

    if (doUpdate) {
      this.updateSearchAndFilters();
    }
  }

  toggleAdminFilterSelected() {
    this.filters.admins = !this.filters.admins;

    this.updateSearchAndFilters();
  }

  clearCategoriesFilters() {
    const filterCategories: ('departments' | 'sites' | 'managers')[] = ['departments', 'sites', 'managers']
    filterCategories.forEach(category => this.disableAllFiltersInCategory(category))
  }

  clearFilters() {
    this.clearCategoriesFilters();
    this.filters.admins = false;

    this.updateSearchAndFilters();
  }
  // #endregion

  getAdminIDsFromUserArray(users: PageUser[]): number[] {
    return users.filter(u => (u.imgTitle === 'Admin')).map(u => u.id);
  }

  doError(message: string) {
    this.state.loading = false;
    this.state.error = true;
    this.state.errorMessage = message;
  }

  exportPeople() {
    this.userAPIService.downloadUserData().subscribe((data: Blob) => {
      const blob = new Blob([data], { type: 'text/csv' });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }

      const downloadURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = 'allUsers.csv';
      link.click();
    });
  }

  toggleSidebarCollapsed() {
    this.sidebarOpen = !this.sidebarOpen;
  }

  getCardColour(user: PageUser) {
    // METHOD UNO:
    // Cycles every X so colour index can be determined by [index % X] gives the index;
    // const colourIndex = index % this.colours.length;
    // const colour = this.colours[colourIndex];
    // return colour

    // METHOD DOS:
    // Colours are assigned based on role
    if (user.roles && user.roles.length > 0) {
      const rolesMapped = user.roles.map(r => r.name);
      if (rolesMapped.includes(RoleName.ADMIN) || rolesMapped.includes(RoleName.FRANKLI_ADMIN)) { // Admin
        return this.colours.ADMIN;
      }

      if (rolesMapped.includes(RoleName.MANAGER)) { // Manager
        return this.colours.MANAGER
      }
    }

    return this.colours.USER;
  }

  getImgTitle(user: PageUser) {
    if (user.roles && user.roles.length > 0) {
      const rolesMapped = user.roles.map(r => r.name);
      if (rolesMapped.includes(RoleName.ADMIN) || rolesMapped.includes(RoleName.FRANKLI_ADMIN)) { // Admin
        return 'Admin';
      }

      if (rolesMapped.includes(RoleName.MANAGER)) { // Manager
        return 'Manager'
      }
    }

    return 'User';
  }

  toggleCategoryExpanded(title: string) {
    const currentValue = this.categoryExpanded[title] || false;
    const newValue = !currentValue;

    this.categoryExpanded[title] = newValue;
    return newValue;
  }
}
