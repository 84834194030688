import { EvaluationCycle } from '@app/models/evaluation/evaluation-cycle.model';
import { EvaluationCycleState } from '@app/models/evaluation/evaluation-cycle-state';
import * as moment from 'moment';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { EvaluationCycleCreationStep } from '@app/models/evaluation/evaluation-cycle-creation-step';
import { EvaluationReviewPeriod } from '@app/models/evaluation/evaluation-review-period.model';
import { mockEndYearReviewModules, mockMidYearReviewModules } from '@app/mock/api/data/mockEvaluationCycleModules';
import {
    mockMidYearReviewManagerReviewQuestions, mockMidYearReviewSelfReflectionQuestions
} from '@app/mock/api/data/mockEvaluationCycleQuestions';

export const mockEvaluationCycles: EvaluationCycle[] = [
    {
        id: 1,
        name: 'Mid Year Review ' + moment().year(),
        periodStart: new Date(moment().year(), 4, 1),
        periodEnd: new Date(moment().year(), 6, 1),
        isOpen: true,
        state: EvaluationCycleState.ACTIVATED,
        calibration: false,
        creatorId: mockUsers[0].id,
        createdTimestamp: moment().subtract(1, 'day').toDate(),
        creationStep: EvaluationCycleCreationStep.COMPLETE,
        timezone: 'Europe/Dublin',
        evaluationReviewPeriod: EvaluationReviewPeriod.THREE_MONTH,
        users: mockUsers.slice(0, 20),
        peerReviewQuestions: [],
        managerReviewQuestions: mockMidYearReviewManagerReviewQuestions,
        selfReflectionQuestions: mockMidYearReviewSelfReflectionQuestions,
        modules: mockMidYearReviewModules
    },
    {
        id: 2,
        name: 'End of year Review ' + moment().subtract(1, 'year').year(),
        periodStart: new Date(moment().subtract(1, 'year').year(), 7, 1),
        periodEnd: new Date(moment().subtract(1, 'year').year(), 11, 1),
        isOpen: true,
        state: EvaluationCycleState.CLOSED,
        calibration: false,
        creatorId: mockUsers[0].id,
        createdTimestamp: moment().subtract(1, 'year').subtract(1, 'day').toDate(),
        creationStep: EvaluationCycleCreationStep.COMPLETE,
        timezone: 'Europe/Dublin',
        evaluationReviewPeriod: EvaluationReviewPeriod.SIX_MONTH,
        users: mockUsers.slice(0, 20),
        peerReviewQuestions: [],
        managerReviewQuestions: mockMidYearReviewManagerReviewQuestions,
        selfReflectionQuestions: mockMidYearReviewSelfReflectionQuestions,
        modules: mockEndYearReviewModules
    }
];