import { SelfReflectionQuestionAnswered } from '@app/models/evaluation/self-reflection-question-answered.model';
import { SurveyQuestionType } from '@app/models/survey-question-type.enum';
import { EvaluationQuestionAnswered } from '@app/models/evaluation/evaluation-question-answered.model';
import { EvaluationCycleQuestion } from '@app/models/evaluation/evaluation-cycle-question.model';

export const mapSelfReflectionAnswers = (questions: EvaluationCycleQuestion[]): SelfReflectionQuestionAnswered[] => {
    return questions.map(q => {
        switch (q.questionBankQuestion.surveyQuestionType) {
            case SurveyQuestionType.EXPECTATIONS:
            case SurveyQuestionType.PERFORMER:
            case SurveyQuestionType.RANKED_ONE_TO_FIVE:
                return {
                    id: 1,
                    questionId: q.id,
                    question: q,
                    textAnswer: '3',
                    comment: ''
                }
            case SurveyQuestionType.TEXT:
                return {
                    id: 2,
                    questionId: q.id,
                    question: q,
                    textAnswer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ac quam ante. Ut elementum et magna at aliquam. Aliquam sed mauris molestie, condimentum est at, gravida eros. Cras lectus nunc, gravida et scelerisque eget, ultricies a arcu. In fermentum libero et facilisis lacinia. Cras eu turpis erat. In ultrices, felis a commodo vulputate, tortor neque varius orci, et egestas lorem nisl vehicula nibh. Cras vitae nisl sodales, luctus ex consequat, egestas nisl. Aliquam aliquet tempus dui, in vestibulum nunc scelerisque nec. Nullam elementum erat sed ex vestibulum, id faucibus nunc gravida. Curabitur pulvinar sagittis tortor, id dignissim lacus pharetra ac. Morbi egestas leo turpis, sit amet mattis risus accumsan sit amet. Quisque mollis quis nulla eget dignissim. Aenean rutrum suscipit lacinia. Nam vel dui elementum, auctor eros eu, molestie metus. Nullam blandit mattis euismod.',
                    comment: ''
                }
            case SurveyQuestionType.LIKERT_SCALE:
            case SurveyQuestionType.LIKERT_SCALE_COMMENT:
                return {
                    id: 3,
                    questionId: q.id,
                    question: q,
                    textAnswer: '4',
                    comment: 'Etiam eleifend laoreet consectetur. Duis non leo risus. Pellentesque facilisis lorem quis sagittis massa nunc.'
                }
            default:
                return undefined!;
        }
    });
}

export const mapEvaluationFeedbackAnswers = (questions: EvaluationCycleQuestion[]): EvaluationQuestionAnswered[] => {
    return questions.map(q => {
        switch (q.questionBankQuestion.surveyQuestionType) {
            case SurveyQuestionType.EXPECTATIONS:
            case SurveyQuestionType.PERFORMER:
            case SurveyQuestionType.RANKED_ONE_TO_FIVE:
                return {
                    id: 1,
                    questionId: q.id,
                    question: q,
                    textAnswer: '3',
                    comment: ''
                }
            case SurveyQuestionType.TEXT:
                return {
                    id: 2,
                    questionId: q.id,
                    question: q,
                    textAnswer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ac quam ante. Ut elementum et magna at aliquam. Aliquam sed mauris molestie, condimentum est at, gravida eros. Cras lectus nunc, gravida et scelerisque eget, ultricies a arcu. In fermentum libero et facilisis lacinia. Cras eu turpis erat. In ultrices, felis a commodo vulputate, tortor neque varius orci, et egestas lorem nisl vehicula nibh. Cras vitae nisl sodales, luctus ex consequat, egestas nisl. Aliquam aliquet tempus dui, in vestibulum nunc scelerisque nec. Nullam elementum erat sed ex vestibulum, id faucibus nunc gravida. Curabitur pulvinar sagittis tortor, id dignissim lacus pharetra ac. Morbi egestas leo turpis, sit amet mattis risus accumsan sit amet. Quisque mollis quis nulla eget dignissim. Aenean rutrum suscipit lacinia. Nam vel dui elementum, auctor eros eu, molestie metus. Nullam blandit mattis euismod.',
                    comment: ''
                }
            case SurveyQuestionType.LIKERT_SCALE:
            case SurveyQuestionType.LIKERT_SCALE_COMMENT:
                return {
                    id: 3,
                    questionId: q.id,
                    question: q,
                    textAnswer: '4',
                    comment: 'Etiam eleifend laoreet consectetur. Duis non leo risus. Pellentesque facilisis lorem quis sagittis massa nunc.'
                }
            default:
                return undefined!;
        }
    });
}