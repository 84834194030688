import { Component, Input, OnInit } from '@angular/core';
import { FeedbackRequest } from '@app/models/feedback/feedback-request.model';

@Component({
  selector: 'app-feedback-request-display',
  templateUrl: './feedback-request-display.component.html',
  styleUrls: ['./feedback-request-display.component.css']
})
export class FeedbackRequestDisplayComponent implements OnInit {
  @Input() feedback: FeedbackRequest;

  @Input() showSourceUser: boolean;
  @Input() showDestinationUser: boolean;

  constructor(
  ) {
    this.feedback = undefined!;

    this.showSourceUser = true;
    this.showDestinationUser = true;
  }

  ngOnInit() {
  }
}
