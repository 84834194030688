import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Globals } from '../globals/globals';
import { UserState } from 'app/models/user/user.model';

/**
 * Allows pending or invited users through, otherwise redirects to log in
 */
@Injectable()
export class SignUpGuard {

  constructor(
    private globals: Globals,
    private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.globals.user.userState !== UserState.PENDING && this.globals.user.userState !== UserState.INVITED) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
