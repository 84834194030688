import { EvaluationCycleQuestion } from '@app/models/evaluation/evaluation-cycle-question.model';
import {
    mockManagerReviewAssessmentGroupQuestionBankQuestions,
    mockManagerReviewQuestionBankQuestions,
    mockPeerReviewQuestionBankQuestions, mockSelfReflectionAssessmentGroupQuestionBankQuestions,
    mockSelfReflectionQuestionBankQuestions
} from '@app/mock/api/data/mockQuestionBankQuestions';

export const mockMidYearReviewSelfReflectionQuestions: EvaluationCycleQuestion[] = [
    ...mockSelfReflectionQuestionBankQuestions.map((qbq, idx) => ({
        id: idx + 1,
        questionBankQuestion: qbq,
        questionText: qbq.questionText,
        orderIndex: idx
    })),
    {
        id: mockSelfReflectionQuestionBankQuestions.length + 1,
        questionBankQuestion: mockSelfReflectionAssessmentGroupQuestionBankQuestions[0],
        questionText: mockSelfReflectionAssessmentGroupQuestionBankQuestions[0].questionText,
        orderIndex: mockSelfReflectionQuestionBankQuestions.length
    }
]

export const mockMidYearReviewPeerReviewQuestions: EvaluationCycleQuestion[] = mockPeerReviewQuestionBankQuestions.map((qbq, idx) => ({
    id: idx + 1,
    questionBankQuestion: qbq,
    questionText: qbq.questionText,
    orderIndex: idx
}));

export const mockMidYearReviewManagerReviewQuestions: EvaluationCycleQuestion[] = [
    ...mockManagerReviewQuestionBankQuestions.map((qbq, idx) => ({
        id: idx + 1,
        questionBankQuestion: qbq,
        questionText: qbq.questionText,
        orderIndex: idx
    })),
    {
        id: mockManagerReviewQuestionBankQuestions.length + 1,
        questionBankQuestion: mockManagerReviewAssessmentGroupQuestionBankQuestions[0],
        questionText: mockManagerReviewAssessmentGroupQuestionBankQuestions[0].questionText,
        orderIndex: mockManagerReviewQuestionBankQuestions.length
    }
]

export const mockEvaluationCycleQuestions: EvaluationCycleQuestion[] = [
    ...mockMidYearReviewSelfReflectionQuestions,
    ...mockMidYearReviewPeerReviewQuestions,
    ...mockMidYearReviewManagerReviewQuestions
];