<div class="row center-screen">
  <div class="col-md-12 text-center">
    <img class="bigger-icon" src="/assets/img/Failure.svg" />
    <h3 class="text-center display-3" *ngIf="!globals.hasRole(eRole.ADMIN) && !globals.hasRole(eRole.FRANKLI_ADMIN)">
      This feature is not enabled, please contact your Frankli admin if you would like it enabled.
    </h3>
    <h3 class="text-center display-3" *ngIf="(globals.hasRole(eRole.ADMIN) || globals.hasRole(eRole.FRANKLI_ADMIN)) && !globals.company.featureLocked">
      This feature is not enabled, you can enable it in your admin dashboard.
    </h3>
  </div>

  <div class="col-md-12 text-center m-top-25">
    <a [routerLink]="'/dashboard'">
      <button class="btn-frankli-round btn-frankli-green">Back to Dashboard</button>
    </a>
    <a [routerLink]="'/admin/dashboard/features'"
      *ngIf="(globals.hasRole(eRole.ADMIN) || globals.hasRole(eRole.FRANKLI_ADMIN)) && !globals.company.featureLocked">
      <button class="btn-frankli-round btn-frankli-green">Go to Admin Dashboard</button>
    </a>
  </div>
</div>