import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'html'
})
export class HtmlPipe implements PipeTransform {
    previousValue!: string | null;
    resultCached!: string;

    transform(html: string | null): string {
        if (html === null) {
            return '';
        }

        // Skip calculation if value hasn't changed
        if ((html === this.previousValue) && this.resultCached) {
            return this.resultCached;
        }
        this.previousValue = html;

        const result = html
        .replace(/(?=<!--)([\s\S]*?)-->/gm, '') // replace html comments
        .replace(/(<([^>]+)>)/ig, '') // Replace html tags
        .replace(/(?:\r\n|\r|\n)/g, '') // Replace newlines
        .replace(/&nbsp;/g, '') // Replace space string with space char
        .replace(/&bull;/g, '•') // Replace bullet point string with char
        .replace(/&ndash;/g, '-') // Replace hyphen string with char
        .replace(/&amp;/g, '&') // Replace ampersand string with char
        .replace(/&rsquo;/g, '’') // Replace string with char
        .replace(/&lt;/g, '<') // Replace < string with <
        .replace(/&gt/g, '>') // Replace > string with >

        this.resultCached = result;
        return result;
    }
}

