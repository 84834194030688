<!-- Stats panels -->
<div class="row stats-row" *ngIf="stats">
  <div class="col-md-3 col-sm-6 col-xs-12">
    <div class="stat-col">
      <span class="fal fa-bullseye"></span>
      <span class="stats-text">
        <span><b>{{goalsActive.length}}</b> Active Goals</span>
      </span>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-xs-12">
    <div class="stat-col">
      <span class="progress progress-round">
        <span class="progress-left">
          <span class="progress-bar border-primary" [style.transform]="roundProgress.left"></span>
        </span>
        <span class="progress-right">
          <span class="progress-bar border-primary" [style.transform]="roundProgress.right"></span>
        </span>
      </span>
      <span class="stats-text">
        <span><b>{{stats.percentComplete}}%</b> Complete</span>
      </span>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-xs-12">
    <div class="stat-col">
      <span class="fal fa-calendar-alt"></span>
      <span class="stats-text">
        <span><b>{{stats.dueIn30DaysCount}}</b> Due in the Next 30 Days</span>
      </span>
    </div>
  </div>
  <div class="col-md-3 col-sm-6 col-xs-12">
    <div class="stat-col">
      <span class="fal fa-check-circle"></span>
      <span class="stats-text">
        <span><b>{{stats.completed3Months}}</b> Completed in last 3 Months</span>
      </span>
    </div>
  </div>
</div>