import { Injectable } from '@angular/core';
import { EditProfileTab } from '@app/models/edit-profile/edit-profile-tab.model';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class EditProfileService {

  private readonly subjectCanChange$ = new Subject<EditProfileTab>();
  private readonly subjectChange$ = new Subject<EditProfileTab>();

  constructor() { }

  // edit asking inner components if it can nav
  getCanChange(): Observable<EditProfileTab> {
    return this.subjectCanChange$.asObservable();
  }

  sendCanChange(tab: EditProfileTab): void {
    this.subjectCanChange$.next(tab);
  }

  // inner components telling edit / nav to navigate
  getChange(): Observable<EditProfileTab> {
    return this.subjectChange$.asObservable();
  }

  sendChange(tab: EditProfileTab): void {
    this.subjectChange$.next(tab);
  }

}
