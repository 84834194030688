import { SurveyQuestion } from '@app/models/survey/question.model';
import { mockSurveyQuestionCategories } from '@app/mock/api/data/mockSurveyQuestionCategories';

export const mockWellbeingSurveyQuestions: SurveyQuestion[] = [
  {
    id: 77,
    selected: false,
    surveyQuestion: "I believe Frankli is truly committed to the health and wellbeing of employees.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[0],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 83,
    selected: false,
    surveyQuestion: "We are genuinely supported if we choose to make use of flexible working arrangements.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[0],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 76,
    selected: false,
    surveyQuestion: "I am able to arrange time out from work when I need to.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[0],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 81,
    selected: false,
    surveyQuestion: "I know what to do if I feel anxious or stressed out at work.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[0],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 63,
    selected: false,
    surveyQuestion: "I have good friendships at Frankli.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[0],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 86,
    selected: false,
    surveyQuestion: "A healthy lifestyle is encouraged at Frankli.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[0],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 82,
    selected: false,
    surveyQuestion: "I feel like I am treated fairly at Frankli.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[0],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 84,
    selected: false,
    surveyQuestion: "I believe my health and wellbeing is a priority for my manager.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[0],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 41,
    selected: false,
    surveyQuestion: "Frankli really allows us to make a positive difference.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[1],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 91,
    selected: false,
    surveyQuestion: "The values of Frankli align well with my own values.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[1],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 85,
    selected: false,
    surveyQuestion: "There is regular communication between employees and management about health and wellbeing.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[0],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 13,
    selected: false,
    surveyQuestion: "My manager genuinely cares about my wellbeing.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[2],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 40,
    selected: false,
    surveyQuestion: "Frankli's commitment to social responsibility (e.g. community support, sustainability, etc.) is genuine.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[1],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  }
];

export const mockOnboardingSurveyQuestions: SurveyQuestion[] = [
  {
    id: 88,
    selected: false,
    surveyQuestion: "I am confident using the systems I need in my role.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[3],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 29,
    selected: false,
    surveyQuestion: "I know how my work contributes to the goals of Frankli.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[3],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 28,
    selected: false,
    surveyQuestion: "Most of the systems and processes here support us getting our work done effectively.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[3],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 22,
    selected: false,
    surveyQuestion: "I feel I am part of a team.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[4],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 10,
    selected: false,
    surveyQuestion: "The leaders at Frankli have communicated a vision that motivates me.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[5],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 93,
    selected: false,
    surveyQuestion: "I feel welcome at Frankli.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[1],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 94,
    selected: false,
    surveyQuestion: "What is one thing we could have done differently to improve your onboarding experience?",
    surveyQuestionType: "TEXT",
    templateCategory: mockSurveyQuestionCategories[6],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 27,
    selected: false,
    surveyQuestion: "Our physical workspace is enjoyable to work in.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[3],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 72,
    selected: false,
    surveyQuestion: "I feel like I have a resposibility in helping Frankli achieve its aims.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[0],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 31,
    selected: false,
    surveyQuestion: "I know what I need to do to be successful in my role.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[3],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 25,
    selected: false,
    surveyQuestion: "I have access to the things I need to do my job well.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[3],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 90,
    selected: false,
    surveyQuestion: "The information I was provided during my first weeks has proved valuable.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[2],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 12,
    selected: false,
    surveyQuestion: "The leaders at Frankli demonstrate that people are important to the company's success.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[5],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  }
];

export const mockExitInterviewSurveyQuestions: SurveyQuestion[] = [
  {
    id: 101,
    selected: false,
    surveyQuestion: "How would you rate the overall workplace?",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[7],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 100,
    selected: false,
    surveyQuestion: "Overall, there was a good Team Spirit at Frankli.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[7],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 98,
    selected: false,
    surveyQuestion: "Opportunities for job satisfaction at Frankli were fair.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[7],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 105,
    selected: false,
    surveyQuestion: "I would consider coming back to work here in the future.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[7],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 99,
    selected: false,
    surveyQuestion: "The financial benefits for your role at Frankli were fair.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[7],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 104,
    selected: false,
    surveyQuestion: "I would consider recommending a friend looking for a job to Frankli.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[7],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 107,
    selected: false,
    surveyQuestion: "I had clear goals and objectives.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[7],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 97,
    selected: false,
    surveyQuestion: "The opportunities for growth at Frankli were adequate.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[7],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 96,
    selected: false,
    surveyQuestion: "What’s your reason for leaving the company?",
    surveyQuestionType: "TEXT",
    templateCategory: mockSurveyQuestionCategories[7],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 103,
    selected: false,
    surveyQuestion: "Overall, stress levels in my position were reasonable.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[7],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 106,
    selected: false,
    surveyQuestion: "I received constructive feedback to help me improve my performance.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[7],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 102,
    selected: false,
    surveyQuestion: "I was satisfied with the way I was managed.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[7],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 108,
    selected: false,
    surveyQuestion: "How would you describe the culture of our company?",
    surveyQuestionType: "TEXT",
    templateCategory: mockSurveyQuestionCategories[7],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  }
];

export const mockEmployeeSatisfactionSurveyQuestions: SurveyQuestion[] = [
  {
    id: 19,
    selected: false,
    surveyQuestion: "At Frankli, there is open and honest two-way communication.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[4],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 2,
    selected: false,
    surveyQuestion: "Frankli motivates me to go beyond what I would in a similar role elsewhere.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[8],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 46,
    selected: false,
    surveyQuestion: "I have access to the learning and development I need to do my job well.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[9],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 8,
    selected: false,
    surveyQuestion: "Frankli is in a position to really succeed over the next three years.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[10],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 21,
    selected: false,
    surveyQuestion: "My workload is similar to that of other co-workers.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[4],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 3,
    selected: false,
    surveyQuestion: "I am proud to work for Frankli.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[8],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 43,
    selected: false,
    surveyQuestion: "My job performance is evaluated fairly.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[9],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 45,
    selected: false,
    surveyQuestion: "When it is clear that someone is not delivering in their role, Frankli does something about it.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[9],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 11,
    selected: false,
    surveyQuestion: "The leaders at Frankli keep people informed about what is happening.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[5],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 16,
    selected: false,
    surveyQuestion: "My manager is a positive role model for me.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[2],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 36,
    selected: false,
    surveyQuestion: "I am encouraged to be innovative even though some of my initiatives may not succeed.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[1],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 15,
    selected: false,
    surveyQuestion: "My manager keeps me informed about what is happening.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[2],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 1,
    selected: false,
    surveyQuestion: "I would recommend Frankli as a great place to work.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[8],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  }
];

export const mockJobSatisfactionSurveyQuestions: SurveyQuestion[] = [
  {
    id: 30,
    selected: false,
    surveyQuestion: "I am appropriately involved in decisions that affect my work.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[3],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 38,
    selected: false,
    surveyQuestion: "Day-to-day decisions demonstrate that quality and improvement are top priorities.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[1],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 47,
    selected: false,
    surveyQuestion: "I am given opportunities to develop skills relevant to my interests.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[9],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 42,
    selected: false,
    surveyQuestion: "I receive appropriate recognition for good work at Frankli.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[9],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 4,
    selected: false,
    surveyQuestion: "I rarely think about looking for a job at another company.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[8],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 33,
    selected: false,
    surveyQuestion: "I believe my total compensation is fair, relative to similar roles at other companies.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[3],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 20,
    selected: false,
    surveyQuestion: "Workloads are divided fairly among people where I work.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[4],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 17,
    selected: false,
    surveyQuestion: "My manager is a great role model for employees.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[2],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 23,
    selected: false,
    surveyQuestion: "We hold ourselves and our team members accountable for results.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[4],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 7,
    selected: false,
    surveyQuestion: "Frankli effectively directs resources (funding, people & effort) towards company goals.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[10],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 57,
    selected: false,
    surveyQuestion: "It really feels like everyone is on the same team at Frankli.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[8],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 14,
    selected: false,
    surveyQuestion: "My manager gives me useful feedback on how well I am performing.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[2],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 37,
    selected: false,
    surveyQuestion: "At Frankli, we act on promising new or innovative ideas.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[1],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  }
];

export const mockTrainingEvaluationSurveyQuestions: SurveyQuestion[] = [
  {
    id: 109,
    selected: false,
    surveyQuestion: "Required training material and assistance was given during the program.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[11],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 117,
    selected: false,
    surveyQuestion: "I felt the need for the training before attending the training program.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[11],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 119,
    selected: false,
    surveyQuestion: "The program was relevant to my growth and development.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[11],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 114,
    selected: false,
    surveyQuestion: "Overall, the training was relevant to my needs.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[11],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 111,
    selected: false,
    surveyQuestion: "The trainer presented the materials satisfactorily and it was easy to understand.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[11],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 112,
    selected: false,
    surveyQuestion: "The mix of presentations and activities over all was very suitable.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[11],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 116,
    selected: false,
    surveyQuestion: "I would recommend the training to colleagues.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[11],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 113,
    selected: false,
    surveyQuestion: "As a result of going through this program, I will be able to improve the way I do my current job.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[11],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 118,
    selected: false,
    surveyQuestion: "I achieved the objectives of the training program.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[11],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 115,
    selected: false,
    surveyQuestion: "The program was well structured and sufficient time was allocated for each subject/session.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[11],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 110,
    selected: false,
    surveyQuestion: "The size of your training group was appropriate for the content.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[11],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 120,
    selected: false,
    surveyQuestion: "Do you have any suggestions to improve this course?",
    surveyQuestionType: "TEXT",
    templateCategory: mockSurveyQuestionCategories[11],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  }
];

export const mockCovidResponseSurveyQuestions: SurveyQuestion[] = [
  {
    id: 122,
    selected: false,
    surveyQuestion: "I believe my company is taking the appropriate steps to minimse disruption to our business during this time.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[12],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 132,
    selected: false,
    surveyQuestion: "Working remotely at Frankli is something I would certainly consider on a more permanent basis.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[12],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 124,
    selected: false,
    surveyQuestion: "I feel supported to adjust my work schedule to accommodate my personal circumstances.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[12],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 139,
    selected: false,
    surveyQuestion: "What is one thing we could have done differently to improve your experience at work during this time?",
    surveyQuestionType: "TEXT",
    templateCategory: mockSurveyQuestionCategories[12],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 121,
    selected: false,
    surveyQuestion: "I have the hardware and software I need to do my job effectively during this period.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[12],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 134,
    selected: false,
    surveyQuestion: "I am motivated to show up for work every day.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[12],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 128,
    selected: false,
    surveyQuestion: "My manager has checked in on my wellbeing in the last 7 days.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[12],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 123,
    selected: false,
    surveyQuestion: "My stress leves during this period are manageable.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[12],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 133,
    selected: false,
    surveyQuestion: "I believe remote working would benefit Frankli.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[12],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 130,
    selected: false,
    surveyQuestion: "I am able to maintain meaningful connections with my work colleagues during this time.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[12],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 137,
    selected: false,
    surveyQuestion: "I trust the leadership team at Frankli to make good decisions during this crisis.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[12],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 136,
    selected: false,
    surveyQuestion: "The leadership team at Frankli has shown genuine compassion towards employees during this time.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[12],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 127,
    selected: false,
    surveyQuestion: "My manager clearly communicates team and individual responsibilities.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[12],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 138,
    selected: false,
    surveyQuestion: "Are there any supports you need at this time that you are not currently receiving?",
    surveyQuestionType: "TEXT",
    templateCategory: mockSurveyQuestionCategories[12],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 126,
    selected: false,
    surveyQuestion: "My manager has effectively responded to my needs in the current environment.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[12],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 131,
    selected: false,
    surveyQuestion: "I feel comfortable bringing up work-related problems with my work colleagues during this time.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[12],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 125,
    selected: false,
    surveyQuestion: "I am encouraged to take appropriate breaks and time for myself during the workday.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[12],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 135,
    selected: false,
    surveyQuestion: "I am proud to tell others about my company's actions during this time.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[12],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  },
  {
    id: 129,
    selected: false,
    surveyQuestion: "I like the way my company is handling internal communications during this public health crisis.",
    surveyQuestionType: "LIKERT_SCALE",
    templateCategory: mockSurveyQuestionCategories[12],
    surveyQuestionPossibleAnswers: [],
    enps: false,
    enpsCategory: null
  }
];

export const mockEnpsSurveyQuestions: SurveyQuestion[] = [
  {
    id: 140,
    selected: false,
    surveyQuestion: "On a scale of 0-10, how likely are you to recommend Frankli as a good place to work?",
    surveyQuestionType: "ENPS",
    templateCategory: mockSurveyQuestionCategories[13],
    surveyQuestionPossibleAnswers: [],
    enps: true,
    enpsCategory: null
  },
  {
    id: 141,
    selected: false,
    surveyQuestion: "If you were offered the same job at another organisation, how likely is it that you would stay with Frankli?",
    surveyQuestionType: "ENPS",
    templateCategory: mockSurveyQuestionCategories[13],
    surveyQuestionPossibleAnswers: [],
    enps: true,
    enpsCategory: "Loyalty"
  },
  {
    id: 142,
    selected: false,
    surveyQuestion: "Overall, how satisfied are you working for Frankli?",
    surveyQuestionType: "ENPS",
    templateCategory: mockSurveyQuestionCategories[13],
    surveyQuestionPossibleAnswers: [],
    enps: true,
    enpsCategory: "Satisfaction"
  },
  {
    id: 143,
    selected: false,
    surveyQuestion: "How likely is it you would recommend Frankli products or services to friends and family?",
    surveyQuestionType: "ENPS",
    templateCategory: mockSurveyQuestionCategories[13],
    surveyQuestionPossibleAnswers: [],
    enps: true,
    enpsCategory: "Belief"
  }
];

export const mockSurveyQuestions: SurveyQuestion[] = [
  ...mockWellbeingSurveyQuestions,
  ...mockOnboardingSurveyQuestions,
  ...mockExitInterviewSurveyQuestions,
  ...mockEmployeeSatisfactionSurveyQuestions,
  ...mockJobSatisfactionSurveyQuestions,
  ...mockTrainingEvaluationSurveyQuestions,
  ...mockCovidResponseSurveyQuestions,
  ...mockEnpsSurveyQuestions
];