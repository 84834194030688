import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CallbackComponent } from '@app/callback/callback.component';
import { NewsActivityAPIService } from '@app/shared/api/news-activity.api.service';
import { FeatureLockedGuard } from '@app/shared/auth/feature-locked.guard';
import { FeatureLockedComponent } from '@app/shared/feature-locked/feature-locked.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { RxStompService } from '@stomp/ng2-stompjs';
import { Angulartics2Module } from 'angulartics2';
import { ProfileModule } from 'app/profile/profile.module';
import { CustomerAccountAPIService } from 'app/shared/api/customer-account.api.service';
import { FrankliAdminAPIService } from 'app/shared/api/frankli-admin.api.service';
import { PollAPIService } from 'app/shared/api/polls.api.service';
import { SettingsAPIService } from 'app/shared/api/settings.api.service';
import { ErrorService } from 'app/shared/interceptors/error.service';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { MomentModule } from 'ngx-moment';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { EmployeeModule } from './employee/employee.module';
import { EditProfileService } from './profile/profile-components/edit/edit-profile.service';
import { AnalyticsAPIService } from './shared/api/analytics.api.service';
import { CompanyAPIService } from './shared/api/company.api.service';
import { CompetencyAPIService } from './shared/api/competency.api.service';
import { NotificationAPIService } from './shared/api/events.api.service';
import { FeedbackAPIService } from './shared/api/feedback.api.service';
import { FileAPIService } from './shared/api/file.api.service';
import { GoalsAPIService } from './shared/api/goals.api.service';
import { IdeaAPIService } from './shared/api/idea.api.service';
import { InterestAPIService } from './shared/api/interest.api.service';
import { OneToOneAPIService } from './shared/api/one-to-one.api.service';
import { PositionAPIService } from './shared/api/position.api.service';
import { PraiseAPIService } from './shared/api/praise.api.service';
import { SimilarUsersAPIService } from './shared/api/similar-users.api.service';
import { StatusAPIService } from './shared/api/status.api.service';
import { SurveyAPIService } from './shared/api/survey.api.service';
import { TodoAPIService } from './shared/api/todos.api.service';
import { UserAPIService } from './shared/api/user.api.service';
import { WebsocketService } from './shared/api/websocket.service';
import { WelcomeAPIService } from './shared/api/welcome.api.service';
import { ArchivedComponent } from './shared/archived/archived.component';
import { AuthAPIService } from './shared/auth/auth.api.service';
import { AuthenticatedGuard } from './shared/auth/authenticated.guard';
import { CanDeactivateGuard } from './shared/auth/can-deactivate.guard';
import { FeatureGuard } from './shared/auth/feature.guard';
import { NewUserGuard } from './shared/auth/newuser.guard';
import { PasswordResetAllowGuard } from './shared/auth/password-reset-allow.guard';
import { PasswordResetGuard } from './shared/auth/password-reset.guard';
import { RoleGuard } from './shared/auth/role.guard';
import { SignUpGuard } from './shared/auth/sign-up.guard';
import { UnauthenticatedGuard } from './shared/auth/unauthenticated.guard';
import { UserAccessRevokedGuard } from './shared/auth/user-access-revoked.guard';
import { UserArchivedGuard } from './shared/auth/user-archived.guard';
import { FeatureNotEnabledComponent } from './shared/feature-not-enabled/feature-not-enabled.component';
import { FooterModule } from './shared/footer/footer.module';
import { Globals } from './shared/globals/globals';
import { HttpInterceptors } from './shared/interceptors/interceptors.service';
import { JwtInterceptor } from './shared/interceptors/jwt.interceptor';
import { EmployeeLayoutComponent } from './shared/layouts/employee/employee-layout.component';
import { OverlayLoginComponent } from './shared/layouts/employee/overlay-login/overlay-login.component';
import { OverlayNoInternetConnectionComponent } from './shared/layouts/employee/overlay-no-internet-connection/overlay-no-internet-connection.component';
import { OverlayServerUnavailableComponent } from './shared/layouts/employee/overlay-server-unreachable/overlay-server-unreachable.component';
import { LoginLayoutComponent } from './shared/layouts/login/login-layout.component';
import { SignUpLayoutComponent } from './shared/layouts/sign-up/sign-up-layout.component';
import { LoginComponent } from './shared/login/login.component';
import { LogoutComponent } from './shared/logout/logout.component';
import { NavbarModule } from './shared/navbar/navbar.module';
import { PaginationModule } from './shared/pagination/pagination.module';
import { ResetPasswordEmailComponent } from './shared/reset-password-email/reset-password-email.component';
import { ResetPasswordNewComponent } from './shared/reset-password-new/reset-password-new.component';
import { RevokedComponent } from './shared/revoked/revoked.component';
import { SharedModule } from './shared/shared.module';
import { SidebarModule } from './shared/sidebar/sidebar.module';
import { DateService } from './shared/utils/date.service';
import { WindowReferenceService } from './shared/window/windowReference.service';
import { FAQAPIService } from '@app/shared/api/faq.api.service';
import { MockModule } from '@app/mock/mock.module';
import { EmailAPIService } from './shared/api/email.api.service';
import { HttpErrorInterceptor } from '@app/shared/interceptors/http.error.interceptor';
import { GlobalErrorHandler } from '@app/shared/interceptors/global-error-handler';
import { StartUpService, startUpServiceFactory } from '@app/services/start-up.service';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SwalModule } from '@sweetalert2/ngx-sweetalert2/lib/sweetalert2-loader.service';
import { PermissionAPIService } from './shared/api/permission.api.service';

async function provideSwalFn(): Promise<SwalModule> {
  const { default: swal } = await import('sweetalert2');
  return swal.mixin({
    buttonsStyling: true,
    customClass: {
      container: 'modal-content-ngx'
    },
    confirmButtonColor: '#54c6bb',
  });
}

@NgModule({
  imports: [
    // Angular
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // Libraries
    Angulartics2Module,
    LeafletModule.forRoot(),
    SweetAlert2Module.forRoot({
      provideSwal: provideSwalFn
    }),
    // Frankli
    SidebarModule,
    NavbarModule,
    CommonModule,
    FooterModule,
    AppRoutingModule,
    EmployeeModule,
    ProfileModule,
    PaginationModule,
    SharedModule,
    MomentModule,
    environment.mock.enabled ? MockModule : []
  ],
  declarations: [
    AppComponent,
    CallbackComponent,
    EmployeeLayoutComponent,
    LoginLayoutComponent,
    SignUpLayoutComponent,
    LoginComponent,
    ResetPasswordEmailComponent,
    ResetPasswordNewComponent,
    RevokedComponent,
    ArchivedComponent,
    LogoutComponent,
    FeatureNotEnabledComponent,
    FeatureLockedComponent,
    OverlayServerUnavailableComponent,
    OverlayLoginComponent,
    OverlayNoInternetConnectionComponent
  ],
  bootstrap: [AppComponent],
  providers: [
    AuthAPIService,
    Globals,
    RoleGuard,
    NewUserGuard,
    AuthenticatedGuard,
    FeatureLockedGuard,
    SettingsAPIService,
    SignUpGuard,
    WindowReferenceService,
    WebsocketService,
    RxStompService,
    DateService,
    EditProfileService,
    PasswordResetGuard,
    PasswordResetAllowGuard,
    FrankliAdminAPIService,
    FeatureGuard,
    UserAccessRevokedGuard,
    UserArchivedGuard,
    StatusAPIService,
    SidebarService,
    CustomerAccountAPIService,
    UserAPIService,
    CompanyAPIService,
    SurveyAPIService,
    InterestAPIService,
    NotificationAPIService,
    AnalyticsAPIService,
    FeedbackAPIService,
    IdeaAPIService,
    PraiseAPIService,
    FileAPIService,
    WelcomeAPIService,
    FAQAPIService,
    SimilarUsersAPIService,
    GoalsAPIService,
    OneToOneAPIService,
    CanDeactivateGuard,
    CompetencyAPIService,
    PositionAPIService,
    TodoAPIService,
    UnauthenticatedGuard,
    StartUpService,
    NewsActivityAPIService,
    ErrorService,
    PollAPIService,
    EmailAPIService,
    PermissionAPIService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    environment.mock.enabled ? [] :
      [
        { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptors, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
      ],
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: startUpServiceFactory, deps: [StartUpService], multi: true },
  ]
})
export class AppModule {
  constructor() {
  }
}
