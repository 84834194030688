import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SidebarService {
  private subjectUpdate = new Subject<string>();

  constructor() {
  }

  getUpdate() {
    return this.subjectUpdate.asObservable();
  }

  sendUpdate() {
    this.subjectUpdate.next('INIT');
  }

}
