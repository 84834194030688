<router-outlet></router-outlet>
<ng-container *ngIf="route.children.length === 0">
  <div class="content">
    <ng-container *ngIf="!state.loading; else stateLoading">
      <ng-container *ngIf="!state.error; else stateError">

        <!-- Stats -->
        <!-- <div class="card p-left-10 p-right-10 p-top-10" *ngIf="rootDepartment">
          <div class="row stats-row">
            <div class="col-md-3 col-sm-6 col-xs-12">
              <div class="stat-col">
                <span class="fal fa-bullseye"></span>
                <span class="stats-text">
                  <span><b>{{rootDepartment.goalsCount}}</b> Total Goals</span>
                </span>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
              <div class="stat-col">
                <span class="fal fa-calendar-alt"></span>
                <span class="stats-text">
                  <span><b>{{rootDepartment.activeCount}}</b> Active Goals</span>
                </span>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
              <div class="stat-col">
                <span class="fal fa-check-circle"></span>
                <span class="stats-text">
                  <span><b>{{rootDepartment.completeCount}}</b> Complete Goals</span>
                </span>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
              <div class="stat-col">
                <span class="progress progress-round">
                  <span class="progress-left">
                    <span class="progress-bar border-primary" [style.transform]="roundProgress.left"></span>
                  </span>
                  <span class="progress-right">
                    <span class="progress-bar border-primary" [style.transform]="roundProgress.right"></span>
                  </span>
                </span>
                <span class="stats-text">
                  <span><b>{{rootDepartment.averageCompletion}}%</b> Average Completion</span>
                </span>
              </div>
            </div>
          </div>
        </div> -->

        <div class="card">
          <!-- Universal filter and export -->
          <div class="text-center p-left-15 p-right-15 p-top-15 p-bottom-10 position-relative">
            <span class="center-container">
              <!-- (Search / Filter) -->
              <span class="uni-filter-container">
                <app-universal-filter [filterOptions]="filterOptions" [searchProps]="searchProps"
                  (resultEmit)="universalFilterEmit($event)" #universalFilter></app-universal-filter>
              </span>

              <!-- Column Toggle -->
              <span class="col-toggle-container">
                <app-column-toggle [titles]="columns" #columnToggle></app-column-toggle>
              </span>
            </span>
          </div>

          <!-- Table -->
          <ng-container *ngIf="columnToggle && (departmentsFiltered.length > 0); else noSearchResults">
            <div class="scroll-x">
              <table width="100%">
                <!-- Table headings -->
                <tr class="frankli-table frankli-table-small frankli-table-header noselect">
                  <th></th> <!-- Spacing for dropdown -->

                  <th (click)="doSort(eTableColumn.NAME)" *ngIf="columnToggle.getColumnEnabled(eTableColumn.NAME)"
                    [class.sorting-ascending]="sortType.column === eTableColumn.NAME && !sortType.ascending"
                    [class.sorting-descending]="sortType.column === eTableColumn.NAME && sortType.ascending">
                    {{eTableColumn.NAME}}
                  </th>
                  <th class="text-center" (click)="doSort(eTableColumn.GOAL_COUNT)"
                    *ngIf="columnToggle.getColumnEnabled(eTableColumn.GOAL_COUNT)"
                    [class.sorting-ascending]="sortType.column === eTableColumn.GOAL_COUNT && !sortType.ascending"
                    [class.sorting-descending]="sortType.column === eTableColumn.GOAL_COUNT && sortType.ascending">
                    {{eTableColumn.GOAL_COUNT}}
                  </th>
                  <th class="text-center" (click)="doSort(eTableColumn.ACTIVE_GOAL_COUNT)"
                    *ngIf="columnToggle.getColumnEnabled(eTableColumn.ACTIVE_GOAL_COUNT)"
                    [class.sorting-ascending]="sortType.column === eTableColumn.ACTIVE_GOAL_COUNT && !sortType.ascending"
                    [class.sorting-descending]="sortType.column === eTableColumn.ACTIVE_GOAL_COUNT && sortType.ascending">
                    {{eTableColumn.ACTIVE_GOAL_COUNT}}
                  </th>
                  <th class="text-center" (click)="doSort(eTableColumn.COMPLETE_GOAL_COUNT)"
                    *ngIf="columnToggle.getColumnEnabled(eTableColumn.COMPLETE_GOAL_COUNT)"
                    [class.sorting-ascending]="sortType.column === eTableColumn.COMPLETE_GOAL_COUNT && !sortType.ascending"
                    [class.sorting-descending]="sortType.column === eTableColumn.COMPLETE_GOAL_COUNT && sortType.ascending">
                    {{eTableColumn.COMPLETE_GOAL_COUNT}}
                  </th>
                  <th class="text-center" (click)="doSort(eTableColumn.TOTAL_OBJECTIVES)"
                    *ngIf="columnToggle.getColumnEnabled(eTableColumn.TOTAL_OBJECTIVES)"
                    [class.sorting-ascending]="sortType.column === eTableColumn.TOTAL_OBJECTIVES && !sortType.ascending"
                    [class.sorting-descending]="sortType.column === eTableColumn.TOTAL_OBJECTIVES && sortType.ascending">
                    {{eTableColumn.TOTAL_OBJECTIVES}}
                  </th>
                  <th (click)="doSort(eTableColumn.AVERAGE_COMPLETION)"
                    *ngIf="columnToggle.getColumnEnabled(eTableColumn.AVERAGE_COMPLETION)"
                    [class.sorting-ascending]="sortType.column === eTableColumn.AVERAGE_COMPLETION && !sortType.ascending"
                    [class.sorting-descending]="sortType.column === eTableColumn.AVERAGE_COMPLETION && sortType.ascending">
                    {{eTableColumn.AVERAGE_COMPLETION}}
                  </th>
                  <th class="text-center" *ngIf="columnToggle.getColumnEnabled(eTableColumn.ACTIONS)">Actions</th>
                </tr>
  
                <!-- Table Data -->
                <ng-container *ngFor="let dept of departmentsDisplay" [ngTemplateOutlet]="tableRow" [ngTemplateOutletContext]="{dept: dept, sub: 0}"></ng-container>
  
                <tr *ngIf="columnToggle.checkAllDisabled()">
                  <td class="text-center">
                    <div class="m-top-20 m-bottom-20">No columns selected to display, you can choose which columns to show by clicking the <span class="fal fa-columns text-info"></span> icon above</div>
                  </td>
                </tr>
              </table>
            </div>
  
            <!-- Paginatioon -->
            <app-pagination-new-component *ngIf="!columnToggle.checkAllDisabled()" class="m-top-10" [arrayData]="departmentsFiltered" [arrayDisplay]="departmentsDisplay" [amountToDisplay]="100" #pagination></app-pagination-new-component>
          </ng-container>

          <!-- No searches found -->
          <ng-template #noSearchResults>
            <div class="text-center p-bottom-15">
              <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
              <br>
              <p> There are no {{companyWording.departmentPlural}} available for this search</p>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<!-- #region - ALTERNATE PAGE STATES -->
<!-- LOADING -->
<ng-template #stateLoading>
  <div class="card">
    <div class="p-top-15 p-bottom-15 text-center">
      <span class="fal fa-spin fa-spinner fa-3x fa-fw"></span>
    </div>
  </div>
</ng-template>

<!-- ERROR -->
<ng-template #stateError>
  <div class="card">
    <div class="p-top-15 p-bottom-15 text-center">
      <p>
        <img class="icon-padding new-icon-height" src="/assets/img/Error.svg" />
        <br />
        An error occurred while getting your peoples goals:
        <br />
        {{state.errorMessage}}
      </p>
      <span class="btn-frankli-round btn-frankli-green" [routerLink]="'/dashboard'">
        Back to your dashboard?
      </span>
    </div>
  </div>
</ng-template>

<!-- #endregion -->

<ng-template #tableRow let-dept='dept' let-sub='sub'>
  <ng-container *ngIf="columnToggle">
    <!-- Table Data -->
    <tr>
      <td *ngFor="let indent of [].constructor(sub); index as si" class="text-center" width="50px"></td>
  
      <!-- Dropdown -->
      <td class="text-center" *ngIf="!columnToggle.checkAllDisabled()" (click)="dept.dropdownOpen = !dept.dropdownOpen" width="50px">
        <span class="fal fa-chevron-right dropdown-toggle" [class.dropdown-opened]="dept.dropdownOpen" *ngIf="dept.childDepartments.length > 0; else noDropdown"></span>
        <ng-template #noDropdown>
          <span class="fal fa-circle dropdown-toggle icon-no-dropdown"></span>
        </ng-template>
      </td>
  
      <td *ngIf="columnToggle.getColumnEnabled(eTableColumn.NAME)">
        <span>{{ dept.department.name }}</span>
      </td>
  
      <!-- Goal Count -->
      <td class="text-center" *ngIf="columnToggle.getColumnEnabled(eTableColumn.GOAL_COUNT)" width="60px">
        <span *ngIf="dept.childDepartments.length > 0" [title]="dept.goalsCount + ' goals'">{{dept.goalsCount}}</span>
      </td>
  
      <!-- Active Goal Count -->
      <td class="text-center" *ngIf="columnToggle.getColumnEnabled(eTableColumn.ACTIVE_GOAL_COUNT)" width="60px">
        <span *ngIf="dept.childDepartments.length > 0" [title]="dept.activeCount">{{dept.activeCount}}</span>
      </td>
  
      <!-- Complete Goal Count -->
      <td class="text-center" *ngIf="columnToggle.getColumnEnabled(eTableColumn.COMPLETE_GOAL_COUNT)" width="80px">
        <span *ngIf="dept.childDepartments.length > 0" [title]="dept.completeCount">{{dept.completeCount}}</span>
      </td>
  
      <!-- Total Objective Count -->
      <td class="text-center" *ngIf="columnToggle.getColumnEnabled(eTableColumn.TOTAL_OBJECTIVES)" width="130px">
        <span *ngIf="dept.childDepartments.length > 0" [title]="dept.objectiveCount">{{dept.objectiveCount}}</span>
      </td>
  
      <!-- Average Completion -->
      <td class="text-nowrap" *ngIf="columnToggle.getColumnEnabled(eTableColumn.AVERAGE_COMPLETION)" width="190px">
        <ng-container *ngIf="dept.childDepartments.length > 0">
          <div class="progress m-bottom-0 min-width-progress d-inline-block">
            <div class="progress-bar progress-bar-info" [style.width]="dept.averageCompletion+'%'">
              <span class="sr-only"></span>
            </div>
          </div>
          <div class="m-left-10 m-right-5 d-inline-block">{{dept.averageCompletion}}%</div>
        </ng-container>
      </td>
  
      <!-- Actions -->
      <td class="text-center action-icons" *ngIf="columnToggle.getColumnEnabled(eTableColumn.ACTIONS)" width="140px">
        <span (click)="opendepartmentGoals(dept)" class="fal fa-bullseye frankli-view expand-on-hover" *ngIf="dept.goalsCountSelf > 0"
          [title]="dept.department.name + ' has ' + dept.goalsCountSelf + ' goals'" angulartics2On="click" angularticsAction="TeamGoalsViewUser"
          angularticsCategory="Support">
        </span>
      </td>
    </tr>
  
    <!-- Dropdown contents -->
    <tr *ngIf="dept.dropdownOpen && !columnToggle.checkAllDisabled() && (dept.childDepartments.length > 0)">
      <td colspan="50">
        <ng-container *ngIf="(dept.childDepartments.length > 0); else noGoals">
          <div class="scroll-x">
            <table width="100%" class="goals-subtable">
              <ng-container *ngFor="let dept of dept.childDepartments" [ngTemplateOutlet]="tableRow" [ngTemplateOutletContext]="{dept : dept, sub: (sub+1)}"></ng-container>
            </table>
          </div>
        </ng-container>
        <ng-template #noGoals>
          <span class="m-left-20">This person does not have any goals</span>
        </ng-template>
      </td>
    </tr>
  </ng-container>
</ng-template>

<!-- #region -First login of the day modal -->
<app-modal #departmentGoalsModal [background]="'light'" [width]="'80%'">
  <div class="modal-body-content">
    <div class="goal-modal-stats">
      <app-goal-stats [goals]="viewingDepartment.goals"></app-goal-stats>
    </div>
    <div class="goal-modal-table">
      <app-goals-all-table-component [goals]="viewingDepartment.goals" (navigatedToGoal)="closeGoalModal()"></app-goals-all-table-component>
    </div>
  </div>
</app-modal>