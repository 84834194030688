import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Globals } from '../globals/globals';

/**
 * Allows user to dashboard reset if user.passwordReset === true
 */
@Injectable()
export class PasswordResetAllowGuard {

  constructor(
    private globals: Globals,
    private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.globals.user.passwordReset === true) {
      return true;
    }
    this.router.navigate(['/dashboard']);
    return false;
  }
}
