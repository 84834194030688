import { MockService } from '@app/mock/api/mock-service';
import { AnalyticsService } from '@app/shared/api/interfaces/analytics.service';
import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GoalAnalytics, GoalAnalyticsItem } from '@app/models/analytics/goal-analytics.model';
import { OneToOneAnalytics, OneToOneAnalyticsItem } from '@app/models/analytics/one-to-one-analytics.model';
import { SurveyAnalytics } from '@app/models/analytics/survey-analytics-model';
import { AnalyticsUser } from '@app/models/analytics/analytics-user.model';
import { ReviewAnalytics } from '@app/models/analytics/review-analytics.model';
import { clone, sanitizeUrl } from '@app/shared/utils/helpers';
import { mockGoalAnalytics, mockGoalAnalyticsAlternate } from '@app/mock/api/data/mockGoalAnalytics';
import { mockOneToOneAnalytics } from '@app/mock/api/data/mockOneToOneAnalytics';
import { mockSurveyAnalytics } from '@app/mock/api/data/mockSurveyAnalytics';
import { mockReviewAnalytics, mockReviewAnalyticsAlternate } from '@app/mock/api/data/mockReviewAnalytics';

@Injectable()
export class AnalyticsMockService implements MockService, AnalyticsService {

    handleRoute(req: HttpRequest<any>): any {
        // Don't use urlWithParams here because we don't care about the time period for mock analytics
        const url = sanitizeUrl(req.url);

        switch (true) {
            case url.endsWith('api/statistics/goals'):
                return this.getGoalAnalyticsForPeriod(new Date(req.params.get('periodStart')!), null, true, true);
            case url.match(/api\/statistics\/goals\/managers\/\d+$/) !== null:
                return this.getTeamGoalAnalyticsForPeriodByManagerId(null, null, true, true, null);
            case url.endsWith('api/statistics/one-to-ones'):
                return this.getOneToOneAnalyticsForPeriod(null, null);
            case url.endsWith('api/statistics/surveys'):
                return this.getSurveyAnalyticsOverview();
            case url.endsWith('api/statistics/evaluations/selected-cycles'):
                return this.getReviewAnalyticsForSelectedCycles(req.body);
            case url.endsWith('api/statistics/evaluations'):
                return this.getReviewAnalyticsForPeriod(new Date(req.params.get('periodStart')!), null);
        }
    }

    getGoalAnalyticsForPeriod(periodStart: Date, periodEnd: Date, includeArchived: boolean, includeCompleted: boolean): Observable<GoalAnalytics> | GoalAnalytics {
        const startYearIsEven: boolean = ((periodStart.getFullYear() % 2) === 0)
        return (startYearIsEven) ? mockGoalAnalytics : mockGoalAnalyticsAlternate;
    }

    getOneToOneAnalyticsForPeriod(periodStart: Date, periodEnd: Date): Observable<OneToOneAnalytics> | OneToOneAnalytics {
        return clone(mockOneToOneAnalytics);
    }

    getOneToOneAnalyticsForPeriodByManagerId(periodStart: Date, periodEnd: Date, managerId: number): Observable<OneToOneAnalyticsItem> | OneToOneAnalyticsItem {
        return clone(mockOneToOneAnalytics.all);
    }

    getReviewAnalyticsForPeriod(periodStart: Date, periodEnd: Date): Observable<ReviewAnalytics> | ReviewAnalytics {
        const startMonthIsEven: boolean = ((periodStart.getMonth() % 2) === 0)
        return (startMonthIsEven) ? mockReviewAnalytics : mockReviewAnalyticsAlternate;
    }

    getReviewAnalyticsForSelectedCycles(selectedCycleIds: number[]): Observable<ReviewAnalytics> | ReviewAnalytics {
        const selectedIsEven: boolean = ((selectedCycleIds.length % 2) === 0)
        return (selectedIsEven) ? mockReviewAnalytics : mockReviewAnalyticsAlternate;
    }

    getSurveyAnalyticsOverview(): Observable<SurveyAnalytics> | SurveyAnalytics {
        return mockSurveyAnalytics;
    }

    getTeamGoalAnalyticsForPeriodByManagerId(periodStart: Date, periodEnd: Date, includeArchived: boolean, includeCompleted: boolean, managerId: number): Observable<GoalAnalyticsItem> | GoalAnalyticsItem {
        return mockGoalAnalytics.team;
    }

    // No Ops listed below
    getOneToOneOverviewReport(): Observable<Blob> {
        return undefined;
    }

    getSurveyOverviewReport(): Observable<Blob> {
        return undefined;
    }

    getUserReport(users: AnalyticsUser[]): Observable<Blob> {
        return undefined;
    }
}