import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SessionLogAPIService } from './session-log.api.service';
import { SessionEventType } from '@app/models/session-log/session-event-type.model';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SessionLogService {

  private loggedInThisSession: boolean = false;

  constructor(private sessionLogAPIService: SessionLogAPIService) {

  }

  log(type: SessionEventType): void {
    // We want to track if a user has just logged in or is returning after logging in before
    // If SESSION_START_LOGIN has been sent then don't send SESSION_START_APP_INITIALISED
    if (type === SessionEventType.SESSION_START_LOGIN) {
      this.loggedInThisSession = true;
    }

    if (type === SessionEventType.SESSION_START_APP_INITIALISED) {
      if (this.loggedInThisSession === true) {
        return;
      }
    }

    console.log(`[session log] ${type}`);
    if (!environment.mock.enabled) {
      this.sessionLogAPIService.createSessionEvent(type).subscribe(event => {
        // TODO:
      }, (failure: HttpErrorResponse) => {
        // TODO:
      });
    }
  }

}
