import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { User } from 'app/models/user/user.model';
import { UserAPIService } from 'app/shared/api/user.api.service';
import { Globals } from 'app/shared/globals/globals';
import { PraiseAPIService } from 'app/shared/api/praise.api.service';
import { CreatePraiseDto } from 'app/models/feedback/praise.model';
import { CompanyAPIService } from 'app/shared/api/company.api.service';
import { CompanyFeatures, OfficeLocation } from 'app/models/company.model';
import { MapsAPIService } from 'app/shared/api/maps.api.service';
import { ProfilePraiseComponent } from 'app/shared/profile/profile-praise/profile-praise.component';
import { RoleName } from 'app/models/role.model';
import { OneToOneAPIService } from 'app/shared/api/one-to-one.api.service';
import { OneToOneSchedule } from 'app/models/one-to-one/one-to-one-schedule.model';
import { GoalTag } from 'app/models/goals/goal-tag.model';
import { GoalsAPIService } from 'app/shared/api/goals.api.service';
import { Breadcrumb } from 'app/models/breadcrumb.model';
import { BreadcrumbService } from 'app/shared/breadcrumbs/breadcrumbs.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalComponent } from '@app/shared/modal/modal.component';
import { FrankliValidators } from '@app/shared/validators/validators';
import { CompanyWording } from '@app/models/company/company-wording/company-wording.model';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';

declare var $: any;

interface State {
  loading: boolean,
  error: boolean,
  errorMessage: string
};

@Component({
  selector: 'app-user-cmp',
  templateUrl: 'user.component.html',
  styleUrls: ['./user.component.css'],
})

export class UserComponent implements OnInit, OnDestroy {
  readonly largeEditorMinLength = 10;
  readonly largeEditorMaxLengthSoft = 1000;
  readonly largeEditorMaxLengthHard = 2000;
  readonly editorToolbar = 'undo redo | formatselect | bold italic underline strikethrough | help';

  @ViewChild('praiseModal') private praiseModal !: ModalComponent;
  @ViewChild('praiseComp') private praiseComp !: ProfilePraiseComponent;
  @ViewChild('aboutMeCard') private aboutMe !: ElementRef;

  eRole = RoleName;
  eFeature = CompanyFeatures;

  id: number;
  user: User;
  userProfile: User;

  praiseSubmitted = false;

  userBirthday: string;
  titleText: string;
  workingFrom: string

  userTimezone: string;
  usersTime: string;

  officeLocations: OfficeLocation[];
  officeLocation: number | null;
  showManagerLink = false;
  praiseForm!: FormGroup;
  archived: boolean;
  hasOneToOne: OneToOneSchedule;

  availableValues: GoalTag[];
  selectedValues: GoalTag[];

  state: State;
  breadcrumb: Breadcrumb;

  minHeightContact: number;
  companyWording: CompanyWording;

  constructor(private userService: UserAPIService,
    public route: ActivatedRoute,
    public globals: Globals,
    private router: Router,
    private praiseAPIService: PraiseAPIService,
    private companyAPIService: CompanyAPIService,
    private goalsAPIService: GoalsAPIService,
    private oneToOneAPIService: OneToOneAPIService,
    private mapsAPIService: MapsAPIService,
    private breadcrumbService: BreadcrumbService,
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef, 
  ) {
    this.companyWording = this.globals.company.companyWording;
    this.id = undefined!;
    this.user = undefined!;
    this.userProfile = undefined!;
    this.usersTime = undefined!;
    this.userTimezone = undefined!;

    this.officeLocations = [];
    this.availableValues = [];
    this.selectedValues = [];

    this.officeLocation = null;

    this.archived = false;

    this.userBirthday = '';
    this.titleText = '';
    this.workingFrom = '';

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    };

    this.hasOneToOne = {} as OneToOneSchedule;
    this.breadcrumb = this.breadcrumbService.init(this.route);

    this.minHeightContact = 0;
  }

  // #region - LIFECYCLE HOOKS
  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {
    this.breadcrumbService.remove(this.breadcrumb);
  }
  // #endregion

  getData() {
    this.route.paramMap.subscribe(
      (params) => {
        const id = params.get('id');

        forkJoin([
          this.companyAPIService.getAllOfficeLocations(),
          this.goalsAPIService.getTags('company_value')
        ]).subscribe(
          ([officeLocations, companyValues]) => {
            this.officeLocations = officeLocations;

            // Populate company values
            this.availableValues = companyValues.map(cv => {
              cv.selected = false;
              return cv;
            });

            // If no ID supplied or current users ID is supplied, get current users data
            if (!id || (+id === this.globals.user.id)) {
              return this.getCurrentUserData();
            }

            this.id = +id;

            // If ID is not a number, show error
            if (isNaN(this.id)) {
              this.doError('Invalid user ID');
              setTimeout(() => this.getContactHeight(), 100);
              return;
            }

            this.getOtherUsersData(this.id);
          },
          (err: HttpErrorResponse) => this.doError(err.message)
        );
      }
    );

    this.initPraiseForm();
  }

  getCurrentUserData() {
    this.breadcrumbService.updateSpecificBreadcrumb(this.breadcrumb, 'My Profile', null);
    this.populateUserDetails();
    this.getManager();

    this.getUserTimezone(this.globals.user);

    this.startTimeLoop();

    this.state.loading = false;

    this.startCheckContactHeight();
  }

  getOtherUsersData(id: number) {
    // Get other users profile info
    this.userService.getById(id).subscribe(
      (userResponse) => {
        this.userProfile = userResponse;
        this.breadcrumbService.updateSpecificBreadcrumb(this.breadcrumb, `${userResponse.firstName} ${userResponse.lastName}'s Profile`, null);

        // Check if archived
        this.archived = this.userProfile.roles.some(role => role.name === RoleName.ARCHIVED);

        // Get 1:1s
        this.oneToOneAPIService.getOneToOneSchedulesForManagerMe().subscribe(
          (success: OneToOneSchedule[]) => {
            this.hasOneToOne = success.find(x => x.user.id === this.userProfile.id && x.status !== 'ARCHIVED')!;
            if (!this.hasOneToOne) {
              this.oneToOneAPIService.getOneToOneSchedulesForUserMe().subscribe(resp => {
                this.hasOneToOne = resp.find(x => x.manager.id === this.userProfile.id && x.status !== 'ARCHIVED')!;
              });
            }
          }
        );

        this.titleText = `Hey ${this.globals.user.firstName}, lets hear your praise for ${this.userProfile.firstName}!`;

        this.userBirthday = (new Date(this.userProfile.dateOfBirth!).toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
        }));

        this.getManager();

        this.getUserTimezone(userResponse);

        this.startTimeLoop();

        this.state.loading = false;

        this.startCheckContactHeight();
      },
      (err: HttpErrorResponse) => this.doError(err.message)
    );
  }

  getUserTimezone(user: User): void {
    if(user.homeAddress === null){
      this.userTimezone = 'UTC'; 
    } else {
      this.userTimezone = user.homeAddress.timezone;
    }
  }

  startTimeLoop() {
    this.updateTime();
    setInterval(() => this.updateTime(), 30000);
  }

  updateTime() {
    this.usersTime = moment().tz(this.userTimezone).format('H:mm a');
  }

  startCheckContactHeight() {
    setTimeout(() => this.getContactHeight(), 100);
  }

  getContactHeight() {
    if (this.aboutMe && this.aboutMe.nativeElement) {
      this.minHeightContact = this.aboutMe.nativeElement.clientHeight;
    }
  }

  officeChange(id: number | null): void {
    let officeLocation = null;
    if (id !== null) {
      const office = this.officeLocations.find(x => x.id === id);
      if (office !== undefined) {
        officeLocation = office;
      }
    }

    this.userService.updateUserOfficeLocation(officeLocation).subscribe(user => {
      if (this.globals.user.id === user.id) {
        this.globals.user = user;
        this.userProfile = user;
      }
      this.getManager();
    }, (failure: HttpErrorResponse) => {
      // TODO: error handling
    });
  }

  getManager(): void {
    if (+this.userProfile.managerId === +this.userProfile.id) {
      this.showManagerLink = false;
      this.userProfile.managerName = 'No Manager';
    } else if (this.userProfile.managerId !== null) {
      this.userService.getById(this.userProfile.managerId).subscribe(manager => {
        this.showManagerLink = (manager.userState !== 'INVITED');
        this.userProfile.managerName = manager.firstName + ' ' + manager.lastName;
        this.userProfile.managerId = manager.id;
      });
    } else {
      this.showManagerLink = false;
    }
  }

  getPraise(): void {
    this.praiseComp.getPraise();
  }

  checkForManagerRole() {
    return this.globals.user.roles.some(x => x.name === RoleName.MANAGER || x.name === RoleName.ADMIN || x.name === RoleName.FRANKLI_ADMIN);
  }

  private populateUserDetails(): void {
    this.userProfile = this.globals.user;
    if (this.globals.user.officeLocation === null) {
      this.officeLocation = null;
    } else {
      this.officeLocation = this.globals.user.officeLocation.id;
    }
    // making the date show up as Month Date
    this.userBirthday = (new Date(this.userProfile.dateOfBirth!).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
    }));
  }

  toggleValue(value: GoalTag) {
    value.selected = !value.selected;
  }

  // TODO: Make all these work properly
  getEmploymentTime() {
    return 345;
  }

  getCompanyName() {
    return 'Frankli';
  }

  getHighFives() {
    return 104;
  }

  getEndorsements() {
    return 69;
  }

  getPraiseCount() {
    return 89;
  }

  createOneToOne(user: User) {
    this.router.navigate(['/one-to-one/create', this.id]);
  }

  // #region - PROFILE PRAISE
  initPraiseForm() {
    this.praiseForm = this.formBuilder.group({
      praise: new FormControl('', [Validators.required, FrankliValidators.softMinValidation(this.largeEditorMinLength), FrankliValidators.softMaxValidation(this.largeEditorMaxLengthSoft), Validators.maxLength(this.largeEditorMaxLengthHard)]),
    });

    this.praiseForm.controls.praise.valueChanges.subscribe(res => {
      this.cdRef.detectChanges();
    })
  }

  startPraise(): void {
    this.initPraiseForm();
    this.praiseSubmitted = false;
    this.praiseModal.show();
  }

  cancelPraise(hideModal: boolean = true): void {
    this.praiseSubmitted = false;
    this.praiseForm.reset();

    if (hideModal) {
      this.praiseModal.hide();
    }
  }

  confirmPraise() {
    this.praiseSubmitted = true;
    if (this.praiseForm.valid) {
      const praise = new CreatePraiseDto(this.userProfile.id, this.praiseForm.controls.praise.value, this.availableValues.filter(av => av.selected));
      this.praiseAPIService.submitPraise(praise).subscribe(response => {
        $.notify({
          icon: 'fal fa-thumbs-up',
          message: 'Praise successfully posted',
        }, {
          type: 'frankli-blue',
          placement: {
            from: 'top',
            align: 'right',
          },
        });
        this.praiseSubmitted = false;
        this.praiseForm.controls.praise.setValue('');
        this.availableValues.forEach(av => av.selected = false);
        this.getPraise();
        this.praiseModal.hide();
      });
    }
  }
  // #endregion

  doError(message: string) {
    this.state.loading = false;
    this.state.error = true;
    this.state.errorMessage = message;
  }
}
