import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Globals } from '../globals/globals';
import { RoleName } from 'app/models/role.model';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(
    private globals: Globals,
    private router: Router
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const roles = route.data['roles'] as Array<RoleName>;

    for (let i = 0; i < this.globals.user.roles.length; i++) {
      if (roles.includes(this.globals.user.roles[i].name)) {
        (<any>window).Intercom('update');

        return true;
      }
    }

    // Redirect to root so other guards can handle where the request should go
    this.router.navigateByUrl('');
    return false;
  }
}
