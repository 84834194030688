import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {NavbarComponent} from '../../navbar/navbar.component';
import {AuthAPIService} from '@app/shared/auth/auth.api.service';
import {environment} from '../../../../environments/environment';
import {Globals} from '@app/shared/globals/globals';
import {CompanyFeatures} from '@app/models/company.model';

declare var $: any;

@Component({
    selector: 'app-login-layout',
    templateUrl: './login-layout.component.html',
    styleUrls: ['./login-layout.component.css']
})

export class LoginLayoutComponent implements OnInit {
    sidebarVisible: boolean;
    showNavbar: boolean;
    toggleButton;

    showNavbarSignUp: boolean;

    @ViewChild(NavbarComponent) navbar !: NavbarComponent;

    constructor(private router: Router, private element: ElementRef, private authService: AuthAPIService, private globals: Globals) {
        this.sidebarVisible = false;
        this.showNavbar = false;
        this.showNavbarSignUp = false;
    }

    ngOnInit() {
        // TODO: This component seems to reload on every page navigation.
        // this is causing the sidebar close animation to be too snappy
        // should revisit this
        switch (this.router.url) {
            case '/sign-up':
            case '/reset/password': {
                this.showNavbarSignUp = true;
                break;
            }
            default: {
                this.showNavbar = true;
                break;
            }
        }

        const body = document.getElementsByTagName('body')[0];

        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];

        // make sure sidebar closes on navigation
        try {
            console.log('remove open');
            body.classList.remove('nav-open');
            console.log('remove toggled');
            this.toggleButton.classList.remove('toggled');
        } catch (e) {

        }
        this.sidebarVisible = false;
    }

    sidebarOpen() {
        const body = document.getElementsByTagName('body')[0];
        const context = this;
        setTimeout(function () {
            try {
                context.toggleButton.classList.add('toggled');
            } catch (e) {

            }
        }, 500);
        body.classList.add('nav-open');
        this.sidebarVisible = true;
    }
    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];

        // TODO: fix this issue so the correct button shows
        try {
            this.toggleButton.classList.remove('toggled');

        } catch (e) {

        }

        body.classList.remove('nav-open');
        this.sidebarVisible = false;
    }
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        console.log(this.sidebarVisible);
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    }

    logout() {
        this.authService.logout();
        // if (this.globals.company.features.find(f => f.feature === CompanyFeatures.SSO).enabled) {
        //     this.authService.logoutSSO();
        // } else {
        //     this.authService.logout();
        // }
    }
}

