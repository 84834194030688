import { Component, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from 'app/shared/breadcrumbs/breadcrumbs.service';
import { Breadcrumb } from 'app/models/breadcrumb.model';
import { forkJoin } from 'rxjs';
import { Goal, GoalType } from '@app/models/goals/goal.model';
import { GoalsAPIService } from '@app/shared/api/goals.api.service';
import { FilterCreation, FilterMethod, FilterOption } from '@app/models/universal-filter-option.model';
import { Globals } from '@app/shared/globals/globals';
import * as moment from 'moment';
import { CompanyFeatures } from '@app/models/company.model';
import { ColumnToggleComponent } from '@app/shared/column-toggle/column-toggle.component';
import { PaginationNewComponent } from '@app/shared/pagination/pagination-new/pagination-new.component';
import { GoalDirectReportOverview } from '@app/models/goals/goal-direct-report-overview.model';
import { User } from '@app/models/user/user.model';
import { ModalComponent } from '@app/shared/modal/modal.component';
import { GoalsAllTableComponent } from '../goals-all-table/goals-all-table.component';
import { FormControl } from '@angular/forms';
import { RoleName } from '@app/models/role.model';
import { NestedOutput, NestedOutputResult } from '@app/shared/universal-filter/universal-filter.component';
import { CompanyWording } from '@app/models/company/company-wording/company-wording.model';

// #region - types
enum TableColumn {
  NAME = 'Name',
  GOAL_COUNT = 'Goals',
  ACTIVE_GOAL_COUNT = 'Active',
  COMPLETE_GOAL_COUNT = 'Complete',
  TOTAL_OBJECTIVES = 'Total Objectives',
  AVERAGE_COMPLETION = 'Average Completion',
  ACTIONS = 'Actions'
}

interface PageState {
  loading: boolean;
  error: boolean;
  errorMessage: string;
}

interface PageUser extends GoalDirectReportOverview {
  dropdownOpen: boolean;
  directReports: PageUser[];
  requiredTreeUser: boolean;
}

interface Sort {
  column: TableColumn;
  ascending: boolean;
}
// #endregion

@Component({
  selector: 'app-goal-overview-manager',
  templateUrl: './goal-overview-manager.component.html',
  styleUrls: ['./goal-overview-manager.component.css']
})
export class GoalOverviewManagerComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('columnToggle') columnToggle!: ColumnToggleComponent;
  @ViewChild('pagination') pagination!: PaginationNewComponent;
  @ViewChild('userGoalsModal') userGoalsModal!: ModalComponent;
  @ViewChild('userGoalsTable') userGoalsTable!: GoalsAllTableComponent;

  breadcrumb: Breadcrumb;
  state: PageState;

  // Stats
  roundProgress: {
    left: string;
    right: string;
  }

  // Table data
  eTableColumn = TableColumn;
  columns: string[];

  rootUser: PageUser // Not really a user, used for aggregating stats
  directReports: PageUser[];
  directReportsFiltered: PageUser[];
  directReportsDisplay: PageUser[];

  // Sorting
  sortType: Sort;

  // Universal filter
  filterOptions: FilterOption[];
  searchProps: string[];
  lastFilterResult: NestedOutputResult;

  eGoalType = GoalType;
  eFeature = CompanyFeatures

  // Modal
  viewingUser: {
    user: User;
    goals: Goal[];
  }
  loadingUserGoals: boolean;

  viewAsAdmin: FormControl;
  companyWording: CompanyWording;

  constructor(
    public route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private goalsAPIService: GoalsAPIService,
    public globals: Globals, 
    
  ) {
    this.companyWording = this.globals.company.companyWording;

    this.roundProgress = {
      left: 'rotate(0)',
      right: 'rotate(0)'
    }

    this.rootUser = undefined!;
    this.directReports = [];
    this.directReportsFiltered = [];
    this.directReportsDisplay = [];

    this.sortType = {
      column: TableColumn.ACTIONS,
      ascending: true
    }

    this.viewingUser = {
      user: undefined!,
      goals: []
    }
    this.loadingUserGoals = false;

    this.filterOptions = [];
    this.searchProps = [];
    this.lastFilterResult = {
      nonMatchesIncluded: [],
      tree: {}
    };

    this.columns = Object.keys(TableColumn).map(c => TableColumn[c]);

    this.state = {
      loading: true,
      error: false,
      errorMessage: ''
    }
    this.breadcrumb = this.breadcrumbService.init(this.route);

    this.viewAsAdmin = new FormControl(false, []);
    this.viewAsAdmin.valueChanges.subscribe(res => {
      this.getData()
    })
  }

  // #region - LIFECYCLE HOOKS
  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {
    this.breadcrumbService.remove(this.breadcrumb);
  }

  ngOnChanges() {
  }
  // #endregion

  // Get the initial page data
  getData() {
    const route = this.viewAsAdmin.value ? this.goalsAPIService.getTeamGoalsOverviewCeo() : this.goalsAPIService.getTeamGoalsOverview();
    forkJoin([
      route
    ]).subscribe(([teamGoalsOverview]) => {
      // Trigger empty state if no stats found
      if (!teamGoalsOverview) {
        return this.displayError('Failed to get ' + this.companyWording.team.toLowerCase() + ' goals');
      }

      this.directReports = teamGoalsOverview.map(u => this.parseRootUser(u));
      this.directReportsFiltered = this.directReports;

      this.rootUser = this.aggregateUserStats(this.directReports);

      this.getUniversalFilterData();
      this.doSort(TableColumn.NAME);

      this.state.loading = false;
    }, (err: Error) => {
      this.displayError(err.message);
    });
  }

  parseRootUser(goalDirectReportOverview: GoalDirectReportOverview): PageUser {
    const rootUser = goalDirectReportOverview as PageUser;

    rootUser.dropdownOpen = false;
    this.getRoundProgress(rootUser.averageCompletion);
    rootUser.directReports = this.convertReportArrayToPageUser(rootUser.directReports);
    rootUser.requiredTreeUser = false;

    return rootUser;
  }

  aggregateUserStats(users: PageUser[]): PageUser {
    const user: PageUser = {
      requiredTreeUser: false,
      dropdownOpen: false,
      user: undefined!,
      directReports: [],

      goalsCountSelf: 0,
      goalsCount: 0,
      activeCount: 0,
      completeCount: 0,
      objectiveCount: 0,
      averageCompletion: 0,
    }

    users.forEach(u => {
      user.goalsCountSelf += u.goalsCountSelf;
      user.goalsCount += u.goalsCount;
      user.activeCount += u.activeCount;
      user.completeCount += u.completeCount;
      user.objectiveCount += u.objectiveCount;
      user.averageCompletion += u.averageCompletion;
    });

    return user;
  }

  displayError(message: string) {
    this.state = {
      loading: false,
      error: true,
      errorMessage: message
    }
  }

  convertReportArrayToPageUser(teamGoalsOverview: GoalDirectReportOverview[]): PageUser[] {
    return teamGoalsOverview.map(tg => this.convertReportToPageUser(tg));
  }

  convertReportToPageUser(user: GoalDirectReportOverview): PageUser {
    const report = user as PageUser;

    if (report) {
      report.dropdownOpen = false;
      report.requiredTreeUser = false;
      report.directReports = report.directReports.map(r => this.convertReportToPageUser(r));
    }

    return report;
  }

  getUniversalFilterData() {
    this.filterOptions = this.directReports.map(dr => this.getFilterDataForItem(dr));
    this.searchProps = ['user_name'];
  }

  getFilterDataForItem(item: PageUser): FilterOption {
    // User Names
    // Goal count
    // Active goal count
    // Complete goal count
    // Key result count
    // Average completion
    const option: FilterOption = {
      id: item.user.id, // Users ID, for returning a result that can be filtered
      properties: {
        'user_name': {value: `${item.user.firstName} ${item.user.lastName}`, filterMethod: FilterMethod.OR}, // Users name - searchable
        'position': {value: (item.user.position ? item.user.position.name : undefined), filterMethod: FilterMethod.OR},
        'department': {value: (item.user.organisationalUnit ? item.user.organisationalUnit.name : undefined), filterMethod: FilterMethod.OR}
      },
      nestedItems: item.directReports.map(dr => this.getFilterDataForItem(dr))
    }

    option.properties['goals'] = {value: FilterCreation.getRangeFilter(item.goalsCount), filterMethod: FilterMethod.OR};
    option.properties['active_goals'] = {value: FilterCreation.getRangeFilter(item.activeCount), filterMethod: FilterMethod.OR};
    option.properties['complete_goals'] = {value: FilterCreation.getRangeFilter(item.completeCount), filterMethod: FilterMethod.OR};
    option.properties['average_completion'] = {value: FilterCreation.getPercentRangeFilter(item.averageCompletion), filterMethod: FilterMethod.OR};

    return option;
  }

  getChildUserNames(directReports: PageUser[]): string[] {
    let reportNames: string[] = [];
    directReports.forEach(dr => {
      reportNames.push(`${dr.user.firstName} ${dr.user.lastName}`)
      if (dr.directReports.length > 0) {
        reportNames = [...reportNames, ...this.getChildUserNames(dr.directReports)]
      }
    });
    return reportNames;
  }

  universalFilterEmit(result: NestedOutputResult) {
    this.lastFilterResult = result;
    const rootIDs = Object.keys(result.tree);

    const output: PageUser[] = [];
    rootIDs.forEach(id => {
      const user = JSON.parse(JSON.stringify(this.directReports.find(u => u.user.id === Number(id))));
      if (user) {
        user.directReports = this.filterReportsByNestedOutput(user.directReports, result.tree[id]);
        user.requiredTreeUser = this.lastFilterResult.nonMatchesIncluded.includes(+id);
        user.dropdownOpen = user.requiredTreeUser;
        output.push(user);
      }
    });

    this.directReportsFiltered = output;
  }

  filterReportsByNestedOutput(users: PageUser[], result: NestedOutput): PageUser[] {
    const output: PageUser[] = [];

    for (const id in result) {
      if (result.hasOwnProperty(id)) {
        const user = users.find(u => u.user.id === Number(id));
        if (user) {
          user.directReports = this.filterReportsByNestedOutput(user.directReports, result[id]);
          user.requiredTreeUser = this.lastFilterResult.nonMatchesIncluded.includes(+id);
          user.dropdownOpen = user.requiredTreeUser;
          output.push(user);
        }
      }
    }

    return output;
  }

  // #region - SORTING AND FILTERING
  doSort(column: TableColumn) {
    if (column === this.sortType.column) {
      this.sortType.ascending = !this.sortType.ascending;
      this.directReportsFiltered = this.directReportsFiltered.reverse();
    } else {
      this.sortType = {
        column: column,
        ascending: false
      }
      this.directReportsFiltered = this.sortByColumn(column);
    }

    if (this.pagination) {
      this.pagination.update();
    }
  }

  sortByColumn(column: TableColumn): PageUser[] {
    switch (column) {
      case TableColumn.NAME:
        return this.directReports.sort((a, b) => {
          const itemA = `${a.user.firstName} ${a.user.lastName}`;
          const itemB = `${b.user.firstName} ${b.user.lastName}`;
          return ((itemA > itemB) ? 1 : -1);
        });
      case TableColumn.GOAL_COUNT:
        return this.directReports.sort((a, b) => {
          if (a.goalsCount === b.goalsCount) {
            return 0;
          };
          return ((a.goalsCount > b.goalsCount) ? 1 : -1);
        });
      case TableColumn.ACTIVE_GOAL_COUNT:
        return this.directReports.sort((a, b) => {
          return ((a.activeCount > b.activeCount) ? 1 : -1);
        });
      case TableColumn.COMPLETE_GOAL_COUNT:
        return this.directReports.sort((a, b) => {
          return ((a.completeCount > b.completeCount) ? 1 : -1);
        })
      case TableColumn.TOTAL_OBJECTIVES:
        return this.directReports.sort((a, b) => {
        return ((a.objectiveCount > b.objectiveCount) ? 1 : -1);
        });
      case TableColumn.AVERAGE_COMPLETION:
        return this.directReports.sort((a, b) => {
          return ((a.averageCompletion > b.averageCompletion) ? 1 : -1);
        });
      default: // Unknown or name col
        return this.directReports.sort((a, b) => {
          const itemA = `${a.user.firstName} ${a.user.lastName}`;
          const itemB = `${b.user.firstName} ${b.user.lastName}`;
          return ((itemA > itemB) ? 1 : -1);
        });
    }
  }
  // #endregion

  getDaysRemaining(endDate: string) {
    const end = new Date(endDate);

    const a = moment(end);
    const b = moment(new Date());

    const difference = a.diff(b, 'days');
    if (difference === 0) {
      return 'Due today';
    } else if (difference === 1) {
      return '1 day remaining';
    } else if (difference > 1) {
      return difference + ' days remaining';
    } else if (difference === -1) {
      return '1 day overdue';
    } else if (difference < -1) {
      return (-1 * difference) + ' days overdue';
    }

    return '';
  }

  getDaysRemainingTotal(endDate: string) {
    const end = new Date(endDate);

    const a = moment(end);
    const b = moment(new Date());

    return a.diff(b, 'days');
  }

  openUserGoals(user: PageUser) {
    if (user && !this.loadingUserGoals) {
      this.loadingUserGoals = true;
      this.goalsAPIService.getIndividualGoalsByUserId(user.user.id).subscribe(goals => {
        goals = Goal.getGoalArrayCompletionPercentage(goals);

        this.viewingUser = {
          user: user.user,
          goals: goals
        };
        this.userGoalsModal.show();

        setTimeout(() => {
          if (this.userGoalsTable) {
            this.userGoalsTable.doSort(this.userGoalsTable.ePageColumns.STATE);
          }
        }, 250);
      }, () => {}, () => this.loadingUserGoals = false)
    }
  }

  getRoundProgress(progress: number): {left: string, right: string} {
    let left = 0;
    let right = 0;
    if (progress > 0) {
      if (progress <= 50) {
        right = (progress / 100 * 360)
      } else {
        right = 180;
        left = ((progress - 50) / 100 * 360)
      }
    }
    return {
      left: `rotate(${left}deg)`,
      right: `rotate(${right}deg)`
    };
  }

  closeGoalModal() {
    this.userGoalsModal.hide();
  }

  userIsAdmin() {
    return (this.globals.hasRole(RoleName.ADMIN) || this.globals.hasRole(RoleName.FRANKLI_ADMIN));
  }

  trackByFn(index: number, item: any) {
    return index;
  }
  
  toggleViewAsAdmin() { // Doing this to fix chrome checkbox bug
    const currentValue = this.viewAsAdmin.value as boolean;
    this.viewAsAdmin.setValue(!currentValue);
  }
}
