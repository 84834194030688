import { Component } from '@angular/core';
import { Competency } from '@app/models/competencies/competency.model';
import { ModalComponent } from '@app/shared/modal/modal.component';

@Component({
  selector: 'app-competency-modal',
  templateUrl: './competency-modal.component.html',
  styleUrls: ['./competency-modal.component.css']
})
export class CompetencyModalComponent extends ModalComponent {
  competency: Competency;

  constructor() {
    super();
  }

  ngOnInit() {
  }

  showFor(competency: Competency) {
    this.competency = competency;
    this.show();
  }
}
