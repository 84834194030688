import { MockService } from '@app/mock/api/mock-service';
import { UserService } from '@app/shared/api/interfaces/user.service';
import { Observable } from 'rxjs';
import { User, UserInit, UserServerSide, UserState } from '@app/models/user/user.model';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { mockCompanies } from '@app/mock/api/data/mockCompanies';
import { UserAdministrateDto } from '@app/models/user/administrate-user.dto';
import { Role } from '@app/models/role.model';
import { Interest } from '@app/models/interest.model';
import { UserProfileDetails } from '@app/models/user/user-profile-details.model';
import { UserPersonalDetails } from '@app/models/user/user-personal-details.model';
import { CreateUserDto } from '@app/models/user/create-user.dto';
import { UserMinimal } from '@app/models/user/user-minimal.model';
import { OfficeLocation } from '@app/models/company.model';
import { UserCompleteSignUp } from '@app/models/user/user-complete-sign-up.dto';
import { UserLocationDetails } from '@app/models/user/user-location-details.dto';
import { ChangePasswordDto } from '@app/models/user/change-password.dto';
import { mockRoles } from '@app/mock/api/data/mockRoles';
import { Globals } from '@app/shared/globals/globals';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { sanitizeUrl } from '@app/shared/utils/helpers';

@Injectable()
export class UserMockService implements MockService, UserService {
    constructor(private globals: Globals) { }

    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);

        switch (true) {
            case url.endsWith('api/user/me'):
                return this.getMe();
            case url.endsWith('api/user/all'):
                return this.getAllUsers();
            case url.endsWith('api/user/all/minimal'):
                return this.getAllUsersMinimal();
            case url.endsWith('api/user/all/manager'):
                return this.getOtherUsersAndManagers();
            case url.endsWith('api/user/all/admin'):
                return this.getAllUsersAdmin();
            case url.endsWith('api/user/other'):
                return this.getAllOtherUsers();
            case url.endsWith('api/user/init'):
                return this.getUserInit();
            case url.endsWith('api/user/similar'):
                return this.getUsersSimilar();
            case url.endsWith('api/user/onboarded'):
                return this.getAllUsersOnboarded();
            case url.endsWith('api/user/onboarded/count'):
                return this.countAllUsersOnboarded();
            case url.endsWith('api/user/evaluation'):
                return this.getUsersOnboardedNotInCycles();
            case url.endsWith('api/user/roles'):
                return this.getAllRoles();
            case url.endsWith('api/user/manager/me'):
                return this.getUsersByManagerMe();
            case url.endsWith('api/user/search'):
                const searchArgument = req.body as string;
                return this.searchUsers(searchArgument);
            case url.endsWith('api/user/socialise'):
                return this.getUsersSocialise();
            case url.match(/api\/user\/manager\/\d+$/) !== null:
                const managerId = +url.split('/')[3];
                return this.getUsersByManagerId(managerId);
            case url.match(/api\/user\/\d+$/) !== null:
                const userId = +url.split('/')[2];
                return this.getById(userId);
            case url.match(/api\/user\/interests\/\d+$/) !== null:
                const interestId = +url.split('/')[3];
                return this.getUsersByInterestId(interestId);
        }
    }

    getAllUsers(): Array<User> {
        return mockUsers;
    }

    getById(id: number): User {
        return mockUsers.find(u => u.id === id);
    }

    getUserInit(): UserInit {
        return {
            user: mockUsers[0],
            company: mockCompanies[0],
            access_token: 'mock_token'
        };
    }

    getUsersSimilar(): Array<UserServerSide> {
        return mockUsers.filter(u => u.id !== this.globals.user.id).map(u => ({
            ...u,
            interests: [...u.personalInterests, ...u.professionalInterests]
        }));
    }

    countAllUsersOnboarded(): number {
        return mockUsers.length;
    }

    getAllOtherUsers(): Observable<Array<User>> | User[] {
        return mockUsers.filter(u => u.id !== this.globals.user.id);
    }

    getAllRoles(): Observable<Array<Role>> | Role[] {
        return mockRoles;
    }

    getAllUsersAdmin(): Observable<Array<User>> | User[] {
        return mockUsers;
    }

    getAllUsersMinimal(): Observable<Array<UserMinimal>> | UserMinimal[] {
        return mockUsers.map(u => UserMockService.toUserMinimal(u));
    }

    private static toUserMinimal(user: UserServerSide): UserMinimal {
        return {
            ...user,
            organisationalUnitId: user.organisationalUnit.id,
            officeLocationId: user.officeLocation.id,
            positionId: user.position.id
        }
    }

    getAllUsersOnboarded(): Observable<Array<User>> | User[] {
        return mockUsers.filter(u => u.userState === UserState.FULL);
    }

    getMe(): Observable<User> | User {
        return mockUsers.find(u => u.id === this.globals.user.id);
    }

    getOtherUsersAndManagers(): Observable<Array<User>> | User[] {
        return mockUsers.filter(u => u.id !== this.globals.user.id);
    }

    getUsersByInterestId(id: number): Observable<Array<User>> | User[] {
        return mockUsers.filter(u => u.personalInterests.map(i => i.id).includes(id));
    }

    getUsersByManagerId(managerId: number): Observable<Array<User>> | User[] {
        return mockUsers.filter(u => u.managerId === managerId);
    }

    getUsersByManagerMe(): Observable<Array<User>> | User[] {
        return mockUsers.filter(u => u.managerId === this.globals.user.id);
    }

    getUsersOnboardedNotInCycles(): Observable<Array<User>> | User[] {
        return mockUsers;
    }

    getUsersSocialise(): Observable<Array<User>> | User[] {
        return mockUsers.filter(u => u.interestsSocialise.map(i => i.id).some(id => this.globals.user.interestsSocialise.map(i => i.id).includes(id)));
    }

    searchUsers(sarg: string): Observable<Array<User>> | User[] {
        return mockUsers.filter(u => `${u.firstName.toLowerCase()} ${u.lastName.toLowerCase()}`.includes(sarg.trim().toLowerCase()));
    }

    // No-Ops listed below
    getUserHash(): any {
        return undefined;
    }

    administrateUser(userId: number, userAdminstrateDto: UserAdministrateDto): any {
        return undefined;
    }

    archiveUser(userId: number): User {
        return undefined;
    }

    create(user: CreateUserDto): UserServerSide {
        return undefined;
    }

    createAllBulkUploadUsers(users: Array<CreateUserDto>): Observable<Array<UserServerSide>> | UserServerSide[] {
        return undefined;
    }

    createBulkUploadUser(user: CreateUserDto): Observable<UserServerSide> | UserServerSide {
        return undefined;
    }

    downloadUserData(): any {
        return undefined;
    }

    frankliAdminExportAllAdmins(): any {
        return undefined;
    }

    frankliAdminExportAllManagers(): any {
        return undefined;
    }

    inviteAllPendingUsers(): Observable<Array<User>> | User[] {
        return undefined;
    }

    inviteUser(userId: number): Observable<User> | User {
        return undefined;
    }

    resetUserPassword(email: string): Observable<number> | number {
        return undefined;
    }

    revokeAccess(userId: number): Observable<User> | User {
        return undefined;
    }

    unarchiveUser(userId: number, role: Role): Observable<User> | User {
        return undefined;
    }

    unrevokeAccessUser(userId: number, role: Role): Observable<User> | User {
        return undefined;
    }

    updateLocationDetails(user: UserLocationDetails): Observable<User> | User {
        return undefined;
    }

    updatePassword(changePasswordDto: ChangePasswordDto): Observable<User> | User {
        return undefined;
    }

    updatePasswordFirstTimeIn(password: String): Observable<User> | User {
        return undefined;
    }

    updatePasswordOnReset(password: String): Observable<User> | User {
        return undefined;
    }

    updatePersonalDetails(user: UserPersonalDetails): Observable<User> | User {
        return undefined;
    }

    updatePersonalInterests(interests: Array<Interest>): Observable<User> | User {
        return undefined;
    }

    updateProfessionalInterests(interests: Array<Interest>): Observable<User> | User {
        return undefined;
    }

    updateProfileDetails(user: UserProfileDetails): Observable<User> | User {
        return undefined;
    }

    updateProfilePicture(file: any, fileExtension: string): Observable<User> | User {
        return undefined;
    }

    updateUserCompleteSignUp(userCompleteSignUp: UserCompleteSignUp): Observable<User> | User {
        return undefined;
    }

    updateUserInterestsCoach(interests: Array<Interest>): any {
    }

    updateUserInterestsMentor(interests: Array<Interest>): any {
    }

    updateUserInterestsSocialise(interests: Array<Interest>): any {
    }

    updateUserOfficeLocation(officeLocation: OfficeLocation | null): Observable<User> | User {
        return undefined;
    }

    userRefresh(): any {
        return undefined;
    }
}

