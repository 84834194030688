import { MockService } from '@app/mock/api/mock-service';
import { PositionService } from '@app/shared/api/interfaces/position.service';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { CreatePositionDto, Position, UpdatePositionDto } from '@app/models/position/position.model';
import { Observable } from 'rxjs';
import { mockPositions } from '@app/mock/api/data/mockPositions';
import { sanitizeUrl } from '@app/shared/utils/helpers';

@Injectable()
export class PositionMockService implements MockService, PositionService {
    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);

        switch (true) {
            case url.endsWith('api/position') && req.method === 'GET':
                return this.getAllPositions();
            case url.match(/api\/position\/\d+$/) !== null && req.method === 'GET':
                const positionId = +url.split('/')[2];
                return this.getPositionById(positionId);
        }
    }

    getAllPositions(): Observable<Array<Position>> | Position[] {
        return mockPositions;
    }

    getPositionById(id: number): Observable<Position> | Position {
        return mockPositions.find(p => p.id === id);
    }

    // No Ops listed below
    createPosition(createPositionDto: CreatePositionDto): Observable<Position> {
        return undefined;
    }

    deletePosition(id: number): Observable<Position> {
        return undefined;
    }

    importPosition(file: File): Observable<Array<Position>> {
        return undefined;
    }

    updatePosition(id: number, updatePositionDto: UpdatePositionDto): Observable<Position> {
        return undefined;
    }

}