import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UserStickiness } from '@app/models/session-log/user-stickiness.model';

@Component({
  selector: 'app-session-log-user-stickiness-overview-daily-component',
  templateUrl: './session-log-user-stickiness-overview-daily.component.html',
  styleUrls: ['./session-log-user-stickiness-overview-daily.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush // For performance reasons
})
export class SessionLogUserStickinessOverviewDailyComponent implements OnInit {
  @Input() showHeader: boolean = true;
  @Input() data: Array<UserStickiness> = new Array<UserStickiness>();

  constructor() { }

  ngOnInit(): void { }

  public calculateDailyActiveVsTotal(userStickiness: UserStickiness): string {
    if (userStickiness.totalUsers === 0) {
      return 'N/A';
    }
    return ((userStickiness.dailyActiveUsers / userStickiness.totalUsers) * 100).toFixed(2);
  }

  public calculateDailyActiveVsWeeklyActive(userStickiness: UserStickiness): string {
    if (userStickiness.weeklyActiveUsers === 0) {
      return 'N/A';
    }
    return ((userStickiness.dailyActiveUsers / userStickiness.weeklyActiveUsers) * 100).toFixed(2);
  }

  public calculateDailyActiveVsMonthlyActive(userStickiness: UserStickiness): string {
    if (userStickiness.monthlyActiveUsers === 0) {
      return 'N/A';
    }
    return ((userStickiness.dailyActiveUsers / userStickiness.monthlyActiveUsers) * 100).toFixed(2);
  }

  public calculateWeeklyActiveVsTotal(userStickiness: UserStickiness): string {
    if (userStickiness.totalUsers === 0) {
      return 'N/A';
    }
    return ((userStickiness.weeklyActiveUsers / userStickiness.totalUsers) * 100).toFixed(2);
  }

  public calculateMonthlyActiveVsTotal(userStickiness: UserStickiness): string {
    if (userStickiness.totalUsers === 0) {
      return 'N/A';
    }
    return ((userStickiness.monthlyActiveUsers / userStickiness.totalUsers) * 100).toFixed(2);
  }

}
