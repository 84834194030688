import { MockService } from '@app/mock/api/mock-service';
import { EvaluationCycleService } from '@app/shared/api/interfaces/evaluation-cycle.service';
import { HttpRequest } from '@angular/common/http';
import { EvaluationFeedback } from '@app/models/evaluation/evaluation-feedback.model';
import { EvaluationFeedbackCalibration } from '@app/models/evaluation/evaluation-feedback-calibration.model';
import { EvaluationFeedbackRequest } from '@app/models/evaluation/evaluation-feedback-request.model';
import { EvaluationCycle } from '@app/models/evaluation/evaluation-cycle.model';
import { AssessmentGroupInfo } from '@app/models/evaluation/assessment-group-info.model';
import { ManagerReviewAccess } from '@app/models/evaluation/manager-review-access.model';
import { EvaluationCycleDirectReport } from '@app/models/evaluation/evaluation-cycle-direct-report.model';
import { EvaluationUser } from '@app/models/evaluation/evaluation-user.model';
import { EvaluationCycleUserOverview } from '@app/models/evaluation/evaluation-cycle-user-overview.model';
import { SelfReflection } from '@app/models/evaluation/self-reflection.model';
import { Observable } from 'rxjs';
import { clone, sanitizeUrl } from '@app/shared/utils/helpers';
import { Globals } from '@app/shared/globals/globals';
import { mockEvaluationCycles } from '@app/mock/api/data/mockEvaluationCycles';
import { mockSelfReflections } from '@app/mock/api/data/mockSelfReflections';
import { EvaluationCycleState } from '@app/models/evaluation/evaluation-cycle-state';
import { mockAssessmentGroupInfos } from '@app/mock/api/data/mockAssessmentGroupInfos';
import { mockEvaluationFeedbackCalibrations } from '@app/mock/api/data/mockEvaluationFeedbackCalibrations';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { mockEvaluationFeedback } from '@app/mock/api/data/mockEvaluationFeedback';
import { EvaluationUserState } from '@app/models/evaluation/evaluation-user-state.model';
import { mockEvaluationFeedbackRequests } from '@app/mock/api/data/mockEvaluationFeedbackRequests';
import { mockManagerReviewAccesses } from '@app/mock/api/data/mockManagerReviewAccesses';
import { User } from '@app/models/user/user.model';
import { Injectable } from '@angular/core';
import { mockPraise } from '@app/mock/api/data/mockPraise';
import { mockGoals } from '@app/mock/api/data/mockGoals';
import { mockPeerFeedback } from '@app/mock/api/data/mockPeerFeedback';
import { mockIdeas } from '@app/mock/api/data/mockIdeas';
import { mockOneToOneSchedules } from '@app/mock/api/data/mockOneToOneSchedules';

@Injectable()
export class EvaluationCycleMockService implements MockService, EvaluationCycleService {
    constructor(private readonly globals: Globals) { }

    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);
        const urlSegments = url.split('/');

        switch (true) {
            case url.match(/api\/evaluation\/\d+$/) !== null && req.method === 'GET':
                const evaluationCycleID = +urlSegments[2];
                return this.getEvaluationCycleById(evaluationCycleID);
            case url.endsWith('api/evaluation/company'):
                return this.getCompanyEvaluationCycles();
            case url.endsWith('api/evaluation/company/completed'):
                return this.getCompletedCompanyEvaluationCycles();
            case url.match(/api\/evaluation\/cycle\/user\/\d+$/) !== null:
                const cycleUserId = +urlSegments[4];
                return this.getUserEvaluationCycle(cycleUserId);
            case url.match(/api\/evaluation\/cycle\/user\/\d+\/all/) !== null:
                const allCycleUserId = +urlSegments[4];
                return this.getAllEvaluationCyclesForUser(allCycleUserId);
            case url.match(/api\/evaluation\/\d+\/users\/managed/) !== null:
                const cycleIdManaged = +urlSegments[2];
                return this.getEvaluationCycleUsersByManagerMe(cycleIdManaged);
            case url.match(/api\/evaluation\/\d+\/users/) !== null:
                const cycleWithUsersId = +urlSegments[2];
                return this.getEvaluationCycleUsers(cycleWithUsersId);
            case url.match(/api\/evaluation\/\d+\/user\/\d+$/) !== null:
                const userCycleId = +urlSegments[2];
                const evalCycleUserId = +urlSegments[4];
                return this.getEvaluationCycleUserById(userCycleId, evalCycleUserId);
            case url.match(/api\/evaluation\/user\/\d+$/) !== null:
                const evalFeedbackUserId = +urlSegments[3];
                return this.getEvaluationFeedbackFromMeForUserIdInActiveCycle(evalFeedbackUserId);
            case url.match(/api\/evaluation\/\d+\/self\/\d+$/) !== null:
                const selfUserCycleId = +urlSegments[2];
                const selfCycleId = +urlSegments[4];
                return this.getSelfReflectionForCycleAndUser(selfUserCycleId, selfCycleId);
            case url.endsWith('api/evaluation/self'):
                return this.getAnswersForSelfReflection();
            case url.endsWith('api/evaluation/self/past'):
                return this.getPastSelfReflections();
            case url.match(/api\/evaluation\/user\/\d+\/requests\/received\/sr-completed/) !== null:
                const srCompletedUserId = +urlSegments[3];
                return this.getEvaluationFeedbackRequestsSentToUserWhereSRCompleted(srCompletedUserId);
            case url.match(/api\/evaluation\/user\/\d+\/requests\/received/) !== null:
                const sentRequestsUserId = +urlSegments[3];
                return this.getEvaluationFeedbackRequestsSentToUser(sentRequestsUserId);
            case url.match(/api\/evaluation\/user\/\d+\/requests/) !== null:
                const reqUserId = +urlSegments[3];
                return this.getFeedbackRequestsForUser(reqUserId);
            case url.match(/api\/evaluation\/\d+\/requests\/managed/) !== null:
                const reqManagedCycleId = +urlSegments[2];
                return this.getFeedbackRequestsManagedByMeByCycle(reqManagedCycleId);
            case url.endsWith('api/evaluation/requests/managed'):
                return this.getFeedbackRequestsManagedByMe();
            case url.match(/api\/evaluation\/\d+\/complete\/users/) !== null:
                const completeUsersManagedCycleId = +urlSegments[2];
                return this.getCycleCompleteUsersManaged(completeUsersManagedCycleId);
            case url.endsWith('api/evaluation/company/managed'):
                return this.getCompanyEvaluationCyclesManaged();
            case url.match(/api\/evaluation\/\d+\/review\/\d+\/calibration/) !== null:
                const reviewCalibrationCycleId = +urlSegments[2];
                const reviewCalibrationReviewId = +urlSegments[4];
                return this.getCalibrationForEvaluationFeedback(reviewCalibrationCycleId, reviewCalibrationReviewId);
            case url.match(/api\/evaluation\/\d+\/reflection\/\d+\/calibration/) !== null:
                const reflectionCalibrationCycleId = +urlSegments[2];
                const reflectionCalibrationReviewId = +urlSegments[4];
                return this.getCalibrationForSelfReflection(reflectionCalibrationCycleId, reflectionCalibrationReviewId);
            case url.match(/api\/evaluation\/\d+\/user-overview\/\d+$/) !== null:
                const overviewCycleId = +urlSegments[2];
                const overviewUserId = +urlSegments[4];
                return this.getEvaluationCycleUserOverview(overviewUserId, overviewCycleId);
            case url.match(/api\/evaluation\/\d+\/review\/\d+\/access/) !== null:
                const accessCycleId = +urlSegments[2];
                const accessReviewId = +urlSegments[4];
                return this.getManagerReviewAccess(accessCycleId, accessReviewId);
            case url.match(/api\/evaluation\/\d+\/review\/me/) !== null:
                const myReviewCycleId = +urlSegments[2];
                return this.getManagerReviewForUserMe(myReviewCycleId);
            case url.match(/api\/evaluation\/\d+\/review\/\d+$/) !== null:
                const myCycleId = +urlSegments[2];
                const myReviewNoCalId = +urlSegments[4];
                return this.getManagerReviewForUserMeById(myCycleId, myReviewNoCalId);
            case url.match(/api\/assessment-group-info\?cycleId=\d+$/) !== null:
                const agCycleId = req.params.get('cycleId');
                return this.getAssessmentGroupInfoForCycle(+agCycleId);
            case url.endsWith('api/assessment-group-info'):
                return this.getAssessmentGroupInfoForCycle(null);
        }
    }

    getAllEvaluationCyclesForUser(userId: number): Observable<Array<EvaluationCycle>> | EvaluationCycle[] {
        return mockEvaluationCycles.filter(c => c.users.map(u => u.id).includes(userId));
    }

    getAnswersForSelfReflection(): Observable<SelfReflection | null> | SelfReflection {
        const activeCycle = mockEvaluationCycles.find(c => c.state === EvaluationCycleState.ACTIVATED && c.users.map(u => u.id).includes(this.globals.user.id));
        return activeCycle ? mockSelfReflections.find(s => s.evaluationCycleId === activeCycle.id && s.sourceUserId === this.globals.user.id) || null : null;
    }

    getAssessmentGroupInfoForCycle(cycleId: number): Observable<Array<AssessmentGroupInfo>> | AssessmentGroupInfo[] {
        return cycleId === null ?
            mockAssessmentGroupInfos :
            mockAssessmentGroupInfos.filter(c => c.cycleId === cycleId);
    }

    getCalibrationForEvaluationFeedback(evaluationCycleId: number, evaluationFeedbackId: number): Observable<EvaluationFeedbackCalibration> | EvaluationFeedbackCalibration {
        return mockEvaluationFeedbackCalibrations.find(c => c.evaluationFeedbackId === evaluationFeedbackId) || null;
    }

    getCalibrationForSelfReflection(evaluationCycleId: number, selfReflectionId: number): Observable<EvaluationFeedbackCalibration> | EvaluationFeedbackCalibration {
        return mockEvaluationFeedbackCalibrations.find(c => c.evaluationFeedbackId === selfReflectionId) || null;
    }

    getCompanyEvaluationCycles(): Observable<Array<EvaluationCycle>> | EvaluationCycle[] {
        return mockEvaluationCycles;
    }

    getCompanyEvaluationCyclesManaged(): Observable<Array<EvaluationCycle>> | EvaluationCycle[] {
        const directReports = mockUsers.filter(u => u.id !== this.globals.user.id && u.managerId === this.globals.user.id);
        return mockEvaluationCycles.filter(c => c.users.some(u => directReports.map(d => d.id).includes(u.id)));
    }

    getCompletedCompanyEvaluationCycles(): Observable<Array<EvaluationCycle>> | EvaluationCycle[] {
        return mockEvaluationCycles.filter(c => c.state === EvaluationCycleState.CLOSED);
    }

    getCycleCompleteUsersManaged(id: number): Observable<number[]> | number[] {
        return [1, 3];
    }

    getEvaluationCycleById(id: number): Observable<EvaluationCycle> | EvaluationCycle {
        const cycle = mockEvaluationCycles.find(c => c.id === id);
        return cycle ? clone(cycle) : null;
    }

    getEvaluationCycleUserById(cycleId: number, userId: number): EvaluationUser {
        const user = mockUsers.find(u => u.id === userId);
        return {
            user: user,
            selfReflection: mockSelfReflections.find(s => s.evaluationCycleId === cycleId && s.sourceUserId === userId),
            managerReview: mockEvaluationFeedback.find(f => f.evaluationCycleId === cycleId && f.feedbackUserId === userId && f.sourceUserId === user.managerId),
            evaluationUserState: EvaluationUserState.IN_PROGRESS,
            evaluationRequestsUser: mockEvaluationFeedbackRequests.filter(r => r.evaluationCycleId === cycleId && r.feedbackUserId === userId && r.requestUserId !== user.managerId),
            evaluationRequestsOthers: mockEvaluationFeedbackRequests.filter(r => r.evaluationCycleId === cycleId && r.requestUserId === userId && r.feedbackUserManagerId !== userId),
            evaluationResponsesUser: mockEvaluationFeedback.filter(f => f.evaluationCycleId === cycleId && f.feedbackUserId === userId && f.sourceUserId !== user.managerId),
            evaluationResponsesOthers: mockEvaluationFeedback.filter(f => f.evaluationCycleId === cycleId && f.sourceUserId === userId),
            evaluationFeedbackCalibration: mockEvaluationFeedbackCalibrations.find(c => c.evaluationFeedbackId === mockEvaluationFeedback.find(f => f.evaluationCycleId === cycleId && f.feedbackUserId === userId && f.sourceUserId === user.managerId).id),
            managerReviewAccess: mockManagerReviewAccesses.find(a => a.evaluationCycleId === cycleId && a.managerReview.feedbackUserId === userId),
            lastEditTimestamp: new Date()
        };
    }

    getEvaluationCycleUserOverview(userId: number, cycleId: number): EvaluationCycleUserOverview {
        return {
            praise: mockPraise.filter(p => p.praiseUserId === userId),
            goals: mockGoals.filter(g => g.owners.map(o => o.id).includes(userId)),
            feedbackPeerReceived: mockPeerFeedback.filter(f => f.recipient.id === userId),
            feedbackPeerSent: mockPeerFeedback.filter(f => f.sender.id === userId),
            competencies: mockUsers.find(u => u.id === userId).position.competencies,
            ideas: mockIdeas.filter(i => i.sourceUserId === userId),
            oneToOneSchedule: mockOneToOneSchedules.find(s => s.user.id === userId && s.manager.id === this.globals.user.id)
        };
    }

    getEvaluationCycleUsers(cycleId: number): Observable<Array<EvaluationUser>> | EvaluationUser[] {
        return mockEvaluationCycles.find(c => c.id === cycleId).users.map(u => this.getEvaluationCycleUserById(cycleId, u.id)) || null;
    }

    getEvaluationCycleUsersByManagerMe(cycleId: number): EvaluationCycleDirectReport {
        const evaluationCycle = mockEvaluationCycles.find(c => c.id === cycleId);
        const currentUser = this.globals.user;
        return this.createEvaluationCycleDirectReportTree(evaluationCycle, currentUser);
    }

    private createEvaluationCycleDirectReportTree(evaluationCycle: EvaluationCycle, user: User): EvaluationCycleDirectReport {
        const directReportList: EvaluationCycleDirectReport[] = [];
        const directReports = mockUsers.filter(u => u.managerId === user.id && u.id !== u.managerId);
        for (const directReport of directReports) {
            if (evaluationCycle.users.map(u => u.id).includes(directReport.id)) {
                directReportList.push(this.createEvaluationCycleDirectReportTree(evaluationCycle, directReport));
            }
        }
        const evaluationUser = this.getEvaluationCycleUserById(evaluationCycle.id, user.id);
        return {
            evaluationUser: evaluationUser,
            directReports: directReportList
        }
    }

    getEvaluationFeedbackFromMeForUserIdInActiveCycle(id: number): EvaluationFeedback {
        const activeEvaluationCycle = mockEvaluationCycles.find(c => c.state === EvaluationCycleState.ACTIVATED && c.users.map(u => u.id).includes(id));
        return mockEvaluationFeedback.find(f => f.evaluationCycleId === activeEvaluationCycle.id && f.sourceUserId === this.globals.user.id && f.feedbackUserId === id) || null;
    }

    getEvaluationFeedbackRequestsSentToUser(id: number): Observable<EvaluationFeedbackRequest[]> | EvaluationFeedbackRequest[] {
        return mockEvaluationFeedbackRequests.filter(r => r.requestUserId === id);
    }

    getEvaluationFeedbackRequestsSentToUserWhereSRCompleted(id: number): Observable<EvaluationFeedbackRequest[]> | EvaluationFeedbackRequest[] {
        return mockEvaluationFeedbackRequests.filter(r => r.requestUserId === id && r.srCompleted);
    }

    getFeedbackRequestsForUser(id: number): Observable<EvaluationFeedbackRequest[]> | EvaluationFeedbackRequest[] {
        const user = mockUsers.find(u => u.id === id);
        return mockEvaluationFeedbackRequests.filter(r => r.feedbackUserId === user.id && r.requestUserId !== user.managerId);
    }

    getFeedbackRequestsManagedByMe(): Observable<EvaluationFeedbackRequest[]> | EvaluationFeedbackRequest[] {
        return mockEvaluationFeedbackRequests.filter(r => r.feedbackUserManagerId === this.globals.user.id);
    }

    getFeedbackRequestsManagedByMeByCycle(cycleId: number): Observable<EvaluationFeedbackRequest[]> | EvaluationFeedbackRequest[] {
        return mockEvaluationFeedbackRequests.filter(r => r.feedbackUserManagerId === this.globals.user.id && r.evaluationCycleId === cycleId);
    }

    getManagerReviewAccess(evaluationCycleId: number, evaluationFeedbackId: number): Observable<ManagerReviewAccess> | ManagerReviewAccess {
        return mockManagerReviewAccesses.find(a => a.managerReview.id === evaluationFeedbackId) || null;
    }

    getManagerReviewForUserMe(evaluationCycleId: number): Observable<EvaluationFeedback> | EvaluationFeedback {
        return mockEvaluationFeedback.find(f => f.evaluationCycleId === evaluationCycleId && f.feedbackUserId === this.globals.user.id && f.sourceUserId === this.globals.user.managerId) || null;;
    }

    getManagerReviewForUserMeById(evaluationCycleId: number, managerReviewId: number): Observable<EvaluationFeedback> | EvaluationFeedback {
        return mockEvaluationFeedback.find(f => f.evaluationCycleId === evaluationCycleId && f.id === managerReviewId) || null;
    }

    getSelfReflectionForCycleAndUser(cycleId: number, userId: number): Observable<SelfReflection> | SelfReflection {
        return mockSelfReflections.find(s => s.evaluationCycleId === cycleId && s.sourceUserId === userId) || null;
    }

    getUserEvaluationCycle(userId: number): Observable<EvaluationCycle> | EvaluationCycle {
        return mockEvaluationCycles.find(c => c.state === EvaluationCycleState.ACTIVATED && c.users.map(u => u.id).includes(userId)) || null;
    }

    // No Ops listed below
    getSelfReflectionForSelf(): Observable<SelfReflection> | SelfReflection {
        return undefined;
    }

    getPastSelfReflections(): Observable<SelfReflection[]> | SelfReflection[] {
        return undefined;
    }

    getEvaluationCyclesReport(ids: Array<number>): any {
        return undefined;
    }

    addAssessmentGroupInfoByCycle(assessmentGI: AssessmentGroupInfo[], cycleId: number): Observable<AssessmentGroupInfo[]> {
        return undefined;
    }

    approveRequestForFeedback(id: number, evaluationRequest: EvaluationFeedbackRequest): Observable<EvaluationFeedbackRequest> {
        return undefined;
    }

    archiveEvaluationQuestion(id: number): Observable<null> {
        return undefined;
    }

    archiveSelfReflectionQuestion(id: number): Observable<null> {
        return undefined;
    }

    closeReviewCycle(cycle: EvaluationCycle): Observable<EvaluationCycle> {
        return undefined;
    }

    completeAnswerForUser(id: number, feedback: EvaluationFeedback): Observable<EvaluationFeedback> {
        return undefined;
    }

    completeSelfReflection(answers: SelfReflection): Observable<SelfReflection> {
        return undefined;
    }

    createEvaluationCalibration(cycleID: number, calibration: EvaluationFeedbackCalibration): Observable<EvaluationFeedbackCalibration> | undefined {
        return undefined;
    }

    createEvaluationFeedbackCalibration(evaluationCycleId: number, evaluationFeedbackCalibration: EvaluationFeedbackCalibration): Observable<EvaluationFeedbackCalibration> {
        return undefined;
    }

    createSelfReflectionCalibration(evaluationCycleId: number, evaluationFeedbackCalibration: EvaluationFeedbackCalibration): Observable<EvaluationFeedbackCalibration> {
        return undefined;
    }

    declineRequestForFeedback(id: number, evaluationRequest: EvaluationFeedbackRequest): Observable<EvaluationFeedbackRequest> {
        return undefined;
    }

    deleteEvaluationCycle(id: number): Observable<null> {
        return undefined;
    }

    downloadUserPDF(cycleId: number, userId: number, fileName: string): any {
    }

    editReviewCycle(cycle: EvaluationCycle): Observable<EvaluationCycle> {
        return undefined;
    }

    extendCycle(evaluationCycleId: number, newEndDate: Date): Observable<EvaluationCycle> {
        return undefined;
    }

    postAnswersForSelfReflection(answers: SelfReflection): any {
    }

    requestPeerFeedback(id: number, requestObj: EvaluationFeedbackRequest): Observable<EvaluationFeedbackRequest> {
        return undefined;
    }

    saveReviewCycle(cycle: EvaluationCycle, draft: boolean): Observable<EvaluationCycle> {
        return undefined;
    }

    shareManagerReviewAccess(evaluationCycleId: number, evaluationFeedbackId: number): Observable<ManagerReviewAccess> {
        return undefined;
    }

    submitNewAnswerForUser(id: number, feedback: EvaluationFeedback): Observable<EvaluationFeedback> {
        return undefined;
    }

    unlockManagerReviewAccess(evaluationCycleId: number, evaluationFeedbackId: number): Observable<ManagerReviewAccess> {
        return undefined;
    }

    unlockManagerReviewAccessBulk(evaluationCycleId: number, reviewIdsToUnlock: number[]): Observable<ManagerReviewAccess[]> {
        return undefined;
    }

    updateAnswersForSelfReflection(answers: SelfReflection, autosave: boolean): any {
    }

    updateAssessmentGroupInfo(assessmentGI: AssessmentGroupInfo): Observable<AssessmentGroupInfo> {
        return undefined;
    }

    updatePastAnswerForUser(id: number, feedback: EvaluationFeedback, autosave: boolean): Observable<EvaluationFeedback> {
        return undefined;
    }

}