import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'app-pagination-new-component',
  templateUrl: './pagination-new.component.html',
  styleUrls: ['./pagination-new.component.css']
})

export class PaginationNewComponent implements OnChanges {
  @Input() currentPage = 1;
  @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();
  pages: number[] = [];

  displayAmountStart = 0;
  displayAmountEnd = 0;
  displayAmountTotal = 0;

  @Input() amountToDisplay: number | 'All' = 5;
  @Input() amountsToDisplay: number[] = [5, 10, 15, 25, 50, 100];

  @Input() arrayData !: Array<any>;
  @Input() arrayDisplay !: Array<any>;
  dataArrayDisplay: Array<any> = [];

  constructor() {
    this.amountToDisplay = 10;
  }

  ngOnChanges() {
    this.update();
  }

  nextPage() {
    if (this.currentPage < this.getLastPage()) {
      this.updateCurrentPageValue(this.currentPage + 1)
      this.updateChangedPage();
    }
  }

  firstPage() {
    if (this.currentPage > 1) {
      this.updateCurrentPageValue(1);
      this.updateChangedPage();
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.updateCurrentPageValue(this.currentPage - 1);
      this.updateChangedPage();
    }
  }

  lastPage() {
    if (this.currentPage < this.getLastPage()) {
      this.updateCurrentPageValue(this.getLastPage());
      this.updateChangedPage();
    }
  }

  getLastPage() {
    if (this.amountToDisplay === 'All') {
      return 1;
    }
    return Math.ceil(this.arrayData.length / (this.amountToDisplay as number));
  }

  getLastOnPage(): number {
    const amount = this.getAmountPerPage();

    let end = amount * this.currentPage;
    if (end > this.arrayData.length) {
      end = this.arrayData.length;
    }
    return end;
  }

  getAmountPerPage(): number {
    let amount;
    if (this.amountToDisplay === 'All') {
      amount = this.arrayData.length;
    } else {
      amount = this.amountToDisplay as number;
    }
    return amount;
  }

  goToPage(page: number) {
    this.updateCurrentPageValue(page)
    this.updateChangedPage();
  }

  updateTable() {
    this.dataArrayDisplay = [];

    const end = this.getLastOnPage();

    if (this.amountToDisplay === 'All') {
      this.dataArrayDisplay = this.arrayData.filter(item => item);
    } else {
      const start = (this.amountToDisplay as number) * (this.currentPage - 1);
      for (let i = start; i < end; i++) {
        this.dataArrayDisplay.push(this.arrayData[i]);
      }
    }
  }

  updatePages() {
    const pages = this.getLastPage();
    this.pages = [];
    for (let i = 0; i < pages; i++) {
      this.pages.push((i + 1));
    }
  }

  updateText() {
    this.resetIfError();
    const end = this.getLastOnPage();
    const amount = this.getAmountPerPage();

    this.displayAmountTotal = this.arrayData.length;
    this.displayAmountStart = (amount * (this.currentPage - 1)) + 1;
    this.displayAmountEnd = end;
  }

  updateTableChangedPage() {
    this.dataArrayDisplay = []
    const end = this.getLastOnPage();
    if (this.amountToDisplay !== 'All') {
      const start = (this.amountToDisplay as number) * (this.currentPage - 1);
      for (let i = start; i < end; i++) {
        this.dataArrayDisplay.push(this.arrayData[i]);
      }
    }
  }

  updateChangedPage() {
    this.updateText();
    this.updateTableChangedPage();
    this.updatePages();

    this.clearAndPopulateDisplay();
  }

  update() {
    this.updateText();
    this.updateTable();
    this.updatePages();

    this.clearAndPopulateDisplay();

  }

  private clearAndPopulateDisplay() {
    while (this.arrayDisplay.length > 0) {
      this.arrayDisplay.pop();
    }

    this.dataArrayDisplay.forEach(i => this.arrayDisplay.push(i));
  }

  // if on page greater than 1 then amount drops so that only 1 page can be made, this resets it to page 1
  resetIfError() {
    const end = this.getLastOnPage();
    const amount = this.getAmountPerPage();
    const start = (amount * (this.currentPage - 1)) + 1;

    if (start > end) {
      this.updateCurrentPageValue(1)
    }
  }

  updateCurrentPageValue(v: number) {
    this.currentPage = v;
    this.pageChanged.emit(this.currentPage);
  }
}
