import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.css']
})
export class TimePickerComponent implements OnInit {
  @ViewChild('timePicker') timePickerInput?: ElementRef;

  // COMMON
  @Input() name: string;
  @Input() placeholder: string;
  @Input() disabled: boolean;

  // TIME PICKER
  @Input() stepping: number;

  constructor() {
    this.name = '';
    this.placeholder = '';
    this.disabled = false;

    this.stepping = 5;
  }

  ngOnInit() {
  }

}
