import { PollQuestion } from '@app/models/poll/poll-question.model';

export const mockSentimentPollQuestions: PollQuestion[] = [
  {
    id: 1,
    questionText: "On a scale of 0-10, how likely are you to recommend Frankli as a good place to work?",
    surveyQuestionType: "LIKERT_SCALE",
    possibleAnswers: [],
    archived: false
  },
  {
    id: 2,
    questionText: "If you were offered the same job at another organisation, how likely is it that you would stay with Frankli?",
    surveyQuestionType: "LIKERT_SCALE",
    possibleAnswers: [],
    archived: false
  },
  {
    id: 3,
    questionText: "Overall, how satisfied are you working for Frankli?",
    surveyQuestionType: "LIKERT_SCALE",
    possibleAnswers: [],
    archived: false
  }
];

export const mockReviewPollQuestions: PollQuestion[] = [
  {
    id: 4,
    questionText: "On a scale of 0-10, how seamless did you find our new review process using Frankli?",
    surveyQuestionType: "LIKERT_SCALE",
    possibleAnswers: [],
    archived: false
  },
  {
    id: 5,
    questionText: "Overall, how satisfied are you with the recent performance review?",
    surveyQuestionType: "LIKERT_SCALE",
    possibleAnswers: [],
    archived: false
  }
];

export const mockPollQuestions: PollQuestion[] = [
  ...mockSentimentPollQuestions,
  ...mockReviewPollQuestions
];