import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Goal} from 'app/models/goals/goal.model';
import {User} from 'app/models/user/user.model';
import {Globals} from 'app/shared/globals/globals';
import {CompanyFeatures} from 'app/models/company.model';
import {GoalsAPIService} from 'app/shared/api/goals.api.service';

@Component({
  selector: 'app-profile-goals',
  templateUrl: './profile-goals.component.html',
  styleUrls: ['./profile-goals.component.css']
})
export class ProfileGoalsComponent implements OnChanges {
  @Input() userProfile: User;

  goals: Array<Goal> = new Array<Goal>();
  user: User;
  globals: Globals;
  eFeature = CompanyFeatures;
  loading = true;
  error = false;
  constructor(private _globals: Globals,
              private goalAPIService: GoalsAPIService,
              ) {
    this.globals = _globals;
    this.user = this.globals.user;
  }
  ngOnChanges() {
    this.goalAPIService.getPublicPersonalGoals(this.userProfile.id).subscribe(response => {
      this.goals = Goal.getGoalArrayCompletionPercentage(response);
      this.loading = false;
    });
  }
}
