<!-- TODO: Remove the row/col stuff in this component. Rows are in this component, cols are in sub components. Not needed. Shouldnt need col-md-12 ever unless doing responsive resizing -->
<router-outlet></router-outlet>
<ng-container *ngIf="route.children.length === 0">
  <ng-container *ngIf="!state.loading; else stateLoading;">
    <ng-container *ngIf="!state.error; else stateError;">
      <ng-container *ngIf="!archived; else stateArchived">
        <ng-container [ngSwitch]="userProfile.userState">
          <!-- PROFILE -->
          <ng-container *ngSwitchDefault>
      
            <!-- Profile -->
            <!-- TODO: Proper data -->
            <!-- <div class="row">
              <div class="col-md-8 col-md-offset-2">
                <div class="headerText text-center">
                  <div>In the last {{getEmploymentTime()}} days {{userProfile.firstName}} has worked at {{getCompanyName()}} he has received</div>
                  <div>
                    <strong>{{getHighFives()}} High Fives</strong>, 
                    <strong>{{getEndorsements()}} Endorsements</strong> and been 
                    <strong>praised {{getPraiseCount()}}</strong> times!
                  </div>
                </div>
              </div>
            </div> -->
      
            <div class="row m-bottom-15" *ngIf="userProfile.id !== globals.user.id">
              <div class="col-md-12 text-right">
                <button class="btn-frankli-round btn-frankli-green-inverted btn-frankli-transparent m-left-5 m-right-5"
                *ngIf="globals.hasFeature(eFeature.FEEDBACK_PRAISE) === true" (click)="startPraise()"
                angulartics2On="click" angularticsAction="OpenedPraiseSwal" angularticsCategory="Profile">
                <span class="fal fa-bullhorn"></span>
                Give Praise
                </button>
                <!-- TODO: Click event for this button -->
                <!-- <button class="btn-frankli-round btn-frankli-green-inverted btn-frankli-transparent m-left-5 m-right-5">
                  Set a Task
                </button> -->
                <button class="btn-frankli-round btn-frankli-green-inverted btn-frankli-transparent m-left-5 m-right-5"
                  *ngIf="globals.hasFeature(eFeature.ONE_TO_ONE) === true && !hasOneToOne" (click)="createOneToOne(userProfile)">
                  <span class="fal fa-calendar-plus"></span>
                  Create 1:1
                </button>
                <button class="btn-frankli-round btn-frankli-green-inverted btn-frankli-transparent"
                        *ngIf="(userProfile.managerId === globals.user.id || globals.hasRole(eRole.GOAL_COMPANY)) && globals.hasFeature(eFeature.GOALS) === true"
                        [routerLink]="'goals/all/' + userProfile.id" angulartics2On="click"
                        angularticsAction="ViewGoalsProfile" angularticsCategory="Profile">
                  <span class="fal fa-bullseye"></span>
                  View Goals
                </button>
              </div>
            </div>
            <div class="row">
              <!-- Left Col -->
              <div class="col-md-4">
      
                <!-- About Me card -->
                <div class="card" #aboutMeCard>
                  <div class="content">
                    <div class="text-center">
                      <span class="image-container">
                        <span class="img-user">
                          <app-profile-photo
                          [src]="userProfile.imageUrl"
                          [alt]="'profile picture'"
                          ></app-profile-photo>
                        </span>
                      </span>
                    </div>
                    <div class="text-center">
                      <span class="user-name text-capitalize"> {{userProfile.firstName}} {{userProfile.lastName}}</span>
                    </div>
                    <div class="content info-box text-left">
                      <!-- TODO: Length limit and read more on overflow -->
                      <p class="description">
                        <app-rich-display [text]="userProfile.description"></app-rich-display>
                      </p>
                    </div>
                  </div>
                </div>
      
                <!-- Location and Work Info card -->
                <div class="card no-pad-b top-row-half mh-transition mh-contact">
                  <div class="header">
                    <h4 class="title text-left">Location & Work Info</h4>
                    <hr class="m-top-0 m-bottom-0" />
                  </div>
      
                  <div class="content">
                    <div class="text-left" *ngIf="userProfile.homeAddress">
                      <ng-container *ngIf="(userProfile.officeLocation !== null); else locNoOfficeLocation">
                        <ng-container *ngIf="(userProfile.officeLocation.name !== 'Working remotely'); else locWorkingRemotely">
                          <h5 class="display-1">Based in {{userProfile.officeLocation.officeAddress.city}}</h5>
                        </ng-container>
                        <ng-template #locWorkingRemotely>
                          <h5 class="display-1">Based in {{(userProfile.homeAddress.city.trim().length === 0)? userProfile.homeAddress.country: userProfile.homeAddress.city}}</h5>
                        </ng-template>
                      </ng-container>
                      <ng-template #locNoOfficeLocation>
                        <h5>Based in {{(!userProfile.homeAddress.city || userProfile.homeAddress.city.trim().length === 0)? userProfile.homeAddress.country: userProfile.homeAddress.city}}</h5>
                      </ng-template>
      
                      <h5 *ngIf="usersTime">
                        <span>Current time:</span>
                        <span class="m-left-10">{{usersTime}}</span>
                      </h5>
                      <hr />
                    </div>
      
                    <!-- Working From -->
                    <div class="row">
                      <div class="col-sm-6">
                        <p>
                          <i class="fal fa-map-marker-alt"></i>
                          <strong>Working</strong>
                          <strong *ngIf="(userProfile.officeLocation && userProfile.officeLocation.name === 'Working remotely')">remotely</strong>
                          <strong>from:</strong>
                        </p>
                      </div>
                      <div class="col-sm-6 frankli-form-group">
                        <ng-container *ngIf="(globals.user.id !== userProfile.id); else selectLocation">
                          <ng-container *ngIf="(userProfile.officeLocation !== null); else noOfficeLocation">
                            <ng-container *ngIf="(userProfile.officeLocation.name !== 'Working remotely'); else workingRemotely">
                              <p>{{userProfile.officeLocation.name}}</p>
                            </ng-container>
                            <ng-template #workingRemotely>
                              <p>{{userProfile.homeAddress.city}}</p>
                            </ng-template>
                          </ng-container>
                          <ng-template #noOfficeLocation>
                            <p>No Office Location</p>
                          </ng-template>
                        </ng-container>
                        <ng-template #selectLocation>
                          <select name="OfficeLocation" class="form-control"
                            [(ngModel)]="officeLocation" (ngModelChange)="officeChange($event)" angulartics2On="click"
                            angularticsAction="ChangedOfficeLoc" angularticsCategory="Profile">
                            <option [ngValue]="null">No Office Location</option>
                            <option *ngFor="let location of officeLocations" [ngValue]="location.id">
                              {{location.name}}
                            </option>
                          </select>
                        </ng-template>
                      </div>
                    </div>
      
                    <div class="row p-top-10">
                      <div class="col-sm-6">
                        <p>
                          <i class="fal fa-briefcase"></i>
                          <strong>{{companyWording.department | titlecase}}:</strong>
                        </p>
                      </div>
                      <div class="col-sm-6">
                        <p>{{(userProfile.organisationalUnit !== null)?(userProfile.organisationalUnit.name):('No ' + companyWording.department) | titlecase}}</p>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
      
              <!-- Right Col -->
              <div class="col-md-8">
                <!-- Contact details & interests card -->
                <div class="card" [style.min-height.px]="minHeightContact">
                  <div class="content">
                    <app-profile-contact-interests [(userProfile)]="userProfile"></app-profile-contact-interests>
                  </div>
                </div>
      
                <!-- Praise card -->
                <div class="card">
                  <div class="row" *ngIf="globals.hasFeature(eFeature.FEEDBACK_PRAISE) === true">
                    <app-profile-praise #praiseComp [(userProfile)]="userProfile" (clickGivePraise)="startPraise()"></app-profile-praise>
                  </div>
                </div>
      
                <!-- Goals -->
                <div class="card" *ngIf="globals.hasFeature(eFeature.GOALS) === true">
                  <div class="row">
                    <app-profile-goals [(userProfile)]="userProfile"></app-profile-goals>
                  </div>
                </div>
      
              <!-- Development -->
                <div class="card" *ngIf="globals.hasFeature(eFeature.CONNECT) === true">
                  <div class="row">
                    <app-profile-development [(userProfile)]="userProfile"></app-profile-development>
                  </div>
                </div>          
              </div>
            </div>
          </ng-container>
      
          <!-- PENDING -->
          <div class="card profile-error" *ngSwitchCase="'PENDING'">
            <div class="text-center">
              <img src='/assets/img/UserNotSignedUp.svg' alt='error-image' />
              <h1>{{userProfile ? userProfile.firstName + ' ' + userProfile.lastName : 'Person'}} has not been invited to frankli yet</h1>
              <!--        <a class="help-link">Learn more</a>-->
            </div>
          </div>
      
          <!-- INVITED -->
          <div class="card profile-error" *ngSwitchCase="'INVITED'">
            <div class="text-center">
              <img src='/assets/img/UserNotSignedUp.svg' alt='error-image' />
              <h1>{{userProfile ? userProfile.firstName + ' ' + userProfile.lastName : 'Person'}} has not completed sign up yet</h1>
              <!--      <a class="help-link">Learn more</a>-->
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- #region - ALTERNATE PAGE STATES -->
  <ng-template #stateArchived>
    <div class="card profile-error text-center">
      <img src='/assets/img/UserNotSignedUp.svg' alt='error-image' />
      <h1>Person has been archived</h1>
      <!--      <a class="help-link">Learn more</a>-->
    </div>
  </ng-template>

  <ng-template #stateLoading>
    <div class="card">
      <div class="p-top-15 p-bottom-15 text-center">
        <span class="fal fa-spin fa-spinner fa-2x fa-fw"></span>
      </div>
    </div>
  </ng-template>

  <ng-template #stateError>
    <div class="p-top-15 p-bottom-15 text-center">
      <img class="icon-padding new-icon-height" src="/assets/img/Error.svg" />
      <br/>
      <span>An error occurred while loading this users profile</span>
      <br/>
      <span>{{state.errorMessage}}</span>
      <br/>
      <span class="btn-frankli-round btn-frankli-green m-top-10" [routerLink]="'/dashboard'">
        Back to your dashboard?
      </span>
    </div>
  </ng-template>
  <!-- #endregion -->

  <app-modal (modalHidden)="cancelPraise(false)" #praiseModal>
    <div class="modal-body-content welcome-modal">
      <div class="text-center">
        <h4>{{titleText}}</h4>
      </div>

      <div class="content m-top-25" *ngIf="praiseModal.visible">
        <div class="frankli-form-group frankli-form-group-filled" [class.frankli-form-group-invalid]="praiseSubmitted && praiseForm.controls.praise.invalid">
          <app-editor-response [formControl]="praiseForm.controls.praise" [enforceCharacterLimit]="false" [columnIndent]="false" [showErrorBorder]="false" [toolbar]="editorToolbar" [height]="200" [maxCharacters]="largeEditorMaxLengthSoft" [minlength]="largeEditorMinLength" [placeholder]="'Please write here (Max ' + largeEditorMaxLengthSoft + ' characters)'"></app-editor-response>
        </div>
        <div class="frankli-form-group-alert">
          <ng-container *ngIf="praiseSubmitted">
            <div *ngIf="praiseForm.controls.praise.hasError('required')">Please provide a little detail here</div>
            <div *ngIf="praiseForm.controls.praise.hasError('softmin')">Your submission must be at least {{largeEditorMinLength}} characters</div>
          </ng-container>
          <div *ngIf="praiseForm.controls.praise.hasError('softmax')">Your submission must be {{largeEditorMaxLengthSoft}} characters or fewer</div>
          <div *ngIf="praiseForm.controls.praise.hasError('maxlength')">Too much formatting</div>
        </div>
      </div>
      <div>
        <p class="value-text">Which company values did they demonstrate?</p>
        <div class="sm-scroll-area">
          <div>
            <ng-container *ngIf="availableValues.length > 0; else noAvailableValues">
              <span class="interest" [class.interest-selected]="value.selected" *ngFor="let value of availableValues" (click)="toggleValue(value)">{{value.text}}</span>
            </ng-container>
            <ng-template #noAvailableValues>
              <p>No values found</p>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="text-center">
        <button class="btn-frankli-round btn-frankli-cancel" (click)="cancelPraise()">Cancel</button>
        <button class="btn-frankli-round btn-frankli-green" (click)="confirmPraise()">Submit</button>
      </div>
    </div>
  </app-modal>
</ng-container>
