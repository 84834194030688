import { Injectable } from '@angular/core';
import { MockService } from '@app/mock/api/mock-service';
import { PraiseService } from '@app/shared/api/interfaces/praise.service';
import { Observable } from 'rxjs';
import { HttpRequest } from '@angular/common/http';
import { ReactionType } from '@app/models/activity-reaction.model';
import { CreatePraiseDto, PraiseItem, UpdatePraiseDto } from '@app/models/feedback/praise.model';
import { Globals } from '@app/shared/globals/globals';
import { mockPraise } from '@app/mock/api/data/mockPraise';
import { sanitizeUrl } from '@app/shared/utils/helpers';

@Injectable()
export class PraiseMockService implements MockService, PraiseService {
    constructor(private readonly globals: Globals) { }

    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);

        switch (true) {
            case url.endsWith('api/praise/sent'):
                return this.getPraiseSent();
            case url.endsWith('api/praise/submitted/manager/me'):
                return this.getPraiseByDestinationUser();
            case url.match(/api\/praise\/submitted\/manager\/id\/\d+$/) !== null:
                const destinationPraiseId = +url.split('/')[5];
                return this.getPraiseByDestinationUserAndId(destinationPraiseId);
            case url.match(/api\/praise\/sent\/\d+$/) !== null:
                const sentPraiseId = +url.split('/')[3];
                return this.getPraiseSentById(sentPraiseId);
            case url.endsWith('api/praise/received'):
                return this.getPraiseReceived();
            case url.match(/api\/praise\/received\/\d+$/) !== null:
                const receivedPraiseId = +url.split('/')[3];
                return this.getPraiseReceivedById(receivedPraiseId);
            case url.match(/api\/praise\/received\/user\/\d+$/) !== null:
                const receivedPraiseUserId = +url.split('/')[4];
                return this.getPraiseReceivedByUserId(receivedPraiseUserId);
        }
    }

    getPraiseByDestinationUser(): Observable<Array<PraiseItem>> | PraiseItem[] {
        return mockPraise.filter(p => p.praiseUserId === this.globals.user.id);
    }

    getPraiseByDestinationUserAndId(id: number): Observable<PraiseItem> | PraiseItem {
        return mockPraise.find(p => p.praiseUserId === this.globals.user.id && p.id === id);
    }

    getPraiseReceived(): Observable<Array<PraiseItem>> | PraiseItem[] {
        return mockPraise.filter(p => p.praiseUserId === this.globals.user.id);
    }

    getPraiseReceivedById(id: number): Observable<PraiseItem> | PraiseItem {
        return mockPraise.find(p => p.praiseUserId === this.globals.user.id && p.id === id);
    }

    getPraiseReceivedByUserId(userId: number): Observable<Array<PraiseItem>> | PraiseItem[] {
        return mockPraise.filter(p => p.praiseUserId === userId);
    }

    getPraiseSent(): Observable<Array<PraiseItem>> | PraiseItem[] {
        return mockPraise.filter(p => p.sourceUserId === this.globals.user.id);
    }

    getPraiseSentById(id: number): Observable<PraiseItem> | PraiseItem {
        return mockPraise.find(p => p.id === id) || null;
    }

    // No Ops listed below
    deletePraiseById(id: number): Observable<PraiseItem> {
        return undefined;
    }

    submitPraise(createPraiseDto: CreatePraiseDto): Observable<PraiseItem> {
        return undefined;
    }

    togglePraiseVisibleById(id: number): Observable<PraiseItem> {
        return undefined;
    }

    toggleReactionForPraiseByIdAndReactionType(id: number, type: ReactionType): Observable<PraiseItem> {
        return undefined;
    }

    updatePraiseById(id: number, updatePraiseDto: UpdatePraiseDto): Observable<PraiseItem> {
        return undefined;
    }
}