<div (click)="onContainerClicked($event)" class="modal fade" tabindex="-1" [ngClass]="{'in': visibleAnimate}"
  [ngStyle]="{'display': visible ? 'block' : 'none', 'opacity': visibleAnimate ? 1 : 0}">
  <div class="modal-dialog">
    <div class="modal-content" [class.modal-content-sidebar]="sidebarCollapsed === false"> 

      <div tooltip="Expand sidebar" placement="right" container="body" class="modal-sidebar-button" *ngIf="sidebarCollapsed === true" (click)="toggleSidebar()">
        <span class="fal fa-angle-right fa-2x"></span>
      </div>

      <div class="modal-body-header">
        <ng-content select=".app-goal-modal-header"></ng-content>
        <ng-content select=".app-goal-modal-subtitle"></ng-content>
      </div>

      <div class="modal-body-content">
        <ng-content select=".app-goal-modal-body"></ng-content>
      </div>

    </div>
    <div class="modal-sidebar" [class.modal-hide-sidebar]="sidebarCollapsed === true">
      <ng-content select=".app-goal-modal-sidebar"></ng-content>
    </div>
    <div class="modal-dismiss" (click)="hide()">
      
    </div>
  </div>
</div>
