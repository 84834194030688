<!------------------------------------------------------------
  Since org chart is fullscreen, all controls will need to be 
  positioned absolutly, on top of the org chart
-------------------------------------------------------------->

<div class="org-chart-wrapper" [class.fullscreen]="fullscreen">
  <!-- Branding -->
  <div class="org-chart-branding">
    <div class="title text-center">Organisational chart</div>
  </div>
  
  <!-- Controls -->
  <!-- Zoom controls are internal in the org chart, others are overlayed here -->
  <div class="org-chart-help" title="Show cheatsheet" (click)="showOrgchartHelp()">
    <span class="fal fa-question-circle fa-2x fa-fw"></span>
  </div>
  <!-- TODO: Move these inside the library -->
  <div class="org-chart-controls">
    <div class="org-chart-control" (click)="expandAll()" [title]="'Expand All'">
      <span class="far fa-2x fa-expand-arrows-alt"></span>
    </div>
    <div class="org-chart-control" (click)="collapseAll()" [title]="'Collapse All'">
      <span class="far fa-2x fa-compress-arrows-alt"></span>
    </div>
  </div>
  <div class="org-chart-fullscreen">
    <div class="org-chart-control text-center" (click)="toggleFullscreen()" [title]="(fullscreen ? 'Exit Fullscreen' : 'Enter Fullscreen')">
      <span class="far fa-2x" [ngClass]="(fullscreen ? 'fa-compress' : 'fa-expand' )"></span>
    </div>
  </div>
  
  <div class="org-chart-container">
    <!-- LOADING -->
    <div class="content text-center org-chart-loading" *ngIf="state.loading && !state.error">
      <span class="fal fa-spin fa-spinner fa-3x fa-fw"></span>
    </div>
    <!-- ERROR -->
    <div class="content text-center org-chart-error" *ngIf="!state.loading && state.error">
      <p>
        <img class="icon-padding new-icon-height" src="/assets/img/Error.svg" />
        <br> An error occurred while getting people</p>
    </div>
    <!-- ORG CHART -->
    <div class="tree-container" id="tree" #treeContainer></div>
  </div>
  
  
  <app-modal #modal [background]="'light'">
    <div class="modal-body-content text-center">
      <div class="row m-top-20" *ngIf="isIE()">
        <div class="col-xs-12">
          <div class="alert alert-danger">
            Unfortunately peoples images are not supported on Internet Explorer 11 or lower.
            If you wish to view images, please use a more modern browser such as Microsoft Edge, Chrome, Firefox, Safari
            or Opera
          </div>
        </div>
      </div>
      <div class="row m-top-20 text-left">
        <div class="col-xs-12">
          <strong>Show subordinates:</strong> Click the <i class="fas fa-plus-circle" style="color: #54C6BB;"></i> icon
          below a person on the org chart
        </div>
        <div class="col-xs-12">
          <strong>Hide subordinates:</strong> Click the <i class="fas fa-minus-circle" style="color: #D3D3D3"></i> icon
          below a person on the org chart
        </div>
        <div class="col-xs-12">
          <strong>Reset zoom:</strong> Click the <span class="fal fa-sync-alt"></span> icon
        </div>
        <div class="col-xs-12">
          <strong>Zoom in:</strong> Click the <span class="fal fa-search-plus"></span> icon. You can also zoom in by scrolling up on your mouse wheel / track pad
        </div>
        <div class="col-xs-12">
          <strong>Zoom out:</strong> Click the <span class="fal fa-search-minus"></span> icon. You can also zoom out by scrolling down on your mouse wheel / track pad
        </div>
        <div class="col-xs-12">
          <strong>Expand all:</strong> Click the <span class="fal fa-expand-arrows-alt"></span> icon on the sidebar on the right
        </div>
        <div class="col-xs-12">
          <strong>Collapse all:</strong> Click the <span class="fal fa-compress-arrows-alt"></span> icon on the sidebar on the right
        </div>
      </div>
    </div>
  </app-modal>
</div>