import { Poll } from '@app/models/poll/poll.model';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import * as moment from 'moment';
import {
  mockReviewPollQuestions,
  mockSentimentPollQuestions
} from '@app/mock/api/data/mockPollQuestions';

export const mockPolls: Poll[] = [
  {
    id: 1,
    name: 'Company Sentiment Poll',
    pollQuestions: mockSentimentPollQuestions,
    description: 'Just a quick poll on how you\'re feeling about the company recently.',
    timezone: 'Europe/Dublin',
    recipients: mockUsers.filter(u => u.id !== mockUsers[0].id).map(u => u.id),
    creatorId: 1,
    publishedDate: moment().subtract(1, 'week').toDate(),
    expiryDate: moment().add(1, 'week').toDate(),
    archived: false,
    creatorUserShown: true,
    completed: false,
    expired: false
  },
  {
    id: 2,
    name: 'Performance Review Process Poll',
    pollQuestions: mockReviewPollQuestions,
    description: 'Just a quick poll on how you\'re feeling about the new review process.',
    timezone: 'Europe/Dublin',
    recipients: mockUsers.slice(0, 20).map(u => u.id),
    creatorId: 1,
    publishedDate: moment().subtract(1, 'week').toDate(),
    expiryDate: moment().add(1, 'week').toDate(),
    archived: false,
    creatorUserShown: true,
    completed: false,
    expired: false
  }
];