import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Goal, GoalHelperFunctions, GoalStatus, GoalType } from 'app/models/goals/goal.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment-timezone';
import { Globals } from 'app/shared/globals/globals';
import { CompanyFeatures } from 'app/models/company.model';
import { PaginationNewComponent } from 'app/shared/pagination/pagination-new/pagination-new.component';
import { FilterCreation, FilterMethod, FilterOption } from '@app/models/universal-filter-option.model';
import { GoalOverviewPages } from '@app/models/goals/goal-overview-pages.model';
import { RoleName } from '@app/models/role.model';
import { CompanyWording } from '@app/models/company/company-wording/company-wording.model';

interface State {
  loading: boolean,
  error: boolean
}

enum PageColumns {
  STATE = 'STATE',
  PRIORITY = 'PRIORITY',
  TYPE = 'TYPE',
  KEY_RESULTS = 'KEY_RESULTS',
  TITLE = 'TITLE',
  PROGRESS = 'PROGRESS',
  END_DATE = 'END_DATE',
  DEPARTMENT = 'DEPARTMENT'
}

enum SortDirection {
  ASCENDING = 'ascending',
  DESCENDING = 'descending'
}

interface Sort {
  type: PageColumns,
  direction: SortDirection
}

interface Filter {
  type: string | undefined,
  filterType: string | undefined
  userId: number | undefined,
  departmentId: number | undefined,
  tagId: number | undefined,
}


@Component({
  selector: 'app-goals-all-table-component',
  templateUrl: './goals-all-table.component.html',
  styleUrls: ['./goals-all-table.component.css'],
})
export class GoalsAllTableComponent implements OnInit, OnChanges {
  @Input() showUniversalFilter: boolean;
  @Input() goals !: Array<Goal>;
  @Input() type !: string;
  @Input() departmentId !: number;
  @Input() tagId !: number;
  @Input() userId !: number;
  @Input() officeLocationId !: number;
  @Input() source: string | null;

  @Input() page: number;
  @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();

  @Output() navigatedToGoal: EventEmitter<number> = new EventEmitter<number>();
  @Output() openCreateModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() stickyPagination: boolean;

  @ViewChild('pagination') pagination!: PaginationNewComponent;

  goalsFiltered: Array<Goal> = new Array<Goal>();
  goalsDisplayed: Array<Goal> = new Array<Goal>();

  state: State;
  filter!: Filter;
  subscriptions: Array<Subscription>;

  eGoalStatus = GoalStatus;
  eGoalType = GoalType;

  departmentName: string;

  sort: Sort;
  ePageColumns = PageColumns;
  eSortDirection = SortDirection;

  columnTitles: string[];

  opened: boolean[];

  eFeature = CompanyFeatures;
  eGoalHelperFunctions = GoalHelperFunctions;
  eGoalOverviewPages = GoalOverviewPages;
  eRoleName = RoleName;

  // Universal filter
  filterReady: boolean;
  searchProps: string[];
  filterOptions: FilterOption[]
  companyWording: CompanyWording;

  constructor(
    public globals: Globals,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.companyWording = this.globals.company.companyWording;
    this.stickyPagination = false;
    this.filterReady = false;
    this.showUniversalFilter = false;
    this.source = null;
    this.page = 1;
    this.sort = {
      type: PageColumns.PRIORITY,
      direction: SortDirection.ASCENDING,
    };
    this.departmentName = '';
    this.state = { loading: true, error: false };
    this.subscriptions = [];

    this.opened = [];

    this.searchProps = [];
    this.filterOptions = [];

    this.columnTitles = Object.keys(PageColumns)

    if (!this.globals.hasFeature(CompanyFeatures.GOAL_PRIORITY)) {
      this.columnTitles = this.columnTitles.filter(c => c !== PageColumns.PRIORITY);
    }
  }

  ngOnInit() {
    if (this.type !== GoalOverviewPages.DEPARTMENT) {
      this.columnTitles = this.columnTitles.filter(c => c !== PageColumns.DEPARTMENT);
    }
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  init() {
    this.goals = Goal.sort.byPriority(this.goals, SortDirection.ASCENDING);
    this.goalsFiltered = this.goals;

    this.opened = this.goals.map(g => false);

    this.populateUniversalFilter();

    if (this.pagination) {
      this.pagination.update();
    }
  }

  populateUniversalFilter() {
    this.filterReady = false;
    const inputOptions: FilterOption[] = [];

    this.goals.forEach(g => {
      const option: FilterOption = {
        id: g.id,
        properties: {
          'goal_name': {value: g.title, filterMethod: FilterMethod.OR},
          'visibility': {value: g.visibility, filterMethod: FilterMethod.OR},
          'priority': {value: g.priority, filterMethod: FilterMethod.OR},
          'state': {value: this.getGoalStateFilter(g.archived, g.complete), filterMethod: FilterMethod.OR},
          'key_result_count': {value: FilterCreation.getRangeFilter(g.keyResults.length), filterMethod: FilterMethod.OR},
          'status': {value: g.status, filterMethod: FilterMethod.OR}
        }
      }

      switch (this.type) {
        case GoalOverviewPages.DEPARTMENT:
          option.properties['department'] = {value: g.department, filterMethod: FilterMethod.OR};
          break;
        case GoalOverviewPages.OFFICE_LOCATION:
          option.properties['site'] = {value: g.officeLocation, filterMethod: FilterMethod.OR};
          break;
        default:
          option.properties['type'] = {value: g.type, filterMethod: FilterMethod.OR};
          break;
      }

      inputOptions.push(option);
    })

    this.searchProps = ['goal_name'];
    this.filterOptions = inputOptions;
    this.filterReady = true;
  }

  universalFilterEmit(IDs: number[]) {
    this.goalsFiltered = this.goals.filter(g => IDs.includes(g.id));
  }

  getGoalStateFilter(archived: boolean, complete: boolean) {
    if (archived) {
      return 'Archived';
    }

    if (complete) {
      return 'Complete';
    }

    return 'Active';
  }

  paginationChanged(e: any) {
    this.pageChanged.emit(e);
  }

  navigateToGoal(id: number) {
    this.router.navigate(['individual/' + id], { relativeTo: this.route })
    .then(res => {
      this.navigatedToGoal.emit(id);
    });
  }

  doSort(type: PageColumns) {
    // Reset all dropdowns
    this.opened = this.goals.map(g => false);

    // If same type as current filter, reverse list
    if (this.sort.type === type) {
      this.goals = this.goals.reverse();

      if (this.sort.direction === SortDirection.ASCENDING) {
        this.sort.direction = SortDirection.DESCENDING
      } else if (this.sort.direction === SortDirection.DESCENDING) {
        this.sort.direction = SortDirection.ASCENDING
      }
    } else {
      this.sort.type = type;

      switch (this.sort.type) {
        case PageColumns.STATE:
          this.goals = this.sortByStatus(this.goals);
          break;
        case PageColumns.PRIORITY:
          this.goals = Goal.sort.byPriority(this.goals, this.sort.direction);
          break;
        case PageColumns.TYPE:
          this.goals = Goal.sort.byType(this.goals, this.sort.direction);
          break;
        case PageColumns.KEY_RESULTS:
          this.goals = Goal.sort.byKeyResults(this.goals, this.sort.direction);
          break;
        case PageColumns.PROGRESS:
          this.goals = Goal.sort.byProgress(this.goals, this.sort.direction);
          break;
        case PageColumns.END_DATE:
          this.goals = Goal.sort.byEndDate(this.goals, this.sort.direction);
          break;
        case PageColumns.TITLE:
        default:
          this.goals = Goal.sort.byTitle(this.goals, this.sort.direction);
          break;
      }
    }

    if (this.pagination) {
      this.pagination.update();
    }
  }

  sortByStatus(input: Goal[]) {
    return input.sort((a, b) => {
      if (a.archived) {
        return 4;
      }
      if (b.archived) {
        return -4;
      }

      if (a.complete) {
        return 4;
      }
      if (b.complete) {
        return -3;
      }

      if (a.status === GoalStatus.OFF_TRACK) {
        return 2;
      }
      if (b.status === GoalStatus.OFF_TRACK) {
        return -2;
      }

      if (a.status === GoalStatus.PROGRESSING) {
        return 1;
      }
      if (b.status === GoalStatus.PROGRESSING) {
        return -1;
      }

      return 0;
    });
  }

  getDaysRemaining(endDate: string) {
    const end = new Date(endDate);

    const a = moment(end);
    const b = moment(new Date());

    const difference = a.diff(b, 'days');
    if (difference === 0) {
      return 'Due today';
    } else if (difference === 1) {
      return '1 day remaining';
    } else if (difference > 1) {
      return difference + ' days remaining';
    } else if (difference === -1) {
      return '1 day overdue';
    } else if (difference < -1) {
      return (-1 * difference) + ' days overdue';
    }

    return '';
  }

  getDaysRemainingTotal(endDate: string) {
    const end = new Date(endDate);

    const a = moment(end);
    const b = moment(new Date());

    return a.diff(b, 'days');
  }

  toggleDropdown(i: number) {
    this.opened[i] = !this.opened[i];
  }

  trackGoalsDisplayed(index: number, item: Goal) {
    return item.id;
  }

  showGoalCreate() {
    this.openCreateModal.emit(true);
  }
}
