import {Component, Input, OnInit} from '@angular/core';
import {Globals} from 'app/shared/globals/globals';
import {CompanyFeatures} from 'app/models/company.model';
import {User} from 'app/models/user/user.model';

@Component({
  selector: 'app-profile-development',
  templateUrl: './profile-development.component.html',
  styleUrls: ['./profile-development.component.css']
})
export class ProfileDevelopmentComponent implements OnInit {
  @Input() userProfile: User;
  user: User;
  globals: Globals;
  eFeature = CompanyFeatures;

  constructor(private _globals: Globals) {
    this.globals = _globals;
    this.user = this.globals.user;
  }

  ngOnInit() {
  }

}
