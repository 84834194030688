import { MockService } from '@app/mock/api/mock-service';
import { Observable } from 'rxjs';
import { CreateTodoDto, Todo, UpdateTodoDto } from '@app/models/todos/todos.model';
import { mockTodos } from '@app/mock/api/data/mockTodos';
import { TodoService } from '@app/shared/api/interfaces/todo.service';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { sanitizeUrl } from '@app/shared/utils/helpers';

@Injectable()
export class TodoMockService implements MockService, TodoService {
    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);

        switch (true){
            case url.endsWith('api/todo/incomplete'):
                return this.getTodosIncomplete();
        }
    }

    getTodos(): Observable<Array<Todo>> | Todo[] {
        return mockTodos;
    }

    getTodosIncomplete(): Array<Todo> {
        return mockTodos.filter(todo => !todo.completionDate);
    }

    getTodosComplete(): Observable<Array<Todo>> | Todo[] {
        return mockTodos.filter(todo => todo.completionDate);
    }

    markTodoAsCompleteById(id: number): any {
    }

    markTodoAsIncompleteById(id: number): any {
    }

    updateTodo(id: number, updateTodoDto: UpdateTodoDto): any {
    }

    createTodo(todo: CreateTodoDto): any {
    }

    deleteTodoById(id: number): any {
    }
}