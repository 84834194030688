import {Component} from '@angular/core';
import {Breadcrumb} from 'app/models/breadcrumb.model';
import {BreadcrumbService} from '../breadcrumbs.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent {
  breadcrumbs: Array<Breadcrumb>;

  constructor(
    private breadcrumbService: BreadcrumbService
  ) {
    this.breadcrumbs = new Array<Breadcrumb>();
    this.breadcrumbService.getBreadcrumbs().subscribe(breadcrumbs => {
      this.breadcrumbs = JSON.parse(JSON.stringify(breadcrumbs));
    });
  }

}
