import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Goal } from 'app/models/goals/goal.model';
import { Observable } from 'rxjs';
import { GoalTag } from 'app/models/goals/goal-tag.model';
import { GoalCommentDto, GoalProgressDto, GoalStatusDto, GoalUpdateDto } from 'app/models/goals/goal.dto';
import { map } from 'rxjs/operators';
import { DateService } from 'app/shared/utils/date.service';
import { GoalTemplate } from 'app/models/goals/goal-template.model';
import { GoalDirectReportOverview } from '@app/models/goals/goal-direct-report-overview.model';
import { GoalDepartmentOverview } from '@app/models/goals/goal-department-overview.model';
import { OfficeLocationGoalOverview } from '@app/models/goals/office-location-goal-overview.model';
import { DepartmentGoalOverview } from '@app/models/goals/department-goal-overview.model';
import { GoalService } from '@app/shared/api/interfaces/goals.service';
import { GoalServerside } from '@app/models/goals/goal-serverside.model';
import { CreateGoalDraftDto } from '@app/models/goals/create-goal-draft.dto';
import { GoalDraft } from '@app/models/goals/goal-draft.model';
import { CreateGoalDto } from '@app/models/goals/create-goal.dto';

@Injectable()
export class GoalsAPIService implements GoalService {
  private readonly BASE_URL = 'api/goal/';

  constructor(
    private http: HttpClient,
    private dateService: DateService
  ) { }

  /**
   * Parses Utc dates to localdate
   * @param goal
   */
  private mapGoalDraft(goal: GoalDraft): GoalDraft {
    if (goal.endDate !== null) {
      goal.endDate = this.dateService.parseUtcToLocal(goal.endDate);
    }
    goal.keyResults.forEach(result => {
      if (result.endDate !== null) {
        result.endDate = this.dateService.parseUtcToLocal(result.endDate);
      }
    });

    return goal;
  }

  createGoal(createGoalDto: CreateGoalDto): Observable<Goal> {
    const url = this.BASE_URL;
    return this.http.post<GoalServerside>(url, createGoalDto).pipe(map(g => new Goal(g)));
  }

  updateGoal(id: number, goalUpdateDto: GoalUpdateDto): Observable<Goal> {
    const url = this.BASE_URL + 'update/goal/' + id;
    return this.http.post<GoalServerside>(url, goalUpdateDto).pipe(map(g => new Goal(g)));
  }

  deleteGoal(id: number) {
    const url = this.BASE_URL + 'delete/' + id;
    return this.http.post(url, null, { responseType: 'text' });
  }

  addComment(id: number, goalCommentDto: GoalCommentDto): Observable<Goal> {
    const url = this.BASE_URL + 'comment/add/' + id;
    return this.http.post<GoalServerside>(url, goalCommentDto).pipe(map(g => new Goal(g)));
  }

  deleteComment(id: number, goalCommentDto: GoalCommentDto): Observable<Goal> {
    const url = this.BASE_URL + 'comment/delete/' + id;
    return this.http.post<GoalServerside>(url, goalCommentDto).pipe(map(g => new Goal(g)));
  }

  updateProgress(id: number, goalProgressDto: GoalProgressDto) {
    const url = this.BASE_URL + 'update/progress/' + id;
    return this.http.post<GoalServerside>(url, goalProgressDto).pipe(map(g => new Goal(g)));
  }

  updateStatus(id: number, goalStatusDto: GoalStatusDto) {
    const url = this.BASE_URL + 'update/status/' + id;
    return this.http.post<GoalServerside>(url, goalStatusDto).pipe(map(g => new Goal(g)));
  }

  getPersonalGoals(): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'personal/';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getPersonalGoalsActive(): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'personal/active/';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getPersonalGoalsComplete(): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'personal/complete/';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getPersonalGoalsArchived(): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'personal/archived/';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getCompanyGoals(): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'company/';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getCompanyGoalsActive(): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'company/active/';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getCompanyGoalsComplete(): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'company/complete/';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getCompanyGoalsArchived(): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'company/archived/';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getGoalById(id: number): Observable<Goal> {
    const url = this.BASE_URL + id;
    return this.http.get<GoalServerside>(url).pipe(map(g => new Goal(g)));
  }

  getOldestGoal(): Observable<Goal> {
    const url = this.BASE_URL + 'oldest';
    return this.http.get<GoalServerside>(url).pipe(map(g => new Goal(g)));
  }

  getTags(type?: string): Observable<Array<GoalTag>> {
    let url = '';
    if (type) {
      url = this.BASE_URL + 'tags?type=' + type;
    } else {
      url = this.BASE_URL + 'tags';
    }
    return this.http.get<Array<GoalTag>>(url);
  }

  createTag(tag: GoalTag): Observable<Array<GoalTag>> {
    const url = this.BASE_URL + 'tags/';
    return this.http.post<Array<GoalTag>>(url, tag);
  }

  updateTag(id: number, tag: GoalTag): Observable<GoalTag> {
    const url = this.BASE_URL + 'tags/' + id;
    return this.http.put<GoalTag>(url, tag);
  }

  deleteTag(id: number): Observable<GoalTag> {
    const url = this.BASE_URL + 'tags/' + id;
    return this.http.delete<GoalTag>(url);
  }

  getOfficeLocationGoalOverview(): Observable<Array<OfficeLocationGoalOverview>> {
    const url = this.BASE_URL + 'office-locations/overview';
    return this.http.get<Array<OfficeLocationGoalOverview>>(url);
  }

  /**
   * Gets all goals the current user is the owners of
   */
  getIndividualGoals(): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'individual/';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getIndividualGoalsByUserId(id: number): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'individual/' + id;
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getIndividualActiveGoalsByUserId(id: number): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'individual/' + id + '/active';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getIndividualArchivedGoalsByUserId(id: number): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'individual/' + id + '/archived';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getIndividualCompleteGoalsByUserId(id: number): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'individual/' + id + '/complete';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  managerGetGoalById(userId: number, goalId: number): Observable<Goal> {
    const url = this.BASE_URL + 'manager/' + userId + '/' + goalId;
    return this.http.get<GoalServerside>(url).pipe(map(g => new Goal(g)));
  }

  getPublicPersonalGoals(id: number) {
    const url = this.BASE_URL + 'personal/' + id;
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getTagGoals(id: number): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'tags/' + id + '/all';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getTagGoalsActive(id: number): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'tags/' + id + '/active';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getTagGoalsComplete(id: number): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'tags/' + id + '/complete';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getTagGoalsArchived(id: number): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'tags/' + id + '/archived';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getGoalsPublicAll(): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'all';
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getGoalsOverviewPercentage(): Observable<Array<number>> {
    const url = this.BASE_URL + 'completion';
    return this.http.get<Array<number>>(url);
  }

  getAlignedGoals(id: number): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'aligned/' + id;
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

  getAdminGoals(): Observable<Array<Goal>> {
    const url = this.BASE_URL + 'admin';
    return this.http.get<Array<GoalServerside>>(url)
      .pipe(
        map(response => response.map(g => new Goal(g)))
      );
  }

  // Goal Drafts
  createDraftGoal(createGoalDraftDto: CreateGoalDraftDto): Observable<GoalDraft> {
    const url = this.BASE_URL + 'draft';
    return this.http.post<GoalDraft>(url, createGoalDraftDto).pipe(map(g => this.mapGoalDraft(g)));
  }

  getDraftGoals(): Observable<Array<GoalDraft>> {
    const url = this.BASE_URL + 'draft/all';
    return this.http.get<Array<GoalDraft>>(url).pipe(map(response => response.map(g => this.mapGoalDraft(g))));
  }

  getDraftGoalById(id: number): Observable<GoalDraft> {
    const url = this.BASE_URL + 'draft/' + id;
    return this.http.get<GoalDraft>(url).pipe(map(g => this.mapGoalDraft(g)));
    ;
  }

  updateDraftGoalById(id: number, draftGoal: GoalDraft): Observable<GoalDraft> {
    const url = this.BASE_URL + 'draft/' + id;
    return this.http.put<GoalDraft>(url, draftGoal).pipe(map(g => this.mapGoalDraft(g)));
    ;
  }

  deleteDraftGoalById(id: number): Observable<GoalDraft> {
    const url = this.BASE_URL + 'draft/' + id;
    return this.http.delete<GoalDraft>(url).pipe(map(g => this.mapGoalDraft(g)));
    ;
  }

  createGoalFromDraftGoal(id: number, goal: Goal): Observable<Goal> {
    const url = this.BASE_URL + 'draft/create/' + id;
    return this.http.post<GoalServerside>(url, goal).pipe(map(g => new Goal(g)));
  }

  // Goal Templates
  getGoalTemplates(): Observable<Array<GoalTemplate>> {
    const url = this.BASE_URL + 'template';
    return this.http.get<Array<GoalTemplate>>(url);
  }

  createGoalTemplate(goalTemplate: GoalTemplate): Observable<GoalTemplate> {
    const url = this.BASE_URL + 'template';
    return this.http.post<GoalTemplate>(url, goalTemplate);
  }

  updateGoalTemplate(templateId: number, goalTemplate: GoalTemplate): Observable<GoalTemplate> {
    const url = this.BASE_URL + `template/${templateId}`;
    return this.http.put<GoalTemplate>(url, goalTemplate);
  }

  deleteGoalTemplate(templateId: number): Observable<GoalTemplate> {
    const url = this.BASE_URL + `template/${templateId}`;
    return this.http.delete<GoalTemplate>(url);
  }

  getTeamGoalsOverview(id?: number): Observable<GoalDirectReportOverview[]> {
    const url = (id ? `${this.BASE_URL}manage/direct/${id}` : `${this.BASE_URL}manage/direct/`);
    return this.http.get<GoalDirectReportOverview[]>(url);
  }

  getTeamGoalsOverviewCeo(): Observable<GoalDirectReportOverview[]> {
    const url = `${this.BASE_URL}manage/direct/ceo`;
    return this.http.get<GoalDirectReportOverview[]>(url);
  }

  getDepartmentGoalsOverview(id?: number): Observable<GoalDepartmentOverview> {
    const url = (id ? `${this.BASE_URL}overview/departments/${id}` : `${this.BASE_URL}overview/departments/`);
    return this.http.get<GoalDepartmentOverview>(url);
  }

  getDepartmentGoalOverviewOld(): Observable<Array<DepartmentGoalOverview>> {
    const url = this.BASE_URL + 'departments/overview';
    return this.http.get<Array<DepartmentGoalOverview>>(url);
  }

  // Office Location Goals

  getOfficeLocationGoals(): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}office-location`;
    return this.getRequestGoalsArray(url);
  }

  getOfficeLocationGoalsActive(): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}office-location/active`;
    return this.getRequestGoalsArray(url);
  }

  getOfficeLocationGoalsComplete(): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}office-location/complete`;
    return this.getRequestGoalsArray(url);
  }

  getOfficeLocationGoalsArchived(): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}office-location/archived`;
    return this.getRequestGoalsArray(url);
  }

  getOfficeLocationGoalsByOfficeLocationId(id: number): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}office-location/${id}`;
    return this.getRequestGoalsArray(url);
  }

  getOfficeLocationGoalsActiveByOfficeLocationId(id: number): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}office-location/${id}/active`;
    return this.getRequestGoalsArray(url);
  }

  getOfficeLocationGoalsCompleteByOfficeLocationId(id: number): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}office-location/${id}/complete`;
    return this.getRequestGoalsArray(url);
  }

  getOfficeLocationGoalsArchivedByOfficeLocationId(id: number): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}office-location/${id}/archived`;
    return this.getRequestGoalsArray(url);
  }

  // Team Goals

  getTeamGoals(): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}team`;
    return this.getRequestGoalsArray(url);
  }

  getTeamGoalsActive(): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}team/active`;
    return this.getRequestGoalsArray(url);
  }

  getTeamGoalsComplete(): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}team/complete`;
    return this.getRequestGoalsArray(url);
  }

  getTeamGoalsArchived(): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}team/archived`;
    return this.getRequestGoalsArray(url);
  }

  getTeamGoalsActiveByManagerId(managerId: number): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}team/${managerId}/active/`;
    return this.getRequestGoalsArray(url);
  }

  getTeamGoalsCompleteByManagerId(managerId: number): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}team/${managerId}/complete`;
    return this.getRequestGoalsArray(url);
  }

  getTeamGoalsArchivedByManagerId(managerId: number): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}team/${managerId}/archived`;
    return this.getRequestGoalsArray(url);
  }

  // Department Goals

  getDepartmentGoals(): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}department`;
    return this.getRequestGoalsArray(url);
  }

  getDepartmentGoalsActive(): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}department/active`;
    return this.getRequestGoalsArray(url);
  }

  getDepartmentGoalsComplete(): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}department/complete`;
    return this.getRequestGoalsArray(url);
  }

  getDepartmentGoalsArchived(): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}department/archived`;
    return this.getRequestGoalsArray(url);
  }

  getDepartmentGoalsByDepartmentId(id: number): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}department/${id}`;
    return this.getRequestGoalsArray(url);
  }

  getDepartmentGoalsActiveByDepartmentId(id: number): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}department/${id}/active/`;
    return this.getRequestGoalsArray(url);
  }

  getDepartmentGoalsCompleteByDepartmentId(id: number): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}department/${id}/complete/`;
    return this.getRequestGoalsArray(url);
  }

  getDepartmentGoalsArchivedByDepartmentId(id: number): Observable<Array<Goal>> {
    const url = `${this.BASE_URL}department/${id}/archived/`;
    return this.getRequestGoalsArray(url);
  }

  // Utils

  getRequestGoalsArray(url: string): Observable<Array<Goal>> {
    return this.http.get<Array<GoalServerside>>(url).pipe(map(response => response.map(g => new Goal(g))));
  }

}
