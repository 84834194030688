import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { User, UserGender } from 'app/models/user/user.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserAPIService } from '../../api/user.api.service';
import { UserProfileDetails } from 'app/models/user/user-profile-details.model';
import { Globals } from '../../globals/globals';
import Swal from 'sweetalert2';
import { EditProfileService } from 'app/profile/profile-components/edit/edit-profile.service';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { SignUpStepType } from '@app/models/sign-up/sign-up-step-type.model';
import { UserConstants } from '@app/constants/user.constants';
import { takeUntil } from 'rxjs/operators';
import { HtmlPipe } from '@app/shared/pipes/html.pipe';

declare var $: any;

@Component({
  selector: 'app-employee-sign-up-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.css']
})
export class ProfileDetailsComponent implements OnInit, OnDestroy {
  public readonly ngUnsubscribe$: Subject<void> = new Subject<void>();
  public readonly eUserGender = UserGender;
  public readonly USER_CONSTANTS = UserConstants;
  public readonly editorToolbar = 'undo redo | formatselect | bold italic underline strikethrough | help';

  @Input() user: User;
  @Input() step;
  @Input() formType: string;

  // Form
  loading: boolean = false;
  submitted: boolean = false;

  form: FormGroup;
  get gender(): FormControl { return this.form.controls.gender as FormControl };
  get description(): FormControl { return this.form.controls.description as FormControl };


  constructor(
      private formBuilder: FormBuilder,
      private userAPIService: UserAPIService,
      private globals: Globals,
      private editProfileService: EditProfileService
  ) {
    this.editProfileService.getCanChange()
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(res => {

          // Prevent navigation if unsaved changes
          if (this.checkForUnsavedChanges()) {
            Swal.fire({
              title: 'Unsaved Changes',
              text: 'Your changes will not be saved if you leave!',
              imageUrl: 'assets/img/swal-icons/Frankli_sure_icon-46.svg',
              imageWidth: 140,
              imageHeight: 140,
              confirmButtonColor: '#54c6bb',
              showCancelButton: true
            }).then(val => {
              if (val.value) {
                this.editProfileService.sendChange(res);
              }
            });
          } else {
            this.editProfileService.sendChange(res);
          }
        });

    this.form = this.formBuilder.group({
      gender: [UserGender.MALE, Validators.required],
      description: ['', [Validators.required, Validators.minLength(UserConstants.DESCRIPTION_MIN_LENGTH), this.softMaxValidation(UserConstants.DESCRIPTION_MAX_LENGTH), Validators.maxLength(UserConstants.DESCRIPTION_MAX_LENGTH_RAW)]],
    });
  }

  ngOnInit() {
    // Description
    if (this.user.description) {
      this.description.setValue(this.user.description);
    }

    // Gender
    const validGenders = [UserGender.MALE, UserGender.FEMALE, UserGender.UNSPECIFIED, UserGender.OTHER];
    if (validGenders.includes(this.user.gender)) {
      this.gender.setValue(this.user.gender);
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }


  softMaxValidation(max: number) {
    return (control: FormControl) => {
      const value = control.value;
      if (value) {
        const htmlPipe = new HtmlPipe();
        const valueNoTags = htmlPipe.transform(value);
        if (valueNoTags.length > max) {
          return {
            softmax: true,
          };
        }
      }
      return null;
    }
  }

  previous(): void {
    this.saveDataToUserObject();
    this.step.complete -= 1;
    this.step.current = SignUpStepType.PERSONAL_DETAILS;
  }

  onFormSubmit(): void {
    this.submitted = true;
    if (this.form.valid) {
      if (this.formType === 'EDIT') {
        this.loading = true;
        this.saveDataToUserObject();
        const userProfileDetails = new UserProfileDetails(this.user);
        this.userAPIService.updateProfileDetails(userProfileDetails).subscribe(user => {
          this.globals.user = user;
          this.user = user;
          $.notify('Your profile has been successfully updated');
          this.loading = false;
        }, (_error: HttpErrorResponse) => {
          this.loading = false;
        })
      } else if (this.formType === 'SIGN_UP') {
        this.saveDataToUserObject();
        // TODO: change to post and update state
        this.step.complete += 1;
        this.step.current = SignUpStepType.SIGNUP_COMPLETE;
      }
    }
  }

  private saveDataToUserObject(): void {
    this.user.gender = this.gender.value;
    this.user.description = this.description.value;
  }

  public checkForUnsavedChanges(): boolean {

    // If gender changed
    if (this.user.gender !== this.gender.value) {
      return true;
    }

    // If description altered at all
    if (this.user.description !== this.description.value) {
      return true;
    }

    return false;
  }

}
