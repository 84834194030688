import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  CreateFeedbackUnpromptedDraftDto,
  CreateFeedbackUnpromptedDto,
  FeedbackUnprompted,
  FeedbackUnpromptedDraft,
  FeedbackUnpromptedProceed,
  FeedbackUnpromptedSettings,
} from 'app/models/feedback/feedback.model';
import { FeedbackService } from '@app/shared/api/interfaces/feedback.service';
import { Observable } from 'rxjs';
import { CreatePeerFeedbackDto, PeerFeedback } from 'app/models/feedback/peer-feedback.model';
import {
  CreateFeedbackRequestDto,
  FeedbackRequest,
  FeedbackRequestResponseAnswer
} from 'app/models/feedback/feedback-request.model';
import { CreateFeedbackUploadDto, FeedbackUpload } from '@app/models/feedback/feedback-upload.model';
import { FeedbackUnpromptedActivity } from '@app/models/feedback/feedback-activity.model';
import { FeedbackUploadActivity } from '@app/models/feedback/feedback-upload-activity.model';

@Injectable()
export class FeedbackAPIService implements FeedbackService {
  baseURL = 'api/feedback';
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(
    private http: HttpClient
  ) {
  }

  // #region - UNPROMPTED FEEDBACK
  submitFeedbackUnprompted(createFeedbackUnpromptedDto: CreateFeedbackUnpromptedDto): Observable<FeedbackUnprompted> {
    const url = `${this.baseURL}/unprompted`;
    return this.http.post<FeedbackUnprompted>(url, createFeedbackUnpromptedDto, {headers: this.headers});
  }

  createFeedbackUnpromptedFromDraft(draftId: number,
                                    createFeedbackUnpromptedDto: CreateFeedbackUnpromptedDto): Observable<FeedbackUnprompted> {
    const url = `${this.baseURL}/unprompted/${draftId}`;
    return this.http.post<FeedbackUnprompted>(url, createFeedbackUnpromptedDto, {headers: this.headers});
  }

  getSentFeedback(): Observable<Array<FeedbackUnprompted>> {
    const url = `${this.baseURL}/unprompted`;
    return this.http.get<Array<FeedbackUnprompted>>(url);
  }

  getSentFeedbackById(id: number): Observable<FeedbackUnprompted> {
    const url = `${this.baseURL}/unprompted/${id}`;
    return this.http.get<FeedbackUnprompted>(url);
  }

  // TODO: Update this route and name
  getFeedbackByManagerMe(): Observable<Array<FeedbackUnprompted>> {
    const url = `${this.baseURL}/submitted/manager/me`;
    return this.http.get<Array<FeedbackUnprompted>>(url);
  }

  // TODO: Update this route and name
  getFeedbackSettings(): Observable<FeedbackUnpromptedSettings> {
    const url = `${this.baseURL}/settings/options`;
    return this.http.get<FeedbackUnpromptedSettings>(url);
  }

  updateFeedbackSettings(proceed: Array<FeedbackUnpromptedProceed>): Observable<FeedbackUnpromptedSettings> {
    const url = `${this.baseURL}/settings/options`;
    return this.http.put<FeedbackUnpromptedSettings>(url, proceed, {headers: this.headers});
  }

  // TODO: Update this route and name
  getFeedbackByManagerAndId(id: number): Observable<FeedbackUnprompted> {
    const url = `${this.baseURL}/submitted/manager/id/${id}`;
    return this.http.get<FeedbackUnprompted>(url);
  }
  // #endregion

  // #region - UNPROMPTED DRAFTS
  createFeedbackUnpromptedDraft(createFeedbackUnpromptedDraftDto: CreateFeedbackUnpromptedDraftDto):
      Observable<FeedbackUnpromptedDraft> {
    const url = `${this.baseURL}/unprompted/draft`;
    return this.http.post<FeedbackUnpromptedDraft>(url,
        createFeedbackUnpromptedDraftDto, {headers: this.headers});
  }

  getFeedbackUnpromptedDrafts(): Observable<Array<FeedbackUnpromptedDraft>> {
    const url = `${this.baseURL}/unprompted/draft`;
    return this.http.get<Array<FeedbackUnpromptedDraft>>(url);
  }

  getFeedbackUnpromptedDraftById(id: number): Observable<FeedbackUnpromptedDraft> {
    const url = `${this.baseURL}/unprompted/draft/${id}`;
    return this.http.get<FeedbackUnpromptedDraft>(url);
  }

  updateFeedbackUnpromptedDraft(id: number, createFeedbackUnpromptedDraftDto: CreateFeedbackUnpromptedDraftDto):
      Observable<FeedbackUnpromptedDraft> {
    const url = `${this.baseURL}/unprompted/draft/${id}`;
    return this.http.put<FeedbackUnpromptedDraft>(url, createFeedbackUnpromptedDraftDto, {headers: this.headers});
  }

  deleteFeedbackUnpromptedDraftById(id: number): Observable<Array<FeedbackUnpromptedDraft>> {
    const url = `${this.baseURL}/unprompted/draft/${id}`;
    return this.http.delete<Array<FeedbackUnpromptedDraft>>(url);
  }
  // #endregion

  // #region - PEER FEEDBACK
  submitPeerFeedback(createPeerFeedbackDto: CreatePeerFeedbackDto): Observable<PeerFeedback> {
    const url = `${this.baseURL}/peer`;
    return this.http.post<PeerFeedback>(url, createPeerFeedbackDto);
  }

  getPeerFeedbackById(id: number): Observable<PeerFeedback> {
    const url = `${this.baseURL}/peer/${id}`;
    return this.http.get<PeerFeedback>(url);
  }

  getPeerFeedbackBySenderId(senderId: number): Observable<Array<PeerFeedback>> {
    const url = `${this.baseURL}/peer/sender/${senderId}`;
    return this.http.get<Array<PeerFeedback>>(url);
  }

  getPeerFeedbackByRecipientId(recipientId: number): Observable<Array<PeerFeedback>> {
    const url = `${this.baseURL}/peer/recipient/${recipientId}`;
    return this.http.get<Array<PeerFeedback>>(url);
  }
  getPeerFeedbackByRecipientsMyDirectReports(): Observable<Array<PeerFeedback>> {
    const url = `${this.baseURL}/peer/recipient/directs`;
    return this.http.get<Array<PeerFeedback>>(url);
  }
  // #endregion

  // #region - FEEDBACK REQUESTS
  createFeedbackRequest(createFeedbackRequestDto: CreateFeedbackRequestDto): Observable<FeedbackRequest> {
    const url = `${this.baseURL}/request`;
    return this.http.post<FeedbackRequest>(url, createFeedbackRequestDto);
  }

  getFeedbackRequestsSent(): Observable<Array<FeedbackRequest>> {
    const url = `${this.baseURL}/request/sent`;
    return this.http.get<Array<FeedbackRequest>>(url);
  }

  getFeedbackRequestSentById(id: number): Observable<FeedbackRequest> {
    const url = `${this.baseURL}/request/sent/${id}`;
    return this.http.get<FeedbackRequest>(url);
  }

  getFeedbackRequestsReceived(): Observable<Array<FeedbackRequest>> {
    const url = `${this.baseURL}/request/received`;
    return this.http.get<Array<FeedbackRequest>>(url);
  }

  getFeedbackRequestReceivedById(id: number): Observable<FeedbackRequest> {
    const url = `${this.baseURL}/request/received/${id}`;
    return this.http.get<FeedbackRequest>(url);
  }

  declineFeedbackRequestById(id: number): Observable<FeedbackRequest> {
    const url = `${this.baseURL}/request/${id}/decline`;
    return this.http.post<FeedbackRequest>(url, null);
  }

  approveFeedbackRequestById(id: number, responseText: FeedbackRequestResponseAnswer[]): Observable<FeedbackRequest> {
    const url = `${this.baseURL}/request/${id}/approve`;
    return this.http.post<FeedbackRequest>(url, responseText);
  }

  getFeedbackRequestsByManagerMe(): Observable<FeedbackRequest[]> {
    const url = `${this.baseURL}/request/sent/manager/me`;
    return this.http.get<FeedbackRequest[]>(url);
  }

  getFeedbackRequestById(id: number): Observable<FeedbackRequest> {
    const url = `${this.baseURL}/request/${id}`;
    return this.http.get<FeedbackRequest>(url);
  }
  // #endregion

  // #region - FEEDBACK UPLOAD
  submitFeedbackUpload(createFeedbackUploadDto: CreateFeedbackUploadDto, file?: File): Observable<FeedbackUpload> {
    const url = `${this.baseURL}/upload`;

    const formData: FormData = new FormData();
    formData.set('details',
      new Blob(
        [JSON.stringify(createFeedbackUploadDto)], {
          type: 'application/json'
        }
      )
    );

    if (file) {
      formData.set('file', file);
    }

    return this.http.post<FeedbackUpload>(url, formData);
  }

  getFeedbackUploadSent(): Observable<FeedbackUpload[]> {
    const url = `${this.baseURL}/upload/sent`;
    return this.http.get<Array<FeedbackUpload>>(url);
  }

  getFeedbackUploadSentById(id: number): Observable<FeedbackUpload> {
    const url = `${this.baseURL}/upload/sent/${id}`;
    return this.http.get<FeedbackUpload>(url);
  }

  // Feedback unprompted activity
  getUnpromptedFeedbackActivityForFeedbackByMe(): Observable<FeedbackUnpromptedActivity[]> {
    const url = `${this.baseURL}/unprompted/activity/sent`;
    return this.http.get<FeedbackUnpromptedActivity[]>(url);
  }
  getUnpromptedFeedbackActivityForFeedbackToMe(): Observable<FeedbackUnpromptedActivity[]> {
    const url = `${this.baseURL}/unprompted/activity/received`;
    return this.http.get<FeedbackUnpromptedActivity[]>(url);
  }

  // Feedback bank activity
  getUploadFeedbackActivityForMe(): Observable<FeedbackUploadActivity[]> {
    const url = `${this.baseURL}/upload/activity`;
    return this.http.get<FeedbackUploadActivity[]>(url);
  }

}
