import { InterestService } from '@app/shared/api/interfaces/interest.service';
import { Injectable } from '@angular/core';
import { MockService } from '@app/mock/api/mock-service';
import { CreateInterestDto, Interest, InterestCategory, UpdateInterestDto } from '@app/models/interest.model';
import { HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mockInterests } from '@app/mock/api/data/mockInterests';
import { groupBy, sanitizeUrl } from '@app/shared/utils/helpers';

@Injectable()
export class InterestMockService implements MockService, InterestService {
    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);

        switch (true) {
            case url.endsWith('api/interests') && req.method === 'GET':
                return this.getAllInterests();
            case url.endsWith('api/interests/personal'):
                return this.getAllPersonal();
            case url.endsWith('api/interests/professional'):
                return this.getAllProfessional();
            case url.endsWith('api/interests/personal/categories'):
                return this.getPersonalCategories();
            case url.endsWith('api/interests/professional/categories'):
                return this.getProfessionalCategories();
            case url.endsWith('api/interests/search'):
                const searchArgument = req.body;
                return this.searchInterests(searchArgument);
            case url.endsWith('api/interests/pending'):
                return this.getPendingInterests();
        }
    }

    getAllInterests(): Observable<Array<Interest>> | Interest[] {
        return mockInterests;
    }

    getAllPersonal(): Observable<Interest[]> | Interest[] {
        return mockInterests.filter(i => i.interestType === 'Personal');
    }

    getAllProfessional(): Observable<Interest[]> | Interest[] {
        return mockInterests.filter(i => i.interestType === 'Professional');
    }

    getPendingInterests(): Observable<Array<Interest>> | Interest[] {
        return mockInterests.filter(i => !i.approved);
    }

    getPersonalCategories(): Observable<InterestCategory[]> | InterestCategory[] {
        const groupedByCategory = groupBy(mockInterests.filter(i => i.interestType === 'Personal'), 'category');
        const interestCategories: InterestCategory[] = [];
        for (const category in groupedByCategory) {
            interestCategories.push({
                name: category,
                icon: '',
                interests: groupedByCategory[category]
            });
        }
        return interestCategories;
    }

    getProfessionalCategories(): Observable<InterestCategory[]> | InterestCategory[] {
        const groupedByCategory = groupBy(mockInterests.filter(i => i.interestType === 'Professional'), 'category');
        const interestCategories: InterestCategory[] = [];
        for (const category in groupedByCategory) {
            interestCategories.push({
                name: category,
                icon: '',
                interests: groupedByCategory[category]
            });
        }
        return interestCategories;
    }

    searchInterests(sarg: string): Observable<Interest[]> | Interest[] {
        const trimmedSearchArg = sarg.trim().toLowerCase();
        return mockInterests.filter(i => i.name.toLowerCase().includes(trimmedSearchArg));
    }

    // No Ops listed below
    importInterests(file: File): Observable<Array<Interest>> {
        return undefined;
    }

    updateInterest(id: number, updateInterestDto: UpdateInterestDto): Observable<Interest> {
        return undefined;
    }

    approvePendingInterest(id: number): Observable<Interest> {
        return undefined;
    }

    createInterest(createInterestDto: CreateInterestDto): Observable<Interest> {
        return undefined;
    }

    deleteInterest(id: number): Observable<Interest> {
        return undefined;
    }
}