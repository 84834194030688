import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionEvent } from '@app/models/session-log/session-event.model';
import { map } from 'rxjs/operators';
import { SessionEventType } from '@app/models/session-log/session-event-type.model';
import { CreateSessionEventDto } from '@app/models/session-log/create-session-event.dto';
import { SessionEventServerSide } from '@app/models/session-log/session-event-serverside.model';
import { SessionLogYear } from '@app/models/session-log/session-event-overview.modal';
import { SessionLogChartRange } from '@app/models/session-log/session-log-chart-range.model';
import { UserStickiness } from '@app/models/session-log/user-stickiness.model';
import { UserStickinessServerside } from '@app/models/session-log/user-stickiness-serverside.model';
import { UserStickinessChartRange } from '@app/models/session-log/user-stickiness-chart-range.model';

@Injectable({ providedIn: 'root' })
export class SessionLogAPIService {
  private readonly BASE_URL = 'api/session';

  constructor(private http: HttpClient) { }

  createSessionEvent(sessionEventType: SessionEventType): Observable<SessionEvent> {
    const url = `${this.BASE_URL}/event`;
    const createSessionEventDto = new CreateSessionEventDto(sessionEventType);

    return this.http.post<SessionEventServerSide>(url, createSessionEventDto).pipe(map(event => new SessionEvent(event)));
  }

  getSessionEvents(): Observable<Array<SessionEvent>> {
    const url = `${this.BASE_URL}/event/company`;
    return this.http.get<Array<SessionEventServerSide>>(url).pipe(map(events => events.map(event => new SessionEvent(event))));
  }

  getSessionEventsByUserId(userId: number): Observable<Array<SessionEvent>> {
    const url = `${this.BASE_URL}/event/user/${userId}`;
    return this.http.get<Array<SessionEventServerSide>>(url).pipe(map(events => events.map(event => new SessionEvent(event))));
  }

  // #region - frankli admin

  frankliAdminGetSessionEventsByCompanyId(companyId: number): Observable<Array<SessionEvent>> {
    const url = `${this.BASE_URL}/event/company/${companyId}`;
    return this.http.get<Array<SessionEventServerSide>>(url).pipe(map(events => events.map(event => new SessionEvent(event))));
  }

  frankliAdminGetSessionEventsByCompanyIdAndUserId(companyId: number, userId: number): Observable<Array<SessionEvent>> {
    const url = `${this.BASE_URL}/event/company/${companyId}/user/${userId}`;
    return this.http.get<Array<SessionEventServerSide>>(url).pipe(map(events => events.map(event => new SessionEvent(event))));
  }

  frankliAdminGetSessionLogOverview(sessionLogChartRange: SessionLogChartRange): Observable<Array<SessionLogYear>> {
    const url = `${this.BASE_URL}/overview?range=${sessionLogChartRange}`;
    return this.http.get<Array<SessionLogYear>>(url);
  }

  frankliAdminGetSessionLogOverviewByCompanyId(companyId: number, sessionLogChartRange: SessionLogChartRange): Observable<Array<SessionLogYear>> {
    const url = `${this.BASE_URL}/overview/company/${companyId}?range=${sessionLogChartRange}`;
    return this.http.get<Array<SessionLogYear>>(url);
  }

  frankliAdminGetUserStickinessByRange(userStickinessChartRange: UserStickinessChartRange): Observable<Array<UserStickiness>> {
    let params = new HttpParams();
    params = params.append('range', userStickinessChartRange);

    const url = `${this.BASE_URL}/stickiness`;
    return this.http.get<Array<UserStickinessServerside>>(url, {params: params}).pipe(map(days => days.map(day => new UserStickiness(day))));
  }

  frankliAdminGetUserStickinessByCompanyIdAndRange(companyId: number, userStickinessChartRange: UserStickinessChartRange): Observable<Array<UserStickiness>> {
    let params = new HttpParams();
    params = params.append('range', userStickinessChartRange);

    const url = `${this.BASE_URL}/stickiness/company/${companyId}`;
    return this.http.get<Array<UserStickinessServerside>>(url, {params: params}).pipe(map(days => days.map(day => new UserStickiness(day))));
  }

  frankliAdminGetLastSeenByCompanyId(companyId: number): Observable<Array<SessionEvent>> {
    const url = `${this.BASE_URL}/last-seen/company/${companyId}`;
    return this.http.get<Array<SessionEventServerSide>>(url).pipe(map(events => events.map(event => new SessionEvent(event))));
  }

  // #endregion

}
