import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { HtmlPipe } from '../pipes/html.pipe';

export class FrankliValidators {

  // Applies to individual controls
  // Returns validDate error if date is in the past
  public static dateInFuture(control: AbstractControl) {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (control.value !== null) {
      const dPDate = new Date(control.value);
        dPDate.setHours(0, 0, 0, 0);
      if ((dPDate.getTime() < currentDate.getTime())) {
        return { validDate: true };
      } else {
        return null;
      }
    }
  }

  // Applies to form groups
  // Returns endBeforeStart error if end date is before start
  // Form controls must be called startDate and endDate
  public static startDateEndDateCompare(g: FormGroup) {
    const startDate = new Date(g.controls.startDate.value);
    const endDate = new Date(g.controls.endDate.value);
    if (startDate.getTime() > endDate.getTime()) {
      return { endBeforeStart: true }
    } else {
      return null
    }
  }

  public static goalCompletionValidator(g: FormGroup) {
    const measureStartValue = g.controls.measureStartValue;
    const measureGoalValue = g.controls.measureGoalValue;
    return  measureStartValue && measureGoalValue && Number(measureStartValue.value) === Number(measureGoalValue.value) ? { goalSetToEqual: true } : null;
  }

  public static softMinValidation(min: number): ValidatorFn {
    return (control: AbstractControl) => {
      const value = control.value;
      if (value) {
        const htmlPipe = new HtmlPipe();
        const valueNoTags = htmlPipe.transform(value);
        if (valueNoTags.length < min) {
          return {
            softmin: true,
          };
        }
      }

      return null!;
    }
  }

  public static softMaxValidation(max: number): ValidatorFn {
    return (control: AbstractControl) => {
      const value = control.value;
      if (value) {
        const htmlPipe = new HtmlPipe();
        const valueNoTags = htmlPipe.transform(value);
        if (valueNoTags.length > max) {
          return {
            softmax: true,
          };
        }
      }

      return null!;
    }
  }
}
