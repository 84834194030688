<div class="content">
  <div class="header">
    <h4 class="title text-left">More about {{(user.id === userProfile.id)?'me':userProfile.firstName}}</h4>
    <hr class="less-padding" />
  </div>
  <!-- If any interests selected -->
  <ng-container *ngIf="(userProfile.interestsCoach.length > 0) || (userProfile.interestsSocialise.length > 0) || (userProfile.interestsMentor.length > 0)">

    <!-- Headings -->
    <div class="row" *ngIf="globals.hasFeature(eFeature.CONNECT_COACH_MENTOR)">
      <div class="col-md-3 m-left-20">
        <h6>Seeking Coaching in</h6>
        <a *ngFor="let interest of userProfile.interestsCoach" class="interest-btn" [routerLink]="'connect/coaching/interests'" [queryParams]="{source: 'profile'}">{{interest.name}}
          <br />
        </a>
        <ng-container *ngIf="userProfile.interestsCoach.length === 0">
          <div class="none-selected"> None selected yet</div>
          <button class="btn-frankli-round btn-frankli-green btn-frankli-small btn-add" [routerLink]="'connect/coaching/interests'" [queryParams]="{source: 'profile'}" *ngIf='(user.id == userProfile.id)'>Add</button>
        </ng-container>
      </div>
      <div class="col-md-3 col-md-offset-1">
        <h6>Seeking Mentoring in</h6>
        <a *ngFor="let interest of userProfile.interestsMentor" class="interest-btn" [routerLink]="'connect/mentoring/interests'" [queryParams]="{source: 'profile'}">{{interest.name}}
          <br />
        </a>
        <ng-container *ngIf="userProfile.interestsMentor.length === 0">
          <div class="none-selected">None selected yet</div>
          <button *ngIf='(user.id == userProfile.id)' class="btn-frankli-round btn-frankli-green btn-frankli-small btn-add" [routerLink]="'connect/mentoring/interests'" [queryParams]="{source: 'profile'}">Add</button>
        </ng-container>
      </div>
      <div class="col-md-3 col-md-offset-1">
        <h6>Socialising in</h6>
        <a *ngFor="let interest of userProfile.interestsSocialise" class="interest-btn" [routerLink]="'connect/socialise'" [queryParams]="{source: 'profile'}">{{interest.name}}
          <br />
        </a>
        <ng-container *ngIf="userProfile.interestsSocialise.length === 0">
          <div class="none-selected"> None selected yet</div>
          <button class="btn-frankli-round btn-frankli-green btn-frankli-small btn-add" [routerLink]="'connect/socialise'" [queryParams]="{source: 'profile'}" *ngIf='(user.id == userProfile.id)'>Add</button>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="(userProfile.interestsCoach.length === 0) && (userProfile.interestsSocialise.length === 0) && (userProfile.interestsMentor.length === 0)">
    <!-- No interests and same user -->
    <div class="row" *ngIf="user.id === userProfile.id">
      <div class="col-md-8 col-md-offset-2 text-center">
        <h5>Interested in developing skills, socialising or mentoring your colleagues?</h5>
      </div>
      <div class="col-md-4 col-md-offset-4 text-center">
        <button class="btn-frankli-round btn-frankli-green btn-frankli-small" [routerLink]="'connect'" [queryParams]="{source: 'profile'}">Get Started</button>
      </div>
    </div>

    <!-- No interests and different user -->
    <div class="row" *ngIf="user.id !== userProfile.id">
      <div class="col-md-8 col-md-offset-2 text-center m-bottom-20">
        <h5>{{userProfile.firstName}} has not selected any development needs</h5>
      </div>
    </div>
  </ng-container>
</div>