import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpMockApiInterceptor } from '@app/mock/http-mock-api.interceptor';
import { AuthMockService } from '@app/mock/api/services/auth.mock.service';
import { CompanyMockService } from '@app/mock/api/services/company.mock.service';
import { UserMockService } from '@app/mock/api/services/user/user.mock.service';
import { TodoMockService } from '@app/mock/api/services/todo.mock.service';
import { MockApiService } from '@app/mock/api/mock-api.service';
import { WelcomeMockService } from '@app/mock/api/services/welcome.mock.service';
import { GoalMockService } from '@app/mock/api/services/goal.mock.service';
import { NewFeatureMockService } from '@app/mock/api/services/new-feature.mock.service';
import { NewsActivityMockService } from '@app/mock/api/services/news-activity.mock.service';
import { InterestMockService } from '@app/mock/api/services/interest.mock.service';
import { CompetencyMockService } from '@app/mock/api/services/competency.mock.service';
import { PositionMockService } from '@app/mock/api/services/position.mock.service';
import { IdeaMockService } from '@app/mock/api/services/idea.mock.service';
import { PraiseMockService } from '@app/mock/api/services/praise.mock.service';
import { FeedbackMockService } from '@app/mock/api/services/feedback.mock.service';
import { AnalyticsMockService } from '@app/mock/api/services/analytics.mock.service';
import { NotificationMockService } from '@app/mock/api/services/notification.mock.service';
import { EvaluationCycleMockService } from '@app/mock/api/services/evaluation-cycle.mock.service';
import { OneToOneMockService } from '@app/mock/api/services/one-to-one.mock.service';
import { PollMockService } from '@app/mock/api/services/poll.mock.service';
import { SurveyMockService } from '@app/mock/api/services/survey.mock.service';
import { QuestionBankMockService } from '@app/mock/api/services/question-bank.mock.service';
import { ConnectMockHandler } from '@app/mock/api/services/connect/connect.mock.handler';
import { CoachApplicationMockService } from '@app/mock/api/services/connect/coach-application.mock.service';
import { CoachingRequestMockService } from '@app/mock/api/services/connect/coaching-request.mock.service';
import { ConnectActivityMockService } from '@app/mock/api/services/connect/connect-activity.mock.service';
import { ConnectQuestionMockService } from '@app/mock/api/services/connect/connect-question.mock.service';
import { MentorMockService } from '@app/mock/api/services/connect/mentor.mock.service';
import { MentoringRequestMockService } from '@app/mock/api/services/connect/mentoring-request.mock.service';
import { HttpMockErrorInterceptor } from '@app/mock/http-mock-error.interceptor';
import { SettingsMockService } from '@app/mock/api/services/settings.mock.service';
import { SpellcheckMockService } from '@app/mock/api/services/spellcheck.mock.service';
import { CalendarMockService } from '@app/mock/api/services/calendar.mock.service';
import { UserMockHandler } from '@app/mock/api/services/user/user.mock.handler';
import { PermissionMockService } from '@app/mock/api/services/user/permission.mock.service';

// Append any new mock services to this list
const mockServices = [
  AuthMockService,
  CompanyMockService,
  UserMockHandler,
  UserMockService,
  PermissionMockService,
  TodoMockService,
  WelcomeMockService,
  GoalMockService,
  NewFeatureMockService,
  NewsActivityMockService,
  InterestMockService,
  CompetencyMockService,
  PositionMockService,
  IdeaMockService,
  PraiseMockService,
  FeedbackMockService,
  AnalyticsMockService,
  NotificationMockService,
  EvaluationCycleMockService,
  OneToOneMockService,
  PollMockService,
  SurveyMockService,
  QuestionBankMockService,
  CoachApplicationMockService,
  CoachingRequestMockService,
  ConnectActivityMockService,
  ConnectQuestionMockService,
  MentorMockService,
  MentoringRequestMockService,
  ConnectMockHandler,
  SettingsMockService,
  SpellcheckMockService,
  CalendarMockService
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    mockServices,

    // These last 3 providers need to go last since they depend on the mock services.
    // The order is important here, because we want the Mock Error Handler to intercept No-Ops from the MockAPI
    MockApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpMockErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpMockApiInterceptor,
      multi: true
    }
  ]
})
export class MockModule { }
