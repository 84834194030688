import { SurveyAnalytics } from '@app/models/analytics/survey-analytics-model';
import { LikertDistType } from '@app/models/analytics/likert-score-distribution.model';

export const mockSurveyAnalytics: SurveyAnalytics = {
  "all": {
    "name": "All",
    "entityId": 13,
    "entitySurveys": 77,
    "averageInvited": 21,
    "averageResponses": 0,
    "totalCompleted": {
      "JANUARY": 14,
      "FEBRUARY": 19,
      "MARCH": 14,
      "APRIL": 14,
      "MAY": 17,
      "JUNE": 19,
      "JULY": 17,
      "AUGUST": 18,
      "SEPTEMBER": 15,
      "OCTOBER": 17,
      "NOVEMBER": 17,
      "DECEMBER": 16
    },
    "totalScheduled": {
      "JANUARY": 18,
      "FEBRUARY": 15,
      "MARCH": 13,
      "APRIL": 20,
      "MAY": 18,
      "JUNE": 19,
      "JULY": 14,
      "AUGUST": 15,
      "SEPTEMBER": 17,
      "OCTOBER": 21,
      "NOVEMBER": 11,
      "DECEMBER": 16
    },
    "status": {
      "OPEN": 4,
      "EXPIRED": 165,
      "ARCHIVED": 8,
      "COMPLETED": 0,
      "SCHEDULED": 20
    },
    "frequencies": {
      "Daily": 0,
      "Weekly": 1,
      "Fortnightly": 0,
      "Monthly": 0,
      "Quarterly": 22,
      "Annually": 0,
      "Individual": 54
    },
    "distributions": [
      {
        "type": LikertDistType.DEPARTMENT,
        "average": 3.3442622950819674,
        "entities": [
          {
            "entityId": 261,
            "name": "Finance",
            "totalResponses": 13,
            "scores": {
              "STRONGLY_DISAGREE": 2,
              "DISAGREE": 2,
              "NEUTRAL": 2,
              "AGREE": 5,
              "STRONGLY_AGREE": 2
            }
          },
          {
            "entityId": 91,
            "name": "Engineering",
            "totalResponses": 23,
            "scores": {
              "STRONGLY_DISAGREE": 3,
              "DISAGREE": 3,
              "NEUTRAL": 2,
              "AGREE": 12,
              "STRONGLY_AGREE": 3
            }
          },
          {
            "entityId": 298,
            "name": "Interior Design Team",
            "totalResponses": 12,
            "scores": {
              "STRONGLY_DISAGREE": 2,
              "DISAGREE": 2,
              "NEUTRAL": 1,
              "AGREE": 5,
              "STRONGLY_AGREE": 2
            }
          },
          {
            "entityId": 93,
            "name": "Projects",
            "totalResponses": 13,
            "scores": {
              "STRONGLY_DISAGREE": 0,
              "DISAGREE": 3,
              "NEUTRAL": 2,
              "AGREE": 7,
              "STRONGLY_AGREE": 1
            }
          }
        ]
      },
      {
        "type": LikertDistType.SITE,
        "average": 3.5121951219512195,
        "entities": [
          {
            "entityId": 92,
            "name": "Sydney Office, Oz",
            "totalResponses": 3,
            "scores": {
              "STRONGLY_DISAGREE": 0,
              "DISAGREE": 1,
              "NEUTRAL": 0,
              "AGREE": 2,
              "STRONGLY_AGREE": 0
            }
          },
          {
            "entityId": 725,
            "name": "Fairmont, USA",
            "totalResponses": 12,
            "scores": {
              "STRONGLY_DISAGREE": 2,
              "DISAGREE": 2,
              "NEUTRAL": 1,
              "AGREE": 5,
              "STRONGLY_AGREE": 2
            }
          },
          {
            "entityId": 88,
            "name": "HQ",
            "totalResponses": 6,
            "scores": {
              "STRONGLY_DISAGREE": 0,
              "DISAGREE": 0,
              "NEUTRAL": 0,
              "AGREE": 2,
              "STRONGLY_AGREE": 4
            }
          },
          {
            "entityId": 93,
            "name": "London Office",
            "totalResponses": 26,
            "scores": {
              "STRONGLY_DISAGREE": 2,
              "DISAGREE": 5,
              "NEUTRAL": 4,
              "AGREE": 12,
              "STRONGLY_AGREE": 3
            }
          },
          {
            "entityId": 395,
            "name": "Auckland Office ",
            "totalResponses": 18,
            "scores": {
              "STRONGLY_DISAGREE": 1,
              "DISAGREE": 1,
              "NEUTRAL": 1,
              "AGREE": 12,
              "STRONGLY_AGREE": 3
            }
          },
          {
            "entityId": 91,
            "name": "Galway Office ",
            "totalResponses": 3,
            "scores": {
              "STRONGLY_DISAGREE": 0,
              "DISAGREE": 0,
              "NEUTRAL": 0,
              "AGREE": 1,
              "STRONGLY_AGREE": 2
            }
          },
          {
            "entityId": 95,
            "name": "Working remotely",
            "totalResponses": 14,
            "scores": {
              "STRONGLY_DISAGREE": 3,
              "DISAGREE": 3,
              "NEUTRAL": 2,
              "AGREE": 4,
              "STRONGLY_AGREE": 2
            }
          }
        ]
      }
    ],
    "templateQuestionDistribution": {
      "type": LikertDistType.TEMPLATE,
      "average": 3.3442622950819674,
      "entities": [
        {
          "entityId": 91,
          "name": "The values of Neonyx Software align well with my own values.",
          "totalResponses": 4,
          "scores": {
            "STRONGLY_DISAGREE": 0,
            "DISAGREE": 1,
            "NEUTRAL": 1,
            "AGREE": 2,
            "STRONGLY_AGREE": 0
          }
        },
        {
          "entityId": 85,
          "name": "There is regular communication between employees and management about health and wellbeing.",
          "totalResponses": 5,
          "scores": {
            "STRONGLY_DISAGREE": 3,
            "DISAGREE": 1,
            "NEUTRAL": 0,
            "AGREE": 1,
            "STRONGLY_AGREE": 0
          }
        },
        {
          "entityId": 81,
          "name": "I know what to do if I feel anxious or stressed out at work.",
          "totalResponses": 5,
          "scores": {
            "STRONGLY_DISAGREE": 0,
            "DISAGREE": 0,
            "NEUTRAL": 1,
            "AGREE": 4,
            "STRONGLY_AGREE": 0
          }
        },
        {
          "entityId": 84,
          "name": "I believe my health and wellbeing is a priority for my manager.",
          "totalResponses": 4,
          "scores": {
            "STRONGLY_DISAGREE": 0,
            "DISAGREE": 1,
            "NEUTRAL": 0,
            "AGREE": 2,
            "STRONGLY_AGREE": 1
          }
        },
        {
          "entityId": 83,
          "name": "We are genuinely supported if we choose to make use of flexible working arrangements.",
          "totalResponses": 5,
          "scores": {
            "STRONGLY_DISAGREE": 0,
            "DISAGREE": 1,
            "NEUTRAL": 2,
            "AGREE": 0,
            "STRONGLY_AGREE": 2
          }
        },
        {
          "entityId": 13,
          "name": "My manager genuinely cares about my wellbeing.",
          "totalResponses": 5,
          "scores": {
            "STRONGLY_DISAGREE": 0,
            "DISAGREE": 1,
            "NEUTRAL": 0,
            "AGREE": 4,
            "STRONGLY_AGREE": 0
          }
        },
        {
          "entityId": 41,
          "name": "Neonyx Software really allows us to make a positive difference.",
          "totalResponses": 5,
          "scores": {
            "STRONGLY_DISAGREE": 0,
            "DISAGREE": 1,
            "NEUTRAL": 0,
            "AGREE": 3,
            "STRONGLY_AGREE": 1
          }
        },
        {
          "entityId": 77,
          "name": "I believe Neonyx Software is truly committed to the health and wellbeing of employees.",
          "totalResponses": 4,
          "scores": {
            "STRONGLY_DISAGREE": 1,
            "DISAGREE": 0,
            "NEUTRAL": 0,
            "AGREE": 2,
            "STRONGLY_AGREE": 1
          }
        },
        {
          "entityId": 76,
          "name": "I am able to arrange time out from work when I need to.",
          "totalResponses": 5,
          "scores": {
            "STRONGLY_DISAGREE": 1,
            "DISAGREE": 2,
            "NEUTRAL": 0,
            "AGREE": 2,
            "STRONGLY_AGREE": 0
          }
        },
        {
          "entityId": 86,
          "name": "A healthy lifestyle is encouraged at Neonyx Software.",
          "totalResponses": 5,
          "scores": {
            "STRONGLY_DISAGREE": 0,
            "DISAGREE": 1,
            "NEUTRAL": 2,
            "AGREE": 1,
            "STRONGLY_AGREE": 1
          }
        },
        {
          "entityId": 82,
          "name": "I feel like I am treated fairly at Neonyx Software.",
          "totalResponses": 5,
          "scores": {
            "STRONGLY_DISAGREE": 1,
            "DISAGREE": 0,
            "NEUTRAL": 0,
            "AGREE": 3,
            "STRONGLY_AGREE": 1
          }
        },
        {
          "entityId": 40,
          "name": "Neonyx Software's commitment to social responsibility (e.g. community support, sustainability, etc.) is genuine.",
          "totalResponses": 5,
          "scores": {
            "STRONGLY_DISAGREE": 1,
            "DISAGREE": 1,
            "NEUTRAL": 1,
            "AGREE": 2,
            "STRONGLY_AGREE": 0
          }
        },
        {
          "entityId": 63,
          "name": "I have good friendships at Neonyx Software.",
          "totalResponses": 4,
          "scores": {
            "STRONGLY_DISAGREE": 0,
            "DISAGREE": 0,
            "NEUTRAL": 0,
            "AGREE": 3,
            "STRONGLY_AGREE": 1
          }
        }
      ]
    }
  },
  "senders": [
    {
      "name": "Gavin Greene",
      "entityId": 291,
      "entitySurveys": 12,
      "averageInvited": 6,
      "averageResponses": 0,
      "totalCompleted": {
        "JANUARY": 5,
        "FEBRUARY": 5,
        "MARCH": 5,
        "APRIL": 5,
        "MAY": 5,
        "JUNE": 7,
        "JULY": 3,
        "AUGUST": 6,
        "SEPTEMBER": 6,
        "OCTOBER": 5,
        "NOVEMBER": 5,
        "DECEMBER": 5
      },
      "totalScheduled": {
        "JANUARY": 4,
        "FEBRUARY": 5,
        "MARCH": 5,
        "APRIL": 6,
        "MAY": 7,
        "JUNE": 6,
        "JULY": 1,
        "AUGUST": 6,
        "SEPTEMBER": 6,
        "OCTOBER": 6,
        "NOVEMBER": 4,
        "DECEMBER": 6
      },
      "status": {
        "OPEN": 1,
        "EXPIRED": 60,
        "ARCHIVED": 0,
        "COMPLETED": 0,
        "SCHEDULED": 1
      },
      "frequencies": {
        "Daily": 0,
        "Weekly": 1,
        "Fortnightly": 0,
        "Monthly": 0,
        "Quarterly": 1,
        "Annually": 0,
        "Individual": 10
      },
      "distributions": [
        {
          "type": LikertDistType.DEPARTMENT,
          "average": null,
          "entities": []
        },
        {
          "type": LikertDistType.SITE,
          "average": null,
          "entities": []
        }
      ],
      "templateQuestionDistribution": {
        "type": LikertDistType.TEMPLATE,
        "average": null,
        "entities": []
      }
    },
    {
      "name": "Jane Smith",
      "entityId": 290,
      "entitySurveys": 65,
      "averageInvited": 28,
      "averageResponses": 0,
      "totalCompleted": {
        "JANUARY": 9,
        "FEBRUARY": 14,
        "MARCH": 9,
        "APRIL": 9,
        "MAY": 12,
        "JUNE": 12,
        "JULY": 14,
        "AUGUST": 12,
        "SEPTEMBER": 9,
        "OCTOBER": 12,
        "NOVEMBER": 12,
        "DECEMBER": 11
      },
      "totalScheduled": {
        "JANUARY": 14,
        "FEBRUARY": 10,
        "MARCH": 8,
        "APRIL": 14,
        "MAY": 11,
        "JUNE": 13,
        "JULY": 13,
        "AUGUST": 9,
        "SEPTEMBER": 11,
        "OCTOBER": 15,
        "NOVEMBER": 7,
        "DECEMBER": 10
      },
      "status": {
        "OPEN": 3,
        "EXPIRED": 105,
        "ARCHIVED": 8,
        "COMPLETED": 0,
        "SCHEDULED": 19
      },
      "frequencies": {
        "Daily": 0,
        "Weekly": 0,
        "Fortnightly": 0,
        "Monthly": 0,
        "Quarterly": 21,
        "Annually": 0,
        "Individual": 44
      },
      "distributions": [
        {
          "type": LikertDistType.DEPARTMENT,
          "average": 3.3442622950819674,
          "entities": [
            {
              "entityId": 261,
              "name": "Finance",
              "totalResponses": 13,
              "scores": {
                "STRONGLY_DISAGREE": 2,
                "DISAGREE": 2,
                "NEUTRAL": 2,
                "AGREE": 5,
                "STRONGLY_AGREE": 2
              }
            },
            {
              "entityId": 91,
              "name": "Engineering",
              "totalResponses": 23,
              "scores": {
                "STRONGLY_DISAGREE": 3,
                "DISAGREE": 3,
                "NEUTRAL": 2,
                "AGREE": 12,
                "STRONGLY_AGREE": 3
              }
            },
            {
              "entityId": 298,
              "name": "Interior Design Team",
              "totalResponses": 12,
              "scores": {
                "STRONGLY_DISAGREE": 2,
                "DISAGREE": 2,
                "NEUTRAL": 1,
                "AGREE": 5,
                "STRONGLY_AGREE": 2
              }
            },
            {
              "entityId": 93,
              "name": "Projects",
              "totalResponses": 13,
              "scores": {
                "STRONGLY_DISAGREE": 0,
                "DISAGREE": 3,
                "NEUTRAL": 2,
                "AGREE": 7,
                "STRONGLY_AGREE": 1
              }
            }
          ]
        },
        {
          "type": LikertDistType.SITE,
          "average": 3.5121951219512195,
          "entities": [
            {
              "entityId": 92,
              "name": "Sydney Office, Oz",
              "totalResponses": 3,
              "scores": {
                "STRONGLY_DISAGREE": 0,
                "DISAGREE": 1,
                "NEUTRAL": 0,
                "AGREE": 2,
                "STRONGLY_AGREE": 0
              }
            },
            {
              "entityId": 725,
              "name": "Fairmont, USA",
              "totalResponses": 12,
              "scores": {
                "STRONGLY_DISAGREE": 2,
                "DISAGREE": 2,
                "NEUTRAL": 1,
                "AGREE": 5,
                "STRONGLY_AGREE": 2
              }
            },
            {
              "entityId": 88,
              "name": "HQ",
              "totalResponses": 6,
              "scores": {
                "STRONGLY_DISAGREE": 0,
                "DISAGREE": 0,
                "NEUTRAL": 0,
                "AGREE": 2,
                "STRONGLY_AGREE": 4
              }
            },
            {
              "entityId": 93,
              "name": "London Office",
              "totalResponses": 26,
              "scores": {
                "STRONGLY_DISAGREE": 2,
                "DISAGREE": 5,
                "NEUTRAL": 4,
                "AGREE": 12,
                "STRONGLY_AGREE": 3
              }
            },
            {
              "entityId": 395,
              "name": "Auckland Office ",
              "totalResponses": 18,
              "scores": {
                "STRONGLY_DISAGREE": 1,
                "DISAGREE": 1,
                "NEUTRAL": 1,
                "AGREE": 12,
                "STRONGLY_AGREE": 3
              }
            },
            {
              "entityId": 91,
              "name": "Galway Office ",
              "totalResponses": 3,
              "scores": {
                "STRONGLY_DISAGREE": 0,
                "DISAGREE": 0,
                "NEUTRAL": 0,
                "AGREE": 1,
                "STRONGLY_AGREE": 2
              }
            },
            {
              "entityId": 95,
              "name": "Working remotely",
              "totalResponses": 14,
              "scores": {
                "STRONGLY_DISAGREE": 3,
                "DISAGREE": 3,
                "NEUTRAL": 2,
                "AGREE": 4,
                "STRONGLY_AGREE": 2
              }
            }
          ]
        }
      ],
      "templateQuestionDistribution": {
        "type": LikertDistType.TEMPLATE,
        "average": 3.3442622950819674,
        "entities": [
          {
            "entityId": 91,
            "name": "The values of Neonyx Software align well with my own values.",
            "totalResponses": 4,
            "scores": {
              "STRONGLY_DISAGREE": 0,
              "DISAGREE": 1,
              "NEUTRAL": 1,
              "AGREE": 2,
              "STRONGLY_AGREE": 0
            }
          },
          {
            "entityId": 85,
            "name": "There is regular communication between employees and management about health and wellbeing.",
            "totalResponses": 5,
            "scores": {
              "STRONGLY_DISAGREE": 3,
              "DISAGREE": 1,
              "NEUTRAL": 0,
              "AGREE": 1,
              "STRONGLY_AGREE": 0
            }
          },
          {
            "entityId": 81,
            "name": "I know what to do if I feel anxious or stressed out at work.",
            "totalResponses": 5,
            "scores": {
              "STRONGLY_DISAGREE": 0,
              "DISAGREE": 0,
              "NEUTRAL": 1,
              "AGREE": 4,
              "STRONGLY_AGREE": 0
            }
          },
          {
            "entityId": 84,
            "name": "I believe my health and wellbeing is a priority for my manager.",
            "totalResponses": 4,
            "scores": {
              "STRONGLY_DISAGREE": 0,
              "DISAGREE": 1,
              "NEUTRAL": 0,
              "AGREE": 2,
              "STRONGLY_AGREE": 1
            }
          },
          {
            "entityId": 83,
            "name": "We are genuinely supported if we choose to make use of flexible working arrangements.",
            "totalResponses": 5,
            "scores": {
              "STRONGLY_DISAGREE": 0,
              "DISAGREE": 1,
              "NEUTRAL": 2,
              "AGREE": 0,
              "STRONGLY_AGREE": 2
            }
          },
          {
            "entityId": 13,
            "name": "My manager genuinely cares about my wellbeing.",
            "totalResponses": 5,
            "scores": {
              "STRONGLY_DISAGREE": 0,
              "DISAGREE": 1,
              "NEUTRAL": 0,
              "AGREE": 4,
              "STRONGLY_AGREE": 0
            }
          },
          {
            "entityId": 41,
            "name": "Neonyx Software really allows us to make a positive difference.",
            "totalResponses": 5,
            "scores": {
              "STRONGLY_DISAGREE": 0,
              "DISAGREE": 1,
              "NEUTRAL": 0,
              "AGREE": 3,
              "STRONGLY_AGREE": 1
            }
          },
          {
            "entityId": 77,
            "name": "I believe Neonyx Software is truly committed to the health and wellbeing of employees.",
            "totalResponses": 4,
            "scores": {
              "STRONGLY_DISAGREE": 1,
              "DISAGREE": 0,
              "NEUTRAL": 0,
              "AGREE": 2,
              "STRONGLY_AGREE": 1
            }
          },
          {
            "entityId": 76,
            "name": "I am able to arrange time out from work when I need to.",
            "totalResponses": 5,
            "scores": {
              "STRONGLY_DISAGREE": 1,
              "DISAGREE": 2,
              "NEUTRAL": 0,
              "AGREE": 2,
              "STRONGLY_AGREE": 0
            }
          },
          {
            "entityId": 86,
            "name": "A healthy lifestyle is encouraged at Neonyx Software.",
            "totalResponses": 5,
            "scores": {
              "STRONGLY_DISAGREE": 0,
              "DISAGREE": 1,
              "NEUTRAL": 2,
              "AGREE": 1,
              "STRONGLY_AGREE": 1
            }
          },
          {
            "entityId": 82,
            "name": "I feel like I am treated fairly at Neonyx Software.",
            "totalResponses": 5,
            "scores": {
              "STRONGLY_DISAGREE": 1,
              "DISAGREE": 0,
              "NEUTRAL": 0,
              "AGREE": 3,
              "STRONGLY_AGREE": 1
            }
          },
          {
            "entityId": 40,
            "name": "Neonyx Software's commitment to social responsibility (e.g. community support, sustainability, etc.) is genuine.",
            "totalResponses": 5,
            "scores": {
              "STRONGLY_DISAGREE": 1,
              "DISAGREE": 1,
              "NEUTRAL": 1,
              "AGREE": 2,
              "STRONGLY_AGREE": 0
            }
          },
          {
            "entityId": 63,
            "name": "I have good friendships at Neonyx Software.",
            "totalResponses": 4,
            "scores": {
              "STRONGLY_DISAGREE": 0,
              "DISAGREE": 0,
              "NEUTRAL": 0,
              "AGREE": 3,
              "STRONGLY_AGREE": 1
            }
          }
        ]
      }
    }
  ],
  "templates": [
    {
      "name": "Wellbeing Survey",
      "entityId": 750,
      "entitySurveys": 9,
      "averageInvited": 31,
      "averageResponses": 0,
      "totalCompleted": {
        "JANUARY": 0,
        "FEBRUARY": 6,
        "MARCH": 0,
        "APRIL": 0,
        "MAY": 6,
        "JUNE": 0,
        "JULY": 1,
        "AUGUST": 6,
        "SEPTEMBER": 0,
        "OCTOBER": 0,
        "NOVEMBER": 0,
        "DECEMBER": 1
      },
      "totalScheduled": {
        "JANUARY": 3,
        "FEBRUARY": 3,
        "MARCH": 0,
        "APRIL": 3,
        "MAY": 3,
        "JUNE": 1,
        "JULY": 3,
        "AUGUST": 3,
        "SEPTEMBER": 0,
        "OCTOBER": 0,
        "NOVEMBER": 1,
        "DECEMBER": 0
      },
      "status": {
        "OPEN": 1,
        "EXPIRED": 13,
        "ARCHIVED": 0,
        "COMPLETED": 0,
        "SCHEDULED": 6
      },
      "frequencies": {
        "Daily": 0,
        "Weekly": 0,
        "Fortnightly": 0,
        "Monthly": 0,
        "Quarterly": 6,
        "Annually": 0,
        "Individual": 3
      },
      "distributions": [
        {
          "type": LikertDistType.DEPARTMENT,
          "average": 3.3442622950819674,
          "entities": [
            {
              "entityId": 261,
              "name": "Finance",
              "totalResponses": 13,
              "scores": {
                "STRONGLY_DISAGREE": 2,
                "DISAGREE": 2,
                "NEUTRAL": 2,
                "AGREE": 5,
                "STRONGLY_AGREE": 2
              }
            },
            {
              "entityId": 91,
              "name": "Engineering",
              "totalResponses": 23,
              "scores": {
                "STRONGLY_DISAGREE": 3,
                "DISAGREE": 3,
                "NEUTRAL": 2,
                "AGREE": 12,
                "STRONGLY_AGREE": 3
              }
            },
            {
              "entityId": 298,
              "name": "Interior Design Team",
              "totalResponses": 12,
              "scores": {
                "STRONGLY_DISAGREE": 2,
                "DISAGREE": 2,
                "NEUTRAL": 1,
                "AGREE": 5,
                "STRONGLY_AGREE": 2
              }
            },
            {
              "entityId": 93,
              "name": "Projects",
              "totalResponses": 13,
              "scores": {
                "STRONGLY_DISAGREE": 0,
                "DISAGREE": 3,
                "NEUTRAL": 2,
                "AGREE": 7,
                "STRONGLY_AGREE": 1
              }
            }
          ]
        },
        {
          "type": LikertDistType.SITE,
          "average": 3.3442622950819674,
          "entities": [
            {
              "entityId": 395,
              "name": "Auckland Office ",
              "totalResponses": 12,
              "scores": {
                "STRONGLY_DISAGREE": 1,
                "DISAGREE": 1,
                "NEUTRAL": 1,
                "AGREE": 8,
                "STRONGLY_AGREE": 1
              }
            },
            {
              "entityId": 95,
              "name": "Working remotely",
              "totalResponses": 11,
              "scores": {
                "STRONGLY_DISAGREE": 2,
                "DISAGREE": 2,
                "NEUTRAL": 1,
                "AGREE": 4,
                "STRONGLY_AGREE": 2
              }
            },
            {
              "entityId": 725,
              "name": "Fairmont, USA",
              "totalResponses": 12,
              "scores": {
                "STRONGLY_DISAGREE": 2,
                "DISAGREE": 2,
                "NEUTRAL": 1,
                "AGREE": 5,
                "STRONGLY_AGREE": 2
              }
            },
            {
              "entityId": 93,
              "name": "London Office",
              "totalResponses": 26,
              "scores": {
                "STRONGLY_DISAGREE": 2,
                "DISAGREE": 5,
                "NEUTRAL": 4,
                "AGREE": 12,
                "STRONGLY_AGREE": 3
              }
            }
          ]
        }
      ],
      "templateQuestionDistribution": {
        "type": LikertDistType.TEMPLATE,
        "average": 3.3442622950819674,
        "entities": [
          {
            "entityId": 91,
            "name": "The values of Neonyx Software align well with my own values.",
            "totalResponses": 4,
            "scores": {
              "STRONGLY_DISAGREE": 0,
              "DISAGREE": 1,
              "NEUTRAL": 1,
              "AGREE": 2,
              "STRONGLY_AGREE": 0
            }
          },
          {
            "entityId": 85,
            "name": "There is regular communication between employees and management about health and wellbeing.",
            "totalResponses": 5,
            "scores": {
              "STRONGLY_DISAGREE": 3,
              "DISAGREE": 1,
              "NEUTRAL": 0,
              "AGREE": 1,
              "STRONGLY_AGREE": 0
            }
          },
          {
            "entityId": 81,
            "name": "I know what to do if I feel anxious or stressed out at work.",
            "totalResponses": 5,
            "scores": {
              "STRONGLY_DISAGREE": 0,
              "DISAGREE": 0,
              "NEUTRAL": 1,
              "AGREE": 4,
              "STRONGLY_AGREE": 0
            }
          },
          {
            "entityId": 84,
            "name": "I believe my health and wellbeing is a priority for my manager.",
            "totalResponses": 4,
            "scores": {
              "STRONGLY_DISAGREE": 0,
              "DISAGREE": 1,
              "NEUTRAL": 0,
              "AGREE": 2,
              "STRONGLY_AGREE": 1
            }
          },
          {
            "entityId": 83,
            "name": "We are genuinely supported if we choose to make use of flexible working arrangements.",
            "totalResponses": 5,
            "scores": {
              "STRONGLY_DISAGREE": 0,
              "DISAGREE": 1,
              "NEUTRAL": 2,
              "AGREE": 0,
              "STRONGLY_AGREE": 2
            }
          },
          {
            "entityId": 13,
            "name": "My manager genuinely cares about my wellbeing.",
            "totalResponses": 5,
            "scores": {
              "STRONGLY_DISAGREE": 0,
              "DISAGREE": 1,
              "NEUTRAL": 0,
              "AGREE": 4,
              "STRONGLY_AGREE": 0
            }
          },
          {
            "entityId": 41,
            "name": "Neonyx Software really allows us to make a positive difference.",
            "totalResponses": 5,
            "scores": {
              "STRONGLY_DISAGREE": 0,
              "DISAGREE": 1,
              "NEUTRAL": 0,
              "AGREE": 3,
              "STRONGLY_AGREE": 1
            }
          },
          {
            "entityId": 77,
            "name": "I believe Neonyx Software is truly committed to the health and wellbeing of employees.",
            "totalResponses": 4,
            "scores": {
              "STRONGLY_DISAGREE": 1,
              "DISAGREE": 0,
              "NEUTRAL": 0,
              "AGREE": 2,
              "STRONGLY_AGREE": 1
            }
          },
          {
            "entityId": 76,
            "name": "I am able to arrange time out from work when I need to.",
            "totalResponses": 5,
            "scores": {
              "STRONGLY_DISAGREE": 1,
              "DISAGREE": 2,
              "NEUTRAL": 0,
              "AGREE": 2,
              "STRONGLY_AGREE": 0
            }
          },
          {
            "entityId": 86,
            "name": "A healthy lifestyle is encouraged at Neonyx Software.",
            "totalResponses": 5,
            "scores": {
              "STRONGLY_DISAGREE": 0,
              "DISAGREE": 1,
              "NEUTRAL": 2,
              "AGREE": 1,
              "STRONGLY_AGREE": 1
            }
          },
          {
            "entityId": 82,
            "name": "I feel like I am treated fairly at Neonyx Software.",
            "totalResponses": 5,
            "scores": {
              "STRONGLY_DISAGREE": 1,
              "DISAGREE": 0,
              "NEUTRAL": 0,
              "AGREE": 3,
              "STRONGLY_AGREE": 1
            }
          },
          {
            "entityId": 40,
            "name": "Neonyx Software's commitment to social responsibility (e.g. community support, sustainability, etc.) is genuine.",
            "totalResponses": 5,
            "scores": {
              "STRONGLY_DISAGREE": 1,
              "DISAGREE": 1,
              "NEUTRAL": 1,
              "AGREE": 2,
              "STRONGLY_AGREE": 0
            }
          },
          {
            "entityId": 63,
            "name": "I have good friendships at Neonyx Software.",
            "totalResponses": 4,
            "scores": {
              "STRONGLY_DISAGREE": 0,
              "DISAGREE": 0,
              "NEUTRAL": 0,
              "AGREE": 3,
              "STRONGLY_AGREE": 1
            }
          }
        ]
      }
    }
  ]
}