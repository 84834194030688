<div class="row pagination-padding" *ngIf="dataArray.length > 0">
  <div class="col-sm-12">
    <div class="dataTables_info" id="datatables_info" role="status" aria-live="polite">
      Showing {{ displayAmountStart }} to {{ displayAmountEnd }} of {{ displayAmountTotal }}
    </div>
  </div>
</div>

<div class="row" *ngIf="dataArray.length > 0">
  <div class="col-sm-12 text-center">
    <div class="dataTables_paginate pagination-controller">
      <ul class="pagination">
        <li (click)="firstPage()" [class.disabled]="currentPage === 1" class="clickable noselect">
          <a>First</a>
        </li>
        <li (click)="previousPage()" [class.disabled]="currentPage === 1" class="clickable noselect">
          <a><span class="fal fa-angle-left"></span></a>
        </li>

        <li *ngFor="let page of pages" (click)="goToPage(page)" [class.active]="currentPage === page" class="clickable noselect">
          <a>{{page}}</a>
        </li>

        <li (click)="nextPage()" [class.disabled]="currentPage === getLastPage()" class="clickable noselect">
          <a><span class="fal fa-angle-right"></span></a>
        </li>
        <li (click)="lastPage()" [class.disabled]="currentPage === getLastPage()" class="clickable noselect">
          <a>Last</a>
        </li>
      </ul>
    </div>
  </div>
</div>