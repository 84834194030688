import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import Swal from 'sweetalert2';
import { Globals } from '../globals/globals';
import { environment } from '../../../environments/environment';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<any> {

  constructor(
    private globals: Globals
  ) {}

  canDeactivate(
    component: any,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    const unsavedChanges = component.unsavedChanges();
    if (!this.globals.user || environment.mock.enabled) {
      return true;
    }
    if (unsavedChanges === true) {
      return from(Swal.fire({
        title: 'Unsaved Changes',
        text: 'Your changes will not be saved if you leave!',
        imageUrl: 'assets/img/swal-icons/Frankli_sure_icon-46.svg',
        imageWidth: 140,
        imageHeight: 140,
        confirmButtonText: 'Stay',
        confirmButtonColor: '#54C6BB',
        cancelButtonText: 'Leave',
        showCancelButton: true,
        width: 390,
        reverseButtons: true
      }).then(val => {
        if (val.dismiss) {
          if (val.dismiss.toString() === 'cancel') {
            return true;
          }
        }
        return false;
      }));
    }

    return true;
  }
}
