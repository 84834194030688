import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from 'app/models/user/user.model';
import { UserAPIService } from '../../api/user.api.service';
import { UserPersonalDetails } from 'app/models/user/user-personal-details.model';
import { Globals } from 'app/shared/globals/globals';
import Swal from 'sweetalert2';
import { EditProfileService } from 'app/profile/profile-components/edit/edit-profile.service';
import { Subscription } from 'rxjs';
import { CountriesPrimary, CountriesRest, Country } from 'app/shared/utils/countries.util';
import * as moment from 'moment-timezone';
import { HttpErrorResponse } from '@angular/common/http';
import { PreferredContactMethod } from '@app/models/user/preferred-contact-method.model';
import { SignUpStepType } from '@app/models/sign-up/sign-up-step-type.model';

declare var $: any;

@Component({
  selector: 'app-employee-sign-up-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.css'],
})
export class PersonalDetailsComponent implements OnInit, OnDestroy {
  // form settings
  readonly charactersMaxLength = 255;
  readonly charactersMaxLengthPhoneNumber = 17;
  readonly linkedInRegExp = new RegExp('.*linkedin.com.*');

  @Input() user!: User;
  @Input() formType!: string;
  @Input() step!: any;

  subscriptionCanChange!: Subscription;

  // form
  form!: FormGroup;
  submitted: boolean;
  loading: boolean;

  ePreferredContactMethod = PreferredContactMethod;


  // form data
  countriesPrimary: Array<Country>;
  countriesRest: Array<Country>;

  minDate: moment.Moment;
  maxDate: moment.Moment;

  constructor(
    private formBuilder: FormBuilder,
    private userAPIService: UserAPIService,
    private globals: Globals,
    private editProfileService: EditProfileService,
  ) {
    this.minDate = moment().subtract(100, 'years');
    this.maxDate = moment().subtract(10, 'years')

    this.countriesPrimary = CountriesPrimary;
    this.countriesRest = CountriesRest;
    this.submitted = false;
    this.loading = false;

    this.initCanChange();
    this.initForm();
  }

  ngOnInit() {
    this.populateWithUserData();
  }

  ngOnDestroy() {
    this.subscriptionCanChange.unsubscribe();
  }

  initCanChange() {
    this.subscriptionCanChange = this.editProfileService.getCanChange().subscribe(res => {
      // check for unsaved changes
      if (this.checkForUnsavedChanges()) {
        Swal.fire({
          title: 'Unsaved Changes',
          text: 'Your changes will not be saved if you leave!',
          imageUrl: 'assets/img/swal-icons/Frankli_sure_icon-46.svg',
          imageWidth: 140,
          imageHeight: 140,
          imageAlt: 'Custom image',
          animation: false,
          confirmButtonColor: '#54c6bb',
          showCancelButton: true,
        }).then(val => {
          if (val.dismiss) {
            return false;
          }

          this.editProfileService.sendChange(res);
        });
      } else {
        this.editProfileService.sendChange(res);
      }
    });
  }

  initForm() {
    this.form = this.formBuilder.group({
      birthdayDate: [null, []],
      birthdayReminder: [true, []],
      preferredContact: [PreferredContactMethod.NONE, [Validators.required]],
      contactNumber: [null, [Validators.maxLength(this.charactersMaxLengthPhoneNumber)]],
      twitterHandle: [null, [Validators.pattern('^@?(\\w){1,15}$')]],
      linkedIn: [null, [Validators.maxLength(255), Validators.pattern(this.linkedInRegExp)]],
      teamsEmail: [null, [Validators.maxLength(255), Validators.pattern(/^(.*).@(.*).\..+$/)]]
    });

    this.form.controls.birthdayDate.valueChanges.subscribe(res => this.markFromDirty());
    this.form.controls.birthdayReminder.valueChanges.subscribe(res => this.markFromDirty());
    this.form.controls.preferredContact.valueChanges.subscribe(res => this.markFromDirty());
    this.form.controls.contactNumber.valueChanges.subscribe(res => this.markFromDirty());
    this.form.controls.twitterHandle.valueChanges.subscribe(res => this.markFromDirty());
    this.form.controls.linkedIn.valueChanges.subscribe(res => this.markFromDirty());
    this.form.controls.teamsEmail.valueChanges.subscribe(res => this.markFromDirty());
  }

  markFromDirty() {
    this.form.markAsDirty();
  }

  // #region - SUBMITTING
  onFormSubmit() {
    this.submitted = true;
    if (this.form.valid) {
      switch (this.formType) {
        case 'EDIT':
          this.doSubmitEdit();
          break;
        case 'SIGN_UP':
          this.doSubmitSignUp();
          break;
      }
    }
  }

  doSubmitEdit() {
    this.loading = true;
    this.saveDataToUserObject();
    const details = new UserPersonalDetails(this.user);
    this.userAPIService.updatePersonalDetails(details).subscribe(user => {
      this.globals.updateUser(user);
      this.user = user;
      $.notify('Your profile has been successfully updated');
      this.populateWithUserData();
      this.form.markAsPristine();
      this.loading = false;
    }, (error: HttpErrorResponse) => {
      this.loading = false;
    });
  }

  doSubmitSignUp() {
    this.saveDataToUserObject();
    this.step.complete += 1;
    this.step.current = SignUpStepType.PROFILE_DETAILS;
  }
  // #endregion

  // Populate form with data if user is navigating back to this page
  populateWithUserData() {
    this.form.controls.birthdayDate.setValue(this.user.dateOfBirth, { emitEvent: false });
    this.form.controls.birthdayReminder.setValue(this.user.birthdayReminder, { emitEvent: false });
    this.form.controls.contactNumber.setValue(this.user.phoneNumber, { emitEvent: false });
    this.form.controls.twitterHandle.setValue(this.user.twitterHandle, { emitEvent: false });
    this.form.controls.linkedIn.setValue(this.user.linkedIn, { emitEvent: false });
    this.form.controls.teamsEmail.setValue(this.user.teamsEmail, { emitEvent: false });
    this.form.controls.preferredContact.setValue(this.user.preferredContactMethod, { emitEvent: false });
  }

  saveDataToUserObject() {
    // date of birth
    if (this.form.controls.birthdayDate.value == null) {
      this.user.dateOfBirth = null;
    } else {
      this.user.dateOfBirth = moment.utc(this.form.controls.birthdayDate.value).toDate();
    }

    // birthday reminder
    this.user.birthdayReminder = this.form.controls.birthdayReminder.value;

    // Preferred contact method
    if (this.form.controls.preferredContact.value) {
      this.user.preferredContactMethod = this.form.controls.preferredContact.value;
    }

    // Linkedin
    if (this.form.controls.linkedIn.value) {
      this.user.linkedIn = this.form.controls.linkedIn.value.replace('https://', '').replace('http://', '').replace('www.', '');
    }

    // Phone number
    if (this.form.controls.contactNumber.value) {
      this.user.phoneNumber = this.form.controls.contactNumber.value.trim();
    }

    // Teams email
    if (this.form.controls.teamsEmail.value) {
      this.user.teamsEmail = this.form.controls.teamsEmail.value;
    }

    // Twitter
    if (this.form.controls.twitterHandle.value && this.form.controls.twitterHandle.value.startsWith('@')) { // TODO: Use validator instead
      this.user.twitterHandle = this.form.controls.twitterHandle.value.substr(1);
    } else {
      this.user.twitterHandle = this.form.controls.twitterHandle.value;
    }
  }

  checkForUnsavedChanges(): boolean {
    return this.form.dirty;
  }

  showHelp() {
    Swal.fire({
      text: 'You can click on the heading to choose wider spans of time',
      imageUrl: 'assets/img/datepicker_help.gif',
      animation: false,
      confirmButtonColor: '#54c6bb',
    });
  }
}
