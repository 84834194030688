import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'enumToArray'
})

export class EnumToArrayPipe implements PipeTransform {
  previousValue!: Object;
  resultCached!: string[];

  transform(data: Object) {
    // For enums with number based values
    /*
    const keys = Object.keys(data);
    return keys.slice(keys.length / 2);
    */

    // Skip calculation if value hasn't changed
    if ((data === this.previousValue) && this.resultCached) {
      return this.resultCached;
    }
    this.previousValue = data;

    // For enums with intialised values
    const result = Object.keys(data);
    this.resultCached = result;
    return result;

  }
}

// from https://stackoverflow.com/questions/38554562/how-can-i-use-ngfor-to-iterate-over-typescript-enum-as-an-array-of-strings
