import { EvaluationCycleModule } from '@app/models/evaluation/evaluation-cycle-module.model';
import { EvaluationModuleType } from '@app/models/evaluation/evaluation-module-type.model';
import * as moment from 'moment';

export const mockMidYearReviewModules: EvaluationCycleModule[] = [
    {
        id: 1,
        startDateTime: moment().month(moment().month()).subtract(1, 'week').startOf('month').toDate(),
        endDateTime: moment().month(moment().month()).add(2, 'weeks').endOf('month').toDate(),
        activated: true,
        closed: false,
        evaluationModuleType: EvaluationModuleType.SELF_REFLECTION,
        stepNumber: 1
    },
    {
        id: 2,
        startDateTime: moment().month(moment().month()).subtract(1, 'week').startOf('month').toDate(),
        endDateTime: moment().month(moment().month()).add(2, 'weeks').endOf('month').toDate(),
        activated: true,
        closed: false,
        evaluationModuleType: EvaluationModuleType.MANAGER_REVIEW_EMPLOYEE,
        stepNumber: 1
    }
];

export const mockEndYearReviewModules: EvaluationCycleModule[] = [
    {
        id: 1,
        startDateTime: moment().subtract(1, 'year').month(7).startOf('month').toDate(),
        endDateTime: moment().subtract(1, 'year').month(11).endOf('month').toDate(),
        activated: true,
        closed: true,
        evaluationModuleType: EvaluationModuleType.SELF_REFLECTION,
        stepNumber: 1
    },
    {
        id: 2,
        startDateTime: moment().subtract(1, 'year').month(7).startOf('month').toDate(),
        endDateTime: moment().subtract(1, 'year').month(11).endOf('month').toDate(),
        activated: true,
        closed: true,
        evaluationModuleType: EvaluationModuleType.MANAGER_REVIEW_EMPLOYEE,
        stepNumber: 1
    }
];

export const mockEvaluationCycleModules: EvaluationCycleModule[] = [
    ...mockMidYearReviewModules
];