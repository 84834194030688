import { GoalKeyResult } from '@app/models/goals/goal-key-result.model';
import * as moment from 'moment';
import { GoalKeyResultType } from '@app/models/goals/goal-key-result-type.model';
import { GoalKeyResultMeasureUnitPlacement } from '@app/models/goals/goal-key-result-measure-unit-placement.model';

export const mockGoalKeyResults: GoalKeyResult[] = [
    {
        id: 1,
        result: "Document our backup policies and make sure they get implemented",
        type: GoalKeyResultType.BINARY,
        measureCurrentValue: 1,
        measureGoalValue: 1,
        measureStartValue: 0,
        endDate: moment().add(5, 'days').toDate(),
        reversed: false,
        measureUnit: null,
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.BEFORE,
        getProgress(): number { return 100; }
    },
    {
        id: 2,
        result: "For learning, review security policies of 5 other companies",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 3,
        measureGoalValue: 5,
        measureStartValue: 0,
        endDate: moment().add(5, 'days').toDate(),
        reversed: false,
        measureUnit: "Companies",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 60; }
    },
    {
        id: 3,
        result: "Conduct a security assessment of our codebase using automated tools",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 100,
        measureGoalValue: 100,
        measureStartValue: 0,
        endDate: moment().add(1, 'month').toDate(),
        reversed: false,
        measureUnit: "%",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 100; }
    },
    {
        id: 4,
        result: "Implement test-driven development in 3 new development teams",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 3,
        measureGoalValue: 3,
        measureStartValue: 0,
        endDate: moment().add(5, 'weeks').toDate(),
        reversed: false,
        measureUnit: "Teams",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 100; }
    },
    {
        id: 5,
        result: "Document clear role division between sales, marketing, design and development",
        type: GoalKeyResultType.BINARY,
        measureCurrentValue: 1,
        measureGoalValue: 1,
        measureStartValue: 0,
        endDate: moment().add(5, 'days').toDate(),
        reversed: false,
        measureUnit: null,
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.BEFORE,
        getProgress(): number { return 100; }
    },
    {
        id: 6,
        result: "Decide on and document the process of input methods to and from sales, marketing, design and development back into product management",
        type: GoalKeyResultType.BINARY,
        measureCurrentValue: 0,
        measureGoalValue: 1,
        measureStartValue: 0,
        endDate: moment().add(5, 'days').toDate(),
        reversed: false,
        measureUnit: null,
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.BEFORE,
        getProgress(): number { return 0; }
    },
    {
        id: 7,
        result: "Integrate user testing into pre-launch testing phase",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 60,
        measureGoalValue: 100,
        measureStartValue: 0,
        endDate: moment().add(1, 'month').toDate(),
        reversed: false,
        measureUnit: "Companies",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 60; }
    },
    {
        id: 8,
        result: "Document our backup policies and make sure they get implemented",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 30,
        measureGoalValue: 100,
        measureStartValue: 0,
        endDate: moment().add(5, 'weeks').toDate(),
        reversed: false,
        measureUnit: "%",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 30; }
    },
    {
        id: 9,
        result: "Conduct a day-long planning session with each division manager",
        type: GoalKeyResultType.BINARY,
        measureCurrentValue: 1,
        measureGoalValue: 1,
        measureStartValue: 0,
        endDate: moment().add(5, 'days').toDate(),
        reversed: false,
        measureUnit: null,
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.BEFORE,
        getProgress(): number { return 100; }
    },
    {
        id: 10,
        result: "Have each business line manager start using our online dashboards",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 60,
        measureGoalValue: 100,
        measureStartValue: 0,
        endDate: moment().add(1, 'month').toDate(),
        reversed: false,
        measureUnit: "%",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 60; }
    },
    {
        id: 11,
        result: "Close the final budget",
        type: GoalKeyResultType.BINARY,
        measureCurrentValue: 0,
        measureGoalValue: 1,
        measureStartValue: 0,
        endDate: moment().add(5, 'weeks').toDate(),
        reversed: false,
        measureUnit: null,
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.BEFORE,
        getProgress(): number { return 0; }
    },
    {
        id: 12,
        result: "E-mail and phone outreach to 100 VCs and seed funds",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 1000,
        measureGoalValue: 1000,
        measureStartValue: 0,
        endDate: moment().add(1, 'month').toDate(),
        reversed: false,
        measureUnit: "VCs",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 100; }
    },
    {
        id: 13,
        result: "Get at least 30 second contact meetings or conference calls",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 30,
        measureGoalValue: 30,
        measureStartValue: 0,
        endDate: moment().add(5, 'weeks').toDate(),
        reversed: false,
        measureUnit: "Meetings",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 100; }
    },
    {
        id: 14,
        result: "Design and promote our new values on intranet and in office environment",
        type: GoalKeyResultType.BINARY,
        measureCurrentValue: 1,
        measureGoalValue: 1,
        measureStartValue: 0,
        endDate: moment().add(5, 'weeks').toDate(),
        reversed: false,
        measureUnit: null,
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.BEFORE,
        getProgress(): number { return 100; }
    },
    {
        id: 15,
        result: "Survey all employees on our current values (min 75 answers received)",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 40,
        measureGoalValue: 100,
        measureStartValue: 0,
        endDate: moment().add(5, 'days').toDate(),
        reversed: false,
        measureUnit: "Answers",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 40; }
    },
    {
        id: 16,
        result: "With 3 separate teams, conduct brainstorm sessions to redefine our culture",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 2,
        measureGoalValue: 3,
        measureStartValue: 0,
        endDate: moment().add(1, 'month').toDate(),
        reversed: false,
        measureUnit: "Teams",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 66; }
    },
    {
        id: 17,
        result: "Survey 15 team leaders on our educational needs and gaps",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 10,
        measureGoalValue: 15,
        measureStartValue: 0,
        endDate: moment().add(5, 'weeks').toDate(),
        reversed: false,
        measureUnit: "Leaders",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 66; }
    },
    {
        id: 18,
        result: "Conduct the first 3-day team offsite to test out the Academy curriculum",
        type: GoalKeyResultType.BINARY,
        measureCurrentValue: 0,
        measureGoalValue: 1,
        measureStartValue: 0,
        endDate: moment().add(1, 'month').toDate(),
        reversed: false,
        measureUnit: null,
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.BEFORE,
        getProgress(): number { return 0; }
    },
    {
        id: 19,
        result: "Talk to 3 other companies HR Directors on how they do internal education",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 1,
        measureGoalValue: 3,
        measureStartValue: 0,
        endDate: moment().add(5, 'weeks').toDate(),
        reversed: false,
        measureUnit: "%",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 33; }
    },
    {
        id: 20,
        result: "Start sales in 2 new countries and achieve first quarter revenues totaling over €100000",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 2,
        measureGoalValue: 2,
        measureStartValue: 0,
        endDate: moment().add(5, 'weeks').toDate(),
        reversed: false,
        measureUnit: "Countries",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 100; }
    },
    {
        id: 21,
        result: "Increase gross profit % margin from 23% to 54%",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 50,
        measureGoalValue: 54,
        measureStartValue: 0,
        endDate: moment().add(5, 'days').toDate(),
        reversed: false,
        measureUnit: "%",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 95; }
    },
    {
        id: 22,
        result: "Hit quarterly revenue of over €100000",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 50000,
        measureGoalValue: 100000,
        measureStartValue: 0,
        endDate: moment().add(3, 'month').toDate(),
        reversed: false,
        measureUnit: "€",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.BEFORE,
        getProgress(): number { return 50; }
    },
    {
        id: 23,
        result: "Create a best practices sales process document with minimum allowed service levels",
        type: GoalKeyResultType.BINARY,
        measureCurrentValue: 0,
        measureGoalValue: 1,
        measureStartValue: 0,
        endDate: moment().add(5, 'weeks').toDate(),
        reversed: false,
        measureUnit: null,
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.BEFORE,
        getProgress(): number { return 0; }
    },
    {
        id: 24,
        result: "Make sure at least 50% of signups % called back in first 24 hours",
        type: GoalKeyResultType.BINARY,
        measureCurrentValue: 1,
        measureGoalValue: 1,
        measureStartValue: 0,
        endDate: moment().add(1, 'month').toDate(),
        reversed: false,
        measureUnit: null,
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.BEFORE,
        getProgress(): number { return 100; }
    },
    {
        id: 25,
        result: "Have all salespeople listen in to at least 10 product demos of other team members",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 7,
        measureGoalValue: 10,
        measureStartValue: 0,
        endDate: moment().add(5, 'weeks').toDate(),
        reversed: false,
        measureUnit: "Demos",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 70; }
    },
    {
        id: 26,
        result: "10 tests of our recent infographics and slide decks for customer understanding",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 1,
        measureGoalValue: 10,
        measureStartValue: 0,
        endDate: moment().add(5, 'weeks').toDate(),
        reversed: false,
        measureUnit: "Tests",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 10; }
    },
    {
        id: 27,
        result: "Get 1000 answers to an online user survey sent to all last quarter's signups",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 750,
        measureGoalValue: 1000,
        measureStartValue: 0,
        endDate: moment().add(5, 'weeks').toDate(),
        reversed: false,
        measureUnit: "Answers",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 75; }
    },
    {
        id: 28,
        result: "Present an action plan for next quarter's messaging improvements",
        type: GoalKeyResultType.BINARY,
        measureCurrentValue: 1,
        measureGoalValue: 1,
        measureStartValue: 0,
        endDate: moment().add(5, 'days').toDate(),
        reversed: false,
        measureUnit: null,
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.BEFORE,
        getProgress(): number { return 100; }
    },
    {
        id: 29,
        result: "Map our customer journey to understand what influences their buying decision",
        type: GoalKeyResultType.BINARY,
        measureCurrentValue: 1,
        measureGoalValue: 1,
        measureStartValue: 0,
        endDate: moment().add(3, 'month').toDate(),
        reversed: false,
        measureUnit: null,
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.BEFORE,
        getProgress(): number { return 100; }
    },
    {
        id: 30,
        result: "Conduct 12 online client surveys and analyze the results",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 12,
        measureGoalValue: 6,
        measureStartValue: 0,
        endDate: moment().add(5, 'weeks').toDate(),
        reversed: false,
        measureUnit: "Surveys",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 50; }
    },
    {
        id: 31,
        result: "Map and analyze all of our current marketing channels and give at least 10 recommendations to improve them",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 6,
        measureGoalValue: 10,
        measureStartValue: 0,
        endDate: moment().add(1, 'month').toDate(),
        reversed: false,
        measureUnit: "Recommendations",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 60; }
    },
    {
        id: 32,
        result: "Invest in Solar power across all sites in the Netherlands",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 100,
        measureGoalValue: 100,
        measureStartValue: 0,
        endDate: moment().add(5, 'weeks').toDate(),
        reversed: false,
        measureUnit: "%",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 100; }
    },
    {
        id: 33,
        result: "Reduce the amount of always on lighting time our offices have",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 60,
        measureGoalValue: 100,
        measureStartValue: 0,
        endDate: moment().add(5, 'weeks').toDate(),
        reversed: false,
        measureUnit: "%",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 60; }
    },
    {
        id: 34,
        result: "Conduct 3 monthly meetings",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 2,
        measureGoalValue: 3,
        measureStartValue: 0,
        endDate: moment().add(5, 'days').toDate(),
        reversed: false,
        measureUnit: "Meetings",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 66; }
    },
    {
        id: 35,
        result: "Implement Frankli goal setting in all teams",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 10,
        measureGoalValue: 20,
        measureStartValue: 0,
        endDate: moment().add(3, 'month').toDate(),
        reversed: false,
        measureUnit: "Teams",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 50; }
    },
    {
        id: 36,
        result: "Benchmark everything related to product to 10 key competitors",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 8,
        measureGoalValue: 10,
        measureStartValue: 0,
        endDate: moment().add(5, 'weeks').toDate(),
        reversed: false,
        measureUnit: "Competitors",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 80; }
    },
    {
        id: 37,
        result: "Survey 100 customers on their thoughts where we need to be better",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 75,
        measureGoalValue: 100,
        measureStartValue: 0,
        endDate: moment().add(1, 'month').toDate(),
        reversed: false,
        measureUnit: "Customers",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 75; }
    },
    {
        id: 38,
        result: "Increase Monthly Recurring Revenue by €50K",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 40000,
        measureGoalValue: 50000,
        measureStartValue: 0,
        endDate: moment().add(1, 'month').toDate(),
        reversed: false,
        measureUnit: "€",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.BEFORE,
        getProgress(): number { return 80; }
    },
    {
        id: 39,
        result: "10% overall sales in Australian Market by Q1",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 7,
        measureGoalValue: 10,
        measureStartValue: 0,
        endDate: moment().add(1, 'month').toDate(),
        reversed: false,
        measureUnit: "%",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 70; }
    },
    {
        id: 40,
        result: "Exceed Net Promoter Score (NPS) of over 8.0",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 7,
        measureGoalValue: 8,
        measureStartValue: 0,
        endDate: moment().add(5, 'days').toDate(),
        reversed: false,
        measureUnit: "Score",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 88; }
    },
    {
        id: 41,
        result: "Conduct 50 phone interviews with top customers",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 25,
        measureGoalValue: 50,
        measureStartValue: 0,
        endDate: moment().add(3, 'month').toDate(),
        reversed: false,
        measureUnit: "Interviews",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 50; }
    },
    {
        id: 42,
        result: "Conduct 15 phone interviews with recently churned customers",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 10,
        measureGoalValue: 15,
        measureStartValue: 0,
        endDate: moment().add(5, 'weeks').toDate(),
        reversed: false,
        measureUnit: "Interviews",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 66; }
    },
    {
        id: 43,
        result: "Present an action plan of 10 improvements for next quarter",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 20,
        measureGoalValue: 100,
        measureStartValue: 0,
        endDate: moment().add(1, 'month').toDate(),
        reversed: false,
        measureUnit: "Improvements",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 20; }
    },
    {
        id: 44,
        result: "Survey all our customers by Q3",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 40,
        measureGoalValue: 100,
        measureStartValue: 0,
        endDate: moment().add(1, 'month').toDate(),
        reversed: false,
        measureUnit: "%",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 40; }
    },
    {
        id: 45,
        result: "Get 1000 survey responses to annual satisfaction survey",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 900,
        measureGoalValue: 1000,
        measureStartValue: 0,
        endDate: moment().add(1, 'month').toDate(),
        reversed: false,
        measureUnit: "Responses",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 90; }
    },
    {
        id: 46,
        result: "Introduce new IRD led service level agreements",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 20,
        measureGoalValue: 100,
        measureStartValue: 0,
        endDate: moment().add(1, 'month').toDate(),
        reversed: false,
        measureUnit: "%",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 20; }
    },
    {
        id: 47,
        result: "Increase technical accreditation across all consultants",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 40,
        measureGoalValue: 100,
        measureStartValue: 0,
        endDate: moment().add(1, 'month').toDate(),
        reversed: false,
        measureUnit: "%",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 40; }
    },
    {
        id: 48,
        result: "Develop and present on business case",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 90,
        measureGoalValue: 100,
        measureStartValue: 0,
        endDate: moment().add(1, 'month').toDate(),
        reversed: false,
        measureUnit: "%",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 90; }
    },
    {
        id: 49,
        result: "Formulate shared agenda",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 70,
        measureGoalValue: 100,
        measureStartValue: 0,
        endDate: moment().add(1, 'month').toDate(),
        reversed: false,
        measureUnit: "%",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 70; }
    },
    {
        id: 50,
        result: "Invite all direct reports to regular meetings",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 7,
        measureGoalValue: 8,
        measureStartValue: 0,
        endDate: moment().add(5, 'days').toDate(),
        reversed: false,
        measureUnit: "Direct Reports",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 88; }
    },
    {
        id: 51,
        result: "Complete Customer Service Training",
        type: GoalKeyResultType.BINARY,
        measureCurrentValue: 1,
        measureGoalValue: 1,
        measureStartValue: 0,
        endDate: moment().add(1, 'month').toDate(),
        reversed: false,
        measureUnit: null,
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.BEFORE,
        getProgress(): number { return 100; }
    },
    {
        id: 52,
        result: "Achieve Monthly CS Targets",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 20,
        measureGoalValue: 100,
        measureStartValue: 0,
        endDate: moment().add(1, 'month').toDate(),
        reversed: false,
        measureUnit: "%",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 20; }
    },
    {
        id: 53,
        result: "Key 35 claims per hour",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 30,
        measureGoalValue: 35,
        measureStartValue: 0,
        endDate: moment().add(1, 'month').toDate(),
        reversed: false,
        measureUnit: "Claims",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 88; }
    },
    {
        id: 54,
        result: "Complete three course modules by Q4 2019",
        type: GoalKeyResultType.NUMERIC,
        measureCurrentValue: 3,
        measureGoalValue: 4,
        measureStartValue: 0,
        endDate: moment().add(1, 'month').toDate(),
        reversed: false,
        measureUnit: "Modules",
        measureUnitPlacement: GoalKeyResultMeasureUnitPlacement.AFTER,
        getProgress(): number { return 75; }
    },
];