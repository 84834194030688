import { OrganisationalUnit } from 'app/models/organisational-unit.model';
import { Interest } from 'app/models/interest.model';
import { Role, RoleName } from 'app/models/role.model';
import { Company, OfficeLocation } from 'app/models/company.model';
import { Address } from 'app/models/address.model';
import { Position } from 'app/models/position/position.model';
import { PreferredContactMethod } from './preferred-contact-method.model';
import { UserMinimal } from '@app/models/user/user-minimal.model';

// These need to appear before User in the file to load correctly
class UserSort {
  byOfficeLocationName: Function;
  byDepartmentName: Function;
  byPreferredContact: Function;
  byFullName: Function;
  byManagerName: Function;

  constructor() {
    this.byOfficeLocationName = this.sortByOfficeLocationName;
    this.byDepartmentName = this.sortByDepartmentName;
    this.byPreferredContact = this.sortByPreferredContact;
    this.byFullName = this.sortByFullName;
    this.byManagerName = this.sortByManagerName;
  }

  /**
 * Sorts an array of users by office Location, placing no office location last
 * @param users
 */
  private sortByOfficeLocationName(users: Array<User>): Array<User> {
    return users.sort((a, b) => {
      // no office location will be put at the end of list
      let locationA = null;
      if (a.officeLocation !== null) {
        locationA = a.officeLocation.name;
      }
      let locationB = null;
      if (b.officeLocation !== null) {
        locationB = b.officeLocation.name;
      }

      if (locationA === null) {
        if (locationB === null) {
          return 0;
        } else {
          return 1;
        }
      } else {
        if (locationB === null) {
          return -1;
        } else {
          return locationA.localeCompare(locationB);
        }
      }
    });
  }

  /**
   * Sorts an array of users by department name, placing no department last
   * @param users
   */
  private sortByDepartmentName(users: Array<User>) {
    return users.sort((a, b) => {
      // no department will be put at the end of list
      let departmentA = null;
      if (a.organisationalUnit !== null) {
        departmentA = a.organisationalUnit.name;
      }
      let departmentB = null;
      if (b.organisationalUnit !== null) {
        departmentB = b.organisationalUnit.name;
      }

      if (departmentA === null) {
        if (departmentB === null) {
          return 0;
        } else {
          return 1;
        }
      } else {
        if (departmentB === null) {
          return -1;
        } else {
          return departmentA.localeCompare(departmentB);
        }
      }
    });
  }

  /**
   * Sorts an array of users by preffered contact name
   * @param users
   */
  private sortByPreferredContact(users: Array<User>): Array<User> {
    return users.sort((a, b) => {
      const methodA = a.preferredContactMethod;
      const methodB = b.preferredContactMethod;
      if (methodA < methodB) {
        return -1;
      } else if (methodA > methodB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  /**
   * Sorts an array of users by full name name
   * @param users
   */
  private sortByFullName(users: Array<User>): Array<User> {
    return users.sort((a, b) => (a.firstName + ' ' + a.lastName).localeCompare((b.firstName + ' ' + b.lastName)));
  }

  /**
   * Sorts an array of users by managers full name
   * @param users
   */
  private sortByManagerName(users: Array<User>): Array<User> {
    return users.sort((a, b) => (a.managerName === null ? '' : a.managerName).localeCompare((b.managerName === null ? '' : b.managerName)));
  }
}

class UserFilter {

  constructor() { }

  /**
  * Returns a filtered list of office locations, filtered by name
  * @param users
  * @param officeLocation string or null
  */
  public byOfficeLocationName(users: Array<User>, officeLocation: string | null): Array<User> {
    if (officeLocation === 'All') {
      return users;
    } else if (officeLocation !== null) {
      return users.filter(user => {
        if (user.officeLocation === null) {
          return false;
        } else {
          return user.officeLocation.name === officeLocation;
        }
      });
    } else {
      return users.filter(user => user.officeLocation === null);
    }
  }

  /**
   * Returns a filtered list of departments, filtered by name
   * @param users
   * @param department string or null
   */
  public byDepartmentName(users: Array<User>, department: string | null): Array<User> {
    if (department === 'All') {
      return users;
    } else if (department !== null) {
      return users.filter(user => {
        if (user.organisationalUnit === null) {
          return false;
        } else {
          return user.organisationalUnit.name === department;
        }
      });
    } else {
      return users.filter(user => user.organisationalUnit === null);
    }
  }

  /**
   * Returns a filtered list of users who's first and last name contain the provided string
   * @param user
   * @param partialName
   */
  public byPartialName(users: Array<User>, partialName: string | null): Array<User> {
    if (partialName === null) {
      return users;
    }

    // sanitise name for ux
    partialName = partialName.trim().toLowerCase();

    if (partialName === '') {
      return users;
    } else {
      return users.filter(user => {
        const fullName = (user.firstName.toLocaleLowerCase() + ' ' + user.lastName.toLocaleLowerCase()).toLowerCase();
        return (fullName.includes(partialName!));
      });
    }
  }

  /**
   * Returns a filtered list of users, filtered on the user's status
   */
  public byStatus(users: Array<User>, status: 'Active' | 'Invited' | 'Pending' | 'Revoked' | 'Archived' | null) {
    if (status === null) {
      return users.filter(u => u);
    } else if (status === 'Active') {
      return users.filter(u => {
        return (
          u.userState !== 'INVITED'
          && u.userState !== 'PENDING'
          && !u.roles.some(r => r.name === RoleName.ARCHIVED)
          && !u.roles.some(r => r.name === RoleName.ACCESS_REVOKED)
        )
      })
    } else if (status === 'Invited') {
      return users.filter(u => {
        return (
          u.userState === 'INVITED'
          && !u.roles.some(r => r.name === RoleName.ARCHIVED)
          && !u.roles.some(r => r.name === RoleName.ACCESS_REVOKED)
        )
      });
    } else if (status === 'Pending') {
      return users.filter(u => {
        return (
          u.userState === 'PENDING'
          && !u.roles.some(r => r.name === RoleName.ARCHIVED)
          && !u.roles.some(r => r.name === RoleName.ACCESS_REVOKED)
        );
      });
    } else if (status === 'Revoked') {
      return users.filter(u => {
        return u.roles.some(r => r.name === RoleName.ACCESS_REVOKED)
      });
    } else if (status === 'Archived') {
      return users.filter(u => {
        return u.roles.some(r => r.name === RoleName.ARCHIVED)
      });
    } else {
      return users.filter(u => u);
    }
  }

  public byPosition(users: Array<User>, position: Position | null) {
    if (position === null) {
      return users.filter(u => u);
    }
    return users.filter(u => u.position!.id === position.id);
  }


}

// TODO: THESE NEED TO BE MOVED TO THEIR RESPECTIVE CLASSES - UserFind should only return Users
class UserFind {
  byOfficeLocationByName: Function;
  byDepartmentByName: Function;

  constructor() {
    this.byOfficeLocationByName = this.findOfficeLocationByName;
    this.byDepartmentByName = this.findDepartmentByName;
  }

  /**
   * Returns officeLocation if found, else returns null
   * @param officeLocations
   * @param officeLocation string or null
   */
  private findOfficeLocationByName(officeLocations: Array<OfficeLocation>, officeLocation: string | null): OfficeLocation | null {
    if (officeLocation === null) {
      return null;
    } else {
      for (let i = 0; i < officeLocations.length; i++) {
        if (officeLocations[i].name === officeLocation) {
          return officeLocations[i];
        }
      }
      return null;
    }
  }

  /**
   * Returns department if found, else returns null
   * @param departments
   * @param department string or null
   */
  private findDepartmentByName(departments: Array<OrganisationalUnit>, department: string | null): OrganisationalUnit | null {
    if (department === null) {
      return null;
    } else {
      for (let i = 0; i < departments.length; i++) {
        if (departments[i].name === department) {
          return departments[i];
        }
      }
      return null;
    }
  }

}

export class User implements UserMinimal {
  static sort: UserSort = new UserSort();
  static filter: UserFilter = new UserFilter();
  static find: UserFind = new UserFind();

  id: number;
  version: number;
  firstTimeLogin: boolean;
  passwordReset: boolean;
  userState: string;
  email: string;
  firstName: string;
  lastName: string;
  gender: UserGender;
  description: string;

  homeAddress: Address | null;
  dateOfBirth: Date | null;
  birthdayReminder: boolean;

  // TODO: make nullable
  startDate: Date;

  imageUrl: string;

  personalInterests: Interest[];
  professionalInterests: Interest[];

  roles: Role[];

  position: Position | null;
  organisationalUnit: OrganisationalUnit | null;
  officeLocation: OfficeLocation | null;

  interestsSocialise: Interest[];
  interestsCoach: Interest[];
  interestsMentor: Interest[];

  // TODO: remove manager name
  managerName: string | null;

  managerId: number;

  preferredContactMethod: PreferredContactMethod;
  twitterHandle: string;
  linkedIn: string;
  teamsEmail: string;
  phoneNumber: string;

  constructor(user: UserServerSide) {
    this.id = user.id;
    this.version = user.version;
    this.firstTimeLogin = user.firstTimeLogin;
    this.passwordReset = user.passwordReset;
    this.userState = user.userState;
    this.email = user.email;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.gender = user.gender;
    this.description = user.description;
    this.homeAddress = user.homeAddress;
    this.dateOfBirth = user.dateOfBirth;
    this.startDate = user.startDate;
    this.imageUrl = user.imageUrl;
    this.organisationalUnit = user.organisationalUnit;
    this.position = user.position;

    this.birthdayReminder = user.birthdayReminder;

    if (user.interests) {
      this.personalInterests = user.interests.filter(i => i.interestType === 'Personal');
      this.professionalInterests = user.interests.filter(i => i.interestType === 'Professional');
    } else {
      this.personalInterests = [];
      this.professionalInterests = [];
    }

    this.roles = user.roles;
    this.officeLocation = user.officeLocation;

    this.interestsSocialise = user.interestsSocialise;
    this.interestsCoach = user.interestsCoach;
    this.interestsMentor = user.interestsMentor;

    this.managerId = user.managerId!;
    this.managerName = user.managerName;

    this.preferredContactMethod = user.preferredContactMethod;
    this.twitterHandle = user.twitterHandle;
    this.linkedIn = user.linkedIn;
    this.teamsEmail = user.teamsEmail;
    this.phoneNumber = user.phoneNumber;
  }
}

export class UserServerSide extends User {
  sso?: boolean;
  interests: Interest[];

  constructor(user: User) {
    super(user as UserServerSide);
    // this.soo = user.sso;
    if (user.personalInterests || user.professionalInterests) {
      this.interests = user.personalInterests.concat(user.professionalInterests);
    } else {
      this.interests = [];
    }
  }

}

export class UserContent {
  content: Array<User>;

  constructor(userContentServerSide: UserContentServerSide) {
    this.content = new Array<User>();
    userContentServerSide.content.forEach(u =>
      this.content.push(new User(u))
    )
  }
}

export interface UserContentServerSide {
  content: Array<UserServerSide>;
}

export interface UserInit {
  user: User;
  company: Company;
  access_token: string;
}

export enum UserState {
  INVITED = 'INVITED',
  FIRST_TIME = 'FIRST_TIME',
  FULL = 'FULL',
  PENDING = 'PENDING'
}

export enum UserGender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
  UNSPECIFIED = 'UNSPECIFIED'
}