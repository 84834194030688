<div class="text-center lh-fullpage">
  <div class="login-container">
    <div class=" row">
      <div class='col-md-6 col-xs-12 text-center'>
        <div class='header'>
          <img src="/assets/img/Frankli_logo_primary.png" />
        </div>
        <form [formGroup]="loginForm" class="text-center center-block m-top-30">
          <div [class.frankli-form-group-invalid]='email.invalid && state.submitted'
            [class.frankli-form-group-filled]='email.value.length > 0' class="frankli-form-group">
            <label for="email">Work Email</label>
            <input type="email" id="email" name="email" class="form-control" placeholder="name@company.com"
              autocomplete="email" formControlName="email">
            <div class="frankli-form-group-alert">
              <div *ngIf="email.invalid && state.submitted && email.hasError('required')">
                Email address is required
              </div>
              <div *ngIf="email.invalid && state.submitted && email.hasError('pattern') && !email.hasError('required')">
                Must be a valid email address
              </div>
            </div>
          </div>

          <div [class.frankli-form-group-invalid]="password.invalid && state.submitted"
            [class.frankli-form-group-filled]='password.value.length > 0' class="frankli-form-group">
            <label for="password">Password</label>
            <input type="password" id="password" name="password" class="form-control" placeholder="password"
              autocomplete="password" formControlName="password">
            <div class="frankli-form-group-alert">
              <div *ngIf="password.invalid && state.submitted && password.hasError('required')">
                Password is required
              </div>
            </div>
          </div>

          <div class="submit text-center">
            <div class="frankli-form-group-alert m-bottom-10">
              <div class="error-message">
                {{state.errorMessage}}
              </div>
            </div>

            <button [disabled]="state.loading === true" class="btn-frankli-round btn-frankli-green"
              angulartics2On="click" angularticsAction="ClickLoginButton" angularticsCategory="Support" type="submit"
              (click)='login()'>
              <span *ngIf="state.loading == false">Sign in</span>
              <span *ngIf="state.loading == true" class="fal fa-spin fa-spinner"></span>
            </button>
            <a routerLink="/reset">forgot password?</a>
          </div>
        </form>

        <!-- SSO buttons -->
        <div class="m-top-10">
          <h5>Or sign in with one of the following</h5>
          <button class="btn-sso microsoft" (click)="ssoLogin(eSSOProvider.MICROSOFT)">
            <span class="fa fa-windows"></span>
            <span class="m-left-5">Microsoft</span>
          </button>

          <button class="btn-sso google" (click)="ssoLogin(eSSOProvider.GOOGLE)">
            <span class="fa fa-google"></span>
            <span class="m-left-5">Google</span>
          </button>
        </div>
      </div>

      <div class="col-md-6 hidden-xs hidden-sm text-center carousel-panels" *ngFor="let c of carousels; index as i"
        [class.hidden]="selectedCarousel != i">
        <div class="carousel-img">
          <img src="{{c.src}}" alt="connectivity" class="img-fluid" />
        </div>
        <h2>{{c.msg}}</h2>

        <div class="carousel-buttons text-center">
          <div *ngFor="let b of carousels; index as i;" [class.active]="(selectedCarousel == i)"
            (click)="changeCarousel(i)"></div>
        </div>
      </div>
    </div>
  </div>
</div>