<div>
  <ng-container *ngIf="state.isSuccessful()">
    <ng-container *ngIf="goals.length > 0">
      <div class="content">
        <div class="row">
          <div class="col-md-5">
            <div class="frankli-form-group frankli-form-group-filled frankli-form-group-placeholder-shown">
              <input id="search" type="search" placeholder="Search by goal/owner name" class="form-control round-search"
                [(ngModel)]="searchValue" (ngModelChange)="search()">
              <i class="fal fa-search form-control-feedback"></i>
            </div>
          </div>

          <div class="col-md-1 col-md-offset-6">
            <button class="btn btn-sm btn-frankli-purple-o b-radius-20 pull-right m-top-5" (click)="reset()">Clear</button>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="row">
          <div class="col-md-10">
            <div class="row">
              <div class="col-md-2">
                <div class="frankli-form-group frankli-form-group-filled">
                  <label for="status">Status</label>
                  <select id="status" class="form-control" [(ngModel)]="selectedStatus" (ngModelChange)="update()">
                    <option [ngValue]="'All'">All</option>
                    <option *ngFor="let status of statuses" [ngValue]="status">{{status | underscoreToSpace}}
                    </option>
                    <option [ngValue]="'COMPLETE'">COMPLETE</option>
                    <option [ngValue]="'ARCHIVED'">ARCHIVED</option>
                  </select>
                </div>
              </div>

              <div class="col-md-2">
                <div class="frankli-form-group frankli-form-group-filled">
                  <label for="department">{{companyWording.department | titlecase}}</label>
                  <select id="department" class="form-control" [(ngModel)]="selectedDepartment" (ngModelChange)="update()">
                    <option [ngValue]="'All'">All</option>
                    <option [ngValue]="null">No {{companyWording.department | titlecase}}</option>
                    <option *ngFor="let department of departments" [ngValue]="department.value">{{department.name}}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-2">
                <div class="frankli-form-group frankli-form-group-filled">
                  <label for="office-location">Office Location</label>
                  <select id="office-location" class="form-control" [(ngModel)]="selectedOfficeLocation"
                    (ngModelChange)="update()">
                    <option [ngValue]="'All'">All</option>
                    <option [ngValue]="null">No Office Location</option>
                    <option *ngFor="let officeLocation of officeLocations" [ngValue]="officeLocation.value">
                      {{officeLocation.name}}</option>
                  </select>
                </div>
              </div>

              <div class="col-md-2">
                <div class="frankli-form-group frankli-form-group-filled">
                  <label for="office-location">Tag</label>
                  <select id="office-location" class="form-control" [(ngModel)]="selectedTag" (ngModelChange)="update()">
                    <option [ngValue]="'All'">All</option>
                    <option *ngFor="let tag of tags" [ngValue]="tag.text">
                      {{tag.text}}</option>
                  </select>
                </div>
              </div>

              <div class="col-md-2">
                <div class="frankli-form-group frankli-form-group-filled">
                  <label for="office-location">Priority</label>
                  <select id="office-location" class="form-control" [(ngModel)]="selectedPriority" (ngModelChange)="update()">
                    <option [ngValue]="'All'">All</option>
                    <option *ngFor="let priority of priorities" [ngValue]="priority">
                      {{priority | underscoreToSpace}}</option>
                  </select>
                </div>
              </div>

              <div class="col-md-2">
                <div class="frankli-form-group frankli-form-group-filled">
                  <label for="visibility">Visibility</label>
                  <select id="visibility" class="form-control" [(ngModel)]="selectedVisibility" (ngModelChange)="update()">
                    <option [ngValue]="'All'">All</option>
                    <option *ngFor="let visibility of visibilities" [ngValue]="visibility">
                      {{visibility}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="frankli-form-group frankli-form-group-filled">
              <div class="text-right">
                <label></label>
                <app-column-toggle [titles]="columnTitles" [startDisabled]="['priority']" #columnToggle></app-column-toggle>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="scroll-x">
        <table width="100%">
          <tr class="frankli-table frankli-table-small frankli-table-header noselect">
            <th *ngIf="!columnToggle.checkAllDisabled()"></th>
            <th class="sorting" *ngIf="columnToggle.getColumnEnabled('owners')" (click)="doSort(eSortType.OWNERS)"
              [class.sorting-ascending]="sort.type === eSortType.OWNERS && sort.direction === eSortDirection.ASCENDING"
              [class.sorting-descending]="sort.type === eSortType.OWNERS && sort.direction === eSortDirection.DESCENDING">Owners</th>
            <th class="sorting text-center" *ngIf="columnToggle.getColumnEnabled('type')" (click)="doSort(eSortType.TYPE)"
              [class.sorting-ascending]="sort.type === eSortType.TYPE && sort.direction === eSortDirection.ASCENDING"
              [class.sorting-descending]="sort.type === eSortType.TYPE && sort.direction === eSortDirection.DESCENDING">Type</th>
            <th *ngIf="columnToggle.getColumnEnabled('title')" (click)="doSort(eSortType.TITLE)"
              [class.sorting-ascending]="sort.type === eSortType.TITLE && sort.direction === eSortDirection.ASCENDING"
              [class.sorting-descending]="sort.type === eSortType.TITLE && sort.direction === eSortDirection.DESCENDING">Title</th>
            <th class="sorting" *ngIf="columnToggle.getColumnEnabled('priority') && globals.hasFeature(eFeature.GOAL_PRIORITY)" (click)="doSort(eSortType.PRIORITY)"
              [class.sorting-ascending]="sort.type === eSortType.PRIORITY && sort.direction === eSortDirection.ASCENDING"
              [class.sorting-descending]="sort.type === eSortType.PRIORITY && sort.direction === eSortDirection.DESCENDING">Priority</th>
            <th class="sorting text-center" *ngIf="columnToggle.getColumnEnabled('key results')" (click)="doSort(eSortType.KEY_RESULTS)"
              [class.sorting-ascending]="sort.type === eSortType.KEY_RESULTS && sort.direction === eSortDirection.ASCENDING"
              [class.sorting-descending]="sort.type === eSortType.KEY_RESULTS && sort.direction === eSortDirection.DESCENDING">Key Results</th>
            <th class="sorting text-center" *ngIf="columnToggle.getColumnEnabled('progress')" (click)="doSort(eSortType.PROGRESS)"
              [class.sorting-ascending]="sort.type === eSortType.PROGRESS && sort.direction === eSortDirection.ASCENDING"
              [class.sorting-descending]="sort.type === eSortType.PROGRESS && sort.direction === eSortDirection.DESCENDING">Progress</th>
            <th class="sorting text-center" *ngIf="columnToggle.getColumnEnabled('date')" (click)="doSort(eSortType.DATE)"
              [class.sorting-ascending]="sort.type === eSortType.DATE && sort.direction === eSortDirection.ASCENDING"
              [class.sorting-descending]="sort.type === eSortType.DATE && sort.direction === eSortDirection.DESCENDING">End Date</th>
          </tr>
          <tr class="frankli-table frankli-table-hover frankli-table-single-line" *ngFor="let goal of goalsDisplay; index as i" [routerLink]="'individual/' + goal.id">
            <!-- Status -->
            <td class="p-relative" *ngIf="!columnToggle.checkAllDisabled()">
              <div class="goal-status-highlight" [title]="eGoalHelperFunctions.getStatusTitle(goal)" [ngClass]="eGoalHelperFunctions.getStatusHighlight(goal)"></div>
            </td>

            <!-- Owner -->
            <td class="text-nowrap ellipsis-text" *ngIf="columnToggle.getColumnEnabled('owners')">
              <ng-container *ngIf="goal.owners.length > 1; else oneOwner;">
                <div class="owner-col-inner" #ownerCol>
                  <span class="img-owner m-left-grouped-owners v-align-middle" *ngFor="let owner of goal.owners" [title]="owner.firstName + ' ' + owner.lastName">
                    <app-profile-photo [src]="owner.imageUrl" [width]="'40px'"></app-profile-photo>
                  </span>
                </div>
              </ng-container>
              <ng-template #oneOwner>
                <ng-container *ngIf="goal.owners[0]; let owner;">
                  <span class="img-owner v-align-middle" [title]="owner.firstName + ' ' + owner.lastName">
                    <app-profile-photo [src]="owner.imageUrl" [width]="'40px'"></app-profile-photo>
                  </span>
                  <span class="v-align-middle">{{owner.firstName}} {{owner.lastName}}</span>
                </ng-container>
              </ng-template>
            </td>

            <!-- Goal type -->
            <td class="text-center" *ngIf="columnToggle.getColumnEnabled('type')">
              <ng-container [ngSwitch]="goal.type">
                <span title="Personal Operational Goal" class="fal fa-user goal-type-icon" *ngSwitchCase="eGoalType.PERSONAL_OPERATIONAL"></span>
                <span title="Personal Developmental Goal" class="fal fa-graduation-cap goal-type-icon" *ngSwitchCase="eGoalType.PERSONAL_DEVELOPMENTAL"></span>
                <span title="{{companyWording.department | titlecase}} Goal" class="fal fa-users goal-type-icon" *ngSwitchCase="eGoalType.DEPARTMENT"></span>
                <span title="Company Goal" class="fal fa-building goal-type-icon" *ngSwitchCase="eGoalType.COMPANY"></span>
                <span title="Site Goal" class="fal fa-business-time goal-type-icon" *ngSwitchCase="eGoalType.OFFICE_LOCATION"></span>
                <span title="{{companyWording.team | titlecase}} Goal" class="fal fa-user-friends goal-type-icon" *ngSwitchCase="eGoalType.TEAM"></span>
              </ng-container>
            </td>

            <!-- Title -->
            <td [title]='goal.title' *ngIf="columnToggle.getColumnEnabled('title')">
              <span class="font-bold min-width-title" [title]="goal.title | ellipsis: 50">{{goal.title | ellipsis: 50}}</span>
            </td>

            <!-- Priority -->
            <td *ngIf="columnToggle.getColumnEnabled('priority') && globals.hasFeature(eFeature.GOAL_PRIORITY)">
              <span [class]="'m-left-10 icon-priority priority-' + goal.priority">{{goal.priority}}</span>
            </td>

            <!-- Key Results -->
            <td class="text-center" *ngIf="columnToggle.getColumnEnabled('key results')">
              <span>{{goal.keyResults.length}} key results</span>
            </td>

            <!-- Progress bar -->
            <td *ngIf="columnToggle.getColumnEnabled('progress')">
              <tr>
                <td>
                  <div class="min-width-progress">
                    <span>
                      <div class="progress m-bottom-0">
                        <div class="progress-bar progress-bar-info" [style.width]="goal.completionPercentage+'%'">
                          <span class="sr-only"></span>
                        </div>
                      </div>
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    <div class="m-left-10 m-right-5">{{goal.completionPercentage}}%</div>
                  </div>
                </td>
              </tr>
            </td>

            <!-- Due date -->
            <td class="text-center" *ngIf="columnToggle.getColumnEnabled('date')">
              <span class="m-right-25">{{goal.endDate | date:'d MMMM y'}}</span>
            </td>
          </tr>

          <tr *ngIf="columnToggle.checkAllDisabled()">
            <td class="text-center">
              <div class="m-top-20 m-bottom-20">No columns selected to display, you can choose which columns to show by clicking the <span class="fal fa-columns text-info"></span> icon above</div>
            </td>
          </tr>
        </table>
      </div>

      <div class="text-center m-bottom-15" *ngIf="goalsDisplay.length === 0">
        <img class="icon-padding new-icon-height" src="/assets/img/NoSearchFound.svg" />
        <br>
        <p> There are no goals available for this search</p>
      </div>

      <app-pagination-new-component *ngIf="!columnToggle.checkAllDisabled()" class="m-top-10" [arrayData]="goalsFiltered" [arrayDisplay]="goalsDisplay" [currentPage]="page" (pageChanged)="paginationChanged($event)" #pagination></app-pagination-new-component>
    </ng-container>

    <!-- NO GOALS -->
    <div class="content text-center" *ngIf="goals.length === 0">
      <p>
        <img class="icon-padding new-icon-height" src="/assets/img/NoGoals.svg" />
        <br>
        The people you manage have no goals set</p>
      <span class="btn-frankli-round btn-frankli-green" [routerLink]="'/dashboard'">
        Back to your dashboard?
      </span>
    </div>
  </ng-container>

  <!-- LOADING -->
  <ng-container *ngIf="state.isLoading()">
    <div class="content text-center">
      <span class="fal fa-spin fa-spinner fa-3x fa-fw"></span>
    </div>
  </ng-container>

  <!-- ERROR -->
  <ng-container *ngIf="state.isFailure()">
    <div class="content text-center">
      <p>
        <img class="icon-padding new-icon-height" src="/assets/img/Error.svg" />
        <br>
        An error occurred while getting your goals</p>
      <span class="btn-frankli-round btn-frankli-green" [routerLink]="'/dashboard'">
        Back to your dashboard?
      </span>
    </div>
  </ng-container>
</div>
