<app-modal #modal [background]="'light'" (modalHidden)="onModalClosed()">
  <div class="modal-body-content">
    <ng-container *ngIf="!state.loading; else stateLoading">
      <ng-container *ngIf="!state.error; else stateError">
        <div class="content p-botttom-25">
          <div class="header text-center m-bottom-15">
            <h4 class="header-text-title m-bottom-0 m-top-0">Create Interest</h4>
          </div>

          <!-- New or existing category -->
          <div class="text-center m-top-15 m-bottom-15" *ngIf="configSelected === ePageConfig.NONE; else otherConfig">
            <div class="text-center">
              <p>First lets define a Category or select an existing one for your Interest</p>
            </div>
            <div class="d-inline-block">
              <button class="btn-frankli-round btn-frankli-green m-right-5" (click)="selectConfig(ePageConfig.NEW)">New category</button>
              <button class="btn-frankli-round btn-frankli-green m-left-5" (click)="selectConfig(ePageConfig.EXISTING)">Use existing</button>
            </div>
          </div>

          <ng-template #otherConfig>
            <div [ngSwitch]="configSelected">
              <!-- New category -->
              <ng-container *ngSwitchCase="ePageConfig.NEW">
                <div class="text-center" *ngIf="categories.length === 0">
                  <p>First lets define a Category</p>
                </div>

                <!-- Category form -->
                <div class="frankli-form-group"
                  [class.frankli-form-group-invalid]="categoryName.invalid && state.category.submitted"
                  [class.frankli-form-group-filled]="categoryName.value && categoryName.value.length > 0">
                  <label for="category-name">New Category</label>
                  <input id="category-name" type="text" class="form-control text-capitalize"
                    placeholder="Category Name" [formControl]="categoryName">
                  <div class="frankli-form-group-alert">
                    <ng-container *ngIf="state.category.submitted">
                      <div *ngIf="categoryName.hasError('required')">Required</div>
                      <div *ngIf="categoryName.hasError('maxlength')">Must be less than 50 characters
                      </div>
                    </ng-container>
                  </div>
                </div>

                <div class="frankli-form-group-alert">
                  <span *ngIf="state.category.submitted && state.category.alreadyExists">Category names must be unique</span>
                </div>

                <div class="m-top-15 m-bottom-5 text-center">
                  <button class="btn-frankli-round btn-frankli-cancel m-right-5" (click)="cancelCreation()" *ngIf="categories.length > 0">Cancel</button>
                  <button class="btn-frankli-round btn-frankli-green m-left-5" (click)="submitCategoryForm()">Create</button>
                </div>
              </ng-container>

              <!-- New interest using existing category -->
              <ng-container *ngSwitchCase="ePageConfig.EXISTING">
                <div *ngIf="categories.length > 0" [formGroup]="createForm">
                  <div class="frankli-form-group frankli-form-group-filled"
                    [class.frankli-form-group-invalid]="createInterestCategory.invalid && state.submitted">
                    <label for="interest-category">Category</label>
                    <select class="form-control" name="interest-category" id="interest-category"
                      [formControl]="createInterestCategory">
                      <option [ngValue]="null">Select a category...</option>
                      <option *ngFor="let category of categories" [ngValue]="category">{{category.name}}</option>
                    </select>
                    <div class="frankli-form-group-alert">
                      <ng-container *ngIf="state.submitted">
                        <div *ngIf="createInterestCategory.hasError('required')">Required</div>
                      </ng-container>
                    </div>
                  </div>
    
                  <div class="frankli-form-group" [class.frankli-form-group-invalid]="createInterestName.invalid && state.submitted" [class.frankli-form-group-filled]="createInterestName.value && createInterestName.value.length > 0">
                    <label for="name">Interest Name</label>
                    <input id="name" type="text" class="form-control text-capitalize" placeholder="Interest Name"
                      [formControl]="createInterestName">
                    <div class="frankli-form-group-alert">
                      <ng-container *ngIf="state.submitted">
                        <div *ngIf="createInterestName.hasError('required')">Required</div>
                        <div *ngIf="createInterestName.hasError('maxlength')">Must be less than 50 characters</div>
                      </ng-container>
                    </div>
                  </div>
    
                  <div class="m-top-15 m-bottom-5 text-center">
                    <p *ngIf="isNotAnAdmin" class="text-center">Once submitted, an admin will need to approve this interest before it can be used</p>
                    <button type="button" class="btn-frankli-round btn-frankli-cancel m-right-5" (click)="cancelCreation()">Cancel</button>
                    <button type="button" *ngIf="!state.creating" class="btn-frankli-round btn-frankli-green m-left-5" (click)="submitCreateForm()">Create Interest</button>
                    <button type="button" *ngIf="state.creating" class="btn-frankli-round btn-frankli-green m-left-5"><span class="fal fa-spin fa-spinner"></span></button>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </ng-container>

    <!-- #region - ALTERNATE PAGE STATES -->
    <ng-template #stateError>
      <div class="card">
        <div class="p-top-25 p-bottom-30 text-center">
          <br>
          <img class="icon-padding new-icon-height" src="/assets/img/Error.svg"  alt="Error"/>
          <br>
          <h4>An error occurred getting required info</h4>
          <button class="btn-frankli-round btn-frankli-green" [routerLink]="'/admin/dashboard'">
            Back to profile?
          </button>
        </div>
      </div>
    </ng-template>

    <ng-template #stateLoading>
      <div class="p-top-25 text-center">
        <span class="fal fa-spin fa-spinner fa-3x fa-fw"></span>
      </div>
    </ng-template>
    <!-- #endregion -->
  </div>
</app-modal>
