import { Component, OnInit } from '@angular/core';
import { WebsocketService } from 'app/shared/api/websocket.service';

@Component({
  selector: 'app-overlay-login-component',
  templateUrl: './overlay-login.component.html',
  styleUrls: ['./overlay-login.component.css']
})
export class OverlayLoginComponent implements OnInit {

  constructor(
    private WebsocketService: WebsocketService
  ) { }

  ngOnInit() {
    this.WebsocketService.deactivate();
  }

  navigate() {
    window.location.href = '/login';
  }

}
