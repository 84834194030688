<!-- Update  -->
<div class="row">
  <div class="col-md-12">
    <div class="grayed-container" *ngIf="goal.complete === false && goal.archived === false && showControls === true">
      <form [formGroup]="formUpdate" autocomplete="off" (ngSubmit)="updateGoal()">
        <div [class.grayed-out]="editing.all">
          <h4 class="m-top-5 m-bottom-5">Update Progress</h4>

          <!-- Status -->
          <div class="row m-bottom-25">
            <div class="col-md-6">
              <h5>Current Status?</h5>

              <div class="row m-top-10 m-bottom-15 m-left-0 m-right-0">
                <div class="col-md-4 goal-status" [class.goal-status-selected]="status.value === eGoalStatus.OFF_TRACK"
                  (click)="setGoalStatus(eGoalStatus.OFF_TRACK)">
                  Off Track
                </div>
                <div class="col-md-4 goal-status"
                  [class.goal-status-selected]="status.value === eGoalStatus.PROGRESSING"
                  (click)="setGoalStatus(eGoalStatus.PROGRESSING)">
                  Progressing
                </div>
                <div class="col-md-4 goal-status" [class.goal-status-selected]="status.value === eGoalStatus.ON_TRACK"
                  (click)="setGoalStatus(eGoalStatus.ON_TRACK)">
                  On Track
                </div>
              </div>
            </div>
          </div>

          <!-- Key Results -->
          <div class="row">
            <div class="col-md-12" formArrayName="keyResults">
              <h5>Key Results</h5>
              <div class="row kr-headings">
                <div class="col-md-3 col-xs-7">Result</div>
                <div class="col-md-6 col-xs-5">
                  <div class="row">
                    <div class="col-xs-3">Start Value</div>
                    <div class="col-xs-3">Current Value</div>
                    <div class="col-xs-offset-1 col-xs-3">Goal Value</div>
                  </div>
                </div>
              </div>
              <div class="row kr-row" *ngFor="let keyResult of keyResults.controls; index as i">
                <!-- Index -->
                <!-- Result title -->
                <div class="col-md-3 col-xs-11 kr-result">
                  <span class="kr-index">{{i+1}}.</span>
                  <span class="kr-title">{{keyResult.controls.result.value}}</span>
                </div>

                <div class="col-md-5 col-xs-7">
                  <div class="row">
                    <!-- Incomplete / Complete -->
                    <ng-container *ngIf="keyResult.controls.type.value === eGoalKeyResultType.BINARY">
                      <div class="col-xs-3 col-xs-offset-4 frankli-form-group">
                        <select class="form-control" [formControl]="keyResult.controls.measureCurrentValue">
                          <option [ngValue]="0">Incomplete</option>
                          <option [ngValue]="1">Complete</option>
                        </select>
                      </div>
                    </ng-container>

                    <!-- Other Current / Goal Values -->
                    <ng-container *ngIf="keyResult.controls.type.value !== eGoalKeyResultType.BINARY">
                      <div class="col-xs-3 frankli-form-group">
                        <span *ngIf="keyResult.controls.measureUnitPlacement.value === eGoalKeyResultMeasureUnitPlacement.BEFORE">{{keyResult.controls.measureUnit.value}}</span>
                        <span>{{ keyResult.controls.measureStartValue.value }}</span>
                        <span *ngIf="keyResult.controls.measureUnitPlacement.value === eGoalKeyResultMeasureUnitPlacement.AFTER">{{keyResult.controls.measureUnit.value}}</span>
                      </div>
                      <div class="col-xs-1 slash-fix text-center">/</div>
                      <div class="col-xs-3 frankli-form-group"
                        [class.frankli-form-group-invalid]="keyResult.controls.measureCurrentValue.invalid && submitted">
                        <input id="current-value" class="form-control"
                          [formControl]="keyResult.controls.measureCurrentValue" placeholder="Current Value" />
                      </div>
                      <div class="col-xs-1 slash-fix text-center">/</div>
                      <div class="col-xs-3 frankli-form-group">
                        <span *ngIf="keyResult.controls.measureUnitPlacement.value === eGoalKeyResultMeasureUnitPlacement.BEFORE">{{keyResult.controls.measureUnit.value}}</span>
                        <span>{{ keyResult.controls.measureGoalValue.value }}</span>
                        <span *ngIf="keyResult.controls.measureUnitPlacement.value === eGoalKeyResultMeasureUnitPlacement.AFTER">{{keyResult.controls.measureUnit.value}}</span>
                      </div>
                    </ng-container>
                  </div>
                </div>

                <!-- Progress bar -->
                <div class="col-md-3 col-xs-5 text-nowrap"
                  *ngIf="keyResult.controls.type.value !== eGoalKeyResultType.BINARY">
                  <div class="progress d-inline-block kr-progress-bar">
                    <div class="progress-bar progress-bar-info"
                      [style.width.%]="getProgress(keyResult.controls.reversed.value, keyResult.controls.measureGoalValue.value, keyResult.controls.measureCurrentValue.value,  keyResult.controls.measureStartValue.value)">
                      <span class="sr-only"></span>
                    </div>
                  </div>
                  <div class="d-inline-block m-left-10">
                    <span>{{getProgress(keyResult.controls.reversed.value, keyResult.controls.measureGoalValue.value,
                      keyResult.controls.measureCurrentValue.value,
                      keyResult.controls.measureStartValue.value)| number: '1.0-2'}}%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Progress -->
          <div class="row m-bottom-25">
            <div class="col-md-6">
              <div class="frankli-form-group frankli-form-group-filled"
                [class.frankli-form-group-invalid]="progressUpdate.invalid">
                <h5 style="color: #333">Progress Made?</h5>
                <app-editor-response [columnIndent]="false" [height]="200" [toolbar]="editorToolbar"
                  [showErrorBorder]="progressUpdate.invalid" [enforceCharacterLimit]="false"
                  [formControl]="progressUpdate" [maxCharacters]="editorMaxLengthSoft"
                  [placeholder]="'Share progress you have made towards your goal here'"></app-editor-response>
              </div>
              <div class="frankli-form-group-alert">
                <div *ngIf="progressUpdate.hasError('maxlength')">Too much formatting</div>
                <div *ngIf="progressUpdate.hasError('softmax')">Must be less than {{editorMaxLengthSoft}} characters
                </div>
              </div>
            </div>
          </div>

          <!-- Buttons -->
          <div class="row m-bottom-20">
            <div class="col-md-12 text-right">
              <button type="submit" [disabled]="progressUpdate.invalid" [class.update-disabled]="progressUpdate.invalid"
                class="btn-frankli-round btn-frankli-green m-right-10" (click)="updateGoal()">
                <span *ngIf="updateLoading" class="fal fa-spin fa-spinner"></span>
                <span *ngIf="!updateLoading">Update Progress</span>
              </button>
              <button type="button" class="btn-frankli-round btn-complete" (click)="completeGoal()"
                *ngIf="!goal.complete">
                <span>Complete <span class="fal fa-check m-left-10"></span></span>
              </button>
            </div>
          </div>

        </div>
      </form>

    </div>
  </div>
</div>