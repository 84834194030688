import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { AuthAPIService } from '../auth/auth.api.service';

@Injectable({
  providedIn: 'root'
})
export class VersionCheckService {

  initiated = false;

  constructor(private http: HttpClient, private authService: AuthAPIService) {
  }

  public initVersionCheck(frequency = 1000 * 60) {
    const currentVersion = environment.version;
    if(currentVersion === '1.0.2') {
      if(!this.initiated){
        console.log('initialising Version Check');
        this.initiated = true
        this.checkVersion();
        setInterval(() => {
          this.checkVersion();
        }, frequency);
      }
    }
  }

  private checkVersion() {
    
    // timestamp these requests to invalidate caches
    const timestamp = + new Date();
    const url = '/assets/version.json?t=' + timestamp;
    console.log('checkVersion: ', url)

    this.http.get(url)
      .subscribe(
        (response: any) => {
          const currentVersion = environment.version;
          const fileVersion = response.version;
          console.log('health endpoint called', currentVersion, fileVersion);
          if(fileVersion !== '1.0.2')
          {
            console.log('fileVersion at ', fileVersion)
            this.authService.logout();
          }
        },
        (err) => {
          console.error(err, 'Could not get version');
        }
      );
  }
}
