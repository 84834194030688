<ng-container *ngIf="!state.error; else stateError">
  <ng-container *ngIf="!state.loading; else stateLoading">
    <div class="card of-hidden">
      <!-- Actions -->
      <ng-container *ngIf="showControls">
        <ng-container *ngIf="!goal.complete; else actionsComplete">
          <ng-container *ngIf="!goal.archived; else actionsArchived">
            <!-- Incomplete -->
            <div class="row" *ngIf="!goal.archived && !goal.complete">
              <div
                class="col-xs-2 col-xs-offset-8 action-bar-button action-bar-button-info frankli-close expand-on-hover"
                *ngIf='editing.all' (click)="cancelEdit()" angulartics2On="click" angularticsAction="EditGoalIndividual"
                angularticsCategory="Goals" tooltip="Discard all changes and stop editing" placement="top"
                container="body">
                <span class="fal fa-times"></span> Cancel Edit
              </div>

              <div class="col-xs-2 action-bar-button action-bar-button-info expand-on-hover"
                [class.save-edits]="editing.all"
                [ngClass]="((editing.all)?('col-xs-offset-0 frankli-view'):('col-xs-offset-6 frankli-edit'))"
                (click)="enableEdit()" angulartics2On="click" angularticsAction="EditGoalIndividual"
                angularticsCategory="Goals" [tooltip]="editing.all ? 'Save all changes' : 'Start editing this goal'"
                placement="top" container="body">
                <span class="fal" [ngClass]="((editing.all)?('fa-check'):('fa-pencil'))"></span>
                {{(editing.all)?('Save Edits'):('Edit')}}
              </div>

              <div class="col-xs-2 action-bar-button action-bar-button-info frankli-archive expand-on-hover"
                (click)="archive()" *ngIf='!editing.all' angulartics2On="click"
                angularticsAction="ArchiveGoalIndividual" angularticsCategory="Goals" tooltip="Archive this goal"
                placement="top" container="body">
                <span class="fal fa-folder-open"></span> Archive
              </div>

              <div class="col-xs-2 action-bar-button action-bar-button-danger frankli-close expand-on-hover"
                (click)="delete()" *ngIf='!editing.all' angulartics2On="click" angularticsAction="DeleteGoalIndividual"
                angularticsCategory="Goals" tooltip="Delete this goal" placement="top" container="body">
                <span class="fal fa-trash"></span> Delete
              </div>
            </div>
          </ng-container>

          <!-- Archived -->
          <ng-template #actionsArchived>
            <div class="row" *ngIf="goal.archived">
              <div class="col-xs-2 col-xs-offset-8 action-bar-button action-bar-button-info" (click)="unarchive()"
                angulartics2On="click" angularticsAction="UnarchiveGoalIndividual" angularticsCategory="Goals">
                <span class="fal fa-folder-open"></span> Unarchive
              </div>

              <div class="col-xs-2 action-bar-button action-bar-button-danger" (click)="delete()" angulartics2On="click"
                angularticsAction="DeleteGoalIndividual" angularticsCategory="Goals">
                <span class="fal fa-trash"></span> Delete
              </div>
            </div>
          </ng-template>
        </ng-container>

        <!-- Complete -->
        <ng-template #actionsComplete>
          <div class="row" *ngIf="!goal.archived && goal.complete">

            <div class="col-xs-2 col-xs-offset-6 action-bar-button action-bar-button-info" (click)="active()"
              angulartics2On="click" angularticsAction="ActivateGoalIndividual" angularticsCategory="Goals">
              <span class="fal fa-check"></span> Activate
            </div>

            <div class="col-xs-2 action-bar-button action-bar-button-info" (click)="archive()" angulartics2On="click"
              angularticsAction="ArchiveGoalIndividual" angularticsCategory="Goals">
              <span class="fal fa-folder-open"></span> Archive
            </div>

            <div class="col-xs-2 action-bar-button action-bar-button-danger" (click)="delete()" angulartics2On="click"
              angularticsAction="DeleteGoalIndividual" angularticsCategory="Goals">
              <span class="fal fa-trash"></span> Delete
            </div>
          </div>
        </ng-template>
      </ng-container>

      <hr class="m-top-0 m-bottom-0" />

      <!-- Main Panel-->
      <div class="p-left-15 p-right-15 p-top-0">
        <form [formGroup]="formEdit" autocomplete="off">
          <div class="row goal-status-highlight"
            [class.goal-status-highlight-off-track]="goal.status === eGoalStatus.OFF_TRACK && goal.complete === false && goal.archived === false"
            [class.goal-status-highlight-progressing]="goal.status === eGoalStatus.PROGRESSING && goal.complete === false && goal.archived === false"
            [class.goal-status-highlight-on-track]="goal.status === eGoalStatus.ON_TRACK && goal.complete === false && goal.archived === false"
            [class.goal-status-highlight-complete]="goal.complete === true && goal.archived === false"
            [class.goal-status-highlight-archived]="goal.archived === true">
            <div class="col-md-10 col-xs-12">
              <!-- Summary -->
              <div class="p-top-10 p-bottom-10" [class.mh-edit-status]="editing.all">
                <!-- Goal title and details dropdown -->
                <div [ngSwitch]="editing.all">
                  <div *ngSwitchCase="false" class="goal-title">
                    <span class="fal fa-fw fa-chevron-right toggle-expand" (click)="topExpanded = !topExpanded"
                      *ngIf="!goal.complete" [class.rotate-90]="topExpanded"></span>
                    <span class="m-left-10">{{goal.title}}</span>
                  </div>
                  <div *ngSwitchCase="true" class="frankli-form-group m-left-10 title-form-group"
                    [class.frankli-form-group-invalid]="state.submitted === true && goalTitle.invalid">
                    <textarea placeholder="Goal Title" class="title-textarea form-control goal-title" rows="1"
                      [formControl]="goalTitle"></textarea>
                    <div class="frankli-form-group-alert">
                      <ng-container *ngIf="state.submitted && goalTitle.invalid">
                        <div *ngIf="goalTitle.hasError('required')">Required</div>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <!-- Meta details -->
                <!--  *ngIf="topExpanded || editing.all || goal.complete" -->
                <div class="row m-top-10">
                  <!-- Date Created -->
                  <div class="col-md-2 col-xs-4">
                    <div class="form-group">
                      <label><span class="fal fa-bullseye m-right-5"></span>Date Created</label>
                      <br />
                      <span class="goal-details">{{(goal.creationDate | date:'d MMMM y')}}</span>
                    </div>
                  </div>

                  <!-- End Date -->
                  <div class="col-md-2 col-xs-4">
                    <div class="form-group">
                      <label>
                        <i class="fal fa-clock m-right-5"></i> End Date</label>
                      <br />
                      <div [class.hidden]='editing.all' class="inline-edit p-left-2">
                        <div><span class="goal-details">{{endDate.value | date:'d MMMM y'}}</span></div>
                        <div *ngIf="getDaysLeft(endDate.value); let daysLeft"><span
                            class="goal-details">({{daysLeft}})</span></div>
                      </div>
                      <div [class.hidden]='!editing.all' class="frankli-form-group inline-edit p-left-2"
                        [class.frankli-form-group-invalid]="state.submitted && endDate.invalid">
                        <app-date-picker-old-component [formControl]="endDate" [invalid]="endDate.invalid" [index]="999"
                          [emptyText]="'DD/MM/YYYY'" [minDate]="minDate" [editing]='true'
                          [inputInvalid]="state.submitted && endDate.invalid"></app-date-picker-old-component>
                        <div class="frankli-form-group-alert">
                          <ng-container *ngIf="state.submitted && endDate.invalid">
                            <div *ngIf="endDate.hasError('required')">Required</div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Visibility -->
                  <div class="col-md-2 col-xs-4">
                    <div class="form-group">
                      <label>
                        <i class="fal fa-lock m-right-5"></i> Visibility</label>
                      <br />
                      <span class="goal-details">
                        <div [class.hidden]='editing.all' class="inline-edit p-left-2">
                          <span class="goal-details">{{goal.visibility}}</span>
                        </div>
                      </span>
                      <div [class.hidden]=!editing.all class="frankli-form-group inline-edit p-left-2">
                        <select [formControl]='visibility' class='width-100 m-top-n-1'>
                          <option [value]="eGoalVisibility.PUBLIC">PUBLIC</option>
                          <option *ngIf="goalType.value === eGoalType.PERSONAL_OPERATIONAL
                            || goalType.value === eGoalType.PERSONAL_DEVELOPMENTAL
                            || goalType.value === eGoalType.TEAM 
                            || goalType.value === eGoalType.COMPANY" [value]="eGoalVisibility.PRIVATE">PRIVATE</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <!-- Priority -->
                  <div class="col-md-2 col-xs-4" [class.hidden]="globals.hasFeature(eFeature.GOAL_PRIORITY) === false">
                    <div class="form-group">
                      <label class="text-nowrap">
                        <i class="fal fa-exclamation-circle m-right-5"></i> Priority
                        {{(editing.all)?('(optional)'):('')}}</label>
                      <br />
                      <span class="goal-details">
                        <div [class.hidden]='editing.all' class="inline-edit p-left-2">
                          <span *ngIf="goal.priority === eGoalPriority.NOT_SET">Not set</span>
                          <span *ngIf="goal.priority === eGoalPriority.P1">P1</span>
                          <span *ngIf="goal.priority === eGoalPriority.P2">P2</span>
                          <span *ngIf="goal.priority === eGoalPriority.P3">P3</span>
                          <span *ngIf="goal.priority === eGoalPriority.P4">P4</span>
                          <span *ngIf="goal.priority === eGoalPriority.P5">P5</span>
                        </div>
                        <div [class.hidden]='!editing.all' class="frankli-form-group inline-edit p-left-2">
                          <select [formControl]='priority' class="width-100 m-top-n-1">
                            <option [ngValue]="eGoalPriority.NOT_SET">Not set</option>
                            <option [ngValue]="eGoalPriority.P1">P1</option>
                            <option [ngValue]="eGoalPriority.P2">P2</option>
                            <option [ngValue]="eGoalPriority.P3">P3</option>
                            <option [ngValue]="eGoalPriority.P4">P4</option>
                            <option [ngValue]="eGoalPriority.P5">P5</option>
                          </select>
                        </div>
                      </span>
                    </div>
                  </div>

                  <!-- Goal Type -->
                  <div class="col-md-2 col-xs-4">
                    <div class="form-group">
                      <label>
                        <i class="fal fa-cog m-right-5"></i> Goal Type</label>
                      <br />
                      <div [class.hidden]='editing.all' class="inline-edit p-left-2">
                        <span class="goal-details" *ngIf="goal.type === eGoalType.PERSONAL_OPERATIONAL">Personal
                          Operational</span>
                        <span class="goal-details" *ngIf="goal.type === eGoalType.PERSONAL_DEVELOPMENTAL">Personal
                          Developmental</span>
                        <span class="goal-details" *ngIf="goal.type === eGoalType.TEAM">{{companyWording.team |
                          titlecase}}</span>
                        <span class="goal-details"
                          *ngIf="goal.type === eGoalType.DEPARTMENT">{{companyWording.department | titlecase}}</span>
                        <span class="goal-details" *ngIf="goal.type === eGoalType.OFFICE_LOCATION">Site</span>
                        <span class="goal-details" *ngIf="goal.type === eGoalType.COMPANY">Company</span>
                      </div>
                      <div [class.hidden]='!editing.all' class="frankli-form-group inline-edit p-left-2">
                        <select class="width-100 m-top-n-1 min-w-type" [formControl]="goalType">
                          <option [ngValue]="eGoalType.PERSONAL_DEVELOPMENTAL">Personal Developmental</option>
                          <option [ngValue]="eGoalType.PERSONAL_OPERATIONAL">Personal Operational</option>
                          <option [ngValue]="eGoalType.TEAM">{{companyWording.team | titlecase}}</option>
                          <option [ngValue]="eGoalType.DEPARTMENT">{{companyWording.department | titlecase}}</option>
                          <option *ngIf="globals.hasFeature(eFeature.GOAL_OFFICE_LOCATION)"
                            [ngValue]="eGoalType.OFFICE_LOCATION">Site</option>
                          <option [ngValue]="eGoalType.COMPANY">Company</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <!-- Goal Department -->
                  <div class="col-md-2 col-xs-4"
                    *ngIf="editing.all === false && goal.type === eGoalType.DEPARTMENT && goal.department !== null">
                    <div class="form-group">
                      <label>
                        <i class="fal fa-briefcase m-right-5"></i>{{companyWording.department | titlecase}}</label>
                      <br />
                      <div class="inline-edit p-left-2">
                        <span class="goal-details">{{goal.department.name}}</span>
                      </div>
                    </div>
                  </div>

                  <!-- Department -->
                  <div class="col-md-2 col-xs-4"
                    [class.hidden]="editing.all === false || goalType.value !== eGoalType.DEPARTMENT">
                    <div class="form-group">
                      <label><i class="fal fa-briefcase m-right-5"></i>{{companyWording.department | titlecase}}</label>
                      <br />
                      <div class="frankli-form-group inline-edit p-left-2"
                        [class.frankli-form-group-invalid]="goalType.value === eGoalType.DEPARTMENT && goalDepartment.value === null">
                        <select class="width-100 m-top-n-1" [formControl]="goalDepartment">
                          <option [ngValue]="null">Please select...</option>
                          <option [ngValue]="department.id" *ngFor="let department of departments">
                            {{department.name}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <!-- Goal Office Location -->
                  <div class="col-md-2 col-xs-4"
                    *ngIf="editing.all === false && goal.type === eGoalType.OFFICE_LOCATION && goal.officeLocation !== null">
                    <div class="form-group">
                      <label>
                        <i class="fal fa-briefcase m-right-5"></i>Office Location</label>
                      <br />
                      <div class="inline-edit p-left-2">
                        <span class="goal-details">{{goal.officeLocation?.name}}</span>
                      </div>
                    </div>
                  </div>

                  <!-- Office Location -->
                  <div class="col-md-2 col-xs-4"
                    [class.hidden]="editing.all === false || goalType.value !== eGoalType.OFFICE_LOCATION">
                    <div class="form-group">
                      <label><i class="fal fa-briefcase m-right-5"></i>Office Location</label>
                      <br />
                      <div class="frankli-form-group inline-edit p-left-2"
                        [class.frankli-form-group-invalid]="goalType.value === eGoalType.OFFICE_LOCATION && goalOfficeLocation.value === null">
                        <select class="width-100 m-top-n-1" [formControl]="goalOfficeLocation">
                          <option [ngValue]="null">Please select...</option>
                          <option [ngValue]="officeLocation.id" *ngFor="let officeLocation of officeLocations">
                            {{officeLocation.name}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <!-- Round progress -->
            <div class="col-md-2 col-xs-12 text-right">
              <span>
                <div class="progress progress-round">
                  <span class="progress-left">
                    <span class="progress-bar border-primary" [style.transform]="roundProgress.left"></span>
                  </span>
                  <span class="progress-right">
                    <span class="progress-bar border-primary" [style.transform]="roundProgress.right"></span>
                  </span>
                  <div class="progress-value rounded-circle">
                    <span>{{goal.completionPercentage | number: '1.0-2'}}%</span>
                  </div>
                </div>
              </span>
            </div>
          </div>

          <!-- More details dropdown -->
          <!-- Auto-opened if editing or goal complete -->
          <div class="p-bottom-10" *ngIf="topExpanded || editing.all || goal.complete">
            <!-- Development Needs -->
            <ng-container *ngIf="goal.type === eGoalType.PERSONAL_DEVELOPMENTAL">
              <hr class="m-top-0" />

              <div class="row">
                <div class="col-md-6 p-left-25">
                  <div class="form-group">
                    <label class="text-nowrap">
                      <span class="fal fa-graduation-cap m-right-5"></span>
                      <span>Development Needs</span>
                    </label>
                    <div>
                      <span class="word-wrap">
                        <ng-container *ngIf="!editing.all">
                          <span>{{(goal.developmentNeeds && goal.developmentNeeds.length > 0) ? goal.developmentNeeds :
                            'None'}}</span>
                        </ng-container>
                        <ng-container *ngIf="editing.all">
                          <textarea rows="3" placeholder="What are your development needs?"
                            [formControl]="developmentNeeds" class="form-control text-area-fix"></textarea>
                        </ng-container>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <!-- Owners -->
            <ng-container>
              <hr class="m-top-0 m-bottom-10" />

              <div class="form-group p-left-10">
                <label>
                  <span class="fal fa-users m-right-5"></span>
                  <span>Owners</span>
                </label>


                <div>
                  <ng-container *ngIf="!editing.all; else editingOwners">
                    <div class="owner-container">
                      <ng-container *ngIf="goal.owners.length > 0; else noOwners">
                        <img *ngFor="let owner of goal.owners" (click)="openOwner(owner)" [src]="owner.imageUrl"
                          class="img-owner" />
                      </ng-container>
                      <ng-template #noOwners>
                        <span>No owners</span>
                      </ng-template>
                    </div>
                  </ng-container>

                  <ng-template #editingOwners>
                    <span class="owner owner-info text-center noselect" *ngFor="let owner of ownerFilter.selected">
                      <span (click)="openOwner(owner)">
                        <img [src]="owner.imageUrl" class="owner-img" />
                        <span class="owner-text">{{owner.firstName}} {{owner.lastName}}</span>
                      </span>
                      <span class="fal fa-times owner-btn m-right-10 frankli-close expand-on-hover"
                        tooltip="Remove owner" placement="right" container="body" (click)="removeOwner(owner)"></span>
                    </span>

                    <span class="text-danger m-right-15"
                      *ngIf="state.submitted && (ownerFilter.selected.length === 0)">At least one goal owner is
                      required</span>
                    <span class="owner owner-info text-center owners-add clickable" (click)="toggleEditOwners()"
                      [tooltip]="editing.owners ? 'Stop adding owners' : 'Add a new owner'" placement="right"
                      container="right"><i class="fal fa-plus" [class.owner-icon-close]="editing.owners"></i></span>
                  </ng-template>
                </div>


                <!-- Editing -->
                <ng-container *ngIf="editing.owners">
                  <div class="row">
                    <div class="col-md-6">
                      <!-- Search box -->
                      <div
                        class="frankli-form-group frankli-form-group-placeholder-shown input-group m-top-10 text-nowrap">
                        <span class="input-group-addon box-left">
                          <i class="fal fa-search"></i>
                        </span>
                        <input type="text" value="" class="form-control" [(ngModel)]="ownerFilter.value"
                          [ngModelOptions]="{standalone: true}" (ngModelChange)="filterOwners($event)"
                          placeholder="Search by typing here..." #ownerSearch>
                        <span *ngIf="ownerFilter.value.length > 0" class="input-group-addon clickable box-right"
                          (click)="clearOwnerFilter()" tooltip="Clear search" placement="right" container="body">
                          <i class="fal fa-times"></i>
                        </span>
                      </div>

                      <!-- Search Output -->
                      <div class="light-border"
                        [class.light-border-collapse]="ownerFilter.value.trim() === '' && ownerFilter.filtered.length === 0">
                        <span class="owner noselect" *ngFor="let owner of ownerFilter.filtered"
                          (click)="addOwner(owner)">
                          <img [src]="owner.imageUrl" class="owner-img" />
                          <span class="owner-text">{{owner.firstName}} {{owner.lastName}}</span>
                        </span>
                        <div class="text-center m-top-25 m-bottom-25 col-md-12"
                          *ngIf="ownerFilter.filtered.length === 0 && ownerFilter.all.length > 0">
                          No people found for this search
                        </div>
                        <div class="text-center m-top-25 m-bottom-25 col-md-12" *ngIf="ownerFilter.all.length === 0">
                          No users remaining
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>

            <!-- Tags -->
            <ng-container>
              <div class="form-group p-left-10">
                <label>
                  <span class="fal fa-tag"></span>
                  <span>Tags</span>
                </label>

                <div>
                  <ng-container *ngIf="!editing.all; else editingTags">
                    <ng-container *ngIf="tagFilter.selected.length > 0; else noTags;">
                      <span [title]="tag.text + ': ' + tag.description"
                        class="tag tag-info text-center noselect p-left-10" *ngFor="let tag of tagFilter.selected"
                        (click)="openTag(tag)">
                        <i class="fal fa-tag"></i>
                        <span class="tag-text">{{tag.text}}</span>
                      </span>
                    </ng-container>
                    <ng-template #noTags>
                      <span *ngIf="tagFilter.selected.length === 0">No tags</span>
                    </ng-template>
                  </ng-container>

                  <ng-template #editingTags>
                    <span (click)="removeTag(tag)" [title]="tag.text + ': ' + tag.description"
                      class="tag tag-info text-center noselect p-left-10" *ngFor="let tag of tagFilter.selected">
                      <i class="fal fa-tag"></i>
                      <span class="tag-text">{{tag.text}}</span>
                      <span class="fal fa-times frankli-close expand-on-hover p-right-10" tooltip="Remove tag"
                        placement="right" container="body"></span>
                    </span>
                    <span class="tag tag-info text-center clickable tag-add-sides" (click)="toggleEditTags()"
                      [tooltip]="editing.tags ? 'Stop adding tags' : 'Add a new tag'" placement="right"
                      container="body">
                      <i class="fal fa-plus" [class.tag-icon-close]="editing.tags"></i>
                    </span>
                  </ng-template>
                </div>


                <!-- Editing -->
                <div class="row" [class.hidden]="!editing.tags">
                  <div class="col-md-6">
                    <!-- Search box -->
                    <div class="frankli-form-group frankli-form-group-placeholder-shown input-group m-top-10">
                      <span class="input-group-addon box-left">
                        <i class="fal fa-search"></i>
                      </span>
                      <input type="text" value="" class="form-control" [(ngModel)]="tagFilter.value"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="filterTags($event)"
                        placeholder="Search by typing here..." #tagSearch>
                      <span *ngIf="tagFilter.value.length > 0" class="input-group-addon box-right clickable"
                        (click)="clearTagFilter()" tooltip="Clear search" placement="right" container="body">
                        <i class="fal fa-times"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row" [class.hidden]="!editing.tags">
                  <div class="col-md-6">
                    <!-- Search Output -->
                    <div class="light-border"
                      [class.light-border-collapse]="tagFilter.value.trim() === '' && tagFilter.filtered.length === 0">
                      <span [title]="tag.text + ': ' + tag.description" class="tag noselect"
                        *ngFor="let tag of tagFilter.filtered" (click)="addTag(tag)">
                        <i class="fal fa-tag"></i>
                        <span class="tag-text">{{tag.text}}</span>
                      </span>

                      <div class="text-center m-top-25 m-bottom-25 col-md-12"
                        *ngIf="tagFilter.filtered.length === 0 && tagFilter.all.length > 0">
                        No tags found for this search
                      </div>

                      <div class="text-center m-top-25 m-bottom-25 col-md-12" *ngIf="tagFilter.all.length === 0">
                        No tags remaining
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <!-- Alignments -->
            <ng-container *ngIf="(editing.all || (alignedTo !== null) || (alignedGoals && alignedGoals.length > 0))">
              <hr class="m-top-0 m-bottom-0" />

              <!-- Aligned To -->
              <ng-container *ngIf="goal.type !== eGoalType.COMPANY">
                <ng-container *ngIf="editing.all === false && alignedTo !== null">
                  <div class="p-top-15 p-left-10">
                    <div class="form-group">
                      <label>
                        <i class="fal fa-handshake m-right-5"></i>
                        <span>Goal Aligned With</span>
                      </label>
                      <div class="row m-left-0">
                        <div class="col-md-8 col-sm-12 col-xs-12 key-result-text">
                          <div class="inline-edit m-left-10">
                            <strong class="goal-aligned-title"
                              (click)="goToAlignedGoal(alignedTo)">{{alignedTo.title}}</strong>
                          </div>
                        </div>
                        <div class="col-md-4 col-md-offset-0 col-sm-12 col-xs-11 col-xs-offset-1">
                          <div class="row m-left-0">
                            <div class="col-md-9 col-sm-9 col-xs-9 progress progress-bar-margin">
                              <div class="progress-bar progress-bar-info"
                                [style.width]="alignedTo.completionPercentage + '%'">
                                <span class="sr-only"></span>
                              </div>
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-3 text-nowrap">{{alignedTo.completionPercentage | number: '1.0-2'}}%
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="editing.all === true">
                  <div class="p-top-15 p-left-10">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>
                            <i class="fal fa-handshake m-right-5"></i> Goal Aligned With
                          </label>
                          <div class="inline-edit m-left-10 frankli-form-group"
                            *ngIf="goalType.value !== eGoalType.COMPANY">
                            <select class="alignSelect form-control h-100" [formControl]="goalAlignment">
                              <option [value]="null">No Alignment</option>
                              <option *ngFor="let goal of goals" [ngValue]="goal.id">
                                <ng-container *ngIf="goal.type === eGoalType.TEAM">
                                  [{{companyWording.team | titlecase}}]
                                </ng-container>
                                <ng-container *ngIf="goal.type === eGoalType.DEPARTMENT">
                                  [{{companyWording.department | titlecase}}]
                                </ng-container>
                                <ng-container *ngIf="goal.type === eGoalType.OFFICE_LOCATION">
                                  [Site]
                                </ng-container>
                                <ng-container *ngIf="goal.type === eGoalType.COMPANY">
                                  [Company]
                                </ng-container>
                                {{ ' ' + goal.title}}
                              </option>
                            </select>
                          </div>
                          <div *ngIf="goalType.value === eGoalType.COMPANY">
                            Company goals cannot be aligned to other goals
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <!-- Aligned with this  -->
              <ng-container *ngIf="(alignedGoals.length > 0) && !editing.all">
                <div class="p-top-15 p-left-10">
                  <div class="form-group">
                    <label>
                      <i class="fal fa-handshake m-right-5"></i> Aligned with this goal
                    </label>

                    <div class="row" *ngFor="let g of alignedGoals; let i = index;">
                      <span class="col-md-1 col-md-offset-0 col-sm-1 col-xs-1 text-right text-nowrap">{{i + 1}}.</span>
                      <div class="col-md-7 col-md-offset-0 col-sm-11 col-xs-11 key-result-text">
                        <strong class="goal-aligned-title" (click)="goToAlignedGoal(g)">
                          {{g.title}}
                        </strong>
                      </div>
                      <div class="col-md-4 col-md-offset-0 col-sm-12 col-xs-12 col-xs-offset-1">
                        <div class="row m-left-0">
                          <div class="col-md-9 col-sm-9 col-xs-9 progress progress-bar-margin">
                            <div class="progress-bar progress-bar-info" [style.width]="g.completionPercentage + '%'">
                              <span class="sr-only"></span>
                            </div>
                          </div>
                          <div class="col-md-3 col-sm-3 col-xs-3 text-nowrap">{{g.completionPercentage | number: '1.0-2'}} %</div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>

          <!-- Goal Update -->
          <ng-container *ngIf="!editing.all && !goal.complete && userIsOwner">
            <hr class="m-top-0 m-bottom-0" />
            <div class="p-top-10 p-left-10">
              <app-goals-individual-update-component [goal]="goal" [showControls]="showControls" [editing]="editing"
                (refresh)="refresh($event)" (completeEmit)="complete()"></app-goals-individual-update-component>
            </div>
          </ng-container>

          <hr class="m-top-0 m-bottom-0" />

          <!-- Editing key results -->
          <div class="row m-top-10 m-bottom-20 p-left-25 p-right-25" formArrayName="keyResults"
            *ngIf="editing.all; else displayKeyResults">
            <h5>Key Results</h5>

            <div class="kr-edit-warning m-bottom-15" *ngIf="visible">
              <div class="icon-hide" title="hide this warning" (click)="visible = false">
                <span class="fal fa-fw fa-times expand-on-hover"></span>
              </div>
              <div class="warning-text">
                <span>Changing the data here will change the start and goal values of this goal's key results, which will not affect their current value.</span>
                <br/>
                <span>If you would like to update progress on the goal, leave edit mode and see the Update Progress area (you must be a goal owner to do so).</span>
              </div>
            </div>

            <div class="row kr-headings">
              <div class="col-md-3">Result</div>
              <div class="col-md-2">Type</div>
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-3">Start Value</div>
                  <div class="col-md-3">Goal Value</div>
                  <div class="col-md-3">Unit (optional)</div>
                  <div class="col-md-3">Unit Placement</div>

                </div>
              </div>
              <div class="col-md-2">End Date</div>
            </div>
            <div class="row kr-row" *ngFor="let keyResult of keyResults.controls; index as i">
              <!-- Result index and title -->
              <div class="col-md-3 frankli-form-group"
                [class.frankli-form-group-invalid]="state.submitted === true && keyResult.controls.result.invalid">
                <input type="text" placeholder="Name" [formControl]="keyResult.controls.result" />
                <div class="frankli-form-group-alert">
                  <ng-container *ngIf="state.submitted && keyResult.controls.result.invalid">
                    <div *ngIf="keyResult.controls.result.hasError('required')">Required</div>
                  </ng-container>
                </div>
              </div>

              <!-- Type -->
              <div class="col-md-2 frankli-form-group"
                [class.frankli-form-group-invalid]="state.submitted === true && keyResult.controls.type.invalid">
                <select class="text-center" [formControl]="keyResult.controls.type">
                  <option [ngValue]="null">Please select...</option>
                  <option [ngValue]="eGoalKeyResultType.BINARY">Complete / Incomplete</option>
                  <option [ngValue]="eGoalKeyResultType.NUMERIC">Numeric</option>
                </select>
                <div class="frankli-form-group-alert">
                  <ng-container *ngIf="state.submitted && keyResult.controls.type.invalid">
                    <div *ngIf="keyResult.controls.type.hasError('required')">Required</div>
                  </ng-container>
                </div>
              </div>

              <!-- Progress -->
              <div class="col-md-4">
                <div class="row">
                  <!-- Incomplete / Complete -->
                  <ng-container *ngIf="keyResult.controls.type.value === eGoalKeyResultType.BINARY">
                    <!-- We don't need to allow any configuration for BINARY so no content -->
                  </ng-container>

                  <!-- Other Current / Goal Values -->
                  <ng-container *ngIf="keyResult.controls.type.value === eGoalKeyResultType.NUMERIC">
                    <div class="col-md-3 frankli-form-group"
                      [class.frankli-form-group-invalid]="keyResult.controls.measureStartValue.invalid && state.submitted">
                      <input id="start-value" class="form-control" [formControl]="keyResult.controls.measureStartValue"
                        placeholder="Start Value" />
                      <div class="frankli-form-group-alert">
                        <ng-container *ngIf="state.submitted && keyResult.controls.measureStartValue.invalid">
                          <div *ngIf="keyResult.controls.measureStartValue.hasError('required')">Required</div>
                        </ng-container>
                        <ng-container *ngIf="keyResult.hasError('goalSetToEqual')">
                          <div>Start Value can't be equal to Goal Value.</div>
                        </ng-container>
                      </div>
                    </div>
                    <div class="col-md-3 frankli-form-group"
                      [class.frankli-form-group-invalid]="keyResult.controls.measureGoalValue.invalid && state.submitted">
                      <input id="goal-value" class="form-control" [formControl]="keyResult.controls.measureGoalValue"
                        placeholder="Goal Value" />
                      <div class="frankli-form-group-alert">
                        <ng-container *ngIf="state.submitted && keyResult.controls.measureGoalValue.invalid">
                          <div *ngIf="keyResult.controls.measureGoalValue.hasError('required')">Required</div>
                        </ng-container>
                      </div>
                    </div>
                    <div class="col-md-3 frankli-form-group"
                      [class.frankli-form-group-invalid]="keyResult.controls.measureUnit.invalid && state.submitted">
                      <input id="start-value" class="form-control" [formControl]="keyResult.controls.measureUnit"
                        placeholder="Measure Unit" />
                      <div class="frankli-form-group-alert">
                        <ng-container *ngIf="state.submitted && keyResult.controls.measureUnit.invalid">
                          <div *ngIf="keyResult.controls.measureUnit.hasError('required')">Required</div>
                        </ng-container>
                      </div>
                    </div>
                    <div class="col-md-3 frankli-form-group" *ngIf="keyResult.controls.measureUnit.value.trimStart()"
                      [class.frankli-form-group-invalid]="keyResult.controls.measureUnitPlacement.invalid && state.submitted">
                      <select class="form-control" [formControl]="keyResult.controls.measureUnitPlacement">
                        <option [ngValue]="eGoalKeyResultMeasureUnitPlacement.BEFORE">Before</option>
                        <option [ngValue]="eGoalKeyResultMeasureUnitPlacement.AFTER">After</option>
                      </select>
                    </div>
                  </ng-container>
                </div>
              </div>

              <div class="col-md-2 frankli-form-group"
                [class.frankli-form-group-invalid]="state.submitted === true && keyResult.controls.endDate.invalid">
                <app-date-picker-old-component [formControl]="keyResult.controls.endDate"
                  [invalid]="keyResult.controls.endDate.invalid" [index]='i' [minDate]="minDate"
                  [emptyText]="'DD/MM/YYYY'" [editing]="true" [horizontalPosition]="'auto'">
                </app-date-picker-old-component>
                <div class="frankli-form-group-alert">
                  <ng-container *ngIf="state.submitted && keyResult.controls.endDate.invalid">
                    <div *ngIf="keyResult.controls.endDate.hasError('required')">Required</div>
                  </ng-container>
                </div>
              </div>

              <div class="col-md-1 text-center">
                <span class="frankli-close expand-on-hover fal fa-times" (click)="deleteKeyResult(keyResults, i)"
                  tooltip="Remove key result" placement="top" container="body"></span>
              </div>
            </div>

            <div class="row" *ngIf="editing.all">
              <div class="col-xs-12 text-center">
                <button type="button" class="btn-frankli-round btn-frankli-green btn-frankli-small"
                (click)="addKeyResult(keyResults)">Add Key Result</button>
              </div>
            </div>

          </div>

          <!-- Display key results (non-owner, archived goal and complete goal) -->
          <ng-template #displayKeyResults>
            <div class="key-results-display m-top-15" *ngIf="goal.archived || goal.complete || !userIsOwner">
              <h5>Key Results</h5>

              <div class="scroll-x row">
                <table width="100%">
                  <tr class="frankli-table frankli-table-small frankli-table-header noselect">
                    <th>Result</th>
                    <th class="text-center">Start Value</th>
                    <th>Current Value</th>
                    <th class="text-center">Goal Value</th>
                    <th>End Date</th>
                  </tr>
                  <tr class="frankli-table frankli-table-single-line"
                    *ngFor="let keyResultAbstract of keyResults.controls; index as i">
                    <ng-container *ngIf="getAbstractAsFormGroup(keyResultAbstract); let keyResult;">
                      <td class="col-result-title">
                        <span>{{keyResult.controls.result.value}}</span>
                      </td>
                      <td class="text-center">
                        <ng-container
                          *ngIf="keyResult.controls.type.value !== eGoalKeyResultType.BINARY; else startComplete">
                          <span
                            *ngIf="keyResult.controls.measureUnitPlacement.value === eGoalKeyResultMeasureUnitPlacement.BEFORE">
                            {{keyResult.controls.measureUnit.value}}
                          </span>
                          <span>{{keyResult.controls.measureStartValue.value | number: '1.0-2'}}</span>
                          <span
                            *ngIf="keyResult.controls.measureUnitPlacement.value === eGoalKeyResultMeasureUnitPlacement.AFTER">
                            {{keyResult.controls.measureUnit.value}}
                          </span>
                        </ng-container>
                        <ng-template #startComplete>
                          <span [ngSwitch]="keyResult.controls.measureStartValue.value">
                            <span *ngSwitchCase="0">Incomplete</span>
                            <span *ngSwitchCase="1">Complete</span>
                          </span>
                        </ng-template>
                      </td>
                      <ng-container
                        *ngIf="keyResult.controls.type.value !== eGoalKeyResultType.BINARY; else progressComplete">
                        <td>
                          <div class="p-top-15 p-bottom-15">
                            <div class="p-relative" *ngIf="getProgressWidth(keyResult); let width">
                              <div class="kr-display-progress-bar">
                                <div class="kr-display-progress-bar-inner" [style.width.%]="width">
                                </div>
                              </div>
                              <span class="kr-display-progress-value"
                                [style.width.%]="width">{{keyResult.controls.measureCurrentValue.value | number: '1.0-2'}}</span>
                            </div>
                          </div>
                        </td>
                      </ng-container>
                      <ng-template #progressComplete>
                        <td class="text-center" [ngSwitch]="keyResult.controls.measureCurrentValue.value">
                          <span *ngSwitchCase="0">Incomplete</span>
                          <span *ngSwitchCase="1">Complete</span>
                        </td>
                      </ng-template>
                      <td class="text-center">
                        <span
                          *ngIf="keyResult.controls.measureUnitPlacement.value === eGoalKeyResultMeasureUnitPlacement.BEFORE">
                          {{keyResult.controls.measureUnit.value}}
                        </span>
                        <span>
                          {{(keyResult.controls.type.value === eGoalKeyResultType.BINARY) ? 'Complete' :
                          keyResult.controls.measureGoalValue.value | number: '1.0-2'}}
                        </span>
                        <span *ngIf="keyResult.controls.measureUnitPlacement.value === eGoalKeyResultMeasureUnitPlacement.AFTER">
                          {{keyResult.controls.measureUnit.value}}
                        </span>
                      </td>
                      <td>
                        <span *ngIf="keyResult.controls.endDate.value; let value">{{value | date}}</span>
                      </td>
                    </ng-container>
                  </tr>
                </table>
              </div>
            </div>
          </ng-template>
        </form>
      </div>
    </div>

    <!-- Activity -->
    <div *ngIf="!editing.all">
      <app-goals-individual-activity-component [goal]="goal" (refresh)="refresh($event)">
      </app-goals-individual-activity-component>
    </div>
  </ng-container>
</ng-container>

<!-- #region - ALTERNATIVE PAGE STATES -->
<!-- LOADING -->
<ng-template #stateLoading>
  <div class="card">
    <div class="p-top-15 p-bottom-15 text-center">
      <span class="fal fa-spin fa-spinner fa-3x fa-fw"></span>
    </div>
  </div>
</ng-template>

<!-- ERROR -->
<ng-template #stateError>
  <div class="card">
    <div class="p-top-15 p-bottom-15 text-center">
      <h4>An error occurred while getting this goal</h4>
      <button class="btn-frankli-round btn-frankli-green" [routerLink]="'/dashboard'">Back to your dashboard?</button>
    </div>
  </div>
</ng-template>
<!-- #endregion -->