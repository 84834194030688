import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  Company,
  CompanyFeature,
  OfficeLocation,
  UpdateCompanyFiscalYearDto,
} from 'app/models/company.model';
import { User, UserServerSide } from 'app/models/user/user.model';
import { OrgChartUserDto } from 'app/models/user/org-chart-user.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganisationalUnit } from 'app/models/organisational-unit.model';
import { DateService } from '../utils/date.service';
import { CompanySetUpStep, CompanySetUpStepType } from '@app/models/company/company-set-up-step.model';
import { CompanySetUpStepGroup } from '@app/models/company/company-set-up-step-group.model';
import { UpdateCompanyFeaturesDto } from '@app/models/company/update-company-features-dto';
import { CompanyService } from '@app/shared/api/interfaces/company.service';
import { CompanyWording } from '@app/models/company/company-wording/company-wording.model';

@Injectable()
export class CompanyAPIService implements CompanyService {
  httpHeaders: HttpHeaders;
  private readonly BASE_URL = '/api/company';

  constructor(
    private http: HttpClient,
    private dateService: DateService
  ) {
    this.httpHeaders = new HttpHeaders();
    this.httpHeaders.append('Content-Type', 'application/json');
  }

  /**
   * Parses Utc dates to localdate
   * @param company
   */
  public mapCompany(company: Company): Company {
    company.fiscalYear = this.dateService.parseFiscalYear(company.fiscalYear);
    company.setUpGroups = this.mapCompanySetUpStepsGroups(company.setUpGroups);
    return company;
  }

  /**
   * Parses Utc dates to localdate
   * @param company
   */
  private mapCompanySetUpStepsGroups(groups: Array<CompanySetUpStepGroup>): Array<CompanySetUpStepGroup> {
    // Map steps
    for (let j = 0; j < groups.length; j++) {
      for (let i = 0; i < groups[j].steps.length; i++) {
        groups[j].steps[i] = this.mapCompanySetUpStep(groups[j].steps[i]);
      }
    }

    // Sort groups
    groups = groups.sort((a, b) => {
      return a.groupOrder - b.groupOrder;
    });

    // Sort group steps
    groups = groups.map(group => {
      group.steps = group.steps.sort((a, b) => {
        return a.stepOrder - b.stepOrder;
      });
      return group;
    });

    return groups;
  }

  /**
 * Parses Utc dates to localdate
 * @param company
 */
  private mapCompanySetUpStep(step: CompanySetUpStep): CompanySetUpStep {
    if (step.completionDate !== null) {
      step.completionDate = this.dateService.parseUtcToLocal(step.completionDate!);
    }
    if (step.skippedDate !== null) {
      step.skippedDate = this.dateService.parseUtcToLocal(step.skippedDate!);
    }
    return step;
  }

  getMe() {
    const url = this.BASE_URL + '/me';
    return this.http.get<Company>(url).pipe(map(company => this.mapCompany(company)));
  }

  getById(id: number) {
    const url = this.BASE_URL + '/?id=' + id;
    return this.http.get<Company>(url).pipe(map(company => this.mapCompany(company)));
  }

  getOrg() {
    const url = this.BASE_URL + '/org';
    return this.http.get<Array<OrganisationalUnit>>(url);
  }

  getOrgByType(type: string) {
    const url = this.BASE_URL + '/org?type=' + type;
    return this.http.get<Array<OrganisationalUnit>>(url);
  }

  getAllDepartments() {
    return this.getOrgByType('Department');
  }

  getAllOfficeLocations() {
    const url = this.BASE_URL + '/office-locations';
    return this.http.get<Array<OfficeLocation>>(url);
  }

  getManagerForOrganisationalUnit(orgUnitId: number) {
    const url = this.BASE_URL + '/manager/?orgunitId=' + orgUnitId;
    return this.http.get<UserServerSide>(url).pipe(map(u => new User(u)));
  }

  updateFeatures(features: Array<CompanyFeature>): Observable<Array<CompanyFeature>> {
    const url = this.BASE_URL + '/features';
    return this.http.post<Array<CompanyFeature>>(url, features, {headers: this.httpHeaders})
  }

  updateFeaturesForId(companyId: number, dto: UpdateCompanyFeaturesDto): Observable<Array<CompanyFeature>> {
    const url = this.BASE_URL + '/features/?id=' + companyId;
    return this.http.post<Array<CompanyFeature>>(url, dto, {headers: this.httpHeaders})
  }

  getCompanyOrgChart(): Observable<Array<OrgChartUserDto>> {
    const url = '/api/company/org-chart';
    return this.http.get<Array<OrgChartUserDto>>(url);
  }

  /**
   * Creates new department
   * @param organisationalUnit
   */
  createOrganisationalUnit(organisationalUnit: OrganisationalUnit): Observable<OrganisationalUnit> {
    const url = this.BASE_URL + '/org/create'
    return this.http.post<OrganisationalUnit>(url, organisationalUnit, {headers: this.httpHeaders});
  }

  /**
   * Updates existing department, provided it exists
   * @param organisationalUnit
   */
  updateOrganisationalUnit(organisationalUnit: OrganisationalUnit): Observable<OrganisationalUnit> {
    const url = this.BASE_URL + '/org/update'
    return this.http.post<OrganisationalUnit>(url, organisationalUnit, {headers: this.httpHeaders});
  }

  /**
   * Deletes existing department, provided it exists and there is at least one remaining
   * @param organisationalUnit
   */
  deleteOrganisationalUnit(organisationalUnit: OrganisationalUnit): Observable<OrganisationalUnit> {
    const url = this.BASE_URL + '/org/delete'
    return this.http.post<OrganisationalUnit>(url, organisationalUnit, {headers: this.httpHeaders});
  }

  /**
   * Creates an office location
   * @param officeLocation
   */
  createOfficeLocation(officeLocation: OfficeLocation): Observable<OfficeLocation> {
    const url = `${this.BASE_URL}/office-location/create`;
    return this.http.post<OfficeLocation>(url, officeLocation, {headers: this.httpHeaders});
  }

  /**
   * Updates an office location by id, provided it exists
   * @param officeLocation
   */
  updateOfficeLocation(officeLocation: OfficeLocation): Observable<OfficeLocation> {
    const url = `${this.BASE_URL}/office-location/update/${officeLocation.id}`;
    return this.http.post<OfficeLocation>(url, officeLocation, {headers: this.httpHeaders});
  }

  /**
   * Deletes an office location by id, provided it exists
   * @param officeLocation
   */
  deleteOfficeLocation(id: number): Observable<OfficeLocation> {
    const url = `${this.BASE_URL}/office-location/delete/${id}`;
    return this.http.post<OfficeLocation>(url, null);
  }

  updateFiscalYear(updateCompanyFiscalYearDto: UpdateCompanyFiscalYearDto): Observable<Company> {
    const url = `${this.BASE_URL}/fiscal-year`;
    return this.http.put<Company>(url, updateCompanyFiscalYearDto).pipe(map(company => this.mapCompany(company)));
  }

  getSetUpSteps(): Observable<Array<CompanySetUpStepGroup>> {
    const url = `${this.BASE_URL}/set-up-steps`;
    return this.http.get<Array<CompanySetUpStepGroup>>(url).pipe(map(steps => this.mapCompanySetUpStepsGroups(steps)));
  }

  skipCompanySetUpStepType(type: CompanySetUpStepType): Observable<CompanySetUpStep> {
    const url = `${this.BASE_URL}/set-up-steps/${type}/skip`;
    return this.http.post<CompanySetUpStep>(url, null).pipe(map(step => this.mapCompanySetUpStep(step)));
  }

  updateCompanyWording(companyWording: CompanyWording): Observable<CompanyWording> {
    const url = `${this.BASE_URL}/company-wording`;
    return this.http.put<CompanyWording>(url, companyWording, { headers: this.httpHeaders });
  }

}
