import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { forkJoin } from 'rxjs';
import { CompanyFeatures, OfficeLocation } from 'app/models/company.model';
import { GoalsAPIService } from 'app/shared/api/goals.api.service';
import {
  Goal,
  GoalHelperFunctions,
  GoalPriority,
  GoalStatus,
  GoalType,
  GoalVisibility,
} from 'app/models/goals/goal.model';
import { Globals } from 'app/shared/globals/globals';
import { GoalTag } from 'app/models/goals/goal-tag.model';
import { DropdownOption } from 'app/models/utils.model';
import { CompanyAPIService } from 'app/shared/api/company.api.service';
import { OrganisationalUnit } from 'app/models/organisational-unit.model';
import { State } from 'app/shared/utils/state.model';
import { HttpErrorResponse } from '@angular/common/http';
import { PaginationNewComponent } from 'app/shared/pagination/pagination-new/pagination-new.component';
import * as moment from 'moment';
import { CompanyWording } from '@app/models/company/company-wording/company-wording.model';


enum SortType {
  PRIORITY = 'priority',
  TYPE = 'type',
  TITLE = 'title',
  OWNERS = 'owners',
  KEY_RESULTS = 'keyResults',
  PROGRESS = 'completionPercentage',
  DATE = 'date'
}

enum SortDirection { ASCENDING, DESCENDING }

interface Sort {
  type: SortType,
  direction: SortDirection
};

@Component({
  selector: 'app-goal-manager-admin',
  templateUrl: './goal-manager-admin.component.html',
  styleUrls: ['./goal-manager-admin.component.css'],
})
export class GoalManagerAdminComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @Input() page!: number;
  @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() goalsEmit: EventEmitter<Goal[]>;

  state: State;
  eFeature = CompanyFeatures;
  sort: Sort = {
    type: SortType.TITLE,
    direction: SortDirection.DESCENDING,
  };

  eSortType = SortType;
  eSortDirection = SortDirection;
  eGoalType = GoalType;
  eGoalPriority = GoalPriority;
  eGoalStatus = GoalStatus;
  eGoalVisibility = GoalVisibility;

  goals = new Array<Goal>();
  goalsFiltered = new Array<Goal>();
  goalsDisplay = new Array<Goal>();

  amountToDisplay: any = 10;

  statuses: Array<string>;
  selectedStatus: string;
  departments: Array<DropdownOption>;
  selectedDepartment: string;
  officeLocations: Array<DropdownOption>;
  selectedOfficeLocation: string;
  tags: Array<GoalTag>;
  selectedTag: string;
  priorities: Array<string>;
  selectedPriority: string;
  visibilities: Array<string>;
  selectedVisibility: string;
  searchValue: string;
  ownersShown: boolean;

  @ViewChildren('ownerCol') ownerCols!: QueryList<ElementRef>;
  @ViewChild('pagination') pagination!: PaginationNewComponent;

  screen: {
    width: number,
    height: number
  }

  columnTitles: string[];

  eGoalHelperFunctions = GoalHelperFunctions;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screen = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    if (this.ownerCols) {
      this.ownersShown = false;
      setTimeout(() => {
        this.ownersShown = true;
        this.checkOverflow();
      }, 250);
    }
  }

  companyWording: CompanyWording;

  constructor(
    private companyAPIService: CompanyAPIService,
    private goalsAPIService: GoalsAPIService,
    private cdRef: ChangeDetectorRef,
    public globals: Globals,
  ) {
    this.companyWording = this.globals.company.companyWording;
    this.state = new State(true);
    this.searchValue = '';
    this.statuses = [];
    this.selectedStatus = 'All';
    this.selectedDepartment = 'All';
    this.selectedOfficeLocation = 'All';
    this.departments = [];
    this.tags = [];
    this.selectedTag = 'All';
    this.officeLocations = [];
    this.selectedPriority = 'All';
    this.visibilities = [];
    this.selectedVisibility = 'All';
    this.priorities = [];
    this.screen = {
      width: 0,
      height: 0
    }
    this.columnTitles = ['owners', 'type', 'title', 'priority', 'key results', 'progress', 'date'];
    this.ownersShown = true;
    this.goalsEmit = new EventEmitter<Goal[]>();

    this.onResize();
  }

  ngOnInit() {
    forkJoin([
      this.companyAPIService.getOrgByType('Department'),
      this.companyAPIService.getAllOfficeLocations(),
      this.goalsAPIService.getTags(),
      this.goalsAPIService.getAdminGoals(),
    ]).subscribe(([departments, officeLocations, tags, goals]) => {
      this.populateDropDowns(departments, officeLocations, tags, goals);
      this.goals = Goal.getGoalArrayCompletionPercentage(goals);
      this.goalsFiltered = Goal.sort.byEndDate(this.goals, 'ascending');
      this.goalsEmit.emit(this.goals);
      this.state.setSuccess();
    }, (failure: HttpErrorResponse) => {
      this.state.setFailure(failure.error);
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.checkOverflow();
    }, 1000);
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  paginationChanged(e: any) {
    this.pageChanged.emit(e);
  }

  update(): void {
    this.goalsFiltered = this.filter();
    if (this.sort.type === SortType.TITLE) {
      this.sort.type = SortType.TITLE;
      this.sort.direction = SortDirection.ASCENDING;
    }
  }

  search(): void {
    this.update();
  }

  filter(): Array<Goal> {
    let data = this.goals;
    data = this.filterByVisibility(data, this.selectedVisibility);
    data = this.filterByStatus(data, this.selectedStatus);
    data = this.filterByOfficeLocations(data, this.selectedOfficeLocation);
    data = this.filterByDepartment(data, this.selectedDepartment);
    data = this.filterByName(data, this.searchValue);
    data = this.filterByTags(data, this.selectedTag);
    data = this.filterByPriority(data, this.selectedPriority);
    return data;
  }

  filterByVisibility(data: Array<Goal>, visibility: string): Array<Goal> {
    if (visibility === 'All') {
      return data;
    } else {
      return data.filter(d => {
        return d.visibility.toString() === visibility;
      });
    }
  }

  filterByStatus(data: Array<Goal>, status: string): Array<Goal> {
    if (status === 'All') {
      return data;
    } else if (status === 'COMPLETE') {
      return data.filter(d => {
        return d.complete === true && d.archived === false;
      });
    } else if (status === 'ARCHIVED') {
      return data.filter(d => {
        return d.archived === true;
      });
    } else {
      return data.filter(d => {
        return (d.status.toString() === status && d.complete === false && d.archived === false);
      });
    }
  }

  filterByName(data: Array<Goal>, name: string): Array<Goal> {
    name = name.trim().toLowerCase();

    if (name === '') {
      return data;
    } else {
      return data.filter(d => {
        let owners = '';
        d.owners.forEach(o => {
          owners += `${o.firstName} ${o.lastName} `;
        });
        const fullName = `${d.title} ${owners}`;
        return fullName.toLowerCase().includes(name);
      });
    }
  }

  filterByDepartment(data: Array<Goal>, department: string | null): Array<Goal> {
    if (department === 'All') {
      return data;
    } else if (department === null) {
      return data.filter(g => g.owners.some(o => o.organisationalUnit === null));
    } else {
      return data.filter(g => g.owners.some(o => {
        if (o.organisationalUnit !== null) {
          return o.organisationalUnit.name === department;
        }
        return false;
      }));
    }
  }

  filterByOfficeLocations(data: Array<Goal>, officeLocation: string | null): Array<Goal> {
    if (officeLocation === 'All') {
      return data;
    } else if (officeLocation === null) {
      return data.filter(g => g.owners.some(o => o.officeLocation === null));
    } else {
      return data.filter(g => g.owners.some(o => {
        if (o.officeLocation !== null) {
          return o.officeLocation.name === officeLocation;
        }
        return false;
      }));
    }
  }

  filterByTags(data: Array<Goal>, tag: string | null): Array<Goal> {
    if (tag === 'All') {
      return data;
    } else if (tag === null) {
      return data.filter(g => g.tags.some(t => t.text === null));
    } else {
      return data.filter(g => g.tags.some(t => t.text === tag));
    }
  }

  filterByPriority(data: Array<Goal>, priority: string | null): Array<Goal> {
    if (priority === 'All') {
      return data;
    } else if (priority === null) {
      return data.filter(g => g.priority === null);
    } else {
      return data.filter(g => g.priority === priority);
    }
  }

  //////////////////////////
  // SORT
  //////////////////////////

  reset(): void {
    this.searchValue = '';
    this.selectedStatus = 'All';
    this.selectedDepartment = 'All';
    this.selectedOfficeLocation = 'All';
    this.selectedTag = 'All';
    this.selectedPriority = 'All';
    this.amountToDisplay = 10;
    this.sort.type = SortType.TITLE;
    this.sort.direction = SortDirection.DESCENDING;
    this.goalsFiltered = this.filter();
    this.doSort(SortType.TITLE)
    this.search();
  }

  private populateDropDowns(departments: Array<OrganisationalUnit>, officeLocations: Array<OfficeLocation>,
                            tags: Array<GoalTag>, goals: Array<Goal>): void {
    this.departments = departments.map(department => {
      const dropdownOption = new DropdownOption();
      dropdownOption.name = department.name;
      dropdownOption.value = department.name;
      return dropdownOption;
    });
    this.officeLocations = officeLocations.map(officeLocation => {
      const dropdownOption = new DropdownOption();
      dropdownOption.name = officeLocation.name;
      dropdownOption.value = officeLocation.name;
      return dropdownOption;
    });
    this.tags = tags;

    this.visibilities = Object.keys(this.eGoalVisibility);
    this.statuses = Object.keys(this.eGoalStatus);
    this.priorities = Object.keys(this.eGoalPriority);
  }

  checkOverflow() {
    this.ownerCols.forEach(c => {
      const col = c.nativeElement;
      const removed = [];
      while (col.offsetWidth < col.scrollWidth && col.childNodes.length > 4) {
        removed.push(col.childNodes[(col.childNodes.length - 1)]);
        col.removeChild(col.childNodes[(col.childNodes.length - 1)]);
      }
      if (removed.length > 0) {
        removed.splice(0, 1);
        removed.push(col.childNodes[(col.childNodes.length - 1)]);
        col.removeChild(col.childNodes[(col.childNodes.length - 1)]);
        const child = document.createElement('span');
        let title = '';
        removed.forEach(r => {
          if (r.title) {
            title += `${r.title}\n`;
          }
        });
        child.title = title;
        child.textContent = `+${removed.length}`;
        child.classList.add('m-left-5');
        child.classList.add('m-right-15');
        child.style.fontWeight = 'bold';
        child.style.borderRadius = '50%';
        child.style.background = '#54c6bb';
        child.style.padding = '4px';
        child.style.color = 'white';
        child.style.margin = '0';
        child.style.marginLeft = '-10px';
        child.style.fontSize = '12px';
        col.appendChild(child);
      }
    });
  }

  // #region - SORTING
  doSort(type: SortType) {
    // PRIORITY, TYPE, TITLE, OWNERS, KEY_RESULTS, PROGRESS, DATE
    if (this.sort.type !== type) {
      this.sort.type = type;
      this.sortArray();
    } else {
      this.switchSortDirection();
    }

    if (this.pagination) {
      this.pagination.update();
    }
  }

  switchSortDirection() {
    if (this.sort.direction === this.eSortDirection.ASCENDING) {
      this.sort.direction = SortDirection.DESCENDING;
    } else {
      this.sort.direction = this.eSortDirection.ASCENDING;
    }
    this.goalsFiltered.reverse();
  }

  sortArray() {
    this.sort.direction = SortDirection.ASCENDING;
    this.goalsFiltered = this.goalsFiltered.sort((t1: Goal, t2: Goal) => {
      if (this.sort.type === SortType.DATE) {
        const date1 = moment(t1[this.sort.type]).startOf('day');
        const date2 = moment(t1[this.sort.type]).startOf('day');

        if (date1.isAfter(date2)) {
          return 1;
        } else if (date1.isBefore(date2)) {
          return -1;
        } else {
          return 0;
        }
      } else {
        if (t1[this.sort.type] > t2[this.sort.type]) {
          return 1;
        }
        if (t1[this.sort.type] < t2[this.sort.type]) {
          return -1;
        } else {
          return 0;
        }
      }

    });
  }
  // #endregion
}
