import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Globals } from '../globals/globals';

@Injectable()
export class DateService {
  constructor(
    private globals: Globals
  ) {
  }

  parseUtcToLocal(date: Date): Date {
    return moment.utc(date).toDate()
  }

  formatAsUTC(date: Date): String {
    return moment.utc(date).toISOString();
  }

  parseDate(dateStr: string, dateFormat: string) {
    return moment(dateStr).format(dateFormat);
  }

  buildDate(year, month, day): Date {
    const dateString = year + '-' + month + '-' + day;
    const date = new Date(dateString);
    return date;
  }

  buildDateString(year, month, day): String {
    return year + '-' + month + '-' + day;
  }

  updateDaysInMonth(month, year, daysArray) {
    const days = this.daysInMonth(month, year);
    while (daysArray.length > 0) {
      daysArray.pop();
    }
    for (let i = 0; i < days; i++) {
      const d = (i + 1);
      let v = '';
      if (d < 10) {
        v = '0' + d;
      } else {
        v = '' + d;
      }
      daysArray.push(v);
    }
  }

  updateDaysInMonthExcl(month, year, daysArray) {
    const days = this.daysInMonth(month, year);
    while (daysArray.length > 0) {
      daysArray.pop();
    }
    const yearNow = new Date().getFullYear();
    const monthNow = new Date().getMonth();
    let i = (+year === yearNow && +month === (monthNow + 1)) ? new Date().getDate() - 1 : 0;
    for (i; i < days; i++) {
      const d = (i + 1);
      let v = '';
      if (d < 10) {
        v = '0' + d;
      } else {
        v = '' + d;
      }
      daysArray.push(v);
    }
  }

  updateYears(dataYear) {
    const year = new Date().getFullYear();
    while (dataYear.length > 0) {
      dataYear.pop();
    }
    for (let i = year; i > (year - 90); i--) {
      dataYear.push(i);
    }
  }

  updateYearsForward(dataYear) {
    const year = new Date().getFullYear();
    while (dataYear.length > 0) {
      dataYear.pop();
    }
    for (let i = year; i < (year + 5); i++) {
      dataYear.push(i);
    }
  }

  daysInMonth(month: number, year: number) {
    return new Date(year, month, 0).getDate();
  }

  getDay(date: Date) {
    const d = date.getDate();
    if (d < 10) {
      return '0' + d;
    }
    return d;
  }

  getMonth(date: Date) {
    const d = (date.getMonth() + 1);
    if (d < 10) {
      return '0' + d;
    }
    return d;
  }

  getYear(date: Date) {
    return date.getFullYear();
  }

  updateYearsStartFinish(dataYear, start, finish) {
    const year = new Date().getFullYear();
    while (dataYear.length > 0) {
      dataYear.pop();
    }
    for (let i = (year + finish); i > (year - start); i--) {
      dataYear.push(i);
    }
  }

  /**
   * Parses fiscal year date from backend to figure out what part of the year we are in
   * @param fiscalYear value returned from backend
   */
  parseFiscalYear(fiscalYear: Date): Date {
    const today = new Date();
    const yearStart = moment(fiscalYear).set('year', today.getFullYear());
    if (yearStart.isAfter(today)) {
      yearStart.add(-1, 'year');
    }

    return yearStart.toDate();
  }

  /**
   * Returns which quarter a date falls in, returns null if before or after this fiscal year
   * @param date
   */
  getQuarterByDate(input: Date): null | 'Q1' | 'Q2' | 'Q3' | 'Q4' {
    const date = moment(input);

    const Q1Start = this.getQuarterStartDate('Q1');
    const Q2Start = this.getQuarterStartDate('Q2');
    const Q3Start = this.getQuarterStartDate('Q3');
    const Q4Start = this.getQuarterStartDate('Q4');

    const Q1End = this.getQuarterEndDate('Q1');
    const Q2End = this.getQuarterEndDate('Q2');
    const Q3End = this.getQuarterEndDate('Q3');
    const Q4End = this.getQuarterEndDate('Q4');

    if (moment(date).isBefore(this.globals.company.fiscalYear, 'day')) {
      return null;
    } else if (moment(date).isSameOrAfter(Q1Start, 'day') && moment(date).isSameOrBefore(Q1End, 'day')) {
      return 'Q1';
    } else if (moment(date).isSameOrAfter(Q2Start, 'day') && moment(date).isSameOrBefore(Q2End, 'day')) {
      return 'Q2';
    } else if (moment(date).isSameOrAfter(Q3Start, 'day') && moment(date).isSameOrBefore(Q3End, 'day')) {
      return 'Q3';
    } else if (moment(date).isSameOrAfter(Q4Start, 'day') && moment(date).isSameOrBefore(Q4End, 'day')) {
      return 'Q4';
    } else {
      return null;
    }
  }

  /**
   * Returns the start date of a given quarter
   * @param quarter
   */
  getQuarterStartDate(quarter: 'Q1' | 'Q2' | 'Q3' | 'Q4'): Date {
    switch (quarter) {
      case 'Q1':
        return moment(this.globals.company.fiscalYear).toDate();
      case 'Q2':
        return moment(this.globals.company.fiscalYear).add(3, 'month').toDate();
      case 'Q3':
        return moment(this.globals.company.fiscalYear).add(6, 'month').toDate();
      case 'Q4':
        return moment(this.globals.company.fiscalYear).add(9, 'month').toDate();
    }
  }

  /**
   * Returns the end date of a given quarter
   * @param quarter
   */
  getQuarterEndDate(quarter: 'Q1' | 'Q2' | 'Q3' | 'Q4'): Date {
    switch (quarter) {
      case 'Q1':
        return moment(this.globals.company.fiscalYear).add(3, 'month').add(-1, 'day').toDate();
      case 'Q2':
        return moment(this.globals.company.fiscalYear).add(6, 'month').add(-1, 'day').toDate();
      case 'Q3':
        return moment(this.globals.company.fiscalYear).add(9, 'month').add(-1, 'day').toDate();
      case 'Q4':
        return moment(this.globals.company.fiscalYear).add(12, 'month').add(-1, 'day').toDate();
    }
  }

  /**
   * Returns true if provided date is within the fiscal year
   * @param input
   */
  isWithinFiscalYear(input: Date): boolean {
    const date = moment(input);
    const Q1Start = this.getQuarterStartDate('Q1');
    const Q4End = this.getQuarterEndDate('Q4');

    return date.isSameOrAfter(Q1Start) && date.isSameOrBefore(Q4End);
  }

}
