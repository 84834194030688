import { Component, NgZone, OnInit } from '@angular/core';
import { NavigationEnd, NavigationError, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { Angulartics2Amplitude } from 'angulartics2/amplitude';
import { environment } from 'environments/environment';
import { Globals } from './shared/globals/globals';
import * as Sentry from '@sentry/browser';
import { StatusAPIService } from './shared/api/status.api.service';
import { WebsocketService } from './shared/api/websocket.service';
import { VersionCheckService } from './shared/api/version-check.service';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {

  private intercomBooted = false;
  public lazyLoading = false;

  constructor(
    private router: Router,
    private angulartics2Amplitude: Angulartics2Amplitude,
    private globals: Globals,
    private zone: NgZone,
    private statusAPIService: StatusAPIService,
    private websocketService: WebsocketService,
    private versionCheckService: VersionCheckService
  ) {
    if (environment.production && !environment.mock.enabled) {
      this.angulartics2Amplitude.startTracking();
      switch (this.router.url) {
        case '/':
        case '/login':
        case '/callback':
        case '/reset':
          this.websocketService.deactivate();
          break;
        default:
          this.websocketService.activate();
          (<any>window).Intercom('boot', {
            app_id: environment.intercom.id,
          });
          break;
      }
    }

    // Bootstrap Notify z-index
    $.notifyDefaults({
      icon: 'fal fa-check-circle',
      offset: 20,
      spacing: 10,
      delay: 2000,
      timer: 50,
      z_index: 1550,
      newest_on_top: true,
      showProgressbar: false,
      type: 'frankli-blue',
      placement: {
        from: 'top',
        align: 'right'
      },
      mouse_over: null,
      animate: {
        enter: 'animated fadeInDown',
        exit: 'animated fadeOutUp'
      },
      onShow: null,
      onShown: null,
      onClose: null,
      onClosed: null,
      icon_type: 'class',
      template: `
        <div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">
          <button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>
          <span data-notify="icon"></span>
          <span data-notify="title">{1}</span>
          <span data-notify="message">{2}</span>
          <div class="progress" data-notify="progressbar">
            <div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>
          </div>
          <a href="{3}" target="{4}" data-notify="url"></a>
        </div>`
    });

  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // we use startsWith to ensure pages like /reset#example etc gets caught and doesn't error
        if (this.router.url === '/'
          || this.router.url.startsWith('/login')
          || this.router.url.startsWith('/callback')
          || this.router.url.startsWith('/external-survey')
          || this.router.url.startsWith('/external-feedback')
          || this.router.url.startsWith('/reset')) {
          this.websocketService.deactivate();
        } else {
          this.websocketService.activate();
          if (environment.production) {
            if (this.intercomBooted === false) {
              this.initIntercom();
              this.intercomBooted = true;
            } else {
              this.updateIntercom();
            }
          }
          if (environment.sentry.enabled === true) {
            this.updateSentry();
          }
        }
      }

      // if chunk can't be loaded - get status to determine reason
      if (event instanceof NavigationError) {
        if (event.error.message.startsWith('Loading chunk')) {
          this.statusAPIService.getStatus().subscribe();
        }
      }

      if (event instanceof RouteConfigLoadStart) {
        this.lazyLoading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.lazyLoading = false;
      }

    });

  }

  private updateIntercom(): void {
    (<any>window).Intercom('update');
  }

  private updateSentry(): void {
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: this.globals.user.id.toString(),
        email: this.globals.user.email
      });
    });
  }

  private initIntercom(): void {
    const user = this.globals.user;

    let roles = '';
    user.roles.map(x => roles += x.name + ' ');
    roles = roles.trim();

    if (environment.production && environment.mock.enabled) {
      (<any>window).Intercom('boot', {
        app_id: environment.intercom.id
      });
    } else {
      (<any>window).Intercom('boot', {
        app_id: environment.intercom.id,
        name: user.firstName + ' ' + user.lastName,
        email: user.email,
        user_id: user.id,
        Access_Level: roles,
        'User State': user.userState,
        'Company State': this.globals.company.state.toString()
      });
    }
  }

}
