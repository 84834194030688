import { EvaluationFeedbackRequest } from '@app/models/evaluation/evaluation-feedback-request.model';
import { mockEvaluationCycles } from '@app/mock/api/data/mockEvaluationCycles';
import { mockEvaluationFeedback } from '@app/mock/api/data/mockEvaluationFeedback';

export const mockEvaluationFeedbackRequests: EvaluationFeedbackRequest[] = [
    ...mockEvaluationCycles[0].users.filter(u => u.id !== u.managerId).map((user, idx) => ({
        id: idx + 1,
        evaluationCycleId: mockEvaluationCycles[0].id,
        requestUserId: user.managerId,
        feedbackUserId: user.id,
        feedbackUserManagerId: user.managerId,
        requestApprovedManager: true,
        requestDeclinedManager: false,
        requestApprovedUser: true,
        requestDeclinedUser: false,
        requestDeclinedReason: '',
        completed: mockEvaluationFeedback.filter(f => f.feedbackUserId === user.id && f.sourceUserId === user.managerId).some(f => f.completed)
    }))
];