import { Injectable } from '@angular/core';
import { MockService } from '@app/mock/api/mock-service';
import { IdeaService } from '@app/shared/api/interfaces/idea.service';
import { IdeaItem } from '@app/models/feedback/idea.model';
import { HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mockIdeas } from '@app/mock/api/data/mockIdeas';
import { Globals } from '@app/shared/globals/globals';
import { sanitizeUrl } from '@app/shared/utils/helpers';

@Injectable()
export class IdeaMockService implements MockService, IdeaService {
    constructor(private readonly globals: Globals) { }

    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);

        switch (true) {
            case url.endsWith('api/idea/submitted'):
                return this.getIdeasSubmitted();
            case url.match(/api\/idea\/submitted\/\d+$/) !== null:
                const submittedIdeaId = +url.split('/')[3];
                return this.getIdeaSubmittedById(submittedIdeaId);
            case url.match(/api\/idea\/submitted\/user\/\d+$/) !== null:
                const submittedIdeaUserId = +url.split('/')[4];
                return this.getIdeaSubmittedByUserId(submittedIdeaUserId);
            case url.endsWith('api/idea/submitted/manager/me'):
                return this.getIdeasByManagerMe();
            case url.match(/api\/idea\/submitted\/manager\/id\/\d+$/) !== null:
                const submittedIdeaManagerId = +url.split('/')[5];
                return this.getIdeaByManagerAndId(submittedIdeaManagerId);
        }
    }

    getIdeaByManagerAndId(id: number): Observable<IdeaItem> | IdeaItem {
        return mockIdeas.find(i => i.id === id);
    }

    getIdeaSubmittedById(id: number): Observable<IdeaItem> | IdeaItem {
        return mockIdeas.find(i => i.id === id);
    }

    getIdeaSubmittedByUserId(id: number): Observable<Array<IdeaItem>> | IdeaItem[] {
        return mockIdeas.filter(i => i.sourceUserId === id);
    }

    getIdeasByManagerMe(): Observable<Array<IdeaItem>> | IdeaItem[] {
        return mockIdeas.filter(i => i.destinationUserId === this.globals.user.id);
    }

    getIdeasSubmitted(): Observable<Array<IdeaItem>> | IdeaItem[] {
        return mockIdeas;
    }

    // No Ops listed below
    submitIdea(ideaItem: IdeaItem): any {
        return undefined;
    }

}