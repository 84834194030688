import { Component } from '@angular/core';
import { Address } from 'app/models/address.model';
import { OfficeLocation } from 'app/models/company.model';
import { User } from 'app/models/user/user.model';
import { interval } from 'rxjs';
import { Globals } from '../globals/globals';
import { SignUpStepType } from '@app/models/sign-up/sign-up-step-type.model';
import { SignUpStep } from '@app/models/sign-up/sign-up-step.model';

interface SignUpCarouselItem {
  src: string,
  msg: string,
  desc: string
}

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css'],
})
export class SignUpComponent {
  public readonly eSignUpStepType = SignUpStepType;
  public readonly step: SignUpStep = {
    current: SignUpStepType.CHOOSE_PASSWORD,
    complete: 0,
    total: 3
  };

  user: User;
  officeLocations: Array<OfficeLocation>;
  minimumPersonalInterests = 5;
  minimumProfessionalInterests = 5;
  selectedCarousel = 0;

  // State
  loading = false;
  error = false;
  errorMessage = '';

  // Data
  carousels: Array<SignUpCarouselItem> = [
    {
      src: '/assets/img/sign-up/ConfirmPassword.svg',
      msg: 'SET YOUR OWN PASSWORD',
      desc: 'Set a secure password with a minimum of 8 characters. Keep it unique but something you\'ll remember easily'
    },
    {
      src: '/assets/img/sign-up/LocationDetails.svg',
      msg: 'WHY ADD YOUR LOCATION',
      desc: 'It\'s important we have a correspondance address on file and know your location of work from a health and safety perspective'
    },
    {
      src: '/assets/img/sign-up/ContactDetails.svg',
      msg: 'WHY ADD YOUR CONTACT DETAILS',
      desc: 'These details will help your employer communicate with you and will only be used for such'
    },
    {
      src: '/assets/img/sign-up/PersonalDetails.svg',
      msg: 'WHY ADD YOUR PERSONAL DETAILS',
      desc: 'These details will be visible to your colleagues and help you introduce yourself in the workplace'
    }
  ];

  constructor(
    private globals: Globals
  ) {

    // Skip the password step if the user's company has enabled SSO.
    if (this.globals.signedInWithSSO === true) {
      this.carousels.splice(0, 1);
      this.step.current = SignUpStepType.LOCATION_DETAILS;
      this.step.complete = 0;
      this.step.total = 2;
    }

    this.user = this.globals.user;
    this.user.homeAddress = new Address('', '', '', '', '', '');
    this.officeLocations = this.globals.company.officeLocations;

    // Cycles carousel every minute
    interval(1000 * 60).subscribe(() => {
      let carousel = this.selectedCarousel + 1;
      if (carousel > (this.carousels.length - 1)) { carousel = 0 }
      this.changeCarousel(carousel)
    });
  }

  getCompletionPercentage(complete: number, total: number) {
    return Math.floor((complete / total) * 100).toFixed(0);
  }

  changeCarousel(index: number) {
    this.selectedCarousel = index;
  }
}
