import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Competency, CreateCompetencyDto, UpdateCompetencyDto } from 'app/models/competencies/competency.model';
import {
  CompetencyCategory,
  CreateCompetencyCategoryDto,
  UpdateCompetencyCategoryDto
} from 'app/models/competencies/competency-category.model';
import { CompetencyService } from '@app/shared/api/interfaces/competency.service';

@Injectable()
export class CompetencyAPIService implements CompetencyService {
  private readonly BASE_URL = '/api/competency';
  private readonly HEADERS = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(
    private http: HttpClient
  ) { }

  // Competency

  getAllCompetencies(): Observable<Array<Competency>> {
    const url = `${this.BASE_URL}`;
    return this.http.get<Array<Competency>>(url);
  }

  getCompetencyById(id: number): Observable<Competency> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.get<Competency>(url);
  }

  createCompetency(competency: CreateCompetencyDto): Observable<Competency> {
    const url = `${this.BASE_URL}`;
    return this.http.post<Competency>(url, competency, {headers: this.HEADERS});
  }

  updateCompetency(id: number, competency: UpdateCompetencyDto): Observable<Competency> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.put<Competency>(url, competency, {headers: this.HEADERS});
  }

  deleteCompetency(id: number): Observable<Competency> {
    const url = `${this.BASE_URL}/${id}`;
    return this.http.delete<Competency>(url, {headers: this.HEADERS});
  }

  /**
   * Imports file with
   * @param file CSV files in the format 'Category','Name','Description',
   * @returns Array<Competency>
   */
  importCompetencies(file: File): Observable<Array<Competency>> {
    const url = `${this.BASE_URL}/import`;

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<Array<Competency>>(url, formData);
  }

  // Category

  getAllCompetencyCategories(): Observable<Array<CompetencyCategory>> {
    const url = `${this.BASE_URL}/category`;
    return this.http.get<Array<CompetencyCategory>>(url);
  }

  getCompetencyCategoryById(id: number): Observable<CompetencyCategory> {
    const url = `${this.BASE_URL}/category/${id}`;
    return this.http.get<CompetencyCategory>(url);
  }

  createCompetencyCategory(competencyCategory: CreateCompetencyCategoryDto): Observable<CompetencyCategory> {
    const url = `${this.BASE_URL}/category`;
    return this.http.post<CompetencyCategory>(url, competencyCategory, {headers: this.HEADERS});
  }

  updateCompetencyCategory(id: number, competencyCategory: UpdateCompetencyCategoryDto): Observable<CompetencyCategory> {
    const url = `${this.BASE_URL}/category/${id}`;
    return this.http.put<CompetencyCategory>(url, competencyCategory, {headers: this.HEADERS});
  }

  deleteCompetencyCategory(id: number): Observable<CompetencyCategory> {
    const url = `${this.BASE_URL}/category/${id}`;
    return this.http.delete<CompetencyCategory>(url, {headers: this.HEADERS});
  }
}
