import { OrganisationalUnit } from '@app/models/organisational-unit.model';
import { mockOrganisationalUnitTypes } from '@app/mock/api/data/mockOrganisationalUnitTypes';

const managementDepartment: OrganisationalUnit = {
    id: 1,
    level: 0,
    organisationalUnitType: mockOrganisationalUnitTypes[0],
    name: "Management",
    parentOrg: null
}

export const mockOrganisationalUnits: OrganisationalUnit[] = [
    managementDepartment,
    {
        id: 2,
        level: 1,
        organisationalUnitType: mockOrganisationalUnitTypes[0],
        name: "Development",
        parentOrg: managementDepartment
    },
    {
        id: 3,
        level: 1,
        organisationalUnitType: mockOrganisationalUnitTypes[0],
        name: "Finance",
        parentOrg: managementDepartment
    },
    {
        id: 4,
        level: 1,
        organisationalUnitType: mockOrganisationalUnitTypes[0],
        name: "People & Culture",
        parentOrg: managementDepartment
    },
    {
        id: 5,
        level: 1,
        organisationalUnitType: mockOrganisationalUnitTypes[0],
        name: "Business Development",
        parentOrg: managementDepartment
    },
    {
        id: 6,
        level: 1,
        organisationalUnitType: mockOrganisationalUnitTypes[0],
        name: "Marketing",
        parentOrg: managementDepartment
    }
];