import { PublishedSurvey } from '@app/models/survey/publish-survey.model';
import * as moment from 'moment';
import { mockSurveys } from '@app/mock/api/data/mockSurveys';

export const mockPublishedSurveys: PublishedSurvey[] = [
  {
    id: 1,
    surveyId: mockSurveys[8].id,
    expiryDateTime: moment().subtract(1, 'day').toISOString(),
    message: 'A new survey \'Wellbeing Survey\' has been published, and you\'re one of the recipients!',
    favourite: true,
    reminderSent: true,
    userIds: mockSurveys[8].userIds,
    startDateTime: moment().subtract(1, 'week').toISOString(),
    status: 'OPEN'
  },
  {
    id: 2,
    surveyId: mockSurveys[9].id,
    expiryDateTime: moment().add(1, 'week').toISOString(),
    message: 'A new survey \'Employee Satisfaction Survey\' has been published, and you\'re one of the recipients!',
    favourite: false,
    reminderSent: true,
    userIds: mockSurveys[9].userIds,
    startDateTime: moment().subtract(1, 'week').toISOString(),
    status: 'OPEN'
  },
  // {
  //   id: 3,
  //   surveyId: mockSurveys[10].id,
  //   expiryDateTime: moment().add(1, 'week').toISOString(),
  //   message: 'A new survey \'eNPS Survey\' has been published, and you\'re one of the recipients!',
  //   favourite: false,
  //   reminderSent: true,
  //   userIds: mockSurveys[10].userIds,
  //   startDateTime: moment().subtract(1, 'week').toISOString(),
  //   status: 'OPEN'
  // }
];