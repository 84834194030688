import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from 'app/models/user/user.model';
import { InterestCategory } from 'app/models/interest.model';
import { InterestAPIService } from 'app/shared/api/interest.api.service';
import { OfficeLocation } from 'app/models/company.model';
import { CompanyAPIService } from 'app/shared/api/company.api.service';
import { UserAPIService } from 'app/shared/api/user.api.service';
import { EditProfileService } from './edit-profile.service';
import { forkJoin, Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { EditProfileTab } from '@app/models/edit-profile/edit-profile-tab.model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css'],
})
export class EditComponent implements OnInit, OnDestroy {
  public readonly ngUnsubscribe$: Subject<void> = new Subject<void>();
  public readonly eEditProfileTab = EditProfileTab;
  public tab: EditProfileTab = EditProfileTab.PERSONAL_DETAILS;

  officeLocations: Array<OfficeLocation> = new Array<OfficeLocation>();
  personalInterests: Array<InterestCategory> = new Array<InterestCategory>();
  professionalInterests: Array<InterestCategory> = new Array<InterestCategory>();
  loading = true;
  error = false;
  errorMessage = '';
  minimumInterests = 0;

  user!: User;

  constructor(
    private interestAPIService: InterestAPIService,
    private companyAPIService: CompanyAPIService,
    private userAPIService: UserAPIService,
    private editProfileService: EditProfileService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.editProfileService.getChange().pipe(takeUntil(this.ngUnsubscribe$)).subscribe(tab => {
      this.tab = tab;
    });
  }

  ngOnInit() {
    const tabParam = this.activatedRoute.snapshot.queryParamMap.get('part');
    if (tabParam) {
      this.openPanel(tabParam);
    }

    forkJoin([
      this.userAPIService.getMe(),
      this.interestAPIService.getPersonalCategories(),
      this.interestAPIService.getProfessionalCategories(),
      this.companyAPIService.getAllOfficeLocations(),
    ]).subscribe(([user, personal, professional, officeLocations]) => {
      this.user = user;
      this.personalInterests = personal.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
      this.professionalInterests = professional.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
      this.officeLocations = officeLocations.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
      this.loading = false;
    }, (failure: HttpErrorResponse) => {
      this.error = true;
      this.errorMessage = 'An error has occured, please try again later.';
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }

  setStep(tab: EditProfileTab): void {
    if (tab !== this.tab) {
      this.editProfileService.sendCanChange(tab);
    }
  }

  openPanel(tab: string) {
    switch (tab) {
      case 'personal':
        this.tab = EditProfileTab.PERSONAL_DETAILS;
        break;
      case 'profile':
        this.tab = EditProfileTab.PROFILE_DETAILS;
        break;
      case 'location':
        this.tab = EditProfileTab.LOCATION_DETAILS;
        break;
      case 'personal-interests':
        this.tab = EditProfileTab.INTERESTS_PERSONAL
        break;
      case 'professional-interests':
        this.tab = EditProfileTab.INTERESTS_PROFESSIONAL;
        break;
      default:
        this.tab = EditProfileTab.PERSONAL_DETAILS;
        break;
    }
  }
}
