import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GoalAnalytics, GoalAnalyticsItem } from '@app/models/analytics/goal-analytics.model';
import { OneToOneAnalytics, OneToOneAnalyticsItem } from '@app/models/analytics/one-to-one-analytics.model';
import { SurveyAnalytics } from '@app/models/analytics/survey-analytics-model';
import { ReviewAnalytics } from '@app/models/analytics/review-analytics.model';
import { AnalyticsUser } from "@app/models/analytics/analytics-user.model";
import { AnalyticsService } from '@app/shared/api/interfaces/analytics.service';

@Injectable()
export class AnalyticsAPIService implements AnalyticsService {
  http: HttpClient;
  baseURL = '/api/statistics/';

  constructor(private _http: HttpClient) {
    this.http = _http;
  }

  // #region - GOALS
  getGoalAnalyticsForPeriod(periodStart: Date, periodEnd: Date, includeArchived: boolean, includeCompleted: boolean): Observable<GoalAnalytics> {
    const url = this.baseURL + 'goals';
    const params = new HttpParams()
        .append('periodStart', `${periodStart.toISOString()}`)
        .append('periodEnd', `${periodEnd.toISOString()}`)
        .append('completed', `${includeCompleted}`)
        .append('archived', `${includeArchived}`);
    return this.http.get<GoalAnalytics>(url, {params});
  }

  getTeamGoalAnalyticsForPeriodByManagerId(periodStart: Date, periodEnd: Date, includeArchived: boolean, includeCompleted: boolean, managerId: number): Observable<GoalAnalyticsItem> {
    const url = this.baseURL + 'goals/managers/' + managerId;
    const params = new HttpParams()
        .append('periodStart', `${periodStart.toISOString()}`)
        .append('periodEnd', `${periodEnd.toISOString()}`)
        .append('completed', `${includeCompleted}`)
        .append('archived', `${includeArchived}`);
    return this.http.get<GoalAnalyticsItem>(url, {params});
  }

  // #endregion

  // #region - ONE TO ONES
  getOneToOneAnalyticsForPeriod(periodStart: Date, periodEnd: Date): Observable<OneToOneAnalytics> {
    const url = this.baseURL + 'one-to-ones';
    const params = new HttpParams()
        .append('periodStart', `${periodStart.toISOString()}`)
        .append('periodEnd', `${periodEnd.toISOString()}`);
    return this.http.get<OneToOneAnalytics>(url, {params});
  }

  getOneToOneAnalyticsForPeriodByManagerId(periodStart: Date, periodEnd: Date, managerId: number): Observable<OneToOneAnalyticsItem> {
    const url = this.baseURL + `one-to-ones/managers/${managerId}`;
    const params = new HttpParams()
        .append('periodStart', `${periodStart.toISOString()}`)
        .append('periodEnd', `${periodEnd.toISOString()}`);
    return this.http.get<OneToOneAnalyticsItem>(url, {params});
  }

  getOneToOneOverviewReport(): Observable<Blob> {
    const url = this.baseURL + 'one-to-ones/report'
    return this.http.get(url, {responseType: 'blob'});
  }

  // #endregion

  // #region - SURVEYS
  getSurveyAnalyticsOverview(): Observable<SurveyAnalytics> {
    const url = this.baseURL + 'surveys';
    const params = new HttpParams()
    return this.http.get<SurveyAnalytics>(url, {params});
  }

  getSurveyOverviewReport(): Observable<Blob> {
    const url = this.baseURL + 'surveys/report'
    return this.http.get(url, {responseType: 'blob'});
  }

  // #endregion

  // #region - REVIEW CYCLES
  getReviewAnalyticsForPeriod(periodStart: Date, periodEnd: Date): Observable<ReviewAnalytics> {
    const url = this.baseURL + 'evaluations';
    const params = new HttpParams()
        .append('periodStart', `${periodStart.toISOString()}`)
        .append('periodEnd', `${periodEnd.toISOString()}`);
    return this.http.get<ReviewAnalytics>(url, {params});
  }

  getReviewAnalyticsForSelectedCycles(selectedCycleIds: number[]): Observable<ReviewAnalytics> {
    const url = this.baseURL + 'evaluations/selected-cycles';
    return this.http.post<ReviewAnalytics>(url, selectedCycleIds);
  }

  // #endregion

  getUserReport(users: AnalyticsUser[]): Observable<Blob> {
    const url = this.baseURL + 'users/report'
    return this.http.post(url, users, {responseType: 'blob'});
  }
}
