export class Interest {
  id: number;
  category: string;
  name: string;
  interestType: string;
  creatorId?: number | null;
  creatorName?: string | null;
  description?: string;
  approved: boolean;
}

export class InterestCategory {
  name: string;
  icon: string;
  interests: Array<Interest>
}

export class CreateInterestDto {
  interestType: string;
  name: string;
  category: string;

  constructor(interestType: string, name: string, category: string) {
    this.interestType = interestType;
    this.name = name;
    this.category = category;
  }
}

export class UpdateInterestDto {
  interestType: string;
  name: string;
  category: string;

  constructor(interestType: string, name: string, category: string) {
    this.interestType = interestType;
    this.name = name;
    this.category = category;
  }
}
