import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoalKeyResultMeasureUnitPlacement } from '@app/models/goals/goal-key-result-measure-unit-placement.model';
import { GoalKeyResultType } from '@app/models/goals/goal-key-result-type.model';
import { GoalKeyResult } from '@app/models/goals/goal-key-result.model';
import { Goal } from '@app/models/goals/goal.model';
import { GoalsAPIService } from '../api/goals.api.service';

@Component({
  selector: 'app-goal-dropdown',
  templateUrl: './goal-dropdown.component.html',
  styleUrls: ['./goal-dropdown.component.css']
})
export class GoalDropdownComponent implements OnInit {
  readonly eGoalKeyResultType = GoalKeyResultType;
  readonly eGoalKeyResultMeasureUnitPlacement = GoalKeyResultMeasureUnitPlacement;
  @Input() goal?: Goal;

  alignedGoal: Goal;
  alignedGoals: Goal[];

  constructor(
    private goalsAPIService: GoalsAPIService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.alignedGoal = undefined!;
    this.alignedGoals = [];
  }

  ngOnInit() {
    this.getAlignedGoal();
    this.getAlignedGoals();
  }

  getAlignedGoal() {
    if (this.goal && this.goal.alignment) {
      this.goalsAPIService.getGoalById(this.goal.alignment).subscribe(goal => {
        this.alignedGoal = Goal.getGoalCompletionPercentage(goal);
      });
    }
  }

  getAlignedGoals() {
    if (this.goal) {
      this.goalsAPIService.getAlignedGoals(this.goal.id).subscribe(goals => {
        this.alignedGoals = Goal.getGoalArrayCompletionPercentage(goals);
      })
    }
  }

  getPercentage(result: GoalKeyResult) {
    let progress =
    ((result.measureStartValue - result.measureCurrentValue) /
      (result.measureStartValue - result.measureGoalValue)) *
    100;

  if (progress > 100) {
    progress = 100;
  }
    return progress.toFixed(0);
  }

  navigateToGoal(id: number) {
    this.router.navigate(['/goals/individual/', id], { relativeTo: this.activatedRoute })
  }
}
