<div class="row pagination-padding" *ngIf="arrayData.length > 0">
  <div class="col-sm-6">
    <div class="dataTables_info" id="datatables_info" role="status" aria-live="polite">
      Showing {{ displayAmountStart }} to {{ displayAmountEnd }} of {{ displayAmountTotal }}
    </div>
  </div>
  <div class="col-sm-6 amount-select" *ngIf="arrayData.length > 5">
    <span>Amount to display: </span>
    <span class="frankli-form-group frankli-form-group-filled">
      <select class="form-control" [(ngModel)]="amountToDisplay" (ngModelChange)="update()">
        <option *ngFor="let amount of amountsToDisplay" [ngValue]="amount">{{amount}}</option>
        <option [ngValue]="'All'">All</option>
      </select>
    </span>
  </div>
</div>

<div class="dataTables_paginate pagination-controller text-center" *ngIf="arrayData.length > 0">
  <ul class="pagination">
    <li (click)="firstPage()" [class.disabled]="currentPage === 1" class="clickable noselect">
      <a>First</a>
    </li>
    <li (click)="previousPage()" [class.disabled]="currentPage === 1" class="clickable noselect">
      <a><span class="fal fa-angle-left"></span></a>
    </li>

    <li *ngFor="let page of pages" (click)="goToPage(page)" [class.active]="currentPage === page" class="clickable noselect">
      <a>{{page}}</a>
    </li>

    <li (click)="nextPage()" [class.disabled]="currentPage === getLastPage()" class="clickable noselect">
      <a><span class="fal fa-angle-right"></span></a>
    </li>
    <li (click)="lastPage()" [class.disabled]="currentPage === getLastPage()" class="clickable noselect">
      <a>Last</a>
    </li>
  </ul>
</div>