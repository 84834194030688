<div class="display-container" *ngIf="praise">
  <!-- Sent by X to Y on Date -->
  <div class="area-metadata" *ngIf="(sourceUser && showSourceUser) || (destinationUser && showDestinationUser) || (praise.timestamp)">
    <span>Sent</span>

    <!-- Destination -->
    <span class="text-nowrap" *ngIf="destinationUser && showDestinationUser">
      <span>to</span>
      <span class="area-destination-user">
        <span class="user-picture">
          <app-profile-photo [src]="destinationUser.imageUrl"></app-profile-photo>
        </span>
        <span>{{destinationUser.firstName}} {{destinationUser.lastName}}</span>
      </span>
    </span>

    <!-- Source user -->
    <span class="text-nowrap" *ngIf="sourceUser && showSourceUser">
      <span>by</span>
      <span class="area-source-user">
        <span class="user-picture">
          <app-profile-photo [src]="sourceUser.imageUrl"></app-profile-photo>
        </span>
        <span>{{sourceUser.firstName}} {{sourceUser.lastName}}</span>
      </span>
    </span>

    <!-- Timestamp -->
    <span class="text-nowrap" *ngIf="praise.timestamp">
      <span>on</span>
      <span class="area-timestamp">{{praise.timestamp | date}}</span>
    </span>
  </div>

  <!-- Message -->
  <div class="area-message">
    <div class="feedback-question">What have they done that deserves praise?</div>
    <div class="feedback-answer">
      <ng-container *ngIf="praise.message; else noAnswer;">
        <app-rich-display [text]="praise.message"></app-rich-display>
      </ng-container>
    </div>
  </div>

  <!-- Values -->
  <div class="area-values">
    <div class="feedback-question">Which company values did they demonstrate?</div>
    <div class="feedback-answer">
      <ng-container *ngIf="praise.values; else noAnswer;">
        <div class="p-top-5" *ngIf="praise.values.length > 0; else noAnswer">
          <ng-container *ngFor="let v of praise.values">
            <span class="interest-item">{{v.text}}</span>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #noAnswer>
  <div>N/A</div>
</ng-template>