import { MockService } from '@app/mock/api/mock-service';
import { AuthService } from '@app/shared/api/interfaces/auth.service';
import { Credentials, SSOCredentials } from '@app/shared/auth/auth.api.service';
import { mockUsers } from '@app/mock/api/data/mockUsers';
import { mockCompanies } from '@app/mock/api/data/mockCompanies';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { sanitizeUrl } from '@app/shared/utils/helpers';

@Injectable()
export class AuthMockService implements MockService, AuthService {
    handleRoute(req: HttpRequest<any>): any {
        const url = sanitizeUrl(req.urlWithParams);
        switch (true) {
            case url.endsWith('/authuser'):
                return this.authenticate(null);
        }
    }

    authenticate(_credentials: Credentials): Object {
        return {
            user: mockUsers[0],
            company: mockCompanies[0],
            accessToken: 'mock_token'
        }
    }

    authenticateSSO(credentials: SSOCredentials): Observable<Object> {
        return undefined;
    }

    logout(): void {
        return undefined;
    }
}