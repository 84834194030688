import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthAPIService, SSOProvider } from '@app/shared/auth/auth.api.service';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment';
import { Globals } from '../globals/globals';

interface State {
  loading: boolean,
  error: boolean,
  errorMessage: string,
  submitted: boolean
}

interface CarouselItem { src: string, msg: string }

@Component({
  selector: 'app-login-cmp',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnDestroy, OnInit {
  private readonly ngUnsubscribe$ = new Subject<void>();
  public readonly eSSOProvider = SSOProvider;
  public readonly state: State = {
    loading: false,
    error: false,
    errorMessage: '',
    submitted: false,
  };

  loginForm: FormGroup;
  get email(): FormControl { return this.loginForm.controls.email as FormControl };
  get password(): FormControl { return this.loginForm.controls.password as FormControl };

  carousels: Array<CarouselItem> = [
    {
      src: '/assets/img/feedback/feedback.svg',
      msg: 'Enable Continuous Performance',
    },
    {
      src: '/assets/img/feedback/ideas.svg',
      msg: 'Supercharge Productivity',
    },
    {
      src: '/assets/img/feedback/praise.svg',
      msg: 'Boost Employee Engagement',
    },
  ];
  selectedCarousel = 0;

  ssoError: string | null;
  redirect: string | null;

  constructor(
    private authService: AuthAPIService,
    private router: Router,
    private formBuilder: FormBuilder,
    private globals: Globals,
    private route: ActivatedRoute
  ) {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(/^(.*).@(.*).\..+$/)]],
      password: ['', Validators.required],
    });

    this.email.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(value => {
        if (value[value.length - 1] === ' ') {
          this.email.setValue(this.email.value.trim());
        }
      });

    // Fetch the sso error if it exists
    this.ssoError = localStorage.getItem('sso_error');

    // Check if a redirect happened
    const redirect = this.route.snapshot.queryParamMap.get('redirect');
    if (redirect === null) {
      this.redirect = null;
    } else {
      this.redirect = decodeURI(redirect);
    }

    // Cycles carousel every minute
    interval(500 * 60).subscribe(x => {
      let carousel = this.selectedCarousel + 1;
      if (carousel > 2) {
        carousel = 0;
      }
      this.changeCarousel(carousel);
    });
  }

  ngOnInit() {
    if (this.ssoError) {
      const timestamp = +this.ssoError.split(':')[0];
      const message = this.ssoError.split(':')[1];
      // Check if error is 30 seconds old or less
      if (Date.now() - timestamp < 30 * 1000) {
        Swal.fire({
          title: 'Error',
          text: message,
          imageUrl: 'assets/img/swal-icons/Frankli_cancel_icon-45.svg',
          imageWidth: 140,
          imageHeight: 140,
          confirmButtonColor: '#2DA1B3'
        });
      }
      localStorage.removeItem('sso_error');
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }

  login() {
    this.state.submitted = true;
    this.state.error = false;

    if (this.loginForm.valid) {
      this.state.loading = true;

      // format email
      const email = (this.email.value as string).trim().toLocaleLowerCase();
      const login = {
        email: email,
        password: this.password.value
      };

      this.authService.authenticate(login).subscribe((res: any) => {
        // if redirect set, go to redirect
        const url = this.redirect ? this.redirect : '/';
        this.router.navigateByUrl(url);
        this.globals.welcomeViewed = false;

      }, (failure: HttpErrorResponse) => {
        let message = '';
        switch (failure.status) {
          case 401:
            message = 'The email address or password you entered is incorrect.';
            break;
          case 403:
            message = failure.error;
            break;
          default:
            message = 'An error occurred.';
            break;
        }

        this.state.errorMessage = message;
        this.state.submitted = false;
        this.state.error = true;
        this.state.loading = false;
      });
    } else {
      this.state.error = false;
      this.state.errorMessage = '';
    }

  }

  ssoLogin(provider: SSOProvider) {
    switch (provider) {
      case SSOProvider.MICROSOFT:
        document.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${environment.sso.microsoft.clientId}&response_type=code&redirect_uri=${environment.sso.redirectUri}&scope=openid+email+profile+offline_access+calendars.readwrite&state=${SSOProvider.MICROSOFT}`;
        break;
      case SSOProvider.GOOGLE:
        document.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${environment.sso.google.clientId}&redirect_uri=${environment.sso.redirectUri}&scope=openid+email+profile&response_type=code&state=${SSOProvider.GOOGLE}`;
        break;
      default:
        break;
    }
  }

  changeCarousel(index: number) {
    this.selectedCarousel = index;
  }
}
