import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, map } from 'rxjs/operators';
import { UserAPIService } from '@app/shared/api/user.api.service';
import { User } from '@app/models/user/user.model';
import { State } from '@app/shared/utils/state.model';

export interface CustomUserPickerOption {
  id: any;
  imageUrl?: string;
  firstName: string;
  lastName: string;
}

@Component({
  selector: 'app-user-picker',
  templateUrl: './user-picker.component.html',
  styleUrls: ['./user-picker.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UserPickerComponent),
    multi: true,
  }],
})
export class UserPickerComponent implements OnInit, ControlValueAccessor {
  @Input() userPool: User[];
  @Input() customOptions: CustomUserPickerOption[];
  @Input() searchPlaceholder: string;
  @Input() searchCustomOptions: boolean;

  state: State = new State(false);

  disabled: boolean;
  dropdownOpen: boolean;

  usersListed: User[];
  customOptionsListed: CustomUserPickerOption[];
  searchControl: FormControl;

  searchRunning: boolean;

  _value: User | CustomUserPickerOption;

  onChange = (_: any) => {};
  onTouched = () => {};

  get value(): (User | CustomUserPickerOption) {
    return this._value;
  }

  set value(u: (User | CustomUserPickerOption)) {
    this._value = u;
    this.onChange(u)
  }

  constructor(private userApiService: UserAPIService) {
    this.customOptionsListed = [];
    this.userPool = [];
    this.customOptions = [];
    this.searchControl = new FormControl();
    this.usersListed = [];
    this._value = undefined!;
    this.disabled = false;
    this.dropdownOpen = false;
    this.searchRunning = false;
    this.searchCustomOptions = false;
    this.searchPlaceholder = '';
  }

  writeValue(val: User) {
    this._value = val;
  }

  // Register callbacks
  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  ngOnInit() {
    // Show all if not searching
    if (!this.searchCustomOptions) {
      this.customOptionsListed = this.customOptions;
    }

    this.searchControl.valueChanges
        .pipe(
          map(sarg => {
            this.searchRunning = !!sarg;
            return sarg;
          })
        )
        .pipe(debounceTime(500))
        .subscribe((v: string) => this.doSearch(v));
    this.setDisabledState(this.disabled);
    this.usersListed = this.userPool;
  }

  doSearch(sarg: string) {
    if (sarg && sarg.length > 0) {
      // If supplied with a user pool, search on that, otherwise call the user search route
      if (this.userPool.length > 0) {
        this.state.setLoading();
        this.doSearchCustomOptions(sarg);
        this.usersListed = this.userPool.filter((u) => (`${u.firstName} ${u.lastName}`).toLocaleLowerCase().includes(sarg.toLocaleLowerCase()));
        this.state.setSuccess();
        this.searchRunning = false;
      } else {
        this.state.setLoading();
        this.userApiService.searchUsers(sarg).subscribe(users => {
          this.doSearchCustomOptions(sarg);
          this.usersListed = users;
          this.state.setSuccess();
          this.searchRunning = false;
        });
      }
    } else {
      this.usersListed = this.userPool;

      if (this.searchCustomOptions) {
        if (this.userPool.length > 0) {
          this.customOptionsListed = this.customOptions;
        } else {
          this.customOptionsListed = [];
        }
      }

      this.searchRunning = false;
    }
  }

  doSearchCustomOptions(sarg: string) {
    if (!this.searchCustomOptions) {
      this.customOptionsListed = this.customOptions;
      return;
    }

    this.customOptionsListed = this.customOptions.filter((u) => (`${u.firstName} ${u.lastName}`).toLocaleLowerCase().includes(sarg.toLocaleLowerCase()));
  }

  selectUser(u: User | CustomUserPickerOption) {
    if (!this.disabled) {
      this.dropdownOpen = false;
      this.searchControl.reset();
      this.onTouched();
      this.value = u;
    }
  }

  trySelectFirst() {
    if (this.usersListed.length > 0) {
      this.selectUser(this.usersListed[0])
    }
  }
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  hideDropdownDelayed() {
    setTimeout(() => {
      this.dropdownOpen = false
    }, 200);
  }
}
